import React from 'react'
import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'
import { format } from 'date-fns'

export const TableBT = (props: any) => {
  const { data, pageLength, getBankAccount } = props
  ////////TO DETECT CHANGE IN SCREEN SIZE///////
  const [width] = useMediaQuery()

  let fontSize
  if (width < 769) {
    fontSize = 7
  } else {
    fontSize = 10
  }

  return (
    <>
      <div style={{ fontSize }}>
        <div style={{ display: 'flex' }}>
          {/* Left Column */}
          <span
            style={{ width: '60%', padding: '8px', borderCollapse: 'collapse' }}
          >
            <table style={{ marginTop: '5px' }}>
              <tr>
                <td>Transfer From</td>
                <td>:</td>
                <td>{data?.FromBankAccountDetail?.BankName}</td>
              </tr>
              <tr>
                <td>Bank Code</td>
                <td>:</td>
                <td>
                  {
                    getBankAccount?.filter(
                      v => v?.BankAccountID === data?.FromBankAccountID
                    )[0]?.Code
                  }
                </td>
              </tr>
              <tr>
                <td>Bank A/C No. </td>
                <td>:</td>
                <td>{data?.FromBankAccountDetail?.AccountNo}</td>
              </tr>
              <tr>
                <td>Description </td>
                <td>:</td>
                <td>{data?.Description}</td>
              </tr>
            </table>
          </span>

          {/* Right Column */}
          <span style={{ width: '40%', padding: '8px' }}>
            <table>
              <>
                <tr>
                  <td style={{ paddingRight: '20px' }}>Document No. </td>
                  <td>:</td>
                  <td>
                    <b>{data?.DocNo}</b>
                  </td>
                </tr>
                {data?.TransactionDate && (
                  <tr>
                    <td style={{ paddingRight: '20px' }}>Document Date </td>
                    <td>:</td>
                    <td>
                      {format(new Date(data?.TransactionDate), 'dd/MM/yyyy')}
                    </td>
                  </tr>
                )}
                <tr>
                  <td style={{ paddingRight: '20px' }}>Reference No. </td>
                  <td>:</td>
                  <td>{!data?.RefNo ? 'NA' : data?.RefNo}</td>
                </tr>
                <tr>
                  <td style={{ paddingRight: '20px' }}>Page No. </td>
                  <td>:</td>
                  <td>1/1</td>
                </tr>

                {/* <tr>
                  <td>Page No. </td>
                  <td>:</td>
                  <td>NA</td>
                </tr> */}

                {data?.DueDate && (
                  <tr>
                    <td style={{ paddingRight: '20px' }}>Due Date </td>
                    <td>:</td>
                    <td>{format(new Date(data?.DueDate), 'dd/MM/yyyy')}</td>
                  </tr>
                )}
              </>
            </table>
          </span>
        </div>
      </div>
    </>
  )
}

export default TableBT
