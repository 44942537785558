import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize';
import { Pagination } from '@material-ui/lab';
import { Group, drawDOM, exportPDF } from '@progress/kendo-drawing';
import ShareDialog from 'components/Dialog/ShareDialog';
import saveAs from 'file-saver';
import { useMobilePDFZoom } from 'helpers/Hooks/useMobilePDFZoom';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  useGetPrHeaderQuery,
  useGetShareAttachmentMutation,
  useGetUsersByAccountAndSoftwareQuery,
} from '../../generated/graphql';
import GPBodyTemplate from './BodyTemplate/GPBodyTemplate';
import './PDFStyle.scss';
import { useGPQueries } from './useGPQueries';
import { ELetterPreview } from '@account-root/account-react/src/containers/ARModule/Hooks/ELetterTemplate/ELetterPreview';

export const PDFPreviewGP = (_props: any) => {
  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  const [width] = useMediaQuery();

  let history = useHistory();
  const { styles } = useMobilePDFZoom();

  const {
    CompanyID,
    PRHeaderID,
    POHeaderID,
    GRTNHeaderID,
    DOHeaderID,
    RFQHeaderID,
  }: any = useParams();
  const { accountType, docType, backPath } = _props;

  let fontSize;
  if (width < 769) fontSize = 7;
  else fontSize = 10;

  let descriptionSize;
  if (width < 769) {
    descriptionSize = 6;
  } else {
    descriptionSize = 9;
  }

  let fontStyle;
  fontStyle = {
    fontSize: fontSize,
    textAlign: 'center',
  };

  let routeSegments;
  let title;
  let smTitle;
  let DocumentID;
  let itemName;
  let eLetter = false;
  switch (accountType) {
    case '/':
      smTitle = 'General Purchases';
      switch (docType) {
        // PO and PR
        case 'generate-po-from-pr':
          routeSegments = 'Purchase Order';
          title = 'PURCHASE ORDER';
          DocumentID = POHeaderID;
          itemName = 'POItem';
          break;
        case 'direct-po':
          routeSegments = 'Purchase Order';
          title = 'PURCHASE ORDER';
          DocumentID = POHeaderID;
          itemName = 'POItem';
          eLetter = true;
          break;
        case 'centralised-po':
          routeSegments = 'Purchase Order';
          title = 'PURCHASE ORDER';
          DocumentID = POHeaderID;
          itemName = 'POItem';
          eLetter = true;
          break;
        case 'purchase-requisition':
          routeSegments = 'Purchase Requisition';
          title = 'PURCHASE REQUISITION';
          DocumentID = PRHeaderID;
          itemName = 'PRItem';
          eLetter = true;
          break;
        case 'rfq':
          routeSegments = 'Request for Quotation';
          title = 'QUOTATION';
          DocumentID = RFQHeaderID;
          itemName = 'RFQItem';
          break;
        // GRTN and GRN
        case 'good-return-note':
          routeSegments = 'Goods Return Note';
          title = 'GOODS RETURN NOTE';
          DocumentID = GRTNHeaderID;
          itemName = 'GRTNHeaderItem';
          eLetter = true;
          break;
        case 'delivery-order':
          routeSegments = 'Goods Receive Note';
          title = 'GOODS RECEIVE NOTE';
          DocumentID = DOHeaderID;
          itemName = 'DOItem';
          eLetter = true;
          break;
        default:
          break;
      }
      switch (backPath) {
        case 'purchase-requisition':
          break;
        case 'direct-po':
          break;
      }
      break;
  }

  const { queryLoading, listData, primaryKey, templateBody } = useGPQueries({
    docType,
    CompanyID,
    transactionID: DocumentID,
  });

  const previewDetail = listData[0];

  interface InfoTemplateRight {
    title: string;
    data: string;
  }
  const InfoTemplateRight = ({ title, data }: InfoTemplateRight) => {
    return (
      <div style={{ paddingLeft: '20%' }}>
        <div style={{ width: '40%', display: 'inline-flex' }}>{`${title}`}</div>
        <div style={{ width: '10%', display: 'inline-flex' }}>:</div>
        <div style={{ width: '50%', display: 'inline-flex' }}>{data}</div>
      </div>
    );
  };

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
  });

  const {
    loading,
    error,
    data: { getPRHeader } = { getPRHeader: [] },
  } = useGetPrHeaderQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, PRHeaderID: PRHeaderID },
  });

  // ========== Page definition ==========

  const [page, setCurrentPage] = useState<number>(0);

  // ========== Threshold definition ==========
  let totalItems = previewDetail?.[itemName]?.length;

  let threshold;
  if (totalItems > 7) {
    threshold = 7;
  } else {
    threshold = 0;
  }

  useEffect(() => {
    if (!!previewDetail) separateThreshold(previewDetail);
  }, [previewDetail]);

  // ========== Remap Data for item list ==========
  const [remapData, setRemapData] = useState(null);

  const separateThreshold = input => {
    let temp = [];
    let noChange = [];
    let tempItem = [];

    previewDetail?.[itemName]?.map((v, i) => {
      tempItem.push({ ...v, index: i + 1 });
      if ((i + 1) % threshold === 0) {
        temp.push({ ...input, [itemName]: tempItem });
        tempItem = [];
      } else if (previewDetail?.[itemName]?.length === i + 1) {
        temp.push({ ...input, [itemName]: tempItem });
      }
    });
    noChange.push(temp);
    temp = [];
    return setRemapData(noChange.flat());
  };

  /// ========== kendo-drawing PDF Download ===========
  const dataURItoFile = (dataurl, filename) => {
    dataurl.replace('data:application/pdf;base64,', '');
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const onPrint = () => {
    let element = document.getElementById('pdf-data-print');

    drawDOM(element, {
      paperSize: 'A4',
      margin: {
        bottom: '1cm',
        top: '1cm',
        left: '0.5cm',
        right: '0.5cm',
      },
      scale: eLetter === true ? 0.72 : width < 769 ? 1.3 : 0.85,
      forcePageBreak: '.page-break',
    })
      .then((group: Group) => exportPDF(group))
      .then(dataURI => {
        let fileObject = dataURItoFile(
          dataURI,
          `${title} (${previewDetail?.DocNo}).pdf`,
        );
        saveAs(fileObject, `${title} (${previewDetail?.DocNo}).pdf`);
      });
  };

  /* ========================================= */
  /* DOCS THAT IS SAVED AS PDF WHICH IS HIDDEN */
  /* ========================================= */

  const pdfToPrint = () => {
    return (
      <>
        <div style={{ position: 'fixed', left: `-1000px` }}>
          <div id={'pdf-data-print'}>
            {remapData &&
              remapData?.map((v, index) => {
                if (eLetter === true)
                  return (
                    <ELetterPreview
                      fontStyle={fontStyle}
                      title={title}
                      previewDetail={previewDetail}
                      accountType={accountType}
                      docType={docType}
                      itemName={itemName}
                      getUsersByAccountAndSoftware={
                        getUsersByAccountAndSoftware
                      }
                      remapData={remapData}
                      page={index}
                      pageLength={remapData?.length}
                      htmlTemplate={templateBody}
                    />
                  );
                else
                  <GPBodyTemplate
                    accountType={accountType}
                    fontStyle={fontStyle}
                    fontSize={fontSize}
                    descriptionSize={descriptionSize}
                    title={title}
                    docType={docType}
                    previewDetail={previewDetail}
                    getUsersByAccountAndSoftware={getUsersByAccountAndSoftware}
                    remapData={remapData}
                    page={index}
                    pageLength={remapData?.length}
                    companyCountry={user?.companyAddress?.Address?.country}
                  />;
              })}
          </div>
        </div>
      </>
    );
  };

  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [openSendEmail, setOpenSendEmail] = useState(false);

  const [
    getShareAttachments,
    {
      data: { getShareAttachment } = { getShareAttachment: {} as any },
      called: getShareAttachmentCalled,
      loading: ShareAttachmentLoading,
    },
  ] = useGetShareAttachmentMutation({
    onCompleted: () => {
      setOpenShareDialog(true);
    },
  });

  const onSubmit = blob => {
    getShareAttachments({
      variables: {
        file: blob,
        CompanyID: CompanyID,
      },
    });
  };

  return (
    <>
      {loading && <Loading />}
      {UserLoading && <Loading />}
      {queryLoading && <Loading />}
      {ShareAttachmentLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/general-purchase/${CompanyID}/${backPath}`);
        }}
        smTitle={'General Purchases'}
        title={user?.companyName}
        routeSegments={[
          { name: `${smTitle}` },
          { name: `${routeSegments}` },
          { name: `${routeSegments}`, current: true },
        ]}
      />

      <ContentWrapper footer>
        <div
          style={eLetter === false ? { height: window.screen.height } : null}
        >
          <div style={eLetter === false ? styles.supercontainer : null}>
            <div style={eLetter === false ? styles.container : null}>
              {previewDetail &&
                remapData &&
                (eLetter === true ? (
                  <ELetterPreview
                    fontStyle={fontStyle}
                    title={title}
                    previewDetail={previewDetail}
                    accountType={accountType}
                    docType={docType}
                    itemName={itemName}
                    getUsersByAccountAndSoftware={getUsersByAccountAndSoftware}
                    remapData={remapData}
                    page={page}
                    pageLength={remapData?.length ?? 1}
                    htmlTemplate={templateBody}
                    isView={true}
                  />
                ) : (
                  <GPBodyTemplate
                    accountType={accountType}
                    fontStyle={fontStyle}
                    fontSize={fontSize}
                    descriptionSize={descriptionSize}
                    title={title}
                    docType={docType}
                    previewDetail={previewDetail}
                    getUsersByAccountAndSoftware={getUsersByAccountAndSoftware}
                    remapData={remapData}
                    page={page}
                    pageLength={remapData?.length}
                    companyCountry={user?.companyAddress?.country}
                  />
                ))}
            </div>
          </div>
        </div>

        <div className={`pagination-dis-${width < 769 ? 'mobile' : 'desktop'}`}>
          <Pagination
            count={remapData?.length}
            shape="rounded"
            page={page + 1}
            onChange={(e, page) => {
              setCurrentPage(page - 1);
            }}
          />
        </div>

        {pdfToPrint()}
      </ContentWrapper>

      {ShareDialog({
        shareTitle: `General Purchases(${routeSegments}) - ${user?.companyName}`,
        title: `Share`,
        URL: getShareAttachment?.fileURL,
        setSimple: setOpenShareDialog,
        simple: openShareDialog,
        emailShare: openSendEmail,
        setSimpleEmail: setOpenSendEmail,
      })}

      <Footer
        options={[
          // {
          //   name: 'Share',
          //   onClick: async () => {
          //     let temp = await document.getElementById('pdf-data-print');

          //     htmlToImage
          //       .toBlob(temp, { style: { background: '#fff' } })
          //       .then(value => {
          //         onSubmit(value);
          //       })
          //       .catch(error => console.error(error, 'Something went wrong'));
          //   },
          //   // disabled:
          //   //   graphData.every(emptyData) == undefined ||
          //   //   graphData.every(emptyData) ||
          //   //   handlePermDisabled({
          //   //     permEnum: ContPermission.ProjectCostingProjectCostingShare,
          //   //   }),
          //   color: 'primary',
          //   props: {
          //     type: 'submit',
          //   },

          //   startIcon: <ShareIcon style={{ fontSize: '18px' }} />,
          // },
          {
            name: 'Download',
            onClick: () => {
              onPrint();
            },
            color: 'primary',
          },
        ]}
      />
    </>
  );
};
