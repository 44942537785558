import theme from '@ifca-root/react-component/src/assets/theme'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  useMediaQuery,
  withStyles,
} from '@material-ui/core'
import { Tune } from '@material-ui/icons'
import EventIcon from '@material-ui/icons/Event'
import { advancedFilterList } from 'components/Filter/AdvancedFilter'
import {
  useGetArAdvancebyStatusLazyQuery,
  useGetArCreditNotebyStatusLazyQuery,
  useGetArDebitNotebyStatusLazyQuery,
  useGetArInvoicebyStatusLazyQuery,
  useGetArOfficialReceiptbyStatusLazyQuery,
  useGetArRefundbyStatusLazyQuery,
  useGetDebtorAccountQuery,
  useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'

/* -------------------------------------------- */
/*            For ACTIVE and APPROVED           */
/* -------------------------------------------- */
export const DebtorAccountTrxListing = (props: any) => {
  useEffect(() => {
    console.log('DebtorAccountTrxListing')
  }, [])
  const { docType } = props
  let history = useHistory()
  const { CompanyID, DebtorAccountID }: any = useParams()
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const GreyTooltip = withStyles({
    tooltip: {
      color: 'white',
      backgroundColor: '#9e9e9e',
    },
  })(Tooltip)

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
  })

  const {
    loading: DebtorAccountLoading,
    error: DebtorAccountError,
    data: { getDebtorAccount } = { getDebtorAccount: [] },
  } = useGetDebtorAccountQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      DebtorAccountID: DebtorAccountID,
    },
  })

  const totalDebtorDocAmt = getDebtorAccount?.reduce(
    (previousValue, currentValue) => previousValue + currentValue?.TotalAmt,
    0
  )
  const totalDocAmt = filteredList?.reduce(
    (previousValue, currentValue) => previousValue + currentValue?.DocAmt,
    0
  )

  const [
    loadARAdvance,
    {
      loading: getARAdvancebyStatusLoading,
      data: { getARAdvancebyStatus } = {
        getARAdvancebyStatus: [],
      },
    },
  ] = useGetArAdvancebyStatusLazyQuery({
    fetchPolicy: 'network-only',
    // variables: {
    //   CompanyID: CompanyID,
    //   StatusArr: [listStatus ?? 'ACTIVE'],
    // },
    onCompleted: ({ getARAdvancebyStatus }) => {
      setOriginalListing(getARAdvancebyStatus, v => {
        v['DocDateFormatted'] = formatDate(v.DocDate)
      })
    },
  })

  const [
    loadARInvoice,
    {
      loading: ARInvoicebyStatusLoading,
      data: { getARInvoicebyStatus } = {
        getARInvoicebyStatus: [],
      },
    },
  ] = useGetArInvoicebyStatusLazyQuery({
    fetchPolicy: 'network-only',
    // variables: {
    //   CompanyID: CompanyID,
    //   StatusArr: [listStatus ?? 'ACTIVE'],
    // },
    onCompleted: ({ getARInvoicebyStatus }) => {
      setOriginalListing(getARInvoicebyStatus, v => {
        v['DocDateFormatted'] = formatDate(v.DocDate)
      })
    },
  })

  const [
    loadARCreditNote,
    {
      loading: ARCreditNotebyStatusLoading,
      data: { getARCreditNotebyStatus } = {
        getARCreditNotebyStatus: [],
      },
    },
  ] = useGetArCreditNotebyStatusLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getARCreditNotebyStatus }) => {
      setOriginalListing(getARCreditNotebyStatus, v => {
        v['DocDateFormatted'] = formatDate(v.DocDate)
      })
    },
  })

  const [
    loadARDebitNote,
    {
      loading: ARDebitNotebyStatusLoading,
      data: { getARDebitNotebyStatus } = {
        getARDebitNotebyStatus: [],
      },
    },
  ] = useGetArDebitNotebyStatusLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getARDebitNotebyStatus }) => {
      setOriginalListing(getARDebitNotebyStatus, v => {
        v['DocDateFormatted'] = formatDate(v.DocDate)
      })
    },
  })

  const [
    loadAROfficialReceipt,
    {
      loading: getAROfficialReceiptbyStatusLoading,
      data: { getAROfficialReceiptbyStatus } = {
        getAROfficialReceiptbyStatus: [],
      },
    },
  ] = useGetArOfficialReceiptbyStatusLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getAROfficialReceiptbyStatus }) => {
      setOriginalListing(getAROfficialReceiptbyStatus, v => {
        v['DocDateFormatted'] = formatDate(v.DocDate)
      })
    },
  })

  const [
    loadARRefund,
    {
      loading: getARRefundbyStatusLoading,
      data: { getARRefundbyStatus } = {
        getARRefundbyStatus: [],
      },
    },
  ] = useGetArRefundbyStatusLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getARRefundbyStatus }) => {
      setOriginalListing(getARRefundbyStatus, v => {
        v['DocDateFormatted'] = formatDate(v.DocDate)
      })
    },
  })

  useEffect(() => {
    if (docType === 'invoice') {
      loadARInvoice({
        variables: {
          CompanyID: CompanyID,
          StatusArr: ['COMPLETED'],
          DebtorAccountID: DebtorAccountID,
        },
      })
    } else if (docType === 'credit-note')
      loadARCreditNote({
        variables: {
          CompanyID: CompanyID,
          StatusArr: ['COMPLETED'],
          DebtorAccountID: DebtorAccountID,
        },
      })
    else if (docType === 'debit-note')
      loadARDebitNote({
        variables: {
          CompanyID: CompanyID,
          StatusArr: ['COMPLETED'],
          DebtorAccountID: DebtorAccountID,
        },
      })
    else if (docType === 'advance')
      loadARAdvance({
        variables: {
          CompanyID: CompanyID,
          StatusArr: ['COMPLETED'],
          DebtorAccountID: DebtorAccountID,
        },
      })
    else if (docType === 'official-receipt')
      loadAROfficialReceipt({
        variables: {
          CompanyID: CompanyID,
          StatusArr: ['COMPLETED'],
          DebtorAccountID: DebtorAccountID,
        },
      })
    else if (docType === 'refund')
      loadARRefund({
        variables: {
          CompanyID: CompanyID,
          StatusArr: ['COMPLETED'],
          DebtorAccountID: DebtorAccountID,
        },
      })
  }, [docType])

  const PageTitle = docType => {
    switch (docType) {
      case 'advance':
        return 'Advances'
      case 'invoice':
        return 'Invoices'
      case 'official-receipt':
        return 'Official Receipt'
      case 'refund':
        return 'Refund'
      case 'credit-note':
        return 'Credit Note'
      case 'debit-note':
        return 'Debit Note'
      default:
        return ''
    }
  }

  const { handlePermHidden } = usePermissionChecker()

  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  return (
    <>
      {getARAdvancebyStatusLoading && <Loading />}
      {ARInvoicebyStatusLoading && <Loading />}
      {ARCreditNotebyStatusLoading && <Loading />}
      {ARDebitNotebyStatusLoading && <Loading />}
      {getAROfficialReceiptbyStatusLoading && <Loading />}
      {UserLoading && <Loading />}
      {DebtorAccountLoading && <Loading />}
      {getARRefundbyStatusLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(
            `/account-receivable/${CompanyID}/debtor-account/debtor-profile/${DebtorAccountID}`
          )
          localStorage.removeItem(`searchFilter`)
          localStorage.removeItem(`advancedFilter`)
        }} //<- back button action
        smTitle="Account Receivable"
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Debtor Accounts' },
          { name: 'Debtor Accounts' },
          { name: PageTitle(docType), current: true },
        ]}
        //rightRouteSegments={[{ name: 'Current Path', current: true }]}
      />
      {DebtorAccountID ? (
        <DynamicSubHeader
          title={
            <span>
              <span style={{ fontWeight: 'bold' }}>
                {getDebtorAccount[0]?.DebtorName}
              </span>
            </span>
          }
          rightText={
            <>
              <span>
                <>
                  <span className="c-orange ">{amtStr(totalDebtorDocAmt)}</span>
                </>
              </span>
            </>
          }
          infoLine={
            <span style={{ fontWeight: 'bold' }}>{PageTitle(docType)}</span>
          }
          rightInfoLine={
            <>
              <span>
                <>
                  <span className="c-orange ">{amtStr(totalDocAmt)}</span>
                </>
              </span>
            </>
          }
        />
      ) : null}

      <div>
        <SearchHeader
          title={`${PageTitle(docType)} Listing`}
          value={`${advancedFilterList(filteredList)?.length}`}
          search
          fixed
          multiDynamicInfo
          onChangeAction={e => {
            handleSearch(e?.target?.value, [
              'DocNo',
              'RefNo',
              'DocDate',
              'Description',
            ])
          }}
          onCloseAction={() => handleSearch('', [])}
          option={{
            icon: <Tune />,
            onClick: () =>
              history.push(`/account-receivable/${CompanyID}/advance/filter`),
          }}
          isDefaultValue={!!getSearch}
          defaultValue={getSearch ? getSearch : ''}
        />
      </div>
      <ContentWrapper style={{ marginTop: isDesktop ? '153px' : '90px' }} float>
        <List className="core-list">
          {advancedFilterList(filteredList) === undefined ||
          advancedFilterList(filteredList)?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            advancedFilterList(filteredList)
              ?.sort((a, b) => {
                return a.DocDate.localeCompare(b.DocDate)
              })
              ?.map((el, index) => {
                return (
                  <ListItem>
                    <ListItemText
                      primary={
                        <>
                          <span
                            className="flex-space mdLabel"
                            style={{ fontSize: '12px' }}
                          >
                            {el?.DocNo}
                          </span>
                          <span
                            className="desc"
                            style={{
                              fontSize: '12px',
                            }}
                          >
                            <span className="mdLabel">
                              <IconText
                                icon={<EventIcon />}
                                font="mdLabel"
                                children={formatDate(el?.DocDate)}
                              ></IconText>
                            </span>
                          </span>
                        </>
                      }
                      secondary={
                        <>
                          <Grid spacing={1} container className="table-content">
                            <Grid item xs={6}>
                              <span style={{ fontSize: '10px' }}>
                                {el?.DebtorAccount?.DebtorName}
                              </span>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                textAlign: 'end',
                              }}
                            >
                              <span className="xxTitle c-orange">
                                <span
                                  style={{
                                    fontSize: '10px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {amtStr(el?.DocAmt)}
                                </span>
                              </span>
                            </Grid>
                          </Grid>

                          <Grid spacing={1} container className="table-content">
                            <Grid item xs={6}>
                              <span style={{ fontSize: '10px' }}>
                                {el?.Allocation?.map(
                                  alloc =>
                                    alloc?.[`${alloc?.CreditOrDebit}DocNo`]
                                )?.join(' | ') ?? '-'}
                              </span>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                textAlign: 'end',
                              }}
                            >
                              <span
                                style={{
                                  fontSize: '10px',
                                  color: 'grey',
                                  fontWeight: 'bold',
                                }}
                              >
                                {amtStr(el?.BalanceAmt)}
                              </span>
                            </Grid>
                          </Grid>
                          <div>
                            <div
                              className="desc text-overflow"
                              style={{ width: '340px' }}
                            >
                              {el?.Remark ?? el?.Description}
                            </div>
                          </div>
                        </>
                      }
                    />
                  </ListItem>
                )
              })
          )}
        </List>
      </ContentWrapper>
    </>
  )
}
