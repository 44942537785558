export const DebtorAccountTemplateForm = [
  {
    header: {
      header: 'DebtorType',
      width: 17,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'DebtorName',
      width: 40,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'CompanyRegNo',
      width: 17,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'MobileNo',
      width: 17,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'OfficeNo',
      width: 17,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'Email',
      width: 20,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'CreditTerm',
      width: 15,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'Tax',
      width: 15,
    },
    dataType: 'Tax',
  },
  {
    header: {
      header: 'IsVIP',
      width: 17,
    },
    dataType: 'IsVIP',
  },
  {
    header: {
      header: 'IsCompany',
      width: 17,
    },
    dataType: 'IsCompany',
  },
  {
    header: {
      header: 'GSTRegNo',
      width: 17,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'GSTStartDate',
      width: 17,
    },
    dataType: 'date',
  },
  {
    header: {
      header: 'Remark',
      width: 30,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'Address',
      width: 40,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'Country',
      width: 17,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'State',
      width: 17,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'City',
      width: 17,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'PostCode',
      width: 17,
    },
    dataType: 'text',
  },
]
