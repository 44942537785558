import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import { Checkbox, MenuItem, TextField } from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { Autocomplete } from '@material-ui/lab'
import { AccountFooter } from 'components/Footer/AccountFooter'
import {
  RecordStatus,
  useGetEntityCoaQuery,
  useGetMasterCoaQuery,
} from 'generated/graphql'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router'
import * as yup from 'yup'

interface MasterCOAListingParamsProps {
  StartDate: Date
  EndDate: Date
  Name: string
  AccountCode: string[]
  ParentCode: string[]
  CostCentreCode: string
  AccountType: string[]
  RecordStatus: string
}

export interface MasterCOA {
  Name: string
  MasterCOAID: string
  ParentMasterCOAID: string
  IsControl: boolean
  IsLastNode: boolean
  AccountType: string
  Code: string
  Level: number
}
export interface AccountType {
  name: string
  value: string
}

export const MasterCOAListingParamsForm = (props: any) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const ParamsSchema = yup.object().shape({})
  const [masterCOAData, setMasterCOAData] = useState<MasterCOA[]>([])
  const [accountTypeData, setAccountTypeData] = useState<AccountType[]>([])
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />

  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    watch,
    setValue,
  } = useForm<MasterCOAListingParamsProps>({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(ParamsSchema),
  })

  //MasterCOA
  const {
    loading: MasterCOALoading,
    error: MasterCOAError,
    data: { getMasterCOA } = { getMasterCOA: [] },
  } = useGetMasterCoaQuery({
    fetchPolicy: 'network-only',
  })

  //EntityCOA
  const {
    loading: EntityCOALoading,
    error: EntityCOAErros,
    data: { getEntityCOA } = { getEntityCOA: [] },
  } = useGetEntityCoaQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
    onCompleted: () => {
      const masterCOAList =
        getEntityCOA?.length > 0
          ? getEntityCOA
              ?.map(coa => coa?.MasterCOA)
              ?.filter(
                coa => coa?.IsLastNode === true && coa?.IsControl === false
              )
          : getMasterCOA?.filter(
              coa => coa?.IsLastNode === true && coa?.IsControl === false
            )
      setMasterCOAData((masterCOAList as any) as MasterCOA[])

      const accountTypeList = [
        { name: 'Asset', value: 'ASSET' },
        { name: 'Liability', value: 'LIABILITY' },
        { name: 'Equity', value: 'EQUITY' },
        { name: 'Revenue', value: 'REVENUE' },
        { name: 'Expenses', value: 'EXPENSES' },
      ]
      setAccountTypeData((accountTypeList as any) as AccountType[])
    },
  })

  const [selectedCreditType, setSelectedCreditType]: any = useState(new Set())

  const handleCheckboxCreditorTypeChange = (event: any) => {
    let creditorType = selectedCreditType

    if (event?.target?.checked) {
      creditorType.add(event?.target?.value)
    } else {
      creditorType.delete(event?.target?.value)
    }
    setSelectedCreditType(new Set(creditorType))
  }

  const [selectedCreditCode, setSelectedCreditCode]: any = useState(new Set())

  const handleCheckboxCreditorCodeChange = (event: any) => {
    let creditorCode = selectedCreditCode

    if (event?.target?.checked) {
      creditorCode.add(event?.target?.value)
    } else {
      creditorCode.delete(event?.target?.value)
    }
    setSelectedCreditCode(new Set(creditorCode))
  }

  const [selectedParentCode, setSelectedParentCode]: any = useState(new Set())

  const handleCheckboxParentCodeChange = (event: any) => {
    let parentCode = selectedParentCode

    if (event?.target?.checked) {
      parentCode.add(event?.target?.value)
    } else {
      parentCode.delete(event?.target?.value)
    }
    setSelectedParentCode(new Set(parentCode))
  }

  const onSubmit = data => {
    history.push({
      pathname: `/general-ledger/${CompanyID}/digital-report/mastercoa-listing-report/generated`,
      state: {
        AccountType: selectedCreditType
          ? Array.from(selectedCreditType)
          : undefined,
        ParentCode: selectedParentCode
          ? Array.from(selectedParentCode)
          : undefined,
        AccountCode: selectedCreditCode
          ? Array.from(selectedCreditCode)
          : undefined,
        Name: data.Name,
        Status: data.Status,
      },
    })
  }

  return (
    <>
      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(`/general-ledger/${CompanyID}/digital-report`)
        }
        smTitle={'General Ledger'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'MasterCOA Listing', current: true },
        ]}
        rightRouteSegments={[{ name: 'Parameters', current: true }]}
      />
      <ContentWrapper float>
        <CardContents>
          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  options={accountTypeData || []}
                  getOptionLabel={option => option?.name}
                  fullWidth
                  disableCloseOnSelect
                  onChange={(value, newValue: any) => {
                    const selected = new Set()
                    newValue?.map(x => {
                      selected.add(x?.value)
                    })

                    setSelectedCreditType(new Set(selected))
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.value}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxCreditorTypeChange(event)
                        }}
                      />
                      {option?.value}
                    </React.Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ width: '100%' }}
                          label="Account Type"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="AccountType"
            label="Account Type"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          {!EntityCOALoading && !MasterCOALoading && (
            <Controller
              render={() => {
                return (
                  <Autocomplete
                    multiple
                    options={
                      getMasterCOA?.filter(x => x?.IsLastNode === false) || []
                    }
                    getOptionLabel={option =>
                      `${option?.Name} | ${option?.Code}`
                    }
                    fullWidth
                    disableCloseOnSelect
                    onChange={(value, newValue: any) => {
                      const selected = new Set()
                      newValue?.map(x => {
                        selected.add(x?.MasterCOAID)
                      })
                      setSelectedParentCode(new Set(selected))
                    }}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          value={option?.MasterCOAID}
                          style={{ marginRight: 8 }}
                          checked={selected}
                          color="primary"
                          onChange={event => {
                            handleCheckboxParentCodeChange(event)
                          }}
                        />

                        <div>
                          <div>
                            <span className="xsTitle">{option.Name}</span>
                          </div>
                          <div className="desc">{option.Code}</div>
                        </div>
                      </React.Fragment>
                    )}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            variant="outlined"
                            style={{ width: '100%' }}
                            label="Parent Code"
                            margin="normal"
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              name="ParentCode"
              label="Parent Code"
              margin="normal"
              autoComplete="off"
              fullWidth
              multiline={true}
              control={control}
              select
              ref={register}
            />
          )}

          {!EntityCOALoading && !MasterCOALoading && (
            <Controller
              render={() => {
                return (
                  <Autocomplete
                    multiple
                    options={masterCOAData || []}
                    getOptionLabel={option =>
                      `${option?.Name} | ${option?.Code}`
                    }
                    fullWidth
                    disableCloseOnSelect
                    onChange={(value, newValue: any) => {
                      const selected = new Set()
                      newValue?.map(x => {
                        selected.add(x?.Code)
                      })
                      setSelectedCreditCode(new Set(selected))
                    }}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          value={option?.Code}
                          style={{ marginRight: 8 }}
                          checked={selected}
                          color="primary"
                          onChange={event => {
                            handleCheckboxCreditorCodeChange(event)
                          }}
                        />

                        <div>
                          <div>
                            <span className="xsTitle">{option.Name}</span>
                          </div>
                          <div className="desc">{option.Code}</div>
                        </div>
                      </React.Fragment>
                    )}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            variant="outlined"
                            style={{ width: '100%' }}
                            label="Account Code"
                            margin="normal"
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              name="AccountCode"
              label="Account Code"
              margin="normal"
              autoComplete="off"
              fullWidth
              multiline={true}
              control={control}
              select
              ref={register}
            />
          )}

          <Controller
            as={TextField}
            id="standard-basic"
            name="Name"
            label="Name"
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            ref={register}
          />

          <Controller
            render={({ onChange, value }) => (
              <TextField
                label="Status"
                select
                fullWidth
                value={value}
                onChange={e => {
                  onChange(e.target.value)
                  setValue('RecordStatus', e.target.value)
                }}
                style={{ marginTop: 'auto' }}
              >
                <MenuItem key={RecordStatus.Active} value={RecordStatus.Active}>
                  Active
                </MenuItem>
                <MenuItem
                  key={RecordStatus.Inactive}
                  value={RecordStatus.Inactive}
                >
                  Inactive
                </MenuItem>
              </TextField>
            )}
            control={control}
            fullWidth
            ref={register()}
            name="Status"
            autoComplete="off"
            multiline={true}
            margin="dense"
          />
        </CardContents>

        <AccountFooter
          options={[
            {
              name: 'Submit',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
            },
          ]}
        />
      </ContentWrapper>
    </>
  )
}
