import React, { lazy } from 'react'

const GPDigitalReportSubmenu = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/GPDigitalReportSubmenu'
  ).then(module => ({
    default: module.GPDigitalReportSubmenu,
  }))
)

const GPPO4WaysMatchReport = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/GeneratedReport/GPPO4WaysMatchReport'
  ).then(module => ({
    default: module.GPPO4WaysMatchReport,
  }))
)

const GPPO4WaysMatchParamsForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/Parameters/GPPO4WaysMatchParamsForm'
  ).then(module => ({
    default: module.GPPO4WaysMatchParamsForm,
  }))
)

const GRNGRTNDailyReportParamsForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/Parameters/GRNGRTNDailyReportParamsForm'
  ).then(module => ({
    default: module.GRNGRTNDailyReportParamsForm,
  }))
)

const GRNGRTNDailyReport = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/GeneratedReport/GRNGRTNDailyReport'
  ).then(module => ({
    default: module.GRNGRTNDailyReport,
  }))
)

const GRNGRTNWarehouseParamsForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/Parameters/GRNGRTNWarehouseParamsForm'
  ).then(module => ({
    default: module.GRNGRTNWarehouseParamsForm,
  }))
)

const GRNGRTNWarehouseReport = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/GeneratedReport/GRNGRTNWarehouseReport'
  ).then(module => ({
    default: module.GRNGRTNWarehouseReport,
  }))
)

const POItemPurchasesParamsForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/Parameters/POItemPurchasesParamsForm'
  ).then(module => ({
    default: module.POItemPurchasesParamsForm,
  }))
)

const POItemPurchasesReport = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/GeneratedReport/POItemPurchasesReport'
  ).then(module => ({
    default: module.POItemPurchasesReport,
  }))
)

const POPurchasesByItemCategoryParamsForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/Parameters/POPurchasesByItemCategoryParamsForm'
  ).then(module => ({
    default: module.POPurchasesByItemCategoryParamsForm,
  }))
)

const POPurchasesByItemCategoryReport = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/GeneratedReport/POPurchasesByItemCategoryReport'
  ).then(module => ({
    default: module.POPurchasesByItemCategoryReport,
  }))
)

const POOutstandingReportParamsForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/Parameters/POOutstandingReportParamsForm'
  ).then(module => ({
    default: module.POOutstandingReportParamsForm,
  }))
)

const POOutstandingReport = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/GeneratedReport/POOutstandingReport'
  ).then(module => ({
    default: module.POOutstandingReport,
  }))
)

const SupplierPerformanceDetailReport = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/GeneratedReport/SupplierPerformanceDetailReport'
  ).then(module => ({
    default: module.SupplierPerformanceDetailReport,
  }))
)

const SupplierPerformanceDetailParamsForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/Parameters/SupplierPerformanceDetailParamsForm'
  ).then(module => ({
    default: module.SupplierPerformanceDetailParamsForm,
  }))
)

const gpDigitalReportRoutes = [
  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/digital-report',
    },
    component: <GPDigitalReportSubmenu />,
  },

  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/digital-report/po-4-ways-match/generated',
    },
    component: <GPPO4WaysMatchReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/digital-report/po-4-ways-match/parameters',
    },
    component: <GPPO4WaysMatchParamsForm />,
  },

  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/digital-report/grn-grtn-daily-report/parameters',
    },
    component: <GRNGRTNDailyReportParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/digital-report/grn-grtn-daily-report/generated',
    },
    component: <GRNGRTNDailyReport />,
  },

  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/digital-report/grn-grtn-warehouse-report/parameters',
    },
    component: <GRNGRTNWarehouseParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/digital-report/grn-grtn-warehouse-report/generated',
    },
    component: <GRNGRTNWarehouseReport />,
  },

  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/digital-report/purchases-analysis-by-item/parameters',
    },
    component: <POItemPurchasesParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/digital-report/purchases-analysis-by-item/generated',
    },
    component: <POItemPurchasesReport />,
  },

  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/digital-report/purchases-by-item-category/parameters',
    },
    component: <POPurchasesByItemCategoryParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/digital-report/purchases-by-item-category/generated',
    },
    component: <POPurchasesByItemCategoryReport />,
  },

  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/digital-report/po-outstanding-report/parameters',
    },
    component: <POOutstandingReportParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/digital-report/po-outstanding-report/generated',
    },
    component: <POOutstandingReport />,
  },

  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/digital-report/supplier-performance-detail/generated',
    },
    component: <SupplierPerformanceDetailReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/digital-report/supplier-performance-detail/parameters',
    },
    component: <SupplierPerformanceDetailParamsForm />,
  },
]

export default gpDigitalReportRoutes
