import theme from '@ifca-root/react-component/src/assets/theme'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import DropdownBar from '@ifca-root/react-component/src/components/SpecialBar/DropdownBar'
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar'
import { useMediaQuery } from '@material-ui/core'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import Fuse from 'fuse.js'
import {
  useGetBankAccountQuery,
  useGetPaymentbyStatusLazyQuery,
  useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtNumStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { PaymentEntryList } from './PaymentList/PaymentEntryList'
import { PaymentPostingList } from './PaymentList/PaymentPostingList'
import { useFilterDialog } from '@ifca-root/react-component/src/components/SpecialBar/SearchFilter/SearchFilterV2'
import { Tune } from '@material-ui/icons'

export const PaymentMain = (props: any) => {
  let history = useHistory()
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  const { CompanyID, BankAccountID }: any = useParams()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  /* -------------------------------------------- */
  /*                   VARIABLES                  */
  /* -------------------------------------------- */

  const approvalStatusEnum = [
    { name: 'Draft', ID: 'ACTIVE' },
    { name: 'Submitted', ID: 'SUBMIT' },
    { name: 'Rejected', ID: 'REJECTED' },
    { name: 'Approved', ID: 'COMPLETED' },
  ]

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */

  const [postIDs, setPostIDs] = useState<Set<any>>(new Set([]))
  const [openExitConf, setOpenExitConf] = useState(null)
  const [listStatus, setListStatus] = useState<string>(
    getSearch?.option ?? 'ACTIVE'
  )

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  const [
    loadPayment,
    {
      loading: PaymentbyStatusLoading,
      data: { getPaymentbyStatus } = {
        getPaymentbyStatus: [],
      },
      fetchMore,
    },
  ] = useGetPaymentbyStatusLazyQuery({
    fetchPolicy: 'network-only',
    // variables: {
    //   CompanyID: CompanyID,
    //   StatusArr: [listStatus ?? 'ACTIVE'],
    // },
    onCompleted: ({ getPaymentbyStatus }) => {
      setOriginalListing(getPaymentbyStatus, v => {
        v['DocDateFormatted'] = formatDate(v.DocDate)
      })
    },
  })

  useEffect(() => {
    loadPayment({
      variables: {
        CompanyID: CompanyID,
        BankAccountID: BankAccountID,
        StatusArr: [listStatus],
      },
    })
  }, [listStatus])

  const loadData = () => {
    loadPayment({
      variables: {
        CompanyID: CompanyID,
        BankAccountID: BankAccountID,
        StatusArr: [listStatus],
      },
    })
  }

  const {
    loading: BankAccountLoading,
    error: BankAccountError,
    data: { getBankAccount } = { getBankAccount: [] },
  } = useGetBankAccountQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      BankAccountID: BankAccountID,
    },
  })

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
  })

  /* -------------------------------------------- */
  /*                    SEARCH                    */
  /* -------------------------------------------- */

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()

  // useEffect(() => {
  //   if (
  //     (getPaymentbyStatus && getPaymentbyStatus?.length > 0) ||
  //     getPaymentbyStatus?.length === 0
  //   ) {
  //     setOriginalListing(getPaymentbyStatus, v => {
  //       v['DocDateFormatted'] = formatDate(v.DocDate)
  //     })
  //   }
  // }, [getPaymentbyStatus])

  useEffect(() => {
    if (getSearch?.name && !!originalList) {
      const keys = [
        'DocNo',
        'Description',
        'DocDateFormatted',
        amtNumStr('PaymentItem.DocAmt'),
      ]
      const options = {
        shouldSort: true,
        threshold: 0.6,
        ignoreLocation: true,
        keys: keys,
      }

      const myFuse = new Fuse(originalList, options)
      const result = myFuse.search(getSearch?.name)
      const val = result?.map(x => x.item)
      if (val.length !== 0) {
        handleSearch(getSearch?.name + '', keys)
      }
    }
  }, [getSearch?.name, originalList])

  /* -------------------------------------------- */
  /*                   EXIT CONF                  */
  /* -------------------------------------------- */

  const hasChanges = () => {
    if (postIDs?.size === 0) return false
    else return true
  }

  const { openFilterDialog, handleFilterDate, filterDialog } = useFilterDialog({
    dateRange: {
      title: 'Doc Date',
      key: 'DocDate',
    },
  })

  return (
    <>
      {UserLoading && <Loading />}
      {PaymentbyStatusLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => {
          hasChanges() === true && listStatus === 'SUBMIT'
            ? setOpenExitConf(true)
            : history.push(`/cash-book/${CompanyID}/submenu/${BankAccountID}`)
          localStorage.removeItem(`searchFilter`)
        }} //<- back button action
        smTitle="Cash Book"
        title={user?.companyName}
        routeSegments={[
          { name: 'Home Payment' },
          { name: 'Payment', current: true },
        ]}
      />

      <DynamicSubHeader
        title={
          <>
            <span className="xsTitle" style={{ color: '#ff9800' }}>
              {getBankAccount[0]?.BankProfile?.Name}
            </span>
          </>
        }
        infoLine={
          <>
            <span className="xxTitle">{'Account No.'}&nbsp;:&nbsp;</span>
            <span className="xxTitle">{getBankAccount[0]?.AccountNo}</span>
          </>
        }
      />

      <div className="search-filter with-dropdown-filter with-subheader">
        <SearchBar
          borderRadius="4px"
          onChangeAction={e => {
            handleSearch(e?.target?.value, [
              'DocNo',
              'Description',
              amtNumStr('PaymentItem.DocAmt'),
              'DocDateFormatted',
            ])
            localStorage.setItem(
              'searchFilter',
              JSON.stringify({
                option: listStatus ?? 'ACTIVE',
                name: e?.target?.value,
              })
            )
          }}
          defaultValue={getSearch?.name ? getSearch?.name : ''}
          onCloseAction={() => {
            handleSearch('', [])
          }}
          isDefaultValue={!!getSearch}
          option={{
            icon: <Tune />,
            onClick: openFilterDialog,
          }}
        />
        {filterDialog}

        <DropdownBar
          options={approvalStatusEnum}
          setOptionID={setListStatus}
          borderRadius="4px"
          defaultOption={approvalStatusEnum
            ?.map(opt => opt?.ID)
            .indexOf(listStatus ?? 'ACTIVE')}
          searchFilter={getSearch}
        />
      </div>

      {(listStatus === 'ACTIVE' ||
        listStatus === 'COMPLETED' ||
        listStatus === 'REJECTED') && (
        <PaymentEntryList
          filteredList={filteredList}
          handleFilterDate={handleFilterDate}
          listLoading={PaymentbyStatusLoading}
          listStatus={listStatus}
          userList={getUsersByAccountAndSoftware}
          fetchMore={loadPayment}
          bankList={getBankAccount}
        />
      )}
      {listStatus === 'SUBMIT' && !PaymentbyStatusLoading && (
        <PaymentPostingList
          filteredList={filteredList}
          handleFilterDate={handleFilterDate}
          listLoading={PaymentbyStatusLoading}
          listStatus={listStatus}
          postIDs={postIDs}
          setPostIDs={setPostIDs}
          userList={getUsersByAccountAndSoftware}
          setOriginalListing={setOriginalListing}
          loadData={loadData}
        />
      )}

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/cash-book/${CompanyID}/submenu/${BankAccountID}`)
        }}
      />
    </>
  )
}
