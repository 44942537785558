import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import ReportViewer, {
  accountxReportUrl,
} from 'containers/DigitalReportModule/ReportViewer'
import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router'

export const APOverduePayableReport = () => {
  const history = useHistory()
  //const { CompanyID, BankAccountID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const accountID = user.accountID
  let location = useLocation()
  const { CompanyID }: any = useParams()
  const editData = location?.state as any

  const parameters = [
    {
      name: 'accountid',
      value: accountID,
    },
    {
      name: 'companyid',
      value: CompanyID,
    },
    {
      name: 'docDate',
      value: new Date(editData?.DocDate.setHours(0, 0, 0)).toISOString(),
    },
    {
      name: 'creditortypeid',
      value: editData?.CreditorTypeID,
    },
    {
      name: 'creditoraccountid',
      value: editData?.CreditorAccountID,
    },
  ]

  const generatedurl = (reportName, parameters) => {
    let reporturl = reportName

    parameters?.map((x, index) => {
      const symbol = index === 0 ? '?' : '&'
      if (x?.value !== undefined)
        reporturl = reporturl + symbol + x?.name + '=' + x?.value
    })
    return reporturl
  }

  console.log(generatedurl('OverduePayableReport', parameters), 'urlGenerate')

  return (
    <>
      <MainHeader
        onClick={() =>
          history.push(
            `/account-payable/${CompanyID}/digital-report/ap-overdue-payable/parameters`
          )
        }
        mainBtn="close"
        smTitle="Account Payable"
        title={user?.companyName}
        routeSegments={[
          { name: 'Submenu' },
          { name: 'Digital Report' },
          { name: 'Parameters' },
          { name: 'Overdue Payable Report', current: true },
        ]}
        rightRouteSegments={[{ name: 'Report', current: true }]}
      />
      <ContentWrapper style={{ maxHeight: '100%' }}>
        {ReportViewer({
          url: generatedurl('OverduePayableReport', parameters),
          host: accountxReportUrl,
          // host: contractReportUrl,
          excludedExportFormat: [],
        })}
      </ContentWrapper>
    </>
  )
}
