import { getAccessToken, setAccessToken } from 'AccessToken'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink, Observable } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { HttpLink } from 'apollo-link-http'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import { createUploadLink } from 'apollo-upload-client'
import jwtDecode from 'jwt-decode'

const cache = new InMemoryCache({})

let accountNodeUrl

switch (process.env.REACT_APP_LOCAL_DB) {
  case 'local':
    accountNodeUrl = 'http://localhost:10000/account-graphql'
    break
  case 'local-dev':
    accountNodeUrl = 'https://accountx-dev-api.ifca.io/account-graphql'
    break
  case 'azure':
    accountNodeUrl = 'https://accountx-dev-api.ifca.io/account-graphql'
    break
  case 'internal':
    accountNodeUrl = 'https://ifcahome-api.accountx.asia/account-graphql'
    break
  case 'staging':
    accountNodeUrl = 'https://accountx-api.ifca.io/account-graphql'
    break
  case 'training':
    accountNodeUrl = 'https://accountx-training-api.ifca.io/account-graphql'
    break
  case 'promenadeuat':
    accountNodeUrl =
      'https://accountxapi-promenade-uat.accountx.asia/account-graphql'
    break
  case 'promenade':
    accountNodeUrl =
      'https://accountxapi-promenade.accountx.asia/account-graphql'
    break
  default:
    accountNodeUrl = 'https://home-api.accountx.asia/account-graphql'
    break
}

export const accountNodeRefreshUrl =
  process.env.REACT_APP_LOCAL_DB === 'local'
    ? 'http://localhost:10000/refresh_token_account'
    : process.env.REACT_APP_LOCAL_DB === 'local-dev'
    ? 'https://accountx-dev-api.ifca.io/refresh_token_account'
    : process.env.REACT_APP_LOCAL_DB === 'azure'
    ? 'https://accountx-dev-api.ifca.io/refresh_token_account'
    : process.env.REACT_APP_LOCAL_DB === 'internal'
    ? 'https://ifcahome-api.accountx.asia/refresh_token_account'
    : process.env.REACT_APP_LOCAL_DB === 'staging'
    ? 'https://accountx-api.ifca.io/refresh_token_account'
    : process.env.REACT_APP_LOCAL_DB === 'training'
    ? 'https://accountx-training-api.ifca.io/refresh_token_account'
    : process.env.REACT_APP_LOCAL_DB === 'promenadeuat'
    ? 'https://accountxapi-promenade-uat.accountx.asia/refresh_token_account'
    : process.env.REACT_APP_LOCAL_DB === 'promenade'
    ? 'https://accountxapi-promenade.accountx.asia/refresh_token_account'
    : process.env.NODE_ENV === 'development'
    ? 'http://localhost:10000/refresh_token_account'
    : 'https://home-api.accountx.asia/refresh_token_account'

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle: any
      Promise.resolve(operation)
        .then(operation => {
          const accessToken = getAccessToken()
          if (accessToken) {
            operation.setContext({
              headers: {
                authorization: `bearer ${accessToken}`,
              },
            })
          } //accessToken is defined
        }) //then operation ends here
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          }) //handle ends here
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    })
)
const uploadLink = createUploadLink({
  uri: accountNodeUrl,
  credentials: 'include',
})

const httpLink = new HttpLink({
  uri: accountNodeUrl,
  credentials: 'include',
}) //new HttpLink ends here

export const AccountClient = new ApolloClient({
  link: ApolloLink.from([
    new TokenRefreshLink({
      accessTokenField: 'accessToken',
      isTokenValidOrUndefined: () => {
        const token = getAccessToken()

        if (!token) {
          return true
        }

        try {
          const { exp }: any = jwtDecode(token)
          if (Date.now() >= exp * 1000) {
            return false
          } else {
            return true
          }
        } catch {
          return false
        }
      },
      fetchAccessToken: () => {
        return fetch(accountNodeRefreshUrl, {
          method: 'POST',
          credentials: 'include',
        })
      },
      handleFetch: accessToken => {
        setAccessToken(accessToken)
      },
      handleResponse: () => {},
      handleError: err => {
        console.warn('Your refresh token is invalid. Try to relogin')
        console.error(err)
      },
    }) as any,
    onError(({ graphQLErrors, networkError }) => {
      // console.log(graphQLErrors)
      // console.log(networkError)
    }),
    requestLink,
    uploadLink,
    // httpLink,
  ]),
  cache,
})

// export const Uploadclient = new ApolloClient({
//   cache: cache,
//   link: ApolloLink.from([requestLink, uploadLink as any]),
// })
