import { Grid, List, ListItem, ListItemText } from '@material-ui/core';
import { default as React } from 'react';
import '../GRTN.scss';
import {
  amtStr,
  amtStr4Dec,
} from '../../../../helpers/StringNumberFunction/numFormatter';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

export const GRTNDetailItemContent = (props: any) => {
  const { listItem } = props;

  return (
    <>
      <div className="rm-padding table-wrap">
        <List className="core-list content-wrap full " disablePadding>
          {listItem?.map((el, index) => (
            <ListItem
              className="table-listItem "
              key={index}
              divider
              style={{ backgroundColor: 'white' }}
            >
              <ListItemText
                primary={
                  <Grid container justify="flex-start" spacing={1}>
                    <Grid item xs={12} className="fw-medium desc text-noflow">
                      <>
                        {index + 1}
                        <span style={{ paddingLeft: '10px' }}>
                          {el?.DOItem?.POItem?.Item?.Name}
                        </span>
                      </>
                    </Grid>
                  </Grid>
                }
                secondary={
                  <>
                    <Grid container justify="flex-start">
                      <Grid
                        item
                        xs={3}
                        className="fw-medium desc "
                        style={{ color: '#858585', paddingLeft: '13px' }}
                      >
                        {el?.DOItem?.POItem?.CostCentre?.Name}
                      </Grid>

                      <Grid
                        item
                        xs={3}
                        className="fw-medium desc "
                        style={{ color: '#858585', textAlign: 'right' }}
                      >
                        {`DO Qty`}
                      </Grid>

                      <Grid
                        item
                        xs={3}
                        className="fw-medium desc "
                        style={{ color: '#858585', textAlign: 'right' }}
                      >
                        {`Return Qty`}
                      </Grid>

                      <Grid
                        item
                        xs={3}
                        className="fw-medium desc "
                        style={{ color: '#858585', textAlign: 'right' }}
                      >
                        {`Return Amt`}
                      </Grid>
                    </Grid>

                    <Grid container justify="flex-start">
                      <Grid
                        item
                        xs={3}
                        className="fw-medium desc "
                        style={{ color: '#858585', paddingLeft: '13px' }}
                      >
                        {el?.DOItem?.POItem?.Item?.UOM?.Code}
                      </Grid>

                      <Grid
                        item
                        xs={3}
                        className="fw-medium desc "
                        style={{ color: '#858585', textAlign: 'right' }}
                      >
                        {`${amtStr4Dec(el?.OutstandingQty)}`}
                      </Grid>

                      <Grid
                        item
                        xs={3}
                        className="fw-medium desc "
                        style={{ color: '#858585', textAlign: 'right' }}
                      >
                        {`${amtStr4Dec(el?.ReturnedQty)}`}
                      </Grid>

                      <Grid
                        item
                        xs={3}
                        className="fw-medium desc "
                        style={{ color: '#858585', textAlign: 'right' }}
                      >
                        {`${amtStr(el?.ReturnedAmt)}`}
                      </Grid>
                    </Grid>

                    <Grid container justify="flex-start">
                      <Grid
                        item
                        xs={12}
                        className="fw-medium desc "
                        style={{ color: '#858585', paddingLeft: '13px' }}
                      >
                        {`${el?.DOItem?.POItem?.POHeader?.DocNo} | ${el?.DOItem?.POItem?.Remarks}`}
                      </Grid>
                    </Grid>

                    <Grid container justify="flex-start">
                      <Grid
                        item
                        xs={12}
                        className="fw-medium desc "
                        style={{ color: '#858585', paddingLeft: '13px' }}
                      >
                        {el?.Replacement === true ? (
                          <div className="xxTitle">
                            <VerifiedUserIcon
                              style={{
                                fontSize: '13px',
                                color: 'green',
                                verticalAlign: 'sub',
                              }}
                            />
                            {` Replacement`}
                          </div>
                        ) : null}
                      </Grid>
                    </Grid>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </div>
      <div
        className="footer-label"
        style={{
          background: '#bfd3ff',
        }}
      ></div>
    </>
  );
};
