import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import { List, ListItem, ListItemText } from '@material-ui/core'

import {
  useGetBankReconciliationMatchQuery,
  useGetBankReconciliationQuery,
} from 'generated/graphql'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React from 'react'
import { useHistory, useParams } from 'react-router'

export const BankReconciliationDetail = (props: any) => {
  let history = useHistory()
  const { CompanyID, BankAccountID, BankReconciliationID }: any = useParams()
  const [open, setOpen] = React.useState(false)
  const { mode }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  // Query for Bank Reconciliation

  const {
    loading: bankReconciliationLoading,
    error: bankReconciliationError,
    data: { getBankReconciliation } = { getBankReconciliation: [] },
  } = useGetBankReconciliationQuery({
    fetchPolicy: 'network-only',
    variables: { BankReconciliationID: BankReconciliationID },
  })

  const {
    loading: ReconciliationMatchLoading,
    error: ReconciliationMatchError,
    data: { getBankReconciliationMatch } = { getBankReconciliationMatch: [] },
  } = useGetBankReconciliationMatchQuery({
    fetchPolicy: 'network-only',
    variables: {
      BankReconciliationID: BankReconciliationID,
    },
    onCompleted: ({ getBankReconciliationMatch }) => {},
  })

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */

  return (
    <>
      {bankReconciliationLoading && <Loading />}
      {ReconciliationMatchLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(
            `/cash-book/${CompanyID}/submenu/${BankAccountID}/bank-reconciliation`
          )
        }
        smTitle={'Cash Book'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Cash Book' },
          { name: 'Bank Reconciliation' },
          { name: 'Bank Reconciliation' },
          { name: 'Bank Reconciliation Details', current: true },
        ]}
      />

      <ContentWrapper footer>
        <CardContents
          section={{ header: { title: 'Bank Reconciliation Info' } }}
        >
          <div className="content-wrap left">
            <div className="desc" style={{ color: '#afafaf' }}>
              DocumentDate
            </div>
            <div className="" style={{ fontSize: '12px', fontWeight: 500 }}>
              {formatDate(getBankReconciliation[0]?.DocumentDate)}
            </div>
          </div>

          <div className="content-wrap right">
            <div className="desc" style={{ color: '#afafaf' }}>
              Bank Statement Balance
            </div>
            <div className="" style={{ fontSize: '12px', fontWeight: 500 }}>
              <span style={{ fontSize: '12px', fontWeight: 500 }}>
                {amtStr(getBankReconciliation[0]?.StatementBalance)}
              </span>
            </div>
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ color: '#afafaf' }}>
              Description
            </div>
            <div className="" style={{ fontSize: '12px', fontWeight: 500 }}>
              {getBankReconciliation[0]?.Remarks}
            </div>
          </div>

          <div className="content-wrap left">
            <div className="desc" style={{ color: '#afafaf' }}>
              Match with Excel Template
            </div>
            <div className="" style={{ fontSize: '12px', fontWeight: 500 }}>
              {getBankReconciliation[0]?.IsExcelMatch === true
                ? `True`
                : `False`}
            </div>
          </div>
        </CardContents>

        {getBankReconciliation[0]?.IsExcelMatch === true && (
          <CardContents section={{ header: { title: 'Bank Statement' } }}>
            <List style={{ width: '100%', paddingBottom: '0px' }}>
              {getBankReconciliationMatch[0]?.BankReconciliationItem?.map(
                (v, index) => {
                  return (
                    <ListItem
                      key={index}
                      style={{ marginLeft: '-5px', marginTop: '-15px' }}
                    >
                      <ListItemText
                        primary={
                          <>
                            <span style={{ display: 'flex' }}>
                              <span className="mdLabel date-width">
                                {formatDate(v.DocumentDate)}
                              </span>
                              <span className="xsTitle flex-space">
                                {v.DocNo}
                              </span>
                              <span className="xxTitle">
                                <span
                                  className=""
                                  style={{
                                    color: v.DocAmt > 0 ? 'orange' : 'red',
                                  }}
                                >
                                  {v.DocAmt > 0
                                    ? amtStr(v.DocAmt)
                                    : `(${amtStr(v.DocAmt - v.DocAmt * 2)})`}
                                </span>
                              </span>
                            </span>
                          </>
                        }
                        secondary={
                          <span className="desc medium">{v.Remarks}</span>
                        }
                      />
                    </ListItem>
                  )
                }
              )}
            </List>
          </CardContents>
        )}

        <CardContents section={{ header: { title: 'Cash Book Ledger' } }}>
          <List style={{ width: '100%', paddingBottom: '0px' }}>
            {getBankReconciliationMatch[0]?.Ledger?.map((v, index) => {
              return (
                <ListItem
                  key={index}
                  style={{ marginLeft: '-5px', marginTop: '-15px' }}
                >
                  <ListItemText
                    primary={
                      <>
                        <span style={{ display: 'flex' }}>
                          <span className="mdLabel date-width">
                            {formatDate(v.DocDate)}
                          </span>
                          <span className="xsTitle flex-space">{v.DocNo}</span>
                          <span className="xxTitle">
                            <span
                              className=""
                              style={{
                                color:
                                  v?.RefTable === 'CB_Receipt'
                                    ? 'orange'
                                    : 'red',
                              }}
                            >
                              {v?.RefTable === 'CB_Receipt'
                                ? amtStr(v.DocAmt)
                                : `(${amtStr(v.DocAmt)})`}
                            </span>
                          </span>
                        </span>
                      </>
                    }
                    secondary={
                      <div style={{ display: 'grid' }}>
                        <span className="desc medium">
                          {v?.RefNo} {v?.PayeeName ?? v?.ReceivedFrom}
                        </span>
                        <span className="desc medium">{v?.Description}</span>
                      </div>
                    }
                  />
                </ListItem>
              )
            })}
          </List>
        </CardContents>
      </ContentWrapper>
    </>
  )
}
