import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import StandardTabs from '@ifca-root/react-component/src/components/Tabs/StandardTabs'
import AppContext from 'containers/App/Store/AppContext'
import {
  RecordStatus,
  useGetCompanyQuery,
  useGetCreditorAccountCompanyAssignmentQuery,
  useGetCreditorAccountQuery,
} from 'generated/graphql'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { CreditorAvailableCompanyForm } from './CreditorAvailableCompanyForm'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import { useMediaQuery } from '@material-ui/core'
import theme from '@ifca-root/react-component/src/assets/theme'

export const CreditorProfileAssignment = (props: any) => {
  let history = useHistory()
  const { globalState, dispatch }: any = useContext(AppContext as any)
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [value, setValue] = useState(0)
  const [availableCompany, setAvailableCompany] = useState([])
  const [assignedCompany, setAssignedCompany] = useState([])
  const { creditorType, CreditorAccountID }: any = useParams()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  //API SECTION
  const {
    loading: CreditorAccountLoading,
    error: CreditorAccountError,
    data: { getCreditorAccount } = { getCreditorAccount: [] },
  } = useGetCreditorAccountQuery({
    fetchPolicy: 'network-only',
    variables: { CreditorAccountID },
  })

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyQuery({
    fetchPolicy: 'network-only',
    variables: {
      RecordStatus: RecordStatus.Active,
    },
  })

  const {
    loading: CreditorAccountCompanyAssignmentLoading,
    error: CreditorAccountCompanyAssignmentError,
    data: { getCreditorAccountCompanyAssignment } = {
      getCreditorAccountCompanyAssignment: {} as any,
    },
  } = useGetCreditorAccountCompanyAssignmentQuery({
    fetchPolicy: 'network-only',
  })

  //API SECTION
  useEffect(() => {
    if (getCreditorAccountCompanyAssignment) {
      const assignedCompany =
        getCreditorAccountCompanyAssignment?.length > 0
          ? getCreditorAccountCompanyAssignment
              ?.filter(x => x?.CreditorAccountID === CreditorAccountID)
              ?.map(v => v.CompanyID)
          : []
      const filteredCompany = getCompany?.filter(v =>
        assignedCompany?.includes(v?.CompanyID)
      )
      setAssignedCompany(filteredCompany)
    }
    if (getCompany && getCompany?.length > 0) {
      const assignedEntity =
        getCreditorAccountCompanyAssignment?.length > 0
          ? getCreditorAccountCompanyAssignment
              ?.filter(x => x?.CreditorAccountID === CreditorAccountID)
              ?.map(v => v.CompanyID)
          : []

      const filteredCompany = getCompany?.filter(
        v => !assignedEntity?.includes(v?.CompanyID)
      )

      setAvailableCompany(filteredCompany)
    }
  }, [getCreditorAccountCompanyAssignment, getCompany])

  return (
    <>
      {CompanyLoading && <Loading />}
      {CreditorAccountLoading && <Loading />}
      {CreditorAccountCompanyAssignmentLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() =>
          history.push(
            `/account-payable/general-setting/creditor-profile/${creditorType}`
          )
        }
        smTitle={'Account Payable'}
        title={user?.accountName}
        routeSegments={[
          { name: 'General Settings' },
          { name: 'Creditor Profile' },
          { name: 'Creditor Company Assignment', current: true },
        ]}
        rightRouteSegments={[{ name: 'Assign', current: true }]}
      />

      <div>
        <DynamicSubHeader
          style={{
            top: isDesktop ? 'auto' : null,
            position: isDesktop ? 'relative' : null,
          }}
          title={
            <>
              <span className="xsTitle">
                {getCreditorAccount[0]?.CompanyName}
              </span>
            </>
          }
        />
      </div>

      <StandardTabs
        value={value}
        onChange={handleChange}
        responsive
        fullWidth
        subHeader
        style={{
          boxShadow: 'none',
          ...(isDesktop
            ? {
                marginTop: '99px',
              }
            : {
                marginTop: '89px',
              }),
        }}
        tabs={[
          {
            label: `Available(${availableCompany?.length})`,
            content: (
              <CreditorAvailableCompanyForm
                company={availableCompany}
                pageMode={'available'}
              />
            ),
          },
          {
            label: `Assigned(${assignedCompany?.length})`,
            content: (
              <CreditorAvailableCompanyForm
                company={assignedCompany}
                pageMode={'assigned'}
              />
            ),
          },
        ]}
      />
    </>
  )
}
