import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { TextField } from '@material-ui/core'
import { SubdirectoryArrowRightOutlined } from '@material-ui/icons'
import CreateIcon from '@material-ui/icons/Create'
import { CopyrightFooter } from 'components/Footer/Copyright'
import {
  useGetCoaStructureQuery,
  useGetCompanyNameQuery,
  useGetMasterCoaQuery,
} from 'generated/graphql'
import React, { useEffect } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation, useParams } from 'react-router-dom'

interface RecurringJournalProps {
  StructureItem: any
}

export const AccCodeStructureDetail = (props: any) => {
  const { mode } = props
  let location = useLocation()
  let history = useHistory()
  const { CompanyID }: any = useParams()
  let user = JSON.parse(localStorage.getItem('loggedInUser'))

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    watch,
    reset,
    setError,
    getValues,
    formState,
  } = useForm<RecurringJournalProps>({
    defaultValues: {},
    mode: 'all',
  })

  const { fields, append, remove, insert, prepend } = useFieldArray({
    control,
    name: 'StructureItem',
  })

  const {
    loading: companyLoading,
    error: companyError,
    data: { getCompany } = {
      getCompany: [],
    },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
  })

  const {
    loading: coaStructureLoading,
    error: coaStructureError,
    data: { getCOAStructure } = {
      getCOAStructure: [],
    },
  } = useGetCoaStructureQuery({
    fetchPolicy: 'network-only',
    variables: {
      AccountID: user?.accountID,
    },
  })

  const {
    loading: materCoaLoading,
    error: materCoaError,
    data: { getMasterCOA } = { getMasterCOA: [] },
  } = useGetMasterCoaQuery({
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (getCOAStructure?.length !== 0) {
      let arr = []
      for (const v of getCOAStructure) {
        arr.push({
          numberLength: v?.CodeLength,
        })
      }
      append(arr)
    }
  }, [getCOAStructure])

  //   history.push(`/general-ledger/general-setting/acc-code-structure/add`)

  //   const defaultStructureFuc = data => {
  //     let arr = []
  //     for (const v of data) {
  //       arr.push({
  //         numberLength: v?.CodeLength,
  //       })
  //     }
  //     append(arr)
  //     console.log('This is fields', data)
  //   }

  console.log('getMasterCOA', getMasterCOA)
  return (
    <>
      {coaStructureLoading && <Loading />}
      {materCoaLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/general-ledger/general-setting`)}
        smTitle={'General Ledger'}
        title={user?.accountName}
        routeSegments={[
          { name: 'General Ledger' },
          { name: 'Account Code Structure', current: true },
        ]}
        rightRouteSegments={[{ name: 'View', current: true }]}
      />

      <ContentWrapper float footer>
        <CardContents
          section={{
            header: {
              title: 'Structure',
              onClickAction: () => {
                history.push({
                  pathname: `/general-ledger/general-setting/acc-code-structure/edit`,
                  state: getCOAStructure,
                })
              },
              icon:
                getMasterCOA?.length > 0
                  ? null
                  : !materCoaLoading && <CreateIcon />,
            },
          }}
        >
          <div className="rm-padding table-wrap ">
            {fields?.map((v, index) => {
              const StructureItem = `StructureItem[${index}]`
              return (
                <>
                  <div className="table-display">
                    <div
                      className="fw-medium xsTitle index-padding"
                      style={{
                        paddingLeft: `${
                          index === 0 ? 16 : (index + index) * 10
                        }px`,
                        paddingRight: `${10}px`,
                      }}
                    >
                      {index === 0 ? null : (
                        <span>
                          <SubdirectoryArrowRightOutlined
                            fontSize="inherit"
                            style={{
                              fontSize: '18px',
                              marginBottom: '-4px',
                              marginTop: '-5px',
                            }}
                          />
                        </span>
                      )}
                      <span
                        style={{
                          paddingLeft: `5px`,
                        }}
                      >
                        Level {index + 1}
                      </span>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        paddingLeft: `${
                          index === fields.length - 1
                            ? 0
                            : (fields.length - 1 - index) * 20
                        }px`,
                        paddingRight: `15px`,
                      }}
                    >
                      <Controller
                        ref={register()}
                        name={`${StructureItem}.numberLength`}
                        control={control}
                        defaultValue={v.numberLength || 0}
                        onChangeName="onValueChange"
                        onChange={([{ value, floatValue }]) => {
                          return value
                        }}
                        as={
                          <NumberFormat
                            key={v?.id}
                            allowNegative={true}
                            customInput={TextField}
                            className="qty-input-new"
                            label="Length of Number"
                            //decimalScale={2}
                            fixedDecimalScale
                            variant="outlined"
                            thousandSeparator
                            isNumericString
                            InputProps={{
                              readOnly: true,
                            }}
                            helperText={
                              errors?.StructureItem &&
                              errors?.StructureItem[index]?.numberLength
                                ?.message
                            }
                            error={
                              errors?.StructureItem &&
                              errors?.StructureItem[index]?.numberLength
                            }
                          />
                        }
                      />
                    </div>
                  </div>
                </>
              )
            })}
          </div>
        </CardContents>
      </ContentWrapper>
      {/* </form> */}
    </>
  )
}
