import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable'
import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'
import { Pagination } from '@material-ui/lab'
import { Group, drawDOM, exportPDF } from '@progress/kendo-drawing'
import ShareDialog from 'components/Dialog/ShareDialog'
import saveAs from 'file-saver'
import {
  useGetBankAccountQuery,
  useGetCompanyQuery,
  useGetCostCentreQuery,
  useGetShareAttachmentMutation,
  useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql'
import { useMobilePDFZoom } from 'helpers/Hooks/useMobilePDFZoom'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import './PDFStyle.scss'
import CBBodyTemplate from './PDFTemplate/CBBodyTemplate'
import { useCBQueries } from './Query/useCBQueries'

export const PDFPreviewCBV2 = (props: any) => {
  const { accountType, docType } = props
  let history = useHistory()
  const { styles } = useMobilePDFZoom()
  const {
    CompanyID,
    PaymentID,
    ReceiptID,
    BankTransferID,
    BankAccountID,
  }: any = useParams()

  const {
    loading: companyLoading,
    error: companyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const {
    loading: CostCentreLoading,
    error: CostCentreError,
    data: { getCostCentre } = { getCostCentre: [] },
  } = useGetCostCentreQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
    },
  })

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
  })

  const {
    loading: BankLoading,
    error: BankError,
    data: { getBankAccount } = { getBankAccount: [] },
  } = useGetBankAccountQuery({
    fetchPolicy: 'network-only',
    variables: {
      BankAccountID,
    },
  })

  const [width] = useMediaQuery()

  let fontSize
  if (width < 769) {
    fontSize = 7
  } else {
    fontSize = 10
  }

  let fontStyle
  fontStyle = {
    fontSize: fontSize,
    textAlign: 'center',
  }

  let routeSegments, title, smallTitle, name, DocumentID, itemName

  smallTitle = smTitle.CASH_BOOK
  name = 'DocNo'

  switch (docType) {
    case 'payment':
      DocumentID = PaymentID
      routeSegments = 'Payment'
      title = 'CASH PAYMENT VOUCHER'
      name = 'DocNo'
      itemName = 'PaymentItem'
      break
    case 'receipt':
      DocumentID = ReceiptID
      routeSegments = 'Receipt'
      title = 'CASH RECEIPT/INVOICE'
      name = 'DocNo'
      itemName = 'ReceiptItem'
      break
    case 'bank-transfer':
      DocumentID = BankTransferID
      routeSegments = 'Bank Transfer'
      title = 'BANK TRANSFER VOUCHER'
      name = 'DocNo'
      itemName = 'ToBankAccountDetail'
      break
  }

  const { loading, listData, primaryKey } = useCBQueries({
    docType,
    CompanyID,
    BankAccountID,
    TransactionID: DocumentID,
  })

  const previewDetail: any = listData?.filter(
    v => v?.[primaryKey] === DocumentID
  )[0]

  // ========== Page definition ==========

  const [page, setCurrentPage] = useState<number>(0)

  // ========== Threshold definition ==========
  let totalItems

  if (docType === 'bank-transfer') totalItems = 1
  else totalItems = previewDetail?.[itemName]?.length

  let threshold
  if (totalItems > 15) {
    threshold = 15
  } else {
    threshold = 0
  }

  useEffect(() => {
    if (!!previewDetail) separateThreshold(previewDetail)
  }, [previewDetail])

  // ========== Remap Data for item list ==========
  const [remapData, setRemapData] = useState(null)

  const separateThreshold = input => {
    if (docType !== 'bank-transfer') {
      let temp = []
      let noChange = []
      let tempItem = []

      previewDetail?.[itemName]?.map((v, i) => {
        tempItem.push({ ...v, index: i + 1 })
        if ((i + 1) % threshold === 0) {
          temp.push({ ...input, [itemName]: tempItem })
          tempItem = []
        } else if (previewDetail?.[itemName]?.length === i + 1) {
          temp.push({ ...input, [itemName]: tempItem })
        }
      })
      noChange.push(temp)
      temp = []
      return setRemapData(noChange.flat())
    }
  }

  /// ========== kendo-drawing PDF Download ===========
  const dataURItoFile = (dataurl, filename) => {
    dataurl.replace('data:application/pdf;base64,', '')
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }

  const onPrint = () => {
    let element = document.getElementById('pdf-data-print')

    drawDOM(element, {
      paperSize: 'A4',
      margin: {
        bottom: width < 769 ? '0.5cm' : '1cm',
        top: width < 769 ? '0.5cm' : '1cm',
        left: width < 769 ? '0.25cm' : '0.5cm',
        right: width < 769 ? '0.25cm' : '0.5cm',
      },
      scale: width < 769 ? 1.25 : 0.85,
      forcePageBreak: '.page-break',
    })
      .then((group: Group) => exportPDF(group))
      .then(dataURI => {
        let fileObject = dataURItoFile(
          dataURI,
          `${title} (${previewDetail?.[name]}).pdf`
        )
        saveAs(fileObject, `${title} (${previewDetail?.[name]}).pdf`)
      })
  }
  const [openShareDialog, setOpenShareDialog] = useState(false)
  const [openSendEmail, setOpenSendEmail] = useState(false)

  const [
    getShareAttachments,
    {
      data: { getShareAttachment } = { getShareAttachment: {} as any },
      called: getShareAttachmentCalled,
      loading: ShareAttachmentLoading,
    },
  ] = useGetShareAttachmentMutation({
    onCompleted: () => {
      setOpenShareDialog(true)
    },
  })

  const onSubmit = blob => {
    getShareAttachments({
      variables: {
        file: blob,
        CompanyID: CompanyID,
      },
    })
  }

  const [labelState, setLabelState] = useState<string>()
  const [bankState, setBankState] = useState<string>()

  useEffect(() => {
    setLabelState(getCompany[0]?.Name)
  }, [getCompany])

  useEffect(() => {
    setBankState(getBankAccount[0]?.BankProfile?.Name)
  }, [getBankAccount])

  const pdfToPrint = () => {
    return (
      <>
        {/* ========================================= */}
        {/* DOCS THAT IS SAVED AS PDF WHICH IS HIDDEN */}
        {/* ========================================= */}

        <div style={{ position: 'fixed', left: `-1000px` }}>
          <div id={'pdf-data-print'}>
            {docType !== 'bank-transfer' ? (
              remapData &&
              remapData?.map((v, index) => {
                return (
                  <CBBodyTemplate
                    fontStyle={fontStyle}
                    title={title}
                    docType={docType}
                    previewDetail={previewDetail}
                    getBankAccount={getBankAccount}
                    getUsersByAccountAndSoftware={getUsersByAccountAndSoftware}
                    listData={listData}
                    remapData={remapData}
                    page={index}
                    pageLength={remapData?.length}
                    width={width}
                    currencyCode={getCompany[0]?.Currency?.code}
                  />
                )
              })
            ) : (
              <CBBodyTemplate
                fontStyle={fontStyle}
                title={title}
                docType={docType}
                previewDetail={previewDetail}
                getBankAccount={getBankAccount}
                getUsersByAccountAndSoftware={getUsersByAccountAndSoftware}
                listData={listData}
                remapData={remapData}
                page={page}
                pageLength={remapData?.length}
                width={width}
                currencyCode={getCompany[0]?.Currency?.code}
              />
            )}
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      {CostCentreLoading && <Loading />}
      {loading && <Loading />}
      {UserLoading && <Loading />}
      {companyLoading && <Loading />}
      {BankLoading && <Loading />}
      {ShareAttachmentLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() => {
          if (previewDetail?.ApprovalStatus === 'COMPLETED') {
            history.push({
              pathname: `/${accountType}/${CompanyID}/submenu/${BankAccountID}/${docType}/${DocumentID}/detail`,
              state: previewDetail,
            })
          } else {
            history.push({
              pathname: `/${accountType}/${CompanyID}/submenu/${BankAccountID}/${docType}/`,
              state: previewDetail,
            })
          }
        }}
        smTitle={smallTitle}
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: `${smTitle}` },
          { name: `${routeSegments}` },
          { name: `${routeSegments}`, current: true },
        ]}
        rightRouteSegments={[
          {
            name: 'Print',
            current: true,
          },
        ]}
      />

      <ContentWrapper footer>
        {/* ============================== */}
        {/* DISPLAYED IN HTML TO BE VIEWED */}
        {/* ============================== */}

        <div style={{ height: window.screen.height }}>
          <div style={styles.supercontainer}>
            <div style={styles.container}>
              <CBBodyTemplate
                fontStyle={fontStyle}
                title={title}
                docType={docType}
                previewDetail={previewDetail}
                getBankAccount={getBankAccount}
                getUsersByAccountAndSoftware={getUsersByAccountAndSoftware}
                listData={listData}
                remapData={remapData}
                page={page}
                pageLength={remapData?.length}
                width={width}
                currencyCode={getCompany[0]?.Currency?.code}
              />
            </div>
          </div>
        </div>

        <div
          className={
            width < 769 ? 'pagination-dis-mobile' : 'pagination-dis-desktop'
          }
        >
          <Pagination
            count={remapData?.length}
            shape="rounded"
            page={page + 1}
            onChange={(e, page) => {
              setCurrentPage(page - 1)
            }}
          />
        </div>
      </ContentWrapper>

      <div style={{ width: 1980 }}>{pdfToPrint()}</div>

      {ShareDialog({
        shareTitle: `Cash Book - ${labelState}(${bankState})`,
        title: `Share`,
        URL: getShareAttachment?.fileURL,
        setSimple: setOpenShareDialog,
        simple: openShareDialog,
        emailShare: openSendEmail,
        setSimpleEmail: setOpenSendEmail,
      })}

      <Footer
        options={[
          // {
          //   name: 'Share',
          //   onClick: async () => {
          //     let temp = await document.getElementById('pdf-data-print')

          //     htmlToImage
          //       .toBlob(temp, { style: { background: '#fff' } })
          //       .then(value => {
          //         onSubmit(value)
          //       })
          //       .catch(error => console.error(error, 'Something went wrong'))
          //   },
          //   // disabled:
          //   //   graphData.every(emptyData) == undefined ||
          //   //   graphData.every(emptyData) ||
          //   //   handlePermDisabled({
          //   //     permEnum: ContPermission.ProjectCostingProjectCostingShare,
          //   //   }),
          //   color: 'primary',
          //   props: {
          //     type: 'submit',
          //   },

          //   startIcon: <ShareIcon style={{ fontSize: '18px' }} />,
          // },
          {
            name: 'Download',
            color: 'primary',
            onClick: () => {
              onPrint()
            },
          },
        ]}
      />
    </>
  )
}

export default PDFPreviewCBV2
