import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type ApAdvanceDeleteInput = {
  AdvanceID: Scalars['String'];
};

export type ApAdvanceInput = {
  AdvanceID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DocDate: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  PaymentMethodID: Scalars['String'];
  BankAccountID: Scalars['String'];
  TransactionDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  IsAutoDocNo?: Maybe<Scalars['Boolean']>;
  DocNo?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type ApAdvanceRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type ApAgingEntity = AuditEntity & {
  __typename?: 'APAgingEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  AgingID: Scalars['String'];
  CompanyID: Scalars['String'];
  Period1: Scalars['Float'];
  Period2: Scalars['Float'];
  Period3: Scalars['Float'];
  Period4: Scalars['Float'];
  Period5: Scalars['Float'];
  Period6: Scalars['Float'];
  AccountType?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type ApAllocationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  AllocationID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DebitRefTable?: Maybe<Scalars['String']>;
  DebitID?: Maybe<Scalars['String']>;
  CreditRefTable?: Maybe<Scalars['String']>;
  CreditID?: Maybe<Scalars['String']>;
  CreditOrDebit?: Maybe<Scalars['String']>;
  AllocationAmt: Scalars['Float'];
  CreditorAccountID?: Maybe<Scalars['String']>;
};

export type ApAllocationItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  AllocationID?: Maybe<Scalars['String']>;
  AllocationItemID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DebitItemRefTable?: Maybe<Scalars['String']>;
  DebitItemID?: Maybe<Scalars['String']>;
  CreditItemRefTable?: Maybe<Scalars['String']>;
  CreditItemID?: Maybe<Scalars['String']>;
  CreditOrDebit?: Maybe<Scalars['String']>;
  AllocationItemAmt?: Maybe<Scalars['Float']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
};

export type ApBatchPaymentDeleteInput = {
  BatchPaymentID: Scalars['String'];
};

export type ApBatchPaymentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  CompanyID: Scalars['String'];
  PaymentMethodID: Scalars['String'];
  BatchPaymentID?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type ApCreditNoteDeleteInput = {
  CreditNoteID: Scalars['String'];
};

export type ApCreditNoteInput = {
  CreditNoteID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DocDate: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  RefRecordID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  TransactionDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_self_billed?: Maybe<Scalars['Boolean']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type ApCreditNoteItemDeleteInput = {
  CreditNoteItemID: Scalars['String'];
};

export type ApCreditNoteItemInput = {
  CreditNoteItemID?: Maybe<Scalars['String']>;
  CreditNoteID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  UOMID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
};

export type ApCreditNoteRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type ApCreditorCreditNoteDeleteInput = {
  CreditorCreditNoteID: Scalars['String'];
};

export type ApCreditorCreditNoteInput = {
  CompanyID: Scalars['String'];
  CreditorCreditNoteID?: Maybe<Scalars['String']>;
  RefRecordID?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  TransactionDate: Scalars['String'];
  RefNo?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  CreditorAccountID: Scalars['String'];
  RefTable?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_self_billed?: Maybe<Scalars['Boolean']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type ApCreditorCreditNoteItemDeleteInput = {
  CreditorCreditNoteItemID: Scalars['String'];
};

export type ApCreditorCreditNoteItemInput = {
  CreditorCreditNoteItemID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  CreditorCreditNoteID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  GRTNItemID?: Maybe<Scalars['String']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  UOMID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
};

export type ApCreditorCreditNoteRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type ApCreditorDebitNoteDeleteInput = {
  DebitNoteID: Scalars['String'];
};

export type ApCreditorDebitNoteInput = {
  CreditorDebitNoteID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DocDate: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  RefNo: Scalars['String'];
  TransactionDate?: Maybe<Scalars['String']>;
  CreditTerm?: Maybe<Scalars['Float']>;
  DueDate?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  DocNo?: Maybe<Scalars['String']>;
  IsAutoDocNo?: Maybe<Scalars['Boolean']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
  einvoice_self_billed?: Maybe<Scalars['Boolean']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type ApCreditorDebitNoteItemDeleteInput = {
  CreditorDebitNoteItemID: Scalars['String'];
};

export type ApCreditorDebitNoteItemInput = {
  CreditorDebitNoteItemID?: Maybe<Scalars['String']>;
  CreditorDebitNoteID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  UOMID?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
};

export type ApCreditorDebitNoteRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type ApDebitNoteDeleteInput = {
  DebitNoteID: Scalars['String'];
};

export type ApDebitNoteInput = {
  DebitNoteID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DocDate: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  RefNo: Scalars['String'];
  TransactionDate?: Maybe<Scalars['String']>;
  CreditTerm?: Maybe<Scalars['Float']>;
  DueDate?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  DocNo?: Maybe<Scalars['String']>;
  IsAutoDocNo?: Maybe<Scalars['Boolean']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
  einvoice_self_billed?: Maybe<Scalars['Boolean']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type ApDebitNoteItemDeleteInput = {
  DebitNoteItemID: Scalars['String'];
};

export type ApDebitNoteItemInput = {
  DebitNoteItemID?: Maybe<Scalars['String']>;
  DebitNoteID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  UOMID?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
};

export type ApDebitNoteRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type ApFavoriteInvoiceDeleteInput = {
  FavoriteInvoiceID: Scalars['String'];
};

export type ApFavoriteInvoiceInput = {
  FavoriteInvoiceID?: Maybe<Scalars['String']>;
  InvoiceID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  CreditorAccountID: Scalars['String'];
  CreditTerm?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocDate?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type ApFavoriteInvoiceItemDeleteInput = {
  FavoriteInvoiceItemID: Scalars['String'];
};

export type ApFavoriteInvoiceItemInput = {
  FavoriteInvoiceItemID?: Maybe<Scalars['String']>;
  FavoriteInvoiceID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  Sequence?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
};

export type ApFavoritePaymentDeleteInput = {
  FavoritePaymentID: Scalars['String'];
};

export type ApFavoritePaymentInput = {
  UserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  PaymentMethodID?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type ApglExportEntity = AuditEntity & {
  __typename?: 'APGLExportEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  GLExportID: Scalars['String'];
  BatchNo?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  SourceID?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  GLDate: Scalars['String'];
  TransactionDate: Scalars['String'];
  AccountCode?: Maybe<Scalars['String']>;
  Debit?: Maybe<Scalars['Float']>;
  Credit?: Maybe<Scalars['Float']>;
};

export type ApInvoiceDeleteInput = {
  InvoiceID: Scalars['String'];
};

export type ApInvoiceInput = {
  InvoiceID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  CreditTerm: Scalars['Float'];
  DocDate: Scalars['String'];
  TransactionDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  Description?: Maybe<Scalars['String']>;
  Discount?: Maybe<Scalars['Float']>;
  DiscountAmt?: Maybe<Scalars['Float']>;
  OtherCharges?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_self_billed?: Maybe<Scalars['Boolean']>;
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type ApInvoiceItemDeleteInput = {
  APInvoiceItemID: Scalars['String'];
};

export type ApInvoiceItemInput = {
  InvoiceItemID?: Maybe<Scalars['String']>;
  InvoiceID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  DOItemID?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
};

export type ApInvoiceRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type ApPaymentDeleteInput = {
  PaymentID: Scalars['String'];
};

export type ApPaymentInput = {
  PaymentID?: Maybe<Scalars['String']>;
  AdvanceID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  PaymentMethodID: Scalars['String'];
  DocDate: Scalars['String'];
  TransactionDate: Scalars['String'];
  RefNo?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  IsAutoDocNo?: Maybe<Scalars['Boolean']>;
  DocNo?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type ApPaymentRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type ApRefundDeleteInput = {
  RefundID: Scalars['String'];
};

export type ApRefundInput = {
  RefundID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  PaymentMethodID: Scalars['String'];
  DocDate: Scalars['String'];
  TransactionDate: Scalars['String'];
  RefNo?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  IsAutoDocNo?: Maybe<Scalars['Boolean']>;
  DocNo?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type ApRefundRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type ApSelfBilledDeleteInput = {
  SelfBilledID: Scalars['String'];
};

export type ApSelfBilledInput = {
  SelfBilledID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DocDate: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  RefNo: Scalars['String'];
  TransactionDate?: Maybe<Scalars['String']>;
  CreditTerm?: Maybe<Scalars['Float']>;
  DueDate?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  DocNo?: Maybe<Scalars['String']>;
  IsAutoDocNo?: Maybe<Scalars['Boolean']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  is_double_entry?: Maybe<Scalars['Boolean']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_self_billed?: Maybe<Scalars['Boolean']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
  einvoice_trx_type?: Maybe<EInvoiceTrxType>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type ApSelfBilledItemDeleteInput = {
  SelfBilledItemID: Scalars['String'];
};

export type ApSelfBilledItemInput = {
  SelfBilledItemID?: Maybe<Scalars['String']>;
  SelfBilledID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  UOMID?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
};

export type ApSelfBilledRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type ArAdvanceDeleteInput = {
  AdvanceID: Scalars['String'];
};

export type ArAdvanceInput = {
  AdvanceID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DocDate: Scalars['String'];
  TransactionDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  DebtorAccountID: Scalars['String'];
  PaymentMethodID: Scalars['String'];
  CreditCardID?: Maybe<Scalars['String']>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  TaxSchemeID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Amount: Scalars['Float'];
  TaxAmt: Scalars['Float'];
  TaxRate: Scalars['Float'];
  TaxInvoiceNo?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  BankAccountID: Scalars['String'];
  Attachment?: Maybe<Array<Scalars['Upload']>>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type ArAdvanceRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type ArAgingEntity = AuditEntity & {
  __typename?: 'ARAgingEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  AgingID: Scalars['String'];
  CompanyID: Scalars['String'];
  Period1: Scalars['Float'];
  Period2: Scalars['Float'];
  Period3: Scalars['Float'];
  Period4: Scalars['Float'];
  Period5: Scalars['Float'];
  Period6: Scalars['Float'];
  AccountType?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type ArAllocationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  AllocationID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DebitRefTable?: Maybe<Scalars['String']>;
  DebitID?: Maybe<Scalars['String']>;
  CreditRefTable?: Maybe<Scalars['String']>;
  CreditID?: Maybe<Scalars['String']>;
  CreditOrDebit?: Maybe<Scalars['String']>;
  AllocationAmt: Scalars['Float'];
  CreditorAccountID?: Maybe<Scalars['String']>;
};

export type ArCreditNoteDeleteInput = {
  CreditNoteID: Scalars['String'];
};

export type ArCreditNoteInput = {
  CreditNoteID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DocDate: Scalars['String'];
  BillItemID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  DebtorAccountID: Scalars['String'];
  TransactionDate?: Maybe<Scalars['String']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type ArCreditNoteItemDeleteInput = {
  CreditNoteItemID: Scalars['String'];
};

export type ArCreditNoteItemInput = {
  CreditNoteItemID?: Maybe<Scalars['String']>;
  CreditNoteID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  BillItemID?: Maybe<Scalars['String']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type ArCreditNoteRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type ArDebitNoteDeleteInput = {
  DebitNoteID: Scalars['String'];
};

export type ArDebitNoteInput = {
  CompanyID: Scalars['String'];
  DebitNoteID?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  TransactionDate: Scalars['String'];
  DebtorAccountID: Scalars['String'];
  CreditTerm?: Maybe<Scalars['Float']>;
  RefNo?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type ArDebitNoteItemDeleteInput = {
  DebitNoteItemID: Scalars['String'];
};

export type ArDebitNoteItemInput = {
  DebitNoteItemID?: Maybe<Scalars['String']>;
  DebitNoteID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  BillItemID?: Maybe<Scalars['String']>;
};

export type ArDebitNoteRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type ArFavoriteInvoiceDeleteInput = {
  FavoriteInvoiceID: Scalars['String'];
};

export type ArFavoriteInvoiceInput = {
  FavoriteInvoiceID?: Maybe<Scalars['String']>;
  InvoiceID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DebtorAccountID: Scalars['String'];
  InterestRate?: Maybe<Scalars['Float']>;
  CreditTerm?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
};

export type ArFavoriteInvoiceItemDeleteInput = {
  FavoriteInvoiceItemID: Scalars['String'];
};

export type ArFavoriteInvoiceItemInput = {
  FavoriteInvoiceItemID?: Maybe<Scalars['String']>;
  FavoriteInvoiceID?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
};

export type ArglExportEntity = AuditEntity & {
  __typename?: 'ARGLExportEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  GLExportID: Scalars['String'];
  BatchNo?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  SourceID?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  GLDate: Scalars['String'];
  TransactionDate: Scalars['String'];
  AccountCode?: Maybe<Scalars['String']>;
  Debit?: Maybe<Scalars['Float']>;
  Credit?: Maybe<Scalars['Float']>;
};

export type ArInvoiceDeleteInput = {
  InvoiceID: Scalars['String'];
};

export type ArInvoiceInput = {
  CompanyID: Scalars['String'];
  InvoiceID?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DebtorAccountID: Scalars['String'];
  CreditTerm?: Maybe<Scalars['Float']>;
  DueDate?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  TransactionDate: Scalars['String'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type ArInvoiceItemDeleteInput = {
  InvoiceItemID: Scalars['String'];
};

export type ArInvoiceItemInput = {
  InvoiceItemID?: Maybe<Scalars['String']>;
  InvoiceID?: Maybe<Scalars['String']>;
  BillItemID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type ArInvoiceRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type ArOfficialReceiptDeleteInput = {
  ReceiptID: Scalars['String'];
};

export type ArOfficialReceiptInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  DocRef?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DocDueDate?: Maybe<Scalars['String']>;
  CancellationDate?: Maybe<Scalars['String']>;
  CancellationRemark?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  BaseAmt?: Maybe<Scalars['Float']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  LocalBaseAmt?: Maybe<Scalars['Float']>;
  BankDocAmt?: Maybe<Scalars['Float']>;
  LocalTaxAmt?: Maybe<Scalars['Float']>;
  CurrencyID?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ReceiptID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  TransactionDate: Scalars['String'];
  RefNo?: Maybe<Scalars['String']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
  PaymentMethodID: Scalars['String'];
  Amount?: Maybe<Scalars['Float']>;
  DocAmt: Scalars['Float'];
  Remark?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
};

export type ArOfficialReceiptRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type ArRefundDeleteInput = {
  RefundID: Scalars['String'];
};

export type ArRefundInput = {
  RefundID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DebtorAccountID: Scalars['String'];
  PaymentMethodID: Scalars['String'];
  DocDate: Scalars['String'];
  TransactionDate: Scalars['String'];
  RefNo?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type ArRefundRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type AccApAdvanceEntity = AuditEntity & {
  __typename?: 'AccAPAdvanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  AdvanceID: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  IsAutoDocNo: Scalars['Boolean'];
  IsCBPosted: Scalars['Boolean'];
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  CreditorAccountID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  CompanyID: Scalars['String'];
  PaymentMethodID: Scalars['String'];
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  BankAccountID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  PaymentID?: Maybe<Scalars['String']>;
  APPayment?: Maybe<Array<AccApPaymentEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccApAllocationObj>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
};

export type AccApAllocationEntity = AuditEntity & {
  __typename?: 'AccAPAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DebitRefTable: Scalars['String'];
  DebitID: Scalars['String'];
  CreditRefTable: Scalars['String'];
  CreditID: Scalars['String'];
  AllocationAmt: Scalars['Float'];
  AllocationID: Scalars['String'];
  CompanyID: Scalars['String'];
  APAllocationItem?: Maybe<Array<AccApAllocationItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type AccApAllocationItemEntity = AuditEntity & {
  __typename?: 'AccAPAllocationItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DebitItemRefTable: Scalars['String'];
  DebitItemID: Scalars['String'];
  CreditItemRefTable: Scalars['String'];
  CreditItemID: Scalars['String'];
  AllocationItemAmt: Scalars['Float'];
  AllocationItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  AllocationID: Scalars['String'];
  Allocation?: Maybe<AccApAllocationEntity>;
};

export type AccApAllocationItemObj = {
  __typename?: 'AccAPAllocationItemObj';
  AllocationItemID: Scalars['String'];
  AllocationID: Scalars['String'];
  AllocationItemAmt?: Maybe<Scalars['Float']>;
  CreditOrDebit: Scalars['String'];
  CompanyID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DebitItemRefTable: Scalars['String'];
  DebitItemID: Scalars['String'];
  CreditItemRefTable: Scalars['String'];
  CreditItemID: Scalars['String'];
};

export type AccApAllocationObj = {
  __typename?: 'AccAPAllocationObj';
  AllocationID: Scalars['String'];
  AllocationAmt?: Maybe<Scalars['Float']>;
  CreditOrDebit: Scalars['String'];
  CompanyID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DebitDocNo?: Maybe<Scalars['String']>;
  DebitDocDate?: Maybe<Scalars['DateTime']>;
  DebitRefTable: Scalars['String'];
  DebitID: Scalars['String'];
  CreditDocNo?: Maybe<Scalars['String']>;
  CreditDocDate?: Maybe<Scalars['DateTime']>;
  CreditRefTable: Scalars['String'];
  CreditID: Scalars['String'];
};

export type AccApBatchPaymentEntity = AuditEntity & {
  __typename?: 'AccAPBatchPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  BatchPaymentID: Scalars['String'];
  BankAccountID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  CompanyID: Scalars['String'];
  PaymentMethodID: Scalars['String'];
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  Remark?: Maybe<Scalars['String']>;
  GLGenerated: Scalars['Boolean'];
  CBGenerated: Scalars['Boolean'];
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  APPayment?: Maybe<Array<AccApPaymentEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccApAllocationObj>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  DocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
};

export type AccApCreditNoteEntity = AuditEntity & {
  __typename?: 'AccAPCreditNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  CreditNoteID: Scalars['String'];
  RefRecordID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  DocAmt: Scalars['Float'];
  IsAutoDocNo?: Maybe<Scalars['Boolean']>;
  IsCBPosted: Scalars['Boolean'];
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_self_billed?: Maybe<Scalars['Boolean']>;
  APCreditNoteItem?: Maybe<Array<AccApCreditNoteItemEntity>>;
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseItem?: Maybe<ExpenseItemEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccApAllocationObj>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  MSICCode?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  einvoice?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  SB?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  einvoice_status?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  einvoice_sent_date?: Maybe<Scalars['String']>;
};

export type AccApCreditNoteItemEntity = AuditEntity & {
  __typename?: 'AccAPCreditNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  CreditNoteItemID: Scalars['String'];
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  ItemID?: Maybe<Scalars['String']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  UOM?: Maybe<AccUomEntity>;
  CreditNoteID: Scalars['String'];
  APCreditNote?: Maybe<AccApCreditNoteEntity>;
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseItem?: Maybe<ExpenseItemEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  AllocationItem?: Maybe<Array<AccApAllocationItemObj>>;
  /** CustomFieldResolver */
  BalanceItemAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  ClassificationCode?: Maybe<Scalars['JSON']>;
};

export type AccApCreditorCreditNoteEntity = AuditEntity & {
  __typename?: 'AccAPCreditorCreditNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  CreditorCreditNoteID: Scalars['String'];
  RefRecordID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  DocAmt: Scalars['Float'];
  IsAutoDocNo?: Maybe<Scalars['Boolean']>;
  IsCBPosted: Scalars['Boolean'];
  einvoice_self_billed?: Maybe<Scalars['Boolean']>;
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  APCreditorCreditNoteItem?: Maybe<Array<AccApCreditorCreditNoteItemEntity>>;
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccApAllocationObj>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  GRTN?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  MSICCode?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  einvoice?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  SB?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  einvoice_status?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  einvoice_sent_date?: Maybe<Scalars['String']>;
};

export type AccApCreditorCreditNoteItemEntity = AuditEntity & {
  __typename?: 'AccAPCreditorCreditNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  CreditorCreditNoteItemID: Scalars['String'];
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
  GRTNItemID?: Maybe<Scalars['String']>;
  CreditorCreditNoteID: Scalars['String'];
  APCreditorCreditNote?: Maybe<AccApCreditorCreditNoteEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseItem?: Maybe<ExpenseItemEntity>;
  UOMID?: Maybe<Scalars['String']>;
  UOM?: Maybe<AccUomEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  AllocationItem?: Maybe<Array<AccApAllocationItemObj>>;
  /** CustomFieldResolver */
  BalanceItemAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  ClassificationCode?: Maybe<Scalars['JSON']>;
};

export type AccApCreditorDebitNoteEntity = AuditEntity & {
  __typename?: 'AccAPCreditorDebitNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  CreditorDebitNoteID: Scalars['String'];
  CreditTerm?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  IsAutoDocNo: Scalars['Boolean'];
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_self_billed?: Maybe<Scalars['Boolean']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  DocAmt: Scalars['Float'];
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  APCreditorDebitNoteItem?: Maybe<Array<AccApCreditorDebitNoteItemEntity>>;
  IsCBPosted: Scalars['Boolean'];
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseItem?: Maybe<ExpenseItemEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccApAllocationObj>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  MSICCode?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  einvoice?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  SB?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  einvoice_status?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  einvoice_sent_date?: Maybe<Scalars['String']>;
};

export type AccApCreditorDebitNoteItemEntity = AuditEntity & {
  __typename?: 'AccAPCreditorDebitNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  CreditorDebitNoteItemID: Scalars['String'];
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
  CreditorDebitNoteID: Scalars['String'];
  APCreditorDebitNote?: Maybe<AccApCreditorDebitNoteEntity>;
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseItem?: Maybe<ExpenseItemEntity>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  UOMID?: Maybe<Scalars['String']>;
  UOM?: Maybe<AccUomEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  ClassificationCode?: Maybe<Scalars['JSON']>;
};

export type AccApDebitNoteEntity = AuditEntity & {
  __typename?: 'AccAPDebitNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DebitNoteID: Scalars['String'];
  CreditTerm?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  IsAutoDocNo: Scalars['Boolean'];
  TaxAmt?: Maybe<Scalars['Float']>;
  DocAmt: Scalars['Float'];
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  APDebitNoteItem?: Maybe<Array<AccApDebitNoteItemEntity>>;
  IsCBPosted: Scalars['Boolean'];
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_self_billed?: Maybe<Scalars['Boolean']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseItem?: Maybe<ExpenseItemEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccApAllocationObj>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  MSICCode?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  einvoice?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  SB?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  einvoice_status?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  einvoice_sent_date?: Maybe<Scalars['String']>;
};

export type AccApDebitNoteItemEntity = AuditEntity & {
  __typename?: 'AccAPDebitNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DebitNoteItemID: Scalars['String'];
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
  DebitNoteID: Scalars['String'];
  APDebitNote?: Maybe<AccApDebitNoteEntity>;
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseItem?: Maybe<ExpenseItemEntity>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  UOMID?: Maybe<Scalars['String']>;
  UOM?: Maybe<AccUomEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ClassificationCode?: Maybe<Scalars['JSON']>;
};

export type AccApFavoriteInvoiceEntity = AuditEntity & {
  __typename?: 'AccAPFavoriteInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  FavoriteInvoiceID: Scalars['String'];
  UserID: Scalars['String'];
  Sequence?: Maybe<Scalars['Float']>;
  CreditorAccountID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  Name?: Maybe<Scalars['String']>;
  CreditTerm?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  APFavoriteInvoiceItem?: Maybe<Array<AccApFavoriteInvoiceItemEntity>>;
};

export type AccApFavoriteInvoiceItemEntity = AuditEntity & {
  __typename?: 'AccAPFavoriteInvoiceItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark: Scalars['String'];
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  FavoriteInvoiceItemID: Scalars['String'];
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  FavoriteInvoiceID: Scalars['String'];
  APFavoriteInvoice?: Maybe<AccApFavoriteInvoiceEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  ExpenseItemID: Scalars['String'];
  ExpenseItem?: Maybe<ExpenseItemEntity>;
};

export type AccApFavoritePaymentEntity = {
  __typename?: 'AccAPFavoritePaymentEntity';
  FavoritePaymentID: Scalars['String'];
  AccountID: Scalars['String'];
  UserID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  RefNo?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  PaymentMethodID?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  BankAccountID?: Maybe<Scalars['String']>;
  AccBankAccount?: Maybe<AccBankAccountEntity>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
};

export type AccApInvoiceEntity = AuditEntity & {
  __typename?: 'AccAPInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  InvoiceID: Scalars['String'];
  CreditTerm?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  DiscountRate?: Maybe<Scalars['Float']>;
  DiscountAmt?: Maybe<Scalars['Float']>;
  OtherCharges?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  IsAutoDocNo?: Maybe<Scalars['Boolean']>;
  IsCBPosted: Scalars['Boolean'];
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_self_billed?: Maybe<Scalars['Boolean']>;
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  APInvoiceItem?: Maybe<Array<AccApInvoiceItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccApAllocationObj>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  DO?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  MSICCode?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  einvoice?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  SB?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  einvoice_status?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  einvoice_sent_date?: Maybe<Scalars['String']>;
};

export type AccApInvoiceItemEntity = AuditEntity & {
  __typename?: 'AccAPInvoiceItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  InvoiceItemID: Scalars['String'];
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  InvoiceID: Scalars['String'];
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
  APInvoice?: Maybe<AccApInvoiceEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  DOItemID?: Maybe<Scalars['String']>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  UOMID?: Maybe<Scalars['String']>;
  UOM?: Maybe<AccUomEntity>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseItem?: Maybe<ExpenseItemEntity>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ClassificationCode?: Maybe<Scalars['JSON']>;
};

export type AccApPaymentEntity = AuditEntity & {
  __typename?: 'AccAPPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  PaymentID: Scalars['String'];
  IsCBPosted: Scalars['Boolean'];
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  PaymentMethodID: Scalars['String'];
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  BankAccountID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  BatchPaymentID?: Maybe<Scalars['String']>;
  APBatchPayment?: Maybe<AccApBatchPaymentEntity>;
  AdvanceID?: Maybe<Scalars['String']>;
  APAdvance?: Maybe<AccApAdvanceEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccApAllocationObj>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
};

export type AccApRefundEntity = AuditEntity & {
  __typename?: 'AccAPRefundEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  RefundID: Scalars['String'];
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  IsCBPosted: Scalars['Boolean'];
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  PaymentMethodID: Scalars['String'];
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  BankAccountID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccApAllocationObj>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
};

export type AccApSelfBilledEntity = AuditEntity & {
  __typename?: 'AccAPSelfBilledEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  SelfBilledID: Scalars['String'];
  CreditTerm?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  DocAmt: Scalars['Float'];
  RefTable?: Maybe<Scalars['String']>;
  RefRecordID?: Maybe<Scalars['String']>;
  einvoice_trx_type?: Maybe<EInvoiceTrxType>;
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  APSelfBilledItem?: Maybe<Array<AccApSelfBilledItemEntity>>;
  IsCBPosted: Scalars['Boolean'];
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
  einvoice_failed_send_reason?: Maybe<Scalars['String']>;
  einvoice_sent_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_validation_date?: Maybe<Scalars['DateTime']>;
  einvoice_validation_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_validation_reason?: Maybe<Scalars['String']>;
  einvoice_cancelled_by?: Maybe<Scalars['String']>;
  einvoice_cancelled_date?: Maybe<Scalars['DateTime']>;
  einvoice_cancelled_reason?: Maybe<Scalars['String']>;
  einvoice_rejected_request_date?: Maybe<Scalars['DateTime']>;
  einvoice_rejected_confirm_by?: Maybe<Scalars['String']>;
  einvoice_rejected_confirm_date?: Maybe<Scalars['DateTime']>;
  einvoice_rejected_reason?: Maybe<Scalars['String']>;
  einvoice_failed_resend_date?: Maybe<Scalars['DateTime']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  einvoice_consol_uniqueID?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  einvoice_self_billed?: Maybe<Scalars['Boolean']>;
  is_double_entry?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  MSICCode?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccApAllocationObj>>;
  /** CustomFieldResolver */
  AllocationCNFromCreditor?: Maybe<Array<AccApAllocationObj>>;
  /** CustomFieldResolver */
  APLedger?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  einvoice?: Maybe<Scalars['JSON']>;
};

export type AccApSelfBilledItemEntity = AuditEntity & {
  __typename?: 'AccAPSelfBilledItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  SelfBilledItemID: Scalars['String'];
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
  SelfBilledID: Scalars['String'];
  APSelfBilled?: Maybe<AccApSelfBilledEntity>;
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseItem?: Maybe<ExpenseItemEntity>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  UOMID?: Maybe<Scalars['String']>;
  UOM?: Maybe<AccUomEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  ClassificationCode?: Maybe<Scalars['JSON']>;
};

export type AccArAdvanceEntity = AuditEntity & {
  __typename?: 'AccARAdvanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  AdvanceID: Scalars['String'];
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  TaxInvoiceNo: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  IsCBPosted: Scalars['Boolean'];
  DebtorAccountID: Scalars['String'];
  DebtorAccount?: Maybe<DebtorAccountEntity>;
  CompanyID: Scalars['String'];
  PaymentMethodID: Scalars['String'];
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  BankAccountID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccArAllocationObj>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
};

export type AccArAllocationEntity = AuditEntity & {
  __typename?: 'AccARAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DebitRefTable: Scalars['String'];
  DebitID: Scalars['String'];
  CreditRefTable: Scalars['String'];
  CreditID: Scalars['String'];
  AllocationAmt: Scalars['Float'];
  AllocationID: Scalars['String'];
  CompanyID: Scalars['String'];
  ARAllocationItem?: Maybe<Array<AccArAllocationItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type AccArAllocationItemEntity = AuditEntity & {
  __typename?: 'AccARAllocationItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DebitItemRefTable: Scalars['String'];
  DebitItemID: Scalars['String'];
  CreditItemRefTable: Scalars['String'];
  CreditItemID: Scalars['String'];
  AllocationItemAmt: Scalars['Float'];
  AllocationItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  AllocationID: Scalars['String'];
  Allocation?: Maybe<AccArAllocationEntity>;
};

export type AccArAllocationItemObj = {
  __typename?: 'AccARAllocationItemObj';
  AllocationItemID: Scalars['String'];
  AllocationID: Scalars['String'];
  AllocationItemAmt?: Maybe<Scalars['Float']>;
  CreditOrDebit: Scalars['String'];
  CompanyID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DebitItemDocNo?: Maybe<Scalars['String']>;
  DebitItemDocDate?: Maybe<Scalars['DateTime']>;
  DebitItemRefTable: Scalars['String'];
  DebitItemID: Scalars['String'];
  CreditItemDocNo?: Maybe<Scalars['String']>;
  CreditItemDocDate?: Maybe<Scalars['DateTime']>;
  CreditItemRefTable: Scalars['String'];
  CreditItemID: Scalars['String'];
};

export type AccArAllocationObj = {
  __typename?: 'AccARAllocationObj';
  AllocationID: Scalars['String'];
  AllocationAmt?: Maybe<Scalars['Float']>;
  CreditOrDebit: Scalars['String'];
  CompanyID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DebitDocNo?: Maybe<Scalars['String']>;
  DebitDocDate?: Maybe<Scalars['DateTime']>;
  DebitRefTable: Scalars['String'];
  DebitID: Scalars['String'];
  CreditDocNo?: Maybe<Scalars['String']>;
  CreditDocDate?: Maybe<Scalars['DateTime']>;
  CreditRefTable: Scalars['String'];
  CreditID: Scalars['String'];
};

export type AccArCollectionEntity = AuditEntity & {
  __typename?: 'AccARCollectionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  CollectionActivityID: Scalars['String'];
  ActionDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DebtorAccountID: Scalars['String'];
  DebtorAccount?: Maybe<DebtorAccountEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type AccArCreditNoteEntity = AuditEntity & {
  __typename?: 'AccARCreditNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  CreditNoteID: Scalars['String'];
  RefRecordID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  IsCBPosted: Scalars['Boolean'];
  Amount?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  DocAmt: Scalars['Float'];
  CompanyID: Scalars['String'];
  DebtorAccountID: Scalars['String'];
  DebtorAccount?: Maybe<DebtorAccountEntity>;
  Remark?: Maybe<Scalars['String']>;
  ARCreditNoteItem?: Maybe<Array<AccArCreditNoteItemEntity>>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccArAllocationObj>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
};

export type AccArCreditNoteItemEntity = AuditEntity & {
  __typename?: 'AccARCreditNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  CreditNoteItemID: Scalars['String'];
  CreditNoteID: Scalars['String'];
  ARCreditNote?: Maybe<AccArCreditNoteEntity>;
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  BillItemID?: Maybe<Scalars['String']>;
  BillItem?: Maybe<BillItemEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type AccArDebitNoteEntity = AuditEntity & {
  __typename?: 'AccARDebitNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DebitNoteID: Scalars['String'];
  CompanyID: Scalars['String'];
  CreditTerm?: Maybe<Scalars['Float']>;
  DebtorAccountID: Scalars['String'];
  DebtorAccount?: Maybe<DebtorAccountEntity>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  InterestRate?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  ARDebitNoteItem?: Maybe<Array<AccArDebitNoteItemEntity>>;
  IsCBPosted: Scalars['Boolean'];
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccArAllocationObj>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
};

export type AccArDebitNoteItemEntity = AuditEntity & {
  __typename?: 'AccARDebitNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DebitNoteItemID: Scalars['String'];
  DebitNoteID: Scalars['String'];
  ARDebitNote?: Maybe<AccArDebitNoteEntity>;
  BillItemID?: Maybe<Scalars['String']>;
  BillItem?: Maybe<BillItemEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseItem?: Maybe<ExpenseItemEntity>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
};

export type AccArFavoriteInvoiceEntity = AuditEntity & {
  __typename?: 'AccARFavoriteInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  FavoriteInvoiceID: Scalars['String'];
  UserID: Scalars['String'];
  DebtorAccountID: Scalars['String'];
  DebtorAccount?: Maybe<DebtorAccountEntity>;
  Name?: Maybe<Scalars['String']>;
  InterestRate?: Maybe<Scalars['Float']>;
  FavoriteInvoiceItem?: Maybe<Array<AccArFavoriteInvoiceItemEntity>>;
  Remark: Scalars['String'];
};

export type AccArFavoriteInvoiceItemEntity = AuditEntity & {
  __typename?: 'AccARFavoriteInvoiceItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark: Scalars['String'];
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  MasterCOAID: Scalars['String'];
  FavoriteInvoiceItemID: Scalars['String'];
  FavoriteInvoiceID: Scalars['String'];
  FavoriteInvoice?: Maybe<AccArFavoriteInvoiceEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
};

export type AccArInvoiceEntity = AuditEntity & {
  __typename?: 'AccARInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  InvoiceID: Scalars['String'];
  DebtorAccountID: Scalars['String'];
  DebtorAccount?: Maybe<DebtorAccountEntity>;
  CreditTerm?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  ARInvoiceItem?: Maybe<Array<AccArInvoiceItemEntity>>;
  CompanyID: Scalars['String'];
  IsCBPosted: Scalars['Boolean'];
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccArAllocationObj>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
};

export type AccArInvoiceItemEntity = AuditEntity & {
  __typename?: 'AccARInvoiceItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  InvoiceItemID: Scalars['String'];
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  InvoiceID: Scalars['String'];
  ARInvoice?: Maybe<AccArInvoiceEntity>;
  BillItemID?: Maybe<Scalars['String']>;
  BillItem?: Maybe<BillItemEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
};

export type AccArOfficialReceiptEntity = AuditEntity & {
  __typename?: 'AccAROfficialReceiptEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  ReceiptID: Scalars['String'];
  CompanyID: Scalars['String'];
  TaxInvoiceNo?: Maybe<Scalars['String']>;
  DebtorAccountID: Scalars['String'];
  DebtorAccount?: Maybe<DebtorAccountEntity>;
  PaymentMethodID: Scalars['String'];
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  BankAccountID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  IsCBPosted: Scalars['Boolean'];
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccArAllocationObj>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
};

export type AccArRefundEntity = AuditEntity & {
  __typename?: 'AccARRefundEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  RefundID: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DebtorAccountID: Scalars['String'];
  DebtorAccount?: Maybe<DebtorAccountEntity>;
  PaymentMethodID: Scalars['String'];
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  BankAccountID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  Amount?: Maybe<Scalars['Float']>;
  IsCBPosted: Scalars['Boolean'];
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccArAllocationObj>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
};

export type AccApprovalPolicyAssignmentEntity = AuditEntity & {
  __typename?: 'AccApprovalPolicyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalPolicyAssignmentID: Scalars['String'];
  ApprovalPolicyID?: Maybe<Scalars['String']>;
  ApprovalPolicy: AccApprovalPolicyEntity;
  CompanyID?: Maybe<Scalars['String']>;
  ApprovalList?: Maybe<Array<Scalars['JSON']>>;
  Category: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type AccApprovalPolicyEntity = AuditEntity & {
  __typename?: 'AccApprovalPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalPolicyID: Scalars['String'];
  Category: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  ApprovalList?: Maybe<Array<Scalars['JSON']>>;
  ApprovalPolicyAssignment: Array<AccApprovalPolicyAssignmentEntity>;
};

export type AccBankAccountEntity = AuditEntity & {
  __typename?: 'AccBankAccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BankAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  BankProfileID: Scalars['String'];
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  BankProfile?: Maybe<BankProfileEntity>;
  Code: Scalars['String'];
  AccountNo: Scalars['String'];
  Type: BankAccountType;
  SWIFTCode: Scalars['String'];
  EffectiveDate: Scalars['String'];
  CurrencyID?: Maybe<Scalars['String']>;
  BankReconciliation?: Maybe<Array<BankReconciliationEntity>>;
  Ledger?: Maybe<Array<LedgerEntity>>;
  ExternalLedger?: Maybe<Array<ExternalLedgerEntity>>;
  Payment?: Maybe<Array<PaymentEntity>>;
  Receipt?: Maybe<Array<ReceiptEntity>>;
  BankTransfer?: Maybe<Array<BankTransferEntity>>;
  APRefund?: Maybe<Array<AccApRefundEntity>>;
  ARRefund?: Maybe<Array<AccArRefundEntity>>;
  APAdvance?: Maybe<Array<AccApAdvanceEntity>>;
  ARAdvance?: Maybe<Array<AccArAdvanceEntity>>;
  APPayment?: Maybe<Array<AccApPaymentEntity>>;
  APFavoritePayment?: Maybe<Array<AccApFavoritePaymentEntity>>;
  AROfficialReceipt?: Maybe<Array<AccArOfficialReceiptEntity>>;
  APBatchPayment?: Maybe<Array<AccApBatchPaymentEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  Currency?: Maybe<CurrencyEntity>;
};

export type AccCompanyEntity = AuditEntity & {
  __typename?: 'AccCompanyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CompanyID: Scalars['String'];
  ParentCompanyID?: Maybe<Scalars['String']>;
  BaseCurrencyID: Scalars['String'];
  Name: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['JSON']>;
  ContactNo?: Maybe<Scalars['String']>;
  CompanyRegNo: Scalars['String'];
  SSTNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  GSTExpiryDate?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  ImageUrl?: Maybe<Scalars['String']>;
  ImageBucketFile?: Maybe<Scalars['String']>;
};

export type AccContactEntity = AuditEntity & {
  __typename?: 'AccContactEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ContactID: Scalars['String'];
  AssociateID: Scalars['String'];
  Name: Scalars['String'];
  Designation?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  JobTitle?: Maybe<Scalars['String']>;
  ContactNo: Scalars['String'];
  Email: Scalars['String'];
  Relationship?: Maybe<Scalars['String']>;
  DocRefTable: Scalars['String'];
};

export type AccCurrencyEntity = AuditEntity & {
  __typename?: 'AccCurrencyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CurrencyID: Scalars['String'];
  Code: Scalars['String'];
  Name: Scalars['String'];
  Symbol?: Maybe<Scalars['String']>;
};

export type AccCurrencyExchangeEntity = AuditEntity & {
  __typename?: 'AccCurrencyExchangeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CurrencyExchangeID: Scalars['String'];
  FromCurrencyID: Scalars['String'];
  ToCurrencyID: Scalars['String'];
  EffectiveDate?: Maybe<Scalars['String']>;
  FromAmt: Scalars['Float'];
  ToAmt: Scalars['Float'];
  ExchangeRate: Scalars['Float'];
  CurrencyLog: Scalars['JSON'];
  /** CustomFieldResolver */
  FromCurrency?: Maybe<CurrencyEntity>;
  /** CustomFieldResolver */
  ToCurrency?: Maybe<CurrencyEntity>;
};

export type AccReportDevEntity = {
  __typename?: 'AccReportDevEntity';
  ID: Scalars['String'];
  ParentID: Scalars['String'];
  ReportName: Scalars['String'];
  FilePath?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Module?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type AccRoleEntity = AuditEntity & {
  __typename?: 'AccRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  rolePerm: Array<AccRolePermissionAssignmentEntity>;
  ModuleType: ModuleType;
  /** CustomFieldResolver */
  userList?: Maybe<Array<UserEntity>>;
};

export type AccRolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'AccRolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
  permName?: Maybe<Scalars['String']>;
};

export type AccRoleUserEntAssignmentEntity = AuditEntity & {
  __typename?: 'AccRoleUserEntAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
};

export type AccRoleUserInput = {
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type AccTax = {
  __typename?: 'AccTax';
  Rate?: Maybe<Scalars['Float']>;
  Date?: Maybe<Scalars['DateTime']>;
};

export enum AccTaxCategory {
  Inclusive = 'INCLUSIVE',
  Exclusive = 'EXCLUSIVE'
}

export enum AccTaxClass {
  Input = 'INPUT',
  Output = 'OUTPUT'
}

export type AccUomEntity = AuditEntity & {
  __typename?: 'AccUOMEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  UOMID: Scalars['String'];
  ExpenseItem?: Maybe<Array<ExpenseItemEntity>>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  APInvoiceItem?: Maybe<Array<AccApInvoiceItemEntity>>;
  APDebitNoteItem?: Maybe<Array<AccApDebitNoteItemEntity>>;
  APCreditorDebitNoteItem?: Maybe<Array<AccApCreditorDebitNoteItemEntity>>;
  APSelfBilledItem?: Maybe<Array<AccApSelfBilledItemEntity>>;
  APFavoriteInvoiceItem?: Maybe<Array<AccApInvoiceItemEntity>>;
  StockItem?: Maybe<Array<StockItemEntity>>;
  StockReceiptItem?: Maybe<Array<StockReceiptItemEntity>>;
  StockIssueItem?: Maybe<Array<StockIssueItemEntity>>;
  StockTransferItem?: Maybe<Array<StockTransferItemEntity>>;
  CycleCountItem?: Maybe<Array<CycleCountItemEntity>>;
  StockAdjusmentItem?: Maybe<Array<StockAdjustmentItemEntity>>;
  StockWeightedAvg?: Maybe<Array<StockWeightedAvgEntity>>;
  StockRequisitionItem?: Maybe<Array<StockRequisitionItemEntity>>;
  FavoriteStockRequisitionItem?: Maybe<Array<FavoriteStockRequisitionItemEntity>>;
  APCreditNoteItem?: Maybe<Array<AccApCreditNoteItemEntity>>;
  APCreditorCreditNoteItem?: Maybe<Array<AccApCreditorCreditNoteItemEntity>>;
};

export type AccUomExchangeEntity = AuditEntity & {
  __typename?: 'AccUOMExchangeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  FromUomID: Scalars['String'];
  ToUomID: Scalars['String'];
  FromAmt: Scalars['Float'];
  ToAmt: Scalars['Float'];
  ExchangeRate: Scalars['Float'];
  UomLog?: Maybe<Array<Scalars['JSON']>>;
  FromUOM: AccUomEntity;
  ToUOM: AccUomEntity;
};

export type AccWorkFlowApprovedStepEntity = AuditEntity & {
  __typename?: 'AccWorkFlowApprovedStepEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  WorkFlowApprovedStepID: Scalars['String'];
  WorkFlowStepID?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  ApprovalDT?: Maybe<Scalars['String']>;
  NotifiedDT?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  StatusRemarks?: Maybe<Scalars['String']>;
  WorkFlowStep: AccWorkFlowStepEntity;
  /** CustomFieldResolver */
  ApproverDetail?: Maybe<UserEntity>;
};

export type AccWorkFlowEntity = AuditEntity & {
  __typename?: 'AccWorkFlowEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  WorkFlowID: Scalars['String'];
  ApprovalPolicyAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  SubmitterID?: Maybe<Scalars['String']>;
  SubmitDate?: Maybe<Scalars['String']>;
  WorkFlowStatus?: Maybe<ApprovalStatus>;
  WorkFlowStep?: Maybe<Array<AccWorkFlowStepEntity>>;
  /** CustomFieldResolver */
  SubmitterDetail?: Maybe<UserEntity>;
};

export type AccWorkFlowStepEntity = AuditEntity & {
  __typename?: 'AccWorkFlowStepEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  WorkFlowStepID: Scalars['String'];
  WorkFlowID?: Maybe<Scalars['String']>;
  ApproverRoleID?: Maybe<Scalars['String']>;
  NotifiedDT?: Maybe<Scalars['String']>;
  StepNo?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  StatusRemarks?: Maybe<Scalars['String']>;
  WorkFlow: AccWorkFlowEntity;
  WorkFlowApprovedStep?: Maybe<Array<AccWorkFlowApprovedStepEntity>>;
  /** CustomFieldResolver */
  RoleDetail?: Maybe<AccRoleEntity>;
};

export type AccountCodeSummary = {
  __typename?: 'AccountCodeSummary';
  CodeLength?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  AllowControl?: Maybe<Scalars['Boolean']>;
};

export type AccountCoreAllocationEntity = AuditEntity & {
  __typename?: 'AccountCoreAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DebitRefTable: Scalars['String'];
  DebitID: Scalars['String'];
  CreditRefTable: Scalars['String'];
  CreditID: Scalars['String'];
  AllocationAmt: Scalars['Float'];
};

export type AccountCoreAllocationItemEntity = AuditEntity & {
  __typename?: 'AccountCoreAllocationItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DebitItemRefTable: Scalars['String'];
  DebitItemID: Scalars['String'];
  CreditItemRefTable: Scalars['String'];
  CreditItemID: Scalars['String'];
  AllocationItemAmt: Scalars['Float'];
};

export type AccountCoreApprovalEntity = AuditEntity & {
  __typename?: 'AccountCoreApprovalEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
};

export type AccountCoreDocItemEntity = AuditEntity & {
  __typename?: 'AccountCoreDocItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type AccountCoreEntity = AuditEntity & {
  __typename?: 'AccountCoreEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
};

export type AccountCoreLedgerItemEntity = AuditEntity & {
  __typename?: 'AccountCoreLedgerItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  MasterCOAID: Scalars['String'];
};

export type AccountCoreReceiptPaymentEntity = AuditEntity & {
  __typename?: 'AccountCoreReceiptPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  CurrencyID?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  LocalDocAmt?: Maybe<Scalars['Float']>;
  LocalTaxAmt?: Maybe<Scalars['Float']>;
  BankAccountID: Scalars['String'];
};

export type AccountCoreTaxItemEntity = AuditEntity & {
  __typename?: 'AccountCoreTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
};

export type AccountDocDate = AuditEntity & {
  __typename?: 'AccountDocDate';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
};

export type AccountDocRef = AuditEntity & {
  __typename?: 'AccountDocRef';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
};

export type AccountDocRefNoApproval = AuditEntity & {
  __typename?: 'AccountDocRefNoApproval';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
};

export type AccountForex = AuditEntity & {
  __typename?: 'AccountForex';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  CurrencyID?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  LocalDocAmt?: Maybe<Scalars['Float']>;
  LocalTaxAmt?: Maybe<Scalars['Float']>;
};

export type AccountLedgerEntity = AuditEntity & {
  __typename?: 'AccountLedgerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
};

export type AccountLedgerInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  DebitRefTable?: Maybe<Scalars['String']>;
  DebitID?: Maybe<Scalars['String']>;
  CreditRefTable?: Maybe<Scalars['String']>;
  CreditID?: Maybe<Scalars['String']>;
  AllocationAmt?: Maybe<Scalars['Float']>;
};

export type AccountMappingDeleteInput = {
  AccountMappingID: Scalars['String'];
};

export type AccountMappingInput = {
  AccountMappingID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  ROUMasterCOAID: Scalars['String'];
  LeaseLiabilityMasterCOAID: Scalars['String'];
  DepreciationROUMasterCOAID: Scalars['String'];
  ProvisionDROUMasterCOAID: Scalars['String'];
  RecurringROUMasterCOAID?: Maybe<Scalars['String']>;
  InterestExpenseMasterCOAID: Scalars['String'];
  LeaseLiabilitiesMasterCOAID: Scalars['String'];
  AccrualLeaseLiabilitiesMasterCOAID: Scalars['String'];
  GLTerminationMasterCOAID: Scalars['String'];
  CapitalisationROUJournalTypeID: Scalars['String'];
  ROUJournalTypeID: Scalars['String'];
  LeaseLiabilitiesJournalTypeID: Scalars['String'];
};

export type AccountPeriodCheck = {
  __typename?: 'AccountPeriodCheck';
  YearClosed?: Maybe<Scalars['Boolean']>;
  IsEditable?: Maybe<Scalars['Boolean']>;
  LatestUsedPeriod?: Maybe<Scalars['Float']>;
  MinStartDate?: Maybe<Scalars['DateTime']>;
  MaxEndDate?: Maybe<Scalars['DateTime']>;
};

export type AccountPeriodDateRange = {
  __typename?: 'AccountPeriodDateRange';
  StartDate?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['String']>;
};

export type AccountPeriodDeleteInput = {
  AccountPeriodID: Scalars['String'];
};

export type AccountPeriodEntity = AuditEntity & {
  __typename?: 'AccountPeriodEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  AccountPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
  FYear: Scalars['Float'];
  FPeriod: Scalars['Float'];
  StartDate: Scalars['String'];
  EndDate: Scalars['String'];
  MonthEndClose: Scalars['Boolean'];
  YearEndClose: Scalars['Boolean'];
  BudgetItemPeriod?: Maybe<Array<BudgetItemPeriodEntity>>;
  RecurringJournal?: Maybe<Array<RecurringJournalEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type AccountPeriodInput = {
  AccountPeriodID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  FYear?: Maybe<Scalars['Float']>;
  FPeriod?: Maybe<Scalars['Float']>;
  StartDate: Scalars['String'];
  EndDate: Scalars['String'];
  MonthEndClose?: Maybe<Scalars['Boolean']>;
  YearEndClose?: Maybe<Scalars['Boolean']>;
};

export type AccountPeriodYearAndPeriod = {
  __typename?: 'AccountPeriodYearAndPeriod';
  StartYear?: Maybe<Scalars['Float']>;
  StartPeriod?: Maybe<Scalars['Float']>;
};

export type AccountTaxLedgerEntity = AuditEntity & {
  __typename?: 'AccountTaxLedgerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
};

export enum AccountType {
  Asset = 'ASSET',
  Liability = 'LIABILITY',
  Equity = 'EQUITY',
  Revenue = 'REVENUE',
  Expense = 'EXPENSE'
}

export enum AcctPermission {
  AccessSecurityIcon = 'ACCESS_SECURITY_______ICON',
  AccessSecurityUserView = 'ACCESS_SECURITY_USER_______VIEW',
  AccessSecurityUserCreate = 'ACCESS_SECURITY_USER_______CREATE',
  AccessSecurityUserUpdate = 'ACCESS_SECURITY_USER_______UPDATE',
  AccessSecurityUserChangePassword = 'ACCESS_SECURITY_USER_______CHANGE_PASSWORD',
  AccessSecurityUserInactive = 'ACCESS_SECURITY_USER_______INACTIVE',
  AccessSecurityUserCompanyAccess = 'ACCESS_SECURITY_USER_______COMPANY_ACCESS',
  AccessSecurityUserUnblock = 'ACCESS_SECURITY_USER_______UNBLOCK',
  AccessSecurityUserResendInvitation = 'ACCESS_SECURITY_USER_______RESEND_INVITATION',
  AccessSecurityRoleView = 'ACCESS_SECURITY_ROLE_______VIEW',
  AccessSecurityRoleCreate = 'ACCESS_SECURITY_ROLE_______CREATE',
  AccessSecurityRoleUpdate = 'ACCESS_SECURITY_ROLE_______UPDATE',
  AccessSecurityRoleDelete = 'ACCESS_SECURITY_ROLE_______DELETE',
  AccessSecurityRoleDuplicate = 'ACCESS_SECURITY_ROLE_______DUPLICATE',
  CompanySetupIcon = 'COMPANY_SETUP__________ICON',
  CompanySetupView = 'COMPANY_SETUP__________VIEW',
  CompanySetupCreate = 'COMPANY_SETUP__________CREATE',
  CompanySetupUpdate = 'COMPANY_SETUP__________UPDATE',
  CommonSettingIcon = 'COMMON_SETTING__________ICON',
  CommonSettingTaxSetupView = 'COMMON_SETTING_TAX_SETUP_______VIEW',
  CommonSettingTaxSetupCreate = 'COMMON_SETTING_TAX_SETUP_______CREATE',
  CommonSettingTaxSetupUpdate = 'COMMON_SETTING_TAX_SETUP_______UPDATE',
  CommonSettingTaxSetupDelete = 'COMMON_SETTING_TAX_SETUP_______DELETE',
  CommonSettingTaxSetupInactive = 'COMMON_SETTING_TAX_SETUP_______INACTIVE',
  CommonSettingUomView = 'COMMON_SETTING_UOM_______VIEW',
  CommonSettingUomCreate = 'COMMON_SETTING_UOM_______CREATE',
  CommonSettingUomUpdate = 'COMMON_SETTING_UOM_______UPDATE',
  CommonSettingUomInactive = 'COMMON_SETTING_UOM_______INACTIVE',
  CommonSettingUomDelete = 'COMMON_SETTING_UOM_______DELETE',
  CommonSettingUomExchangeView = 'COMMON_SETTING_UOM_EXCHANGE_______VIEW',
  CommonSettingUomExchangeCreate = 'COMMON_SETTING_UOM_EXCHANGE_______CREATE',
  CommonSettingUomExchangeUpdate = 'COMMON_SETTING_UOM_EXCHANGE_______UPDATE',
  CommonSettingUomExchangeInactive = 'COMMON_SETTING_UOM_EXCHANGE_______INACTIVE',
  CommonSettingUomExchangeDelete = 'COMMON_SETTING_UOM_EXCHANGE_______DELETE',
  GeneralLedgerIcon = 'GENERAL_LEDGER__________ICON',
  GeneralLedgerJournalEnquiryView = 'GENERAL_LEDGER_JOURNAL_ENQUIRY_______VIEW',
  GeneralLedgerOriginalBudgetView = 'GENERAL_LEDGER_ORIGINAL_BUDGET_______VIEW',
  GeneralLedgerOriginalBudgetCreate = 'GENERAL_LEDGER_ORIGINAL_BUDGET_______CREATE',
  GeneralLedgerOriginalBudgetUpdate = 'GENERAL_LEDGER_ORIGINAL_BUDGET_______UPDATE',
  GeneralLedgerOriginalBudgetDraft = 'GENERAL_LEDGER_ORIGINAL_BUDGET_______DRAFT',
  GeneralLedgerOriginalBudgetVerified = 'GENERAL_LEDGER_ORIGINAL_BUDGET_______VERIFIED',
  GeneralLedgerBudgetRevisionView = 'GENERAL_LEDGER_BUDGET_REVISION_______VIEW',
  GeneralLedgerBudgetRevisionCreate = 'GENERAL_LEDGER_BUDGET_REVISION_______CREATE',
  GeneralLedgerBudgetRevisionUpdate = 'GENERAL_LEDGER_BUDGET_REVISION_______UPDATE',
  GeneralLedgerBudgetRevisionDraft = 'GENERAL_LEDGER_BUDGET_REVISION_______DRAFT',
  GeneralLedgerBudgetRevisionVerified = 'GENERAL_LEDGER_BUDGET_REVISION_______VERIFIED',
  GeneralLedgerJournalProcessingView = 'GENERAL_LEDGER_JOURNAL_PROCESSING_______VIEW',
  GeneralLedgerJournalProcessingCreate = 'GENERAL_LEDGER_JOURNAL_PROCESSING_______CREATE',
  GeneralLedgerJournalProcessingUpdate = 'GENERAL_LEDGER_JOURNAL_PROCESSING_______UPDATE',
  GeneralLedgerJournalProcessingDraft = 'GENERAL_LEDGER_JOURNAL_PROCESSING_______DRAFT',
  GeneralLedgerJournalProcessingDelete = 'GENERAL_LEDGER_JOURNAL_PROCESSING_______DELETE',
  GeneralLedgerJournalProcessingApproveReject = 'GENERAL_LEDGER_JOURNAL_PROCESSING_______APPROVE_REJECT',
  GeneralLedgerExternalJournalView = 'GENERAL_LEDGER_EXTERNAL_JOURNAL_______VIEW',
  GeneralLedgerExternalJournalImport = 'GENERAL_LEDGER_EXTERNAL_JOURNAL_______IMPORT',
  GeneralLedgerGenerateRecurringJournalView = 'GENERAL_LEDGER_GENERATE_RECURRING_JOURNAL_______VIEW',
  GeneralLedgerGenerateRecurringJournalCreate = 'GENERAL_LEDGER_GENERATE_RECURRING_JOURNAL_______CREATE',
  GeneralLedgerRecurringJournalView = 'GENERAL_LEDGER_RECURRING_JOURNAL_______VIEW',
  GeneralLedgerRecurringJournalCreate = 'GENERAL_LEDGER_RECURRING_JOURNAL_______CREATE',
  GeneralLedgerRecurringJournalUpdate = 'GENERAL_LEDGER_RECURRING_JOURNAL_______UPDATE',
  GeneralLedgerRecurringJournalDraft = 'GENERAL_LEDGER_RECURRING_JOURNAL_______DRAFT',
  GeneralLedgerRecurringJournalDelete = 'GENERAL_LEDGER_RECURRING_JOURNAL_______DELETE',
  GeneralLedgerRecurringJournalCancel = 'GENERAL_LEDGER_RECURRING_JOURNAL_______CANCEL',
  GeneralLedgerRecurringJournalApproveReject = 'GENERAL_LEDGER_RECURRING_JOURNAL_______APPROVE_REJECT',
  GeneralLedgerAuditAdjustmentView = 'GENERAL_LEDGER_AUDIT_ADJUSTMENT_______VIEW',
  GeneralLedgerAuditAdjustmentCreate = 'GENERAL_LEDGER_AUDIT_ADJUSTMENT_______CREATE',
  GeneralLedgerAuditAdjustmentUpdate = 'GENERAL_LEDGER_AUDIT_ADJUSTMENT_______UPDATE',
  GeneralLedgerAuditAdjustmentDraft = 'GENERAL_LEDGER_AUDIT_ADJUSTMENT_______DRAFT',
  GeneralLedgerAuditAdjustmentDelete = 'GENERAL_LEDGER_AUDIT_ADJUSTMENT_______DELETE',
  GeneralLedgerAuditAdjustmentApproveReject = 'GENERAL_LEDGER_AUDIT_ADJUSTMENT_______APPROVE_REJECT',
  GeneralLedgerClosingMonthYearEndView = 'GENERAL_LEDGER_CLOSING_MONTH_YEAR_END_______VIEW',
  GeneralLedgerDigitalReportsView = 'GENERAL_LEDGER_DIGITAL_REPORTS_______VIEW',
  GeneralLedgerDigitalReportsGlJournalView = 'GENERAL_LEDGER_DIGITAL_REPORTS_GL_JOURNAL____VIEW',
  GeneralLedgerDigitalReportsTrialBalanceView = 'GENERAL_LEDGER_DIGITAL_REPORTS_TRIAL_BALANCE____VIEW',
  GeneralLedgerDigitalReportsTrialBalanceCostCentreView = 'GENERAL_LEDGER_DIGITAL_REPORTS_TRIAL_BALANCE_COST_CENTRE____VIEW',
  GeneralLedgerDigitalReportsGlJournalCostCentreView = 'GENERAL_LEDGER_DIGITAL_REPORTS_GL_JOURNAL_COST_CENTRE____VIEW',
  GeneralLedgerDigitalReportsMasterCoaView = 'GENERAL_LEDGER_DIGITAL_REPORTS_MASTER_COA____VIEW',
  GeneralLedgerFinancialReportView = 'GENERAL_LEDGER_FINANCIAL_REPORT_______VIEW',
  GeneralLedgerFinancialReportSettingsDataSourceView = 'GENERAL_LEDGER_FINANCIAL_REPORT_SETTINGS_DATA_SOURCE_VIEW',
  GeneralLedgerFinancialReportSettingsDataSourceCreate = 'GENERAL_LEDGER_FINANCIAL_REPORT_SETTINGS_DATA_SOURCE_CREATE',
  GeneralLedgerFinancialReportSettingsDataSourceUpdate = 'GENERAL_LEDGER_FINANCIAL_REPORT_SETTINGS_DATA_SOURCE_UPDATE',
  GeneralLedgerFinancialReportSettingsDataSourceDelete = 'GENERAL_LEDGER_FINANCIAL_REPORT_SETTINGS_DATA_SOURCE_DELETE',
  GeneralLedgerFinancialReportSettingsFormulaToolsView = 'GENERAL_LEDGER_FINANCIAL_REPORT_SETTINGS_FORMULA_TOOLS_VIEW',
  GeneralLedgerFinancialReportSettingsReportFormatView = 'GENERAL_LEDGER_FINANCIAL_REPORT_SETTINGS_REPORT_FORMAT_VIEW',
  GeneralLedgerFinancialReportSettingsReportFormatCreate = 'GENERAL_LEDGER_FINANCIAL_REPORT_SETTINGS_REPORT_FORMAT_CREATE',
  GeneralLedgerFinancialReportSettingsReportFormatUpdate = 'GENERAL_LEDGER_FINANCIAL_REPORT_SETTINGS_REPORT_FORMAT_UPDATE',
  GeneralLedgerFinancialReportSettingsReportFormatDelete = 'GENERAL_LEDGER_FINANCIAL_REPORT_SETTINGS_REPORT_FORMAT_DELETE',
  GeneralLedgerFinancialReportSettingsReportFormatDownload = 'GENERAL_LEDGER_FINANCIAL_REPORT_SETTINGS_REPORT_FORMAT_DOWNLOAD',
  GeneralLedgerFinancialReportSettingsReportFormatUpload = 'GENERAL_LEDGER_FINANCIAL_REPORT_SETTINGS_REPORT_FORMAT_UPLOAD',
  GeneralLedgerGeneralSettingsView = 'GENERAL_LEDGER_GENERAL_SETTINGS_______VIEW',
  GeneralLedgerGeneralSettingsAccCodeStructureView = 'GENERAL_LEDGER_GENERAL_SETTINGS_ACC_CODE_STRUCTURE____VIEW',
  GeneralLedgerGeneralSettingsAccCodeStructureCreate = 'GENERAL_LEDGER_GENERAL_SETTINGS_ACC_CODE_STRUCTURE____CREATE',
  GeneralLedgerGeneralSettingsMasterCoaView = 'GENERAL_LEDGER_GENERAL_SETTINGS_MASTER_COA____VIEW',
  GeneralLedgerGeneralSettingsMasterCoaCreate = 'GENERAL_LEDGER_GENERAL_SETTINGS_MASTER_COA____CREATE',
  GeneralLedgerGeneralSettingsMasterCoaUpdate = 'GENERAL_LEDGER_GENERAL_SETTINGS_MASTER_COA____UPDATE',
  GeneralLedgerGeneralSettingsMasterCoaAssign = 'GENERAL_LEDGER_GENERAL_SETTINGS_MASTER_COA____ASSIGN',
  GeneralLedgerGeneralSettingsMasterCoaDelete = 'GENERAL_LEDGER_GENERAL_SETTINGS_MASTER_COA____DELETE',
  GeneralLedgerGeneralSettingsMasterCoaInactive = 'GENERAL_LEDGER_GENERAL_SETTINGS_MASTER_COA____INACTIVE',
  GeneralLedgerGeneralSettingsCurrencySetupView = 'GENERAL_LEDGER_GENERAL_SETTINGS_CURRENCY_SETUP____VIEW',
  GeneralLedgerGeneralSettingsCurrencySetupCreate = 'GENERAL_LEDGER_GENERAL_SETTINGS_CURRENCY_SETUP____CREATE',
  GeneralLedgerGeneralSettingsCurrencySetupUpdate = 'GENERAL_LEDGER_GENERAL_SETTINGS_CURRENCY_SETUP____UPDATE',
  GeneralLedgerGeneralSettingsCurrencySetupDelete = 'GENERAL_LEDGER_GENERAL_SETTINGS_CURRENCY_SETUP____DELETE',
  GeneralLedgerGeneralSettingsCurrencyExchangeView = 'GENERAL_LEDGER_GENERAL_SETTINGS_CURRENCY_EXCHANGE____VIEW',
  GeneralLedgerGeneralSettingsCurrencyExchangeCreate = 'GENERAL_LEDGER_GENERAL_SETTINGS_CURRENCY_EXCHANGE____CREATE',
  GeneralLedgerGeneralSettingsCurrencyExchangeUpdate = 'GENERAL_LEDGER_GENERAL_SETTINGS_CURRENCY_EXCHANGE____UPDATE',
  GeneralLedgerGeneralSettingsCurrencyExchangeDelete = 'GENERAL_LEDGER_GENERAL_SETTINGS_CURRENCY_EXCHANGE____DELETE',
  GeneralLedgerCompanySettingsView = 'GENERAL_LEDGER_COMPANY_SETTINGS_______VIEW',
  GeneralLedgerCompanySettingsCreate = 'GENERAL_LEDGER_COMPANY_SETTINGS_______CREATE',
  GeneralLedgerCompanySettingsCompanyProfileView = 'GENERAL_LEDGER_COMPANY_SETTINGS_COMPANY_PROFILE____VIEW',
  GeneralLedgerCompanySettingsCompanyProfileUpdate = 'GENERAL_LEDGER_COMPANY_SETTINGS_COMPANY_PROFILE____UPDATE',
  GeneralLedgerCompanySettingsCostCentreView = 'GENERAL_LEDGER_COMPANY_SETTINGS_COST_CENTRE____VIEW',
  GeneralLedgerCompanySettingsCostCentreCreate = 'GENERAL_LEDGER_COMPANY_SETTINGS_COST_CENTRE____CREATE',
  GeneralLedgerCompanySettingsCostCentreUpdate = 'GENERAL_LEDGER_COMPANY_SETTINGS_COST_CENTRE____UPDATE',
  GeneralLedgerCompanySettingsCostCentreDelete = 'GENERAL_LEDGER_COMPANY_SETTINGS_COST_CENTRE____DELETE',
  GeneralLedgerCompanySettingsCostCentreSetDefault = 'GENERAL_LEDGER_COMPANY_SETTINGS_COST_CENTRE____SET_DEFAULT',
  GeneralLedgerCompanySettingsEntityCoaView = 'GENERAL_LEDGER_COMPANY_SETTINGS_ENTITY_COA____VIEW',
  GeneralLedgerCompanySettingsEntityCoaInactive = 'GENERAL_LEDGER_COMPANY_SETTINGS_ENTITY_COA____INACTIVE',
  GeneralLedgerCompanySettingsEntityCoaUnassign = 'GENERAL_LEDGER_COMPANY_SETTINGS_ENTITY_COA____UNASSIGN',
  GeneralLedgerCompanySettingsNumberingStructureView = 'GENERAL_LEDGER_COMPANY_SETTINGS_NUMBERING_STRUCTURE____VIEW',
  GeneralLedgerCompanySettingsNumberingStructureCreate = 'GENERAL_LEDGER_COMPANY_SETTINGS_NUMBERING_STRUCTURE____CREATE',
  GeneralLedgerCompanySettingsNumberingStructureUpdate = 'GENERAL_LEDGER_COMPANY_SETTINGS_NUMBERING_STRUCTURE____UPDATE',
  GeneralLedgerCompanySettingsNumberingStructureDelete = 'GENERAL_LEDGER_COMPANY_SETTINGS_NUMBERING_STRUCTURE____DELETE',
  GeneralLedgerCompanySettingsAccountingPeriodView = 'GENERAL_LEDGER_COMPANY_SETTINGS_ACCOUNTING_PERIOD____VIEW',
  GeneralLedgerCompanySettingsAccountingPeriodCreate = 'GENERAL_LEDGER_COMPANY_SETTINGS_ACCOUNTING_PERIOD____CREATE',
  GeneralLedgerCompanySettingsAccountingPeriodUpdate = 'GENERAL_LEDGER_COMPANY_SETTINGS_ACCOUNTING_PERIOD____UPDATE',
  GeneralLedgerCompanySettingsAccountingPeriodDelete = 'GENERAL_LEDGER_COMPANY_SETTINGS_ACCOUNTING_PERIOD____DELETE',
  GeneralLedgerCompanySettingsJournalTypeView = 'GENERAL_LEDGER_COMPANY_SETTINGS_JOURNAL_TYPE____VIEW',
  GeneralLedgerCompanySettingsJournalTypeCreate = 'GENERAL_LEDGER_COMPANY_SETTINGS_JOURNAL_TYPE____CREATE',
  GeneralLedgerCompanySettingsJournalTypeUpdate = 'GENERAL_LEDGER_COMPANY_SETTINGS_JOURNAL_TYPE____UPDATE',
  GeneralLedgerCompanySettingsJournalTypeDelete = 'GENERAL_LEDGER_COMPANY_SETTINGS_JOURNAL_TYPE____DELETE',
  GeneralLedgerCompanySettingsRetainEarningAccView = 'GENERAL_LEDGER_COMPANY_SETTINGS_RETAIN_EARNING_ACC____VIEW',
  GeneralLedgerCompanySettingsRetainEarningAccCreate = 'GENERAL_LEDGER_COMPANY_SETTINGS_RETAIN_EARNING_ACC____CREATE',
  GeneralLedgerCompanySettingsCompanyAuthorizationView = 'GENERAL_LEDGER_COMPANY_SETTINGS_COMPANY_AUTHORIZATION____VIEW',
  CashBookIcon = 'CASH_BOOK__________ICON',
  CashBookPaymentView = 'CASH_BOOK_PAYMENT_______VIEW',
  CashBookPaymentCreate = 'CASH_BOOK_PAYMENT_______CREATE',
  CashBookPaymentUpdate = 'CASH_BOOK_PAYMENT_______UPDATE',
  CashBookPaymentDraft = 'CASH_BOOK_PAYMENT_______DRAFT',
  CashBookPaymentApproveReject = 'CASH_BOOK_PAYMENT_______APPROVE_REJECT',
  CashBookPaymentDelete = 'CASH_BOOK_PAYMENT_______DELETE',
  CashBookReceiptView = 'CASH_BOOK_RECEIPT_______VIEW',
  CashBookReceiptCreate = 'CASH_BOOK_RECEIPT_______CREATE',
  CashBookReceiptUpdate = 'CASH_BOOK_RECEIPT_______UPDATE',
  CashBookReceiptApproveReject = 'CASH_BOOK_RECEIPT_____APPROVE_REJECT',
  CashBookReceiptDraft = 'CASH_BOOK_RECEIPT_____DRAFT',
  CashBookReceiptDelete = 'CASH_BOOK_RECEIPT_____DELETE',
  CashBookBankTransferView = 'CASH_BOOK_BANK_TRANSFER_______VIEW',
  CashBookBankTransferCreate = 'CASH_BOOK_BANK_TRANSFER_______CREATE',
  CashBookBankTransferUpdate = 'CASH_BOOK_BANK_TRANSFER_______UPDATE',
  CashBookBankTransferDraft = 'CASH_BOOK_BANK_TRANSFER_______DRAFT',
  CashBookBankTransferDelete = 'CASH_BOOK_BANK_TRANSFER_______DELETE',
  CashBookBankTransferApproveReject = 'CASH_BOOK_BANK_TRANSFER_______APPROVE_REJECT',
  CashBookBankReconcilationView = 'CASH_BOOK_BANK_RECONCILATION_______VIEW',
  CashBookBankReconcilationCreate = 'CASH_BOOK_BANK_RECONCILATION_______CREATE',
  CashBookBankReconcilationUpdate = 'CASH_BOOK_BANK_RECONCILATION_______UPDATE',
  CashBookBankReconcilationDelete = 'CASH_BOOK_BANK_RECONCILATION_______DELETE',
  CashBookBankReconcilationMatch = 'CASH_BOOK_BANK_RECONCILATION_______MATCH',
  CashBookBankReconcilationUnmatch = 'CASH_BOOK_BANK_RECONCILATION_______UNMATCH',
  CashBookExternalLedgerView = 'CASH_BOOK_EXTERNAL_LEDGER_______VIEW',
  CashBookExternalLedgerImport = 'CASH_BOOK_EXTERNAL_LEDGER_______IMPORT',
  CashBookGlExportView = 'CASH_BOOK_GL_EXPORT_______VIEW',
  CashBookGlExportShare = 'CASH_BOOK_GL_EXPORT_______SHARE',
  CashBookGlExportDownload = 'CASH_BOOK_GL_EXPORT_______DOWNLOAD',
  CashBookDigitalReportView = 'CASH_BOOK_DIGITAL_REPORT_______VIEW',
  CashBookDigitalReportCashLedgerView = 'CASH_BOOK_DIGITAL_REPORT_CASH_LEDGER____VIEW',
  CashBookDigitalReportCashInOutFlowView = 'CASH_BOOK_DIGITAL_REPORT_CASH_IN_OUT_FLOW____VIEW',
  CashBookDigitalReportBankReconView = 'CASH_BOOK_DIGITAL_REPORT_BANK_RECON____VIEW',
  CashBookGeneralSettingsView = 'CASH_BOOK_GENERAL_SETTINGS_______VIEW',
  CashBookGeneralSettingsBankProfileView = 'CASH_BOOK_GENERAL_SETTINGS_BANK_PROFILE____VIEW',
  CashBookGeneralSettingsBankProfileCreate = 'CASH_BOOK_GENERAL_SETTINGS_BANK_PROFILE____CREATE',
  CashBookGeneralSettingsBankProfileUpdate = 'CASH_BOOK_GENERAL_SETTINGS_BANK_PROFILE____UPDATE',
  CashBookGeneralSettingsBankProfileDelete = 'CASH_BOOK_GENERAL_SETTINGS_BANK_PROFILE____DELETE',
  CashBookGeneralSettingsPolicySettingsView = 'CASH_BOOK_GENERAL_SETTINGS_POLICY_SETTINGS____VIEW',
  CashBookGeneralSettingsPolicySettingsCreate = 'CASH_BOOK_GENERAL_SETTINGS_POLICY_SETTINGS____CREATE',
  CashBookGeneralSettingsTaxSetupView = 'CASH_BOOK_GENERAL_SETTINGS_TAX_SETUP____VIEW',
  CashBookGeneralSettingsTaxSetupCreate = 'CASH_BOOK_GENERAL_SETTINGS_TAX_SETUP____CREATE',
  CashBookGeneralSettingsTaxSetupUpdate = 'CASH_BOOK_GENERAL_SETTINGS_TAX_SETUP____UPDATE',
  CashBookGeneralSettingsTaxSetupInactive = 'CASH_BOOK_GENERAL_SETTINGS_TAX_SETUP____INACTIVE',
  CashBookGeneralSettingsTaxSetupDelete = 'CASH_BOOK_GENERAL_SETTINGS_TAX_SETUP____DELETE',
  CashBookGeneralSettingsPaymentMethodView = 'CASH_BOOK_GENERAL_SETTINGS_PAYMENT_METHOD____VIEW',
  CashBookGeneralSettingsPaymentMethodCreate = 'CASH_BOOK_GENERAL_SETTINGS_PAYMENT_METHOD____CREATE',
  CashBookGeneralSettingsPaymentMethodUpdate = 'CASH_BOOK_GENERAL_SETTINGS_PAYMENT_METHOD____UPDATE',
  CashBookGeneralSettingsPaymentMethodDelete = 'CASH_BOOK_GENERAL_SETTINGS_PAYMENT_METHOD____DELETE',
  CashBookGeneralSettingsCreditCardTypeView = 'CASH_BOOK_GENERAL_SETTINGS_CREDIT_CARD_TYPE____VIEW',
  CashBookGeneralSettingsCreditCardTypeCreate = 'CASH_BOOK_GENERAL_SETTINGS_CREDIT_CARD_TYPE____CREATE',
  CashBookGeneralSettingsCreditCardTypeUpdate = 'CASH_BOOK_GENERAL_SETTINGS_CREDIT_CARD_TYPE____UPDATE',
  CashBookGeneralSettingsCreditCardTypeDelete = 'CASH_BOOK_GENERAL_SETTINGS_CREDIT_CARD_TYPE____DELETE',
  CashBookCompanySettingsView = 'CASH_BOOK_COMPANY_SETTINGS_____VIEW',
  CashBookCompanySettingsNumberingStructuresView = 'CASH_BOOK_COMPANY_SETTINGS_NUMBERING_STRUCTURES____VIEW',
  CashBookCompanySettingsNumberingStructuresCreate = 'CASH_BOOK_COMPANY_SETTINGS_NUMBERING_STRUCTURES____CREATE',
  CashBookCompanySettingsNumberingStructuresUpdate = 'CASH_BOOK_COMPANY_SETTINGS_NUMBERING_STRUCTURES____UPDATE',
  CashBookCompanySettingsNumberingStructuresDelete = 'CASH_BOOK_COMPANY_SETTINGS_NUMBERING_STRUCTURES____DELETE',
  CashBookCompanySettingsBankAccountView = 'CASH_BOOK_COMPANY_SETTINGS_BANK_ACCOUNT____VIEW',
  CashBookCompanySettingsBankAccountCreate = 'CASH_BOOK_COMPANY_SETTINGS_BANK_ACCOUNT____CREATE',
  CashBookCompanySettingsBankAccountUpdate = 'CASH_BOOK_COMPANY_SETTINGS_BANK_ACCOUNT____UPDATE',
  CashBookCompanySettingsBankAccountDelete = 'CASH_BOOK_COMPANY_SETTINGS_BANK_ACCOUNT____DELETE',
  CashBookCompanySettingsBankAccountInactive = 'CASH_BOOK_COMPANY_SETTINGS_BANK_ACCOUNT____INACTIVE',
  AccPayableIcon = 'ACC_PAYABLE__________ICON',
  AccPayableCreditorProfileView = 'ACC_PAYABLE_CREDITOR_PROFILE_______VIEW',
  AccPayableCreditorProfileUpdate = 'ACC_PAYABLE_CREDITOR_PROFILE_______UPDATE',
  AccPayableAdvancesView = 'ACC_PAYABLE_ADVANCES_______VIEW',
  AccPayableAdvancesCreate = 'ACC_PAYABLE_ADVANCES_______CREATE',
  AccPayableAdvancesUpdate = 'ACC_PAYABLE_ADVANCES_______UPDATE',
  AccPayableAdvancesDraft = 'ACC_PAYABLE_ADVANCES_______DRAFT',
  AccPayableAdvancesDelete = 'ACC_PAYABLE_ADVANCES_______DELETE',
  AccPayableAdvancesApproveReject = 'ACC_PAYABLE_ADVANCES_______APPROVE_REJECT',
  AccPayableAdvancesAllocation = 'ACC_PAYABLE_ADVANCES_______ALLOCATION',
  AccPayableInvoicesView = 'ACC_PAYABLE_INVOICES_______VIEW',
  AccPayableInvoicesCreate = 'ACC_PAYABLE_INVOICES_______CREATE',
  AccPayableInvoicesUpdate = 'ACC_PAYABLE_INVOICES_______UPDATE',
  AccPayableInvoicesDraft = 'ACC_PAYABLE_INVOICES_______DRAFT',
  AccPayableInvoicesDelete = 'ACC_PAYABLE_INVOICES_______DELETE',
  AccPayableInvoicesApproveReject = 'ACC_PAYABLE_INVOICES_______APPROVE_REJECT',
  AccPayableCreditNotesView = 'ACC_PAYABLE_CREDIT_NOTES_______VIEW',
  AccPayableCreditNotesCreate = 'ACC_PAYABLE_CREDIT_NOTES_______CREATE',
  AccPayableCreditNotesUpdate = 'ACC_PAYABLE_CREDIT_NOTES_______UPDATE',
  AccPayableCreditNotesAllocation = 'ACC_PAYABLE_CREDIT_NOTES_______ALLOCATION',
  AccPayableCreditNotesDraft = 'ACC_PAYABLE_CREDIT_NOTES_______DRAFT',
  AccPayableCreditNotesDelete = 'ACC_PAYABLE_CREDIT_NOTES_______DELETE',
  AccPayableCreditNotesApproveReject = 'ACC_PAYABLE_CREDIT_NOTES_______APPROVE_REJECT',
  AccPayableCreditorCreditNotesView = 'ACC_PAYABLE_CREDITOR_CREDIT_NOTES_______VIEW',
  AccPayableCreditorCreditNotesCreate = 'ACC_PAYABLE_CREDITOR_CREDIT_NOTES_______CREATE',
  AccPayableCreditorCreditNotesUpdate = 'ACC_PAYABLE_CREDITOR_CREDIT_NOTES_______UPDATE',
  AccPayableCreditorCreditNotesAllocation = 'ACC_PAYABLE_CREDITOR_CREDIT_NOTES_______ALLOCATION',
  AccPayableCreditorCreditNotesDraft = 'ACC_PAYABLE_CREDITOR_CREDIT_NOTES_______DRAFT',
  AccPayableCreditorCreditNotesDelete = 'ACC_PAYABLE_CREDITOR_CREDIT_NOTES_______DELETE',
  AccPayableCreditorCreditNotesApproveReject = 'ACC_PAYABLE_CREDITOR_CREDIT_NOTES_______APPROVE_REJECT',
  AccPayableDebitNotesView = 'ACC_PAYABLE_DEBIT_NOTES_______VIEW',
  AccPayableDebitNotesCreate = 'ACC_PAYABLE_DEBIT_NOTES_______CREATE',
  AccPayableDebitNotesUpdate = 'ACC_PAYABLE_DEBIT_NOTES_______UPDATE',
  AccPayableDebitNotesDraft = 'ACC_PAYABLE_DEBIT_NOTES_______DRAFT',
  AccPayableDebitNotesDelete = 'ACC_PAYABLE_DEBIT_NOTES_______DELETE',
  AccPayableDebitNotesApproveReject = 'ACC_PAYABLE_DEBIT_NOTES_______APPROVE_REJECT',
  AccPayableCreditorDebitNotesView = 'ACC_PAYABLE_CREDITOR_DEBIT_NOTES_______VIEW',
  AccPayableCreditorDebitNotesCreate = 'ACC_PAYABLE_CREDITOR_DEBIT_NOTES_______CREATE',
  AccPayableCreditorDebitNotesUpdate = 'ACC_PAYABLE_CREDITOR_DEBIT_NOTES_______UPDATE',
  AccPayableCreditorDebitNotesDraft = 'ACC_PAYABLE_CREDITOR_DEBIT_NOTES_______DRAFT',
  AccPayableCreditorDebitNotesDelete = 'ACC_PAYABLE_CREDITOR_DEBIT_NOTES_______DELETE',
  AccPayableCreditorDebitNotesApproveReject = 'ACC_PAYABLE_CREDITOR_DEBIT_NOTES_______APPROVE_REJECT',
  AccPayablePaymentView = 'ACC_PAYABLE_PAYMENT_______VIEW',
  AccPayablePaymentCreate = 'ACC_PAYABLE_PAYMENT_______CREATE',
  AccPayablePaymentUpdate = 'ACC_PAYABLE_PAYMENT_______UPDATE',
  AccPayablePaymentAllocation = 'ACC_PAYABLE_PAYMENT_______ALLOCATION',
  AccPayablePaymentDraft = 'ACC_PAYABLE_PAYMENT_______DRAFT',
  AccPayablePaymentDelete = 'ACC_PAYABLE_PAYMENT_______DELETE',
  AccPayablePaymentApproveReject = 'ACC_PAYABLE_PAYMENT_______APPROVE_REJECT',
  AccPayableBatchPaymentView = 'ACC_PAYABLE_BATCH_PAYMENT_______VIEW',
  AccPayableBatchPaymentCreate = 'ACC_PAYABLE_BATCH_PAYMENT_______CREATE',
  AccPayableBatchPaymentUpdate = 'ACC_PAYABLE_BATCH_PAYMENT_______UPDATE',
  AccPayableBatchPaymentDraft = 'ACC_PAYABLE_BATCH_PAYMENT_______DRAFT',
  AccPayableBatchPaymentDelete = 'ACC_PAYABLE_BATCH_PAYMENT_______DELETE',
  AccPayableBatchPaymentPaid = 'ACC_PAYABLE_BATCH_PAYMENT_______PAID',
  AccPayableBatchPaymentApproveReject = 'ACC_PAYABLE_BATCH_PAYMENT_______APPROVE_REJECT',
  AccPayableRefundView = 'ACC_PAYABLE_REFUND_______VIEW',
  AccPayableRefundCreate = 'ACC_PAYABLE_REFUND_______CREATE',
  AccPayableRefundUpdate = 'ACC_PAYABLE_REFUND_______UPDATE',
  AccPayableRefundDraft = 'ACC_PAYABLE_REFUND_______DRAFT',
  AccPayableRefundDelete = 'ACC_PAYABLE_REFUND_______DELETE',
  AccPayableRefundApproveReject = 'ACC_PAYABLE_REFUND_______APPROVE_REJECT',
  AccPayableSelfBilledView = 'ACC_PAYABLE_SELF_BILLED_______VIEW',
  AccPayableSelfBilledCreate = 'ACC_PAYABLE_SELF_BILLED_______CREATE',
  AccPayableSelfBilledUpdate = 'ACC_PAYABLE_SELF_BILLED_______UPDATE',
  AccPayableSelfBilledDraft = 'ACC_PAYABLE_SELF_BILLED_______DRAFT',
  AccPayableSelfBilledDelete = 'ACC_PAYABLE_SELF_BILLED_______DELETE',
  AccPayableSelfBilledApproveReject = 'ACC_PAYABLE_SELF_BILLED_______APPROVE_REJECT',
  AccPayableDigitalReportView = 'ACC_PAYABLE_DIGITAL_REPORT_______VIEW',
  AccPayableDigitalReportOverduePayableView = 'ACC_PAYABLE_DIGITAL_REPORT_OVERDUE_PAYABLE______VIEW',
  AccPayableDigitalReportCreditorLedgerByDocDateView = 'ACC_PAYABLE_DIGITAL_REPORT_CREDITOR_LEDGER_BY_DOC_DATE______VIEW',
  AccPayableDigitalReportCreditorAgingByDocDateView = 'ACC_PAYABLE_DIGITAL_REPORT_CREDITOR_AGING_BY_DOC_DATE______VIEW',
  AccPayableDigitalReportAdvanceLedgerView = 'ACC_PAYABLE_DIGITAL_REPORT_ADVANCE_LEDGER____VIEW',
  AccPayableDigitalReportCreditorLedgerDetailTrxDateView = 'ACC_PAYABLE_DIGITAL_REPORT_CREDITOR_LEDGER_DETAIL_TRX_DATE____VIEW',
  AccPayableDigitalReportCreditorAgingTrxDateView = 'ACC_PAYABLE_DIGITAL_REPORT_CREDITOR_AGING_TRX_DATE____VIEW',
  AccPayableDigitalReportCreditorAgingSummaryDocDateView = 'ACC_PAYABLE_DIGITAL_REPORT_CREDITOR_AGING_SUMMARY_DOC_DATE____VIEW',
  AccPayableDigitalReportCreditorAgingSummaryTrxDateView = 'ACC_PAYABLE_DIGITAL_REPORT_CREDITOR_AGING_SUMMARY_TRX_DATE____VIEW',
  AccPayableDigitalReportCreditorControlSummaryView = 'ACC_PAYABLE_DIGITAL_REPORT_CREDITOR_CONTROL_SUMMARY____VIEW',
  AccPayableDigitalReportCreditorControlDetailView = 'ACC_PAYABLE_DIGITAL_REPORT_CREDITOR_CONTROL_DETAIL____VIEW',
  AccPayableGeneralSettingsView = 'ACC_PAYABLE_GENERAL_SETTINGS_____VIEW',
  AccPayableGeneralSettingsCreditorTypeView = 'ACC_PAYABLE_GENERAL_SETTINGS_CREDITOR_TYPE____VIEW',
  AccPayableGeneralSettingsCreditorTypeCreate = 'ACC_PAYABLE_GENERAL_SETTINGS_CREDITOR_TYPE____CREATE',
  AccPayableGeneralSettingsCreditorTypeUpdate = 'ACC_PAYABLE_GENERAL_SETTINGS_CREDITOR_TYPE____UPDATE',
  AccPayableGeneralSettingsCreditorTypeDelete = 'ACC_PAYABLE_GENERAL_SETTINGS_CREDITOR_TYPE____DELETE',
  AccPayableGeneralSettingsCreditorProfileView = 'ACC_PAYABLE_GENERAL_SETTINGS_CREDITOR_PROFILE____VIEW',
  AccPayableGeneralSettingsCreditorProfileCreate = 'ACC_PAYABLE_GENERAL_SETTINGS_CREDITOR_PROFILE____CREATE',
  AccPayableGeneralSettingsCreditorProfileAssign = 'ACC_PAYABLE_GENERAL_SETTINGS_CREDITOR_PROFILE____ASSIGN',
  AccPayableGeneralSettingsCreditorProfileUpdate = 'ACC_PAYABLE_GENERAL_SETTINGS_CREDITOR_PROFILE____UPDATE',
  AccPayableGeneralSettingsCreditorProfileInactive = 'ACC_PAYABLE_GENERAL_SETTINGS_CREDITOR_PROFILE____INACTIVE',
  AccPayableGeneralSettingsExpenseClassView = 'ACC_PAYABLE_GENERAL_SETTINGS_EXPENSE_CLASS____VIEW',
  AccPayableGeneralSettingsExpenseClassCreate = 'ACC_PAYABLE_GENERAL_SETTINGS_EXPENSE_CLASS____CREATE',
  AccPayableGeneralSettingsExpenseClassUpdate = 'ACC_PAYABLE_GENERAL_SETTINGS_EXPENSE_CLASS____UPDATE',
  AccPayableGeneralSettingsExpenseClassAssign = 'ACC_PAYABLE_GENERAL_SETTINGS_EXPENSE_CLASS____ASSIGN',
  AccPayableGeneralSettingsExpenseClassDelete = 'ACC_PAYABLE_GENERAL_SETTINGS_EXPENSE_CLASS____DELETE',
  AccPayableGeneralSettingsExpenseItemView = 'ACC_PAYABLE_GENERAL_SETTINGS_EXPENSE_ITEM____VIEW',
  AccPayableGeneralSettingsExpenseItemCreate = 'ACC_PAYABLE_GENERAL_SETTINGS_EXPENSE_ITEM____CREATE',
  AccPayableGeneralSettingsExpenseItemUpdate = 'ACC_PAYABLE_GENERAL_SETTINGS_EXPENSE_ITEM____UPDATE',
  AccPayableGeneralSettingsExpenseItemInactive = 'ACC_PAYABLE_GENERAL_SETTINGS_EXPENSE_ITEM____INACTIVE',
  AccPayableGeneralSettingsExpenseItemDelete = 'ACC_PAYABLE_GENERAL_SETTINGS_EXPENSE_ITEM____DELETE',
  AccPayableGeneralSettingsUomSetupView = 'ACC_PAYABLE_GENERAL_SETTINGS_UOM_SETUP____VIEW',
  AccPayableGeneralSettingsUomSetupCreate = 'ACC_PAYABLE_GENERAL_SETTINGS_UOM_SETUP____CREATE',
  AccPayableGeneralSettingsUomSetupUpdate = 'ACC_PAYABLE_GENERAL_SETTINGS_UOM_SETUP____UPDATE',
  AccPayableGeneralSettingsUomSetupDelete = 'ACC_PAYABLE_GENERAL_SETTINGS_UOM_SETUP____DELETE',
  AccPayableGeneralSettingsUomConversionView = 'ACC_PAYABLE_GENERAL_SETTINGS_UOM_CONVERSION____VIEW',
  AccPayableGeneralSettingsUomConversionCreate = 'ACC_PAYABLE_GENERAL_SETTINGS_UOM_CONVERSION____CREATE',
  AccPayableGeneralSettingsUomConversionUpdate = 'ACC_PAYABLE_GENERAL_SETTINGS_UOM_CONVERSION____UPDATE',
  AccPayableGeneralSettingsUomConversionDelete = 'ACC_PAYABLE_GENERAL_SETTINGS_UOM_CONVERSION____DELETE',
  AccPayableCompanySettingsView = 'ACC_PAYABLE_COMPANY_SETTINGS____VIEW',
  AccPayableCompanySettingsNumberingStructuresView = 'ACC_PAYABLE_COMPANY_SETTINGS_NUMBERING_STRUCTURES____VIEW',
  AccPayableCompanySettingsNumberingStructuresCreate = 'ACC_PAYABLE_COMPANY_SETTINGS_NUMBERING_STRUCTURES____CREATE',
  AccPayableCompanySettingsNumberingStructuresUpdate = 'ACC_PAYABLE_COMPANY_SETTINGS_NUMBERING_STRUCTURES____UPDATE',
  AccPayableCompanySettingsNumberingStructuresDelete = 'ACC_PAYABLE_COMPANY_SETTINGS_NUMBERING_STRUCTURES____DELETE',
  AccPayableCompanySettingsAgingView = 'ACC_PAYABLE_COMPANY_SETTINGS_AGING____VIEW',
  AccPayableCompanySettingsAgingCreate = 'ACC_PAYABLE_COMPANY_SETTINGS_AGING____CREATE',
  AccReceivableIcon = 'ACC_RECEIVABLE__________ICON',
  AccReceivableDebtorAccView = 'ACC_RECEIVABLE_DEBTOR_ACC_______VIEW',
  AccReceivableDebtorAccCreate = 'ACC_RECEIVABLE_DEBTOR_ACC_______CREATE',
  AccReceivableDebtorAccUpdate = 'ACC_RECEIVABLE_DEBTOR_ACC_______UPDATE',
  AccReceivableInvoicesView = 'ACC_RECEIVABLE_INVOICES_______VIEW',
  AccReceivableInvoicesCreate = 'ACC_RECEIVABLE_INVOICES_______CREATE',
  AccReceivableInvoicesUpdate = 'ACC_RECEIVABLE_INVOICES_______UPDATE',
  AccReceivableInvoicesDraft = 'ACC_RECEIVABLE_INVOICES_______DRAFT',
  AccReceivableInvoicesDelete = 'ACC_RECEIVABLE_INVOICES_______DELETE',
  AccReceivableInvoicesApproveReject = 'ACC_RECEIVABLE_INVOICES_______APPROVE_REJECT',
  AccReceivableCreditNotesView = 'ACC_RECEIVABLE_CREDIT_NOTES_______VIEW',
  AccReceivableCreditNotesCreate = 'ACC_RECEIVABLE_CREDIT_NOTES_______CREATE',
  AccReceivableCreditNotesUpdate = 'ACC_RECEIVABLE_CREDIT_NOTES_______UPDATE',
  AccReceivableCreditNotesDraft = 'ACC_RECEIVABLE_CREDIT_NOTES_______DRAFT',
  AccReceivableCreditNotesDelete = 'ACC_RECEIVABLE_CREDIT_NOTES_______DELETE',
  AccReceivableCreditNotesAllocation = 'ACC_RECEIVABLE_CREDIT_NOTES_______ALLOCATION',
  AccReceivableCreditNotesApproveReject = 'ACC_RECEIVABLE_CREDIT_NOTES_______APPROVE_REJECT',
  AccReceivableDebitNotesView = 'ACC_RECEIVABLE_DEBIT_NOTES_______VIEW',
  AccReceivableDebitNotesCreate = 'ACC_RECEIVABLE_DEBIT_NOTES_______CREATE',
  AccReceivableDebitNotesUpdate = 'ACC_RECEIVABLE_DEBIT_NOTES_______UPDATE',
  AccReceivableDebitNotesDraft = 'ACC_RECEIVABLE_DEBIT_NOTES_______DRAFT',
  AccReceivableDebitNotesDelete = 'ACC_RECEIVABLE_DEBIT_NOTES_______DELETE',
  AccReceivableDebitNotesApproveReject = 'ACC_RECEIVABLE_DEBIT_NOTES_______APPROVE_REJECT',
  AccReceivableReceiptsView = 'ACC_RECEIVABLE_RECEIPTS_______VIEW',
  AccReceivableReceiptsCreate = 'ACC_RECEIVABLE_RECEIPTS_______CREATE',
  AccReceivableReceiptsUpdate = 'ACC_RECEIVABLE_RECEIPTS_______UPDATE',
  AccReceivableReceiptsDraft = 'ACC_RECEIVABLE_RECEIPTS_______DRAFT',
  AccReceivableReceiptsDelete = 'ACC_RECEIVABLE_RECEIPTS_______DELETE',
  AccReceivableReceiptsAllocation = 'ACC_RECEIVABLE_RECEIPTS_______ALLOCATION',
  AccReceivableReceiptsApproveReject = 'ACC_RECEIVABLE_RECEIPTS_______APPROVE_REJECT',
  AccReceivableAdvancesView = 'ACC_RECEIVABLE_ADVANCES_______VIEW',
  AccReceivableAdvancesCreate = 'ACC_RECEIVABLE_ADVANCES_______CREATE',
  AccReceivableAdvancesUpdate = 'ACC_RECEIVABLE_ADVANCES_______UPDATE',
  AccReceivableAdvancesDraft = 'ACC_RECEIVABLE_ADVANCES_______DRAFT',
  AccReceivableAdvancesDelete = 'ACC_RECEIVABLE_ADVANCES_______DELETE',
  AccReceivableAdvancesAllocation = 'ACC_RECEIVABLE_ADVANCES_______ALLOCATION',
  AccReceivableAdvancesApproveReject = 'ACC_RECEIVABLE_ADVANCES_______APPROVE_REJECT',
  AccReceivableRefundView = 'ACC_RECEIVABLE_REFUND_______VIEW',
  AccReceivableRefundCreate = 'ACC_RECEIVABLE_REFUND_______CREATE',
  AccReceivableRefundUpdate = 'ACC_RECEIVABLE_REFUND_______UPDATE',
  AccReceivableRefundDraft = 'ACC_RECEIVABLE_REFUND_______DRAFT',
  AccReceivableRefundDelete = 'ACC_RECEIVABLE_REFUND_______DELETE',
  AccReceivableRefundApproveReject = 'ACC_RECEIVABLE_REFUND_______APPROVE_REJECT',
  AccReceivableConsolidateEinvoiceView = 'ACC_RECEIVABLE_CONSOLIDATE_EINVOICE_______VIEW',
  AccReceivableConsolidateEinvoiceCreate = 'ACC_RECEIVABLE_CONSOLIDATE_EINVOICE_______CREATE',
  AccReceivableConsolidateEinvoiceSendLhdn = 'ACC_RECEIVABLE_CONSOLIDATE_EINVOICE_______SEND_LHDN',
  AccReceivableDigitalReportsView = 'ACC_RECEIVABLE_DIGITAL_REPORTS_______VIEW',
  AccReceivableDigitalReportsDebtorLedgerByDocDateView = 'ACC_RECEIVABLE_DIGITAL_REPORTS_DEBTOR_LEDGER_BY_DOC_DATE______VIEW',
  AccReceivableDigitalReportsStatementAccountView = 'ACC_RECEIVABLE_DIGITAL_REPORTS_STATEMENT_ACCOUNT____VIEW',
  AccReceivableDigitalReportsDebtorAgingSummaryByTrxDateView = 'ACC_RECEIVABLE_DIGITAL_REPORTS_DEBTOR_AGING_SUMMARY_BY_TRX_DATE____VIEW',
  AccReceivableDigitalReportsDebtorAgingSummaryByDocDateView = 'ACC_RECEIVABLE_DIGITAL_REPORTS_DEBTOR_AGING_SUMMARY_BY_DOC_DATE____VIEW',
  AccReceivableDigitalReportsDebtorAgingDetailByTrxDateView = 'ACC_RECEIVABLE_DIGITAL_REPORTS_DEBTOR_AGING_DETAIL_BY_TRX_DATE____VIEW',
  AccReceivableDigitalReportsDebtorControlSummaryView = 'ACC_RECEIVABLE_DIGITAL_REPORTS_DEBTOR_CONTROL_SUMMARY____VIEW',
  AccReceivableDigitalReportsDebtorControlDetailView = 'ACC_RECEIVABLE_DIGITAL_REPORTS_DEBTOR_CONTROL_DETAIL____VIEW',
  AccReceivableGeneralSettingsView = 'ACC_RECEIVABLE_GENERAL_SETTINGS______VIEW',
  AccReceivableGeneralSettingsDebtorTypeView = 'ACC_RECEIVABLE_GENERAL_SETTINGS_DEBTOR_TYPE___VIEW',
  AccReceivableGeneralSettingsDebtorTypeCreate = 'ACC_RECEIVABLE_GENERAL_SETTINGS_DEBTOR_TYPE___CREATE',
  AccReceivableGeneralSettingsDebtorTypeUpdate = 'ACC_RECEIVABLE_GENERAL_SETTINGS_DEBTOR_TYPE___UPDATE',
  AccReceivableGeneralSettingsDebtorTypeDelete = 'ACC_RECEIVABLE_GENERAL_SETTINGS_DEBTOR_TYPE___DELETE',
  AccReceivableGeneralSettingsBillItemView = 'ACC_RECEIVABLE_GENERAL_SETTINGS_BILL_ITEM___VIEW',
  AccReceivableGeneralSettingsBillItemCreate = 'ACC_RECEIVABLE_GENERAL_SETTINGS_BILL_ITEM___CREATE',
  AccReceivableGeneralSettingsBillItemUpdate = 'ACC_RECEIVABLE_GENERAL_SETTINGS_BILL_ITEM___UPDATE',
  AccReceivableGeneralSettingsBillItemDelete = 'ACC_RECEIVABLE_GENERAL_SETTINGS_BILL_ITEM___DELETE',
  AccReceivableCompanySettingsView = 'ACC_RECEIVABLE_COMPANY_SETTINGS____VIEW',
  AccReceivableCompanySettingsNumberingStructuresView = 'ACC_RECEIVABLE_COMPANY_SETTINGS_NUMBERING_STRUCTURES___VIEW',
  AccReceivableCompanySettingsNumberingStructuresCreate = 'ACC_RECEIVABLE_COMPANY_SETTINGS_NUMBERING_STRUCTURES___CREATE',
  AccReceivableCompanySettingsNumberingStructuresUpdate = 'ACC_RECEIVABLE_COMPANY_SETTINGS_NUMBERING_STRUCTURES___UPDATE',
  AccReceivableCompanySettingsNumberingStructuresDelete = 'ACC_RECEIVABLE_COMPANY_SETTINGS_NUMBERING_STRUCTURES___DELETE',
  AccReceivableCompanySettingsAgingView = 'ACC_RECEIVABLE_COMPANY_SETTINGS_AGING___VIEW',
  AccReceivableCompanySettingsAgingCreate = 'ACC_RECEIVABLE_COMPANY_SETTINGS_AGING___CREATE',
  InventoryControlIcon = 'INVENTORY_CONTROL__________ICON',
  InventoryControlStockReceiptView = 'INVENTORY_CONTROL_STOCK_RECEIPT_______VIEW',
  InventoryControlStockReceiptCreate = 'INVENTORY_CONTROL_STOCK_RECEIPT_______CREATE',
  InventoryControlStockReceiptUpdate = 'INVENTORY_CONTROL_STOCK_RECEIPT_______UPDATE',
  InventoryControlStockReceiptDraft = 'INVENTORY_CONTROL_STOCK_RECEIPT_______DRAFT',
  InventoryControlStockReceiptDelete = 'INVENTORY_CONTROL_STOCK_RECEIPT_______DELETE',
  InventoryControlStockReceiptApproveReject = 'INVENTORY_CONTROL_STOCK_RECEIPT_______APPROVE_REJECT',
  InventoryControlStockIssueView = 'INVENTORY_CONTROL_STOCK_ISSUE_______VIEW',
  InventoryControlStockIssueCreate = 'INVENTORY_CONTROL_STOCK_ISSUE_______CREATE',
  InventoryControlStockIssueUpdate = 'INVENTORY_CONTROL_STOCK_ISSUE_______UPDATE',
  InventoryControlStockIssueDraft = 'INVENTORY_CONTROL_STOCK_ISSUE_______DRAFT',
  InventoryControlStockIssueDelete = 'INVENTORY_CONTROL_STOCK_ISSUE_______DELETE',
  InventoryControlStockIssueApproveReject = 'INVENTORY_CONTROL_STOCK_ISSUE_______APPROVE_REJECT',
  InventoryControlStockTransferView = 'INVENTORY_CONTROL_STOCK_TRANSFER_______VIEW',
  InventoryControlStockTransferCreate = 'INVENTORY_CONTROL_STOCK_TRANSFER_______CREATE',
  InventoryControlStockTransferUpdate = 'INVENTORY_CONTROL_STOCK_TRANSFER_______UPDATE',
  InventoryControlStockTransferDraft = 'INVENTORY_CONTROL_STOCK_TRANSFER_______DRAFT',
  InventoryControlStockTransferDelete = 'INVENTORY_CONTROL_STOCK_TRANSFER_______DELETE',
  InventoryControlStockTransferApproveReject = 'INVENTORY_CONTROL_STOCK_TRANSFER_______APPROVE_REJECT',
  InventoryControlStockAdjustmentView = 'INVENTORY_CONTROL_STOCK_ADJUSTMENT_______VIEW',
  InventoryControlStockAdjustmentCreate = 'INVENTORY_CONTROL_STOCK_ADJUSTMENT_______CREATE',
  InventoryControlStockAdjustmentUpdate = 'INVENTORY_CONTROL_STOCK_ADJUSTMENT_______UPDATE',
  InventoryControlStockAdjustmentDraft = 'INVENTORY_CONTROL_STOCK_ADJUSTMENT_______DRAFT',
  InventoryControlStockAdjustmentDelete = 'INVENTORY_CONTROL_STOCK_ADJUSTMENT_______DELETE',
  InventoryControlStockAdjustmentApproveReject = 'INVENTORY_CONTROL_STOCK_ADJUSTMENT_______APPROVE_REJECT',
  InventoryControlStockCostAdjustmentView = 'INVENTORY_CONTROL_STOCK_COST_ADJUSTMENT_______VIEW',
  InventoryControlStockCostAdjustmentCreate = 'INVENTORY_CONTROL_STOCK_COST_ADJUSTMENT_______CREATE',
  InventoryControlStockCostAdjustmentUpdate = 'INVENTORY_CONTROL_STOCK_COST_ADJUSTMENT_______UPDATE',
  InventoryControlStockCostAdjustmentDraft = 'INVENTORY_CONTROL_STOCK_COST_ADJUSTMENT_______DRAFT',
  InventoryControlStockCostAdjustmentDelete = 'INVENTORY_CONTROL_STOCK_COST_ADJUSTMENT_______DELETE',
  InventoryControlStockCostAdjustmentApproveReject = 'INVENTORY_CONTROL_STOCK_COST_ADJUSTMENT_______APPROVE_REJECT',
  InventoryControlStockRequisitionView = 'INVENTORY_CONTROL_STOCK_REQUISITION_______VIEW',
  InventoryControlStockRequisitionCreate = 'INVENTORY_CONTROL_STOCK_REQUISITION_______CREATE',
  InventoryControlStockRequisitionUpdate = 'INVENTORY_CONTROL_STOCK_REQUISITION_______UPDATE',
  InventoryControlStockRequisitionDraft = 'INVENTORY_CONTROL_STOCK_REQUISITION_______DRAFT',
  InventoryControlStockRequisitionCancel = 'INVENTORY_CONTROL_STOCK_REQUISITION_______CANCEL',
  InventoryControlStockRequisitionDelete = 'INVENTORY_CONTROL_STOCK_REQUISITION_______DELETE',
  InventoryControlStockRequisitionApproveReject = 'INVENTORY_CONTROL_STOCK_REQUISITION_______APPROVE_REJECT',
  InventoryControlStockTransferRequisitionView = 'INVENTORY_CONTROL_STOCK_TRANSFER_REQUISITION_______VIEW',
  InventoryControlStockTransferRequisitionCreate = 'INVENTORY_CONTROL_STOCK_TRANSFER_REQUISITION_______CREATE',
  InventoryControlStockTransferRequisitionUpdate = 'INVENTORY_CONTROL_STOCK_TRANSFER_REQUISITION_______UPDATE',
  InventoryControlStockTransferRequisitionDraft = 'INVENTORY_CONTROL_STOCK_TRANSFER_REQUISITION_______DRAFT',
  InventoryControlStockTransferRequisitionCancel = 'INVENTORY_CONTROL_STOCK_TRANSFER_REQUISITION_______CANCEL',
  InventoryControlStockTransferRequisitionDelete = 'INVENTORY_CONTROL_STOCK_TRANSFER_REQUISITION_______DELETE',
  InventoryControlStockTransferRequisitionApproveReject = 'INVENTORY_CONTROL_STOCK_TRANSFER_REQUISITION_______APPROVE_REJECT',
  InventoryControlCycleCountingView = 'INVENTORY_CONTROL_CYCLE_COUNTING_______VIEW',
  InventoryControlCycleCountingCreate = 'INVENTORY_CONTROL_CYCLE_COUNTING_______CREATE',
  InventoryControlCycleCountingUpdate = 'INVENTORY_CONTROL_CYCLE_COUNTING_______UPDATE',
  InventoryControlCycleCountingDraft = 'INVENTORY_CONTROL_CYCLE_COUNTING_______DRAFT',
  InventoryControlCycleCountingDelete = 'INVENTORY_CONTROL_CYCLE_COUNTING_______DELETE',
  InventoryControlCycleCountingApproveReject = 'INVENTORY_CONTROL_CYCLE_COUNTING_______APPROVE_REJECT',
  InventoryControlStockEnquiryView = 'INVENTORY_CONTROL_STOCK_ENQUIRY_______VIEW',
  InventoryControlDigitalReportsView = 'INVENTORY_CONTROL_DIGITAL_REPORTS_____VIEW',
  InventoryControlDigitalReportsStockLedgerByLocationView = 'INVENTORY_CONTROL_DIGITAL_REPORTS_STOCK_LEDGER_BY_LOCATION____VIEW',
  InventoryControlDigitalReportsStockBalanceByDocDateView = 'INVENTORY_CONTROL_DIGITAL_REPORTS_STOCK_BALANCE_BY_DOC_DATE____VIEW',
  InventoryControlDigitalReportsStockBalanceByTrxDateView = 'INVENTORY_CONTROL_DIGITAL_REPORTS_STOCK_BALANCE_BY_TRX_DATE____VIEW',
  InventoryControlGeneralSettingsView = 'INVENTORY_CONTROL_GENERAL_SETTINGS____VIEW',
  InventoryControlGeneralSettingsWarehouseView = 'INVENTORY_CONTROL_GENERAL_SETTINGS_WAREHOUSE____VIEW',
  InventoryControlGeneralSettingsWarehouseCreate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_WAREHOUSE____CREATE',
  InventoryControlGeneralSettingsWarehouseUpdate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_WAREHOUSE____UPDATE',
  InventoryControlGeneralSettingsWarehouseDelete = 'INVENTORY_CONTROL_GENERAL_SETTINGS_WAREHOUSE____DELETE',
  InventoryControlGeneralSettingsUomView = 'INVENTORY_CONTROL_GENERAL_SETTINGS_UOM____VIEW',
  InventoryControlGeneralSettingsUomCreate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_UOM____CREATE',
  InventoryControlGeneralSettingsUomUpdate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_UOM____UPDATE',
  InventoryControlGeneralSettingsUomDelete = 'INVENTORY_CONTROL_GENERAL_SETTINGS_UOM____DELETE',
  InventoryControlGeneralSettingsUomExchangeView = 'INVENTORY_CONTROL_GENERAL_SETTINGS_UOM_EXCHANGE____VIEW',
  InventoryControlGeneralSettingsUomExchangeCreate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_UOM_EXCHANGE____CREATE',
  InventoryControlGeneralSettingsUomExchangeUpdate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_UOM_EXCHANGE____UPDATE',
  InventoryControlGeneralSettingsUomExchangeDelete = 'INVENTORY_CONTROL_GENERAL_SETTINGS_UOM_EXCHANGE____DELETE',
  InventoryControlGeneralSettingsStockCategoryView = 'INVENTORY_CONTROL_GENERAL_SETTINGS_STOCK_CATEGORY____VIEW',
  InventoryControlGeneralSettingsStockCategoryCreate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_STOCK_CATEGORY____CREATE',
  InventoryControlGeneralSettingsStockCategoryUpdate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_STOCK_CATEGORY____UPDATE',
  InventoryControlGeneralSettingsStockCategoryDelete = 'INVENTORY_CONTROL_GENERAL_SETTINGS_STOCK_CATEGORY____DELETE',
  InventoryControlGeneralSettingsStockItemView = 'INVENTORY_CONTROL_GENERAL_SETTINGS_STOCK_ITEM____VIEW',
  InventoryControlGeneralSettingsStockItemCreate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_STOCK_ITEM____CREATE',
  InventoryControlGeneralSettingsStockItemUpdate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_STOCK_ITEM____UPDATE',
  InventoryControlGeneralSettingsStockItemDelete = 'INVENTORY_CONTROL_GENERAL_SETTINGS_STOCK_ITEM____DELETE',
  InventoryControlGeneralSettingsStockItemInactive = 'INVENTORY_CONTROL_GENERAL_SETTINGS_STOCK_ITEM____INACTIVE',
  InventoryControlGeneralSettingsTrxTypeView = 'INVENTORY_CONTROL_GENERAL_SETTINGS_TRX_TYPE____VIEW',
  InventoryControlGeneralSettingsTrxTypeCreate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_TRX_TYPE____CREATE',
  InventoryControlGeneralSettingsTrxTypeUpdate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_TRX_TYPE____UPDATE',
  InventoryControlGeneralSettingsTrxTypeInactive = 'INVENTORY_CONTROL_GENERAL_SETTINGS_TRX_TYPE____INACTIVE',
  InventoryControlGeneralSettingsStockQtyAdjReasonsView = 'INVENTORY_CONTROL_GENERAL_SETTINGS_STOCK_QTY_ADJ_REASONS____VIEW',
  InventoryControlGeneralSettingsStockQtyAdjReasonsCreate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_STOCK_QTY_ADJ_REASONS____CREATE',
  InventoryControlGeneralSettingsStockQtyAdjReasonsUpdate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_STOCK_QTY_ADJ_REASONS____UPDATE',
  InventoryControlGeneralSettingsStockQtyAdjReasonsDelete = 'INVENTORY_CONTROL_GENERAL_SETTINGS_STOCK_QTY_ADJ_REASONS____DELETE',
  InventoryControlGeneralSettingsNumberingStructureView = 'INVENTORY_CONTROL_GENERAL_SETTINGS_NUMBERING_STRUCTURE____VIEW',
  InventoryControlGeneralSettingsNumberingStructureCreate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_NUMBERING_STRUCTURE____CREATE',
  InventoryControlGeneralSettingsNumberingStructureUpdate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_NUMBERING_STRUCTURE____UPDATE',
  InventoryControlGeneralSettingsNumberingStructureDelete = 'INVENTORY_CONTROL_GENERAL_SETTINGS_NUMBERING_STRUCTURE____DELETE',
  InventoryControlCompanySettingsView = 'INVENTORY_CONTROL_COMPANY_SETTINGS____VIEW',
  GeneralPurchaseIcon = 'GENERAL_PURCHASE__________ICON',
  GeneralPurchaseScheduledPurchasesView = 'GENERAL_PURCHASE_SCHEDULED_PURCHASES_______VIEW',
  GeneralPurchaseScheduledPurchasesCreate = 'GENERAL_PURCHASE_SCHEDULED_PURCHASES_______CREATE',
  GeneralPurchaseScheduledPurchasesUpdate = 'GENERAL_PURCHASE_SCHEDULED_PURCHASES_______UPDATE',
  GeneralPurchaseScheduledPurchasesDelete = 'GENERAL_PURCHASE_SCHEDULED_PURCHASES_______DELETE',
  GeneralPurchaseScheduledPurchasesPreparePo = 'GENERAL_PURCHASE_SCHEDULED_PURCHASES_______PREPARE_PO',
  GeneralPurchaseScheduledPurchasesGeneratePo = 'GENERAL_PURCHASE_SCHEDULED_PURCHASES_______GENERATE_PO',
  GeneralPurchaseScheduledPurchasesPurchaseRequest = 'GENERAL_PURCHASE_SCHEDULED_PURCHASES_______PURCHASE_REQUEST',
  GeneralPurchaseScheduledPurchasesVerifyRequest = 'GENERAL_PURCHASE_SCHEDULED_PURCHASES_______VERIFY_REQUEST',
  GeneralPurchaseDirectPoView = 'GENERAL_PURCHASE_DIRECT_PO_______VIEW',
  GeneralPurchaseDirectPoCreate = 'GENERAL_PURCHASE_DIRECT_PO_______CREATE',
  GeneralPurchaseDirectPoUpdate = 'GENERAL_PURCHASE_DIRECT_PO_______UPDATE',
  GeneralPurchaseDirectPoDelete = 'GENERAL_PURCHASE_DIRECT_PO_______DELETE',
  GeneralPurchaseDirectPoCancel = 'GENERAL_PURCHASE_DIRECT_PO_______CANCEL',
  GeneralPurchaseDirectPoDuplicate = 'GENERAL_PURCHASE_DIRECT_PO_______DUPLICATE',
  GeneralPurchaseDirectPoApproveReject = 'GENERAL_PURCHASE_DIRECT_PO_______APPROVE_REJECT',
  GeneralPurchasePurchaseRequisitionView = 'GENERAL_PURCHASE_PURCHASE_REQUISITION_______VIEW',
  GeneralPurchasePurchaseRequisitionCreate = 'GENERAL_PURCHASE_PURCHASE_REQUISITION_______CREATE',
  GeneralPurchasePurchaseRequisitionUpdate = 'GENERAL_PURCHASE_PURCHASE_REQUISITION_______UPDATE',
  GeneralPurchasePurchaseRequisitionDelete = 'GENERAL_PURCHASE_PURCHASE_REQUISITION_______DELETE',
  GeneralPurchasePurchaseRequisitionCancel = 'GENERAL_PURCHASE_PURCHASE_REQUISITION_______CANCEL',
  GeneralPurchasePurchaseRequisitionDuplicate = 'GENERAL_PURCHASE_PURCHASE_REQUISITION_______DUPLICATE',
  GeneralPurchasePurchaseRequisitionApproveReject = 'GENERAL_PURCHASE_PURCHASE_REQUISITION_______APPROVE_REJECT',
  GeneralPurchaseRequestForQuotationView = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_______VIEW',
  GeneralPurchaseRequestForQuotationCreate = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_______CREATE',
  GeneralPurchaseRequestForQuotationUpdate = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_______UPDATE',
  GeneralPurchaseRequestForQuotationDelete = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_______DELETE',
  GeneralPurchaseRequestForQuotationApproveReject = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_______APPROVE_REJECT',
  GeneralPurchaseRequestForQuotationRfqInviteesView = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES____VIEW',
  GeneralPurchaseRequestForQuotationRfqInviteesCreate = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES____CREATE',
  GeneralPurchaseRequestForQuotationRfqInviteesUninvite = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES____UNINVITE',
  GeneralPurchaseRequestForQuotationRfqInviteesQuotedRfqView = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_VIEW',
  GeneralPurchaseRequestForQuotationRfqInviteesQuotedRfqCreate = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_CREATE',
  GeneralPurchaseRequestForQuotationRfqInviteesQuotedRfqUpdate = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_UPDATE',
  GeneralPurchaseRequestForQuotationRfqInviteesQuotedRfqDelete = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_DELETE',
  GeneralPurchaseRequestForQuotationEvaluationSelectionView = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_EVALUATION_SELECTION____VIEW',
  GeneralPurchaseRequestForQuotationEvaluationSelectionCreate = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_EVALUATION_SELECTION____CREATE',
  GeneralPurchaseGeneratePoFromPrView = 'GENERAL_PURCHASE_GENERATE_PO_FROM_PR_______VIEW',
  GeneralPurchaseGeneratePoFromPrCreate = 'GENERAL_PURCHASE_GENERATE_PO_FROM_PR_______CREATE',
  GeneralPurchaseGeneratePoFromPrUpdate = 'GENERAL_PURCHASE_GENERATE_PO_FROM_PR_______UPDATE',
  GeneralPurchaseGeneratePoFromPrDelete = 'GENERAL_PURCHASE_GENERATE_PO_FROM_PR_______DELETE',
  GeneralPurchaseGeneratePoFromPrApproveReject = 'GENERAL_PURCHASE_GENERATE_PO_FROM_PR_______APPROVE_REJECT',
  GeneralPurchaseGeneratePoFromPrCancel = 'GENERAL_PURCHASE_GENERATE_PO_FROM_PR_______CANCEL',
  GeneralPurchaseCentralisedPoView = 'GENERAL_PURCHASE_CENTRALISED_PO_______VIEW',
  GeneralPurchaseCentralisedPoCreate = 'GENERAL_PURCHASE_CENTRALISED_PO_______CREATE',
  GeneralPurchaseCentralisedPoUpdate = 'GENERAL_PURCHASE_CENTRALISED_PO_______UPDATE',
  GeneralPurchaseCentralisedPoDelete = 'GENERAL_PURCHASE_CENTRALISED_PO_______DELETE',
  GeneralPurchaseCentralisedPoApproveReject = 'GENERAL_PURCHASE_CENTRALISED_PO_______APPROVE_REJECT',
  GeneralPurchaseCentralisedPoCancel = 'GENERAL_PURCHASE_CENTRALISED_PO_______CANCEL',
  GeneralPurchaseGoodReceiveNoteView = 'GENERAL_PURCHASE_GOOD_RECEIVE_NOTE_______VIEW',
  GeneralPurchaseGoodReceiveNoteCreate = 'GENERAL_PURCHASE_GOOD_RECEIVE_NOTE_______CREATE',
  GeneralPurchaseGoodReceiveNoteUpdate = 'GENERAL_PURCHASE_GOOD_RECEIVE_NOTE_______UPDATE',
  GeneralPurchaseGoodReceiveNoteDelete = 'GENERAL_PURCHASE_GOOD_RECEIVE_NOTE_______DELETE',
  GeneralPurchaseGoodReceiveNoteApproveReject = 'GENERAL_PURCHASE_GOOD_RECEIVE_NOTE_______APPROVE_REJECT',
  GeneralPurchaseGoodReturnNoteView = 'GENERAL_PURCHASE_GOOD_RETURN_NOTE_______VIEW',
  GeneralPurchaseGoodReturnNoteCreate = 'GENERAL_PURCHASE_GOOD_RETURN_NOTE_______CREATE',
  GeneralPurchaseGoodReturnNoteUpdate = 'GENERAL_PURCHASE_GOOD_RETURN_NOTE_______UPDATE',
  GeneralPurchaseGoodReturnNoteDelete = 'GENERAL_PURCHASE_GOOD_RETURN_NOTE_______DELETE',
  GeneralPurchaseGoodReturnNoteApproveReject = 'GENERAL_PURCHASE_GOOD_RETURN_NOTE_______APPROVE_REJECT',
  GeneralPurchaseDigitalReportsView = 'GENERAL_PURCHASE_DIGITAL_REPORTS_______VIEW',
  GeneralPurchaseDigitalReportsPurchaseOrder_4WaysMatchReportView = 'GENERAL_PURCHASE_DIGITAL_REPORTS_PURCHASE_ORDER_4_WAYS_MATCH_REPORT_______VIEW',
  GeneralPurchaseDigitalReportsPoOutstandingView = 'GENERAL_PURCHASE_DIGITAL_REPORTS_PO_OUTSTANDING_______VIEW',
  GeneralPurchaseDigitalReportsPurchaseByItemCategoryView = 'GENERAL_PURCHASE_DIGITAL_REPORTS_PURCHASE_BY_ITEM_CATEGORY_______VIEW',
  GeneralPurchaseDigitalReportsPurchaseAnalysisByItemView = 'GENERAL_PURCHASE_DIGITAL_REPORTS_PURCHASE_ANALYSIS_BY_ITEM_______VIEW',
  GeneralPurchaseDigitalReportsGrnGrtnDailyReportView = 'GENERAL_PURCHASE_DIGITAL_REPORTS_GRN_GRTN_DAILY_REPORT_______VIEW',
  GeneralPurchaseGeneralSettingsView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_____VIEW',
  GeneralPurchaseGeneralSettingsSupplierCategoryView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_SUPPLIER_CATEGORY____VIEW',
  GeneralPurchaseGeneralSettingsSupplierCategoryCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_SUPPLIER_CATEGORY____CREATE',
  GeneralPurchaseGeneralSettingsSupplierCategoryUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_SUPPLIER_CATEGORY____UPDATE',
  GeneralPurchaseGeneralSettingsSupplierCategoryDelete = 'GENERAL_PURCHASE_GENERAL_SETTINGS_SUPPLIER_CATEGORY____DELETE',
  GeneralPurchaseGeneralSettingsSupplierProfileView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_SUPPLIER_PROFILE____VIEW',
  GeneralPurchaseGeneralSettingsSupplierProfileCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_SUPPLIER_PROFILE____CREATE',
  GeneralPurchaseGeneralSettingsSupplierProfileUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_SUPPLIER_PROFILE____UPDATE',
  GeneralPurchaseGeneralSettingsSupplierProfileInactive = 'GENERAL_PURCHASE_GENERAL_SETTINGS_SUPPLIER_PROFILE____INACTIVE',
  GeneralPurchaseGeneralSettingsSupplierProfileAssign = 'GENERAL_PURCHASE_GENERAL_SETTINGS_SUPPLIER_PROFILE____ASSIGN',
  GeneralPurchaseGeneralSettingsUomSetupView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_UOM_SETUP____VIEW',
  GeneralPurchaseGeneralSettingsUomSetupCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_UOM_SETUP____CREATE',
  GeneralPurchaseGeneralSettingsUomSetupUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_UOM_SETUP____UPDATE',
  GeneralPurchaseGeneralSettingsUomSetupDelete = 'GENERAL_PURCHASE_GENERAL_SETTINGS_UOM_SETUP____DELETE',
  GeneralPurchaseGeneralSettingsUomConversionView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_UOM_CONVERSION____VIEW',
  GeneralPurchaseGeneralSettingsUomConversionCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_UOM_CONVERSION____CREATE',
  GeneralPurchaseGeneralSettingsUomConversionUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_UOM_CONVERSION____UPDATE',
  GeneralPurchaseGeneralSettingsUomConversionDelete = 'GENERAL_PURCHASE_GENERAL_SETTINGS_UOM_CONVERSION____DELETE',
  GeneralPurchaseGeneralSettingsPurchaseItemCategoryView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM_CATEGORY____VIEW',
  GeneralPurchaseGeneralSettingsPurchaseItemCategoryCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM_CATEGORY____CREATE',
  GeneralPurchaseGeneralSettingsPurchaseItemCategoryUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM_CATEGORY____UPDATE',
  GeneralPurchaseGeneralSettingsPurchaseItemCategoryAssign = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM_CATEGORY____ASSIGN',
  GeneralPurchaseGeneralSettingsPurchaseItemCategoryDelete = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM_CATEGORY____DELETE',
  GeneralPurchaseGeneralSettingsPurchaseItemView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM____VIEW',
  GeneralPurchaseGeneralSettingsPurchaseItemCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM____CREATE',
  GeneralPurchaseGeneralSettingsPurchaseItemUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM____UPDATE',
  GeneralPurchaseGeneralSettingsPurchaseItemInactive = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM____INACTIVE',
  GeneralPurchaseGeneralSettingsPurchaseItemDelete = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM____DELETE',
  GeneralPurchaseGeneralSettingsPurchaseItemDisallow = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM____DISALLOW',
  GeneralPurchaseGeneralSettingsBulkPurchasePricingView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_BULK_PURCHASE_PRICING____VIEW',
  GeneralPurchaseGeneralSettingsBulkPurchasePricingCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_BULK_PURCHASE_PRICING____CREATE',
  GeneralPurchaseGeneralSettingsBulkPurchasePricingUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_BULK_PURCHASE_PRICING____UPDATE',
  GeneralPurchaseGeneralSettingsBulkPurchasePricingDelete = 'GENERAL_PURCHASE_GENERAL_SETTINGS_BULK_PURCHASE_PRICING____DELETE',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY____VIEW',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyPurchaseRequisitionView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_PURCHASE_REQUISITION___VIEW',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyPurchaseRequisitionCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_PURCHASE_REQUISITION___CREATE',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyPurchaseRequisitionUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_PURCHASE_REQUISITION___UPDATE',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyPurchaseRequisitionDelete = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_PURCHASE_REQUISITION___DELETE',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyRfqView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_RFQ___VIEW',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyRfqCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_RFQ___CREATE',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyRfqUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_RFQ___UPDATE',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyRfqDelete = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_RFQ___DELETE',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyPurchaseOrderView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_PURCHASE_ORDER___VIEW',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyPurchaseOrderCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_PURCHASE_ORDER___CREATE',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyPurchaseOrderUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_PURCHASE_ORDER___UPDATE',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyPurchaseOrderDelete = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_PURCHASE_ORDER___DELETE',
  GeneralPurchaseGeneralSettingsGeneralPurchasePolicyView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_GENERAL_PURCHASE_POLICY____VIEW',
  GeneralPurchaseGeneralSettingsGeneralPurchasePolicyCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_GENERAL_PURCHASE_POLICY____CREATE',
  GeneralPurchaseGeneralSettingsGeneralPurchasePolicyUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_GENERAL_PURCHASE_POLICY____UPDATE',
  GeneralPurchaseCompanySettingsView = 'GENERAL_PURCHASE_COMPANY_SETTINGS_____VIEW',
  GeneralPurchaseCompanySettingsApprovalPolicyView = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY____VIEW',
  GeneralPurchaseCompanySettingsApprovalPolicyPrView = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY_PR___VIEW',
  GeneralPurchaseCompanySettingsApprovalPolicyPrCreate = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY_PR___CREATE',
  GeneralPurchaseCompanySettingsApprovalPolicyPrUpdate = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY_PR___UPDATE',
  GeneralPurchaseCompanySettingsApprovalPolicyPrDelete = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY_PR___DELETE',
  GeneralPurchaseCompanySettingsApprovalPolicyRfqView = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY_RFQ___VIEW',
  GeneralPurchaseCompanySettingsApprovalPolicyRfqCreate = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY_RFQ___CREATE',
  GeneralPurchaseCompanySettingsApprovalPolicyRfqUpdate = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY_RFQ___UPDATE',
  GeneralPurchaseCompanySettingsApprovalPolicyRfqDelete = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY_RFQ___DELETE',
  GeneralPurchaseCompanySettingsApprovalPolicyPoView = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY_PO___VIEW',
  GeneralPurchaseCompanySettingsApprovalPolicyPoCreate = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY_PO___CREATE',
  GeneralPurchaseCompanySettingsApprovalPolicyPoUpdate = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY_PO___UPDATE',
  GeneralPurchaseCompanySettingsApprovalPolicyPoDelete = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY_PO___DELETE',
  GeneralPurchaseCompanySettingsNumberingStructuresView = 'GENERAL_PURCHASE_COMPANY_SETTINGS_NUMBERING_STRUCTURES____VIEW',
  GeneralPurchaseCompanySettingsNumberingStructuresCreate = 'GENERAL_PURCHASE_COMPANY_SETTINGS_NUMBERING_STRUCTURES____CREATE',
  GeneralPurchaseCompanySettingsNumberingStructuresUpdate = 'GENERAL_PURCHASE_COMPANY_SETTINGS_NUMBERING_STRUCTURES____UPDATE',
  GeneralPurchaseCompanySettingsNumberingStructuresDelete = 'GENERAL_PURCHASE_COMPANY_SETTINGS_NUMBERING_STRUCTURES____DELETE',
  GeneralPurchaseCompanySettingsDeliveryLocationView = 'GENERAL_PURCHASE_COMPANY_SETTINGS_DELIVERY_LOCATION____VIEW',
  GeneralPurchaseCompanySettingsDeliveryLocationCreate = 'GENERAL_PURCHASE_COMPANY_SETTINGS_DELIVERY_LOCATION____CREATE',
  GeneralPurchaseCompanySettingsDeliveryLocationUpdate = 'GENERAL_PURCHASE_COMPANY_SETTINGS_DELIVERY_LOCATION____UPDATE',
  GeneralPurchaseCompanySettingsDeliveryLocationDelete = 'GENERAL_PURCHASE_COMPANY_SETTINGS_DELIVERY_LOCATION____DELETE'
}

export type AccuserRoleId = {
  __typename?: 'AccuserRoleID';
  roleID: Scalars['String'];
  userID: Scalars['String'];
};

/** JsonType */
export type Address = {
  __typename?: 'Address';
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export type AddressInput = {
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export enum AddressType {
  Primary = 'PRIMARY',
  Delivery = 'DELIVERY',
  Site = 'SITE'
}

export type AgingDeleteInput = {
  AgingID: Scalars['String'];
};

export type AgingInput = {
  AgingID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Period1: Scalars['Float'];
  Period2: Scalars['Float'];
  Period3: Scalars['Float'];
  Period4: Scalars['Float'];
  Period5: Scalars['Float'];
  Period6: Scalars['Float'];
  AccountType: Scalars['String'];
};

export type AllStandardCoreBaseEntity = StandardAuditEntity & {
  __typename?: 'AllStandardCoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type AllocationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  AllocationID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DebitRefTable?: Maybe<Scalars['String']>;
  DebitID?: Maybe<Scalars['String']>;
  CreditRefTable?: Maybe<Scalars['String']>;
  CreditID?: Maybe<Scalars['String']>;
  CreditOrDebit?: Maybe<Scalars['String']>;
  AllocationAmt: Scalars['Float'];
  CreditorAccountID?: Maybe<Scalars['String']>;
};

export type AllocationItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  AllocationID?: Maybe<Scalars['String']>;
  DebitItemRefTable?: Maybe<Scalars['String']>;
  DebitItemID?: Maybe<Scalars['String']>;
  CreditItemRefTable?: Maybe<Scalars['String']>;
  CreditItemID?: Maybe<Scalars['String']>;
  CreditOrDebit?: Maybe<Scalars['String']>;
  AllocationItemAmt?: Maybe<Scalars['Float']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
};

export enum AmountType {
  Opening = 'OPENING',
  YearOpening = 'YEAR_OPENING',
  Closing = 'CLOSING',
  YearClosing = 'YEAR_CLOSING',
  Transaction = 'TRANSACTION'
}

export type ApprovalListJson = {
  __typename?: 'ApprovalListJson';
  Category: Scalars['String'];
  StepNo: Scalars['Float'];
  RoleID: Scalars['String'];
  Predicate?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  RoleName?: Maybe<Scalars['String']>;
};

export type ApprovalListJsonInput = {
  Category: Scalars['String'];
  StepNo: Scalars['Float'];
  RoleID: Scalars['String'];
  Predicate?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  RoleName?: Maybe<Scalars['String']>;
};

export type ApprovalPolicyAssignmentDeleteInput = {
  ApprovalPolicyAssignmentID: Scalars['String'];
};

export type ApprovalPolicyAssignmentInput = {
  ApprovalPolicyAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
  upperLimit?: Maybe<Scalars['Float']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  ApprovalList?: Maybe<Array<ApprovalListJsonInput>>;
};

export type ApprovalPolicyDeleteInput = {
  ApprovalPolicyID: Scalars['String'];
};

export type ApprovalPolicyInput = {
  ApprovalPolicyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
  upperLimit?: Maybe<Scalars['Float']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  ApprovalList?: Maybe<Array<ApprovalListJsonInput>>;
};

export enum ApprovalStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Completed = 'COMPLETED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Retention = 'RETENTION',
  PendingClose = 'PENDING_CLOSE',
  PendingCancel = 'PENDING_CANCEL',
  SubmitCancel = 'SUBMIT_CANCEL',
  ApprovedCancel = 'APPROVED_CANCEL',
  RejectedCancel = 'REJECTED_CANCEL',
  CompletedCancel = 'COMPLETED_CANCEL',
  Closed = 'CLOSED',
  New = 'NEW',
  Packed = 'PACKED'
}

export type AttachmentEntity = AuditEntity & {
  __typename?: 'AttachmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  AttachmentID: Scalars['String'];
  mediaType: Scalars['String'];
  refTable: AttachmentRefTable;
  refID: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
  bucketFileName?: Maybe<Scalars['String']>;
  opacity?: Maybe<Scalars['String']>;
  isPrivate: Scalars['Boolean'];
  folderExist: Scalars['Boolean'];
};

/** JsonType */
export type AttachmentInfo = {
  __typename?: 'AttachmentInfo';
  fileURL?: Maybe<Scalars['String']>;
  mediaType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum AttachmentRefTable {
  Contact = 'CONTACT',
  Company = 'COMPANY',
  Ledger = 'LEDGER',
  Project = 'PROJECT'
}

export type AuditAdjustmentDeleteInput = {
  AuditAdjustmentID: Scalars['String'];
};

export type AuditAdjustmentEntity = AuditEntity & {
  __typename?: 'AuditAdjustmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  AuditAdjustmentID: Scalars['String'];
  CompanyID: Scalars['String'];
  AdjYearOrPeriod: Scalars['String'];
  JournalID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  AuditAdjustmentItem?: Maybe<Array<AuditAdjustmentItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
};

export type AuditAdjustmentInput = {
  AuditAdjustmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  AdjYearOrPeriod: Scalars['String'];
  RefNo: Scalars['String'];
  submittedTs?: Maybe<Scalars['DateTime']>;
  Description: Scalars['String'];
  Status?: Maybe<JournalStatus>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type AuditAdjustmentItemDeleteInput = {
  AuditAdjustmentItemID: Scalars['String'];
};

export type AuditAdjustmentItemEntity = AuditEntity & {
  __typename?: 'AuditAdjustmentItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  AuditAdjustmentItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  AuditAdjustmentID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  DocAmt?: Maybe<Scalars['Float']>;
  CostCentreID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  AuditAdjustment?: Maybe<AuditAdjustmentEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  CostCentre?: Maybe<CostCentreEntity>;
};

export type AuditAdjustmentItemInput = {
  AuditAdjustmentItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  AuditAdjustmentID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  CostCentreID: Scalars['String'];
  createdTs?: Maybe<Scalars['DateTime']>;
  modTs?: Maybe<Scalars['DateTime']>;
  DocAmt: Scalars['Float'];
  Sequence?: Maybe<Scalars['Float']>;
};

export type AuditAdjustmentRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type AuditAdjustmentReturnData = {
  __typename?: 'AuditAdjustmentReturnData';
  Data?: Maybe<Array<AuditAdjustmentEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type AuditEntity = {
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
};

export type AzureStorageOutput = {
  __typename?: 'AzureStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type BaImportInput = {
  BankName?: Maybe<Scalars['String']>;
  BankCode?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  AccountType?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  SwiftCode?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  GLAccountCode?: Maybe<Scalars['String']>;
  DepartmentCode?: Maybe<Scalars['String']>;
  RowNo?: Maybe<Scalars['Float']>;
};

export type BpImportInput = {
  BankName?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  RowNo?: Maybe<Scalars['Float']>;
};

/** JsonType */
export type BankAccDetail = {
  __typename?: 'BankAccDetail';
  AccountNo?: Maybe<Scalars['String']>;
  BankName?: Maybe<Scalars['String']>;
};

export type BankAccountCancellationInput = {
  Remarks?: Maybe<Scalars['String']>;
  CancellationDate?: Maybe<Scalars['String']>;
};

export type BankAccountDeleteInput = {
  BankAccountID: Scalars['String'];
};

export type BankAccountInput = {
  BankAccountID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  BankProfileID: Scalars['String'];
  Code: Scalars['String'];
  AccountNo: Scalars['String'];
  SWIFTCode: Scalars['String'];
  EffectiveDate: Scalars['String'];
  CurrencyID?: Maybe<Scalars['String']>;
  MasterCOAID: Scalars['String'];
  CostCentreID: Scalars['String'];
  Type?: Maybe<BankAccountType>;
  RecordStatus?: Maybe<RecordStatus>;
};

export enum BankAccountType {
  Cash = 'CASH',
  Bank = 'BANK'
}

export type BankDetails = {
  __typename?: 'BankDetails';
  name?: Maybe<Scalars['String']>;
  acctHolder?: Maybe<Scalars['String']>;
  acctNo?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
};

export type BankDetailsInput = {
  name?: Maybe<Scalars['String']>;
  acctHolder?: Maybe<Scalars['String']>;
  acctNo?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
};

export type BankProfileDeleteInput = {
  BankProfileID: Scalars['String'];
};

export type BankProfileEntity = AuditEntity & {
  __typename?: 'BankProfileEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BankProfileID: Scalars['String'];
  Name: Scalars['String'];
  Address: Scalars['JSON'];
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  BankAccount?: Maybe<Array<AccBankAccountEntity>>;
};

export type BankProfileInput = {
  BankProfileID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Address: Scalars['JSON'];
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
};

export type BankReconciliationDeleteInput = {
  BankReconciliationID: Scalars['String'];
};

export type BankReconciliationEntity = AuditEntity & {
  __typename?: 'BankReconciliationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BankReconciliationID: Scalars['String'];
  CompanyID: Scalars['String'];
  BankAccountID: Scalars['String'];
  BankReconciliationMatchID?: Maybe<Scalars['String']>;
  BankAccount?: Maybe<AccBankAccountEntity>;
  DocumentDate?: Maybe<Scalars['String']>;
  Remarks: Scalars['String'];
  IsExcelMatch: Scalars['Boolean'];
  StatementBalance: Scalars['Float'];
  BankReconciliationItem?: Maybe<Array<BankReconciliationItemEntity>>;
  BankReconciliationMatch?: Maybe<BankReconciliationMatchEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type BankReconciliationInput = {
  BankReconciliationID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  BankAccountID: Scalars['String'];
  DocumentDate: Scalars['String'];
  Remarks: Scalars['String'];
  StatementBalance: Scalars['Float'];
  IsExcelMatch: Scalars['Boolean'];
};

export type BankReconciliationItemDeleteInput = {
  BankReconciliationItemID: Scalars['String'];
};

export type BankReconciliationItemEntity = AuditEntity & {
  __typename?: 'BankReconciliationItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BankReconciliationItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  BankReconciliationID: Scalars['String'];
  BankReconciliation?: Maybe<BankReconciliationEntity>;
  BankReconciliationMatchID?: Maybe<Scalars['String']>;
  BankReconciliationMatch?: Maybe<BankReconciliationMatchEntity>;
  DocumentDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  DocNo: Scalars['String'];
  Remarks: Scalars['String'];
  IsMatch: Scalars['Boolean'];
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type BankReconciliationItemImportInput = {
  DocNo?: Maybe<Scalars['String']>;
  DocumentDate?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  RowNo?: Maybe<Scalars['Float']>;
};

export type BankReconciliationItemInput = {
  BankReconciliationItemID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  BankReconciliationID: Scalars['String'];
  DocNo: Scalars['String'];
  DocumentDate: Scalars['String'];
  DocAmt: Scalars['Float'];
  Remarks: Scalars['String'];
  IsMatch: Scalars['Boolean'];
};

export type BankReconciliationMatchDeleteInput = {
  BankReconciliationMatchID: Scalars['String'];
};

export type BankReconciliationMatchEntity = AuditEntity & {
  __typename?: 'BankReconciliationMatchEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BankReconciliationMatchID: Scalars['String'];
  CompanyID: Scalars['String'];
  BankReconciliationID?: Maybe<Scalars['String']>;
  Ledger?: Maybe<Array<LedgerEntity>>;
  BankReconciliation?: Maybe<Array<BankReconciliationEntity>>;
  BankReconciliationItem?: Maybe<Array<BankReconciliationItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type BankReconciliationMatchInput = {
  BankReconciliationMatchID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  BankReconciliationID: Scalars['String'];
};

export type BankTransferDeleteInput = {
  BankTransferID: Scalars['String'];
};

export type BankTransferEntity = AuditEntity & {
  __typename?: 'BankTransferEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  BankTransferID: Scalars['String'];
  CompanyID: Scalars['String'];
  FromBankAccountID?: Maybe<Scalars['String']>;
  ToBankAccountID?: Maybe<Scalars['String']>;
  BankAccount?: Maybe<AccBankAccountEntity>;
  PaymentMethodID?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  TransferDate: Scalars['String'];
  TransactionDate?: Maybe<Scalars['String']>;
  Amount: Scalars['Float'];
  LedgerID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  ToBankAccountDetail?: Maybe<BankAccDetail>;
  /** CustomFieldResolver */
  FromBankAccountDetail?: Maybe<BankAccDetail>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
};

export type BankTransferInput = {
  BankTransferID?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
  PaymentMethodID?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  TransferDate?: Maybe<Scalars['String']>;
  FromBankAccountID?: Maybe<Scalars['String']>;
  ToBankAccountID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  RejectionRemark?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type BankTransferRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type BaseInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

/** JsonType */
export type BillItemArg = {
  __typename?: 'BillItemArg';
  BillItemID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DebitID?: Maybe<Scalars['String']>;
};

export type BillItemDeleteInput = {
  BillItemID: Scalars['String'];
};

export type BillItemEntity = AuditEntity & {
  __typename?: 'BillItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BillItemID: Scalars['String'];
  Name: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  TaxRate?: Maybe<Scalars['Float']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  ARInvoiceItem?: Maybe<Array<AccArInvoiceItemEntity>>;
  ARDebitNoteItem?: Maybe<Array<AccArDebitNoteItemEntity>>;
  ARCreditNoteItem?: Maybe<Array<AccArCreditNoteItemEntity>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  IsUsed?: Maybe<Scalars['Boolean']>;
};


export type BillItemEntityBalanceAmtArgs = {
  DebitID?: Maybe<Scalars['String']>;
};

export type BillItemInput = {
  BillItemID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Description: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  TaxRate?: Maybe<Scalars['Float']>;
  RecordStatus?: Maybe<RecordStatus>;
};

export type BudgetAmt = {
  __typename?: 'BudgetAmt';
  budgetAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdT?: Maybe<Scalars['DateTime']>;
  modTs?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type BudgetAmtInput = {
  budgetAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdT?: Maybe<Scalars['DateTime']>;
  modTs?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type BudgetDeleteInput = {
  BudgetID: Scalars['String'];
};

export type BudgetEntity = AuditEntity & {
  __typename?: 'BudgetEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  BudgetID: Scalars['String'];
  CompanyID: Scalars['String'];
  Fyear?: Maybe<Scalars['Float']>;
  Version: Scalars['String'];
  IsDefault: Scalars['Boolean'];
  BudgetItem?: Maybe<Array<BudgetItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
};

export type BudgetInput = {
  BudgetID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Fyear?: Maybe<Scalars['Float']>;
  Version: Scalars['String'];
  Description: Scalars['String'];
  IsDefault: Scalars['Boolean'];
  ApprovalStatus?: Maybe<ApprovalStatus>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
};

export type BudgetItemCostCentreDeleteInput = {
  BudgetItemCostCentreID: Scalars['String'];
};

export type BudgetItemCostCentreEntity = AuditEntity & {
  __typename?: 'BudgetItemCostCentreEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BudgetItemCostCentreID: Scalars['String'];
  BudgetItemID: Scalars['String'];
  BudgetAmt: Scalars['Float'];
  CostCentreID?: Maybe<Scalars['String']>;
  BudgetItemPeriod?: Maybe<Array<BudgetItemPeriodEntity>>;
  BudgetItem?: Maybe<BudgetItemEntity>;
  CostCentre?: Maybe<CostCentreEntity>;
};

export type BudgetItemCostCentreInput = {
  BudgetItemID?: Maybe<Scalars['String']>;
  BudgetItemCostCentreID?: Maybe<Scalars['String']>;
  BudgetAmt: Scalars['Float'];
  CostCentreID?: Maybe<Scalars['String']>;
};

export type BudgetItemDeleteInput = {
  BudgetItemID: Scalars['String'];
};

export type BudgetItemEntity = AuditEntity & {
  __typename?: 'BudgetItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BudgetItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  BudgetID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  BudgetAmt: Scalars['Float'];
  BudgetItemCostCentre?: Maybe<Array<BudgetItemCostCentreEntity>>;
  Budget?: Maybe<BudgetEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type BudgetItemInput = {
  BudgetItemID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  BudgetID?: Maybe<Scalars['String']>;
  MasterCOAID: Scalars['String'];
  BudgetAmt: Scalars['Float'];
  CostCentreID?: Maybe<Scalars['String']>;
  AccountPeriodID?: Maybe<Scalars['String']>;
};

export type BudgetItemPeriodDeleteInput = {
  BudgetItemPeriodID: Scalars['String'];
};

export type BudgetItemPeriodEntity = AuditEntity & {
  __typename?: 'BudgetItemPeriodEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BudgetItemPeriodID: Scalars['String'];
  BudgetItemCostCentreID: Scalars['String'];
  BudgetAmt: Scalars['Float'];
  AccountPeriodID?: Maybe<Scalars['String']>;
  AccountPeriod?: Maybe<AccountPeriodEntity>;
  BudgetItemCostCentre?: Maybe<BudgetItemCostCentreEntity>;
};

export type BudgetItemPeriodInput = {
  BudgetItemCostCentreID?: Maybe<Scalars['String']>;
  BudgetItemPeriodID?: Maybe<Scalars['String']>;
  BudgetAmt: Scalars['Float'];
  AccountPeriodID?: Maybe<Scalars['String']>;
};

export type BulkPurchasePriceDetailEntity = AuditEntity & {
  __typename?: 'BulkPurchasePriceDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BulkPurchasePriceDetailID: Scalars['String'];
  BulkPurchasePriceItemID: Scalars['String'];
  BulkPurchasePriceItem?: Maybe<BulkPurchasePriceItemEntity>;
  LowerLimit: Scalars['Float'];
  UpperLimit: Scalars['Float'];
  UnitPrice: Scalars['Float'];
};

export type BulkPurchasePriceDetailInput = {
  BulkPurchasePriceDetailID?: Maybe<Scalars['String']>;
  BulkPurchasePriceItemID?: Maybe<Scalars['String']>;
  LowerLimit?: Maybe<Scalars['Float']>;
  UpperLimit?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
};

export type BulkPurchasePriceHeaderEntity = AuditEntity & {
  __typename?: 'BulkPurchasePriceHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BulkPurchasePriceHeaderID: Scalars['String'];
  SupplierID: Scalars['String'];
  Remarks?: Maybe<Scalars['String']>;
  EffectiveDate: Scalars['String'];
  ExpiryDate: Scalars['String'];
  BulkPurchasePriceItem?: Maybe<Array<BulkPurchasePriceItemEntity>>;
  Supplier?: Maybe<CreditorAccountEntity>;
};

export type BulkPurchasePriceHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  BulkPurchasePriceHeaderID?: Maybe<Scalars['String']>;
  SupplierID: Scalars['String'];
  EffectiveDate: Scalars['String'];
  ExpiryDate?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  File?: Maybe<Array<Scalars['Upload']>>;
};

export type BulkPurchasePriceItemEntity = AuditEntity & {
  __typename?: 'BulkPurchasePriceItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BulkPurchasePriceItemID: Scalars['String'];
  BulkPurchasePriceHeaderID: Scalars['String'];
  BulkPurchasePriceHeader?: Maybe<BulkPurchasePriceHeaderEntity>;
  ItemID: Scalars['String'];
  Item?: Maybe<ItemEntity>;
  UOMID: Scalars['String'];
  BulkPurchasePriceDetail?: Maybe<Array<BulkPurchasePriceDetailEntity>>;
  UOM?: Maybe<AccUomEntity>;
};

export type BulkPurchasePriceItemInput = {
  BulkPurchasePriceItemID?: Maybe<Scalars['String']>;
  BulkPurchasePriceHeaderID?: Maybe<Scalars['String']>;
  UOMID: Scalars['String'];
  ItemID: Scalars['String'];
  BulkPurchaseDetail: Array<BulkPurchasePriceDetailInput>;
};

export type CbglExportEntity = AuditEntity & {
  __typename?: 'CBGLExportEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  GLExportID: Scalars['String'];
  BatchNo?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  SourceID?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  GLDate: Scalars['String'];
  TransactionDate: Scalars['String'];
  AccountCode?: Maybe<Scalars['String']>;
  Debit?: Maybe<Scalars['Float']>;
  Credit?: Maybe<Scalars['Float']>;
};

export type CbImportLedgerInput = {
  AccountID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  LedgerTypeName?: Maybe<Scalars['String']>;
  DocumentDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  BankCode?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  PayeeReceiverName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
};

/** JsonType */
export type CoaImport = {
  __typename?: 'COAImport';
  createdBy?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['DateTime']>;
  Code?: Maybe<Scalars['String']>;
  ParentCode?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['String']>;
  AccountType?: Maybe<Scalars['String']>;
  IsControl?: Maybe<Scalars['String']>;
  IsLastNode?: Maybe<Scalars['String']>;
  ErrorList?: Maybe<Array<Scalars['String']>>;
};

/** JsonType */
export type CoaImportFinal = {
  __typename?: 'COAImportFinal';
  ErrorData?: Maybe<Array<CoaImport>>;
  ValidatedData?: Maybe<Array<CoaImport>>;
};

export type CoaStructureDeleteInput = {
  COAStructureID: Scalars['String'];
};

export type CoaStructureEntity = AuditEntity & {
  __typename?: 'COAStructureEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  COAStructureID: Scalars['String'];
  Level: Scalars['Float'];
  CodeLength: Scalars['String'];
};

export type CoaStructureInput = {
  COAStructureID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  Level: Scalars['Float'];
  CodeLength: Scalars['String'];
};

export type CancelInput = {
  CancellationDate?: Maybe<Scalars['String']>;
  CancellationRemark?: Maybe<Scalars['String']>;
};

export type CellValuesInput = {
  Coordinate: Scalars['String'];
  Value: Scalars['String'];
};

export type CellValuesJson = {
  __typename?: 'CellValuesJson';
  Coordinate: Scalars['String'];
  Value: Scalars['String'];
};

export type CheckExistingAccountPeriod = {
  __typename?: 'CheckExistingAccountPeriod';
  LastYear?: Maybe<Scalars['Boolean']>;
  LastMonth?: Maybe<Scalars['Boolean']>;
};

export enum ColumnSource {
  Actual = 'ACTUAL',
  Budget = 'BUDGET'
}

export enum CommonStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Block = 'BLOCK'
}

export type CompanyDeleteInput = {
  CompanyID: Scalars['String'];
};

export type CompanyEntity = AuditEntity & {
  __typename?: 'CompanyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  AccountID: Scalars['String'];
  ParentCompanyID?: Maybe<Scalars['String']>;
  BaseCurrencyID: Scalars['String'];
  Currency: CurrencyEntity;
  Name: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  CompanyRegNo: Scalars['String'];
  Tin?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Address?: Maybe<Address>;
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  SSTNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  GSTExpiryDate?: Maybe<Scalars['String']>;
  GroupGSTRegNo?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  ImageUrl?: Maybe<Scalars['String']>;
  ImageBucketFile?: Maybe<Scalars['String']>;
  WebsiteUrl?: Maybe<Scalars['String']>;
  eSign?: Maybe<Scalars['String']>;
  eSignExpiryDate?: Maybe<Scalars['DateTime']>;
  eInvIntegrated: Scalars['Boolean'];
  eInvStartDate?: Maybe<Scalars['String']>;
  eInvEndDate?: Maybe<Scalars['String']>;
  eInvClientID?: Maybe<Scalars['String']>;
  eInvSecretKey?: Maybe<Scalars['String']>;
  eInvSecretKey2?: Maybe<Scalars['String']>;
  TourismTaxNo?: Maybe<Scalars['String']>;
  RegionID?: Maybe<Scalars['String']>;
  ImageLogoPosition?: Maybe<Scalars['String']>;
  WatermarkImplementation: Scalars['Boolean'];
  WatermarkWording?: Maybe<Scalars['String']>;
  IsoImplementation: Scalars['Boolean'];
  IsoLogoPosition?: Maybe<Scalars['String']>;
  CompanyTax: CompanyTax;
  RecordStatus: Scalars['String'];
  /** CustomFieldResolver */
  Location?: Maybe<DeliveryLocationEntity>;
  /** CustomFieldResolver */
  BankAccount?: Maybe<AccBankAccountEntity>;
  /** CustomFieldResolver */
  EntityCOA?: Maybe<EntityCoaEntity>;
  /** CustomFieldResolver */
  ParentCompany?: Maybe<CompanyInfo>;
  /** CustomFieldResolver */
  DefaultCostCentre?: Maybe<CostCentreEntity>;
  /** CustomFieldResolver */
  Logo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  EInvoiceStarted?: Maybe<Scalars['Boolean']>;
};


export type CompanyEntityEInvoiceStartedArgs = {
  DocDate?: Maybe<Scalars['DateTime']>;
};

export type CompanyImportInput = {
  CompanyName?: Maybe<Scalars['String']>;
  RegistrationNo?: Maybe<Scalars['String']>;
  CompanyTax?: Maybe<Scalars['String']>;
  BaseCurrency?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  RowNo?: Maybe<Scalars['Float']>;
};

/** JsonType */
export type CompanyInfo = {
  __typename?: 'CompanyInfo';
  Name?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type CompanyInput = {
  CompanyID?: Maybe<Scalars['String']>;
  ParentCompanyID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Address?: Maybe<Scalars['JSON']>;
  ContactNo?: Maybe<Scalars['String']>;
  CompanyRegNo: Scalars['String'];
  Code: Scalars['String'];
  Email?: Maybe<Scalars['String']>;
  TourismTaxNo?: Maybe<Scalars['String']>;
  CompanyTax?: Maybe<CompanyTax>;
  SSTNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  GSTExpiryDate?: Maybe<Scalars['String']>;
  GroupGSTRegNo?: Maybe<Scalars['String']>;
  eInvIntegrated: Scalars['Boolean'];
  Tin?: Maybe<Scalars['String']>;
  eSign?: Maybe<Scalars['String']>;
  eSignExpiryDate?: Maybe<Scalars['DateTime']>;
  eInvStartDate?: Maybe<Scalars['String']>;
  eInvEndDate?: Maybe<Scalars['String']>;
  eInvClientID?: Maybe<Scalars['String']>;
  eInvSecretKey?: Maybe<Scalars['String']>;
  eInvSecretKey2?: Maybe<Scalars['String']>;
  BaseCurrencyID: Scalars['String'];
  RecordStatus: Scalars['String'];
  Attachment?: Maybe<Array<Scalars['Upload']>>;
  LogoFiles?: Maybe<Array<Scalars['Upload']>>;
};

export type CompanyLetterTemplateDeleteInput = {
  CompanyLetterTemplateID?: Maybe<Scalars['String']>;
};

export type CompanyLetterTemplateEntity = {
  __typename?: 'CompanyLetterTemplateEntity';
  CompanyLetterTemplateID: Scalars['String'];
  AccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  StandardLetterID: Scalars['String'];
  LetterTemplateID: Scalars['String'];
  Name: Scalars['String'];
  TemplateBody?: Maybe<Scalars['String']>;
  JointBuyerSignRequired?: Maybe<Scalars['Boolean']>;
  StandardLetterDataSourceVersion?: Maybe<Scalars['Float']>;
  IsReportDesignEditor?: Maybe<Scalars['Boolean']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  RecordStatus: RecordStatus;
  StandardLetter?: Maybe<StandardLetterEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type CompanyLetterTemplateInput = {
  CompanyLetterTemplateID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  StandardLetterID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  TemplateBody?: Maybe<Scalars['String']>;
  JointBuyerSignRequired?: Maybe<Scalars['Boolean']>;
  StandardDataSourceVersion?: Maybe<Scalars['Float']>;
  IsReportDesignEditor?: Maybe<Scalars['Boolean']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  LetterTemplateID?: Maybe<Scalars['String']>;
};

/** JsonType */
export type CompanyReturnType = {
  __typename?: 'CompanyReturnType';
  Data?: Maybe<Array<CompanyEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export enum CompanyTax {
  NoTax = 'NO_TAX',
  SstNo = 'SST_NO',
  GstNo = 'GST_NO'
}

export type ContactInput = {
  ContactID?: Maybe<Scalars['String']>;
  AssociateID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Designation?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  DocRefTable?: Maybe<Scalars['String']>;
};

/** JsonType */
export type ContactPerson = {
  __typename?: 'ContactPerson';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ContactPersonInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type CoreBaseEntity = AuditEntity & {
  __typename?: 'CoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type CoreDocTypeEntity = AuditEntity & {
  __typename?: 'CoreDocTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  docName: Scalars['String'];
  commonStatus: CommonStatus;
};

export type CoreEntityRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'CoreEntityRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
};

export type CoreNumberFormatEntity = AuditEntity & {
  __typename?: 'CoreNumberFormatEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  docTypeID: Scalars['String'];
  numberFormat: Scalars['String'];
  numberLength: Scalars['Float'];
  prefix: Scalars['String'];
  commonStatus: CommonStatus;
};

export type CorePermissionEntity = {
  __typename?: 'CorePermissionEntity';
  index: Scalars['String'];
  name: Scalars['String'];
};

export type CoreReceiptPaymentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  DocRef?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  DocDueDate?: Maybe<Scalars['String']>;
  CancellationDate?: Maybe<Scalars['String']>;
  CancellationRemark?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  BaseAmt?: Maybe<Scalars['Float']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  LocalBaseAmt?: Maybe<Scalars['Float']>;
  BankDocAmt?: Maybe<Scalars['Float']>;
  LocalTaxAmt?: Maybe<Scalars['Float']>;
  CurrencyID?: Maybe<Scalars['String']>;
  BankAccountID: Scalars['String'];
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type CoreRoleEntity = AuditEntity & {
  __typename?: 'CoreRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'CoreRolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
};

export type CoreRunningNumberSetupEntity = AuditEntity & {
  __typename?: 'CoreRunningNumberSetupEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  numberFormatID: Scalars['String'];
  nextNumber: Scalars['Float'];
};

export type CostCentreCoaDeleteInput = {
  CostCentreCOAID: Scalars['String'];
};

export type CostCentreCoaEntity = AuditEntity & {
  __typename?: 'CostCentreCOAEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CostCentreCOAID: Scalars['String'];
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  MasterCOAID: Scalars['String'];
  MasterCOA?: Maybe<MasterCoaEntity>;
};

export type CostCentreCoaInput = {
  CostCentreCOAID?: Maybe<Scalars['String']>;
  CostCentreID: Scalars['String'];
  MasterCOAID: Scalars['String'];
};

export type CostCentreDefSummary = {
  __typename?: 'CostCentreDefSummary';
  CostCentreDefinitionHeader?: Maybe<CostCentreDefinitionHeaderEntity>;
  CostCentreDefinitionDetail?: Maybe<Array<CostCentreDefinitionDetailEntity>>;
};

export type CostCentreDefinitionAsgmtEntity = AuditEntity & {
  __typename?: 'CostCentreDefinitionAsgmtEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CostCentreDefinitionAsgmtID: Scalars['String'];
  CompanyID: Scalars['String'];
  CostCentreDefinitionHeaderID: Scalars['String'];
  CostCentreDefinitionHeader?: Maybe<CostCentreDefinitionHeaderEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type CostCentreDefinitionAsgmtInput = {
  CostCentreDefinitionAsgmtID?: Maybe<Scalars['String']>;
  CostCentreDefinitionHeaderID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};

export type CostCentreDefinitionDetailDeleteInput = {
  CostCentreDefinitionDetailID: Scalars['String'];
};

export type CostCentreDefinitionDetailEntity = AuditEntity & {
  __typename?: 'CostCentreDefinitionDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CostCentreDefinitionDetailID: Scalars['String'];
  CostCentreDefinitionHeaderID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Level: Scalars['Float'];
  IsLastNode: Scalars['Boolean'];
  ParentCostCentreDefinitionDetailID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CostCentreDefinitionHeader?: Maybe<CostCentreDefinitionHeaderEntity>;
};

export type CostCentreDefinitionDetailInput = {
  Name: Scalars['String'];
  Level: Scalars['Float'];
  IsLastNode: Scalars['Boolean'];
  CostCentreDefinitionDetailID?: Maybe<Scalars['String']>;
  ParentCostCentreDefinitionDetailID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type CostCentreDefinitionHeaderDeleteInput = {
  CostCentreDefinitionHeaderID: Scalars['String'];
};

export type CostCentreDefinitionHeaderEntity = AuditEntity & {
  __typename?: 'CostCentreDefinitionHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CostCentreDefinitionHeaderID: Scalars['String'];
  Title: Scalars['String'];
  CostCentreDefConnection?: Maybe<Array<CostCentreDefinitionAsgmtEntity>>;
  CostCentreDefinitionDetail?: Maybe<Array<CostCentreDefinitionDetailEntity>>;
};

export type CostCentreDefinitionHeaderInput = {
  CostCentreDefinitionHeaderID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};

export type CostCentreDeleteInput = {
  CostCentreID: Scalars['String'];
};

export type CostCentreEntity = AuditEntity & {
  __typename?: 'CostCentreEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CostCentreID: Scalars['String'];
  ParentCostCentreID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Name: Scalars['String'];
  Level: Scalars['Float'];
  IsLastNode: Scalars['Boolean'];
  IsDefault: Scalars['Boolean'];
  Code?: Maybe<Scalars['String']>;
  MasterCode?: Maybe<Scalars['String']>;
  CostCentreDefinitionDetailID?: Maybe<Scalars['String']>;
  BudgetItemCostCentre?: Maybe<Array<BudgetItemCostCentreEntity>>;
  JournalItem?: Maybe<Array<JournalItemEntity>>;
  LedgerItem?: Maybe<Array<LedgerItemEntity>>;
  ExternalJournalItem?: Maybe<Array<ExternalJournalItemEntity>>;
  JournalProcessingItem?: Maybe<Array<JournalProcessingItemEntity>>;
  RecurringJournalGenerationItem?: Maybe<Array<RecurringJournalGenerationItemEntity>>;
  RecurringJournalItem?: Maybe<Array<RecurringJournalItemEntity>>;
  FavoriteJournalProcessingItem?: Maybe<Array<FavoriteJournalProcessingItemEntity>>;
  PaymentItem?: Maybe<Array<PaymentItemEntity>>;
  ReceiptItem?: Maybe<Array<ReceiptItemEntity>>;
  FavoritePaymentItem?: Maybe<Array<FavoritePaymentItemEntity>>;
  FavoriteReceiptItem?: Maybe<Array<FavoriteReceiptItemEntity>>;
  FavoriteInvoiceItem?: Maybe<Array<AccArFavoriteInvoiceItemEntity>>;
  APFavoriteInvoiceItem?: Maybe<Array<AccApFavoriteInvoiceItemEntity>>;
  APInvoiceItem?: Maybe<Array<AccApInvoiceItemEntity>>;
  ARInvoiceItem?: Maybe<Array<AccArInvoiceItemEntity>>;
  AuditAdjustmentItem?: Maybe<Array<AuditAdjustmentItemEntity>>;
  ARCreditNote?: Maybe<Array<AccArCreditNoteEntity>>;
  APCreditNoteItem?: Maybe<Array<AccApCreditNoteItemEntity>>;
  ARCreditNoteItem?: Maybe<Array<AccArCreditNoteItemEntity>>;
  APDebitNoteItem?: Maybe<Array<AccApDebitNoteItemEntity>>;
  APCreditorDebitNoteItem?: Maybe<Array<AccApCreditorDebitNoteItemEntity>>;
  APSelfBilledItem?: Maybe<Array<AccApSelfBilledItemEntity>>;
  ARDebitNoteItem?: Maybe<Array<AccArDebitNoteItemEntity>>;
  APCreditNote?: Maybe<Array<AccApCreditNoteEntity>>;
  APCreditorCreditNote?: Maybe<Array<AccApCreditorCreditNoteEntity>>;
  APCreditorCreditNoteItem?: Maybe<Array<AccApCreditorCreditNoteItemEntity>>;
  Warehouse?: Maybe<Array<WarehouseEntity>>;
  BankAccount?: Maybe<Array<AccBankAccountEntity>>;
  StockReceipt?: Maybe<Array<StockReceiptEntity>>;
  StockIssue?: Maybe<Array<StockIssueEntity>>;
  StockAdjustment?: Maybe<Array<StockAdjustmentEntity>>;
  StockTransfer?: Maybe<Array<StockTransferEntity>>;
  CycleCount?: Maybe<Array<CycleCountEntity>>;
  StockCostAdjustment?: Maybe<Array<StockCostAdjustmentEntity>>;
  StockRequisition?: Maybe<Array<StockRequisitionEntity>>;
  FavoriteStockRequisition?: Maybe<Array<FavoriteStockRequisitionEntity>>;
  CostCentreCOA?: Maybe<Array<CostCentreCoaEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  UsedDepartment?: Maybe<Scalars['Boolean']>;
};

export type CostCentreInput = {
  CostCentreID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CostCentreDefinitionDetailID?: Maybe<Scalars['String']>;
  ParentCostCentreID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Level?: Maybe<Scalars['Float']>;
  Code?: Maybe<Scalars['String']>;
  MasterCode?: Maybe<Scalars['String']>;
  IsLastNode: Scalars['Boolean'];
};

export type CostCentreTypeDeleteInput = {
  CostCentreTypeID: Scalars['String'];
};

export type CostCentreTypeEntity = AuditEntity & {
  __typename?: 'CostCentreTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CostCentreTypeID: Scalars['String'];
  Name: Scalars['String'];
};

export type CostCentreTypeInput = {
  CostCentreTypeID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
};

export type CreateAuditInput = {
  createdBy?: Maybe<Scalars['String']>;
};

export type CreatePermRoleAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  permId: Scalars['String'];
  roleId: Scalars['String'];
};

export type CreditCardTypeDeleteInput = {
  CreditCardID: Scalars['String'];
};

export type CreditCardTypeEntity = AuditEntity & {
  __typename?: 'CreditCardTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CreditCardID: Scalars['String'];
  Name: Scalars['String'];
  Description: Scalars['String'];
  Sequence: Scalars['Float'];
  IsSystem: Scalars['Boolean'];
  APPayment?: Maybe<Array<AccApPaymentEntity>>;
  APFavoritePayment?: Maybe<Array<AccApFavoritePaymentEntity>>;
  APRefund?: Maybe<Array<AccApRefundEntity>>;
  AROfficialReceipt?: Maybe<Array<AccArOfficialReceiptEntity>>;
  ARRefund?: Maybe<Array<AccArRefundEntity>>;
  Payment?: Maybe<Array<PaymentEntity>>;
  Receipt?: Maybe<Array<ReceiptEntity>>;
  BankTransfer?: Maybe<Array<BankTransferEntity>>;
  FavoritePayment?: Maybe<Array<FavoritePaymentEntity>>;
  FavoriteReceipt?: Maybe<Array<FavoriteReceiptEntity>>;
  ARAdvance?: Maybe<Array<AccArAdvanceEntity>>;
  APAdvance?: Maybe<Array<AccArAdvanceEntity>>;
};

export type CreditCardTypeInput = {
  CreditCardID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Description: Scalars['String'];
  Sequence: Scalars['Float'];
  IsSystem: Scalars['Boolean'];
  IsUsed?: Maybe<Scalars['Boolean']>;
};

export type CreditorAccountCompanyAssignmentDeleteInput = {
  CreditorAccountCompanyAssignmentID: Scalars['String'];
};

export type CreditorAccountCompanyAssignmentEntity = AuditEntity & {
  __typename?: 'CreditorAccountCompanyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CreditorAccountCompanyAssignmentID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
};

export type CreditorAccountCompanyAssignmentInput = {
  CreditorAccountCompanyAssignmentID?: Maybe<Scalars['String']>;
  CreditorAccountID: Scalars['String'];
  companyID: Array<Scalars['String']>;
};

/** JsonType */
export type CreditorAccountCompanyAssignmentReturnData = {
  __typename?: 'CreditorAccountCompanyAssignmentReturnData';
  Data?: Maybe<Array<CreditorAccountCompanyAssignmentEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type CreditorAccountDeleteInput = {
  CreditorAccountID: Scalars['String'];
};

export type CreditorAccountEntity = AuditEntity & {
  __typename?: 'CreditorAccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CreditorAccountID: Scalars['String'];
  CreditorTypeID: Scalars['String'];
  CreditorType?: Maybe<CreditorTypeEntity>;
  CompanyRegNo: Scalars['String'];
  CompanyName: Scalars['String'];
  TaxIdentificationNo?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  CreditTerm: Scalars['Float'];
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  Region?: Maybe<Scalars['String']>;
  GSTRegNo?: Maybe<Scalars['String']>;
  SSTRegNo?: Maybe<Scalars['String']>;
  IsCompany: Scalars['Boolean'];
  einvoice_foreign_supplier: Scalars['Boolean'];
  einvoice_self_billed: Scalars['Boolean'];
  einvoice_TIN?: Maybe<Scalars['String']>;
  einvoice_type: EInvoiceType;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  BankName?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  SWIFTCode?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['JSON']>;
  CreditorTypeEnum: CreditorType;
  CreditorStatus: CreditorStatus;
  Advance?: Maybe<Array<AccApAdvanceEntity>>;
  Invoice?: Maybe<Array<AccApInvoiceEntity>>;
  CreditNote?: Maybe<Array<AccApCreditNoteEntity>>;
  CreditorCreditNote?: Maybe<Array<AccApCreditorCreditNoteEntity>>;
  DebitNote?: Maybe<Array<AccApDebitNoteEntity>>;
  SelfBilled?: Maybe<Array<AccApSelfBilledEntity>>;
  CreditorDebitNote?: Maybe<Array<AccApCreditorDebitNoteEntity>>;
  Refund?: Maybe<Array<AccApRefundEntity>>;
  Payment?: Maybe<Array<AccApPaymentEntity>>;
  APFavoritePayment?: Maybe<Array<AccApFavoritePaymentEntity>>;
  FavInvoice?: Maybe<Array<AccApInvoiceEntity>>;
  CreditorContact?: Maybe<Array<CreditorContactEntity>>;
  CreditorDirector?: Maybe<Array<CreditorDirectorEntity>>;
  CreditorAccountCompanyAssignment?: Maybe<Array<CreditorAccountCompanyAssignmentEntity>>;
  /** CustomFieldResolver */
  TotalAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  DocNoList?: Maybe<Array<Scalars['String']>>;
  /** CustomFieldResolver */
  TotalOutstandingAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ClassificationCode?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  MSICCode?: Maybe<Scalars['JSON']>;
};


export type CreditorAccountEntityTotalOutstandingAmtArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};

export type CreditorAccountInput = {
  CreditorAccountID?: Maybe<Scalars['String']>;
  CreditorTypeID: Scalars['String'];
  CompanyRegNo: Scalars['String'];
  CompanyName: Scalars['String'];
  IsCompany?: Maybe<Scalars['Boolean']>;
  ContactNo?: Maybe<Scalars['String']>;
  TaxIdentificationNo?: Maybe<Scalars['String']>;
  einvoice_TIN?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  CreditTerm: Scalars['Float'];
  TaxSchemeID?: Maybe<Scalars['String']>;
  Region?: Maybe<Scalars['String']>;
  GSTRegNo?: Maybe<Scalars['String']>;
  SSTRegNo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  BankName?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  SWIFTCode?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  einvoice_foreign_supplier?: Maybe<Scalars['Boolean']>;
  einvoice_self_billed?: Maybe<Scalars['Boolean']>;
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
  CreditorTypeEnum: CreditorType;
  einvoice_type: EInvoiceType;
  CreditorStatus: CreditorStatus;
  Address?: Maybe<Scalars['JSON']>;
};

/** JsonType */
export type CreditorAccountReturnData = {
  __typename?: 'CreditorAccountReturnData';
  Data?: Maybe<Scalars['JSON']>;
  TotalLength?: Maybe<Scalars['JSON']>;
};

/** JsonType */
export type CreditorArg = {
  __typename?: 'CreditorArg';
  CreditorAccountID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type CreditorContactDeleteInput = {
  CreditorContactID: Scalars['String'];
};

export type CreditorContactEntity = AuditEntity & {
  __typename?: 'CreditorContactEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CreditorContactID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  Name: Scalars['String'];
  ContactNo: Scalars['String'];
  Designation: Scalars['String'];
  Email: Scalars['String'];
  IsDefault: Scalars['Boolean'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
};

export type CreditorContactInput = {
  CreditorContactID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Designation?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
};

/** JsonType */
export type CreditorDebtorSubInput = {
  __typename?: 'CreditorDebtorSubInput';
  AdvanceTotalDocAmt?: Maybe<Scalars['Float']>;
  CreditNoteTotalDocAmt?: Maybe<Scalars['Float']>;
  DebitNoteTotalDocAmt?: Maybe<Scalars['Float']>;
  InvoiceTotalDocAmt?: Maybe<Scalars['Float']>;
};

export type CreditorDirectorDeleteInput = {
  CreditorDirectorID: Scalars['String'];
};

export type CreditorDirectorEntity = AuditEntity & {
  __typename?: 'CreditorDirectorEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CreditorDirectorID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  IdentityNo: Scalars['String'];
  IdentityType?: Maybe<IdentityType>;
  Name: Scalars['String'];
  ContactNo: Scalars['String'];
  Email: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
};

export type CreditorDirectorInput = {
  CreditorDirectorID?: Maybe<Scalars['String']>;
  IdentityNo: Scalars['String'];
  IdentityType?: Maybe<IdentityType>;
  Name?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
};

/** JsonType */
export type CreditorInput = {
  __typename?: 'CreditorInput';
  CreditorAccountID?: Maybe<Scalars['String']>;
  TotalDocAmount?: Maybe<Scalars['Float']>;
};

/** JsonType */
export type CreditorLatestUpdatedDateInput = {
  __typename?: 'CreditorLatestUpdatedDateInput';
  CreditorAccountID?: Maybe<Scalars['String']>;
  LatestUpdatedDate?: Maybe<Scalars['DateTime']>;
};

export type CreditorProfileImportInput = {
  CreditorCategory?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  TaxIdentificationNo?: Maybe<Scalars['String']>;
  CreditorType?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  CreditTerm?: Maybe<Scalars['String']>;
  Tax?: Maybe<Scalars['String']>;
  Region?: Maybe<Scalars['String']>;
  GSTRegNo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  BankName?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  SWIFTCode?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  RowNo?: Maybe<Scalars['Float']>;
};

export enum CreditorStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Block = 'BLOCK'
}

export enum CreditorType {
  Supplier = 'SUPPLIER',
  Contractor = 'CONTRACTOR',
  Consultant = 'CONSULTANT',
  Agent = 'AGENT'
}

export type CreditorTypeDeleteInput = {
  CreditorTypeID: Scalars['String'];
};

export type CreditorTypeEntity = AuditEntity & {
  __typename?: 'CreditorTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CreditorTypeID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  CreditorType: CreditorType;
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  AccrualCOAID?: Maybe<Scalars['String']>;
  AccrualCOA?: Maybe<MasterCoaEntity>;
  AdvanceCOAID?: Maybe<Scalars['String']>;
  AdvanceCOA?: Maybe<MasterCoaEntity>;
  SelfBilledCOAID?: Maybe<Scalars['String']>;
  SelfBilledCOA?: Maybe<MasterCoaEntity>;
  CreditorAccount?: Maybe<Array<CreditorAccountEntity>>;
};

export type CreditorTypeInput = {
  CreditorTypeID?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  MasterCOAID: Scalars['String'];
  AccrualCOAID: Scalars['String'];
  AdvanceCOAID: Scalars['String'];
  SelfBilledCOAID?: Maybe<Scalars['String']>;
  CreditorType: CreditorType;
};

export type CurrencyDeleteInput = {
  CurrencyID: Scalars['String'];
};

export type CurrencyEntity = AuditEntity & {
  __typename?: 'CurrencyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  symbol: Scalars['String'];
  format: NumericFormatter;
  commonStatus: CommonStatus;
  company: Array<CompanyEntity>;
};

export type CurrencyExchangeDeleteInput = {
  CurrencyExchangeID: Scalars['String'];
};

export type CurrencyExchangeInput = {
  CurrencyExchangeID?: Maybe<Scalars['String']>;
  FromCurrencyID: Scalars['String'];
  ToCurrencyID: Scalars['String'];
  EffectiveDate?: Maybe<Scalars['String']>;
  FromAmt: Scalars['Float'];
  ToAmt: Scalars['Float'];
  ExchangeRate: Scalars['Float'];
  Symbol?: Maybe<Scalars['String']>;
  CurrencyLog?: Maybe<Array<CurrencyLogInput>>;
};

export type CurrencyInput = {
  CurrencyID?: Maybe<Scalars['String']>;
  Code: Scalars['String'];
  Name: Scalars['String'];
  Symbol?: Maybe<Scalars['String']>;
};

export type CurrencyLog = {
  __typename?: 'CurrencyLog';
  PrevFromAmt: Scalars['Float'];
  PrevToAmt: Scalars['Float'];
  ModTs: Scalars['DateTime'];
  ModBy: Scalars['DateTime'];
};

export type CurrencyLogInput = {
  PrevFromAmt: Scalars['Float'];
  PrevToAmt: Scalars['Float'];
  ModTs: Scalars['DateTime'];
  ModBy: Scalars['DateTime'];
};

export type CycleCountDeleteInput = {
  CycleCountID: Scalars['String'];
};

export type CycleCountEntity = AuditEntity & {
  __typename?: 'CycleCountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  CycleCountID: Scalars['String'];
  CycleCountItem?: Maybe<Array<CycleCountItemEntity>>;
  WarehouseID: Scalars['String'];
  Warehouse?: Maybe<WarehouseEntity>;
  CompanyID: Scalars['String'];
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
};

export type CycleCountInput = {
  CycleCountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type CycleCountItemDeleteInput = {
  CycleCountItemID: Scalars['String'];
};

export type CycleCountItemEntity = AuditEntity & {
  __typename?: 'CycleCountItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CycleCountItemID: Scalars['String'];
  OnHandQty?: Maybe<Scalars['Float']>;
  CountQty?: Maybe<Scalars['Float']>;
  AdjustQty?: Maybe<Scalars['Float']>;
  WarehouseID?: Maybe<Scalars['String']>;
  IsReconciled: Scalars['Boolean'];
  CompanyID?: Maybe<Scalars['String']>;
  CycleCountID: Scalars['String'];
  CycleCount?: Maybe<CycleCountEntity>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  StockItemID: Scalars['String'];
  StockItem?: Maybe<StockItemEntity>;
  ReasonID?: Maybe<Scalars['String']>;
  Reason?: Maybe<StockAdjustmentReasonEntity>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<TransactionTypeEntity>;
  /** CustomFieldResolver */
  StockItemWarehouse?: Maybe<StockItemWarehouseEntity>;
};

export type CycleCountItemInput = {
  CycleCountItemID?: Maybe<Scalars['String']>;
  CycleCountID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  OnHandQty?: Maybe<Scalars['Float']>;
  CountQty?: Maybe<Scalars['Float']>;
  AdjustQty?: Maybe<Scalars['Float']>;
  WarehouseID?: Maybe<Scalars['String']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
};

export type CycleCountRejectInput = {
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type CycleCountType = {
  __typename?: 'CycleCountType';
  Data: Array<CycleCountEntity>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type DoHeaderEntity = AuditEntity & {
  __typename?: 'DOHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DOHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  SupplierID: Scalars['String'];
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DoNo?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DOItem?: Maybe<Array<DoItemEntity>>;
  /** CustomFieldResolver */
  Company: CompanyEntity;
  Supplier?: Maybe<CreditorAccountEntity>;
  /** CustomFieldResolver */
  DeliveryLocation?: Maybe<DeliveryLocationEntity>;
  /** CustomFieldResolver */
  UpToDateAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  PurchaseOrder?: Maybe<Array<Scalars['JSON']>>;
};


export type DoHeaderEntityUpToDateAmtArgs = {
  TaxInclusive?: Maybe<Scalars['Boolean']>;
};

export type DoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  DOHeaderID?: Maybe<Scalars['String']>;
  SupplierID: Scalars['String'];
  DoNo: Scalars['String'];
  RecordStatus?: Maybe<RecordStatus>;
  ApprovalStatus: ApprovalStatus;
  CompanyID: Scalars['String'];
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  ContactPerson?: Maybe<ContactPersonInput>;
  DeliveryAddress?: Maybe<AddressInput>;
  File?: Maybe<Array<Scalars['Upload']>>;
};

export type DoItemEntity = AuditEntity & {
  __typename?: 'DOItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DOItemID: Scalars['String'];
  DOHeaderID: Scalars['String'];
  DOHeader?: Maybe<DoHeaderEntity>;
  POItemID?: Maybe<Scalars['String']>;
  POItem?: Maybe<PoItemEntity>;
  GRTNItemID?: Maybe<Scalars['String']>;
  ReplacedGRTNItem?: Maybe<GrtnItemEntity>;
  AcceptedQty: Scalars['Float'];
  DeliveredQty?: Maybe<Scalars['Float']>;
  AcceptedAmt?: Maybe<Scalars['Float']>;
  DeliveredAmt?: Maybe<Scalars['Float']>;
  GRTNItem?: Maybe<Array<GrtnItemEntity>>;
  /** CustomFieldResolver */
  UpToDateQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ReturnedQty?: Maybe<Scalars['Float']>;
};

export type DoItemInput = {
  POHeaderID?: Maybe<Scalars['String']>;
  GRTNHeaderID?: Maybe<Scalars['String']>;
  POItemsInput: Array<PoItemsInput>;
};

export type DoRejectInput = {
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type DOsInput = {
  PurchaseOrderID: Scalars['String'];
  POItemID: Scalars['String'];
  ReceivedQty: Scalars['Float'];
};

export type DataSource = {
  __typename?: 'DataSource';
  DataSource: Scalars['String'];
  Columns?: Maybe<Array<Scalars['String']>>;
  DataMember: Scalars['String'];
  IsStoreProcedure?: Maybe<Scalars['Boolean']>;
  IsMainQuery?: Maybe<Scalars['Boolean']>;
  DynamicFilterColumn?: Maybe<Scalars['String']>;
};

export type DataSourceInput = {
  DataSource: Scalars['String'];
  Columns?: Maybe<Array<Scalars['String']>>;
  DataMember: Scalars['String'];
  IsStoreProcedure?: Maybe<Scalars['Boolean']>;
  IsMainQuery?: Maybe<Scalars['Boolean']>;
  DynamicFilterColumn?: Maybe<Scalars['String']>;
};

export type DataSourceSettingsDeleteInput = {
  DataSourceSettingsID: Scalars['String'];
};

export type DataSourceSettingsEntity = AuditEntity & {
  __typename?: 'DataSourceSettingsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DataSourceSettingsID: Scalars['String'];
  Name: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ColumnSource: ColumnSource;
  ColumnValue: AmountType;
  YearType: YearPeriodType;
  YearValue: Scalars['Float'];
  PeriodType?: Maybe<YearPeriodType>;
  PeriodValue?: Maybe<Scalars['Float']>;
  Duration: DurationType;
};

export type DataSourceSettingsInput = {
  DataSourceSettingsID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ColumnSource?: Maybe<ColumnSource>;
  ColumnValue?: Maybe<AmountType>;
  YearType?: Maybe<YearPeriodType>;
  PeriodType?: Maybe<YearPeriodType>;
  YearValue?: Maybe<Scalars['Float']>;
  PeriodValue?: Maybe<Scalars['Float']>;
  Duration?: Maybe<Scalars['String']>;
};


export type DebtorAccountDeleteInput = {
  DebtorAccountID: Scalars['String'];
};

export type DebtorAccountEntity = AuditEntity & {
  __typename?: 'DebtorAccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DebtorAccountID: Scalars['String'];
  DebtorTypeID: Scalars['String'];
  DebtorType?: Maybe<DebtorTypeEntity>;
  CompanyID: Scalars['String'];
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  CompanyRegNo: Scalars['String'];
  DebtorName: Scalars['String'];
  MobileNo?: Maybe<Scalars['String']>;
  OfficeNo?: Maybe<Scalars['String']>;
  CreditTerm: Scalars['Float'];
  Amount?: Maybe<Scalars['Float']>;
  GSTRegNo?: Maybe<Scalars['String']>;
  GSTStartDate?: Maybe<Scalars['String']>;
  IsVIP: Scalars['Boolean'];
  IsCompany: Scalars['Boolean'];
  Remark?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['JSON']>;
  DebtorContact?: Maybe<Array<DebtorContactEntity>>;
  Advance?: Maybe<Array<AccArAdvanceEntity>>;
  Invoice?: Maybe<Array<AccArInvoiceEntity>>;
  FavInvoice?: Maybe<Array<AccArFavoriteInvoiceEntity>>;
  CreditNote?: Maybe<Array<AccArCreditNoteEntity>>;
  DebitNote?: Maybe<Array<AccArDebitNoteEntity>>;
  Receipt?: Maybe<Array<AccArOfficialReceiptEntity>>;
  Refund?: Maybe<Array<AccArRefundEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  TotalAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  TotalOSAmt?: Maybe<Scalars['Float']>;
};

export type DebtorAccountImportInput = {
  DebtorType?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  DebtorName?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  OfficeNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  CreditTerm?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsVIP?: Maybe<Scalars['String']>;
  IsCompany?: Maybe<Scalars['String']>;
  Tax?: Maybe<Scalars['String']>;
  GSTRegNo?: Maybe<Scalars['String']>;
  GSTStartDate?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  RowNo?: Maybe<Scalars['Float']>;
};

export type DebtorAccountInput = {
  DebtorAccountID?: Maybe<Scalars['String']>;
  DebtorTypeID: Scalars['String'];
  CompanyRegNo: Scalars['String'];
  DebtorName: Scalars['String'];
  MobileNo?: Maybe<Scalars['String']>;
  OfficeNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  CreditTerm: Scalars['Float'];
  TaxSchemeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsVIP?: Maybe<Scalars['Boolean']>;
  IsCompany?: Maybe<Scalars['Boolean']>;
  GSTRegNo?: Maybe<Scalars['String']>;
  GSTStartDate?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['JSON']>;
};

export type DebtorContactDeleteInput = {
  DebtorContactID: Scalars['String'];
};

export type DebtorContactEntity = AuditEntity & {
  __typename?: 'DebtorContactEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DebtorContactID: Scalars['String'];
  DebtorAccountID: Scalars['String'];
  Name: Scalars['String'];
  ContactNo: Scalars['String'];
  Designation: Scalars['String'];
  Email: Scalars['String'];
  DebtorAccount?: Maybe<DebtorAccountEntity>;
};

export type DebtorContactInput = {
  DebtorContactID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  ContactNo: Scalars['String'];
  Designation: Scalars['String'];
  Email: Scalars['String'];
};

/** JsonType */
export type DebtorInput = {
  __typename?: 'DebtorInput';
  DebtorAccountID?: Maybe<Scalars['String']>;
  TotalDocAmount?: Maybe<Scalars['Float']>;
};

/** JsonType */
export type DebtorLatestUpdatedDateInput = {
  __typename?: 'DebtorLatestUpdatedDateInput';
  DebtorAccountID?: Maybe<Scalars['String']>;
  LatestUpdatedDate?: Maybe<Scalars['DateTime']>;
};

export type DebtorTypeDeleteInput = {
  DebtorTypeID: Scalars['String'];
};

export type DebtorTypeEntity = AuditEntity & {
  __typename?: 'DebtorTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DebtorTypeID: Scalars['String'];
  Name: Scalars['String'];
  MasterCOAID: Scalars['String'];
  MasterCOA?: Maybe<MasterCoaEntity>;
  DebtorAccount?: Maybe<Array<DebtorAccountEntity>>;
};

export type DebtorTypeInput = {
  DebtorTypeID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  MasterCOAID: Scalars['String'];
};

export type DefaultUomEntity = AuditEntity & {
  __typename?: 'DefaultUomEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DefaultUOMID?: Maybe<Scalars['String']>;
  DefaultUOMQty?: Maybe<Scalars['Float']>;
  DefaultUOMUnitCost?: Maybe<Scalars['Float']>;
  DefaultUOMTotalCost?: Maybe<Scalars['Float']>;
};

export type DeliveryLocationDeleteInput = {
  DeliveryLocationID: Scalars['String'];
};

export type DeliveryLocationEntity = AuditEntity & {
  __typename?: 'DeliveryLocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DeliveryLocationID: Scalars['String'];
  CompanyID: Scalars['String'];
  Name: Scalars['String'];
  Address: Scalars['JSON'];
  ContactNo?: Maybe<Scalars['String']>;
  Latitude?: Maybe<Scalars['String']>;
  Longitude?: Maybe<Scalars['String']>;
  MainLocation: Scalars['Boolean'];
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type DeliveryLocationInput = {
  DeliveryLocationID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Address?: Maybe<Scalars['JSON']>;
  ContactNo?: Maybe<Scalars['String']>;
  Latitude?: Maybe<Scalars['String']>;
  Longitude?: Maybe<Scalars['String']>;
  MainLocation?: Maybe<Scalars['Boolean']>;
};

export type DirectPoInput = {
  POHeaderID?: Maybe<Scalars['String']>;
  SupplierID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  POType: PoType;
  Instruction?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  ContactPerson?: Maybe<Scalars['JSON']>;
  RecordStatus?: Maybe<RecordStatus>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  File?: Maybe<Array<Scalars['Upload']>>;
  POItems: Array<PoItemInput>;
  CreditTerm?: Maybe<Scalars['Float']>;
};

/** JsonType */
export type DocDateInput = {
  __typename?: 'DocDateInput';
  DocDate?: Maybe<Scalars['DateTime']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type DocRefInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  DocRef?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  DocDueDate?: Maybe<Scalars['String']>;
  CancellationDate?: Maybe<Scalars['String']>;
  CancellationRemark?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  BaseAmt?: Maybe<Scalars['Float']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type DocumentDateValidationDeleteInput = {
  DocumentDateValidationID: Scalars['String'];
};

export type DocumentDateValidationEntity = AuditEntity & {
  __typename?: 'DocumentDateValidationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DocumentDateValidationID: Scalars['String'];
  MonthsBefore: Scalars['Float'];
  MonthsAfter: Scalars['Float'];
};

export type DocumentDateValidationInput = {
  DocumentDateValidationID?: Maybe<Scalars['String']>;
  MonthsBefore?: Maybe<Scalars['Float']>;
  MonthsAfter?: Maybe<Scalars['Float']>;
};

export type DocumentEntity = AuditEntity & {
  __typename?: 'DocumentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DocumentID: Scalars['String'];
  mediaType: Scalars['String'];
  refTable: RefTable;
  DocumentFolderID?: Maybe<Scalars['String']>;
  DocumentFolder?: Maybe<DocumentFolderEntity>;
  associateID?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
  bucketFileName?: Maybe<Scalars['String']>;
  opacity?: Maybe<Scalars['String']>;
  isPrivate: Scalars['Boolean'];
  folderExist: Scalars['Boolean'];
};

export type DocumentFolderEntity = AuditEntity & {
  __typename?: 'DocumentFolderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  DocumentFolderID: Scalars['String'];
  AccountID: Scalars['String'];
  Name: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Document?: Maybe<Array<DocumentEntity>>;
};

export type DocumentFolderInput = {
  DocumentFolderID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
};

export type DocumentNumberDetailDeleteInput = {
  DocumentNumberDetailID: Scalars['String'];
};

export type DocumentNumberDetailEntity = AuditEntity & {
  __typename?: 'DocumentNumberDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DocumentNumberDetailID: Scalars['String'];
  DocumentNumberHeaderID: Scalars['String'];
  Sequence: Scalars['Float'];
  FormatType: Scalars['String'];
  FormatValue: Scalars['String'];
  NextNumber?: Maybe<Scalars['Float']>;
  StartFrom?: Maybe<Scalars['Float']>;
  ResetBy?: Maybe<Scalars['String']>;
  DocumentNumberHeader?: Maybe<DocumentNumberHeaderEntity>;
};

export type DocumentNumberDetailInput = {
  DocumentNumberDetailID?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  Sequence: Scalars['Float'];
  FormatType: Scalars['String'];
  FormatValue: Scalars['String'];
  NextNumber?: Maybe<Scalars['Float']>;
  StartFrom?: Maybe<Scalars['Float']>;
  ResetBy?: Maybe<Scalars['String']>;
};

export type DocumentNumberHeaderDeleteInput = {
  DocumentNumberHeaderID: Scalars['String'];
};

export type DocumentNumberHeaderEntity = AuditEntity & {
  __typename?: 'DocumentNumberHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DocumentNumberHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  Type: DocumentType;
  SampleOutput: Scalars['String'];
  RefTable: Scalars['String'];
  RefRecordID: Scalars['String'];
  IsAutoDocNo?: Maybe<Scalars['Boolean']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  DocumentNumberDetail?: Maybe<Array<DocumentNumberDetailEntity>>;
  DocumentNumberResetBy?: Maybe<Array<DocumentNumberResetByEntity>>;
};

export type DocumentNumberHeaderInput = {
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Type?: Maybe<DocumentType>;
  SampleOutput: Scalars['String'];
  RefTable: Scalars['String'];
  RefRecordID: Scalars['String'];
};

export type DocumentNumberResetByEntity = AuditEntity & {
  __typename?: 'DocumentNumberResetByEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DocumentNumberResetByID: Scalars['String'];
  DocumentNumberHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  Year?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  NextNumber?: Maybe<Scalars['Float']>;
  DocumentNumberHeader?: Maybe<DocumentNumberHeaderEntity>;
};

export enum DocumentType {
  JournalProcessing = 'JOURNAL_PROCESSING',
  ExternalJournal = 'EXTERNAL_JOURNAL',
  RecurringJournal = 'RECURRING_JOURNAL',
  AuditAdjustment = 'AUDIT_ADJUSTMENT',
  Payment = 'PAYMENT',
  PaymentAp = 'PAYMENT_AP',
  PaymentCb = 'PAYMENT_CB',
  ReceiptCb = 'RECEIPT_CB',
  Receipt = 'RECEIPT',
  BankTransfer = 'BANK_TRANSFER',
  Advance = 'ADVANCE',
  Invoice = 'INVOICE',
  CreditNote = 'CREDIT_NOTE',
  DebitNote = 'DEBIT_NOTE',
  Refund = 'REFUND',
  SelfBilled = 'SELF_BILLED',
  ConsolidateEinvoice = 'CONSOLIDATE_EINVOICE',
  OfficialReceipt = 'OFFICIAL_RECEIPT',
  Interest = 'INTEREST',
  StockReceipt = 'STOCK_RECEIPT',
  StockIssue = 'STOCK_ISSUE',
  StockTransfer = 'STOCK_TRANSFER',
  CycleCounting = 'CYCLE_COUNTING',
  StockAdjustment = 'STOCK_ADJUSTMENT',
  StockCostAdjustment = 'STOCK_COST_ADJUSTMENT',
  StockRequisition = 'STOCK_REQUISITION',
  PurchaseRequisition = 'PURCHASE_REQUISITION',
  RequestForQuotation = 'REQUEST_FOR_QUOTATION',
  PurchaseOrder = 'PURCHASE_ORDER',
  GoodReceiveNote = 'GOOD_RECEIVE_NOTE',
  GoodReturnNote = 'GOOD_RETURN_NOTE',
  Employee = 'EMPLOYEE'
}

export type DropDownDataSource = {
  __typename?: 'DropDownDataSource';
  DataSource: Scalars['String'];
  DataMember: Scalars['String'];
  parameters: Array<DropDownParamterInterface>;
};

export type DropDownDataSourceInput = {
  DataSource: Scalars['String'];
  DataMember: Scalars['String'];
  parameters: Array<DropDownParamterInterfaceInput>;
};

export type DropDownMember = {
  __typename?: 'DropDownMember';
  ValueSource: Scalars['String'];
  DynamicSource?: Maybe<DynamicSource>;
  StaticData?: Maybe<Array<StaticData>>;
};

export type DropDownMemberInput = {
  ValueSource: Scalars['String'];
  DynamicSource?: Maybe<DynamicSourceInput>;
  StaticData?: Maybe<Array<StaticDataInput>>;
};

export type DropDownParamterInterface = {
  __typename?: 'DropDownParamterInterface';
  Name: Scalars['String'];
  ParameterName: Scalars['String'];
};

export type DropDownParamterInterfaceInput = {
  Name: Scalars['String'];
  ParameterName: Scalars['String'];
};

export enum DurationType {
  Year = 'YEAR',
  Month = 'MONTH'
}

export type DynamicSource = {
  __typename?: 'DynamicSource';
  DisplayMember: Scalars['String'];
  ValueMember: Scalars['String'];
  DataSource: DropDownDataSource;
};

export type DynamicSourceInput = {
  DisplayMember: Scalars['String'];
  ValueMember: Scalars['String'];
  DataSource: DropDownDataSourceInput;
};

export enum EInvoiceTrxType {
  Invoice = 'INVOICE',
  CreditNote = 'CREDIT_NOTE',
  DebitNote = 'DEBIT_NOTE',
  All = 'ALL'
}

export enum EInvoiceType {
  Individual = 'INDIVIDUAL',
  Company = 'COMPANY',
  Foreigner = 'FOREIGNER',
  Government = 'GOVERNMENT'
}

export type EntityCoaDeleteInput = {
  EntityCOAID: Scalars['String'];
};

export type EntityCoaEntity = AuditEntity & {
  __typename?: 'EntityCOAEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  EntityCOAID: Scalars['String'];
  CompanyID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  MasterCOA?: Maybe<MasterCoaEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  IsUsed?: Maybe<Scalars['Boolean']>;
};

export type EntityCoaInput = {
  EntityCOAID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  MasterCOAID: Scalars['String'];
};

export type EntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  userID: Scalars['String'];
  roleID: Scalars['String'];
  companyID: Array<Scalars['String']>;
};

export type ExcelJournalInput = {
  Obj?: Maybe<Scalars['String']>;
};

export type ExchangeLog = {
  __typename?: 'ExchangeLog';
  exchangeRate?: Maybe<Scalars['Float']>;
  modTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['DateTime']>;
};

export type ExchangeLogInput = {
  exchangeRate?: Maybe<Scalars['Float']>;
  modTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['DateTime']>;
};

export type ExpenseClassDeleteInput = {
  ExpenseClassID: Scalars['String'];
};

export type ExpenseClassEntity = AuditEntity & {
  __typename?: 'ExpenseClassEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ExpenseClassID: Scalars['String'];
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  Name: Scalars['String'];
  ParentExpenseClassID?: Maybe<Scalars['String']>;
  Level: Scalars['Float'];
  IsLastNode: Scalars['Boolean'];
  ExpenseItem?: Maybe<Array<ExpenseItemEntity>>;
  ItemClassCompanyAssignment?: Maybe<Array<ItemClassCompanyAssignmentEntity>>;
  /** CustomFieldResolver */
  ParentClassMCOA?: Maybe<Scalars['JSON']>;
};

export type ExpenseClassInput = {
  ExpenseClassID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  ParentExpenseClassID?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  IsLastNode?: Maybe<Scalars['Boolean']>;
  MasterCOAID?: Maybe<Scalars['String']>;
};

export type ExpenseItemDeleteInput = {
  ExpenseItemID: Scalars['String'];
};

export type ExpenseItemEntity = AuditEntity & {
  __typename?: 'ExpenseItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ExpenseItemID: Scalars['String'];
  ItemName: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  TaxRate?: Maybe<Scalars['Float']>;
  ExpenseClassID: Scalars['String'];
  ExpenseClass?: Maybe<ExpenseClassEntity>;
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  APDebitNoteItem?: Maybe<Array<AccApDebitNoteItemEntity>>;
  ARDebitNoteItem?: Maybe<Array<AccArDebitNoteItemEntity>>;
  APCreditNoteItem?: Maybe<Array<AccApCreditNoteItemEntity>>;
  APInvoiceItem?: Maybe<Array<AccApInvoiceItemEntity>>;
  APFavoriteInvoiceItem?: Maybe<Array<AccApFavoriteInvoiceItemEntity>>;
  APDebitNote?: Maybe<Array<AccApDebitNoteEntity>>;
  APCreditorDebitNoteItem?: Maybe<Array<AccApCreditorDebitNoteItemEntity>>;
  APSelfBilledItem?: Maybe<Array<AccApSelfBilledItemEntity>>;
  APCreditNote?: Maybe<Array<AccApCreditNoteEntity>>;
  APCreditorDebitNote?: Maybe<Array<AccApCreditorDebitNoteEntity>>;
  /** CustomFieldResolver */
  IsUse?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  IsInventory?: Maybe<Scalars['Boolean']>;
};

export type ExpenseItemInput = {
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseClassID?: Maybe<Scalars['String']>;
  ItemName: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  RecordStatus?: Maybe<RecordStatus>;
};

export type ExternalJournalDeleteInput = {
  ExternalJournalID: Scalars['String'];
};

export type ExternalJournalEntity = AuditEntity & {
  __typename?: 'ExternalJournalEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  ExternalJournalID: Scalars['String'];
  CompanyID: Scalars['String'];
  JournalDate: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  JournalTypeID: Scalars['String'];
  JournalType?: Maybe<JournalTypeEntity>;
  FYear: Scalars['Float'];
  FPeriod: Scalars['Float'];
  IsManual: Scalars['Boolean'];
  RefNo?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  AccountName?: Maybe<Scalars['String']>;
  RefRecordID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  DocRefName: Scalars['String'];
  ExternalJournalItem?: Maybe<Array<ExternalJournalItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  ItemCount?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ItemAmount?: Maybe<Scalars['Float']>;
};

export type ExternalJournalImportInput = {
  JournalDate?: Maybe<Scalars['DateTime']>;
  JournalType?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  JournalDescription?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  MasterCOACode?: Maybe<Scalars['String']>;
  DepartmentCode?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  RowNo?: Maybe<Scalars['Float']>;
};

export type ExternalJournalInput = {
  JournalID?: Maybe<Scalars['String']>;
  JournalDate?: Maybe<Scalars['String']>;
  JournalTypeID?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  MasterCOACode?: Maybe<Scalars['String']>;
  CostCentreCode?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
};

export type ExternalJournalItemEntity = AuditEntity & {
  __typename?: 'ExternalJournalItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ExternalJournalItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  ExternalJournalID: Scalars['String'];
  ExternalJournal?: Maybe<ExternalJournalEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  MasterCOAID: Scalars['String'];
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxInvoiceNo?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  Remark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type ExternalJournalType = {
  __typename?: 'ExternalJournalType';
  Data?: Maybe<Array<ExternalJournalEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type ExternalLedgerDeleteInput = {
  ExternalLedgerID: Scalars['String'];
};

export type ExternalLedgerEntity = AuditEntity & {
  __typename?: 'ExternalLedgerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  CurrencyID?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  LocalDocAmt?: Maybe<Scalars['Float']>;
  LocalTaxAmt?: Maybe<Scalars['Float']>;
  BankAccountID: Scalars['String'];
  ExternalLedgerID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  CompanyID: Scalars['String'];
  TrxType?: Maybe<Scalars['String']>;
  BaseAmt?: Maybe<Scalars['Float']>;
  RefTable: Scalars['String'];
  TaxInvoiceNo?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  ReceivedFrom?: Maybe<Scalars['String']>;
  IsManual: Scalars['Boolean'];
  IsMatch: Scalars['Boolean'];
  LedgerType?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  Currency?: Maybe<CurrencyEntity>;
};

export type ExternalLedgerImportInput = {
  BankCode?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  LedgerType?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  BaseAmt?: Maybe<Scalars['Float']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  PayeeReceiverName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RowNo?: Maybe<Scalars['Float']>;
};

export type ExternalLedgerInput = {
  ExternalLedgerID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  BankAccountID: Scalars['String'];
  DocNo: Scalars['String'];
  DocDate: Scalars['String'];
  TrxType: Scalars['String'];
  RefNo: Scalars['String'];
  CurrencyID: Scalars['String'];
  ExchangeRate: Scalars['Float'];
  BaseAmt: Scalars['Float'];
  DocAmt: Scalars['Float'];
  Description: Scalars['String'];
  RefRecordID: Scalars['String'];
  TransactionDate: Scalars['String'];
  RefTable: Scalars['String'];
  PayeeName: Scalars['String'];
  ReceivedFrom: Scalars['String'];
};

export type FavoriteApproverDeleteInput = {
  FavoriteApproverID: Scalars['String'];
};

export type FavoriteApproverEntity = AuditEntity & {
  __typename?: 'FavoriteApproverEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  FavoriteApproverID: Scalars['String'];
  CompanyID: Scalars['String'];
  UserID: Scalars['String'];
  RefTable?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  ApprovalList?: Maybe<Array<Scalars['JSON']>>;
};

export type FavoriteApproverInput = {
  FavoriteApproverID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  ApprovalList?: Maybe<Array<FavoriteApproverListJsonInput>>;
};

export type FavoriteApproverListJson = {
  __typename?: 'FavoriteApproverListJson';
  RoleID: Scalars['String'];
  UserID: Scalars['String'];
};

export type FavoriteApproverListJsonInput = {
  RoleID: Scalars['String'];
  UserID: Scalars['String'];
};

export type FavoriteJournalProcessingDeleteInput = {
  FavoriteJournalProcessingID: Scalars['String'];
};

export type FavoriteJournalProcessingEntity = AuditEntity & {
  __typename?: 'FavoriteJournalProcessingEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  FavoriteJournalProcessingID: Scalars['String'];
  UserID: Scalars['String'];
  JournalTypeID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  FavoriteJournalProcessingItem?: Maybe<Array<FavoriteJournalProcessingItemEntity>>;
  JournalType?: Maybe<JournalTypeEntity>;
};

export type FavoriteJournalProcessingInput = {
  FavoriteJournalProcessingID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  JournalTypeID?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type FavoriteJournalProcessingItemDeleteInput = {
  FavoriteJournalProcessingItemID: Scalars['String'];
};

export type FavoriteJournalProcessingItemEntity = AuditEntity & {
  __typename?: 'FavoriteJournalProcessingItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  FavoriteJournalProcessingItemID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  FavoriteJournalProcessingID: Scalars['String'];
  DocAmt?: Maybe<Scalars['Float']>;
  CostCentreID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  FavoriteJournalProcessing?: Maybe<FavoriteJournalProcessingEntity>;
  CostCentre?: Maybe<CostCentreEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
};

export type FavoriteJournalProcessingItemInput = {
  FavoriteJournalProcessingItemID?: Maybe<Scalars['String']>;
  FavoriteJournalProcessingID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Sequence?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
};

export type FavoritePrDeleteInput = {
  FavoritePRHeaderID: Scalars['String'];
};

export type FavoritePrEntity = AuditEntity & {
  __typename?: 'FavoritePREntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  FavoritePRHeaderID: Scalars['String'];
  UserID: Scalars['String'];
  CompanyID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  RequestedBy?: Maybe<Scalars['String']>;
  ContactPerson?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Instruction?: Maybe<Scalars['String']>;
  CancelCloseDate?: Maybe<Scalars['String']>;
  CancelCloseRemark?: Maybe<Scalars['String']>;
  FavoritePRItem?: Maybe<Array<FavoritePrItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  DeliveryLocation?: Maybe<DeliveryLocationEntity>;
};

export type FavoritePrInput = {
  FavoritePRHeaderID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  RequestedBy?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  ContactPerson?: Maybe<Scalars['String']>;
  Instruction?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type FavoritePrItemDeleteInput = {
  FavoritePRItemID: Scalars['String'];
};

export type FavoritePrItemEntity = AuditEntity & {
  __typename?: 'FavoritePRItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  FavoritePRItemID: Scalars['String'];
  FavoritePRHeaderID: Scalars['String'];
  ItemID: Scalars['String'];
  CostCentreID: Scalars['String'];
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  UOMID: Scalars['String'];
  Quantity: Scalars['Float'];
  OutstandingQty?: Maybe<Scalars['Float']>;
  POItemPricingID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  IsPOGenerated: Scalars['Boolean'];
  FavoritePRHeader?: Maybe<FavoritePrEntity>;
  Item?: Maybe<ItemEntity>;
  POItemPricing?: Maybe<PoItemPricingEntity>;
  RFQItem?: Maybe<Array<RfqItemEntity>>;
  POItem?: Maybe<Array<PoItemEntity>>;
  /** CustomFieldResolver */
  CostCentre?: Maybe<CostCentreEntity>;
  /** CustomFieldResolver */
  UOM?: Maybe<AccUomEntity>;
  /** CustomFieldResolver */
  DeliveryLocation?: Maybe<DeliveryLocationEntity>;
  /** CustomFieldResolver */
  Warehouse?: Maybe<WarehouseEntity>;
};

export type FavoritePrItemInput = {
  FavoritePRItemID?: Maybe<Scalars['String']>;
  FavoritePRHeaderID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  CostCentreID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type FavoritePaymentDeleteInput = {
  FavoritePaymentID: Scalars['String'];
};

export type FavoritePaymentEntity = AuditEntity & {
  __typename?: 'FavoritePaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  FavoritePaymentID: Scalars['String'];
  UserID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  PaymentMethodID?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  ChequeNo?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  FavoritePaymentItem?: Maybe<Array<FavoritePaymentItemEntity>>;
};

export type FavoritePaymentInput = {
  FavoritePaymentID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  PaymentMethodID?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  ChequeNo?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  TaxInvoiceNo?: Maybe<Scalars['String']>;
};

export type FavoritePaymentItemDeleteInput = {
  FavoritePaymentItemID: Scalars['String'];
};

export type FavoritePaymentItemEntity = AuditEntity & {
  __typename?: 'FavoritePaymentItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark: Scalars['String'];
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  MasterCOAID: Scalars['String'];
  FavoritePaymentItemID: Scalars['String'];
  FavoritePaymentID: Scalars['String'];
  FavoritePayment?: Maybe<FavoritePaymentEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  DocDate?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
};

export type FavoritePaymentItemInput = {
  FavoritePaymentItemID?: Maybe<Scalars['String']>;
  FavoritePaymentID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocNo?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type FavoriteReceiptDeleteInput = {
  FavoriteReceiptID: Scalars['String'];
};

export type FavoriteReceiptEntity = AuditEntity & {
  __typename?: 'FavoriteReceiptEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  FavoriteReceiptID: Scalars['String'];
  UserID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ReceivedFrom?: Maybe<Scalars['String']>;
  PaymentMethodID?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  ChequeNo?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  FavoriteReceiptItem?: Maybe<Array<FavoriteReceiptItemEntity>>;
};

export type FavoriteReceiptInput = {
  FavoriteReceiptID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  ReceiptID?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  PaymentMethodID?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  ChequeNo?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  TaxInvoiceNo?: Maybe<Scalars['String']>;
  ReceivedFrom?: Maybe<Scalars['String']>;
};

export type FavoriteReceiptItemDeleteInput = {
  FavoriteReceiptItemID: Scalars['String'];
};

export type FavoriteReceiptItemEntity = AuditEntity & {
  __typename?: 'FavoriteReceiptItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark: Scalars['String'];
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  MasterCOAID: Scalars['String'];
  FavoriteReceiptItemID: Scalars['String'];
  FavoriteReceiptID: Scalars['String'];
  FavoriteReceipt?: Maybe<FavoriteReceiptEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
};

export type FavoriteReceiptItemInput = {
  FavoriteReceiptItemID?: Maybe<Scalars['String']>;
  FavoriteReceiptID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Sequence?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
};

export type FavoriteStockRequisitionDeleteInput = {
  FavoriteStockRequisitionID: Scalars['String'];
};

export type FavoriteStockRequisitionEntity = AuditEntity & {
  __typename?: 'FavoriteStockRequisitionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  FavoriteStockRequisitionID: Scalars['String'];
  UserID: Scalars['String'];
  CompanyID: Scalars['String'];
  WarehouseID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  RequestDate: Scalars['String'];
  ExpectedDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  FavoriteStockRequisitionItem?: Maybe<Array<FavoriteStockRequisitionItemEntity>>;
};

export type FavoriteStockRequisitionInput = {
  FavoriteStockRequisitionID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
};

export type FavoriteStockRequisitionItemDeleteInput = {
  FavoriteStockRequisitionItemID: Scalars['String'];
};

export type FavoriteStockRequisitionItemEntity = AuditEntity & {
  __typename?: 'FavoriteStockRequisitionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  FavoriteStockRequisitionItemID: Scalars['String'];
  FavoriteStockRequisitionID: Scalars['String'];
  StockItemID: Scalars['String'];
  UOMID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  FavoriteStockRequisition?: Maybe<FavoriteStockRequisitionEntity>;
  StockItem?: Maybe<StockItemEntity>;
  UOM?: Maybe<AccUomEntity>;
  TransactionType?: Maybe<TransactionTypeEntity>;
};

export type FavoriteStockRequisitionItemInput = {
  FavoriteStockRequisitionItemID?: Maybe<Scalars['String']>;
  FavoriteStockRequisitionID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  CostCentreID?: Maybe<Scalars['String']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export enum FileType {
  Image = 'IMAGE',
  Logo = 'LOGO',
  Pdf = 'PDF'
}

export type ForexInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  DocRef?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  DocDueDate?: Maybe<Scalars['String']>;
  CancellationDate?: Maybe<Scalars['String']>;
  CancellationRemark?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  BaseAmt?: Maybe<Scalars['Float']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  LocalBaseAmt?: Maybe<Scalars['Float']>;
  BankDocAmt?: Maybe<Scalars['Float']>;
  LocalTaxAmt?: Maybe<Scalars['Float']>;
  CurrencyID?: Maybe<Scalars['String']>;
};

export type GdoActionInput = {
  DOHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  CancelCloseDate?: Maybe<Scalars['String']>;
  CancelCloseRemark?: Maybe<Scalars['String']>;
  ApprovalStatus: ApprovalStatus;
};

export type GlExportInput = {
  AccountCode?: Maybe<Scalars['String']>;
  GLLedgerID?: Maybe<Scalars['String']>;
  GLLedgerTable?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TrxDesc?: Maybe<Scalars['String']>;
  Credit?: Maybe<Scalars['Float']>;
  Debit?: Maybe<Scalars['Float']>;
};

export type GlImportLedgerInput = {
  AccountID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  JournalTypeName?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  JournalDate?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  MasterCOACode?: Maybe<Scalars['String']>;
  DepartmentCode?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
};

export type GlJournalItem = AuditEntity & {
  __typename?: 'GLJournalItem';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  JournalItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  JournalID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  CostCentreID?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  Remark?: Maybe<Scalars['String']>;
  TaxInvoiceNo?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  Journal?: Maybe<JournalEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  AccountCode?: Maybe<Scalars['String']>;
  JournalNo?: Maybe<Scalars['String']>;
  JournalDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  CostCentreCode?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  FPeriod?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  DebitAmount?: Maybe<Scalars['String']>;
  CreditAmount?: Maybe<Scalars['String']>;
};

export type Glmfrs16AccountMappingEntity = {
  __typename?: 'GLMFRS16AccountMappingEntity';
  AccountMappingID: Scalars['String'];
  AccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  ROUMasterCOAID: Scalars['String'];
  LeaseLiabilityMasterCOAID: Scalars['String'];
  RecurringROUMasterCOAID?: Maybe<Scalars['String']>;
  DepreciationROUMasterCOAID: Scalars['String'];
  ProvisionDROUMasterCOAID: Scalars['String'];
  InterestExpenseMasterCOAID: Scalars['String'];
  LeaseLiabilitiesMasterCOAID: Scalars['String'];
  AccrualLeaseLiabilitiesMasterCOAID: Scalars['String'];
  GLTerminationMasterCOAID: Scalars['String'];
  CapitalisationROUJournalTypeID: Scalars['String'];
  ROUJournalTypeID: Scalars['String'];
  LeaseLiabilitiesJournalTypeID: Scalars['String'];
};

export type Glmfrs16LeaseContractEntity = {
  __typename?: 'GLMFRS16LeaseContractEntity';
  LeaseContractID: Scalars['String'];
  AccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  ContractNo: Scalars['String'];
  Description: Scalars['String'];
  StartDate: Scalars['String'];
  EndDate: Scalars['String'];
  StartJournalDate: Scalars['String'];
  LeasePayment: Scalars['Float'];
  LeaseMonth: Scalars['Float'];
  TotalLeaseAmount: Scalars['Float'];
  AnnualDiscountRate: Scalars['Float'];
  DiscountedTotalCashFlows: Scalars['Float'];
  DiscountedTotalCashFlowsValues: Scalars['Float'];
  IsAccountROU: Scalars['Boolean'];
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  SubmittedBy: Scalars['String'];
  SubmittedDate: Scalars['String'];
  ApprovedBy: Scalars['String'];
  ApprovedDate: Scalars['String'];
  RejectedBy: Scalars['String'];
  RejectedDate: Scalars['String'];
  RejectedRemark: Scalars['String'];
  TerminatedBy: Scalars['String'];
  TerminatedDate: Scalars['String'];
  TerminateDescription: Scalars['String'];
};

export type Glmfrs16LeasePeriodEntity = {
  __typename?: 'GLMFRS16LeasePeriodEntity';
  LeasePeriodID: Scalars['String'];
  AccountID: Scalars['String'];
  LeaseContractID: Scalars['String'];
  LeaseYear: Scalars['Float'];
  LeasePeriod: Scalars['Float'];
  LeasePayment: Scalars['Float'];
  Principal: Scalars['Float'];
  Interest: Scalars['Float'];
  LeaseLiabilityCF: Scalars['Float'];
  LeaseLiabilityBFValue: Scalars['Float'];
  LeasePaymentValue: Scalars['Float'];
  PrincipalValue: Scalars['Float'];
  InterestValue: Scalars['Float'];
  LeaseLiabilityCFValue: Scalars['Float'];
  GLPostStatus: GlPostStatus;
  GeneratedBy: Scalars['String'];
  GeneratedDate: Scalars['String'];
  ApprovedBy: Scalars['String'];
  ApprovedDate: Scalars['String'];
};

export enum GlPostStatus {
  Pending = 'PENDING',
  Generated = 'GENERATED',
  Approved = 'APPROVED'
}

export enum GpApprovalStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Completed = 'COMPLETED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Retention = 'RETENTION',
  PendingClose = 'PENDING_CLOSE',
  PendingCancel = 'PENDING_CANCEL',
  SubmitCancel = 'SUBMIT_CANCEL',
  ApprovedCancel = 'APPROVED_CANCEL',
  RejectedCancel = 'REJECTED_CANCEL',
  CompletedCancel = 'COMPLETED_CANCEL',
  Closed = 'CLOSED',
  New = 'NEW',
  Packed = 'PACKED'
}

export type GprActionInput = {
  PRHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  CancelCloseDate?: Maybe<Scalars['String']>;
  CancelCloseRemark?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  File?: Maybe<Array<Scalars['Upload']>>;
};

export type GrtnDeleteInput = {
  ID: Scalars['String'];
  File?: Maybe<Array<Scalars['Upload']>>;
};

export type GrtnHeaderEntity = AuditEntity & {
  __typename?: 'GRTNHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  GRTNHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  SupplierID: Scalars['String'];
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  GRTNHeaderItem?: Maybe<Array<GrtnItemEntity>>;
  /** CustomFieldResolver */
  Company: CompanyEntity;
  Supplier?: Maybe<CreditorAccountEntity>;
  /** CustomFieldResolver */
  DeliveryLocation?: Maybe<DeliveryLocationEntity>;
  /** CustomFieldResolver */
  Warehouse?: Maybe<WarehouseEntity>;
};

export type GrtnItemEntity = AuditEntity & {
  __typename?: 'GRTNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  GRTNItemID: Scalars['String'];
  GRTNHeaderID: Scalars['String'];
  GRTNHeader?: Maybe<GrtnHeaderEntity>;
  DOItemID: Scalars['String'];
  DOItem?: Maybe<DoItemEntity>;
  OutstandingQty?: Maybe<Scalars['Float']>;
  ReturnedQty?: Maybe<Scalars['Float']>;
  ReturnedAmt?: Maybe<Scalars['Float']>;
  Replacement: Scalars['Boolean'];
  ReturnedDoItem?: Maybe<Array<DoItemEntity>>;
};

export type GeneralGrtnInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  SupplierID: Scalars['String'];
  DeliveryLocationID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  WarehouseID?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  TransactionDate?: Maybe<Scalars['String']>;
  RefNo: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type GeneralGrtnItemDeleteInput = {
  ID: Scalars['String'];
};

export type GeneralGrtnItemInput = {
  GRTNHeaderID?: Maybe<Scalars['String']>;
  DoItemID: Scalars['String'];
  ReturnedQty: Scalars['Float'];
  ReturnedAmt: Scalars['Float'];
  Replacement: Scalars['Boolean'];
};

export type GeneralGrtnItemInputGrn = {
  DOItemID: Scalars['String'];
  DOItemInput: Array<DoItemInput>;
};

export type GeneralGrtnRejectInput = {
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type GeneralPurchasePolicyEntity = {
  __typename?: 'GeneralPurchasePolicyEntity';
  GeneralPurchasePolicyID: Scalars['String'];
  AccountID: Scalars['String'];
  VariancePerc?: Maybe<Scalars['Float']>;
  RFQRequired: RfqRequired;
  MasterCOAID: Scalars['String'];
  MasterCOA?: Maybe<MasterCoaEntity>;
  RecordStatus: RecordStatus;
  PRConfidentiality: PrConfidentiality;
};

export type GeneralPurchasePolicyInput = {
  GeneralPurchasePolicyID?: Maybe<Scalars['String']>;
  VariancePerc?: Maybe<Scalars['Float']>;
  RFQRequired?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
};

export type GeneralScheduleItemInput = {
  ScheduledPurchasesID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  TotalAmt?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
};

export type HomeCalendarOutput = {
  __typename?: 'HomeCalendarOutput';
  description?: Maybe<Scalars['String']>;
  voEndDate?: Maybe<Scalars['DateTime']>;
  publicHolidayOffDayDate?: Maybe<Scalars['DateTime']>;
};

export type HuaweiStorageOutput = {
  __typename?: 'HuaweiStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type IcItemInput = {
  Name?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  StockItemID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  Uom?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  reasonName?: Maybe<Scalars['String']>;
  StockIssueItemID?: Maybe<Scalars['String']>;
  StockTransferItemID?: Maybe<Scalars['String']>;
  StockAdjustmentItemID?: Maybe<Scalars['String']>;
  StockRequisitionItemID?: Maybe<Scalars['String']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export enum IdentityType {
  IdentityCard = 'IDENTITY_CARD',
  MilitaryCard = 'MILITARY_CARD',
  Passport = 'PASSPORT',
  BusinessLicense = 'BUSINESS_LICENSE'
}

/** JsonType */
export type Input = {
  __typename?: 'Input';
  EntityCOAID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};

export type InventoryPolicyDeleteInput = {
  InventoryPolicyID: Scalars['String'];
};

export type InventoryPolicyEntity = AuditEntity & {
  __typename?: 'InventoryPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  InventoryPolicyID: Scalars['String'];
  PaymentReminder1: Scalars['Float'];
  PaymentReminder2: Scalars['Float'];
  PaymentReminder3: Scalars['Float'];
  PaymentReminder4: Scalars['Float'];
  PaymentReminder5: Scalars['Float'];
  PaymentReminder6: Scalars['Float'];
  StockCostingType: StockCostingType;
  CompanyID: Scalars['String'];
  /** CustomFieldResolver */
  FromCurrency?: Maybe<CompanyEntity>;
};

export type InventoryPolicyInput = {
  InventoryPolicyID?: Maybe<Scalars['String']>;
  StockCostingType?: Maybe<Scalars['String']>;
  PaymentReminder1?: Maybe<Scalars['Float']>;
  PaymentReminder2?: Maybe<Scalars['Float']>;
  PaymentReminder3?: Maybe<Scalars['Float']>;
  PaymentReminder4?: Maybe<Scalars['Float']>;
  PaymentReminder5?: Maybe<Scalars['Float']>;
  PaymentReminder6?: Maybe<Scalars['Float']>;
};

export type ItemCategoryCompanyAssignmentDeleteInput = {
  ItemCategoryCompanyAssignmentID: Scalars['String'];
};

export type ItemCategoryCompanyAssignmentEntity = AuditEntity & {
  __typename?: 'ItemCategoryCompanyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ItemCategoryCompanyAssignmentID: Scalars['String'];
  ItemCategoryID: Scalars['String'];
  CompanyID: Scalars['String'];
  ItemCategory?: Maybe<ItemCategoryEntity>;
};

export type ItemCategoryCompanyAssignmentInput = {
  ItemCategoryCompanyAssignmentID?: Maybe<Scalars['String']>;
  ItemCategoryID: Scalars['String'];
  companyID: Array<Scalars['String']>;
};

export type ItemCategoryDeleteInput = {
  ID: Scalars['String'];
};

export type ItemCategoryEntity = AuditEntity & {
  __typename?: 'ItemCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ItemCategoryID: Scalars['String'];
  Name: Scalars['String'];
  MasterCOAID?: Maybe<Scalars['String']>;
  ParentItemCategoryID?: Maybe<Scalars['String']>;
  Level: Scalars['Float'];
  IsLastNode: Scalars['Boolean'];
  Item?: Maybe<Array<ItemEntity>>;
  ItemCategoryCompanyAssignment?: Maybe<Array<ItemCategoryCompanyAssignmentEntity>>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  /** CustomFieldResolver */
  ParentCategoryMCOA?: Maybe<Scalars['JSON']>;
};

export type ItemCategoryInput = {
  ItemCategoryID?: Maybe<Scalars['String']>;
  ParentItemCategoryID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  MasterCOAID?: Maybe<Scalars['String']>;
  Level: Scalars['Float'];
  IsLastNode: Scalars['Boolean'];
};

export type ItemClassCompanyAssignmentDeleteInput = {
  ItemClassCompanyAssignmentID: Scalars['String'];
};

export type ItemClassCompanyAssignmentEntity = AuditEntity & {
  __typename?: 'ItemClassCompanyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ItemClassCompanyAssignmentID: Scalars['String'];
  ExpenseClassID: Scalars['String'];
  CompanyID: Scalars['String'];
  ExpenseClass?: Maybe<ExpenseClassEntity>;
};

export type ItemClassCompanyAssignmentInput = {
  ItemClassCompanyAssignmentID?: Maybe<Scalars['String']>;
  ExpenseClassID: Scalars['String'];
  companyID: Array<Scalars['String']>;
};

export type ItemEntity = AuditEntity & {
  __typename?: 'ItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ItemID: Scalars['String'];
  ItemCategoryID: Scalars['String'];
  ItemCategory?: Maybe<ItemCategoryEntity>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  UOMID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  DefaultSupplierID?: Maybe<Scalars['String']>;
  MarketPrice: Scalars['Float'];
  IsCentralised: Scalars['Boolean'];
  IsInventory: Scalars['Boolean'];
  VariancePerc?: Maybe<Scalars['Float']>;
  LatestPurchasePrice?: Maybe<Scalars['Float']>;
  LatestUOMID?: Maybe<Scalars['String']>;
  BulkPurchasePriceItem?: Maybe<Array<BulkPurchasePriceItemEntity>>;
  PRItem?: Maybe<Array<PrItemEntity>>;
  FavoritePRItem?: Maybe<Array<FavoritePrItemEntity>>;
  POItem?: Maybe<Array<PoItemEntity>>;
  ScheduledPurchasesItem?: Maybe<Array<ScheduledPurchasesItemEntity>>;
  ParentItem: Array<Scalars['JSON']>;
  UOM?: Maybe<AccUomEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  DefaultSupplier?: Maybe<CreditorAccountEntity>;
  /** CustomFieldResolver */
  LatestPurchase?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  ExpenseItemDetail?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  IsUse?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  UsedInIC?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  LatestUOMCode?: Maybe<Scalars['JSON']>;
};

export type ItemExcelInput = {
  ID?: Maybe<Scalars['String']>;
  ParentName?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  UOM?: Maybe<Scalars['String']>;
  Tax?: Maybe<Scalars['String']>;
  RowNo?: Maybe<Scalars['Float']>;
};

export type ItemInput = {
  ItemID?: Maybe<Scalars['String']>;
  ItemCategoryID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  DefaultSupplierID?: Maybe<Scalars['String']>;
  IsCentralised?: Maybe<Scalars['Boolean']>;
  IsInventory?: Maybe<Scalars['Boolean']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ParentName?: Maybe<Scalars['String']>;
  VariancePerc?: Maybe<Scalars['Float']>;
  MarketPrice?: Maybe<Scalars['Float']>;
  RecordStatus?: Maybe<RecordStatus>;
};

export type ItemObject = {
  __typename?: 'ItemObject';
  ItemID: Scalars['String'];
  ParentItemID?: Maybe<Scalars['String']>;
};


export type JournalDeleteInput = {
  JournalID: Scalars['String'];
};

export type JournalEntity = AuditEntity & {
  __typename?: 'JournalEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  JournalID: Scalars['String'];
  CompanyID: Scalars['String'];
  JournalDate?: Maybe<Scalars['String']>;
  JournalTypeID: Scalars['String'];
  JournalType?: Maybe<JournalTypeEntity>;
  JournalNo?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  IsManual: Scalars['Boolean'];
  FYear: Scalars['Float'];
  FPeriod: Scalars['Float'];
  RefRecordID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  JournalItem?: Maybe<Array<JournalItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type JournalInput = {
  JournalID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  JournalDate: Scalars['String'];
  FYear: Scalars['Float'];
  FPeriod: Scalars['Float'];
  JournalTypeID: Scalars['String'];
  JournalNo?: Maybe<Scalars['String']>;
  IsManual?: Maybe<Scalars['Boolean']>;
  RefNo: Scalars['String'];
  Description: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  Status?: Maybe<JournalStatus>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type JournalItemDeleteInput = {
  JournalItemID: Scalars['String'];
};

export type JournalItemEntity = AuditEntity & {
  __typename?: 'JournalItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  JournalItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  JournalID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  CostCentreID?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  Remark?: Maybe<Scalars['String']>;
  TaxInvoiceNo?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  Journal?: Maybe<JournalEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type JournalItemInput = {
  JournalItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JournalID?: Maybe<Scalars['String']>;
  MasterCOAID: Scalars['String'];
  CostCentreID: Scalars['String'];
  DocAmt: Scalars['Float'];
};

export type JournalProcessingDeleteInput = {
  JournalProcessingID: Scalars['String'];
};

export type JournalProcessingEntity = AuditEntity & {
  __typename?: 'JournalProcessingEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  JournalProcessingID: Scalars['String'];
  CompanyID: Scalars['String'];
  JournalTypeID: Scalars['String'];
  FYear: Scalars['Float'];
  FPeriod: Scalars['Float'];
  JournalID?: Maybe<Scalars['String']>;
  JournalProcessingItem?: Maybe<Array<JournalProcessingItemEntity>>;
  JournalType?: Maybe<JournalTypeEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
};

export type JournalProcessingInput = {
  JournalProcessingID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DocDate: Scalars['String'];
  FYear: Scalars['Float'];
  FPeriod: Scalars['Float'];
  JournalTypeID: Scalars['String'];
  RefNo?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  Status?: Maybe<JournalStatus>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type JournalProcessingItemDeleteInput = {
  JournalProcessingItemID: Scalars['String'];
};

export type JournalProcessingItemEntity = AuditEntity & {
  __typename?: 'JournalProcessingItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  JournalProcessingItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  JournalProcessingID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  CostCentreID: Scalars['String'];
  DocAmt: Scalars['Float'];
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  CostCentre?: Maybe<CostCentreEntity>;
  JournalProcessing?: Maybe<JournalProcessingEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type JournalProcessingItemInput = {
  JournalProcessingItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JournalProcessingID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['DateTime']>;
  modTs?: Maybe<Scalars['DateTime']>;
  MasterCOAID: Scalars['String'];
  CostCentreID: Scalars['String'];
  DocAmt: Scalars['Float'];
  Sequence?: Maybe<Scalars['Float']>;
};

export type JournalProcessingRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type JournalProcessingReturnData = {
  __typename?: 'JournalProcessingReturnData';
  Data?: Maybe<Array<JournalProcessingEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

/** JsonType */
export type JournalReturnData = {
  __typename?: 'JournalReturnData';
  Data?: Maybe<Array<JournalEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export enum JournalStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED'
}

export type JournalTypeDeleteInput = {
  JournalTypeID: Scalars['String'];
};

export type JournalTypeEntity = AuditEntity & {
  __typename?: 'JournalTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  JournalTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
  JournalType: Scalars['String'];
  Description: Scalars['String'];
  IsSystem: Scalars['Boolean'];
  Journal?: Maybe<Array<JournalEntity>>;
  RoutineJournal?: Maybe<Array<RoutineJournalEntity>>;
  RecurringJournal?: Maybe<Array<RecurringJournalEntity>>;
  RecurringJournalGeneration?: Maybe<Array<RecurringJournalGenerationEntity>>;
  FavoriteJournalProcessing?: Maybe<Array<FavoriteJournalProcessingEntity>>;
  JournalProcessing?: Maybe<Array<JournalProcessingEntity>>;
  ExternalExternalJournal?: Maybe<Array<ExternalJournalEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type JournalTypeInput = {
  JournalTypeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  JournalType: Scalars['String'];
  Description: Scalars['String'];
  IsSystem?: Maybe<Scalars['Boolean']>;
};

export type LedgerDeleteInput = {
  LedgerID: Scalars['String'];
};

export type LedgerEntity = AuditEntity & {
  __typename?: 'LedgerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  CurrencyID?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  LocalDocAmt?: Maybe<Scalars['Float']>;
  LocalTaxAmt?: Maybe<Scalars['Float']>;
  BankAccountID: Scalars['String'];
  LedgerID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  CompanyID: Scalars['String'];
  BankReconciliationMatchID?: Maybe<Scalars['String']>;
  BankReconciliationMatch?: Maybe<BankReconciliationMatchEntity>;
  TrxType?: Maybe<Scalars['String']>;
  BaseAmt?: Maybe<Scalars['Float']>;
  RefTable: Scalars['String'];
  RefRecordID: Scalars['String'];
  TaxInvoiceNo?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  ReceivedFrom?: Maybe<Scalars['String']>;
  IsManual: Scalars['Boolean'];
  IsMatch: Scalars['Boolean'];
  LedgerType?: Maybe<Scalars['String']>;
  LedgerItem?: Maybe<Array<LedgerItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  Currency?: Maybe<CurrencyEntity>;
};

export type LedgerInput = {
  LedgerID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  BankAccountID: Scalars['String'];
  DocNo: Scalars['String'];
  DocDate: Scalars['String'];
  TrxType: Scalars['String'];
  RefNo: Scalars['String'];
  CurrencyID: Scalars['String'];
  ExchangeRate: Scalars['Float'];
  BaseAmt: Scalars['Float'];
  DocAmt: Scalars['Float'];
  Description: Scalars['String'];
  RefRecordID: Scalars['String'];
  TransactionDate: Scalars['String'];
  RefTable: Scalars['String'];
  PayeeName: Scalars['String'];
  ReceivedFrom: Scalars['String'];
};

export type LedgerItemDeleteInput = {
  LedgerItemID: Scalars['String'];
};

export type LedgerItemEntity = AuditEntity & {
  __typename?: 'LedgerItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  MasterCOAID: Scalars['String'];
  LedgerItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  LedgerID: Scalars['String'];
  Ledger?: Maybe<LedgerEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  TaxInvoiceNo?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type LedgerItemInput = {
  LedgerItemID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  JournalID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  CostCentreID: Scalars['String'];
  JournalAmount: Scalars['Float'];
};

/** JsonType */
export type LedgerType = {
  __typename?: 'LedgerType';
  Data?: Maybe<Array<LedgerEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type LetterTemplateDeleteInput = {
  LetterTemplateID?: Maybe<Scalars['String']>;
};

export type LetterTemplateEntity = AuditEntity & {
  __typename?: 'LetterTemplateEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  LetterTemplateID: Scalars['String'];
  AccountID: Scalars['String'];
  StandardLetterID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  TemplateBody?: Maybe<Scalars['String']>;
  JointBuyerSignRequired?: Maybe<Scalars['Boolean']>;
  StandardLetterDataSourceVersion?: Maybe<Scalars['Float']>;
  IsReportDesignEditor?: Maybe<Scalars['Boolean']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  RecordStatus: RecordStatus;
  StandardLetter?: Maybe<StandardLetterEntity>;
};

export type LetterTemplateInput = {
  LetterTemplateID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  TemplateBody?: Maybe<Scalars['String']>;
  StandardLetterID?: Maybe<Scalars['String']>;
  JointBuyerSignRequired?: Maybe<Scalars['Boolean']>;
  StandardDataSourceVersion?: Maybe<Scalars['Float']>;
  IsReportDesignEditor?: Maybe<Scalars['Boolean']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
};

export type ListEntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  companyID: Scalars['String'];
  roleUserIDs: Array<AccRoleUserInput>;
};

export type LocalBankEntity = AuditEntity & {
  __typename?: 'LocalBankEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  countryID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  code: Scalars['String'];
  address: Address;
  contactNo: Scalars['String'];
  faxNo?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  user: UserResponse;
};

export type MasterCoaDeleteInput = {
  MasterCOAID: Scalars['String'];
};

export type MasterCoaEntity = AuditEntity & {
  __typename?: 'MasterCOAEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  MasterCOAID: Scalars['String'];
  ParentMasterCOAID?: Maybe<Scalars['String']>;
  Code: Scalars['String'];
  Name: Scalars['String'];
  Level: Scalars['Float'];
  AccountType: AccountType;
  IsControl: Scalars['Boolean'];
  IsLastNode: Scalars['Boolean'];
  BudgetItem?: Maybe<Array<BudgetItemEntity>>;
  JournalItem?: Maybe<Array<JournalItemEntity>>;
  LedgerItem?: Maybe<Array<LedgerItemEntity>>;
  ExternalJournalItem?: Maybe<Array<ExternalJournalItemEntity>>;
  CreditorType?: Maybe<Array<CreditorTypeEntity>>;
  AccrualCreditorType?: Maybe<Array<CreditorTypeEntity>>;
  AdvanceCreditorType?: Maybe<Array<CreditorTypeEntity>>;
  SelfBilledCreditorType?: Maybe<Array<CreditorTypeEntity>>;
  TaxScheme?: Maybe<Array<TaxSchemeEntity>>;
  BankAccount?: Maybe<Array<AccBankAccountEntity>>;
  JournalProcessingItem?: Maybe<Array<JournalProcessingItemEntity>>;
  FavoriteJournalProcessingItem?: Maybe<Array<FavoriteJournalProcessingItemEntity>>;
  RoutineJournalItem?: Maybe<Array<RoutineJournalItemEntity>>;
  RecurringJournalItem?: Maybe<Array<RecurringJournalItemEntity>>;
  RecurringJournalGenerationItem?: Maybe<Array<RecurringJournalGenerationItemEntity>>;
  EntityCOA?: Maybe<Array<EntityCoaEntity>>;
  RetainEarningAccountDefID?: Maybe<Array<RetainEarningAccountDefEntity>>;
  AuditAdjustmentItem?: Maybe<Array<AuditAdjustmentItemEntity>>;
  PaymentItem?: Maybe<Array<PaymentItemEntity>>;
  ReceiptItem?: Maybe<Array<ReceiptItemEntity>>;
  FavoritePaymentItem?: Maybe<Array<FavoritePaymentItemEntity>>;
  FavoriteReceiptItem?: Maybe<Array<FavoriteReceiptItemEntity>>;
  FavoriteInvoiceItem?: Maybe<Array<AccArFavoriteInvoiceItemEntity>>;
  APInvoiceItem?: Maybe<Array<AccApInvoiceItemEntity>>;
  DebtorType?: Maybe<Array<DebtorTypeEntity>>;
  ExpenseClass?: Maybe<Array<ExpenseClassEntity>>;
  ExpenseItem?: Maybe<Array<ExpenseItemEntity>>;
  APCreditNoteItem?: Maybe<Array<AccApCreditNoteItemEntity>>;
  APDebitNoteItem?: Maybe<Array<AccApDebitNoteItemEntity>>;
  APSelfBilledItem?: Maybe<Array<AccApSelfBilledItemEntity>>;
  APCreditorDebitNoteItem?: Maybe<Array<AccApCreditorDebitNoteItemEntity>>;
  ARCreditNoteItem?: Maybe<Array<AccArCreditNoteItemEntity>>;
  SCStockAccount?: Maybe<Array<StockCategoryEntity>>;
  BillItem?: Maybe<Array<BillItemEntity>>;
  TransactionType?: Maybe<Array<TransactionTypeEntity>>;
  CostCentreCOA?: Maybe<Array<CostCentreCoaEntity>>;
  GeneralPurchasePolicy?: Maybe<Array<GeneralPurchasePolicyEntity>>;
};

export type MasterCoaInput = {
  MasterCOAID?: Maybe<Scalars['String']>;
  ParentMasterCOAID?: Maybe<Scalars['String']>;
  ParentCode?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  IsControl?: Maybe<Scalars['Boolean']>;
  IsLastNode?: Maybe<Scalars['Boolean']>;
  AccountType: AccountType;
  RowNo?: Maybe<Scalars['Float']>;
};

export enum ModuleType {
  AllModule = 'ALL_MODULE',
  Gl = 'GL',
  Cb = 'CB',
  Ar = 'AR',
  Ap = 'AP',
  Ic = 'IC',
  Gp = 'GP'
}

export type MonthPeriod = {
  __typename?: 'MonthPeriod';
  month?: Maybe<Scalars['DateTime']>;
  outstandingAmt?: Maybe<Scalars['Float']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createUser: UserEntity;
  createUserToken: UserEntity;
  updateUser: UserEntity;
  createPassword: Scalars['Boolean'];
  createPasswordTest: Scalars['Boolean'];
  activateUser: Scalars['Boolean'];
  login: LoginResponse;
  changePassword: Scalars['Boolean'];
  changePasswordForUser: Scalars['Boolean'];
  logout: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  toggleUserActivation: Scalars['Boolean'];
  totpSecret: Scalars['String'];
  totpValidate: Scalars['Boolean'];
  uploadProfilePic: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  resendInvitation: Scalars['Boolean'];
  createAPAdvance: AccApAdvanceEntity;
  updateAPAdvance: AccApAdvanceEntity;
  deleteAPAdvance: Scalars['Boolean'];
  postAPAdvance: Scalars['Boolean'];
  cancelAPAdvance: Scalars['Boolean'];
  rejectAPAdvance: Scalars['Boolean'];
  generatePayment: AccApPaymentEntity;
  createARAdvance: Scalars['Boolean'];
  updateARAdvance: Scalars['Boolean'];
  deleteARAdvance: Scalars['Boolean'];
  postARAdvance: Scalars['Boolean'];
  cancelARAdvance: Scalars['Boolean'];
  rejectARAdvance: Scalars['Boolean'];
  createAllocation: Scalars['JSON'];
  updateAPBatchPayment: Scalars['Boolean'];
  deleteAPBatchPayment: Scalars['Boolean'];
  cancelAPBatchPayment: Scalars['Boolean'];
  createAPBatchPaymentWithAllocation: Scalars['Boolean'];
  updateAPBatchPaymentWithAllocation: Scalars['Boolean'];
  payAPBatchPayment: Scalars['Boolean'];
  deleteBatchPayment: Scalars['Boolean'];
  rejectBatchPayment: Scalars['Boolean'];
  createAPCreditNote: AccApCreditNoteEntity;
  updateAPCreditNote: AccApCreditNoteEntity;
  deleteAPCreditNote: Scalars['Boolean'];
  postAPCreditNote: Scalars['Boolean'];
  cancelAPCreditNote: Scalars['Boolean'];
  rejectAPCreditNote: Scalars['Boolean'];
  createARCreditNote: AccArCreditNoteEntity;
  updateARCreditNote: AccArCreditNoteEntity;
  deleteARCreditNote: Scalars['Boolean'];
  postARCreditNote: Scalars['Boolean'];
  cancelARCreditNote: Scalars['Boolean'];
  rejectARCreditNote: Scalars['Boolean'];
  createAPCreditorCreditNote: Scalars['Boolean'];
  updateAPCreditorCreditNote: Scalars['Boolean'];
  deleteAPCreditorCreditNote: Scalars['Boolean'];
  postAPCreditorCreditNote: Scalars['Boolean'];
  rejectAPCreditorCreditNote: Scalars['Boolean'];
  createAPCreditNoteItem: AccApCreditNoteItemEntity;
  updateAPCreditNoteItem: AccApCreditNoteItemEntity;
  deleteAPCreditNoteItem: Scalars['Boolean'];
  cancelAPCreditNoteItem: Scalars['Boolean'];
  createAPDebitNote: AccApDebitNoteEntity;
  updateAPDebitNote: AccApDebitNoteEntity;
  deleteAPDebitNote: Scalars['Boolean'];
  postAPDebitNote: Scalars['Boolean'];
  cancelAPDebitNote: Scalars['Boolean'];
  rejectAPDebitNote: Scalars['Boolean'];
  createARDebitNote: AccArDebitNoteEntity;
  updateARDebitNote: AccArDebitNoteEntity;
  deleteARDebitNote: Scalars['Boolean'];
  postARDebitNote: Scalars['Boolean'];
  cancelARDebitNote: Scalars['Boolean'];
  rejectARDebitNote: Scalars['Boolean'];
  createAPCreditorDebitNote: AccApCreditorDebitNoteEntity;
  updateAPCreditorDebitNote: AccApCreditorDebitNoteEntity;
  deleteAPCreditorDebitNote: Scalars['Boolean'];
  postAPCreditorDebitNote: Scalars['Boolean'];
  cancelAPCreditorDebitNote: Scalars['Boolean'];
  rejectAPCreditorDebitNote: Scalars['Boolean'];
  createAPDebitNoteItem: AccApDebitNoteItemEntity;
  updateAPDebitNoteItem: AccApDebitNoteItemEntity;
  deleteAPDebitNoteItem: Scalars['Boolean'];
  cancelAPDebitNoteItem: Scalars['Boolean'];
  createARDebitNoteItem: AccArDebitNoteItemEntity;
  updateARDebitNoteItem: AccArDebitNoteItemEntity;
  deleteARDebitNoteItem: Scalars['Boolean'];
  cancelARDebitNoteItem: Scalars['Boolean'];
  deleteAPFavoriteInvoice: Scalars['Boolean'];
  createAPFavoriteInvoice: Scalars['Boolean'];
  deleteARFavoriteInvoice: Scalars['Boolean'];
  createARFavoriteInvoice: Scalars['Boolean'];
  createAPFavoritePayment: Scalars['Boolean'];
  deleteAPFavoritePayment: Scalars['Boolean'];
  createAPInvoice: AccApInvoiceEntity;
  updateAPInvoice: AccApInvoiceEntity;
  deleteAPInvoice: Scalars['Boolean'];
  postAPInvoice: Scalars['Boolean'];
  cancelAPInvoice: Scalars['Boolean'];
  rejectAPInvoice: Scalars['Boolean'];
  createARInvoice: AccArInvoiceEntity;
  updateARInvoice: AccArInvoiceEntity;
  deleteARInvoice: Scalars['Boolean'];
  postARInvoice: Scalars['Boolean'];
  rejectARInvoice: Scalars['Boolean'];
  createAPInvoiceItem: AccApInvoiceItemEntity;
  updateAPInvoiceItem: AccApInvoiceItemEntity;
  deleteAPInvoiceItem: Scalars['Boolean'];
  cancelAPInvoiceItem: Scalars['Boolean'];
  createARInvoiceItem: AccArInvoiceItemEntity;
  updateARInvoiceItem: AccArInvoiceItemEntity;
  deleteARInvoiceItem: Scalars['Boolean'];
  cancelARInvoiceItem: Scalars['Boolean'];
  cancelAROfficialReceipt: Scalars['Boolean'];
  createAROfficialReceipt: AccArOfficialReceiptEntity;
  updateAROfficialReceipt: AccArOfficialReceiptEntity;
  deleteAROfficialReceipt: Scalars['Boolean'];
  postAROfficialReceipt: Scalars['Boolean'];
  rejectAROfficialReceipt: Scalars['Boolean'];
  cancelAPPayment: Scalars['Boolean'];
  createAPPayment: AccApPaymentEntity;
  updateAPPayment: AccApPaymentEntity;
  deleteAPPayment: Scalars['Boolean'];
  postAPPayment: Scalars['Boolean'];
  rejectAPPayment: Scalars['Boolean'];
  cancelAPRefund: Scalars['Boolean'];
  createAPRefund: Scalars['Boolean'];
  updateAPRefund: Scalars['Boolean'];
  deleteAPRefund: Scalars['Boolean'];
  postAPRefund: Scalars['Boolean'];
  rejectAPRefund: Scalars['Boolean'];
  cancelARRefund: Scalars['Boolean'];
  createARRefund: AccArRefundEntity;
  deleteARRefund: Scalars['Boolean'];
  updateARRefund: AccArRefundEntity;
  postARRefund: Scalars['Boolean'];
  rejectARRefund: Scalars['Boolean'];
  createAPSelfBilled: AccApSelfBilledEntity;
  updateAPSelfBilled: AccApSelfBilledEntity;
  deleteAPSelfBilled: Scalars['Boolean'];
  postAPSelfBilled: Scalars['Boolean'];
  cancelAPSelfBilled: Scalars['Boolean'];
  rejectAPSelfBilled: Scalars['Boolean'];
  cancelEinvoice: Scalars['Boolean'];
  getShareAttachment?: Maybe<AttachmentEntity>;
  singleUpload: Scalars['Boolean'];
  createBankReconciliation: BankReconciliationEntity;
  updateBankReconciliation: BankReconciliationEntity;
  cancelBankReconciliation: Scalars['Boolean'];
  createImportBankReconciliation: Scalars['Boolean'];
  updateImportBankReconciliation: Scalars['Boolean'];
  createBankReconciliationWihoutExcel: Scalars['Boolean'];
  updateBankReconciliationWihoutExcel: Scalars['Boolean'];
  MatchBankReconciliation: Scalars['Boolean'];
  deleteBankReconciliation: Scalars['Boolean'];
  createBankReconciliationItem: BankReconciliationItemEntity;
  updateBankReconciliationItem: BankReconciliationItemEntity;
  deleteBankReconciliationItem: Scalars['Boolean'];
  cancelBankReconciliationItem: Scalars['Boolean'];
  createBankReconciliationMatch: BankReconciliationMatchEntity;
  updateBankReconciliationMatch: BankReconciliationMatchEntity;
  deleteBankReconciliationMatch: Scalars['Boolean'];
  cancelBankReconciliationMatch: Scalars['Boolean'];
  createBankTransfer: BankTransferEntity;
  updateBankTransfer: BankTransferEntity;
  deleteBankTransfer: Scalars['Boolean'];
  cancelBankTransfer: Scalars['Boolean'];
  postCBBankTransfer: Scalars['Boolean'];
  rejectBankTransfer: Scalars['Boolean'];
  createExternalLedger: Scalars['Boolean'];
  CBImportLedger: Scalars['JSON'];
  deleteFavoritePayment: Scalars['Boolean'];
  createFavoritePayment: Scalars['Boolean'];
  deleteFavoriteReceipt: Scalars['Boolean'];
  createFavoriteReceipt: Scalars['Boolean'];
  createLedger: LedgerEntity;
  updateLedger: LedgerEntity;
  deleteLedger: Scalars['Boolean'];
  cancelLedger: Scalars['Boolean'];
  createLedgerItem: LedgerItemEntity;
  updateLedgerItem: LedgerItemEntity;
  deleteLedgerItem: Scalars['Boolean'];
  cancelLedgerItem: Scalars['Boolean'];
  createPayment: PaymentEntity;
  updatePayment: PaymentEntity;
  deletePayment: Scalars['Boolean'];
  cancelPayment: Scalars['Boolean'];
  postCBPayment: Scalars['Boolean'];
  rejectPayment: Scalars['Boolean'];
  createPaymentItem: PaymentItemEntity;
  updatePaymentItem: PaymentItemEntity;
  deletePaymentItem: Scalars['Boolean'];
  cancelPaymentItem: Scalars['Boolean'];
  createReceipt: ReceiptEntity;
  updateReceipt: ReceiptEntity;
  deleteReceipt: Scalars['Boolean'];
  cancelReceipt: Scalars['Boolean'];
  postCBReceipt: Scalars['Boolean'];
  rejectReceipt: Scalars['Boolean'];
  createReceiptItem: ReceiptItemEntity;
  updateReceiptItem: ReceiptItemEntity;
  deleteReceiptItem: Scalars['Boolean'];
  cancelReceiptItem: Scalars['Boolean'];
  deleteCompanyLetterTemplate: Scalars['Boolean'];
  cancelCompanyLetterTemplate: Scalars['Boolean'];
  createCompanyLetterTemplate: CompanyLetterTemplateEntity;
  updateCompanyLetterTemplate: Scalars['Boolean'];
  deleteLetterTemplate: Scalars['Boolean'];
  cancelLetterTemplate: Scalars['Boolean'];
  createLetterTemplate: LetterTemplateEntity;
  updateLetterTemplate: Scalars['Boolean'];
  createStandardLetterDataSource: StandardLetterDataSourceEntity;
  updateStandardLetterDataSource: StandardLetterDataSourceEntity;
  deleteStandardLetterDataSource: Scalars['Boolean'];
  cancelStandardLetterDataSource: Scalars['Boolean'];
  createAuditAdjustment: AuditAdjustmentEntity;
  updateAuditAdjustment: AuditAdjustmentEntity;
  deleteAuditAdjustment: Scalars['Boolean'];
  cancelAuditAdjustment: Scalars['Boolean'];
  postAuditAdjustment: Scalars['Boolean'];
  rejectAuditAdjustment: Scalars['Boolean'];
  createAuditAdjustmentItem: AuditAdjustmentItemEntity;
  updateAuditAdjustmentItem: AuditAdjustmentItemEntity;
  deleteAuditAdjustmentItem: Scalars['Boolean'];
  cancelAuditAdjustmentItem: Scalars['Boolean'];
  createBudget: BudgetEntity;
  updateBudget: BudgetEntity;
  deleteBudget: Scalars['Boolean'];
  cancelBudget: Scalars['Boolean'];
  createBudgetWithDetail: Scalars['Boolean'];
  updateBudgetWithDetail: Scalars['Boolean'];
  setBudgetDefault: Scalars['Boolean'];
  createBudgetItem: BudgetItemEntity;
  updateBudgetItem: BudgetItemEntity;
  deleteBudgetItem: Scalars['Boolean'];
  cancelBudgetItem: Scalars['Boolean'];
  createBudgetItemCostCentre: BudgetItemCostCentreEntity;
  updateBudgetItemCostCentre: BudgetItemCostCentreEntity;
  deleteBudgetItemCostCentre: Scalars['Boolean'];
  cancelBudgetItemCostCentre: Scalars['Boolean'];
  createBudgetItemPeriod: BudgetItemPeriodEntity;
  updateBudgetItemPeriod: BudgetItemPeriodEntity;
  deleteBudgetItemPeriod: Scalars['Boolean'];
  cancelBudgetItemPeriod: Scalars['Boolean'];
  createExternalJournal: Scalars['Boolean'];
  deleteFavoriteJournalProcessing: Scalars['Boolean'];
  createFavoriteJournalProcessing: Scalars['Boolean'];
  createDataSourceSettings: DataSourceSettingsEntity;
  updateDataSourceSettings: DataSourceSettingsEntity;
  deleteDataSourceSettings: Scalars['Boolean'];
  cancelDataSourceSettings: Scalars['Boolean'];
  createReportSettings: ReportSettingsEntity;
  updateReportSettings: ReportSettingsEntity;
  deleteReportSettings: Scalars['Boolean'];
  cancelReportSettings: Scalars['Boolean'];
  uploadReportTemplate: Scalars['Boolean'];
  updateJournal: Scalars['Boolean'];
  deleteJournal: Scalars['Boolean'];
  cancelJournal: Scalars['Boolean'];
  createJournal: Scalars['Boolean'];
  GLImportLedger: Scalars['JSON'];
  createJournalItem: JournalItemEntity;
  updateJournalItem: JournalItemEntity;
  deleteJournalItem: Scalars['Boolean'];
  cancelJournalItem: Scalars['Boolean'];
  createJournalProcessing: JournalProcessingEntity;
  updateJournalProcessing: JournalProcessingEntity;
  deleteJournalProcessing: Scalars['Boolean'];
  cancelJournalProcessing: Scalars['Boolean'];
  postJournalProcessing: Scalars['Boolean'];
  rejectJournalProcessing: Scalars['Boolean'];
  processMonthEndClosing?: Maybe<ProcessMonthYearEndOutput>;
  revertMonthEndClosing?: Maybe<Scalars['Boolean']>;
  revertYearEndClosing?: Maybe<Scalars['Boolean']>;
  processYearEndClosing: Scalars['Boolean'];
  createRecurringJournal: Scalars['Boolean'];
  updateRecurringJournal: Scalars['Boolean'];
  deleteRecurringJournal: Scalars['Boolean'];
  cancelRecurringJournal: Scalars['Boolean'];
  postRecurringJournal: Scalars['Boolean'];
  rejectRecurringJournal: Scalars['Boolean'];
  generateRecurringJournal: Scalars['Boolean'];
  createRecurringJournalItem: RecurringJournalItemEntity;
  updateRecurringJournalItem: RecurringJournalItemEntity;
  deleteRecurringJournalItem: Scalars['Boolean'];
  cancelRecurringJournalItem: Scalars['Boolean'];
  updateRoutineJournal: RoutineJournalEntity;
  deleteRoutineJournal: Scalars['Boolean'];
  cancelRoutineJournal: Scalars['Boolean'];
  createRoutineJournal: Scalars['Boolean'];
  updateRoutineJournals: Scalars['Boolean'];
  createRoutineJournalItem: RoutineJournalItemEntity;
  updateRoutineJournalItem: RoutineJournalItemEntity;
  deleteRoutineJournalItem: Scalars['Boolean'];
  cancelRoutineJournalItem: Scalars['Boolean'];
  postGLExport: Scalars['Boolean'];
  createGLExport: Scalars['Boolean'];
  deleteCycleCount: Scalars['Boolean'];
  cancelCycleCount: Scalars['Boolean'];
  createCycleCount: Scalars['JSON'];
  updateCycleCount: Scalars['JSON'];
  postCycleCount: Scalars['Boolean'];
  createStockRecon: Scalars['Boolean'];
  createCycleCountItem: CycleCountItemEntity;
  updateCycleCountItem: CycleCountItemEntity;
  deleteCycleCountItem: Scalars['Boolean'];
  cancelCycleCountItem: Scalars['Boolean'];
  createFavoriteStockRequisition: Scalars['Boolean'];
  deleteFavoriteStockRequisition: Scalars['Boolean'];
  createInventoryPolicy: InventoryPolicyEntity;
  updateInventoryPolicy: InventoryPolicyEntity;
  deleteInventoryPolicy: Scalars['Boolean'];
  cancelInventoryPolicy: Scalars['Boolean'];
  deleteStockAdjustment: Scalars['Boolean'];
  cancelStockAdjustment: Scalars['Boolean'];
  createStockAdjustment: Scalars['JSON'];
  updateStockAdjustment: Scalars['JSON'];
  postStockAdjustment: Scalars['Boolean'];
  createStockAdjustmentItem: StockAdjustmentItemEntity;
  updateStockAdjustmentItem: StockAdjustmentItemEntity;
  deleteStockAdjustmentItem: Scalars['Boolean'];
  cancelStockAdjustmentItem: Scalars['Boolean'];
  createStockAdjustmentReason: StockAdjustmentReasonEntity;
  updateStockAdjustmentReason: StockAdjustmentReasonEntity;
  deleteStockAdjustmentReason: Scalars['Boolean'];
  cancelStockAdjustmentReason: Scalars['Boolean'];
  cancelStockCategory: Scalars['Boolean'];
  deleteStockCategory: Scalars['Boolean'];
  createStockCategory: Scalars['Boolean'];
  updateStockCategory: Scalars['Boolean'];
  createStockCostAdjustment: StockCostAdjustmentEntity;
  updateStockCostAdjustment: StockCostAdjustmentEntity;
  deleteStockCostAdjustment: Scalars['Boolean'];
  cancelStockCostAdjustment: Scalars['Boolean'];
  postStockCostAdjustment: Scalars['Boolean'];
  deleteStockIssue: Scalars['Boolean'];
  cancelStockIssue: Scalars['Boolean'];
  createStockIssue: Scalars['JSON'];
  updateStockIssue: Scalars['JSON'];
  postStockIssue: Scalars['Boolean'];
  createStockIssueItem: StockIssueItemEntity;
  updateStockIssueItem: StockIssueItemEntity;
  deleteStockIssueItem: Scalars['Boolean'];
  cancelStockIssueItem: Scalars['Boolean'];
  deleteStockReceipt: Scalars['Boolean'];
  cancelStockReceipt: Scalars['Boolean'];
  createStockReceipt: Scalars['JSON'];
  updateStockReceipt: Scalars['JSON'];
  postStockReceipt: Scalars['Boolean'];
  RevokeStockReceipt: Scalars['Boolean'];
  createStockReceiptItem: StockReceiptItemEntity;
  updateStockReceiptItem: StockReceiptItemEntity;
  deleteStockReceiptItem: Scalars['Boolean'];
  cancelStockReceiptItem: Scalars['Boolean'];
  deleteStockRequisition: Scalars['Boolean'];
  cancelStockRequisition: Scalars['Boolean'];
  createStockRequisition: Scalars['JSON'];
  updateStockRequisition: Scalars['JSON'];
  postStockRequisition: Scalars['Boolean'];
  createStockRequisitionItem: StockRequisitionItemEntity;
  updateStockRequisitionItem: StockRequisitionItemEntity;
  deleteStockRequisitionItem: Scalars['Boolean'];
  cancelStockRequisitionItem: Scalars['Boolean'];
  deleteStockTransfer: Scalars['Boolean'];
  cancelStockTransfer: Scalars['Boolean'];
  createStockTransfer: Scalars['JSON'];
  updateStockTransfer: Scalars['JSON'];
  postStockTransfer: Scalars['Boolean'];
  createStockTransferItem: StockTransferItemEntity;
  updateStockTransferItem: StockTransferItemEntity;
  deleteStockTransferItem: Scalars['Boolean'];
  cancelStockTransferItem: Scalars['Boolean'];
  deleteStockTransferRequisition: Scalars['Boolean'];
  createStockTransferRequisition: Scalars['Boolean'];
  updateStockTransferRequisition: Scalars['Boolean'];
  postStockTransferRequisition: Scalars['Boolean'];
  cancelStockTransferRequisition: Scalars['Boolean'];
  createStockTransferRequisitionItem: StockTransferRequisitionItemEntity;
  updateStockTransferRequisitionItem: StockTransferRequisitionItemEntity;
  deleteStockTransferRequisitionItem: Scalars['Boolean'];
  cancelStockTransferRequisitionItem: Scalars['Boolean'];
  updateAccountMapping: Glmfrs16AccountMappingEntity;
  deleteAccountMapping: Scalars['Boolean'];
  cancelAccountMapping: Scalars['Boolean'];
  createAccountMapping: Scalars['Boolean'];
  deleteCompany: Scalars['Boolean'];
  cancelCompany: Scalars['Boolean'];
  createCompany: CompanyEntity;
  updateCompany: Scalars['Boolean'];
  deleteCompanyProfile: Scalars['Boolean'];
  createCompanyImport: Scalars['Boolean'];
  resetGeneralRunningNumber: Scalars['Boolean'];
  patchAPPayment: Scalars['Boolean'];
  patchNullGLRecurringDescriptionData: Scalars['Boolean'];
  patchNullBaseUOMInStockItemData: Scalars['Boolean'];
  patchJournalTypeForCompanies: Scalars['Boolean'];
  initialDatabaseSetup: Scalars['Boolean'];
  initialMasterFileSetup: Scalars['Boolean'];
  extraDBSetup: Scalars['Boolean'];
  extraSystemAdminSetup: Scalars['Boolean'];
  insertAdjustmentReason: Scalars['Boolean'];
  createSystemJournalType: Scalars['Boolean'];
  CreateRolePermission: Scalars['Boolean'];
  UpdateRolePermission: Scalars['Boolean'];
  DuplicateRolePermission: Scalars['Boolean'];
  DeleteRolePermission: Scalars['Boolean'];
  AssignEntityRoleUser: Scalars['Boolean'];
  UnassignEntityRoleUser: Scalars['Boolean'];
  CreateListEntityRoleUser: Scalars['Boolean'];
  resetRolePermission: Scalars['Boolean'];
  deleteAccountPeriod: Scalars['Boolean'];
  cancelAccountPeriod: Scalars['Boolean'];
  createAccountPeriod: Scalars['Boolean'];
  updateAccountPeriod: Scalars['Boolean'];
  closeAccountPeriod: Scalars['Boolean'];
  updateARAging: ArAgingEntity;
  deleteARAging: Scalars['Boolean'];
  cancelARAging: Scalars['Boolean'];
  updateAPAging: ApAgingEntity;
  deleteAPAging: Scalars['Boolean'];
  cancelAPAging: Scalars['Boolean'];
  createARAging: Scalars['Boolean'];
  createAPAging: Scalars['Boolean'];
  cancelApprovalPolicy: Scalars['Boolean'];
  deleteApprovalPolicyAssignment: Scalars['Boolean'];
  cancelApprovalPolicyAssignment: Scalars['Boolean'];
  createApprovalPolicy: AccApprovalPolicyEntity;
  updateApprovalPolicy: AccApprovalPolicyEntity;
  deleteApprovalPolicy: Scalars['Boolean'];
  createApprovalPolicyAssignment: AccApprovalPolicyAssignmentEntity;
  updateApprovalPolicyAssignment: AccApprovalPolicyAssignmentEntity;
  createUpdateDeleteApprovalPolicyPO: Scalars['JSON'];
  createBankAccount: AccBankAccountEntity;
  updateBankAccount: AccBankAccountEntity;
  deleteBankAccount: Scalars['Boolean'];
  cancelBankAccount: Scalars['Boolean'];
  activateBankAccount: Scalars['Boolean'];
  createBankAccountImport: Scalars['Boolean'];
  createBankProfile: BankProfileEntity;
  updateBankProfile: BankProfileEntity;
  deleteBankProfile: Scalars['Boolean'];
  cancelBankProfile: Scalars['Boolean'];
  createBankProfileImport: Scalars['Boolean'];
  createBillItem: BillItemEntity;
  updateBillItem: BillItemEntity;
  deleteBillItem: Scalars['Boolean'];
  cancelBillItem: Scalars['Boolean'];
  updateCOAStructure: CoaStructureEntity;
  deleteCOAStructure: Scalars['Boolean'];
  cancelCOAStructure: Scalars['Boolean'];
  createCOAStructure: Scalars['Boolean'];
  updateContact: AccContactEntity;
  updateCostCentre: CostCentreEntity;
  cancelCostCentre: Scalars['Boolean'];
  createCostCentreDefinitionAsgmt: Scalars['Boolean'];
  deleteCostCentre: Scalars['Boolean'];
  createCostCentre: Scalars['Boolean'];
  setDefaultCostCentre: Scalars['Boolean'];
  createCostCentreCOA: Scalars['Boolean'];
  assignCostCentreCOA: Scalars['Boolean'];
  unassignCostCentreCOA: Scalars['Boolean'];
  deleteCostcentreCOA: Scalars['Boolean'];
  updateCostCentreDefinitionHeader: CostCentreDefinitionHeaderEntity;
  cancelCostCentreDefinitionHeader: Scalars['Boolean'];
  updateCostCentreDefinitionDetail: CostCentreDefinitionDetailEntity;
  deleteCostCentreDefinitionDetail: Scalars['Boolean'];
  cancelCostCentreDefinitionDetail: Scalars['Boolean'];
  createCostCentreDefinition: Scalars['Boolean'];
  createUpdateCostCentreDefinition: Scalars['Boolean'];
  deleteCostCentreDefinition: Scalars['Boolean'];
  createCostCentreType: CostCentreTypeEntity;
  updateCostCentreType: CostCentreTypeEntity;
  deleteCostCentreType: Scalars['Boolean'];
  cancelCostCentreType: Scalars['Boolean'];
  createUpdateCostCentreType: Scalars['Boolean'];
  updateCreditCardType: CreditCardTypeEntity;
  deleteCreditCardType: Scalars['Boolean'];
  cancelCreditCardType: Scalars['Boolean'];
  createCreditCardType: Scalars['Boolean'];
  updateCreditorAccount: CreditorAccountEntity;
  deleteCreditorAccount: Scalars['Boolean'];
  cancelCreditorAccount: Scalars['Boolean'];
  CreateCreditorAccount: Scalars['Boolean'];
  UpdateCreditorAccountWithItem: Scalars['Boolean'];
  CreateCreditorAccountWithAccount: Scalars['Boolean'];
  createCreditorProfileImport: Scalars['Boolean'];
  updateCreditorAccountCompanyAssignment: CreditorAccountCompanyAssignmentEntity;
  deleteCreditorAccountCompanyAssignment: Scalars['Boolean'];
  cancelCreditorAccountCompanyAssignment: Scalars['Boolean'];
  AssignCreditorAccountComapany: Scalars['Boolean'];
  UnassignCreditorAccountComapany: Scalars['Boolean'];
  createCreditorContact: CreditorContactEntity;
  updateCreditorContact: CreditorContactEntity;
  deleteCreditorContact: Scalars['Boolean'];
  cancelCreditorContact: Scalars['Boolean'];
  createCreditorDirector: CreditorDirectorEntity;
  updateCreditorDirector: CreditorDirectorEntity;
  deleteCreditorDirector: Scalars['Boolean'];
  cancelCreditorDirector: Scalars['Boolean'];
  createCreditorType: CreditorTypeEntity;
  updateCreditorType: CreditorTypeEntity;
  deleteCreditorType: Scalars['Boolean'];
  cancelCreditorType: Scalars['Boolean'];
  CreateCreditorTypeWithAccount: Scalars['Boolean'];
  createCurrency: CurrencyEntity;
  updateCurrency: CurrencyEntity;
  deleteCurrency: Scalars['Boolean'];
  cancelCurrency: Scalars['Boolean'];
  cancelCurrencyExchange: Scalars['Boolean'];
  createCurrencyExchange: Scalars['Boolean'];
  updateCurrencyExchange: Scalars['Boolean'];
  deleteCurrencyExchange: Scalars['Boolean'];
  updateDebtorAccount: DebtorAccountEntity;
  deleteDebtorAccount: Scalars['Boolean'];
  cancelDebtorAccount: Scalars['Boolean'];
  CreateDebtorAccount: Scalars['Boolean'];
  UpdateDebtorAccountWithItem: Scalars['Boolean'];
  createDebtorAccountImport: Scalars['Boolean'];
  createDebtorContact: DebtorContactEntity;
  updateDebtorContact: DebtorContactEntity;
  deleteDebtorContact: Scalars['Boolean'];
  cancelDebtorContact: Scalars['Boolean'];
  createDebtorType: DebtorTypeEntity;
  updateDebtorType: DebtorTypeEntity;
  deleteDebtorType: Scalars['Boolean'];
  cancelDebtorType: Scalars['Boolean'];
  deleteDeliveryLocation: Scalars['Boolean'];
  cancelDeliveryLocation: Scalars['Boolean'];
  createDeliveryLoc: Scalars['Boolean'];
  updateDeliveryLoc: Scalars['Boolean'];
  updateDocumentDateValidation: DocumentDateValidationEntity;
  deleteDocumentDateValidation: Scalars['Boolean'];
  cancelDocumentDateValidation: Scalars['Boolean'];
  createDocumentDateValidation: Scalars['Boolean'];
  createDocumentNumberDetail: DocumentNumberDetailEntity;
  updateDocumentNumberDetail: DocumentNumberDetailEntity;
  deleteDocumentNumberDetail: Scalars['Boolean'];
  cancelDocumentNumberDetail: Scalars['Boolean'];
  deleteDocumentNumberHeader: Scalars['Boolean'];
  cancelDocumentNumberHeader: Scalars['Boolean'];
  createDocumentNumberHeader: Scalars['Boolean'];
  updateDocumentNumberHeader: Scalars['Boolean'];
  updateIsAutoDocNo: Scalars['Boolean'];
  createEntityCOA: EntityCoaEntity;
  updateEntityCOA: EntityCoaEntity;
  deleteEntityCOA: Scalars['Boolean'];
  cancelEntityCOA: Scalars['Boolean'];
  assignEntityCOA: Scalars['Boolean'];
  deleteAssignedEntityCOA: Scalars['Boolean'];
  cancelExpenseClass: Scalars['Boolean'];
  deleteExpenseClass: Scalars['Boolean'];
  createExpenseClass: Scalars['Boolean'];
  updateExpenseClass: Scalars['Boolean'];
  cancelExpenseItem: Scalars['Boolean'];
  createExpenseItem: ExpenseItemEntity;
  updateExpenseItem: Scalars['Boolean'];
  deleteExpenseItem: Scalars['Boolean'];
  testDeleteExpenseItem: Scalars['Boolean'];
  initialTaxSetup: Scalars['Boolean'];
  createSuperUser: UserEntity;
  updateItemClassCompanyAssignment: ItemClassCompanyAssignmentEntity;
  deleteItemClassCompanyAssignment: Scalars['Boolean'];
  cancelItemClassCompanyAssignment: Scalars['Boolean'];
  AssignItemClassCompany: Scalars['Boolean'];
  UnassignItemClassCompany: Scalars['Boolean'];
  createJournalType: JournalTypeEntity;
  updateJournalType: JournalTypeEntity;
  deleteJournalType: Scalars['Boolean'];
  cancelJournalType: Scalars['Boolean'];
  updateMasterCOA: MasterCoaEntity;
  cancelMasterCOA: Scalars['Boolean'];
  createMasterCOA: Scalars['Boolean'];
  deleteMasterCOA: Scalars['Boolean'];
  createExcelMasterCOA: Scalars['Boolean'];
  inactiveMasterCOA: Scalars['Boolean'];
  activateMasterCOA: Scalars['Boolean'];
  createNumberFormat: NumberFormatEntity;
  updateNumberFormat: NumberFormatEntity;
  deleteNumberFormat: Scalars['Boolean'];
  cancelNumberFormat: Scalars['Boolean'];
  updatePaymentMethod: PaymentMethodEntity;
  deletePaymentMethod: Scalars['Boolean'];
  cancelPaymentMethod: Scalars['Boolean'];
  createPaymentMethod: Scalars['Boolean'];
  updateRetainEarningAccountDef: RetainEarningAccountDefEntity;
  deleteRetainEarningAccountDef: Scalars['Boolean'];
  cancelRetainEarningAccountDef: Scalars['Boolean'];
  createRetainEarningAccountDef: Scalars['Boolean'];
  cancelStockItem: Scalars['Boolean'];
  createStockItem: Scalars['Boolean'];
  UpdateStockItemWithWarehouse: Scalars['Boolean'];
  updateStockItem: Scalars['Boolean'];
  deleteStockItem: Scalars['Boolean'];
  createStockItemWarehouse: StockItemWarehouseEntity;
  updateStockItemWarehouse: StockItemWarehouseEntity;
  deleteStockItemWarehouse: Scalars['Boolean'];
  cancelStockItemWarehouse: Scalars['Boolean'];
  createTaxType: TaxTypeEntity;
  updateTaxType: TaxTypeEntity;
  deleteTaxType: Scalars['Boolean'];
  createTaxScheme: TaxSchemeEntity;
  updateTaxScheme: TaxSchemeEntity;
  createTaxEffectiveDate: TaxEffectiveDateEntity;
  updateTaxEffectiveDate: TaxEffectiveDateEntity;
  deleteTaxEffectiveDate: Scalars['Boolean'];
  insertTax: TaxSchemeEntity;
  updateTax: Scalars['Boolean'];
  deleteTaxScheme: Scalars['Boolean'];
  createTransactionType: TransactionTypeEntity;
  updateTransactionType: TransactionTypeEntity;
  deleteTransactionType: Scalars['Boolean'];
  cancelTransactionType: Scalars['Boolean'];
  createUOM: AccUomEntity;
  updateUOM: AccUomEntity;
  deleteUOM: Scalars['Boolean'];
  createUOMWithAccount: Scalars['Boolean'];
  updateUOMWithAccount: Scalars['Boolean'];
  createUOMExchange: Scalars['Boolean'];
  updateUOMExchange: Scalars['Boolean'];
  deleteUOMExchange: Scalars['Boolean'];
  updateWarehouse: Scalars['Boolean'];
  cancelWarehouse: Scalars['Boolean'];
  createWarehouse: Scalars['Boolean'];
  DeleteWarehouse: Scalars['Boolean'];
  AssignWarehouseUser: Scalars['Boolean'];
  UnassignUserWarehouse: Scalars['Boolean'];
  AssignWarehouseItem: Scalars['Boolean'];
  UnassignWarehouseItem: Scalars['Boolean'];
  approveRejectWorkDesk: Scalars['Boolean'];
  createProcurementApprovalPolicy: ProcurementApprovalPolicyEntity;
  updateProcurementApprovalPolicy: ProcurementApprovalPolicyEntity;
  cancelProcurementApprovalPolicy: Scalars['Boolean'];
  createProcurementApprovalPolicyAssignment: ProcurementApprovalPolicyAssignmentEntity;
  updateProcurementApprovalPolicyAssignment: ProcurementApprovalPolicyAssignmentEntity;
  cancelProcurementApprovalPolicyAssignment: Scalars['Boolean'];
  deleteProcurementApprovalPolicy: Scalars['Boolean'];
  createBulkPurchasePrice: BulkPurchasePriceHeaderEntity;
  updateBulkPurchasePrice: Scalars['JSON'];
  deleteBulkPurchasePrice: Scalars['Boolean'];
  submitGeneralDO: Scalars['JSON'];
  deleteGeneralDO: Scalars['Boolean'];
  rejectGeneralDO: Scalars['Boolean'];
  actionOnGDOStatus: Scalars['Boolean'];
  createFavoriteApprover: Scalars['Boolean'];
  deleteFavoriteApprover: Scalars['Boolean'];
  createFavoritePR: Scalars['Boolean'];
  deleteFavoritePR: Scalars['Boolean'];
  createGeneralPurchasePolicy: Scalars['Boolean'];
  updateGeneralPurchasePolicy: Scalars['Boolean'];
  createUpdateGRTN: Scalars['Boolean'];
  CreateGRTN: Scalars['JSON'];
  UpdateGRTN: Scalars['JSON'];
  deleteGRTN: Scalars['Boolean'];
  actionOnGRTNStatus: Scalars['Boolean'];
  createGeneralPO: Scalars['Boolean'];
  updateGeneralPO: Scalars['Boolean'];
  createDirectPO: Scalars['JSON'];
  updateDirectPO: Scalars['JSON'];
  deletePO: Scalars['Boolean'];
  actionOnGPOStatus: Scalars['Boolean'];
  cancelClosePO: Scalars['Boolean'];
  combinePO: Scalars['JSON'];
  createGeneralPR: PrHeaderEntity;
  updateGeneralPR: Scalars['JSON'];
  actionOnGPRStatus: Scalars['Boolean'];
  deleteGeneralPR: Scalars['Boolean'];
  cancelGeneralPR: Scalars['Boolean'];
  cancelClosePR: Scalars['Boolean'];
  patchPRItemOutstandingQtyData: Scalars['Boolean'];
  createGeneralRFQ: Scalars['Boolean'];
  updateGeneralRFQ: Scalars['Boolean'];
  deleteGeneralRFQ: Scalars['Boolean'];
  submitEvaluationSelection: Scalars['Boolean'];
  actionOnRFQStatus: Scalars['Boolean'];
  inviteSupplier: Scalars['Boolean'];
  uninviteSupplier: Scalars['Boolean'];
  submitGeneralRFQQuote: Scalars['Boolean'];
  deleteRFQSupplierSubmission: Scalars['Boolean'];
  createScheduledPurchases: Scalars['JSON'];
  updateScheduledPurchases: Scalars['JSON'];
  deleteScheduledPurchases: Scalars['Boolean'];
  deleteScheduledPurchasesItem: Scalars['Boolean'];
  createPreparePO: Scalars['JSON'];
  createItem: ItemEntity;
  updateItem: Scalars['Boolean'];
  cancelItem: Scalars['Boolean'];
  deleteItem: Scalars['Boolean'];
  createBatchItem: Scalars['Boolean'];
  createItemCategory: ItemCategoryEntity;
  updateItemCategory: Scalars['Boolean'];
  deleteItemCategory: Scalars['Boolean'];
  AssignItemCategoryCompany: Scalars['Boolean'];
  UnassignItemCategoryCompany: Scalars['Boolean'];
  createPurchaseItemVariancePolicy: Scalars['Boolean'];
  updatePurchaseItemVariancePolicy: Scalars['Boolean'];
  procurementLogin: LoginResponse;
  createDocumentFolder: Scalars['Boolean'];
  updateDocumentFolder: Scalars['Boolean'];
  deleteDocumentFolder: Scalars['Boolean'];
  accountSubscriptionInfo: Scalars['Boolean'];
};


export type MutationCreateUserArgs = {
  roleID?: Maybe<Scalars['String']>;
  input: UserInput;
};


export type MutationCreateUserTokenArgs = {
  roleID?: Maybe<Scalars['String']>;
  input: UserInput;
};


export type MutationUpdateUserArgs = {
  roleID?: Maybe<Scalars['String']>;
  input: UserInput;
};


export type MutationCreatePasswordArgs = {
  input: PasswordAccInfo;
};


export type MutationCreatePasswordTestArgs = {
  input: PasswordAccInfo;
};


export type MutationActivateUserArgs = {
  token: Scalars['String'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationChangePasswordForUserArgs = {
  userID: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationLogoutArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type MutationResetPasswordArgs = {
  accountID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationToggleUserActivationArgs = {
  userID: Scalars['String'];
};


export type MutationTotpValidateArgs = {
  token: Scalars['String'];
};


export type MutationUploadProfilePicArgs = {
  file?: Maybe<Scalars['Upload']>;
  userID: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResendInvitationArgs = {
  userID?: Maybe<Scalars['String']>;
};


export type MutationCreateApAdvanceArgs = {
  itemInput?: Maybe<Array<ApAdvanceInput>>;
  input: ApAdvanceInput;
};


export type MutationUpdateApAdvanceArgs = {
  itemInput?: Maybe<Array<ApAdvanceInput>>;
  input: ApAdvanceInput;
};


export type MutationDeleteApAdvanceArgs = {
  AdvanceID: Scalars['String'];
};


export type MutationPostApAdvanceArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  AdvanceIDs: Array<Scalars['String']>;
};


export type MutationCancelApAdvanceArgs = {
  input: ApAdvanceDeleteInput;
};


export type MutationRejectApAdvanceArgs = {
  input: ApAdvanceRejectInput;
  AdvanceIDs: Array<Scalars['String']>;
};


export type MutationGeneratePaymentArgs = {
  input: ApPaymentInput;
};


export type MutationCreateArAdvanceArgs = {
  itemInput?: Maybe<Array<ArAdvanceInput>>;
  input: ArAdvanceInput;
};


export type MutationUpdateArAdvanceArgs = {
  itemInput?: Maybe<Array<ArAdvanceInput>>;
  input: ArAdvanceInput;
};


export type MutationDeleteArAdvanceArgs = {
  AdvanceID: Scalars['String'];
};


export type MutationPostArAdvanceArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  AdvanceIDs: Array<Scalars['String']>;
};


export type MutationCancelArAdvanceArgs = {
  input: ArAdvanceDeleteInput;
};


export type MutationRejectArAdvanceArgs = {
  input: ArAdvanceRejectInput;
  AdvanceIDs: Array<Scalars['String']>;
};


export type MutationCreateAllocationArgs = {
  entityID?: Maybe<Scalars['String']>;
  input?: Maybe<Array<AllocationInput>>;
};


export type MutationUpdateApBatchPaymentArgs = {
  itemInput?: Maybe<Array<ApBatchPaymentInput>>;
  input: ApBatchPaymentInput;
};


export type MutationDeleteApBatchPaymentArgs = {
  BatchPaymentID: Scalars['String'];
};


export type MutationCancelApBatchPaymentArgs = {
  input: ApBatchPaymentDeleteInput;
};


export type MutationCreateApBatchPaymentWithAllocationArgs = {
  allocationInput?: Maybe<Array<AllocationInput>>;
  input: ApBatchPaymentInput;
};


export type MutationUpdateApBatchPaymentWithAllocationArgs = {
  allocationInput?: Maybe<Array<AllocationInput>>;
  input: ApBatchPaymentInput;
};


export type MutationPayApBatchPaymentArgs = {
  RefTable?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  allocationInput?: Maybe<Array<AllocationInput>>;
  BatchPaymentID: Scalars['String'];
};


export type MutationDeleteBatchPaymentArgs = {
  BatchPaymentID: Scalars['String'];
};


export type MutationRejectBatchPaymentArgs = {
  BatchPaymentID: Scalars['String'];
};


export type MutationCreateApCreditNoteArgs = {
  allocationInput?: Maybe<Array<ApAllocationInput>>;
  itemInput?: Maybe<Array<ApCreditNoteItemInput>>;
  input: ApCreditNoteInput;
};


export type MutationUpdateApCreditNoteArgs = {
  allocationInput?: Maybe<Array<ApAllocationInput>>;
  itemInput?: Maybe<Array<ApCreditNoteItemInput>>;
  input: ApCreditNoteInput;
};


export type MutationDeleteApCreditNoteArgs = {
  CreditNoteID: Scalars['String'];
};


export type MutationPostApCreditNoteArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  CreditNoteIDs: Array<Scalars['String']>;
};


export type MutationCancelApCreditNoteArgs = {
  input: ApCreditNoteDeleteInput;
};


export type MutationRejectApCreditNoteArgs = {
  input: ApCreditNoteRejectInput;
  CreditNoteIDs: Array<Scalars['String']>;
};


export type MutationCreateArCreditNoteArgs = {
  allocationInput?: Maybe<Array<ArAllocationInput>>;
  itemInput?: Maybe<Array<ArCreditNoteItemInput>>;
  input: ArCreditNoteInput;
};


export type MutationUpdateArCreditNoteArgs = {
  allocationInput?: Maybe<Array<ArAllocationInput>>;
  itemInput?: Maybe<Array<ArCreditNoteItemInput>>;
  input: ArCreditNoteInput;
};


export type MutationDeleteArCreditNoteArgs = {
  CreditNoteID: Scalars['String'];
};


export type MutationPostArCreditNoteArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  CreditNoteIDs: Array<Scalars['String']>;
};


export type MutationCancelArCreditNoteArgs = {
  input: ArCreditNoteDeleteInput;
};


export type MutationRejectArCreditNoteArgs = {
  input: ArCreditNoteRejectInput;
  CreditNoteIDs: Array<Scalars['String']>;
};


export type MutationCreateApCreditorCreditNoteArgs = {
  allocationInput?: Maybe<Array<ApAllocationInput>>;
  itemInput?: Maybe<Array<ApCreditorCreditNoteItemInput>>;
  input: ApCreditorCreditNoteInput;
};


export type MutationUpdateApCreditorCreditNoteArgs = {
  allocationInput?: Maybe<Array<ApAllocationInput>>;
  itemInput?: Maybe<Array<ApCreditorCreditNoteItemInput>>;
  input: ApCreditorCreditNoteInput;
};


export type MutationDeleteApCreditorCreditNoteArgs = {
  CreditorCreditNoteID: Scalars['String'];
};


export type MutationPostApCreditorCreditNoteArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  CreditorCreditNoteIDs: Array<Scalars['String']>;
};


export type MutationRejectApCreditorCreditNoteArgs = {
  input: ApCreditorCreditNoteRejectInput;
  CreditorCreditNoteIDs: Array<Scalars['String']>;
};


export type MutationCreateApCreditNoteItemArgs = {
  input: ApCreditNoteItemInput;
};


export type MutationUpdateApCreditNoteItemArgs = {
  input: ApCreditNoteItemInput;
};


export type MutationDeleteApCreditNoteItemArgs = {
  input: ApCreditNoteItemDeleteInput;
};


export type MutationCancelApCreditNoteItemArgs = {
  input: ApCreditNoteItemDeleteInput;
};


export type MutationCreateApDebitNoteArgs = {
  itemInput?: Maybe<Array<ApDebitNoteItemInput>>;
  input: ApDebitNoteInput;
};


export type MutationUpdateApDebitNoteArgs = {
  itemInput?: Maybe<Array<ApDebitNoteItemInput>>;
  input: ApDebitNoteInput;
};


export type MutationDeleteApDebitNoteArgs = {
  DebitNoteID: Scalars['String'];
};


export type MutationPostApDebitNoteArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  DebitNoteIDs: Array<Scalars['String']>;
};


export type MutationCancelApDebitNoteArgs = {
  input: ApDebitNoteDeleteInput;
};


export type MutationRejectApDebitNoteArgs = {
  input: ApDebitNoteRejectInput;
  DebitNoteIDs: Array<Scalars['String']>;
};


export type MutationCreateArDebitNoteArgs = {
  itemInput?: Maybe<Array<ArDebitNoteItemInput>>;
  input: ArDebitNoteInput;
};


export type MutationUpdateArDebitNoteArgs = {
  itemInput?: Maybe<Array<ArDebitNoteItemInput>>;
  input: ArDebitNoteInput;
};


export type MutationDeleteArDebitNoteArgs = {
  DebitNoteID: Scalars['String'];
};


export type MutationPostArDebitNoteArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  DebitNoteIDs: Array<Scalars['String']>;
};


export type MutationCancelArDebitNoteArgs = {
  input: ArDebitNoteDeleteInput;
};


export type MutationRejectArDebitNoteArgs = {
  input: ArDebitNoteRejectInput;
  DebitNoteIDs: Array<Scalars['String']>;
};


export type MutationCreateApCreditorDebitNoteArgs = {
  itemInput?: Maybe<Array<ApCreditorDebitNoteItemInput>>;
  input: ApCreditorDebitNoteInput;
};


export type MutationUpdateApCreditorDebitNoteArgs = {
  itemInput?: Maybe<Array<ApCreditorDebitNoteItemInput>>;
  input: ApCreditorDebitNoteInput;
};


export type MutationDeleteApCreditorDebitNoteArgs = {
  CreditorDebitNoteID: Scalars['String'];
};


export type MutationPostApCreditorDebitNoteArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  CreditorDebitNoteIDs: Array<Scalars['String']>;
};


export type MutationCancelApCreditorDebitNoteArgs = {
  input: ApCreditorDebitNoteDeleteInput;
};


export type MutationRejectApCreditorDebitNoteArgs = {
  input: ApCreditorDebitNoteRejectInput;
  CreditorDebitNoteIDs: Array<Scalars['String']>;
};


export type MutationCreateApDebitNoteItemArgs = {
  input: ApDebitNoteItemInput;
};


export type MutationUpdateApDebitNoteItemArgs = {
  input: ApDebitNoteItemInput;
};


export type MutationDeleteApDebitNoteItemArgs = {
  input: ApDebitNoteItemDeleteInput;
};


export type MutationCancelApDebitNoteItemArgs = {
  input: ApDebitNoteItemDeleteInput;
};


export type MutationCreateArDebitNoteItemArgs = {
  input: ArDebitNoteItemInput;
};


export type MutationUpdateArDebitNoteItemArgs = {
  input: ArDebitNoteItemInput;
};


export type MutationDeleteArDebitNoteItemArgs = {
  input: ArDebitNoteItemDeleteInput;
};


export type MutationCancelArDebitNoteItemArgs = {
  input: ArDebitNoteItemDeleteInput;
};


export type MutationDeleteApFavoriteInvoiceArgs = {
  FavoriteInvoiceID: Scalars['String'];
};


export type MutationCreateApFavoriteInvoiceArgs = {
  APFavoriteInvoiceItemInput?: Maybe<Array<ApFavoriteInvoiceItemInput>>;
  APFavoriteInvoiceInput: ApFavoriteInvoiceInput;
};


export type MutationDeleteArFavoriteInvoiceArgs = {
  FavoriteInvoiceID: Scalars['String'];
};


export type MutationCreateArFavoriteInvoiceArgs = {
  ARFavoriteInvoiceItemInput?: Maybe<Array<ArFavoriteInvoiceItemInput>>;
  ARFavoriteInvoiceInput: ArFavoriteInvoiceInput;
};


export type MutationCreateApFavoritePaymentArgs = {
  APFavoritePaymentInput: ApFavoritePaymentInput;
};


export type MutationDeleteApFavoritePaymentArgs = {
  FavoritePaymentID: Scalars['String'];
};


export type MutationCreateApInvoiceArgs = {
  itemInput?: Maybe<Array<ApInvoiceItemInput>>;
  input: ApInvoiceInput;
};


export type MutationUpdateApInvoiceArgs = {
  itemInput?: Maybe<Array<ApInvoiceItemInput>>;
  input: ApInvoiceInput;
};


export type MutationDeleteApInvoiceArgs = {
  InvoiceID: Scalars['String'];
};


export type MutationPostApInvoiceArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  InvoiceIDs: Array<Scalars['String']>;
};


export type MutationCancelApInvoiceArgs = {
  input: ApInvoiceDeleteInput;
};


export type MutationRejectApInvoiceArgs = {
  input: ApInvoiceRejectInput;
  APInvoiceIDs: Array<Scalars['String']>;
};


export type MutationCreateArInvoiceArgs = {
  itemInput?: Maybe<Array<ArInvoiceItemInput>>;
  input: ArInvoiceInput;
};


export type MutationUpdateArInvoiceArgs = {
  itemInput?: Maybe<Array<ArInvoiceItemInput>>;
  input: ArInvoiceInput;
};


export type MutationDeleteArInvoiceArgs = {
  InvoiceID: Scalars['String'];
};


export type MutationPostArInvoiceArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  InvoiceIDs: Array<Scalars['String']>;
};


export type MutationRejectArInvoiceArgs = {
  input: ArInvoiceRejectInput;
  ARInvoiceIDs: Array<Scalars['String']>;
};


export type MutationCreateApInvoiceItemArgs = {
  input: ApInvoiceItemInput;
};


export type MutationUpdateApInvoiceItemArgs = {
  input: ApInvoiceItemInput;
};


export type MutationDeleteApInvoiceItemArgs = {
  input: ApInvoiceItemDeleteInput;
};


export type MutationCancelApInvoiceItemArgs = {
  input: ApInvoiceItemDeleteInput;
};


export type MutationCreateArInvoiceItemArgs = {
  input: ArInvoiceItemInput;
};


export type MutationUpdateArInvoiceItemArgs = {
  input: ArInvoiceItemInput;
};


export type MutationDeleteArInvoiceItemArgs = {
  input: ArInvoiceItemDeleteInput;
};


export type MutationCancelArInvoiceItemArgs = {
  input: ArInvoiceItemDeleteInput;
};


export type MutationCancelArOfficialReceiptArgs = {
  input: ArOfficialReceiptDeleteInput;
};


export type MutationCreateArOfficialReceiptArgs = {
  allocationInput?: Maybe<Array<ArAllocationInput>>;
  itemInput?: Maybe<Array<ArOfficialReceiptInput>>;
  input: ArOfficialReceiptInput;
};


export type MutationUpdateArOfficialReceiptArgs = {
  allocationInput?: Maybe<Array<ArAllocationInput>>;
  itemInput?: Maybe<Array<ArOfficialReceiptInput>>;
  input: ArOfficialReceiptInput;
};


export type MutationDeleteArOfficialReceiptArgs = {
  ReceiptID: Scalars['String'];
};


export type MutationPostArOfficialReceiptArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  ReceiptIDs: Array<Scalars['String']>;
};


export type MutationRejectArOfficialReceiptArgs = {
  input: ArOfficialReceiptRejectInput;
  ReceiptIDs: Array<Scalars['String']>;
};


export type MutationCancelApPaymentArgs = {
  input: ApPaymentDeleteInput;
};


export type MutationCreateApPaymentArgs = {
  allocationInput?: Maybe<Array<AllocationInput>>;
  input: ApPaymentInput;
};


export type MutationUpdateApPaymentArgs = {
  allocationInput?: Maybe<Array<AllocationInput>>;
  input: ApPaymentInput;
};


export type MutationDeleteApPaymentArgs = {
  PaymentID: Scalars['String'];
};


export type MutationPostApPaymentArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PaymentIDs: Array<Scalars['String']>;
};


export type MutationRejectApPaymentArgs = {
  input: ApPaymentRejectInput;
  PaymentIDs: Array<Scalars['String']>;
};


export type MutationCancelApRefundArgs = {
  input: ApRefundDeleteInput;
};


export type MutationCreateApRefundArgs = {
  allocationInput?: Maybe<Array<ApAllocationInput>>;
  itemInput?: Maybe<Array<ApRefundInput>>;
  input: ApRefundInput;
};


export type MutationUpdateApRefundArgs = {
  allocationInput?: Maybe<Array<ApAllocationInput>>;
  itemInput?: Maybe<Array<ApRefundInput>>;
  input: ApRefundInput;
};


export type MutationDeleteApRefundArgs = {
  RefundID: Scalars['String'];
};


export type MutationPostApRefundArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  RefundIDs: Array<Scalars['String']>;
};


export type MutationRejectApRefundArgs = {
  input: ApRefundRejectInput;
  RefundIDs: Array<Scalars['String']>;
};


export type MutationCancelArRefundArgs = {
  input: ArRefundDeleteInput;
};


export type MutationCreateArRefundArgs = {
  allocationInput?: Maybe<Array<AllocationInput>>;
  input: ArRefundInput;
};


export type MutationDeleteArRefundArgs = {
  RefundID: Scalars['String'];
};


export type MutationUpdateArRefundArgs = {
  allocationInput?: Maybe<Array<AllocationInput>>;
  input: ArRefundInput;
};


export type MutationPostArRefundArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  RefundIDs: Array<Scalars['String']>;
};


export type MutationRejectArRefundArgs = {
  input: ArRefundRejectInput;
  RefundIDs: Array<Scalars['String']>;
};


export type MutationCreateApSelfBilledArgs = {
  itemInput?: Maybe<Array<ApSelfBilledItemInput>>;
  input: ApSelfBilledInput;
};


export type MutationUpdateApSelfBilledArgs = {
  itemInput?: Maybe<Array<ApSelfBilledItemInput>>;
  input: ApSelfBilledInput;
};


export type MutationDeleteApSelfBilledArgs = {
  SelfBilledID: Scalars['String'];
};


export type MutationPostApSelfBilledArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  SelfBilledIDs: Array<Scalars['String']>;
};


export type MutationCancelApSelfBilledArgs = {
  input: ApSelfBilledDeleteInput;
};


export type MutationRejectApSelfBilledArgs = {
  input: ApSelfBilledRejectInput;
  SelfBilledIDs: Array<Scalars['String']>;
};


export type MutationCancelEinvoiceArgs = {
  CompanyID: Scalars['String'];
  SelfBilledID: Scalars['String'];
};


export type MutationGetShareAttachmentArgs = {
  file: Scalars['Upload'];
  CompanyID: Scalars['String'];
};


export type MutationSingleUploadArgs = {
  file: Scalars['Upload'];
};


export type MutationCreateBankReconciliationArgs = {
  input: BankReconciliationInput;
};


export type MutationUpdateBankReconciliationArgs = {
  input: BankReconciliationInput;
};


export type MutationCancelBankReconciliationArgs = {
  input: BankReconciliationDeleteInput;
};


export type MutationCreateImportBankReconciliationArgs = {
  IsExcelMatch: Scalars['Boolean'];
  StatementBalance: Scalars['Float'];
  Description: Scalars['String'];
  Date: Scalars['DateTime'];
  BankAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  BankReconciliationItemImportInput: Array<BankReconciliationItemImportInput>;
};


export type MutationUpdateImportBankReconciliationArgs = {
  IsExcelMatch: Scalars['Boolean'];
  StatementBalance: Scalars['Float'];
  Description: Scalars['String'];
  Date: Scalars['DateTime'];
  BankAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  BankReconciliationID: Scalars['String'];
  BankReconciliationItemImportInput: Array<BankReconciliationItemImportInput>;
};


export type MutationCreateBankReconciliationWihoutExcelArgs = {
  IsExcelMatch: Scalars['Boolean'];
  StatementBalance: Scalars['Float'];
  Description: Scalars['String'];
  Date: Scalars['DateTime'];
  BankAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type MutationUpdateBankReconciliationWihoutExcelArgs = {
  IsExcelMatch: Scalars['Boolean'];
  StatementBalance: Scalars['Float'];
  Description: Scalars['String'];
  Date: Scalars['DateTime'];
  BankAccountID: Scalars['String'];
  BankReconciliationID: Scalars['String'];
};


export type MutationMatchBankReconciliationArgs = {
  Mode: Scalars['String'];
  LedgerIDs: Array<Scalars['String']>;
  BankReconItemIDs?: Maybe<Array<Scalars['String']>>;
  input: BankReconciliationMatchInput;
};


export type MutationDeleteBankReconciliationArgs = {
  BankReconciliationID: Scalars['String'];
};


export type MutationCreateBankReconciliationItemArgs = {
  input: BankReconciliationItemInput;
};


export type MutationUpdateBankReconciliationItemArgs = {
  input: BankReconciliationItemInput;
};


export type MutationDeleteBankReconciliationItemArgs = {
  input: BankReconciliationItemDeleteInput;
};


export type MutationCancelBankReconciliationItemArgs = {
  input: BankReconciliationItemDeleteInput;
};


export type MutationCreateBankReconciliationMatchArgs = {
  input: BankReconciliationMatchInput;
};


export type MutationUpdateBankReconciliationMatchArgs = {
  input: BankReconciliationMatchInput;
};


export type MutationDeleteBankReconciliationMatchArgs = {
  input: BankReconciliationMatchDeleteInput;
};


export type MutationCancelBankReconciliationMatchArgs = {
  input: BankReconciliationMatchDeleteInput;
};


export type MutationCreateBankTransferArgs = {
  itemInput?: Maybe<Array<BankTransferInput>>;
  input: BankTransferInput;
};


export type MutationUpdateBankTransferArgs = {
  itemInput?: Maybe<Array<BankTransferInput>>;
  input: BankTransferInput;
};


export type MutationDeleteBankTransferArgs = {
  BankTransferID: Scalars['String'];
};


export type MutationCancelBankTransferArgs = {
  input: BankTransferDeleteInput;
};


export type MutationPostCbBankTransferArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  BankTransferIDs: Array<Scalars['String']>;
};


export type MutationRejectBankTransferArgs = {
  input: BankTransferRejectInput;
  BankTransferIDs: Array<Scalars['String']>;
};


export type MutationCreateExternalLedgerArgs = {
  DocRefName: Scalars['String'];
  CompanyID: Scalars['String'];
  ExternalLedgerImportInput: Scalars['String'];
};


export type MutationCbImportLedgerArgs = {
  input: Array<CbImportLedgerInput>;
};


export type MutationDeleteFavoritePaymentArgs = {
  FavoritePaymentID: Scalars['String'];
};


export type MutationCreateFavoritePaymentArgs = {
  FavoritePaymentItemInput?: Maybe<Array<FavoritePaymentItemInput>>;
  FavoritePaymentInput: FavoritePaymentInput;
};


export type MutationDeleteFavoriteReceiptArgs = {
  FavoriteReceiptID: Scalars['String'];
};


export type MutationCreateFavoriteReceiptArgs = {
  FavoriteReceiptItemInput?: Maybe<Array<FavoriteReceiptItemInput>>;
  FavoriteReceiptInput: FavoriteReceiptInput;
};


export type MutationCreateLedgerArgs = {
  input: LedgerInput;
};


export type MutationUpdateLedgerArgs = {
  input: LedgerInput;
};


export type MutationDeleteLedgerArgs = {
  input: LedgerDeleteInput;
};


export type MutationCancelLedgerArgs = {
  input: LedgerDeleteInput;
};


export type MutationCreateLedgerItemArgs = {
  input: LedgerItemInput;
};


export type MutationUpdateLedgerItemArgs = {
  input: LedgerItemInput;
};


export type MutationDeleteLedgerItemArgs = {
  input: LedgerItemDeleteInput;
};


export type MutationCancelLedgerItemArgs = {
  input: LedgerItemDeleteInput;
};


export type MutationCreatePaymentArgs = {
  itemInput?: Maybe<Array<PaymentItemInput>>;
  input: PaymentInput;
};


export type MutationUpdatePaymentArgs = {
  itemInput?: Maybe<Array<PaymentItemInput>>;
  input: PaymentInput;
};


export type MutationDeletePaymentArgs = {
  PaymentID: Scalars['String'];
};


export type MutationCancelPaymentArgs = {
  input: PaymentDeleteInput;
};


export type MutationPostCbPaymentArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PaymentIDs: Array<Scalars['String']>;
};


export type MutationRejectPaymentArgs = {
  input: PaymentRejectInput;
  PaymentIDs: Array<Scalars['String']>;
};


export type MutationCreatePaymentItemArgs = {
  input: PaymentItemInput;
};


export type MutationUpdatePaymentItemArgs = {
  input: PaymentItemInput;
};


export type MutationDeletePaymentItemArgs = {
  input: PaymentItemDeleteInput;
};


export type MutationCancelPaymentItemArgs = {
  input: PaymentItemDeleteInput;
};


export type MutationCreateReceiptArgs = {
  itemInput?: Maybe<Array<ReceiptItemInput>>;
  input: ReceiptInput;
};


export type MutationUpdateReceiptArgs = {
  itemInput?: Maybe<Array<ReceiptItemInput>>;
  input: ReceiptInput;
};


export type MutationDeleteReceiptArgs = {
  ReceiptID: Scalars['String'];
};


export type MutationCancelReceiptArgs = {
  input: ReceiptDeleteInput;
};


export type MutationPostCbReceiptArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  ReceiptIDs: Array<Scalars['String']>;
};


export type MutationRejectReceiptArgs = {
  input: ReceiptRejectInput;
  ReceiptIDs: Array<Scalars['String']>;
};


export type MutationCreateReceiptItemArgs = {
  input: ReceiptItemInput;
};


export type MutationUpdateReceiptItemArgs = {
  input: ReceiptItemInput;
};


export type MutationDeleteReceiptItemArgs = {
  input: ReceiptItemDeleteInput;
};


export type MutationCancelReceiptItemArgs = {
  input: ReceiptItemDeleteInput;
};


export type MutationDeleteCompanyLetterTemplateArgs = {
  input: CompanyLetterTemplateDeleteInput;
};


export type MutationCancelCompanyLetterTemplateArgs = {
  input: CompanyLetterTemplateDeleteInput;
};


export type MutationCreateCompanyLetterTemplateArgs = {
  input: CompanyLetterTemplateInput;
};


export type MutationUpdateCompanyLetterTemplateArgs = {
  input: CompanyLetterTemplateInput;
};


export type MutationDeleteLetterTemplateArgs = {
  input: LetterTemplateDeleteInput;
};


export type MutationCancelLetterTemplateArgs = {
  input: LetterTemplateDeleteInput;
};


export type MutationCreateLetterTemplateArgs = {
  input: LetterTemplateInput;
};


export type MutationUpdateLetterTemplateArgs = {
  input: LetterTemplateInput;
};


export type MutationCreateStandardLetterDataSourceArgs = {
  input: StandardLetterDataSourceInput;
};


export type MutationUpdateStandardLetterDataSourceArgs = {
  input: StandardLetterDataSourceInput;
};


export type MutationDeleteStandardLetterDataSourceArgs = {
  input: StandardLetterDataSourceDeleteInput;
};


export type MutationCancelStandardLetterDataSourceArgs = {
  input: StandardLetterDataSourceDeleteInput;
};


export type MutationCreateAuditAdjustmentArgs = {
  itemInput?: Maybe<Array<AuditAdjustmentItemInput>>;
  input: AuditAdjustmentInput;
};


export type MutationUpdateAuditAdjustmentArgs = {
  itemInput?: Maybe<Array<AuditAdjustmentItemInput>>;
  input: AuditAdjustmentInput;
};


export type MutationDeleteAuditAdjustmentArgs = {
  AuditAdjustmentID: Scalars['String'];
};


export type MutationCancelAuditAdjustmentArgs = {
  input: AuditAdjustmentDeleteInput;
};


export type MutationPostAuditAdjustmentArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  AuditAdjustmentIDs: Array<Scalars['String']>;
};


export type MutationRejectAuditAdjustmentArgs = {
  input: AuditAdjustmentRejectInput;
  AuditAdjustmentIDs: Array<Scalars['String']>;
};


export type MutationCreateAuditAdjustmentItemArgs = {
  input: AuditAdjustmentItemInput;
};


export type MutationUpdateAuditAdjustmentItemArgs = {
  input: AuditAdjustmentItemInput;
};


export type MutationDeleteAuditAdjustmentItemArgs = {
  input: AuditAdjustmentItemDeleteInput;
};


export type MutationCancelAuditAdjustmentItemArgs = {
  input: AuditAdjustmentItemDeleteInput;
};


export type MutationCreateBudgetArgs = {
  input: BudgetInput;
};


export type MutationUpdateBudgetArgs = {
  input: BudgetInput;
};


export type MutationDeleteBudgetArgs = {
  input: BudgetDeleteInput;
};


export type MutationCancelBudgetArgs = {
  input: BudgetDeleteInput;
};


export type MutationCreateBudgetWithDetailArgs = {
  BudgetItemPeriodInput?: Maybe<Array<BudgetItemPeriodInput>>;
  BudgetItemCostCentreInput?: Maybe<Array<BudgetItemCostCentreInput>>;
  BudgetItemInput: Array<BudgetItemInput>;
  BudgetInput: BudgetInput;
};


export type MutationUpdateBudgetWithDetailArgs = {
  BudgetItemPeriodInput?: Maybe<Array<BudgetItemPeriodInput>>;
  BudgetItemCostCentreInput?: Maybe<Array<BudgetItemCostCentreInput>>;
  BudgetItemInput: Array<BudgetItemInput>;
  BudgetInput: BudgetInput;
};


export type MutationSetBudgetDefaultArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['String'];
  BudgetID: Scalars['String'];
};


export type MutationCreateBudgetItemArgs = {
  input: BudgetItemInput;
};


export type MutationUpdateBudgetItemArgs = {
  input: BudgetItemInput;
};


export type MutationDeleteBudgetItemArgs = {
  input: BudgetItemDeleteInput;
};


export type MutationCancelBudgetItemArgs = {
  input: BudgetItemDeleteInput;
};


export type MutationCreateBudgetItemCostCentreArgs = {
  input: BudgetItemCostCentreInput;
};


export type MutationUpdateBudgetItemCostCentreArgs = {
  input: BudgetItemCostCentreInput;
};


export type MutationDeleteBudgetItemCostCentreArgs = {
  input: BudgetItemCostCentreDeleteInput;
};


export type MutationCancelBudgetItemCostCentreArgs = {
  input: BudgetItemCostCentreInput;
};


export type MutationCreateBudgetItemPeriodArgs = {
  input: BudgetItemPeriodInput;
};


export type MutationUpdateBudgetItemPeriodArgs = {
  input: BudgetItemPeriodInput;
};


export type MutationDeleteBudgetItemPeriodArgs = {
  input: BudgetItemPeriodDeleteInput;
};


export type MutationCancelBudgetItemPeriodArgs = {
  input: BudgetItemPeriodInput;
};


export type MutationCreateExternalJournalArgs = {
  DocRefName: Scalars['String'];
  CompanyID: Scalars['String'];
  ExtJournalInput: ExcelJournalInput;
};


export type MutationDeleteFavoriteJournalProcessingArgs = {
  FavoriteJournalProcessingID: Scalars['String'];
};


export type MutationCreateFavoriteJournalProcessingArgs = {
  FavoriteJournalProcessingItemInput?: Maybe<Array<FavoriteJournalProcessingItemInput>>;
  FavoriteJournalProcessingInput: FavoriteJournalProcessingInput;
};


export type MutationCreateDataSourceSettingsArgs = {
  input: DataSourceSettingsInput;
};


export type MutationUpdateDataSourceSettingsArgs = {
  input: DataSourceSettingsInput;
};


export type MutationDeleteDataSourceSettingsArgs = {
  input: DataSourceSettingsDeleteInput;
};


export type MutationCancelDataSourceSettingsArgs = {
  input: DataSourceSettingsDeleteInput;
};


export type MutationCreateReportSettingsArgs = {
  input: ReportSettingsInput;
};


export type MutationUpdateReportSettingsArgs = {
  input: ReportSettingsInput;
};


export type MutationDeleteReportSettingsArgs = {
  input: ReportSettingsDeleteInput;
};


export type MutationCancelReportSettingsArgs = {
  input: ReportSettingsDeleteInput;
};


export type MutationUploadReportTemplateArgs = {
  file: Scalars['Upload'];
  ReportSettingsID: Scalars['String'];
};


export type MutationUpdateJournalArgs = {
  JournalItemInput: Array<JournalItemInput>;
  JournalInput: JournalInput;
};


export type MutationDeleteJournalArgs = {
  input: JournalDeleteInput;
};


export type MutationCancelJournalArgs = {
  input: JournalDeleteInput;
};


export type MutationCreateJournalArgs = {
  JournalItemInput: Array<JournalItemInput>;
  JournalInput: JournalInput;
};


export type MutationGlImportLedgerArgs = {
  input: Array<GlImportLedgerInput>;
};


export type MutationCreateJournalItemArgs = {
  input: JournalItemInput;
};


export type MutationUpdateJournalItemArgs = {
  input: JournalItemInput;
};


export type MutationDeleteJournalItemArgs = {
  input: JournalItemDeleteInput;
};


export type MutationCancelJournalItemArgs = {
  input: JournalItemDeleteInput;
};


export type MutationCreateJournalProcessingArgs = {
  itemInput?: Maybe<Array<JournalProcessingItemInput>>;
  input: JournalProcessingInput;
};


export type MutationUpdateJournalProcessingArgs = {
  itemInput?: Maybe<Array<JournalProcessingItemInput>>;
  input: JournalProcessingInput;
};


export type MutationDeleteJournalProcessingArgs = {
  JournalProcessingID: Scalars['String'];
};


export type MutationCancelJournalProcessingArgs = {
  input: JournalProcessingDeleteInput;
};


export type MutationPostJournalProcessingArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  JournalProcessingIDs: Array<Scalars['String']>;
};


export type MutationRejectJournalProcessingArgs = {
  input: JournalProcessingRejectInput;
  JournalProcessingIDs: Array<Scalars['String']>;
};


export type MutationProcessMonthEndClosingArgs = {
  AccountPeriodID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type MutationRevertMonthEndClosingArgs = {
  AccountPeriodID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type MutationRevertYearEndClosingArgs = {
  AccountPeriodID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type MutationProcessYearEndClosingArgs = {
  FYear: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type MutationCreateRecurringJournalArgs = {
  itemInput?: Maybe<Array<RecurringJournalItemInput>>;
  input: RecurringJournalInput;
};


export type MutationUpdateRecurringJournalArgs = {
  itemInput?: Maybe<Array<RecurringJournalItemInput>>;
  input: RecurringJournalInput;
};


export type MutationDeleteRecurringJournalArgs = {
  RecurringJournalID: Scalars['String'];
};


export type MutationCancelRecurringJournalArgs = {
  RecurringJournalID: Scalars['String'];
};


export type MutationPostRecurringJournalArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  RecurringJournalIDs: Array<Scalars['String']>;
};


export type MutationRejectRecurringJournalArgs = {
  input: RecurringJournalRejectInput;
  RecurringJournalIDs: Array<Scalars['String']>;
};


export type MutationGenerateRecurringJournalArgs = {
  AccountPeriodID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type MutationCreateRecurringJournalItemArgs = {
  input: RecurringJournalItemInput;
};


export type MutationUpdateRecurringJournalItemArgs = {
  input: RecurringJournalItemInput;
};


export type MutationDeleteRecurringJournalItemArgs = {
  input: RecurringJournalItemDeleteInput;
};


export type MutationCancelRecurringJournalItemArgs = {
  input: RecurringJournalItemDeleteInput;
};


export type MutationUpdateRoutineJournalArgs = {
  input: RoutineJournalInput;
};


export type MutationDeleteRoutineJournalArgs = {
  input: RoutineJournalDeleteInput;
};


export type MutationCancelRoutineJournalArgs = {
  input: RoutineJournalDeleteInput;
};


export type MutationCreateRoutineJournalArgs = {
  RoutineJournalItemInput: Array<RoutineJournalItemInput>;
  RoutineJournalInput: RoutineJournalInput;
};


export type MutationUpdateRoutineJournalsArgs = {
  RoutineItemInput: Array<RoutineJournalItemInput>;
  RoutineJournalInput: RoutineJournalInput;
  CompanyID: Scalars['String'];
  RoutineJournalID: Scalars['String'];
};


export type MutationCreateRoutineJournalItemArgs = {
  input: RoutineJournalItemInput;
};


export type MutationUpdateRoutineJournalItemArgs = {
  input: RoutineJournalItemInput;
};


export type MutationDeleteRoutineJournalItemArgs = {
  input: RoutineJournalItemDeleteInput;
};


export type MutationCancelRoutineJournalItemArgs = {
  input: RoutineJournalItemDeleteInput;
};


export type MutationPostGlExportArgs = {
  prefix: Scalars['String'];
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  GLDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type MutationCreateGlExportArgs = {
  prefix: Scalars['String'];
  CompanyID: Scalars['String'];
  GLDate: Scalars['DateTime'];
  input?: Maybe<Array<GlExportInput>>;
};


export type MutationDeleteCycleCountArgs = {
  CycleCountID: Scalars['String'];
};


export type MutationCancelCycleCountArgs = {
  input: CycleCountDeleteInput;
};


export type MutationCreateCycleCountArgs = {
  itemInput?: Maybe<Array<CycleCountItemInput>>;
  input: CycleCountInput;
};


export type MutationUpdateCycleCountArgs = {
  itemInput?: Maybe<Array<CycleCountItemInput>>;
  input: CycleCountInput;
};


export type MutationPostCycleCountArgs = {
  input: CycleCountRejectInput;
  CompanyID: Scalars['String'];
  ApprovalStatus: Scalars['String'];
  postIDs: Array<Scalars['String']>;
};


export type MutationCreateStockReconArgs = {
  itemInput?: Maybe<Array<StockReconItemInput>>;
  input: StockReconInput;
};


export type MutationCreateCycleCountItemArgs = {
  input: CycleCountItemInput;
};


export type MutationUpdateCycleCountItemArgs = {
  input: CycleCountItemInput;
};


export type MutationDeleteCycleCountItemArgs = {
  input: CycleCountItemDeleteInput;
};


export type MutationCancelCycleCountItemArgs = {
  input: CycleCountItemDeleteInput;
};


export type MutationCreateFavoriteStockRequisitionArgs = {
  FavoriteStockRequisitionItemInput?: Maybe<Array<FavoriteStockRequisitionItemInput>>;
  FavoriteStockRequisitionInput: FavoriteStockRequisitionInput;
};


export type MutationDeleteFavoriteStockRequisitionArgs = {
  FavoriteStockRequisitionID: Scalars['String'];
};


export type MutationCreateInventoryPolicyArgs = {
  input: InventoryPolicyInput;
};


export type MutationUpdateInventoryPolicyArgs = {
  input: InventoryPolicyInput;
};


export type MutationDeleteInventoryPolicyArgs = {
  input: InventoryPolicyDeleteInput;
};


export type MutationCancelInventoryPolicyArgs = {
  input: InventoryPolicyDeleteInput;
};


export type MutationDeleteStockAdjustmentArgs = {
  StockAdjustmentID: Scalars['String'];
};


export type MutationCancelStockAdjustmentArgs = {
  input: StockAdjustmentDeleteInput;
};


export type MutationCreateStockAdjustmentArgs = {
  itemInput?: Maybe<Array<StockAdjustmentItemInput>>;
  input: StockAdjustmentInput;
};


export type MutationUpdateStockAdjustmentArgs = {
  itemInput?: Maybe<Array<StockAdjustmentItemInput>>;
  input: StockAdjustmentInput;
};


export type MutationPostStockAdjustmentArgs = {
  input: StockAdjustmentRejectInput;
  CompanyID: Scalars['String'];
  ApprovalStatus: Scalars['String'];
  postIDs: Array<Scalars['String']>;
};


export type MutationCreateStockAdjustmentItemArgs = {
  input: StockAdjustmentItemInput;
};


export type MutationUpdateStockAdjustmentItemArgs = {
  input: StockAdjustmentItemInput;
};


export type MutationDeleteStockAdjustmentItemArgs = {
  input: StockAdjustmentItemDeleteInput;
};


export type MutationCancelStockAdjustmentItemArgs = {
  input: StockAdjustmentItemDeleteInput;
};


export type MutationCreateStockAdjustmentReasonArgs = {
  input: StockAdjustmentReasonInput;
};


export type MutationUpdateStockAdjustmentReasonArgs = {
  input: StockAdjustmentReasonInput;
};


export type MutationDeleteStockAdjustmentReasonArgs = {
  input: StockAdjustmentReasonDeleteInput;
};


export type MutationCancelStockAdjustmentReasonArgs = {
  input: StockAdjustmentReasonDeleteInput;
};


export type MutationCancelStockCategoryArgs = {
  input: StockCategoryDeleteInput;
};


export type MutationDeleteStockCategoryArgs = {
  StockCategoryID: Scalars['String'];
};


export type MutationCreateStockCategoryArgs = {
  input: StockCategoryInput;
};


export type MutationUpdateStockCategoryArgs = {
  input: StockCategoryInput;
};


export type MutationCreateStockCostAdjustmentArgs = {
  itemInput?: Maybe<Array<StockCostAdjustmentItemInput>>;
  input: StockCostAdjustmentInput;
};


export type MutationUpdateStockCostAdjustmentArgs = {
  itemInput?: Maybe<Array<StockCostAdjustmentItemInput>>;
  input: StockCostAdjustmentInput;
};


export type MutationDeleteStockCostAdjustmentArgs = {
  StockCostAdjustmentID: Scalars['String'];
};


export type MutationCancelStockCostAdjustmentArgs = {
  input: StockCostAdjustmentDeleteInput;
};


export type MutationPostStockCostAdjustmentArgs = {
  CompanyID: Scalars['String'];
  input: StockCostAdjustmentRejectInput;
  ApprovalStatus: Scalars['String'];
  postIDs: Array<Scalars['String']>;
};


export type MutationDeleteStockIssueArgs = {
  StockIssueID: Scalars['String'];
};


export type MutationCancelStockIssueArgs = {
  input: StockIssueDeleteInput;
};


export type MutationCreateStockIssueArgs = {
  itemInput?: Maybe<Array<StockIssueItemInput>>;
  input: StockIssueInput;
};


export type MutationUpdateStockIssueArgs = {
  itemInput?: Maybe<Array<StockIssueItemInput>>;
  input: StockIssueInput;
};


export type MutationPostStockIssueArgs = {
  input: StockIssueRejectInput;
  CompanyID: Scalars['String'];
  ApprovalStatus: Scalars['String'];
  postIDs: Array<Scalars['String']>;
};


export type MutationCreateStockIssueItemArgs = {
  input: StockIssueItemInput;
};


export type MutationUpdateStockIssueItemArgs = {
  input: StockIssueItemInput;
};


export type MutationDeleteStockIssueItemArgs = {
  input: StockIssueItemDeleteInput;
};


export type MutationCancelStockIssueItemArgs = {
  input: StockIssueItemDeleteInput;
};


export type MutationDeleteStockReceiptArgs = {
  StockReceiptID: Scalars['String'];
};


export type MutationCancelStockReceiptArgs = {
  input: StockReceiptDeleteInput;
};


export type MutationCreateStockReceiptArgs = {
  itemInput?: Maybe<Array<StockReceiptItemInput>>;
  input: StockReceiptInput;
};


export type MutationUpdateStockReceiptArgs = {
  itemInput?: Maybe<Array<StockReceiptItemInput>>;
  input: StockReceiptInput;
};


export type MutationPostStockReceiptArgs = {
  input: StockReceiptRejectInput;
  CompanyID: Scalars['String'];
  ApprovalStatus: Scalars['String'];
  postIDs: Array<Scalars['String']>;
};


export type MutationRevokeStockReceiptArgs = {
  StockReceiptID: Scalars['String'];
};


export type MutationCreateStockReceiptItemArgs = {
  input: StockReceiptItemInput;
};


export type MutationUpdateStockReceiptItemArgs = {
  input: StockReceiptItemInput;
};


export type MutationDeleteStockReceiptItemArgs = {
  input: StockReceiptItemDeleteInput;
};


export type MutationCancelStockReceiptItemArgs = {
  input: StockReceiptItemDeleteInput;
};


export type MutationDeleteStockRequisitionArgs = {
  StockRequisitionID: Scalars['String'];
};


export type MutationCancelStockRequisitionArgs = {
  input: StockRequisitionCancelInput;
};


export type MutationCreateStockRequisitionArgs = {
  itemInput?: Maybe<Array<StockRequisitionItemInput>>;
  input: StockRequisitionInput;
};


export type MutationUpdateStockRequisitionArgs = {
  itemInput?: Maybe<Array<StockRequisitionItemInput>>;
  input: StockRequisitionInput;
};


export type MutationPostStockRequisitionArgs = {
  input: StockRequisitionRejectInput;
  CompanyID: Scalars['String'];
  ApprovalStatus: Scalars['String'];
  postIDs: Array<Scalars['String']>;
};


export type MutationCreateStockRequisitionItemArgs = {
  input: StockRequisitionItemInput;
};


export type MutationUpdateStockRequisitionItemArgs = {
  input: StockRequisitionItemInput;
};


export type MutationDeleteStockRequisitionItemArgs = {
  input: StockRequisitionItemDeleteInput;
};


export type MutationCancelStockRequisitionItemArgs = {
  input: StockRequisitionItemDeleteInput;
};


export type MutationDeleteStockTransferArgs = {
  StockTransferID: Scalars['String'];
};


export type MutationCancelStockTransferArgs = {
  input: StockTransferDeleteInput;
};


export type MutationCreateStockTransferArgs = {
  itemInput?: Maybe<Array<StockTransferItemInput>>;
  input: StockTransferInput;
};


export type MutationUpdateStockTransferArgs = {
  itemInput?: Maybe<Array<StockTransferItemInput>>;
  input: StockTransferInput;
};


export type MutationPostStockTransferArgs = {
  input: StockTransferRejectInput;
  CompanyID: Scalars['String'];
  ApprovalStatus: Scalars['String'];
  postIDs: Array<Scalars['String']>;
};


export type MutationCreateStockTransferItemArgs = {
  input: StockTransferItemInput;
};


export type MutationUpdateStockTransferItemArgs = {
  input: StockTransferItemInput;
};


export type MutationDeleteStockTransferItemArgs = {
  input: StockTransferItemDeleteInput;
};


export type MutationCancelStockTransferItemArgs = {
  input: StockTransferItemDeleteInput;
};


export type MutationDeleteStockTransferRequisitionArgs = {
  StockTransferRequisitionID: Scalars['String'];
};


export type MutationCreateStockTransferRequisitionArgs = {
  itemInput?: Maybe<Array<StockTransferRequisitionItemInput>>;
  input: StockTransferRequisitionInput;
};


export type MutationUpdateStockTransferRequisitionArgs = {
  itemInput?: Maybe<Array<StockTransferRequisitionItemInput>>;
  input: StockTransferRequisitionInput;
};


export type MutationPostStockTransferRequisitionArgs = {
  input: StockTransferRequisitionRejectInput;
  CompanyID: Scalars['String'];
  ApprovalStatus: Scalars['String'];
  postIDs: Array<Scalars['String']>;
};


export type MutationCancelStockTransferRequisitionArgs = {
  input: StockTransferRequisitionCancelInput;
};


export type MutationCreateStockTransferRequisitionItemArgs = {
  input: StockTransferRequisitionItemInput;
};


export type MutationUpdateStockTransferRequisitionItemArgs = {
  input: StockTransferRequisitionItemInput;
};


export type MutationDeleteStockTransferRequisitionItemArgs = {
  input: StockTransferRequisitionItemDeleteInput;
};


export type MutationCancelStockTransferRequisitionItemArgs = {
  input: StockTransferRequisitionItemDeleteInput;
};


export type MutationUpdateAccountMappingArgs = {
  input: AccountMappingInput;
};


export type MutationDeleteAccountMappingArgs = {
  input: AccountMappingDeleteInput;
};


export type MutationCancelAccountMappingArgs = {
  input: AccountMappingDeleteInput;
};


export type MutationCreateAccountMappingArgs = {
  input: AccountMappingInput;
};


export type MutationDeleteCompanyArgs = {
  input: CompanyDeleteInput;
};


export type MutationCancelCompanyArgs = {
  input: CompanyDeleteInput;
};


export type MutationCreateCompanyArgs = {
  input: CompanyInput;
};


export type MutationUpdateCompanyArgs = {
  input: CompanyInput;
};


export type MutationDeleteCompanyProfileArgs = {
  CompanyID: Scalars['String'];
};


export type MutationCreateCompanyImportArgs = {
  TemplateStr: Scalars['String'];
};


export type MutationResetGeneralRunningNumberArgs = {
  Type: DocumentType;
  AccountID: Scalars['String'];
};


export type MutationPatchApPaymentArgs = {
  AccountID: Scalars['String'];
};


export type MutationPatchNullGlRecurringDescriptionDataArgs = {
  AccountID?: Maybe<Scalars['String']>;
};


export type MutationPatchNullBaseUomInStockItemDataArgs = {
  tableName: Scalars['String'];
};


export type MutationInitialMasterFileSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationExtraSystemAdminSetupArgs = {
  AccountID: Scalars['String'];
};


export type MutationInsertAdjustmentReasonArgs = {
  AccountID: Scalars['String'];
};


export type MutationCreateSystemJournalTypeArgs = {
  CompanyIDs: Array<Scalars['String']>;
};


export type MutationCreateRolePermissionArgs = {
  permissionArr: Array<AcctPermission>;
  input: RoleInput;
};


export type MutationUpdateRolePermissionArgs = {
  permissionArr: Array<AcctPermission>;
  input: RoleInput;
};


export type MutationDuplicateRolePermissionArgs = {
  name: Scalars['String'];
  roleID: Scalars['String'];
};


export type MutationDeleteRolePermissionArgs = {
  roleID: Scalars['String'];
};


export type MutationAssignEntityRoleUserArgs = {
  input: EntityRoleUserAsgInput;
};


export type MutationUnassignEntityRoleUserArgs = {
  input: EntityRoleUserAsgInput;
};


export type MutationCreateListEntityRoleUserArgs = {
  roleUserIDs: Array<AccRoleUserInput>;
  companyID: Scalars['String'];
};


export type MutationResetRolePermissionArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteAccountPeriodArgs = {
  input: AccountPeriodDeleteInput;
};


export type MutationCancelAccountPeriodArgs = {
  input: AccountPeriodDeleteInput;
};


export type MutationCreateAccountPeriodArgs = {
  input?: Maybe<Array<AccountPeriodInput>>;
  CompanyID: Scalars['String'];
  FYear: Scalars['Float'];
};


export type MutationUpdateAccountPeriodArgs = {
  input: Array<AccountPeriodInput>;
  CompanyID: Scalars['String'];
  FYear: Scalars['Float'];
};


export type MutationCloseAccountPeriodArgs = {
  input: Array<AccountPeriodInput>;
  AccountPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
  FYear: Scalars['Float'];
};


export type MutationUpdateArAgingArgs = {
  input: AgingInput;
};


export type MutationDeleteArAgingArgs = {
  input: AgingDeleteInput;
};


export type MutationCancelArAgingArgs = {
  input: AgingDeleteInput;
};


export type MutationUpdateApAgingArgs = {
  input: AgingInput;
};


export type MutationDeleteApAgingArgs = {
  input: AgingDeleteInput;
};


export type MutationCancelApAgingArgs = {
  input: AgingDeleteInput;
};


export type MutationCreateArAgingArgs = {
  input: AgingInput;
};


export type MutationCreateApAgingArgs = {
  input: AgingInput;
};


export type MutationCancelApprovalPolicyArgs = {
  input: ApprovalPolicyDeleteInput;
};


export type MutationDeleteApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentDeleteInput;
};


export type MutationCancelApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentDeleteInput;
};


export type MutationCreateApprovalPolicyArgs = {
  input: ApprovalPolicyInput;
};


export type MutationUpdateApprovalPolicyArgs = {
  input: ApprovalPolicyInput;
};


export type MutationDeleteApprovalPolicyArgs = {
  input: ApprovalPolicyDeleteInput;
};


export type MutationCreateApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentInput;
};


export type MutationUpdateApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentInput;
};


export type MutationCreateUpdateDeleteApprovalPolicyPoArgs = {
  deleteInput?: Maybe<Array<ApprovalPolicyDeleteInput>>;
  input: Array<ApprovalPolicyInput>;
};


export type MutationCreateBankAccountArgs = {
  input: BankAccountInput;
};


export type MutationUpdateBankAccountArgs = {
  input: BankAccountInput;
};


export type MutationDeleteBankAccountArgs = {
  input: BankAccountDeleteInput;
};


export type MutationCancelBankAccountArgs = {
  input?: Maybe<BankAccountCancellationInput>;
  BankAccountID: Scalars['String'];
};


export type MutationActivateBankAccountArgs = {
  BankAccountID: Scalars['String'];
};


export type MutationCreateBankAccountImportArgs = {
  CompanyID: Scalars['String'];
  TemplateStr: Scalars['String'];
};


export type MutationCreateBankProfileArgs = {
  input: BankProfileInput;
};


export type MutationUpdateBankProfileArgs = {
  input: BankProfileInput;
};


export type MutationDeleteBankProfileArgs = {
  input: BankProfileDeleteInput;
};


export type MutationCancelBankProfileArgs = {
  input: BankProfileDeleteInput;
};


export type MutationCreateBankProfileImportArgs = {
  TemplateStr: Scalars['String'];
};


export type MutationCreateBillItemArgs = {
  input: BillItemInput;
};


export type MutationUpdateBillItemArgs = {
  input: BillItemInput;
};


export type MutationDeleteBillItemArgs = {
  input: BillItemDeleteInput;
};


export type MutationCancelBillItemArgs = {
  input: BillItemDeleteInput;
};


export type MutationUpdateCoaStructureArgs = {
  input: CoaStructureInput;
};


export type MutationDeleteCoaStructureArgs = {
  input: CoaStructureDeleteInput;
};


export type MutationCancelCoaStructureArgs = {
  input: CoaStructureDeleteInput;
};


export type MutationCreateCoaStructureArgs = {
  COAStructureInput: Array<CoaStructureInput>;
};


export type MutationUpdateContactArgs = {
  input: ContactInput;
};


export type MutationUpdateCostCentreArgs = {
  input: CostCentreInput;
};


export type MutationCancelCostCentreArgs = {
  input: CostCentreDeleteInput;
};


export type MutationCreateCostCentreDefinitionAsgmtArgs = {
  input: CostCentreDefinitionAsgmtInput;
};


export type MutationDeleteCostCentreArgs = {
  CompanyID: Scalars['String'];
  CostCentreID: Scalars['String'];
};


export type MutationCreateCostCentreArgs = {
  input: CostCentreInput;
};


export type MutationSetDefaultCostCentreArgs = {
  CompanyID: Scalars['String'];
  CostCentreID: Scalars['String'];
};


export type MutationCreateCostCentreCoaArgs = {
  CostCentreCOAInput: Array<CostCentreCoaInput>;
};


export type MutationAssignCostCentreCoaArgs = {
  CostCentreArr: Array<Scalars['String']>;
  MasterCOAID: Scalars['String'];
};


export type MutationUnassignCostCentreCoaArgs = {
  CostCentreCOAArr: Array<Scalars['String']>;
  MasterCOAID: Scalars['String'];
};


export type MutationDeleteCostcentreCoaArgs = {
  CostCentreCOADeleteInput: Array<CostCentreCoaDeleteInput>;
};


export type MutationUpdateCostCentreDefinitionHeaderArgs = {
  input: CostCentreDefinitionHeaderInput;
};


export type MutationCancelCostCentreDefinitionHeaderArgs = {
  input: CostCentreDefinitionHeaderDeleteInput;
};


export type MutationUpdateCostCentreDefinitionDetailArgs = {
  input: CostCentreDefinitionDetailInput;
};


export type MutationDeleteCostCentreDefinitionDetailArgs = {
  input: CostCentreDefinitionDetailDeleteInput;
};


export type MutationCancelCostCentreDefinitionDetailArgs = {
  input: CostCentreDefinitionDetailDeleteInput;
};


export type MutationCreateCostCentreDefinitionArgs = {
  CostCentreDefinitionDetailInput: Array<CostCentreDefinitionDetailInput>;
  CostCentreDefinitionHeaderInput: CostCentreDefinitionHeaderInput;
};


export type MutationCreateUpdateCostCentreDefinitionArgs = {
  CostCentreDefinitionDetailInput: Array<CostCentreDefinitionDetailInput>;
  CompanyID: Scalars['String'];
};


export type MutationDeleteCostCentreDefinitionArgs = {
  CostCentreDefinitionHeaderID: Scalars['String'];
};


export type MutationCreateCostCentreTypeArgs = {
  input: CostCentreTypeInput;
};


export type MutationUpdateCostCentreTypeArgs = {
  input: CostCentreTypeInput;
};


export type MutationDeleteCostCentreTypeArgs = {
  input: CostCentreTypeDeleteInput;
};


export type MutationCancelCostCentreTypeArgs = {
  input: CostCentreTypeDeleteInput;
};


export type MutationCreateUpdateCostCentreTypeArgs = {
  CostCentreTypeArr: Array<CostCentreTypeInput>;
};


export type MutationUpdateCreditCardTypeArgs = {
  input: CreditCardTypeInput;
};


export type MutationDeleteCreditCardTypeArgs = {
  input: CreditCardTypeDeleteInput;
};


export type MutationCancelCreditCardTypeArgs = {
  input: CreditCardTypeDeleteInput;
};


export type MutationCreateCreditCardTypeArgs = {
  CreditCardTypeInput: Array<CreditCardTypeInput>;
};


export type MutationUpdateCreditorAccountArgs = {
  input: CreditorAccountInput;
};


export type MutationDeleteCreditorAccountArgs = {
  input: CreditorAccountDeleteInput;
};


export type MutationCancelCreditorAccountArgs = {
  input: CreditorAccountDeleteInput;
};


export type MutationCreateCreditorAccountArgs = {
  directorItem?: Maybe<Array<CreditorDirectorInput>>;
  contactItem?: Maybe<Array<CreditorContactInput>>;
  input: CreditorAccountInput;
};


export type MutationUpdateCreditorAccountWithItemArgs = {
  directorItem?: Maybe<Array<CreditorDirectorInput>>;
  contactItem?: Maybe<Array<CreditorContactInput>>;
  input: CreditorAccountInput;
};


export type MutationCreateCreditorAccountWithAccountArgs = {
  directorItem?: Maybe<Array<CreditorDirectorInput>>;
  contactItem?: Maybe<Array<CreditorContactInput>>;
  input: CreditorAccountInput;
  userID?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationCreateCreditorProfileImportArgs = {
  TemplateStr: Scalars['String'];
};


export type MutationUpdateCreditorAccountCompanyAssignmentArgs = {
  input: CreditorAccountCompanyAssignmentInput;
};


export type MutationDeleteCreditorAccountCompanyAssignmentArgs = {
  input: CreditorAccountCompanyAssignmentDeleteInput;
};


export type MutationCancelCreditorAccountCompanyAssignmentArgs = {
  input: CreditorAccountCompanyAssignmentDeleteInput;
};


export type MutationAssignCreditorAccountComapanyArgs = {
  input: CreditorAccountCompanyAssignmentInput;
};


export type MutationUnassignCreditorAccountComapanyArgs = {
  input: CreditorAccountCompanyAssignmentInput;
};


export type MutationCreateCreditorContactArgs = {
  input: CreditorContactInput;
};


export type MutationUpdateCreditorContactArgs = {
  input: CreditorContactInput;
};


export type MutationDeleteCreditorContactArgs = {
  input: CreditorContactDeleteInput;
};


export type MutationCancelCreditorContactArgs = {
  input: CreditorContactDeleteInput;
};


export type MutationCreateCreditorDirectorArgs = {
  input: CreditorDirectorInput;
};


export type MutationUpdateCreditorDirectorArgs = {
  input: CreditorDirectorInput;
};


export type MutationDeleteCreditorDirectorArgs = {
  input: CreditorDirectorDeleteInput;
};


export type MutationCancelCreditorDirectorArgs = {
  input: CreditorDirectorDeleteInput;
};


export type MutationCreateCreditorTypeArgs = {
  input: CreditorTypeInput;
};


export type MutationUpdateCreditorTypeArgs = {
  input: CreditorTypeInput;
};


export type MutationDeleteCreditorTypeArgs = {
  input: CreditorTypeDeleteInput;
};


export type MutationCancelCreditorTypeArgs = {
  input: CreditorTypeDeleteInput;
};


export type MutationCreateCreditorTypeWithAccountArgs = {
  input: CreditorTypeInput;
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationCreateCurrencyArgs = {
  input: CurrencyInput;
};


export type MutationUpdateCurrencyArgs = {
  input: CurrencyInput;
};


export type MutationDeleteCurrencyArgs = {
  input: CurrencyDeleteInput;
};


export type MutationCancelCurrencyArgs = {
  input: CurrencyDeleteInput;
};


export type MutationCancelCurrencyExchangeArgs = {
  input: CurrencyExchangeDeleteInput;
};


export type MutationCreateCurrencyExchangeArgs = {
  input: CurrencyExchangeInput;
};


export type MutationUpdateCurrencyExchangeArgs = {
  input: CurrencyExchangeInput;
};


export type MutationDeleteCurrencyExchangeArgs = {
  CurrencyExchangeID: Scalars['String'];
};


export type MutationUpdateDebtorAccountArgs = {
  input: DebtorAccountInput;
};


export type MutationDeleteDebtorAccountArgs = {
  input: DebtorAccountDeleteInput;
};


export type MutationCancelDebtorAccountArgs = {
  input: DebtorAccountDeleteInput;
};


export type MutationCreateDebtorAccountArgs = {
  input: DebtorAccountInput;
  contactItem?: Maybe<Array<DebtorContactInput>>;
};


export type MutationUpdateDebtorAccountWithItemArgs = {
  contactItem?: Maybe<Array<DebtorContactInput>>;
  input: DebtorAccountInput;
};


export type MutationCreateDebtorAccountImportArgs = {
  CompanyID: Scalars['String'];
  TemplateStr: Scalars['String'];
};


export type MutationCreateDebtorContactArgs = {
  input: DebtorContactInput;
};


export type MutationUpdateDebtorContactArgs = {
  input: DebtorContactInput;
};


export type MutationDeleteDebtorContactArgs = {
  input: DebtorContactDeleteInput;
};


export type MutationCancelDebtorContactArgs = {
  input: DebtorContactDeleteInput;
};


export type MutationCreateDebtorTypeArgs = {
  input: DebtorTypeInput;
};


export type MutationUpdateDebtorTypeArgs = {
  input: DebtorTypeInput;
};


export type MutationDeleteDebtorTypeArgs = {
  input: DebtorTypeDeleteInput;
};


export type MutationCancelDebtorTypeArgs = {
  input: DebtorTypeDeleteInput;
};


export type MutationDeleteDeliveryLocationArgs = {
  input: DeliveryLocationDeleteInput;
};


export type MutationCancelDeliveryLocationArgs = {
  input: DeliveryLocationDeleteInput;
};


export type MutationCreateDeliveryLocArgs = {
  input: DeliveryLocationInput;
};


export type MutationUpdateDeliveryLocArgs = {
  input: DeliveryLocationInput;
};


export type MutationUpdateDocumentDateValidationArgs = {
  input: DocumentDateValidationInput;
};


export type MutationDeleteDocumentDateValidationArgs = {
  input: DocumentDateValidationDeleteInput;
};


export type MutationCancelDocumentDateValidationArgs = {
  input: DocumentDateValidationDeleteInput;
};


export type MutationCreateDocumentDateValidationArgs = {
  DocumentDateValidationInput: Array<DocumentDateValidationInput>;
};


export type MutationCreateDocumentNumberDetailArgs = {
  input: DocumentNumberDetailInput;
};


export type MutationUpdateDocumentNumberDetailArgs = {
  input: DocumentNumberDetailInput;
};


export type MutationDeleteDocumentNumberDetailArgs = {
  input: DocumentNumberDetailDeleteInput;
};


export type MutationCancelDocumentNumberDetailArgs = {
  input: DocumentNumberDetailDeleteInput;
};


export type MutationDeleteDocumentNumberHeaderArgs = {
  input: DocumentNumberHeaderDeleteInput;
};


export type MutationCancelDocumentNumberHeaderArgs = {
  input: DocumentNumberHeaderDeleteInput;
};


export type MutationCreateDocumentNumberHeaderArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  DocumentNumberDetailInput: Array<DocumentNumberDetailInput>;
  DocumentNumberHeaderInput: DocumentNumberHeaderInput;
};


export type MutationUpdateDocumentNumberHeaderArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  DocumentNumberDetailInput: Array<DocumentNumberDetailInput>;
  DocumentNumberHeaderInput: DocumentNumberHeaderInput;
};


export type MutationUpdateIsAutoDocNoArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  input: DocumentNumberHeaderInput;
  IsAutoDocNo: Scalars['Boolean'];
  RefTable: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type MutationCreateEntityCoaArgs = {
  input: EntityCoaInput;
};


export type MutationUpdateEntityCoaArgs = {
  input: EntityCoaInput;
};


export type MutationDeleteEntityCoaArgs = {
  input: EntityCoaDeleteInput;
};


export type MutationCancelEntityCoaArgs = {
  input: EntityCoaDeleteInput;
};


export type MutationAssignEntityCoaArgs = {
  CompanyArr: Array<Scalars['String']>;
  MasterCOAID: Scalars['String'];
};


export type MutationDeleteAssignedEntityCoaArgs = {
  CompanyArr: Array<Scalars['String']>;
  MasterCOAID: Scalars['String'];
};


export type MutationCancelExpenseClassArgs = {
  input: ExpenseClassDeleteInput;
};


export type MutationDeleteExpenseClassArgs = {
  ExpenseClassID: Scalars['String'];
};


export type MutationCreateExpenseClassArgs = {
  input: ExpenseClassInput;
};


export type MutationUpdateExpenseClassArgs = {
  input: ExpenseClassInput;
};


export type MutationCancelExpenseItemArgs = {
  input: ExpenseItemDeleteInput;
};


export type MutationCreateExpenseItemArgs = {
  input: ExpenseItemInput;
};


export type MutationUpdateExpenseItemArgs = {
  input: ExpenseItemInput;
};


export type MutationDeleteExpenseItemArgs = {
  ExpenseItemID: Scalars['String'];
};


export type MutationTestDeleteExpenseItemArgs = {
  ExpenseItemID: Scalars['String'];
};


export type MutationInitialTaxSetupArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationCreateSuperUserArgs = {
  accountID: Scalars['String'];
  input: UserInput;
};


export type MutationUpdateItemClassCompanyAssignmentArgs = {
  input: ItemClassCompanyAssignmentInput;
};


export type MutationDeleteItemClassCompanyAssignmentArgs = {
  input: ItemClassCompanyAssignmentDeleteInput;
};


export type MutationCancelItemClassCompanyAssignmentArgs = {
  input: ItemClassCompanyAssignmentDeleteInput;
};


export type MutationAssignItemClassCompanyArgs = {
  input: ItemClassCompanyAssignmentInput;
};


export type MutationUnassignItemClassCompanyArgs = {
  input: ItemClassCompanyAssignmentInput;
};


export type MutationCreateJournalTypeArgs = {
  input: JournalTypeInput;
};


export type MutationUpdateJournalTypeArgs = {
  input: JournalTypeInput;
};


export type MutationDeleteJournalTypeArgs = {
  input: JournalTypeDeleteInput;
};


export type MutationCancelJournalTypeArgs = {
  input: JournalTypeDeleteInput;
};


export type MutationUpdateMasterCoaArgs = {
  input: MasterCoaInput;
};


export type MutationCancelMasterCoaArgs = {
  input: MasterCoaDeleteInput;
};


export type MutationCreateMasterCoaArgs = {
  input: Array<MasterCoaInput>;
};


export type MutationDeleteMasterCoaArgs = {
  MasterCOAID: Scalars['String'];
};


export type MutationCreateExcelMasterCoaArgs = {
  MasterCOAExcelInput: Array<MasterCoaInput>;
};


export type MutationInactiveMasterCoaArgs = {
  MasterCOAID: Scalars['String'];
};


export type MutationActivateMasterCoaArgs = {
  MasterCOAID: Scalars['String'];
};


export type MutationCreateNumberFormatArgs = {
  input: NumberFormatInput;
};


export type MutationUpdateNumberFormatArgs = {
  input: NumberFormatInput;
};


export type MutationDeleteNumberFormatArgs = {
  input: NumberFormatDeleteInput;
};


export type MutationCancelNumberFormatArgs = {
  input: NumberFormatDeleteInput;
};


export type MutationUpdatePaymentMethodArgs = {
  input: PaymentMethodInput;
};


export type MutationDeletePaymentMethodArgs = {
  input: PaymentMethodDeleteInput;
};


export type MutationCancelPaymentMethodArgs = {
  input: PaymentMethodDeleteInput;
};


export type MutationCreatePaymentMethodArgs = {
  PaymentMethodInput: Array<PaymentMethodInput>;
};


export type MutationUpdateRetainEarningAccountDefArgs = {
  input: RetainEarningAccountDefInput;
};


export type MutationDeleteRetainEarningAccountDefArgs = {
  input: RetainEarningAccountDefDeleteInput;
};


export type MutationCancelRetainEarningAccountDefArgs = {
  input: RetainEarningAccountDefDeleteInput;
};


export type MutationCreateRetainEarningAccountDefArgs = {
  input: RetainEarningAccountDefInput;
};


export type MutationCancelStockItemArgs = {
  input: StockItemDeleteInput;
};


export type MutationCreateStockItemArgs = {
  input: StockItemInput;
  warehouseInput?: Maybe<Array<StockItemWarehouseInput>>;
};


export type MutationUpdateStockItemWithWarehouseArgs = {
  warehouse?: Maybe<Array<StockItemWarehouseInput>>;
  StockItemID: Scalars['String'];
  input: StockItemInput;
};


export type MutationUpdateStockItemArgs = {
  input: StockItemInput;
};


export type MutationDeleteStockItemArgs = {
  input: StockItemDeleteInput;
};


export type MutationCreateStockItemWarehouseArgs = {
  input: StockItemWarehouseInput;
};


export type MutationUpdateStockItemWarehouseArgs = {
  input: StockItemWarehouseInput;
};


export type MutationDeleteStockItemWarehouseArgs = {
  input: StockItemWarehouseDeleteInput;
};


export type MutationCancelStockItemWarehouseArgs = {
  input: StockItemWarehouseDeleteInput;
};


export type MutationCreateTaxTypeArgs = {
  input: TaxTypeInput;
};


export type MutationUpdateTaxTypeArgs = {
  input: TaxTypeInput;
};


export type MutationDeleteTaxTypeArgs = {
  input: TaxTypeInput;
};


export type MutationCreateTaxSchemeArgs = {
  input: TaxSchemeInput;
};


export type MutationUpdateTaxSchemeArgs = {
  input: TaxSchemeInput;
};


export type MutationCreateTaxEffectiveDateArgs = {
  input: TaxEffectiveDateInput;
};


export type MutationUpdateTaxEffectiveDateArgs = {
  input: TaxEffectiveDateInput;
};


export type MutationDeleteTaxEffectiveDateArgs = {
  input: TaxEffectiveDateInput;
};


export type MutationInsertTaxArgs = {
  TaxEffectiveInput: Array<TaxEffectiveInput>;
  TaxSchemeInput: TaxSchemeInput;
};


export type MutationUpdateTaxArgs = {
  TaxEffectiveInput: Array<TaxEffectiveInput>;
  TaxSchemeInput: TaxSchemeInput;
};


export type MutationDeleteTaxSchemeArgs = {
  TaxSchemeID: Scalars['String'];
};


export type MutationCreateTransactionTypeArgs = {
  input: TransactionTypeInput;
};


export type MutationUpdateTransactionTypeArgs = {
  input: TransactionTypeInput;
};


export type MutationDeleteTransactionTypeArgs = {
  input: TransactionTypeDeleteInput;
};


export type MutationCancelTransactionTypeArgs = {
  input: TransactionTypeDeleteInput;
};


export type MutationCreateUomArgs = {
  input: UomInput;
};


export type MutationUpdateUomArgs = {
  input: UomInput;
};


export type MutationDeleteUomArgs = {
  input: UomDeleteInput;
};


export type MutationCreateUomWithAccountArgs = {
  input: UomInput;
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationUpdateUomWithAccountArgs = {
  input: UomInput;
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationCreateUomExchangeArgs = {
  input: UomExchangeInput;
};


export type MutationUpdateUomExchangeArgs = {
  input: UomExchangeInput;
};


export type MutationDeleteUomExchangeArgs = {
  ID: Scalars['String'];
};


export type MutationUpdateWarehouseArgs = {
  WarehouseID: Scalars['String'];
  WarehouseInput: WarehouseInput;
};


export type MutationCancelWarehouseArgs = {
  input: WarehouseDeleteInput;
};


export type MutationCreateWarehouseArgs = {
  WarehouseInput: WarehouseInput;
};


export type MutationDeleteWarehouseArgs = {
  input: WarehouseDeleteInput;
};


export type MutationAssignWarehouseUserArgs = {
  input: WarehouseUserAssignmentInput;
};


export type MutationUnassignUserWarehouseArgs = {
  input: WarehouseUserAssignmentInput;
};


export type MutationAssignWarehouseItemArgs = {
  input: WarehouseItemAssignmentInput;
};


export type MutationUnassignWarehouseItemArgs = {
  input: WarehouseItemAssignmentInput;
};


export type MutationApproveRejectWorkDeskArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  WorkDeskInput: Array<WorkDeskInput>;
};


export type MutationCreateProcurementApprovalPolicyArgs = {
  input: ProcurementApprovalPolicyInput;
};


export type MutationUpdateProcurementApprovalPolicyArgs = {
  input: ProcurementApprovalPolicyInput;
};


export type MutationCancelProcurementApprovalPolicyArgs = {
  input: ProcurementApprovalPolicyDeleteInput;
};


export type MutationCreateProcurementApprovalPolicyAssignmentArgs = {
  input: ProcurementApprovalPolicyAssignmentInput;
};


export type MutationUpdateProcurementApprovalPolicyAssignmentArgs = {
  input: ProcurementApprovalPolicyAssignmentInput;
};


export type MutationCancelProcurementApprovalPolicyAssignmentArgs = {
  input: ProcurementApprovalPolicyAssignmentDeleteInput;
};


export type MutationDeleteProcurementApprovalPolicyArgs = {
  ID: Scalars['String'];
};


export type MutationCreateBulkPurchasePriceArgs = {
  bulkPurchaseItemInput: Array<BulkPurchasePriceItemInput>;
  input: BulkPurchasePriceHeaderInput;
};


export type MutationUpdateBulkPurchasePriceArgs = {
  bulkPurchaseItemInput: Array<BulkPurchasePriceItemInput>;
  input: BulkPurchasePriceHeaderInput;
  BulkPurchasePriceHeaderID: Scalars['String'];
};


export type MutationDeleteBulkPurchasePriceArgs = {
  BulkPurchasePriceHeaderID: Scalars['String'];
};


export type MutationSubmitGeneralDoArgs = {
  doItemInput: Array<DoItemInput>;
  doInput: DoInput;
  DOHeaderID?: Maybe<Scalars['String']>;
};


export type MutationDeleteGeneralDoArgs = {
  DOHeaderID: Scalars['String'];
};


export type MutationRejectGeneralDoArgs = {
  rejectInput?: Maybe<DoRejectInput>;
  ID: Scalars['String'];
};


export type MutationActionOnGdoStatusArgs = {
  input: GdoActionInput;
  DOHeaderID: Array<Scalars['String']>;
};


export type MutationCreateFavoriteApproverArgs = {
  FavoriteApproverInput: FavoriteApproverInput;
};


export type MutationDeleteFavoriteApproverArgs = {
  FavoriteApproverID: Scalars['String'];
};


export type MutationCreateFavoritePrArgs = {
  FavoritePRItemInput?: Maybe<Array<FavoritePrItemInput>>;
  FavoritePRInput: FavoritePrInput;
};


export type MutationDeleteFavoritePrArgs = {
  FavoritePRHeaderID: Scalars['String'];
};


export type MutationCreateGeneralPurchasePolicyArgs = {
  GeneralPurchasePolicyInput: GeneralPurchasePolicyInput;
};


export type MutationUpdateGeneralPurchasePolicyArgs = {
  GeneralPurchasePolicyID?: Maybe<Scalars['String']>;
  GeneralPurchasePolicyInput: GeneralPurchasePolicyInput;
};


export type MutationCreateUpdateGrtnArgs = {
  grtnItemInput: Array<GeneralGrtnItemInput>;
  grtnInput: GeneralGrtnInput;
  GRTNHeaderID?: Maybe<Scalars['String']>;
};


export type MutationCreateGrtnArgs = {
  grtnItemInput: Array<GeneralGrtnItemInput>;
  grtnInput: GeneralGrtnInput;
};


export type MutationUpdateGrtnArgs = {
  grtnItemInput: Array<GeneralGrtnItemInput>;
  grtnInput: GeneralGrtnInput;
  GRTNHeaderID?: Maybe<Scalars['String']>;
};


export type MutationDeleteGrtnArgs = {
  GRTNHeaderID: Scalars['String'];
};


export type MutationActionOnGrtnStatusArgs = {
  CompanyID: Scalars['String'];
  input: GeneralGrtnRejectInput;
  ApprovalStatus: ApprovalStatus;
  GRTNHeaderID: Array<Scalars['String']>;
};


export type MutationCreateGeneralPoArgs = {
  generalPOInput: Array<PoInput>;
};


export type MutationUpdateGeneralPoArgs = {
  generalPOInput: Array<PoInput>;
  POHeaderID: Scalars['String'];
};


export type MutationCreateDirectPoArgs = {
  input: DirectPoInput;
};


export type MutationUpdateDirectPoArgs = {
  input: DirectPoInput;
  POHeaderID?: Maybe<Scalars['String']>;
};


export type MutationDeletePoArgs = {
  POHeaderID: Scalars['String'];
};


export type MutationActionOnGpoStatusArgs = {
  CompanyID: Scalars['String'];
  input: PoRejectInput;
  ApprovalStatus: ApprovalStatus;
  POHeaderID: Array<Scalars['String']>;
};


export type MutationCancelClosePoArgs = {
  input: PurchaseActionInput;
  POHeaderID: Scalars['String'];
};


export type MutationCombinePoArgs = {
  input: Array<DirectPoInput>;
};


export type MutationCreateGeneralPrArgs = {
  generalPRItemInput?: Maybe<Array<PrItemInput>>;
  generalPRInput: PrInput;
};


export type MutationUpdateGeneralPrArgs = {
  generalPRItemInput: Array<PrItemInput>;
  generalPRInput: PrInput;
  PRHeaderID: Scalars['String'];
};


export type MutationActionOnGprStatusArgs = {
  input: GprActionInput;
  GeneralPRIDs: Array<Scalars['String']>;
};


export type MutationDeleteGeneralPrArgs = {
  PRHeaderID: Scalars['String'];
};


export type MutationCancelGeneralPrArgs = {
  PRHeaderID: Array<Scalars['String']>;
};


export type MutationCancelClosePrArgs = {
  input: GprActionInput;
  PRHeaderID: Scalars['String'];
};


export type MutationCreateGeneralRfqArgs = {
  RFQItemInput: Array<RfqItemInput>;
  RFQInput: RfqInput;
};


export type MutationUpdateGeneralRfqArgs = {
  generalRFQItemInput: Array<RfqItemInput>;
  generalRFQInput: RfqInput;
  RFQHeaderID: Scalars['String'];
};


export type MutationDeleteGeneralRfqArgs = {
  RFQHeaderID: Scalars['String'];
};


export type MutationSubmitEvaluationSelectionArgs = {
  RFQEvalSelectInput: Array<RfqEvalSelectInput>;
  RFQInput: RfqEvalInput;
};


export type MutationActionOnRfqStatusArgs = {
  CompanyID: Scalars['String'];
  input: RfqRejectInput;
  RFQHeaderID: Array<Scalars['String']>;
};


export type MutationInviteSupplierArgs = {
  SupplierIDs: Array<Scalars['String']>;
  RFQHeaderID: Scalars['String'];
};


export type MutationUninviteSupplierArgs = {
  SupplierID: Scalars['String'];
  RFQHeaderID: Scalars['String'];
};


export type MutationSubmitGeneralRfqQuoteArgs = {
  input: RfqSupplierSubmissionInput;
};


export type MutationDeleteRfqSupplierSubmissionArgs = {
  RFQSupplierSubmissionID: Scalars['String'];
};


export type MutationCreateScheduledPurchasesArgs = {
  input: ScheduledPurchasesInput;
};


export type MutationUpdateScheduledPurchasesArgs = {
  input: ScheduledPurchasesInput;
  ScheduledPurchasesID: Scalars['String'];
};


export type MutationDeleteScheduledPurchasesArgs = {
  ScheduledPurchasesID: Scalars['String'];
};


export type MutationDeleteScheduledPurchasesItemArgs = {
  ScheduledPurchasesID: Scalars['String'];
};


export type MutationCreatePreparePoArgs = {
  ScheduledPurchasesID: Scalars['String'];
  input: Array<PreparePoInput>;
};


export type MutationCreateItemArgs = {
  input: ItemInput;
};


export type MutationUpdateItemArgs = {
  input: ItemInput;
};


export type MutationCancelItemArgs = {
  ItemID: Scalars['String'];
};


export type MutationDeleteItemArgs = {
  ItemID: Scalars['String'];
};


export type MutationCreateBatchItemArgs = {
  input: Array<ItemExcelInput>;
};


export type MutationCreateItemCategoryArgs = {
  ItemCategoryInput: ItemCategoryInput;
};


export type MutationUpdateItemCategoryArgs = {
  ItemCategoryInput: ItemCategoryInput;
};


export type MutationDeleteItemCategoryArgs = {
  ItemCategoryID: Scalars['String'];
};


export type MutationAssignItemCategoryCompanyArgs = {
  input: ItemCategoryCompanyAssignmentInput;
};


export type MutationUnassignItemCategoryCompanyArgs = {
  input: ItemCategoryCompanyAssignmentInput;
};


export type MutationCreatePurchaseItemVariancePolicyArgs = {
  PurchaseItemVariancePolicyInput: PurchaseItemVariancePolicyInput;
};


export type MutationUpdatePurchaseItemVariancePolicyArgs = {
  PurchaseItemVariancePolicyID: Scalars['String'];
  PurchaseItemVariancePolicyInput: PurchaseItemVariancePolicyInput;
};


export type MutationProcurementLoginArgs = {
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type MutationCreateDocumentFolderArgs = {
  input: DocumentFolderInput;
};


export type MutationUpdateDocumentFolderArgs = {
  input: DocumentFolderInput;
};


export type MutationDeleteDocumentFolderArgs = {
  ID: Scalars['String'];
};

export type NumberFormatDeleteInput = {
  NumberFormatID: Scalars['String'];
};

export type NumberFormatEntity = AuditEntity & {
  __typename?: 'NumberFormatEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  NumberFormatID: Scalars['String'];
  Format: Scalars['String'];
  Constant: Scalars['String'];
  Day: Scalars['Float'];
  Month: Scalars['Float'];
  Year: Scalars['Float'];
  CompanyCode: Scalars['String'];
  SerialNo: Scalars['String'];
  RunningNumber?: Maybe<Array<RunningNumberEntity>>;
};

export type NumberFormatInput = {
  NumberFormatID?: Maybe<Scalars['String']>;
  Format: Scalars['String'];
  Constant: Scalars['String'];
  Day: Scalars['Float'];
  Month: Scalars['Float'];
  Year: Scalars['Float'];
  CompanyCode: Scalars['String'];
  SerialNo: Scalars['String'];
  CompanyID: Scalars['String'];
};

export enum NumericFormatter {
  Lumpsum = 'LUMPSUM',
  Thousands = 'THOUSANDS',
  Millions = 'MILLIONS',
  Billions = 'BILLIONS'
}

/** JsonType */
export type OsprItemReturnData = {
  __typename?: 'OSPRItemReturnData';
  Data?: Maybe<Array<PrItemEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type PoHeaderEntity = AuditEntity & {
  __typename?: 'POHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  POHeaderID: Scalars['String'];
  SupplierID: Scalars['String'];
  CompanyID: Scalars['String'];
  DeliveryLocationID?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  ContactPerson?: Maybe<Scalars['JSON']>;
  ExpectedDate: Scalars['String'];
  Instruction?: Maybe<Scalars['String']>;
  CancelCloseDate?: Maybe<Scalars['String']>;
  CancelCloseRemark?: Maybe<Scalars['String']>;
  POType: PoType;
  Remarks?: Maybe<Scalars['String']>;
  CreditTerm?: Maybe<Scalars['Float']>;
  POItem?: Maybe<Array<PoItemEntity>>;
  POItemPricing?: Maybe<Array<PoItemPricingEntity>>;
  /** CustomFieldResolver */
  TotalAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  Company: CompanyEntity;
  Supplier?: Maybe<CreditorAccountEntity>;
  /** CustomFieldResolver */
  DeliveryLocation?: Maybe<DeliveryLocationEntity>;
  /** CustomFieldResolver */
  ApprovalWorkflowPO?: Maybe<Scalars['JSON']>;
};

export type PoInput = {
  POHeaderID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  Instruction?: Maybe<Scalars['String']>;
  ContactPerson?: Maybe<Scalars['JSON']>;
  RecordStatus?: Maybe<RecordStatus>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  POItems: Array<PoItemInput>;
  POType: PoType;
  CreditTerm?: Maybe<Scalars['Float']>;
};

export type PoItemEntity = AuditEntity & {
  __typename?: 'POItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  POItemID: Scalars['String'];
  POHeaderID: Scalars['String'];
  POHeader?: Maybe<PoHeaderEntity>;
  PRItemID?: Maybe<Scalars['String']>;
  FavoritePRItemID?: Maybe<Scalars['String']>;
  PRItem?: Maybe<PrItemEntity>;
  FavoritePRItem?: Maybe<FavoritePrItemEntity>;
  ItemID: Scalars['String'];
  Item?: Maybe<ItemEntity>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  UOMID: Scalars['String'];
  CostCentreID?: Maybe<Scalars['String']>;
  OrderedQty: Scalars['Float'];
  OutstandingQty: Scalars['Float'];
  UnitPrice: Scalars['Float'];
  BaseAmt: Scalars['Float'];
  TaxSchemeID: Scalars['String'];
  TaxAmt: Scalars['Float'];
  TotalAmt: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  DOItem?: Maybe<Array<DoItemEntity>>;
  ScheduledPurchasesItemID?: Maybe<Scalars['String']>;
  PriceRefTable?: Maybe<Scalars['String']>;
  PriceRefID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  UOMExchange?: Maybe<Array<PoUomExchange>>;
  /** CustomFieldResolver */
  UOM?: Maybe<AccUomEntity>;
  /** CustomFieldResolver */
  TaxScheme?: Maybe<TaxSchemeEntity>;
  /** CustomFieldResolver */
  DeliveryLocation?: Maybe<DeliveryLocationEntity>;
  /** CustomFieldResolver */
  CostCentre?: Maybe<CostCentreEntity>;
  /** CustomFieldResolver */
  Warehouse?: Maybe<WarehouseEntity>;
  /** CustomFieldResolver */
  ScheduledPurchaseDetail?: Maybe<Scalars['String']>;
};

export type PoItemInput = {
  POHeaderID?: Maybe<Scalars['String']>;
  PRItemID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  DeliveryTo?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  OutstandingQty?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  TotalAmt?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  CostCentreID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  OrderedQty?: Maybe<Scalars['Float']>;
  BaseAmt?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  ScheduledPurchasesItemID?: Maybe<Scalars['String']>;
};

export type PoItemPricingEntity = AuditEntity & {
  __typename?: 'POItemPricingEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  POItemPricingID: Scalars['String'];
  SupplierID: Scalars['String'];
  ItemID: Scalars['String'];
  UOMID?: Maybe<Scalars['String']>;
  TotalQuantity: Scalars['Float'];
  UnitPrice: Scalars['Float'];
  PriceType: PriceType;
  POHeaderID: Scalars['String'];
  POHeader?: Maybe<PoHeaderEntity>;
  PRItem?: Maybe<Array<PrItemEntity>>;
  FavoritePRItem?: Maybe<Array<FavoritePrItemEntity>>;
};

export type PoItemsInput = {
  DOItemID?: Maybe<Scalars['String']>;
  POItemID?: Maybe<Scalars['String']>;
  GRTNItemID?: Maybe<Scalars['String']>;
  AcceptedQty?: Maybe<Scalars['Float']>;
  AcceptedAmt?: Maybe<Scalars['Float']>;
  DeliveredQty?: Maybe<Scalars['Float']>;
  WarehouseID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
};

export type PoRejectInput = {
  POHeaderID?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
};

export enum PoType {
  PoFromPr = 'PO_FROM_PR',
  DirectPo = 'DIRECT_PO',
  CentralisedPo = 'CENTRALISED_PO'
}

/** JsonType */
export type PoWorkflowInfo = {
  __typename?: 'POWorkflowInfo';
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
};

export enum PrConfidentiality {
  Yes = 'YES',
  No = 'NO'
}

export type PrHeaderEntity = AuditEntity & {
  __typename?: 'PRHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  PRHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  DeliveryLocationID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  RequestedBy?: Maybe<Scalars['String']>;
  ContactPerson?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Instruction?: Maybe<Scalars['String']>;
  CancelCloseDate?: Maybe<Scalars['String']>;
  CancelCloseRemark?: Maybe<Scalars['String']>;
  PRItem?: Maybe<Array<PrItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  DeliveryLocation?: Maybe<DeliveryLocationEntity>;
  /** CustomFieldResolver */
  CheckboxPRDisabled?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  ApprovalWorkflowPR?: Maybe<Scalars['JSON']>;
};

export type PrInput = {
  DocDate?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  RequestedBy?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  ContactPerson?: Maybe<Scalars['String']>;
  Instruction: Scalars['String'];
  ContactNo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PRHeaderID?: Maybe<Scalars['String']>;
  AccountType?: Maybe<AccountType>;
  ApprovalStatus: ApprovalStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type PrItemEntity = AuditEntity & {
  __typename?: 'PRItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  PRItemID: Scalars['String'];
  PRHeaderID: Scalars['String'];
  PRHeader?: Maybe<PrHeaderEntity>;
  ItemID: Scalars['String'];
  Item?: Maybe<ItemEntity>;
  CostCentreID: Scalars['String'];
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  UOMID: Scalars['String'];
  Quantity: Scalars['Float'];
  UnitCost?: Maybe<Scalars['Float']>;
  TotalUnitCost?: Maybe<Scalars['Float']>;
  OutstandingQty?: Maybe<Scalars['Float']>;
  POItemPricingID?: Maybe<Scalars['String']>;
  POItemPricing?: Maybe<PoItemPricingEntity>;
  Remark?: Maybe<Scalars['String']>;
  IsPOGenerated: Scalars['Boolean'];
  RFQItem?: Maybe<Array<RfqItemEntity>>;
  POItem?: Maybe<Array<PoItemEntity>>;
  /** CustomFieldResolver */
  CostCentre?: Maybe<CostCentreEntity>;
  /** CustomFieldResolver */
  UOM?: Maybe<AccUomEntity>;
  /** CustomFieldResolver */
  DeliveryLocation?: Maybe<DeliveryLocationEntity>;
  /** CustomFieldResolver */
  Warehouse?: Maybe<WarehouseEntity>;
  /** CustomFieldResolver */
  rfqSubmissionSelected?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  bulkPurchaseSelected?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  masterFileSelected?: Maybe<Scalars['JSON']>;
};

export type PrItemInput = {
  ItemID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  TotalUnitCost?: Maybe<Scalars['Float']>;
  CostCentreID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  PRHeaderID?: Maybe<Scalars['String']>;
};

/** JsonType */
export type PrWorkflowInfo = {
  __typename?: 'PRWorkflowInfo';
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
};

export type PaymentDeleteInput = {
  PaymentID: Scalars['String'];
};

export type PaymentEntity = AuditEntity & {
  __typename?: 'PaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  CurrencyID?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  LocalDocAmt?: Maybe<Scalars['Float']>;
  LocalTaxAmt?: Maybe<Scalars['Float']>;
  BankAccountID: Scalars['String'];
  PaymentID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  CompanyID: Scalars['String'];
  PaymentMethodID: Scalars['String'];
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  LedgerID?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  PaymentItem?: Maybe<Array<PaymentItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
};

export type PaymentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  DocRef?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  DocDueDate?: Maybe<Scalars['String']>;
  CancellationDate?: Maybe<Scalars['String']>;
  CancellationRemark?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  BaseAmt?: Maybe<Scalars['Float']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  LocalBaseAmt?: Maybe<Scalars['Float']>;
  BankDocAmt?: Maybe<Scalars['Float']>;
  LocalTaxAmt?: Maybe<Scalars['Float']>;
  CurrencyID?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  PaymentID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  PaymentMethodID: Scalars['String'];
  TransactionDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  PayeeName?: Maybe<Scalars['String']>;
};

export type PaymentItemDeleteInput = {
  PaymentItemID: Scalars['String'];
};

export type PaymentItemEntity = AuditEntity & {
  __typename?: 'PaymentItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  MasterCOAID: Scalars['String'];
  PaymentItemID: Scalars['String'];
  DocDate?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  PaymentID: Scalars['String'];
  Payment?: Maybe<PaymentEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type PaymentItemInput = {
  PaymentItemID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type PaymentMethodDeleteInput = {
  PaymentMethodID: Scalars['String'];
};

export type PaymentMethodEntity = AuditEntity & {
  __typename?: 'PaymentMethodEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  PaymentMethodID: Scalars['String'];
  Name: Scalars['String'];
  Description: Scalars['String'];
  Sequence: Scalars['Float'];
  IsSystem: Scalars['Boolean'];
  Payment?: Maybe<Array<PaymentEntity>>;
  Receipt?: Maybe<Array<ReceiptEntity>>;
  FavoritePayment?: Maybe<Array<FavoritePaymentEntity>>;
  FavoriteReceipt?: Maybe<Array<FavoriteReceiptEntity>>;
  APAdvance?: Maybe<Array<AccApAdvanceEntity>>;
  BankTransfer?: Maybe<Array<BankTransferEntity>>;
  ARAdvance?: Maybe<Array<AccArAdvanceEntity>>;
  APRefund?: Maybe<Array<AccApRefundEntity>>;
  APPayment?: Maybe<Array<AccApPaymentEntity>>;
  APFavoritePayment?: Maybe<Array<AccApFavoritePaymentEntity>>;
  AROfficialReceipt?: Maybe<Array<AccArOfficialReceiptEntity>>;
  ARRefund?: Maybe<Array<AccArRefundEntity>>;
};

export type PaymentMethodInput = {
  PaymentMethodID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Description: Scalars['String'];
  Sequence: Scalars['Float'];
  IsSystem: Scalars['Boolean'];
  IsUsed?: Maybe<Scalars['Boolean']>;
};

export type PaymentRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type PendingToDo = {
  __typename?: 'PendingToDo';
  PendingGL?: Maybe<Scalars['Float']>;
  PendingCB?: Maybe<Scalars['Float']>;
  PendingAR?: Maybe<Scalars['Float']>;
  PendingAP?: Maybe<Scalars['Float']>;
};

export type PeriodBudgetItemInput = {
  AccountPeriodID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  PeriodBudgetAmount?: Maybe<Scalars['Float']>;
  Period?: Maybe<Scalars['Float']>;
};

export type PreparePoEntry = {
  input: Array<PreparePoInput>;
};

export type PreparePoHeaderInput = {
  ScheduledPurchasesID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  Instruction?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  POType: PoType;
  ContactPerson?: Maybe<Scalars['JSON']>;
  Remarks?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type PreparePoInput = {
  POHeaderID?: Maybe<Scalars['String']>;
  SupplierID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledPurchasesID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  POType: PoType;
  Instruction?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  ContactPerson?: Maybe<Scalars['JSON']>;
  RecordStatus?: Maybe<RecordStatus>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  POItems: Array<PreparePoItemInput>;
  CreditTerm?: Maybe<Scalars['Float']>;
};

export type PreparePoItemBySupplierInput = {
  SupplierID?: Maybe<Scalars['String']>;
  POItems: Array<PreparePoItemInput>;
};

export type PreparePoItemInput = {
  POHeaderID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  PRItemID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  DeliveryTo?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  OrderQty?: Maybe<Scalars['Float']>;
  OutstandingQty?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  TotalAmt?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  CostCentreID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  OrderedQty?: Maybe<Scalars['Float']>;
  BaseAmt?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  ScheduledPurchasesItemID?: Maybe<Scalars['String']>;
  IsCentralised?: Maybe<Scalars['Boolean']>;
  StockItemID?: Maybe<Scalars['String']>;
};

export enum PriceType {
  BulkPurchase = 'BULK_PURCHASE',
  MarketPrice = 'MARKET_PRICE',
  Rfq = 'RFQ'
}

export type PrintReportOutput = {
  __typename?: 'PrintReportOutput';
  file_name?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
};

export type ProcessMonthYearEndOutput = {
  __typename?: 'ProcessMonthYearEndOutput';
  pendingJP?: Maybe<Scalars['Float']>;
  pendingRJ?: Maybe<Scalars['Float']>;
  pendingAA?: Maybe<Scalars['Float']>;
  pendingGenrateRJ?: Maybe<Scalars['Float']>;
  pendingAPAdv?: Maybe<Scalars['Float']>;
  pendingAPInv?: Maybe<Scalars['Float']>;
  pendingAPCN?: Maybe<Scalars['Float']>;
  pendingAPDN?: Maybe<Scalars['Float']>;
  pendingAPPayment?: Maybe<Scalars['Float']>;
  pendingAPBatchPayment?: Maybe<Scalars['Float']>;
  pendingAPRefund?: Maybe<Scalars['Float']>;
  pendingARAdv?: Maybe<Scalars['Float']>;
  pendingARInv?: Maybe<Scalars['Float']>;
  pendingARCN?: Maybe<Scalars['Float']>;
  pendingARDN?: Maybe<Scalars['Float']>;
  pendingAROfficialReceipt?: Maybe<Scalars['Float']>;
  pendingARRefund?: Maybe<Scalars['Float']>;
  pendingCBPayment?: Maybe<Scalars['Float']>;
  pendingCBReceipt?: Maybe<Scalars['Float']>;
  pendingCBBankTransfer?: Maybe<Scalars['Float']>;
  pendingStockReceipt?: Maybe<Scalars['Float']>;
  pendingStockIssue?: Maybe<Scalars['Float']>;
  pendingStockTransfer?: Maybe<Scalars['Float']>;
  pendingStockAdjustment?: Maybe<Scalars['Float']>;
  pendingStockCostAdjustment?: Maybe<Scalars['Float']>;
  pendingStockRequisition?: Maybe<Scalars['Float']>;
  pendingCycleCount?: Maybe<Scalars['Float']>;
  pendingGRN?: Maybe<Scalars['Float']>;
  pendingGRTN?: Maybe<Scalars['Float']>;
  processStatus?: Maybe<Scalars['Boolean']>;
};

export type ProcurementApprovalListJson = {
  __typename?: 'ProcurementApprovalListJson';
  Category: Scalars['String'];
  StepNo: Scalars['Float'];
  RoleID: Scalars['String'];
  Predicate?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  RoleName?: Maybe<Scalars['String']>;
};

export type ProcurementApprovalListJsonInput = {
  Category: Scalars['String'];
  StepNo: Scalars['Float'];
  RoleID: Scalars['String'];
  Predicate?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  RoleName?: Maybe<Scalars['String']>;
};

export type ProcurementApprovalPolicyAssignmentDeleteInput = {
  ApprovalPolicyAssignmentID: Scalars['String'];
};

export type ProcurementApprovalPolicyAssignmentEntity = AuditEntity & {
  __typename?: 'ProcurementApprovalPolicyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalPolicyAssignmentID: Scalars['String'];
  ApprovalPolicyID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ApprovalList?: Maybe<Array<Scalars['JSON']>>;
  Category: Scalars['String'];
  LowerLimit?: Maybe<Scalars['Float']>;
  UpperLimit?: Maybe<Scalars['Float']>;
  Company?: Maybe<CompanyEntity>;
};

export type ProcurementApprovalPolicyAssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
  ApprovalList: Array<ProcurementApprovalListJsonInput>;
};

export type ProcurementApprovalPolicyDeleteInput = {
  ApprovalPolicyID: Scalars['String'];
};

export type ProcurementApprovalPolicyEntity = AuditEntity & {
  __typename?: 'ProcurementApprovalPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalPolicyID: Scalars['String'];
  Category: Scalars['String'];
  ApprovalList?: Maybe<Array<Scalars['JSON']>>;
};

export type ProcurementApprovalPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
  ApprovalList: Array<ProcurementApprovalListJsonInput>;
};

export type ProcurementConDocTypeEntity = AuditEntity & {
  __typename?: 'ProcurementConDocTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  docName: Scalars['String'];
  commonStatus: CommonStatus;
  docCode: PurchaserDocNum;
  code?: Maybe<Scalars['String']>;
  numberFormat: Array<ProcurementConNumberFormatEntity>;
};

export type ProcurementConNumberFormatEntity = AuditEntity & {
  __typename?: 'ProcurementConNumberFormatEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  docTypeID: Scalars['String'];
  numberFormat: Scalars['String'];
  numberLength: Scalars['Float'];
  prefix: Scalars['String'];
  commonStatus: CommonStatus;
  softwareCode: Scalars['String'];
  documentDivider: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  prefixExtra?: Maybe<Scalars['String']>;
  docType: ProcurementConDocTypeEntity;
  runningNum: Array<ProcurementConRunningNumberSetupEntity>;
};

export type ProcurementConRunningNumberSetupEntity = AuditEntity & {
  __typename?: 'ProcurementConRunningNumberSetupEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  numberFormatID: Scalars['String'];
  nextNumber: Scalars['Float'];
  accountID: Scalars['String'];
  numberFormat: ProcurementConNumberFormatEntity;
};

export type ProcurementCoreApprovalEntity = AuditEntity & {
  __typename?: 'ProcurementCoreApprovalEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
};

export type ProcurementCoreEntity = AuditEntity & {
  __typename?: 'ProcurementCoreEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
};

export type ProcurementDocumentsInput = {
  fileType?: Maybe<FileType>;
  refTable?: Maybe<RefTable>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['Upload']>;
  RecordStatus?: Maybe<RecordStatus>;
  bucketFileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
};

export type ProcurementWorkFlowApprovedStepEntity = AuditEntity & {
  __typename?: 'ProcurementWorkFlowApprovedStepEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  WorkFlowApprovedStepID: Scalars['String'];
  WorkFlowStepID?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  ApprovalDT?: Maybe<Scalars['String']>;
  NotifiedDT?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<GpApprovalStatus>;
  StatusRemarks?: Maybe<Scalars['String']>;
  WorkFlowStep: ProcurementWorkFlowStepEntity;
};

export type ProcurementWorkFlowEntity = AuditEntity & {
  __typename?: 'ProcurementWorkFlowEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  WorkFlowID: Scalars['String'];
  ApprovalPolicyAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  SubmitterID?: Maybe<Scalars['String']>;
  SubmitDate?: Maybe<Scalars['String']>;
  WorkFlowStatus?: Maybe<GpApprovalStatus>;
  WorkFlowStep?: Maybe<Array<ProcurementWorkFlowStepEntity>>;
};

export type ProcurementWorkFlowStepEntity = AuditEntity & {
  __typename?: 'ProcurementWorkFlowStepEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  WorkFlowStepID: Scalars['String'];
  WorkFlowID?: Maybe<Scalars['String']>;
  ApproverRoleID?: Maybe<Scalars['String']>;
  NotifiedDT?: Maybe<Scalars['String']>;
  StepNo?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<GpApprovalStatus>;
  StatusRemarks?: Maybe<Scalars['String']>;
  WorkFlow: ProcurementWorkFlowEntity;
  WorkFlowApprovedStep?: Maybe<Array<ProcurementWorkFlowApprovedStepEntity>>;
};

export type PurchaseActionInput = {
  CancelCloseRemark?: Maybe<Scalars['String']>;
  CancelCloseDate?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  File?: Maybe<Array<Scalars['Upload']>>;
};

export type PurchaseBudgetInput = {
  Remarks: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type PurchaseItemVariancePolicyEntity = AuditEntity & {
  __typename?: 'PurchaseItemVariancePolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  PurchaseItemVariancePolicyID: Scalars['String'];
  VariancePerc?: Maybe<Scalars['Float']>;
};

export type PurchaseItemVariancePolicyInput = {
  PurchaseItemVariancePolicyID?: Maybe<Scalars['String']>;
  VariancePerc?: Maybe<Scalars['Float']>;
};

export type PurchaserCoreAssociateEntity = AuditEntity & {
  __typename?: 'PurchaserCoreAssociateEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxTypeID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  platformAccess: Scalars['Boolean'];
};

export enum PurchaserDocNum {
  GPr = 'G_PR',
  GRfq = 'G_RFQ',
  GPo = 'G_PO',
  ArClaim = 'AR_CLAIM',
  ArMiscIv = 'AR_MISC_IV',
  ArCn = 'AR_CN',
  ArDn = 'AR_DN',
  ArAdvanceRecoup = 'AR_ADVANCE_RECOUP',
  ArPayment = 'AR_PAYMENT',
  ArReceipt = 'AR_RECEIPT',
  ArInvoice = 'AR_INVOICE',
  ApClaim = 'AP_CLAIM',
  ApMiscIv = 'AP_MISC_IV',
  ApCn = 'AP_CN',
  ApDn = 'AP_DN',
  ApAdvanceRecoup = 'AP_ADVANCE_RECOUP',
  ApPayment = 'AP_PAYMENT',
  ApReceipt = 'AP_RECEIPT',
  ApInvoice = 'AP_INVOICE',
  Default = 'DEFAULT',
  Df = 'DF',
  StockMvmt = 'STOCK_MVMT',
  Grn = 'GRN',
  Grtn = 'GRTN',
  Po = 'PO',
  Pr = 'PR',
  Rfq = 'RFQ',
  Vo = 'VO',
  Svo = 'SVO'
}

export type PurchaserRoleEntity = AuditEntity & {
  __typename?: 'PurchaserRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  softwareCode: SoftwareCode;
  rolePerm: Array<PurchaserRolePermissionAssignmentEntity>;
  conRolePerm: Array<PurchaserRolePermissionAssignmentEntity>;
  siteRolePerm: Array<PurchaserRolePermissionAssignmentEntity>;
};

export type PurchaserRolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'PurchaserRolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
  accountID?: Maybe<Scalars['String']>;
  permName?: Maybe<Scalars['String']>;
};

export type PurchaserRoleUserEntAssignmentEntity = AuditEntity & {
  __typename?: 'PurchaserRoleUserEntAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  getUserRole: Array<UserRoleEntity>;
  loggedInUserProfile: UserEntity;
  activeUser: Scalars['Float'];
  getUsersByAccount: Array<UserEntity>;
  getUsersWithAccount: Array<UserEntity>;
  getUser: UserEntity;
  getUsersbyIds: Array<UserEntity>;
  isUserValid: Scalars['Boolean'];
  getUsersBySoftware: Array<UserEntity>;
  getUsersByAccountAndSoftware: Array<UserEntity>;
  getActiveUsersCount: Scalars['Float'];
  getSuperUsers: Array<UserEntity>;
  getAssignedUserByCompany: Scalars['JSON'];
  getRoleNameByUser?: Maybe<Scalars['JSON']>;
  getAPAdvance: Array<AccApAdvanceEntity>;
  getAPAdvancebyStatus: Array<AccApAdvanceEntity>;
  getAPAdvancebyStatusListing: Array<AccApAdvanceEntity>;
  getARAdvance: Array<AccArAdvanceEntity>;
  getARAdvancebyStatus: Array<AccArAdvanceEntity>;
  getAllocationDocument: Scalars['JSON'];
  getDocumentDetail: Scalars['JSON'];
  getAllocationItemDocument: Scalars['JSON'];
  getAPBatchPayment: Array<AccApBatchPaymentEntity>;
  getAPBatchPaymentbyStatus: Array<AccApBatchPaymentEntity>;
  getAPBatchPaymentbyStatusListing: Array<AccApBatchPaymentEntity>;
  getAPCreditNote: Array<AccApCreditNoteEntity>;
  getAPCreditNotebyStatus: Array<AccApCreditNoteEntity>;
  getAPCreditNotebyStatusListing: Array<AccApCreditNoteEntity>;
  getARCreditNote: Array<AccArCreditNoteEntity>;
  getARCreditNotebyStatus: Array<AccArCreditNoteEntity>;
  getAPCreditorCreditNote: Array<AccApCreditorCreditNoteEntity>;
  getAPCreditorCreditNotebyStatus: Array<AccApCreditorCreditNoteEntity>;
  getAPCreditorCreditNotebyStatusListing: Array<AccApCreditorCreditNoteEntity>;
  getGeneralGRTN: Scalars['JSON'];
  getGRTNItem: Scalars['JSON'];
  getAPCreditNoteItem: Array<AccApCreditNoteItemEntity>;
  getAPDebitNote: Array<AccApDebitNoteEntity>;
  getAPDebitNotebyStatus: Array<AccApDebitNoteEntity>;
  getAPDebitNotebyStatusListing: Array<AccApDebitNoteEntity>;
  getARDebitNote: Array<AccArDebitNoteEntity>;
  getARDebitNotebyStatus: Array<AccArDebitNoteEntity>;
  getAPCreditorDebitNote: Array<AccApCreditorDebitNoteEntity>;
  getAPCreditorDebitNotebyStatus: Array<AccApCreditorDebitNoteEntity>;
  getAPCreditorDebitNotebyStatusListing: Array<AccApCreditorDebitNoteEntity>;
  getAPDebitNoteItem: Array<AccApDebitNoteItemEntity>;
  getARDebitNoteItem: Array<AccArDebitNoteItemEntity>;
  getAPFavoriteInvoice: Array<AccApFavoriteInvoiceEntity>;
  getARFavoriteInvoice: Array<AccArFavoriteInvoiceEntity>;
  getAPFavoritePayment: Array<AccApFavoritePaymentEntity>;
  getAPInvoice: Array<AccApInvoiceEntity>;
  getAPInvoiceCount: Scalars['Float'];
  getAPInvoicebyStatus: Array<AccApInvoiceEntity>;
  getAPInvoicebyStatusListing: Array<AccApInvoiceEntity>;
  getGeneralDOs: Scalars['JSON'];
  getDOItem: Scalars['JSON'];
  getSelfBilledInvoice: Array<AccApInvoiceEntity>;
  getARInvoice: Array<AccArInvoiceEntity>;
  getARInvoicebyStatus: Array<AccArInvoiceEntity>;
  getAPInvoiceItem: Array<AccApInvoiceItemEntity>;
  getARInvoiceItem: Array<AccArInvoiceItemEntity>;
  getAROfficialReceipt: Array<AccArOfficialReceiptEntity>;
  getAROfficialReceiptbyStatus: Array<AccArOfficialReceiptEntity>;
  getAPPayment: Array<AccApPaymentEntity>;
  getAPPaymentbyStatus: Array<AccApPaymentEntity>;
  getAPPaymentbyStatusListing: Array<AccApPaymentEntity>;
  getAPRefund: Array<AccApRefundEntity>;
  getAPRefundbyStatus: Array<AccApRefundEntity>;
  getARRefund: Array<AccArRefundEntity>;
  getARRefundbyStatus: Array<AccArRefundEntity>;
  getAPSelfBilled: Array<AccApSelfBilledEntity>;
  getAPSelfBilledbyStatus: Array<AccApSelfBilledEntity>;
  getAPSelfBilledbyStatusListing: Array<AccApSelfBilledEntity>;
  getAPInvoiceSelfBilled: Array<AccApSelfBilledEntity>;
  DocumentListing?: Maybe<Array<AttachmentEntity>>;
  viewLogo: AttachmentEntity;
  attachmentListing?: Maybe<Array<AttachmentEntity>>;
  fetchShareAttachment?: Maybe<Array<AttachmentEntity>>;
  getBankReconciliation: Array<BankReconciliationEntity>;
  getCheckingValidationBankRecon: Scalars['JSON'];
  getBankReconCBLedger: Array<LedgerEntity>;
  getBankReconciliationItem: Array<BankReconciliationItemEntity>;
  getBankReconciliationMatch: Array<BankReconciliationMatchEntity>;
  getBankTransfer: Array<BankTransferEntity>;
  getBankTransferbyStatus: Array<BankTransferEntity>;
  getApprovedBankTransfer: Array<Scalars['JSON']>;
  getExternalLedger: Array<ExternalLedgerEntity>;
  getCheckingValidationExtLedger: Scalars['JSON'];
  getFavoritePayment: Array<FavoritePaymentEntity>;
  getFavoriteReceipt: Array<FavoriteReceiptEntity>;
  getLedger: Array<LedgerEntity>;
  getLedgerCount: Scalars['Float'];
  getLedgerItem: Array<LedgerItemEntity>;
  getPayment: Array<PaymentEntity>;
  getPaymentbyStatus: Array<PaymentEntity>;
  getPaymentItem: Array<PaymentItemEntity>;
  getReceipt: Array<ReceiptEntity>;
  getReceiptbyStatus: Array<ReceiptEntity>;
  getReceiptItem: Array<ReceiptItemEntity>;
  GetGLJournalItemReport: Array<GlJournalItem>;
  getCompanyLetterTemplate: Array<CompanyLetterTemplateEntity>;
  getLetterTemplate: Array<LetterTemplateEntity>;
  getLetterTemplateByModule: Scalars['String'];
  getStandardLetter: Array<StandardLetterEntity>;
  getStandardLetterByCategory: Scalars['JSON'];
  getStandardLetterDataSource: Array<StandardLetterDataSourceEntity>;
  getStandardLetterParameter: Array<StandardLetterParameterEntity>;
  getAuditAdjustment: Array<AuditAdjustmentEntity>;
  getAuditAdjustmentbyStatus: Array<AuditAdjustmentEntity>;
  getAuditAdjustmentbyStatusListing: AuditAdjustmentReturnData;
  getAuditAdjustmentAdjDate: AccountPeriodEntity;
  getAuditAdjustmentItem: Array<AuditAdjustmentItemEntity>;
  getBudget: Array<BudgetEntity>;
  getBudgetbyStatus: Array<BudgetEntity>;
  getBudgetMasterCOA: Scalars['JSON'];
  getParentMasterCOAForTree: Scalars['JSON'];
  getBudgetCostCentre: Scalars['JSON'];
  getBudgetImportList: Scalars['JSON'];
  ImportExcelBudget: Scalars['JSON'];
  getBudgetItem: Array<BudgetItemEntity>;
  getBudgetItemCostCentre: Array<BudgetItemCostCentreEntity>;
  getBudgetItemPeriod: Array<BudgetItemPeriodEntity>;
  getExternalJournal: Array<ExternalJournalEntity>;
  getExternalJournalCount: Scalars['Float'];
  getCheckingValidationExtJournal: Scalars['JSON'];
  getFavoriteJournalProcessing: Array<FavoriteJournalProcessingEntity>;
  getDataSourceSettings: Array<DataSourceSettingsEntity>;
  getReportSettings: Array<ReportSettingsEntity>;
  printReport?: Maybe<PrintReportOutput>;
  DesignReport?: Maybe<PrintReportOutput>;
  testExtractReportDataService?: Maybe<Scalars['JSON']>;
  getJournal: Array<JournalEntity>;
  getJournalbyStatus: Array<JournalEntity>;
  getJournalbyStatusListing: JournalReturnData;
  getJournalItem: Array<JournalItemEntity>;
  getJournalProcessing: Array<JournalProcessingEntity>;
  getJournalProcessingbyStatus: Array<JournalProcessingEntity>;
  getJournalProcessingbyStatusListing: JournalProcessingReturnData;
  getJournalReporting: Array<JournalEntity>;
  latestOpenPeriodChecking?: Maybe<AccountPeriodEntity>;
  latestOpenYearChecking: AccountPeriodEntity;
  getLastClosedPeriod?: Maybe<AccountPeriodEntity>;
  needYearEndClose: Scalars['Boolean'];
  MonthEndYearEndDate?: Maybe<Scalars['DateTime']>;
  latestOpenPeriodCheckingDate?: Maybe<Scalars['JSON']>;
  getRecurringJournal: Array<RecurringJournalEntity>;
  getRecurringJournalbyStatus: Array<RecurringJournalEntity>;
  getRecurringJournalbyStatusListing: RecurringJournalReturnData;
  getRecurringJournalGeneration: Array<RecurringJournalGenerationEntity>;
  getRecurringJournalItem: Array<RecurringJournalItemEntity>;
  getRoutineJournal: Array<RoutineJournalEntity>;
  getRoutineJournalItem: Array<RoutineJournalItemEntity>;
  checkCBDoubleEntry: Scalars['JSON'];
  checkAccRecDoubleEntry: Scalars['JSON'];
  checkAccPayDoubleEntry: Scalars['JSON'];
  getExportedGLListing: Scalars['JSON'];
  getExportedGLDetail: Array<Scalars['JSON']>;
  getGLExport: Scalars['JSON'];
  getGLExportDetailByAccountCode: Scalars['JSON'];
  getCycleCount: Array<CycleCountEntity>;
  getCycleCountbyStatus: Array<CycleCountEntity>;
  getCycleCountListing: CycleCountType;
  getLatestCycleCountDate?: Maybe<Scalars['JSON']>;
  pendingICTransactionChecking: Scalars['Boolean'];
  pendingCycleCount: Scalars['Boolean'];
  getCycleCountItem: Array<CycleCountItemEntity>;
  getStockItemFromWarehouse: Scalars['JSON'];
  getStockItemCycleCount: Scalars['JSON'];
  getFavoriteStockRequisition: Array<FavoriteStockRequisitionEntity>;
  stockItemMovementChecking: Scalars['Float'];
  stockIssueInfoLine: Scalars['JSON'];
  stockReceiptInfoLine: Scalars['JSON'];
  stockTransferInfoLine: Scalars['JSON'];
  stockAdjustmentInfoLine: Scalars['JSON'];
  cycleCountInfoLine: Scalars['JSON'];
  stockRequisitionInfoLine: Scalars['JSON'];
  digitalReportInfoLine: Scalars['JSON'];
  stockIssueCount?: Maybe<Scalars['JSON']>;
  stockReceiptCount?: Maybe<Scalars['JSON']>;
  stockRequisitionCount?: Maybe<Scalars['JSON']>;
  stockTransferCount?: Maybe<Scalars['JSON']>;
  stockAdjustmentCount?: Maybe<Scalars['JSON']>;
  stockCostAdjustmentCount?: Maybe<Scalars['JSON']>;
  cycleCountCount?: Maybe<Scalars['JSON']>;
  getInventoryPolicy: Array<InventoryPolicyEntity>;
  getStockAdjustment: Array<StockAdjustmentEntity>;
  getStockAdjustmentbyStatus: Array<StockAdjustmentEntity>;
  getStockQtyAdjustmentListing: StockQtyAdjustmentType;
  getStockAdjustmentItem: Array<StockAdjustmentItemEntity>;
  getStockAdjustmentReason: Array<StockAdjustmentReasonEntity>;
  getStockCategory: Array<StockCategoryEntity>;
  getStockCostAdjustment: Array<StockCostAdjustmentEntity>;
  getStockCostAdjustmentbyStatus: Array<StockCostAdjustmentEntity>;
  getStockCostAdjustmentListing: StockCostAdjustmentType;
  getStockIssue: Array<StockIssueEntity>;
  getStockIssuebyStatus: Array<StockIssueEntity>;
  getStockIssueListing: StockIssueType;
  getStockIssueItem: Array<StockIssueItemEntity>;
  getStockReceipt: Array<StockReceiptEntity>;
  getStockReceiptbyStatus: Array<StockReceiptEntity>;
  getStockReceiptListing: StockReceiptType;
  getStockReceiptItem: Array<StockReceiptItemEntity>;
  getStockRequisition: Array<StockRequisitionEntity>;
  getStockRequisitionbyStatus: Array<StockRequisitionEntity>;
  getStockRequisitionListing?: Maybe<StockRequisitionType>;
  getStockRequisitionItem: Array<StockRequisitionItemEntity>;
  getStockTransfer: Array<StockTransferEntity>;
  getStockTransferbyStatus: Array<StockTransferEntity>;
  getStockTransferListing: StockTransferType;
  getStockTransferItem: Array<StockTransferItemEntity>;
  getStockTransferRequisition: Array<StockTransferRequisitionEntity>;
  getStockTransferRequisitionbyStatus: Array<StockTransferRequisitionEntity>;
  getStockTransferRequisitionListing?: Maybe<StockTransferRequisitionType>;
  getStockTransferRequisitionItem: Array<StockTransferRequisitionItemEntity>;
  getAccountMapping: Array<Glmfrs16AccountMappingEntity>;
  getCreditorSubmenuAmt?: Maybe<Array<CreditorDebtorSubInput>>;
  apAdvanceCount?: Maybe<Scalars['JSON']>;
  apAdvanceTotalAmt?: Maybe<Scalars['JSON']>;
  apAdvanceLatestUpdate?: Maybe<Scalars['JSON']>;
  apInvoiceCount?: Maybe<Scalars['JSON']>;
  apInvoiceTotalAmt?: Maybe<Scalars['JSON']>;
  apInvoiceLatestUpdate?: Maybe<Scalars['JSON']>;
  apCreditNoteCount?: Maybe<Scalars['JSON']>;
  apCreditNoteTotalAmt?: Maybe<Scalars['JSON']>;
  apCreditNoteLatestUpdate?: Maybe<Scalars['JSON']>;
  apCreditorCreditNoteCount?: Maybe<Scalars['JSON']>;
  apCreditorCreditNoteTotalAmt?: Maybe<Scalars['JSON']>;
  apCreditorCreditNoteLatestUpdate?: Maybe<Scalars['JSON']>;
  apDebitNoteCount?: Maybe<Scalars['JSON']>;
  apDebitNoteTotalAmt?: Maybe<Scalars['JSON']>;
  apDebitNoteLatestUpdate?: Maybe<Scalars['JSON']>;
  apCreditorDebitNoteCount?: Maybe<Scalars['JSON']>;
  apCreditorDebitNoteTotalAmt?: Maybe<Scalars['JSON']>;
  apCreditorDebitNoteLatestUpdate?: Maybe<Scalars['JSON']>;
  apSelfBilledCount?: Maybe<Scalars['JSON']>;
  apSelfBilledTotalAmt?: Maybe<Scalars['JSON']>;
  apSelfBilledLatestUpdate?: Maybe<Scalars['JSON']>;
  apPaymentCount?: Maybe<Scalars['JSON']>;
  apPaymentTotalAmt?: Maybe<Scalars['JSON']>;
  apPaymentLatestUpdate?: Maybe<Scalars['JSON']>;
  apBatchPaymentCount?: Maybe<Scalars['JSON']>;
  apRefundCount?: Maybe<Scalars['JSON']>;
  ceditorAccountCount?: Maybe<Scalars['JSON']>;
  apRefundTotalAmt?: Maybe<Scalars['JSON']>;
  apRefundLatestUpdate?: Maybe<Scalars['JSON']>;
  APDigitalReportDate?: Maybe<Scalars['DateTime']>;
  getCreditorTotalDocAmt?: Maybe<Array<CreditorInput>>;
  getDebtorTotalDocAmt?: Maybe<Array<DebtorInput>>;
  getEachSubDocAmt?: Maybe<Array<CreditorDebtorSubInput>>;
  getDebtorLatestUpdatedDate?: Maybe<Array<DebtorLatestUpdatedDateInput>>;
  getCreditorLatestUpdatedDate?: Maybe<Array<CreditorLatestUpdatedDateInput>>;
  getARSubmenuTotal?: Maybe<Array<CreditorDebtorSubInput>>;
  arAdvanceCount?: Maybe<Scalars['JSON']>;
  arAdvanceTotalAmt?: Maybe<Scalars['JSON']>;
  arAdvanceLatestUpdate?: Maybe<Scalars['JSON']>;
  arInvoiceCount?: Maybe<Scalars['JSON']>;
  arInvoiceTotalAmt?: Maybe<Scalars['JSON']>;
  arInvoiceLatestUpdate?: Maybe<Scalars['JSON']>;
  arCreditNoteCount?: Maybe<Scalars['JSON']>;
  arCreditNoteTotalAmt?: Maybe<Scalars['JSON']>;
  arCreditNoteLatestUpdate?: Maybe<Scalars['JSON']>;
  arDebitNoteCount?: Maybe<Scalars['JSON']>;
  arDebitNoteTotalAmt?: Maybe<Scalars['JSON']>;
  arDebitNoteLatestUpdate?: Maybe<Scalars['JSON']>;
  arOfficialReceiptCount?: Maybe<Scalars['JSON']>;
  arOfficialReceiptTotalAmt?: Maybe<Scalars['JSON']>;
  arOfficialReceiptLatestUpdate?: Maybe<Scalars['JSON']>;
  arRefundCount?: Maybe<Scalars['JSON']>;
  arRefundTotalAmt?: Maybe<Scalars['JSON']>;
  arRefundLatestUpdate?: Maybe<Scalars['JSON']>;
  paymentCount?: Maybe<Scalars['JSON']>;
  receiptCount?: Maybe<Scalars['JSON']>;
  bankTransferCount?: Maybe<Scalars['JSON']>;
  BankReconciliationDate?: Maybe<Scalars['DateTime']>;
  BankTransferDate?: Maybe<Scalars['DateTime']>;
  PaymentDate?: Maybe<Scalars['DateTime']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ExternalLedgerDate?: Maybe<Scalars['DateTime']>;
  CashBookCount?: Maybe<Scalars['JSON']>;
  CostCentreDate?: Maybe<Scalars['DateTime']>;
  CostCentreDefinitionDate?: Maybe<Scalars['DateTime']>;
  EntityCOADate?: Maybe<Scalars['DateTime']>;
  AccountPeriodDate?: Maybe<Scalars['String']>;
  JournalTypeDate?: Maybe<Scalars['DateTime']>;
  BudgetDate?: Maybe<Scalars['DateTime']>;
  BankAccountDate?: Maybe<Scalars['DateTime']>;
  CompanySumDate?: Maybe<Scalars['DateTime']>;
  NumberingStructureGeneralLedgerDate?: Maybe<Scalars['JSON']>;
  NumberingStructureCashBookDate?: Maybe<Scalars['JSON']>;
  NumberingStructureAPDate?: Maybe<Scalars['JSON']>;
  NumberingStructureARDate?: Maybe<Scalars['JSON']>;
  NumberingStructureICDate?: Maybe<Scalars['JSON']>;
  ApprovalPolicyAssignmentDate?: Maybe<Scalars['DateTime']>;
  OriginalBudgetApprovalPolicyAssignmentDate?: Maybe<Scalars['DateTime']>;
  BudgetRevisionApprovalPolicyAssignmentDate?: Maybe<Scalars['DateTime']>;
  RecurringJournalApprovalPolicyAssignmentDate?: Maybe<Scalars['DateTime']>;
  JournalProcessingApprovalPolicyAssignmentDate?: Maybe<Scalars['DateTime']>;
  AuditAdjustmentApprovalPolicyAssignmentDate?: Maybe<Scalars['DateTime']>;
  BankTransferStandardApprovalFlowDate?: Maybe<Scalars['DateTime']>;
  ReceiptStandardApprovalFlowDate?: Maybe<Scalars['DateTime']>;
  PaymentStandardApprovalFlowDate?: Maybe<Scalars['DateTime']>;
  CompanyAuthorizationDate?: Maybe<Scalars['DateTime']>;
  journalProcessingCount?: Maybe<Scalars['JSON']>;
  recurringJournalCount?: Maybe<Scalars['JSON']>;
  auditAdjustmentCount?: Maybe<Scalars['JSON']>;
  RoutineJournalDate?: Maybe<Scalars['DateTime']>;
  RecurringJournalDate?: Maybe<Scalars['DateTime']>;
  JournalProcessingDate?: Maybe<Scalars['DateTime']>;
  AuditAdjustmentDate?: Maybe<Scalars['DateTime']>;
  JournalPending?: Maybe<Scalars['JSON']>;
  GenerateRJCount: Scalars['Int'];
  OriginalBudgetDate?: Maybe<Scalars['DateTime']>;
  ExternalJournalDate?: Maybe<Scalars['DateTime']>;
  DataSourceDate?: Maybe<Scalars['DateTime']>;
  FinancialReportDate?: Maybe<Scalars['DateTime']>;
  GLDigitalReportDate?: Maybe<Scalars['DateTime']>;
  BankProfileDate?: Maybe<Scalars['DateTime']>;
  CurrencyExchangeDate?: Maybe<Scalars['DateTime']>;
  CurrencyDate?: Maybe<Scalars['DateTime']>;
  TaxDate?: Maybe<Scalars['DateTime']>;
  CompanyDate?: Maybe<Scalars['DateTime']>;
  UserDate: Scalars['DateTime'];
  RoleDate?: Maybe<Scalars['DateTime']>;
  PaymentMethodDate?: Maybe<Scalars['DateTime']>;
  CreditCardTypeDate?: Maybe<Scalars['DateTime']>;
  ExpenseClassDate?: Maybe<Scalars['DateTime']>;
  ExpenseItemDate?: Maybe<Scalars['DateTime']>;
  CreditorTypeDate?: Maybe<Scalars['DateTime']>;
  PolicySettingsDate?: Maybe<Scalars['DateTime']>;
  ApprovalPolicyDate?: Maybe<Scalars['DateTime']>;
  PaymentApprovalDate?: Maybe<Scalars['DateTime']>;
  ReceiptApprovalDate?: Maybe<Scalars['DateTime']>;
  BankTransferApprovalDate?: Maybe<Scalars['DateTime']>;
  CreditorTypeCount?: Maybe<Scalars['JSON']>;
  WarehouseDate?: Maybe<Scalars['DateTime']>;
  UOMDate?: Maybe<Scalars['DateTime']>;
  UOMExchangeDate?: Maybe<Scalars['DateTime']>;
  StockCategoryDate?: Maybe<Scalars['DateTime']>;
  StockAdjustmentReasonDate?: Maybe<Scalars['DateTime']>;
  DocumentNumberingDate?: Maybe<Scalars['DateTime']>;
  StockEnquiryDate?: Maybe<Scalars['DateTime']>;
  StockReceiptDate?: Maybe<Scalars['DateTime']>;
  StockIssueDate?: Maybe<Scalars['DateTime']>;
  StockTransferDate?: Maybe<Scalars['DateTime']>;
  CycleCountDate?: Maybe<Scalars['DateTime']>;
  StockAdjustmentDate?: Maybe<Scalars['DateTime']>;
  getCompany: Array<CompanyEntity>;
  getParentCompany: Array<CompanyEntity>;
  getCompanyListing: CompanyReturnType;
  getAssignedCompanyByUser: Scalars['JSON'];
  getAssignedWarehouseCompanyByUser: Scalars['JSON'];
  getActiveCompanyCount: Scalars['Float'];
  getCompanyWithAccount: Scalars['JSON'];
  getCompanyNameWithAccount: Scalars['JSON'];
  getCompanyEntityWithAccount: Array<CompanyEntity>;
  getCheckingValidationCompany: Scalars['JSON'];
  getEInvoiceCompanyStartDate: Scalars['Boolean'];
  getLocalBank: Array<LocalBankEntity>;
  testDocNoOutput: Scalars['JSON'];
  getEntityRoleUserAssignment: Array<AccRoleUserEntAssignmentEntity>;
  getRole: Array<AccRoleEntity>;
  getRoleWithAccount: Array<AccRoleEntity>;
  AccuserRoleIDs: Array<AccuserRoleId>;
  getAccountPermission: Scalars['JSON'];
  getRolePermission: Scalars['JSON'];
  getUsersRoleCompany: Scalars['JSON'];
  getDrawerPermission: Scalars['JSON'];
  getAccountPeriod: Array<AccountPeriodEntity>;
  getAccountYear: Scalars['JSON'];
  getNextAccPeriod: Scalars['JSON'];
  getSelectedAccountPeriod: AccountPeriodEntity;
  accountPeriodChecking: AccountPeriodCheck;
  getOpenAccPeriodDateRange: AccountPeriodDateRange;
  CheckExistingAccountPeriod: CheckExistingAccountPeriod;
  getPreviousPeriodOfYear: AccountPeriodEntity;
  getARAging: Array<ArAgingEntity>;
  getAPAging: Array<ApAgingEntity>;
  getApprovalPolicy: Array<AccApprovalPolicyEntity>;
  getApprovalPolicyAssignment?: Maybe<ProcurementApprovalPolicyAssignmentEntity>;
  getAllApprovalPolicyAssignment?: Maybe<Array<ProcurementApprovalPolicyAssignmentEntity>>;
  getAllApprovalPolicy?: Maybe<Array<ProcurementApprovalPolicyEntity>>;
  getGPApprovalPolicyInfoline: Scalars['JSON'];
  getPendingGP: Scalars['JSON'];
  getBankAccount: Array<AccBankAccountEntity>;
  getCheckingValidationBA: Scalars['JSON'];
  getBankProfile: Array<BankProfileEntity>;
  getBankProfileWithAccount: Array<BankProfileEntity>;
  getCheckingValidationBP: Scalars['JSON'];
  getBillItem: Array<BillItemEntity>;
  getClassificationCode: Array<EInvoiceClassificationEntity>;
  getCOAStructure: Array<CoaStructureEntity>;
  getCompanyAssignedUsersByRole: Scalars['JSON'];
  getUserCompanyAssignedUsersByRole: Scalars['JSON'];
  getContact: Array<AccContactEntity>;
  getAllCostCentre: Array<CostCentreEntity>;
  getCostCentreDefinitionAsgmt: Array<CostCentreDefinitionAsgmtEntity>;
  getCostCentre: Array<CostCentreEntity>;
  getCostCentreListing: Scalars['JSON'];
  getNextLevelCCDefinition: Scalars['JSON'];
  getParentCostCentreForTree: Scalars['JSON'];
  getCostCentreWithAccount: CostCentreEntity;
  getCostCentreEntityWithAccount: Array<CostCentreEntity>;
  getCostCentreCOA: Array<CostCentreCoaEntity>;
  getCostCentreDefinitionHeader: Array<CostCentreDefinitionHeaderEntity>;
  getCostCentreDefinitionDetail: Array<CostCentreDefinitionDetailEntity>;
  getCostCentreDefinitionListing: Array<CostCentreDefinitionHeaderEntity>;
  getCostCentreDefinition: CostCentreDefSummary;
  getCostCentreDefinitionHeaderDetails: Array<CostCentreDefinitionDetailEntity>;
  getCostCentreType: Array<CostCentreTypeEntity>;
  getCreditCardType: Array<CreditCardTypeEntity>;
  getCreditorAccount: Array<CreditorAccountEntity>;
  getCreditorAccountCount: Scalars['Float'];
  getCreditorProfileWithAccount: Array<CreditorAccountEntity>;
  getCheckingValidationCreditorProfile: Scalars['JSON'];
  getCreditorAccountCompanyAssignment: Array<CreditorAccountCompanyAssignmentEntity>;
  getCreditorAccountCompanyAssignmentCount: Scalars['Float'];
  CreditorAccountTotalDue: Scalars['JSON'];
  getCreditorAccountCompanyAssignmentListing: CreditorAccountCompanyAssignmentReturnData;
  getCreditorContact: Array<CreditorContactEntity>;
  getCreditorDirector: Array<CreditorDirectorEntity>;
  getCreditorType: Array<CreditorTypeEntity>;
  getCreditorTypeCount: Scalars['Float'];
  getCreditorTypeWithAccount: Array<CreditorTypeEntity>;
  getCurrency: Array<CurrencyEntity>;
  getCurrencyExchange: Array<AccCurrencyExchangeEntity>;
  createCurrencyExchange: Scalars['JSON'];
  getCurrencyExchangeData: Scalars['JSON'];
  getDebtorAccount: Array<DebtorAccountEntity>;
  DebtorAccountTotalDue: Scalars['JSON'];
  getCheckingValidationDebtorAccount: Scalars['JSON'];
  getDebtorContact: Array<DebtorContactEntity>;
  getDebtorType: Array<DebtorTypeEntity>;
  getDeliveryLocation: Array<DeliveryLocationEntity>;
  getDocumentDateValidation: Array<DocumentDateValidationEntity>;
  getDocumentNumberDetail: Array<DocumentNumberDetailEntity>;
  getDocumentNumberHeader: Array<DocumentNumberHeaderEntity>;
  getDocumentNumberHeaderWithPerm: Array<DocumentNumberHeaderEntity>;
  getDocNumByRefTable: Array<DocumentNumberHeaderEntity>;
  getDocumentNumberHeaderAndDetail: Array<DocumentNumberDetailEntity>;
  getEntityCOA: Array<EntityCoaEntity>;
  getAssignedMasterCOA: Array<MasterCoaEntity>;
  getEntityCOAWithAccount: Array<EntityCoaEntity>;
  getEntityCOAFiltered: Array<EntityCoaEntity>;
  getExpenseClass: Array<ExpenseClassEntity>;
  getParentExpenseItemCategoryCOAAccountType: Scalars['JSON'];
  getExpenseItem: Array<ExpenseItemEntity>;
  getSelectedItem: Scalars['JSON'];
  getItemByCompany: Array<ExpenseItemEntity>;
  getItemClassCompanyAssignment: Array<ItemClassCompanyAssignmentEntity>;
  getJournalType: Array<JournalTypeEntity>;
  getMasterCOA: Array<MasterCoaEntity>;
  getMasterCOAAccountType: Array<MasterCoaEntity>;
  getMasterCOAListing: Array<MasterCoaEntity>;
  getParentMasterCOA: Array<MasterCoaEntity>;
  accountCodeChecking: Scalars['JSON'];
  getFirstMasterCOA: Scalars['Boolean'];
  getMasterCOAWithAccount: Array<MasterCoaEntity>;
  getMasterCOAFiltered: Array<MasterCoaEntity>;
  getCheckingValidationCOA: Scalars['JSON'];
  getMSICCode: Array<EInvoiceMsicEntity>;
  getNumberFormat: Array<NumberFormatEntity>;
  getPaymentMethod: Array<PaymentMethodEntity>;
  getRetainEarningAccountDef: Array<RetainEarningAccountDefEntity>;
  getStockItem: Array<StockItemEntity>;
  getStockLedger: Scalars['JSON'];
  stockItemWarehouseSummary: Scalars['JSON'];
  getStockItemWarehouse: Array<StockItemWarehouseEntity>;
  getStockItemWarehouseByCostCentre: Array<StockItemWarehouseEntity>;
  getStockItemWarehouseListing: StockItemWarehouseType;
  getTaxScheme: Array<TaxSchemeEntity>;
  getTaxEffectiveDate: Array<TaxEffectiveDateEntity>;
  getTaxType: Array<TaxTypeEntity>;
  getTaxSchemeWithAccount: Array<TaxSchemeEntity>;
  getTransactionType: Array<TransactionTypeEntity>;
  getUOM: Array<AccUomEntity>;
  getUOMWithAccount: Array<AccUomEntity>;
  getUOMExchange: Array<AccUomExchangeEntity>;
  getUomExchangeData: Scalars['JSON'];
  getUOMExchangeWithAccount: Scalars['JSON'];
  getUOMExchangeList: Scalars['JSON'];
  getWarehouse: Array<WarehouseEntity>;
  getCompanyWarehouse?: Maybe<Scalars['JSON']>;
  approvableChecking: Scalars['Boolean'];
  getToDoSummary: PendingToDo;
  getWorkDeskHistory: Array<ToDo>;
  getWorkDeskToDo: Array<ToDo>;
  getApprovalLog?: Maybe<Array<Scalars['JSON']>>;
  getProcurementApprovalPolicy: Array<ProcurementApprovalPolicyEntity>;
  getProcurementApprovalPolicyAssignment: Array<ProcurementApprovalPolicyAssignmentEntity>;
  getBulkPurchasePrice: Array<BulkPurchasePriceHeaderEntity>;
  getSupplierAccountByCompany: Scalars['JSON'];
  getGeneralDO: Array<DoHeaderEntity>;
  getGeneralDOItem: Array<DoItemEntity>;
  getGeneralDOCount: Scalars['Float'];
  getSupplierByDOStatus: Array<CreditorAccountEntity>;
  getWarehouseDeliveryLocation?: Maybe<Scalars['JSON']>;
  getGeneralPOs: Array<PoHeaderEntity>;
  getOldGeneralPOs?: Maybe<Scalars['JSON']>;
  getGeneralGRTNs: Array<GrtnHeaderEntity>;
  getFavoriteApprover: Array<FavoriteApproverEntity>;
  getFavoritePRHeader: Array<FavoritePrEntity>;
  getGeneralPurchasePolicy: Array<GeneralPurchasePolicyEntity>;
  getGRTNHeader: Array<GrtnHeaderEntity>;
  getGRTNHeaderCount: Scalars['Float'];
  getGeneralPRItem: Array<PrItemEntity>;
  getCentralisedPRItems: Array<PrItemEntity>;
  getPOHeader: Array<PoHeaderEntity>;
  getPOHeaderCount: Scalars['Float'];
  getSupplierByPOStatus: Array<CreditorAccountEntity>;
  checkingCancelClosePO: Scalars['Boolean'];
  getPRHeader: Array<PrHeaderEntity>;
  getPRHeaderCount: Scalars['Float'];
  getPRNoList: Array<Scalars['JSON']>;
  getOutstandingGeneralPRItems: Array<PrItemEntity>;
  getOutstandingGeneralPRItemsListing: OsprItemReturnData;
  getOSGeneralPR: Array<PrHeaderEntity>;
  checkingCancelClosePR: Scalars['Boolean'];
  getRFQHeader: Array<RfqHeaderEntity>;
  getRFQItem: Array<RfqItemEntity>;
  getRFQbyStatus: Array<RfqHeaderEntity>;
  getRFQbyStatusListing: RfqHeaderReturnData;
  getInvitedSupplier?: Maybe<Scalars['JSON']>;
  getRFQSupplierInvitation: Array<RfqSupplierInvitationEntity>;
  getRFQSupplierSubmission: Array<RfqSupplierSubmissionEntity>;
  getPRItemByPRIDForRFQ: Array<Scalars['JSON']>;
  getScheduledPurchases: Array<ScheduledPurchasesEntity>;
  getScheduledPurchasesCount: Scalars['Float'];
  getLatestPurchaseSupplierFromPO?: Maybe<Scalars['JSON']>;
  getItem: Array<ItemEntity>;
  getCheckingValidationItem: Scalars['JSON'];
  getLatestTaxByTaxSchemeIDs: Scalars['JSON'];
  getWarehouseByStockItem: Scalars['JSON'];
  getItemByCompanyAssignment: Array<ItemEntity>;
  getItemCategory: Array<ItemCategoryEntity>;
  getItemCategoryCompanyAssignment: Array<ItemCategoryCompanyAssignmentEntity>;
  getPurchaseItemVariancePolicy: Array<PurchaseItemVariancePolicyEntity>;
  getSiteWithAccount: Scalars['JSON'];
  getSupplierLedgerByCreditorAccount: Array<Scalars['JSON']>;
  getSupplierAccount: Array<Scalars['JSON']>;
  getSupplierAccountListing: CreditorAccountReturnData;
  procurementLoggedInUserProfile: UserEntity;
  getAdjustmentType: Scalars['Boolean'];
  getDocumentFolder?: Maybe<Array<DocumentFolderEntity>>;
  lastApprovalPolicy?: Maybe<Scalars['DateTime']>;
  lastNumberingStructure?: Maybe<Scalars['DateTime']>;
  activeDeliveryLocationCount: Scalars['Float'];
  scheduledPurchaseInfoCount: Scalars['Float'];
  prInfoCount: Scalars['JSON'];
  rfqCount: Scalars['Float'];
  poInfoCount: Scalars['JSON'];
  doInfoCount: Scalars['Float'];
  lastDODate?: Maybe<Scalars['String']>;
  grtnInfoCount: Scalars['Float'];
  lastGRTNDate?: Maybe<Scalars['String']>;
  activeSupplierCategoryCount: Scalars['Float'];
  supplierCategoryCount: Scalars['JSON'];
  activeSupplierProfileCount: Scalars['Float'];
  supplierProfileCount: Scalars['JSON'];
  activeUomCount: Scalars['Float'];
  lastUomExchange?: Maybe<Scalars['DateTime']>;
  activePurchaseCategoryCount: Scalars['Float'];
  activeItemCount: Scalars['Float'];
  activeBulkPurchaseCount: Scalars['Float'];
  lastPurchaseVariance?: Maybe<Scalars['DateTime']>;
  purchaseVariancePercenetage?: Maybe<Scalars['Float']>;
};


export type QueryGetUserRoleArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
};


export type QueryGetUsersByAccountArgs = {
  accountID: Scalars['String'];
};


export type QueryGetUserArgs = {
  ID: Scalars['String'];
};


export type QueryGetUsersbyIdsArgs = {
  userIDs: Array<Scalars['String']>;
};


export type QueryIsUserValidArgs = {
  userID: Scalars['String'];
};


export type QueryGetUsersByAccountAndSoftwareArgs = {
  superUserBool?: Maybe<UserSelection>;
  status?: Maybe<CommonStatus>;
};


export type QueryGetAssignedUserByCompanyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetRoleNameByUserArgs = {
  ID: Scalars['String'];
};


export type QueryGetApAdvanceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AdvanceID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetApAdvancebyStatusArgs = {
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApAdvancebyStatusListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetArAdvanceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AdvanceID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetArAdvancebyStatusArgs = {
  DebtorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetAllocationDocumentArgs = {
  isEinvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  billExpenseItemID?: Maybe<Scalars['String']>;
  creditorDebtorID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Array<Scalars['String']>>;
  companyID: Scalars['String'];
  refTable: Scalars['String'];
};


export type QueryGetDocumentDetailArgs = {
  expBillItemID: Scalars['String'];
  expBillItem: Scalars['String'];
  refTableItem: Scalars['String'];
  refTable: Scalars['String'];
  prefixID: Scalars['String'];
  prefix: Scalars['String'];
};


export type QueryGetAllocationItemDocumentArgs = {
  documentID: Scalars['String'];
  entityItemID?: Maybe<Array<Scalars['String']>>;
  refTable: Scalars['String'];
};


export type QueryGetApBatchPaymentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BatchPaymentID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetApBatchPaymentbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  BatchPaymentID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApBatchPaymentbyStatusListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  BatchPaymentID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApCreditNoteArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CreditNoteID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetApCreditNotebyStatusArgs = {
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApCreditNotebyStatusListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetArCreditNoteArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CreditNoteID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetArCreditNotebyStatusArgs = {
  DebtorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApCreditorCreditNoteArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CreditorCreditNoteID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr?: Maybe<Array<Scalars['String']>>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetApCreditorCreditNotebyStatusArgs = {
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApCreditorCreditNotebyStatusListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetGeneralGrtnArgs = {
  GRTNItemIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
};


export type QueryGetGrtnItemArgs = {
  GRTNHeaderID: Array<Scalars['String']>;
};


export type QueryGetApCreditNoteItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CreditNoteItemID?: Maybe<Scalars['String']>;
  CreditNoteID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
};


export type QueryGetApDebitNoteArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DebitNoteID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetApDebitNotebyStatusArgs = {
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApDebitNotebyStatusListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetArDebitNoteArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DebitNoteID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetArDebitNotebyStatusArgs = {
  DebtorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApCreditorDebitNoteArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CreditorDebitNoteID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetApCreditorDebitNotebyStatusArgs = {
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApCreditorDebitNotebyStatusListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApDebitNoteItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DebitNoteItemID?: Maybe<Scalars['String']>;
  DebitNoteID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
};


export type QueryGetArDebitNoteItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DebitNoteItemID?: Maybe<Scalars['String']>;
  DebitNoteID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
};


export type QueryGetApFavoriteInvoiceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  FavoriteInvoiceID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetArFavoriteInvoiceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  FavoriteInvoiceID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetApFavoritePaymentArgs = {
  FavoritePaymentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
};


export type QueryGetApInvoiceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  InvoiceID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetApInvoiceCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  InvoiceID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetApInvoicebyStatusArgs = {
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApInvoicebyStatusListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetGeneralDOsArgs = {
  DOItemIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
};


export type QueryGetDoItemArgs = {
  DOHeaderID: Array<Scalars['String']>;
};


export type QueryGetSelfBilledInvoiceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetArInvoiceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  InvoiceID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetArInvoicebyStatusArgs = {
  DebtorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApInvoiceItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  InvoiceItemID?: Maybe<Scalars['String']>;
  InvoiceID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetArInvoiceItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  InvoiceItemID?: Maybe<Scalars['String']>;
  InvoiceID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetArOfficialReceiptArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ReceiptID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetArOfficialReceiptbyStatusArgs = {
  DebtorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApPaymentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  PaymentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetApPaymentbyStatusArgs = {
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApPaymentbyStatusListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApRefundArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  RefundID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetApRefundbyStatusArgs = {
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetArRefundArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  RefundID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetArRefundbyStatusArgs = {
  DebtorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApSelfBilledArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  SelfBilledID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetApSelfBilledbyStatusArgs = {
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApSelfBilledbyStatusListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApInvoiceSelfBilledArgs = {
  CreditorAccountID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryDocumentListingArgs = {
  hasDescription?: Maybe<Scalars['Boolean']>;
  notDescription?: Maybe<Scalars['String']>;
  documentFolderID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
};


export type QueryViewLogoArgs = {
  description?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
};


export type QueryAttachmentListingArgs = {
  refID?: Maybe<Scalars['String']>;
};


export type QueryFetchShareAttachmentArgs = {
  file: Scalars['Upload'];
  Prefix: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetBankReconciliationArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BankReconciliationID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetCheckingValidationBankReconArgs = {
  BankAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  BankReconciliationItemImportInput: Array<BankReconciliationItemImportInput>;
};


export type QueryGetBankReconCbLedgerArgs = {
  DocDate: Scalars['String'];
  Mode: Scalars['String'];
  BankAccountID?: Maybe<Scalars['String']>;
  BankReconciliationID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetBankReconciliationItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BankReconciliationItemID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BankReconciliationID?: Maybe<Scalars['String']>;
  IsMatch?: Maybe<Scalars['Boolean']>;
};


export type QueryGetBankReconciliationMatchArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BankReconciliationMatchID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BankReconciliationID?: Maybe<Scalars['String']>;
};


export type QueryGetBankTransferArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BankTransferID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
  FromBankAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetBankTransferbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  FromBankAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetApprovedBankTransferArgs = {
  StatusArr: Array<Scalars['String']>;
  ToBankAccountID?: Maybe<Scalars['String']>;
  FromBankAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetExternalLedgerArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ExternalLedgerID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetCheckingValidationExtLedgerArgs = {
  CompanyID: Scalars['String'];
  ExternalLedgerImportInput: Array<ExternalLedgerImportInput>;
};


export type QueryGetFavoritePaymentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  FavoritePaymentID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetFavoriteReceiptArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  FavoriteReceiptID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetLedgerArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  LedgerID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
  IsManual?: Maybe<Scalars['Boolean']>;
  IsMatch?: Maybe<Scalars['Boolean']>;
  RefTable?: Maybe<Scalars['String']>;
};


export type QueryGetLedgerCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  LedgerID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
  IsManual?: Maybe<Scalars['Boolean']>;
  IsMatch?: Maybe<Scalars['Boolean']>;
  RefTable?: Maybe<Scalars['String']>;
};


export type QueryGetLedgerItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  LedgerItemID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetPaymentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  PaymentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetPaymentbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  BankAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetPaymentItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  PaymentItemID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
};


export type QueryGetReceiptArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ReceiptID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetReceiptbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  BankAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetReceiptItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ReceiptItemID?: Maybe<Scalars['String']>;
  ReceiptID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
};


export type QueryGetGlJournalItemReportArgs = {
  AccountCode?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<Scalars['String']>;
  JournalNo?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
};


export type QueryGetCompanyLetterTemplateArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CompanyLetterTemplateID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  TemplateBody?: Maybe<Scalars['String']>;
  StandardLetterID?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
};


export type QueryGetLetterTemplateArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  LetterTemplateID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  TemplateBody?: Maybe<Scalars['String']>;
  StandardLetterID?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
};


export type QueryGetLetterTemplateByModuleArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  LetterType: DocumentType;
  LetterCategory: ModuleType;
};


export type QueryGetStandardLetterArgs = {
  LetterCategory?: Maybe<Scalars['String']>;
  StandardLetterID?: Maybe<Scalars['String']>;
};


export type QueryGetStandardLetterDataSourceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StandardLetterDataSourceID?: Maybe<Scalars['String']>;
  StandardLetterID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};


export type QueryGetStandardLetterParameterArgs = {
  StandardLetterID?: Maybe<Scalars['String']>;
};


export type QueryGetAuditAdjustmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AuditAdjustmentID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetAuditAdjustmentbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetAuditAdjustmentbyStatusListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetAuditAdjustmentAdjDateArgs = {
  AdjYearOrPeriod: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetAuditAdjustmentItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AuditAdjustmentItemID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  AuditAdjustmentID?: Maybe<Scalars['String']>;
};


export type QueryGetBudgetArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BudgetID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Fyear?: Maybe<Scalars['String']>;
  Version?: Maybe<Scalars['String']>;
};


export type QueryGetBudgetbyStatusArgs = {
  Fyear?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetBudgetMasterCoaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetParentMasterCoaForTreeArgs = {
  CompanyID: Scalars['String'];
  MasterCOABudgetArr?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetBudgetCostCentreArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetBudgetImportListArgs = {
  PeriodBudgetItemInput?: Maybe<Array<PeriodBudgetItemInput>>;
  CompanyID: Scalars['String'];
};


export type QueryImportExcelBudgetArgs = {
  BudgetexcelImportInput: Scalars['String'];
  Year: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryGetBudgetItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BudgetItemID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetBudgetItemCostCentreArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BudgetItemCostCentreID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BudgetItemID?: Maybe<Scalars['String']>;
};


export type QueryGetBudgetItemPeriodArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BudgetItemPeriodID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BudgetItemCostCentreID?: Maybe<Scalars['String']>;
};


export type QueryGetExternalJournalArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ExternalJournalID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RefRecordID?: Maybe<Scalars['String']>;
  IsManual?: Maybe<Scalars['Boolean']>;
};


export type QueryGetExternalJournalCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ExternalJournalID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RefRecordID?: Maybe<Scalars['String']>;
  IsManual?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCheckingValidationExtJournalArgs = {
  CompanyID: Scalars['String'];
  ExternalJournalImportInput: Array<ExternalJournalImportInput>;
};


export type QueryGetFavoriteJournalProcessingArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  FavoriteJournalProcessingID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetDataSourceSettingsArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DataSourceSettingsID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
};


export type QueryGetReportSettingsArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ReportSettingsID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  FileName?: Maybe<Scalars['String']>;
};


export type QueryPrintReportArgs = {
  FilterZero: Scalars['Boolean'];
  FPeriod: Scalars['Float'];
  FYear: Scalars['Float'];
  CompanyID: Scalars['String'];
  ReportSettingsID: Scalars['String'];
};


export type QueryDesignReportArgs = {
  ReportSettingsID: Scalars['String'];
};


export type QueryTestExtractReportDataServiceArgs = {
  FilterZero: Scalars['Boolean'];
  FPeriod: Scalars['Float'];
  FYear: Scalars['Float'];
  CompanyID: Scalars['String'];
  ReportSettingsID: Scalars['String'];
};


export type QueryGetJournalArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  JournalID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RefRecordID?: Maybe<Scalars['String']>;
  IsManual?: Maybe<Scalars['Boolean']>;
};


export type QueryGetJournalbyStatusArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  TxnArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetJournalbyStatusListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  TxnArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetJournalItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  JournalItemID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  JournalID?: Maybe<Scalars['String']>;
};


export type QueryGetJournalProcessingArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  JournalProcessingID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetJournalProcessingbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetJournalProcessingbyStatusListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetJournalReportingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryLatestOpenPeriodCheckingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryLatestOpenYearCheckingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetLastClosedPeriodArgs = {
  CompanyID: Scalars['String'];
};


export type QueryNeedYearEndCloseArgs = {
  CompanyID: Scalars['String'];
};


export type QueryMonthEndYearEndDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryLatestOpenPeriodCheckingDateArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetRecurringJournalArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  RecurringJournalID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JournalID?: Maybe<Scalars['String']>;
};


export type QueryGetRecurringJournalbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetRecurringJournalbyStatusListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetRecurringJournalGenerationArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetRecurringJournalItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  RecurringJournalItemID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetRoutineJournalArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  RoutineJournalID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetRoutineJournalItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  RoutineJournalItemID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryCheckCbDoubleEntryArgs = {
  isBankTransfer?: Maybe<Scalars['Boolean']>;
  RefTable: Scalars['String'];
  suffixID: Scalars['String'];
  CompanyID: Scalars['String'];
  entityID: Scalars['String'];
};


export type QueryCheckAccRecDoubleEntryArgs = {
  isReceipt?: Maybe<Scalars['Boolean']>;
  RefTable: Scalars['String'];
  suffixID: Scalars['String'];
  CompanyID: Scalars['String'];
  entityID: Scalars['String'];
};


export type QueryCheckAccPayDoubleEntryArgs = {
  isPayment?: Maybe<Scalars['Boolean']>;
  RefTable: Scalars['String'];
  suffixID: Scalars['String'];
  CompanyID: Scalars['String'];
  entityID: Scalars['String'];
};


export type QueryGetExportedGlListingArgs = {
  prefix: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetExportedGlDetailArgs = {
  prefix: Scalars['String'];
  BatchNo: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryGetGlExportArgs = {
  prefix: Scalars['String'];
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryGetGlExportDetailByAccountCodeArgs = {
  prefix: Scalars['String'];
  AccountCode: Scalars['String'];
  CompanyID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryGetCycleCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CycleCountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
};


export type QueryGetCycleCountbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetCycleCountListingArgs = {
  StatusArr: Array<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetLatestCycleCountDateArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryPendingIcTransactionCheckingArgs = {
  DocDate?: Maybe<Scalars['DateTime']>;
  WarehouseID: Scalars['String'];
};


export type QueryPendingCycleCountArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryGetCycleCountItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CycleCountItemID?: Maybe<Scalars['String']>;
  CycleCountID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsReconciled?: Maybe<Scalars['Boolean']>;
  DocDate?: Maybe<Scalars['String']>;
};


export type QueryGetStockItemFromWarehouseArgs = {
  WarehouseID?: Maybe<Scalars['String']>;
};


export type QueryGetStockItemCycleCountArgs = {
  DocDate?: Maybe<Scalars['DateTime']>;
  WarehouseID?: Maybe<Scalars['String']>;
};


export type QueryGetFavoriteStockRequisitionArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  FavoriteStockRequisitionID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
};


export type QueryStockItemMovementCheckingArgs = {
  ICItemInput?: Maybe<Array<IcItemInput>>;
  input: StockItemMovementInput;
};


export type QueryStockIssueInfoLineArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryStockReceiptInfoLineArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryStockTransferInfoLineArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryStockAdjustmentInfoLineArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryCycleCountInfoLineArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryStockRequisitionInfoLineArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryDigitalReportInfoLineArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryStockIssueCountArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryStockReceiptCountArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryStockRequisitionCountArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryStockTransferCountArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryStockAdjustmentCountArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryStockCostAdjustmentCountArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryCycleCountCountArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryGetInventoryPolicyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  InventoryPolicyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetStockAdjustmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockAdjustmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
};


export type QueryGetStockAdjustmentbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetStockQtyAdjustmentListingArgs = {
  StatusArr: Array<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetStockAdjustmentItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockAdjustmentItemID?: Maybe<Scalars['String']>;
  StockAdjustmentID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetStockAdjustmentReasonArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockAdjustmentReasonID?: Maybe<Scalars['String']>;
};


export type QueryGetStockCategoryArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockCategoryID?: Maybe<Scalars['String']>;
  StockAccountCodeID?: Maybe<Scalars['String']>;
};


export type QueryGetStockCostAdjustmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockCostAdjustmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
};


export type QueryGetStockCostAdjustmentbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetStockCostAdjustmentListingArgs = {
  StatusArr: Array<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetStockIssueArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockIssueID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
};


export type QueryGetStockIssuebyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetStockIssueListingArgs = {
  StatusArr: Array<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetStockIssueItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockIssueItemID?: Maybe<Scalars['String']>;
  StockIssueID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetStockReceiptArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockReceiptID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
};


export type QueryGetStockReceiptbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetStockReceiptListingArgs = {
  StatusArr: Array<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetStockReceiptItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockReceiptItemID?: Maybe<Scalars['String']>;
  StockReceiptID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetStockRequisitionArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockRequisitionID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
};


export type QueryGetStockRequisitionbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetStockRequisitionListingArgs = {
  StatusArr: Array<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetStockRequisitionItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockRequisitionItemID?: Maybe<Scalars['String']>;
  StockRequisitionID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetStockTransferArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockTransferID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  FromWarehouseID?: Maybe<Scalars['String']>;
  ToWarehouseID?: Maybe<Scalars['String']>;
};


export type QueryGetStockTransferbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetStockTransferListingArgs = {
  StatusArr: Array<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetStockTransferItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockTransferItemID?: Maybe<Scalars['String']>;
  StockTransferID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetStockTransferRequisitionArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockTransferRequisitionID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  FromWarehouseID?: Maybe<Scalars['String']>;
  ToWarehouseID?: Maybe<Scalars['String']>;
};


export type QueryGetStockTransferRequisitionbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetStockTransferRequisitionListingArgs = {
  StatusArr: Array<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetStockTransferRequisitionItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockTransferRequisitionItemID?: Maybe<Scalars['String']>;
  StockTransferRequisitionID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetAccountMappingArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AccountMappingID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetCreditorSubmenuAmtArgs = {
  CreditorAccountID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryApAdvanceCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryApAdvanceTotalAmtArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApAdvanceLatestUpdateArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApInvoiceCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryApInvoiceTotalAmtArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApInvoiceLatestUpdateArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApCreditNoteCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryApCreditNoteTotalAmtArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApCreditNoteLatestUpdateArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApCreditorCreditNoteCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryApCreditorCreditNoteTotalAmtArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApCreditorCreditNoteLatestUpdateArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApDebitNoteCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryApDebitNoteTotalAmtArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApDebitNoteLatestUpdateArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApCreditorDebitNoteCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryApCreditorDebitNoteTotalAmtArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApCreditorDebitNoteLatestUpdateArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApSelfBilledCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryApSelfBilledTotalAmtArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApSelfBilledLatestUpdateArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApPaymentCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryApPaymentTotalAmtArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApPaymentLatestUpdateArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApBatchPaymentCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryApRefundCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryCeditorAccountCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryApRefundTotalAmtArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApRefundLatestUpdateArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApDigitalReportDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetCreditorTotalDocAmtArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetDebtorTotalDocAmtArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetEachSubDocAmtArgs = {
  DebtorAccountID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetDebtorLatestUpdatedDateArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetCreditorLatestUpdatedDateArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetArSubmenuTotalArgs = {
  DebtorAccountID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryArAdvanceCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryArAdvanceTotalAmtArgs = {
  DebtorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryArAdvanceLatestUpdateArgs = {
  DebtorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryArInvoiceCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryArInvoiceTotalAmtArgs = {
  DebtorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryArInvoiceLatestUpdateArgs = {
  DebtorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryArCreditNoteCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryArCreditNoteTotalAmtArgs = {
  DebtorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryArCreditNoteLatestUpdateArgs = {
  DebtorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryArDebitNoteCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryArDebitNoteTotalAmtArgs = {
  DebtorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryArDebitNoteLatestUpdateArgs = {
  DebtorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryArOfficialReceiptCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryArOfficialReceiptTotalAmtArgs = {
  DebtorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryArOfficialReceiptLatestUpdateArgs = {
  DebtorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryArRefundCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryArRefundTotalAmtArgs = {
  DebtorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryArRefundLatestUpdateArgs = {
  DebtorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPaymentCountArgs = {
  BankAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryReceiptCountArgs = {
  BankAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryBankTransferCountArgs = {
  BankAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryBankTransferDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryPaymentDateArgs = {
  BankAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryReceiptDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryExternalLedgerDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryCashBookCountArgs = {
  BankAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryCostCentreDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryEntityCoaDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryAccountPeriodDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryJournalTypeDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryBudgetDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryBankAccountDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryCompanySumDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryNumberingStructureGeneralLedgerDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryNumberingStructureCashBookDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryNumberingStructureApDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryNumberingStructureArDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryNumberingStructureIcDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryApprovalPolicyAssignmentDateArgs = {
  Category?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryOriginalBudgetApprovalPolicyAssignmentDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryBudgetRevisionApprovalPolicyAssignmentDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryRecurringJournalApprovalPolicyAssignmentDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryJournalProcessingApprovalPolicyAssignmentDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryAuditAdjustmentApprovalPolicyAssignmentDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryBankTransferStandardApprovalFlowDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryReceiptStandardApprovalFlowDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryPaymentStandardApprovalFlowDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryJournalProcessingCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryRecurringJournalCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryAuditAdjustmentCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGenerateRjCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryOriginalBudgetDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryExternalJournalDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryApprovalPolicyDateArgs = {
  Category?: Maybe<Array<Scalars['String']>>;
};


export type QueryStockEnquiryDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryStockReceiptDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryStockIssueDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryStockTransferDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryCycleCountDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryStockAdjustmentDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetCompanyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  ParentCompanyID?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
};


export type QueryGetCompanyListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
};


export type QueryGetCompanyWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type QueryGetCompanyNameWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type QueryGetCompanyEntityWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
};


export type QueryGetCheckingValidationCompanyArgs = {
  CompanyImportInput: Array<CompanyImportInput>;
};


export type QueryGetEInvoiceCompanyStartDateArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetLocalBankArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  countryID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<Scalars['String']>;
};


export type QueryGetEntityRoleUserAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
};


export type QueryGetRoleArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetRoleWithAccountArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};


export type QueryAccuserRoleIDsArgs = {
  companyID: Scalars['String'];
};


export type QueryGetUsersRoleCompanyArgs = {
  companyID: Scalars['String'];
  roleID: Scalars['String'];
};


export type QueryGetDrawerPermissionArgs = {
  pageMode?: Maybe<Scalars['String']>;
};


export type QueryGetAccountPeriodArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AccountPeriodID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  FYear?: Maybe<Scalars['Float']>;
};


export type QueryGetAccountYearArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetNextAccPeriodArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetSelectedAccountPeriodArgs = {
  selectedDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryAccountPeriodCheckingArgs = {
  FYear: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryGetOpenAccPeriodDateRangeArgs = {
  CompanyID: Scalars['String'];
};


export type QueryCheckExistingAccountPeriodArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetPreviousPeriodOfYearArgs = {
  FYear: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryGetArAgingArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AgingID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountType?: Maybe<Scalars['String']>;
};


export type QueryGetApAgingArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AgingID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountType?: Maybe<Scalars['String']>;
};


export type QueryGetApprovalPolicyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ApprovalPolicyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
};


export type QueryGetApprovalPolicyAssignmentArgs = {
  AccountID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetAllApprovalPolicyAssignmentArgs = {
  AccountID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetAllApprovalPolicyArgs = {
  AccountID?: Maybe<Scalars['String']>;
  Category: Scalars['String'];
};


export type QueryGetPendingGpArgs = {
  RefTable: Scalars['String'];
};


export type QueryGetBankAccountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BankAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
};


export type QueryGetCheckingValidationBaArgs = {
  CompanyID: Scalars['String'];
  BAImportInput: Array<BaImportInput>;
};


export type QueryGetBankProfileArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BankProfileID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetBankProfileWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type QueryGetCheckingValidationBpArgs = {
  BPImportInput: Array<BpImportInput>;
};


export type QueryGetBillItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BillItemID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
};


export type QueryGetClassificationCodeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  classificationCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export type QueryGetCoaStructureArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  COAStructureID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  CodeLength?: Maybe<Scalars['String']>;
};


export type QueryGetCompanyAssignedUsersByRoleArgs = {
  accountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetUserCompanyAssignedUsersByRoleArgs = {
  ID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetContactArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ContactID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetAllCostCentreArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CostCentreID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsLastNode?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCostCentreDefinitionAsgmtArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CostCentreDefinitionAsgmtID?: Maybe<Scalars['String']>;
  CostCentreDefinitionHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetCostCentreArgs = {
  ItemID?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  IsLastNode?: Maybe<Scalars['Boolean']>;
  AccountID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
};


export type QueryGetCostCentreListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetNextLevelCcDefinitionArgs = {
  CostCentreID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetParentCostCentreForTreeArgs = {
  CostCentreArr?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetCostCentreWithAccountArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};


export type QueryGetCostCentreEntityWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
};


export type QueryGetCostCentreCoaArgs = {
  MasterCOAID?: Maybe<Scalars['String']>;
};


export type QueryGetCostCentreDefinitionHeaderArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CostCentreDefinitionHeaderID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetCostCentreDefinitionDetailArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CostCentreDefinitionDetailID?: Maybe<Scalars['String']>;
  ParentCostCentreDefinitionDetailID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetCostCentreDefinitionArgs = {
  CostCentreDefinitionHeaderID: Scalars['String'];
};


export type QueryGetCostCentreDefinitionHeaderDetailsArgs = {
  CostCentreDefinitionHeaderID: Scalars['String'];
};


export type QueryGetCostCentreTypeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CostCentreTypeID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};


export type QueryGetCreditCardTypeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CreditCardID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetCreditorAccountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AccountID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  CreditorTypeID?: Maybe<Scalars['String']>;
  CreditorTypeEnum?: Maybe<CreditorType>;
};


export type QueryGetCreditorAccountCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AccountID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  CreditorTypeID?: Maybe<Scalars['String']>;
  CreditorTypeEnum?: Maybe<CreditorType>;
};


export type QueryGetCreditorProfileWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type QueryGetCheckingValidationCreditorProfileArgs = {
  CreditorProfileImportInput: Array<CreditorProfileImportInput>;
};


export type QueryGetCreditorAccountCompanyAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CreditorAccountCompanyAssignmentID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetCreditorAccountCompanyAssignmentCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CreditorAccountCompanyAssignmentID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
};


export type QueryCreditorAccountTotalDueArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetCreditorAccountCompanyAssignmentListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetCreditorContactArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AccountID?: Maybe<Scalars['String']>;
  CreditorContactID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetCreditorDirectorArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AccountID?: Maybe<Scalars['String']>;
  CreditorDirectorID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetCreditorTypeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CreditorTypeID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CreditorType?: Maybe<CreditorType>;
};


export type QueryGetCreditorTypeCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CreditorTypeID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CreditorType?: Maybe<CreditorType>;
};


export type QueryGetCreditorTypeWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type QueryGetCurrencyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CurrencyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetCurrencyExchangeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CurrencyExchangeID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryCreateCurrencyExchangeArgs = {
  input: CurrencyExchangeInput;
};


export type QueryGetDebtorAccountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryDebtorAccountTotalDueArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetCheckingValidationDebtorAccountArgs = {
  CompanyID: Scalars['String'];
  DebtorAccountImportInput: Array<DebtorAccountImportInput>;
};


export type QueryGetDebtorContactArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DebtorContactID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetDebtorTypeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DebtorTypeID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetDeliveryLocationArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentDateValidationArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DocumentDateValidationID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentNumberDetailArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DocumentNumberDetailID?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentNumberHeaderArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  Type?: Maybe<DocumentType>;
};


export type QueryGetDocumentNumberHeaderWithPermArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  Type?: Maybe<DocumentType>;
};


export type QueryGetDocNumByRefTableArgs = {
  RefTable: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetDocumentNumberHeaderAndDetailArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DocumentType: DocumentType;
};


export type QueryGetEntityCoaArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  EntityCOAID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
};


export type QueryGetAssignedMasterCoaArgs = {
  MasterCOAID?: Maybe<Scalars['String']>;
  AccountType: AccountType;
  CompanyID: Scalars['String'];
};


export type QueryGetEntityCoaWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};


export type QueryGetEntityCoaFilteredArgs = {
  IsControl?: Maybe<Scalars['Boolean']>;
  orderByAsc?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetExpenseClassArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ExpenseClassID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetParentExpenseItemCategoryCoaAccountTypeArgs = {
  ExpenseItemID?: Maybe<Scalars['String']>;
};


export type QueryGetExpenseItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  ExpenseClassID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
};


export type QueryGetSelectedItemArgs = {
  creditorDebtorID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  refTable: Scalars['String'];
  prefix: Scalars['String'];
};


export type QueryGetItemByCompanyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetItemClassCompanyAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ItemClassCompanyAssignmentID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ExpenseClassID?: Maybe<Scalars['String']>;
};


export type QueryGetJournalTypeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  JournalTypeID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
};


export type QueryGetMasterCoaArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  AccountType?: Maybe<Scalars['String']>;
  IsLastNode?: Maybe<Scalars['Boolean']>;
  IsControl?: Maybe<Scalars['Boolean']>;
};


export type QueryGetMasterCoaAccountTypeArgs = {
  AccountType: AccountType;
  CompanyID: Scalars['String'];
};


export type QueryGetMasterCoaListingArgs = {
  AccountType: Scalars['String'];
};


export type QueryAccountCodeCheckingArgs = {
  MasterCOAID?: Maybe<Scalars['String']>;
};


export type QueryGetMasterCoaWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};


export type QueryGetMasterCoaFilteredArgs = {
  IsControl?: Maybe<Scalars['Boolean']>;
  orderByAsc?: Maybe<Scalars['String']>;
};


export type QueryGetCheckingValidationCoaArgs = {
  MasterCOAInput: Array<MasterCoaInput>;
};


export type QueryGetMsicCodeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  msicCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  categoryReference?: Maybe<Scalars['String']>;
};


export type QueryGetNumberFormatArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  NumberFormatID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetPaymentMethodArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  PaymentMethodID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetRetainEarningAccountDefArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  RetainEarningAccountDefIDID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetStockItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockItemID?: Maybe<Scalars['String']>;
  StockCategoryID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
};


export type QueryGetStockLedgerArgs = {
  WarehouseID: Scalars['String'];
  StockItemID: Scalars['String'];
};


export type QueryStockItemWarehouseSummaryArgs = {
  WarehouseIDs: Array<Scalars['String']>;
  StockItemID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetStockItemWarehouseArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockItemWarehouseID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
};


export type QueryGetStockItemWarehouseByCostCentreArgs = {
  StockItemID?: Maybe<Scalars['String']>;
  StockItemWarehouseID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
};


export type QueryGetStockItemWarehouseListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetTaxSchemeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxTypeID?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  AccTaxClass?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
};


export type QueryGetTaxEffectiveDateArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  TaxEffectiveDateID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  Claimable?: Maybe<Scalars['Boolean']>;
};


export type QueryGetTaxTypeArgs = {
  RecordStatus?: Maybe<RecordStatus>;
  TaxTypeID?: Maybe<Scalars['String']>;
};


export type QueryGetTaxSchemeWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type QueryGetTransactionTypeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
  RecordStatus?: Maybe<Scalars['String']>;
};


export type QueryGetUomArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  UOMID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
};


export type QueryGetUomWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type QueryGetUomExchangeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  FromUomID?: Maybe<Scalars['String']>;
  ToUomID?: Maybe<Scalars['String']>;
};


export type QueryGetUomExchangeWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetUomExchangeListArgs = {
  UOMID: Scalars['String'];
};


export type QueryGetWarehouseArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  WarehouseID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryApprovableCheckingArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  UserID: Scalars['String'];
  CompanyID: Scalars['String'];
  RefID: Scalars['String'];
};


export type QueryGetToDoSummaryArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  RoleID: Scalars['String'];
};


export type QueryGetWorkDeskHistoryArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  tableIdentifier: Scalars['String'];
};


export type QueryGetWorkDeskToDoArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  tableIdentifier: Scalars['String'];
};


export type QueryGetApprovalLogArgs = {
  RefID: Scalars['String'];
};


export type QueryGetProcurementApprovalPolicyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
};


export type QueryGetProcurementApprovalPolicyAssignmentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
};


export type QueryGetBulkPurchasePriceArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  SupplierID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  BulkPurchasePriceHeaderID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierAccountByCompanyArgs = {
  CompanyID: Scalars['String'];
  CreditorAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetGeneralDoArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DOHeaderID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['DateTime']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  RecordStatus?: Maybe<RecordStatus>;
};


export type QueryGetGeneralDoItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DOItemID?: Maybe<Scalars['String']>;
};


export type QueryGetGeneralDoCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DOHeaderID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['DateTime']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  RecordStatus?: Maybe<RecordStatus>;
};


export type QueryGetSupplierByDoStatusArgs = {
  ApprovalStatus: ApprovalStatus;
  CompanyID: Scalars['String'];
};


export type QueryGetWarehouseDeliveryLocationArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetGeneralPOsArgs = {
  WarehouseID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  DOHeaderID?: Maybe<Scalars['String']>;
};


export type QueryGetOldGeneralPOsArgs = {
  WarehouseID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetGeneralGrtNsArgs = {
  WarehouseID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
};


export type QueryGetFavoriteApproverArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  FavoriteApproverID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
};


export type QueryGetFavoritePrHeaderArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  FavoritePRHeaderID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetGeneralPurchasePolicyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  GeneralPurchasePolicyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetGrtnHeaderArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  SupplierID?: Maybe<Scalars['String']>;
  GRTNHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetGrtnHeaderCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  SupplierID?: Maybe<Scalars['String']>;
  GRTNHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetGeneralPrItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetPoHeaderArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  POHeaderID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  POType?: Maybe<PoType>;
};


export type QueryGetPoHeaderCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  POHeaderID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  POType?: Maybe<PoType>;
};


export type QueryGetSupplierByPoStatusArgs = {
  ApprovalStatus: ApprovalStatus;
  CompanyID: Scalars['String'];
};


export type QueryCheckingCancelClosePoArgs = {
  POHeaderID: Scalars['String'];
};


export type QueryGetPrHeaderArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  PRHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountType?: Maybe<AccountType>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetPrHeaderCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  PRHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountType?: Maybe<AccountType>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetPrNoListArgs = {
  PRHeaderID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetOutstandingGeneralPrItemsArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetOutstandingGeneralPrItemsListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetOsGeneralPrArgs = {
  CompanyID: Scalars['String'];
};


export type QueryCheckingCancelClosePrArgs = {
  PRHeaderID: Scalars['String'];
};


export type QueryGetRfqHeaderArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  RFQHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetRfqItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  RFQHeaderID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  PRItemID?: Maybe<Scalars['String']>;
};


export type QueryGetRfQbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetRfQbyStatusListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetInvitedSupplierArgs = {
  companyID: Scalars['String'];
  RFQHeaderID: Scalars['String'];
};


export type QueryGetRfqSupplierInvitationArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  RFQHeaderID?: Maybe<Scalars['String']>;
};


export type QueryGetRfqSupplierSubmissionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  RFQSupplierSubmissionID?: Maybe<Scalars['String']>;
  RFQSupplierInvitationID?: Maybe<Scalars['String']>;
  RFQHeaderID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
};


export type QueryGetPrItemByPridForRfqArgs = {
  CompanyID: Scalars['String'];
  PRHeaderID: Scalars['String'];
};


export type QueryGetScheduledPurchasesArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ScheduledPurchasesID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
};


export type QueryGetScheduledPurchasesCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ScheduledPurchasesID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
};


export type QueryGetLatestPurchaseSupplierFromPoArgs = {
  ItemID?: Maybe<Scalars['String']>;
};


export type QueryGetItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  SupplierID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  ItemCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetCheckingValidationItemArgs = {
  ItemExcelInput: Array<ItemExcelInput>;
};


export type QueryGetLatestTaxByTaxSchemeIDsArgs = {
  taxSchemeIDs: Array<Scalars['String']>;
};


export type QueryGetWarehouseByStockItemArgs = {
  CompanyID: Scalars['String'];
  StockItemID: Scalars['String'];
};


export type QueryGetItemByCompanyAssignmentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetItemCategoryArgs = {
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ItemCategoryID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetItemCategoryCompanyAssignmentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ItemCategoryCompanyAssignmentID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ItemCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetPurchaseItemVariancePolicyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  PurchaseItemVariancePolicyID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteWithAccountArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierLedgerByCreditorAccountArgs = {
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
};


export type QueryGetSupplierAccountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetSupplierAccountListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetAdjustmentTypeArgs = {
  optField?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryGetDocumentFolderArgs = {
  siteID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryLastApprovalPolicyArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryLastNumberingStructureArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryActiveDeliveryLocationCountArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryScheduledPurchaseInfoCountArgs = {
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryPrInfoCountArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryRfqCountArgs = {
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryPoInfoCountArgs = {
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryDoInfoCountArgs = {
  SupplierID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryLastDoDateArgs = {
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGrtnInfoCountArgs = {
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryLastGrtnDateArgs = {
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type QueryFilterString = {
  __typename?: 'QueryFilterString';
  Column: Scalars['String'];
  Expression: Scalars['String'];
  Value: Scalars['String'];
  ParameterName?: Maybe<Scalars['String']>;
};

export type QueryFilterStringInput = {
  Column: Scalars['String'];
  Expression: Scalars['String'];
  Value: Scalars['String'];
  ParameterName?: Maybe<Scalars['String']>;
};

export type QueryParameter = {
  __typename?: 'QueryParameter';
  Name: Scalars['String'];
  Type?: Maybe<Scalars['String']>;
  ValueType: Scalars['String'];
  QueryFilterStringalue: Scalars['String'];
};

export type QueryParameterInput = {
  Name: Scalars['String'];
  Type?: Maybe<Scalars['String']>;
  ValueType: Scalars['String'];
  QueryFilterStringalue: Scalars['String'];
};

export type QuotationDetailEntity = AuditEntity & {
  __typename?: 'QuotationDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  QuotationDetailID: Scalars['String'];
  QuotationItemID: Scalars['String'];
  QuotationItem?: Maybe<QuotationItemEntity>;
  LowerLimit: Scalars['Float'];
  UpperLimit: Scalars['Float'];
  Price: Scalars['Float'];
};

export type QuotationHeaderEntity = AuditEntity & {
  __typename?: 'QuotationHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  QuotationHeaderID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  SupplierID: Scalars['String'];
  RefNo?: Maybe<Scalars['String']>;
  EffectiveDate: Scalars['String'];
  ExpiryDate: Scalars['String'];
  QuotationNo?: Maybe<Scalars['String']>;
  CreditTerm?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  QuotationItem?: Maybe<Array<QuotationItemEntity>>;
};

export type QuotationItemEntity = AuditEntity & {
  __typename?: 'QuotationItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  QuotationItemID: Scalars['String'];
  QuotationHeaderID: Scalars['String'];
  QuotationHeader?: Maybe<QuotationHeaderEntity>;
  ItemID: Scalars['String'];
  Item?: Maybe<ItemEntity>;
  UOMID: Scalars['String'];
  QuotationDetail?: Maybe<Array<QuotationDetailEntity>>;
};

export type RfqEvalInput = {
  RFQHeaderID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type RfqEvalSelectInput = {
  RFQItemID?: Maybe<Scalars['String']>;
  RFQSubmissionItemID?: Maybe<Scalars['String']>;
};

export type RfqHeaderEntity = AuditEntity & {
  __typename?: 'RFQHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  RFQHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  ExpectedDate?: Maybe<Scalars['String']>;
  CancelCloseDate?: Maybe<Scalars['String']>;
  CancelCloseRemark?: Maybe<Scalars['String']>;
  RFQItem?: Maybe<Array<RfqItemEntity>>;
  RFQSupplierInvitation?: Maybe<Array<RfqSupplierInvitationEntity>>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  PRHeaderInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  ApprovalWorkflowRFQ?: Maybe<Scalars['JSON']>;
};

/** JsonType */
export type RfqHeaderReturnData = {
  __typename?: 'RFQHeaderReturnData';
  Data?: Maybe<Array<RfqHeaderEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type RfqInput = {
  PRHeaderID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  RecordStatus?: Maybe<RecordStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type RfqItemEntity = AuditEntity & {
  __typename?: 'RFQItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  RFQItemID: Scalars['String'];
  RFQHeaderID: Scalars['String'];
  RFQHeader?: Maybe<RfqHeaderEntity>;
  PRItemID: Scalars['String'];
  FavoritePRItemID?: Maybe<Scalars['String']>;
  PRItem?: Maybe<PrItemEntity>;
  FavoritePRItem?: Maybe<FavoritePrItemEntity>;
  RFQSubmissionItem?: Maybe<Array<RfqSubmissionItemEntity>>;
  UOM?: Maybe<AccUomEntity>;
};

export type RfqItemInput = {
  PRItemID?: Maybe<Scalars['String']>;
  RFQHeaderID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
};

export type RfqRejectInput = {
  RFQHeaderID?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export enum RfqRequired {
  Optional = 'OPTIONAL',
  Required = 'REQUIRED'
}

export type RfqSubmissionItemEntity = AuditEntity & {
  __typename?: 'RFQSubmissionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  RFQSubmissionItemID: Scalars['String'];
  RFQSupplierSubmissionID: Scalars['String'];
  RFQSupplierSubmission?: Maybe<RfqSupplierSubmissionEntity>;
  RFQItemID: Scalars['String'];
  RFQItem?: Maybe<RfqItemEntity>;
  UnitPrice: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  IsSelected?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  Cheapest?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  TotalPrice?: Maybe<Scalars['JSON']>;
};


export type RfqSubmissionItemEntityCheapestArgs = {
  RFQHeaderID?: Maybe<Scalars['String']>;
};


export type RfqSubmissionItemEntityTotalPriceArgs = {
  RFQHeaderID?: Maybe<Scalars['String']>;
};

export type RfqSubmissionItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  RFQItemID: Scalars['String'];
  UnitPrice: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  isSelected?: Maybe<Scalars['Boolean']>;
};

export type RfqSupplierInvitationEntity = AuditEntity & {
  __typename?: 'RFQSupplierInvitationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  RFQSupplierInvitationID: Scalars['String'];
  SupplierID: Scalars['String'];
  RFQHeaderID: Scalars['String'];
  RFQHeader?: Maybe<RfqHeaderEntity>;
  RFQSupplierSubmission?: Maybe<Array<RfqSupplierSubmissionEntity>>;
  Supplier?: Maybe<CreditorAccountEntity>;
  LatestSubmittedQuotation?: Maybe<RfqSupplierSubmissionEntity>;
};

export type RfqSupplierSubmissionEntity = AuditEntity & {
  __typename?: 'RFQSupplierSubmissionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  RFQSupplierSubmissionID: Scalars['String'];
  RFQSupplierInvitationID: Scalars['String'];
  RFQSupplierInvitation?: Maybe<RfqSupplierInvitationEntity>;
  RefNo: Scalars['String'];
  CreditTerm: Scalars['Float'];
  SubmittedDate: Scalars['String'];
  EffectiveDate: Scalars['String'];
  ExpiryDate: Scalars['String'];
  Remarks?: Maybe<Scalars['String']>;
  RFQSubmissionItem?: Maybe<Array<RfqSubmissionItemEntity>>;
  Supplier?: Maybe<CreditorAccountEntity>;
};

export type RfqSupplierSubmissionInput = {
  RFQSupplierSubmissionID?: Maybe<Scalars['String']>;
  RFQSupplierInvitationID: Scalars['String'];
  RefNo: Scalars['String'];
  CreditTerm: Scalars['Float'];
  SubmittedDate: Scalars['String'];
  EffectiveDate: Scalars['String'];
  ExpiryDate: Scalars['String'];
  Remarks?: Maybe<Scalars['String']>;
  RecordStatus: RecordStatus;
  RFQSubmissionItem: Array<RfqSubmissionItemInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

/** JsonType */
export type RfqWorkflowInfo = {
  __typename?: 'RFQWorkflowInfo';
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
};

export type ReceiptDeleteInput = {
  ReceiptID: Scalars['String'];
};

export type ReceiptEntity = AuditEntity & {
  __typename?: 'ReceiptEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  CurrencyID?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  LocalDocAmt?: Maybe<Scalars['Float']>;
  LocalTaxAmt?: Maybe<Scalars['Float']>;
  BankAccountID: Scalars['String'];
  ReceiptID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  CompanyID: Scalars['String'];
  ReceivedFrom?: Maybe<Scalars['String']>;
  PaymentMethodID: Scalars['String'];
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  LedgerID?: Maybe<Scalars['String']>;
  ReceiptItem?: Maybe<Array<ReceiptItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
};

export type ReceiptInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  DocRef?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  DocDueDate?: Maybe<Scalars['String']>;
  CancellationDate?: Maybe<Scalars['String']>;
  CancellationRemark?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  BaseAmt?: Maybe<Scalars['Float']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  LocalBaseAmt?: Maybe<Scalars['Float']>;
  BankDocAmt?: Maybe<Scalars['Float']>;
  LocalTaxAmt?: Maybe<Scalars['Float']>;
  CurrencyID?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ReceiptID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  TransactionDate?: Maybe<Scalars['String']>;
  PaymentMethodID: Scalars['String'];
  RefNo?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  TaxInvoiceNo?: Maybe<Scalars['String']>;
  ReceivedFrom?: Maybe<Scalars['String']>;
};

export type ReceiptItemDeleteInput = {
  ReceiptItemID: Scalars['String'];
};

export type ReceiptItemEntity = AuditEntity & {
  __typename?: 'ReceiptItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  MasterCOAID: Scalars['String'];
  ReceiptItemID: Scalars['String'];
  ReceiptID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  CostCentreID: Scalars['String'];
  Receipt?: Maybe<ReceiptEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  CostCentre?: Maybe<CostCentreEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type ReceiptItemInput = {
  ReceiptItemID?: Maybe<Scalars['String']>;
  ReceiptID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type ReceiptRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export enum RecordStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED'
}

export type RecurringJournalDeleteInput = {
  RecurringJournalID: Scalars['String'];
};

export type RecurringJournalEntity = AuditEntity & {
  __typename?: 'RecurringJournalEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  RecurringJournalID: Scalars['String'];
  CompanyID: Scalars['String'];
  JournalTypeID: Scalars['String'];
  RefNo?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  StartPeriod: Scalars['Float'];
  Interval: Scalars['Float'];
  NoOfRecurrance: Scalars['Float'];
  PostAt?: Maybe<Scalars['String']>;
  Specify?: Maybe<Scalars['Float']>;
  StartYear?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  JournalID?: Maybe<Scalars['String']>;
  AccountPeriodID?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  TransactionDate?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  RecurringJournalItem?: Maybe<Array<RecurringJournalItemEntity>>;
  AccountPeriod?: Maybe<AccountPeriodEntity>;
  JournalType?: Maybe<JournalTypeEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  GeneratedJournals?: Maybe<Array<JournalEntity>>;
  /** CustomFieldResolver */
  RecurringJournalStartDate?: Maybe<Scalars['JSON']>;
};

export type RecurringJournalGenerationEntity = AuditEntity & {
  __typename?: 'RecurringJournalGenerationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  RecurringJournalGenerationID: Scalars['String'];
  CompanyID: Scalars['String'];
  JournalDate: Scalars['String'];
  JournalTypeID: Scalars['String'];
  JournalNo?: Maybe<Scalars['String']>;
  FYear: Scalars['Float'];
  FPeriod: Scalars['Float'];
  IsManual: Scalars['Boolean'];
  RefNo: Scalars['String'];
  Description: Scalars['String'];
  RefRecordID: Scalars['String'];
  RefTable: Scalars['String'];
  IsGenerated: Scalars['Boolean'];
  RecurringJournalGenerationItem?: Maybe<Array<RecurringJournalGenerationItemEntity>>;
  JournalType?: Maybe<JournalTypeEntity>;
};

export type RecurringJournalGenerationItemEntity = AuditEntity & {
  __typename?: 'RecurringJournalGenerationItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  RecurringJournalGenerationItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  MasterCOA?: Maybe<MasterCoaEntity>;
  JournalID: Scalars['String'];
  RecurringJournalGeneration?: Maybe<RecurringJournalGenerationEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  DocAmt?: Maybe<Scalars['Float']>;
  TaxInvoiceNo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type RecurringJournalInput = {
  RecurringJournalID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  JournalTypeID: Scalars['String'];
  RefNo: Scalars['String'];
  Description: Scalars['String'];
  StartYear: Scalars['Float'];
  StartPeriod: Scalars['Float'];
  Interval: Scalars['Float'];
  PostAt?: Maybe<Scalars['String']>;
  Specify?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Status?: Maybe<JournalStatus>;
  NoOfRecurrance: Scalars['Float'];
  ApprovalStatus?: Maybe<ApprovalStatus>;
  RejectionRemark?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type RecurringJournalItemDeleteInput = {
  RecurringJournalItemID: Scalars['String'];
};

export type RecurringJournalItemEntity = AuditEntity & {
  __typename?: 'RecurringJournalItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  RecurringJournalItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  RecurringJournalID: Scalars['String'];
  RecurringJournal?: Maybe<RecurringJournalEntity>;
  MasterCOAID: Scalars['String'];
  MasterCOA?: Maybe<MasterCoaEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type RecurringJournalItemInput = {
  RecurringJournalItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  RecurringJournalID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  CostCentreID: Scalars['String'];
  Sequence?: Maybe<Scalars['Float']>;
};

export type RecurringJournalRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type RecurringJournalReturnData = {
  __typename?: 'RecurringJournalReturnData';
  Data?: Maybe<Array<RecurringJournalEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export enum RefTable {
  GeneralLedger = 'GENERAL_LEDGER',
  Cashbook = 'CASHBOOK',
  FixedAsset = 'FIXED_ASSET'
}

export type RejectInput = {
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type ReportParameter = {
  __typename?: 'ReportParameter';
  Name: Scalars['String'];
  Description: Scalars['String'];
  Type: Scalars['String'];
  Visible?: Maybe<Scalars['Boolean']>;
  AllowNull?: Maybe<Scalars['Boolean']>;
  DropDownMember?: Maybe<DropDownMember>;
  MultiValue?: Maybe<Scalars['Boolean']>;
  IsDateRange?: Maybe<Scalars['Boolean']>;
  GlobalType?: Maybe<Scalars['String']>;
  Tag?: Maybe<Scalars['String']>;
};

export type ReportParameterInput = {
  Name: Scalars['String'];
  Description: Scalars['String'];
  Type: Scalars['String'];
  Visible?: Maybe<Scalars['Boolean']>;
  AllowNull?: Maybe<Scalars['Boolean']>;
  DropDownMember?: Maybe<DropDownMemberInput>;
  MultiValue?: Maybe<Scalars['Boolean']>;
  IsDateRange?: Maybe<Scalars['Boolean']>;
  GlobalType?: Maybe<Scalars['String']>;
  Tag?: Maybe<Scalars['String']>;
};

export type ReportSettingsDeleteInput = {
  ReportSettingsID: Scalars['String'];
};

export type ReportSettingsEntity = AuditEntity & {
  __typename?: 'ReportSettingsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ReportSettingsID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  DataSourceCodes?: Maybe<Array<Scalars['JSON']>>;
  AccountCodes?: Maybe<Array<Scalars['JSON']>>;
  CostCentreCodes?: Maybe<Array<Scalars['JSON']>>;
  CompanyCodes?: Maybe<Array<Scalars['JSON']>>;
  Description?: Maybe<Scalars['String']>;
  FileName?: Maybe<Scalars['String']>;
  FileURL?: Maybe<Scalars['String']>;
  CellValues?: Maybe<Array<Scalars['JSON']>>;
  BucketFileName?: Maybe<Scalars['String']>;
  MediaType?: Maybe<Scalars['String']>;
  Opacity?: Maybe<Scalars['String']>;
  IsPrivate: Scalars['Boolean'];
};

export type ReportSettingsInput = {
  ReportSettingsID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  FileName?: Maybe<Scalars['String']>;
  FileURL?: Maybe<Scalars['String']>;
  CellValuesJson?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  Opacity?: Maybe<Scalars['String']>;
  IsPrivate?: Maybe<Scalars['Boolean']>;
};

export type RetainEarningAccountDefDeleteInput = {
  RetainEarningAccountDefID: Scalars['String'];
};

export type RetainEarningAccountDefEntity = AuditEntity & {
  __typename?: 'RetainEarningAccountDefEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  RetainEarningAccountDefID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  MasterCOA?: Maybe<MasterCoaEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type RetainEarningAccountDefInput = {
  RetainEarningAccountDefID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  Code: Scalars['String'];
};

/** JsonType */
export type RoleInfo = {
  __typename?: 'RoleInfo';
  Name?: Maybe<Scalars['String']>;
  RoleID?: Maybe<Scalars['String']>;
};

export type RoleInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
};

export type RoleUser = {
  __typename?: 'RoleUser';
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type RoutineJournalDeleteInput = {
  RoutineJournalID: Scalars['String'];
};

export type RoutineJournalEntity = AuditEntity & {
  __typename?: 'RoutineJournalEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  RoutineJournalID: Scalars['String'];
  CompanyID: Scalars['String'];
  JournalTypeID: Scalars['String'];
  RefNo?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  Remark: Scalars['String'];
  JournalID?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  RoutineJournalItem?: Maybe<Array<RoutineJournalItemEntity>>;
  JournalType?: Maybe<JournalTypeEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type RoutineJournalInput = {
  RoutineJournalID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  JournalTypeID: Scalars['String'];
  RefNo: Scalars['String'];
  Description: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  Status?: Maybe<JournalStatus>;
};

export type RoutineJournalItemDeleteInput = {
  RoutineJournalItemID: Scalars['String'];
};

export type RoutineJournalItemEntity = AuditEntity & {
  __typename?: 'RoutineJournalItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  RoutineJournalItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  RoutineJournalID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  DocAmt: Scalars['Float'];
  RoutineJournal?: Maybe<RoutineJournalEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
};

export type RoutineJournalItemInput = {
  RoutineJournalItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RoutineJournalID?: Maybe<Scalars['String']>;
  MasterCOAID: Scalars['String'];
  DocAmt: Scalars['Float'];
};

export type RunningNumberEntity = AuditEntity & {
  __typename?: 'RunningNumberEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  RunningNumberID: Scalars['String'];
  NumberFormatID: Scalars['String'];
  NumberFormat?: Maybe<NumberFormatEntity>;
  NextNumber: Scalars['Float'];
};

export type SampleField = {
  __typename?: 'SampleField';
  testID: Scalars['String'];
  arrayID: Array<Scalars['String']>;
};

export type SampleFieldInput = {
  testID: Scalars['String'];
  arrayID: Array<Scalars['String']>;
};

export enum ScheduledFrequency {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  HalfYearly = 'HALF_YEARLY',
  Yearly = 'YEARLY',
  SpecificDate = 'SPECIFIC_DATE'
}

export type ScheduledPurchasesEntity = AuditEntity & {
  __typename?: 'ScheduledPurchasesEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ScheduledPurchasesID: Scalars['String'];
  CompanyID: Scalars['String'];
  Title: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  RequestedBy?: Maybe<Scalars['String']>;
  RequestStatus: ScheduledPurchasesRequestStatus;
  DeliveryLocationID: Scalars['String'];
  ScheduledFrequency: ScheduledFrequency;
  ScheduledDate?: Maybe<Scalars['String']>;
  ScheduledPurchasesItem?: Maybe<Array<ScheduledPurchasesItemEntity>>;
  /** CustomFieldResolver */
  Company: CompanyEntity;
  /** CustomFieldResolver */
  DeliveryLocation?: Maybe<DeliveryLocationEntity>;
};

export type ScheduledPurchasesInput = {
  ScheduledPurchasesID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  ScheduledFrequency?: Maybe<ScheduledFrequency>;
  ScheduledDate?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledPurchasesItems: Array<ScheduledPurchasesItemInput>;
  RequestedBy?: Maybe<Scalars['String']>;
  RequestStatus?: Maybe<ScheduledPurchasesRequestStatus>;
};

export type ScheduledPurchasesItemEntity = AuditEntity & {
  __typename?: 'ScheduledPurchasesItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ScheduledPurchasesItemID: Scalars['String'];
  ScheduledPurchasesID: Scalars['String'];
  ScheduledPurchases?: Maybe<ScheduledPurchasesEntity>;
  ItemID: Scalars['String'];
  Item?: Maybe<ItemEntity>;
  SupplierID: Scalars['String'];
  UOMID: Scalars['String'];
  BaseUOMID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  Quantity: Scalars['Float'];
  UnitPrice: Scalars['Float'];
  TaxSchemeID: Scalars['String'];
  TaxAmt: Scalars['Float'];
  TotalAmt: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  Supplier?: Maybe<CreditorAccountEntity>;
  UOM?: Maybe<AccUomEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  /** CustomFieldResolver */
  UOMExchangeList?: Maybe<Scalars['JSON']>;
};

export type ScheduledPurchasesItemInput = {
  createdBy?: Maybe<Scalars['String']>;
  ScheduledPurchasesID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  TotalAmt?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export enum ScheduledPurchasesRequestStatus {
  Draft = 'DRAFT',
  PurchaseRequest = 'PURCHASE_REQUEST',
  VerifyRequest = 'VERIFY_REQUEST',
  PreparePo = 'PREPARE_PO'
}

export type ShareAttachmentEntity = AuditEntity & {
  __typename?: 'ShareAttachmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ShareAttachmentID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  bucketFileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
};

export enum SoftwareCode {
  Contract = 'CONTRACT',
  Site = 'SITE',
  Client = 'CLIENT',
  Subcontractor = 'SUBCONTRACTOR',
  Supplier = 'SUPPLIER',
  Hr = 'HR',
  Property = 'PROPERTY',
  Rent = 'RENT',
  Hotel = 'HOTEL',
  Account = 'ACCOUNT',
  Ifca = 'IFCA',
  Subscription = 'SUBSCRIPTION',
  User = 'USER',
  Tenant = 'TENANT',
  LeaseTodo = 'LEASE_TODO',
  LeaseOperation = 'LEASE_OPERATION',
  LeaseSales = 'LEASE_SALES',
  Menu = 'MENU',
  PosCustomer = 'POS_CUSTOMER',
  Probation = 'PROBATION',
  ReportDesigner = 'REPORT_DESIGNER'
}

export type StandardAuditEntity = {
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
};

export type StandardCoreBaseEntity = AuditEntity & {
  __typename?: 'StandardCoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type StandardLetterDataSourceDeleteInput = {
  StandardLetterDataSourceID?: Maybe<Scalars['String']>;
};

export type StandardLetterDataSourceEntity = {
  __typename?: 'StandardLetterDataSourceEntity';
  StandardLetterDataSourceID: Scalars['String'];
  AccountID: Scalars['String'];
  StandardLetterID: Scalars['String'];
  Name: Scalars['String'];
  DataSource: Array<DataSource>;
  QueryFilterString: Array<QueryFilterString>;
  QueryParameter: Array<QueryParameter>;
  ReportParameter: Array<ReportParameter>;
  Version?: Maybe<Scalars['Float']>;
  RecordStatus: RecordStatus;
  StandardLetter?: Maybe<StandardLetterEntity>;
};

export type StandardLetterDataSourceInput = {
  StandardLetterDataSourceID?: Maybe<Scalars['String']>;
  StandardLetterID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DataSource?: Maybe<Array<DataSourceInput>>;
  QueryFilterString?: Maybe<Array<QueryFilterStringInput>>;
  QueryParameter?: Maybe<Array<QueryParameterInput>>;
  ReportParameter?: Maybe<Array<ReportParameterInput>>;
  Version?: Maybe<Scalars['Float']>;
};

export type StandardLetterEntity = {
  __typename?: 'StandardLetterEntity';
  StandardLetterID: Scalars['String'];
  Name: Scalars['String'];
  LetterCategory: Scalars['String'];
  LetterType: Scalars['String'];
  DefaultTemplateBody?: Maybe<Scalars['String']>;
  IsAllowHtmlEditor?: Maybe<Scalars['Boolean']>;
  IsAllowReportDesignEditor?: Maybe<Scalars['Boolean']>;
  RecordStatus: RecordStatus;
  StandardLetterParameter: Array<StandardLetterParameterEntity>;
  StandardLetterDataSource: Array<StandardLetterDataSourceEntity>;
  LetterTemplate: Array<LetterTemplateEntity>;
  CompanyLetterTemplate: Array<CompanyLetterTemplateEntity>;
};

export type StandardLetterParameterEntity = {
  __typename?: 'StandardLetterParameterEntity';
  StandardLetterParameterID: Scalars['String'];
  StandardLetterID: Scalars['String'];
  DisplayName: Scalars['String'];
  ColumnName: Scalars['String'];
  RecordStatus: RecordStatus;
  StandardLetter?: Maybe<StandardLetterEntity>;
};

export type StandardProcurementCoreEntity = AuditEntity & {
  __typename?: 'StandardProcurementCoreEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
};

export type StaticData = {
  __typename?: 'StaticData';
  Value: Scalars['String'];
  Desc: DynamicSource;
};

export type StaticDataInput = {
  Value: Scalars['String'];
  Desc: DynamicSourceInput;
};

export type StockAdjustmentDeleteInput = {
  StockAdjustmentID: Scalars['String'];
};

export type StockAdjustmentEntity = AuditEntity & {
  __typename?: 'StockAdjustmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  StockAdjustmentID: Scalars['String'];
  WarehouseID: Scalars['String'];
  Warehouse?: Maybe<WarehouseEntity>;
  CompanyID: Scalars['String'];
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  StockAdjustmentItem?: Maybe<Array<StockAdjustmentItemEntity>>;
};

export type StockAdjustmentInput = {
  StockAdjustmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type StockAdjustmentItemDeleteInput = {
  StockAdjustmentItemID: Scalars['String'];
};

export type StockAdjustmentItemEntity = AuditEntity & {
  __typename?: 'StockAdjustmentItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DefaultUOMID?: Maybe<Scalars['String']>;
  DefaultUOMQty?: Maybe<Scalars['Float']>;
  DefaultUOMUnitCost?: Maybe<Scalars['Float']>;
  DefaultUOMTotalCost?: Maybe<Scalars['Float']>;
  StockAdjustmentItemID: Scalars['String'];
  Quantity?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  TotalCost?: Maybe<Scalars['Float']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  CycleCountItemID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  WarehouseID?: Maybe<Scalars['String']>;
  StockAdjustmentID: Scalars['String'];
  StockAdjustment?: Maybe<StockAdjustmentEntity>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  StockItemID: Scalars['String'];
  StockItem?: Maybe<StockItemEntity>;
  ReasonID: Scalars['String'];
  Reason?: Maybe<StockAdjustmentReasonEntity>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<TransactionTypeEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  StockItemWarehouse?: Maybe<StockItemWarehouseEntity>;
};

export type StockAdjustmentItemInput = {
  StockAdjustmentItemID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  WarehouseID?: Maybe<Scalars['String']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type StockAdjustmentReasonDeleteInput = {
  StockAdjustmentReasonID: Scalars['String'];
};

export type StockAdjustmentReasonEntity = AuditEntity & {
  __typename?: 'StockAdjustmentReasonEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  StockAdjustmentReasonID: Scalars['String'];
  Reason: Scalars['String'];
  IsSystem: Scalars['Boolean'];
  CycleCountItem?: Maybe<Array<CycleCountItemEntity>>;
  StockAdjusmentItem?: Maybe<Array<StockAdjustmentItemEntity>>;
  StockTransferItem?: Maybe<Array<StockTransferItemEntity>>;
};

export type StockAdjustmentReasonInput = {
  StockAdjustmentReasonID?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
};

export type StockAdjustmentRejectInput = {
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type StockCategoryDeleteInput = {
  StockCategoryID: Scalars['String'];
};

export type StockCategoryEntity = AuditEntity & {
  __typename?: 'StockCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  StockCategoryID: Scalars['String'];
  Name: Scalars['String'];
  ParentStockCategoryID?: Maybe<Scalars['String']>;
  Level: Scalars['Float'];
  IsLastNode: Scalars['Boolean'];
  StockAccountCodeID?: Maybe<Scalars['String']>;
  StockAccountCode?: Maybe<MasterCoaEntity>;
  StockItem?: Maybe<Array<StockItemEntity>>;
  /** CustomFieldResolver */
  ParentCategoryMCOA?: Maybe<Scalars['JSON']>;
};

export type StockCategoryInput = {
  StockCategoryID?: Maybe<Scalars['String']>;
  ParentStockCategoryID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  ISLastNode?: Maybe<Scalars['Boolean']>;
  StockAccountCodeID?: Maybe<Scalars['String']>;
};

export type StockCostAdjustmentDeleteInput = {
  StockCostAdjustmentID: Scalars['String'];
};

export type StockCostAdjustmentEntity = AuditEntity & {
  __typename?: 'StockCostAdjustmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  StockCostAdjustmentID: Scalars['String'];
  WarehouseID: Scalars['String'];
  Warehouse?: Maybe<WarehouseEntity>;
  StockCostAdjustmentItem?: Maybe<Array<StockCostAdjustmentItemEntity>>;
  CompanyID: Scalars['String'];
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  /** CustomFieldResolver */
  FromCurrency?: Maybe<CompanyEntity>;
};

export type StockCostAdjustmentInput = {
  StockCostAdjustmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type StockCostAdjustmentItemDeleteInput = {
  StockCostAdjustmentItemID: Scalars['String'];
};

export type StockCostAdjustmentItemEntity = AuditEntity & {
  __typename?: 'StockCostAdjustmentItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  StockCostAdjustmentItemID: Scalars['String'];
  Cost?: Maybe<Scalars['Float']>;
  CompanyID: Scalars['String'];
  WarehouseID?: Maybe<Scalars['String']>;
  StockCostAdjustmentID: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  StockCostAdjustment?: Maybe<StockCostAdjustmentEntity>;
  StockItemID: Scalars['String'];
  StockItem?: Maybe<StockItemEntity>;
  ReasonID: Scalars['String'];
  Reason?: Maybe<StockAdjustmentReasonEntity>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<TransactionTypeEntity>;
  /** CustomFieldResolver */
  FromCurrency?: Maybe<CompanyEntity>;
};

export type StockCostAdjustmentItemInput = {
  StockCostAdjustmentItemID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  Cost?: Maybe<Scalars['Float']>;
  WarehouseID?: Maybe<Scalars['String']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type StockCostAdjustmentRejectInput = {
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type StockCostAdjustmentType = {
  __typename?: 'StockCostAdjustmentType';
  Data: Array<StockCostAdjustmentEntity>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export enum StockCostingType {
  Weighted = 'WEIGHTED',
  Fifo = 'FIFO',
  Lifo = 'LIFO'
}

export type StockIssueDeleteInput = {
  StockIssueID: Scalars['String'];
};

export type StockIssueEntity = AuditEntity & {
  __typename?: 'StockIssueEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  StockIssueID: Scalars['String'];
  CompanyID: Scalars['String'];
  WarehouseID: Scalars['String'];
  Warehouse?: Maybe<WarehouseEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  StockRequisitionID?: Maybe<Scalars['String']>;
  StockRequisition?: Maybe<StockRequisitionEntity>;
  StockIssueItem?: Maybe<Array<StockIssueItemEntity>>;
};

export type StockIssueInput = {
  StockIssueID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  WarehouseID?: Maybe<Scalars['String']>;
  StockRequisitionID?: Maybe<Scalars['String']>;
};

export type StockIssueItemDeleteInput = {
  StockIssueItemID: Scalars['String'];
};

export type StockIssueItemEntity = AuditEntity & {
  __typename?: 'StockIssueItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DefaultUOMID?: Maybe<Scalars['String']>;
  DefaultUOMQty?: Maybe<Scalars['Float']>;
  DefaultUOMUnitCost?: Maybe<Scalars['Float']>;
  DefaultUOMTotalCost?: Maybe<Scalars['Float']>;
  StockIssueItemID: Scalars['String'];
  Quantity?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  TotalCost?: Maybe<Scalars['Float']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  StockIssueID: Scalars['String'];
  StockIssue?: Maybe<StockIssueEntity>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  StockItemID: Scalars['String'];
  StockItem?: Maybe<StockItemEntity>;
  WarehouseID?: Maybe<Scalars['String']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<TransactionTypeEntity>;
  StockRequisitionItemID?: Maybe<Scalars['String']>;
  StockRequisitionItem?: Maybe<StockRequisitionItemEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  StockItemWarehouse?: Maybe<StockItemWarehouseEntity>;
};

export type StockIssueItemInput = {
  StockIssueItemID?: Maybe<Scalars['String']>;
  StockIssueID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  UnitCost?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  Sequence?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  StockRequisitionItemID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type StockIssueRejectInput = {
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type StockIssueType = {
  __typename?: 'StockIssueType';
  Data: Array<StockIssueEntity>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type StockItemDeleteInput = {
  StockItemID: Scalars['String'];
};

export type StockItemEntity = AuditEntity & {
  __typename?: 'StockItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  StockItemID: Scalars['String'];
  Name: Scalars['String'];
  ABCClass?: Maybe<Scalars['String']>;
  ShelfLife?: Maybe<Scalars['Float']>;
  StandardCost?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  StockCategoryID: Scalars['String'];
  StockCategory?: Maybe<StockCategoryEntity>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  StockItemWarehouse?: Maybe<Array<StockItemWarehouseEntity>>;
  StockIssueItem?: Maybe<Array<StockIssueItemEntity>>;
  StockTransferItem?: Maybe<Array<StockTransferItemEntity>>;
  CycleCountItem?: Maybe<Array<CycleCountItemEntity>>;
  StockAdjusmentItem?: Maybe<Array<StockAdjustmentItemEntity>>;
  StockReceiptItem?: Maybe<Array<StockReceiptItemEntity>>;
  StockWeightedAvg?: Maybe<Array<StockWeightedAvgEntity>>;
  StockRequisitionItem?: Maybe<Array<StockRequisitionItemEntity>>;
  FavoriteStockRequisitionItem?: Maybe<Array<FavoriteStockRequisitionItemEntity>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  CurrentQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  IsUse?: Maybe<Scalars['Boolean']>;
};


export type StockItemEntityCurrentQtyArgs = {
  DocDate?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};

/** JsonType */
export type StockItemInfo = {
  __typename?: 'StockItemInfo';
  Name?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
};

export type StockItemInput = {
  StockItemID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  ABCClass?: Maybe<Scalars['String']>;
  ShelfLife?: Maybe<Scalars['Float']>;
  StandardCost?: Maybe<Scalars['Float']>;
  StockCategoryID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
  RecordStatus?: Maybe<RecordStatus>;
};

export type StockItemMovementInput = {
  StockItemID: Scalars['String'];
  WarehouseID: Scalars['String'];
  UOMID: Scalars['String'];
  CompanyID: Scalars['String'];
  IsAdjustment?: Maybe<Scalars['Boolean']>;
  DocDate: Scalars['String'];
};

export type StockItemWarehouseDeleteInput = {
  StockItemWarehouseID: Scalars['String'];
};

export type StockItemWarehouseEntity = AuditEntity & {
  __typename?: 'StockItemWarehouseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  StockItemWarehouseID: Scalars['String'];
  MaximumQty: Scalars['Float'];
  MinimumQty: Scalars['Float'];
  ReorderLevel: Scalars['Float'];
  ReorderQty: Scalars['Float'];
  StockItemID: Scalars['String'];
  StockItem?: Maybe<StockItemEntity>;
  WarehouseID: Scalars['String'];
  Warehouse?: Maybe<WarehouseEntity>;
  /** CustomFieldResolver */
  StockQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  SubmitStockQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  StockTotalValue?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  StockAvgUnitCost?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  UsedStockItemWarehouse?: Maybe<Scalars['Boolean']>;
};


export type StockItemWarehouseEntityStockQtyArgs = {
  WarehouseID?: Maybe<Scalars['String']>;
};


export type StockItemWarehouseEntitySubmitStockQtyArgs = {
  WarehouseID?: Maybe<Scalars['String']>;
};


export type StockItemWarehouseEntityStockTotalValueArgs = {
  WarehouseID?: Maybe<Scalars['String']>;
};


export type StockItemWarehouseEntityStockAvgUnitCostArgs = {
  WarehouseID?: Maybe<Scalars['String']>;
};

export type StockItemWarehouseInput = {
  StockItemWarehouseID?: Maybe<Scalars['String']>;
  MaximumQty?: Maybe<Scalars['Float']>;
  MinimumQty?: Maybe<Scalars['Float']>;
  ReorderLevel?: Maybe<Scalars['Float']>;
  ReorderQty?: Maybe<Scalars['Float']>;
  StockItemID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
};

/** JsonType */
export type StockItemWarehouseType = {
  __typename?: 'StockItemWarehouseType';
  Data: Array<StockItemWarehouseEntity>;
  TotalLength?: Maybe<Scalars['Float']>;
};

/** JsonType */
export type StockMovementInfo = {
  __typename?: 'StockMovementInfo';
  StockItemWarehouseID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
};

/** JsonType */
export type StockMovementInput = {
  __typename?: 'StockMovementInput';
  StockItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};

/** JsonType */
export type StockQtyAdjustmentType = {
  __typename?: 'StockQtyAdjustmentType';
  Data: Array<StockAdjustmentEntity>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type StockReceiptDeleteInput = {
  StockReceiptID: Scalars['String'];
};

export type StockReceiptEntity = AuditEntity & {
  __typename?: 'StockReceiptEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  StockReceiptID: Scalars['String'];
  CompanyID: Scalars['String'];
  WarehouseID: Scalars['String'];
  Warehouse?: Maybe<WarehouseEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  StockReceiptItem?: Maybe<Array<StockReceiptItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type StockReceiptInput = {
  StockReceiptID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  WarehouseID?: Maybe<Scalars['String']>;
};

export type StockReceiptItemDeleteInput = {
  StockReceiptItemID: Scalars['String'];
};

export type StockReceiptItemEntity = AuditEntity & {
  __typename?: 'StockReceiptItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DefaultUOMID?: Maybe<Scalars['String']>;
  DefaultUOMQty?: Maybe<Scalars['Float']>;
  DefaultUOMUnitCost?: Maybe<Scalars['Float']>;
  DefaultUOMTotalCost?: Maybe<Scalars['Float']>;
  StockReceiptItemID: Scalars['String'];
  Quantity?: Maybe<Scalars['Float']>;
  BalanceQty?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  TotalCost?: Maybe<Scalars['Float']>;
  WarehouseID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  StockReceiptID: Scalars['String'];
  StockReceipt?: Maybe<StockReceiptEntity>;
  UOMID?: Maybe<Scalars['String']>;
  UOM?: Maybe<AccUomEntity>;
  StockItemID?: Maybe<Scalars['String']>;
  StockItem?: Maybe<StockItemEntity>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<TransactionTypeEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  StockItemWarehouse?: Maybe<StockItemWarehouseEntity>;
};

export type StockReceiptItemInput = {
  StockReceiptItemID?: Maybe<Scalars['String']>;
  StockReceiptID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  UnitCost?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  BalanceQty?: Maybe<Scalars['Float']>;
  TotalCost?: Maybe<Scalars['Float']>;
  Sequence?: Maybe<Scalars['Float']>;
  WarehouseID?: Maybe<Scalars['String']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type StockReceiptRejectInput = {
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type StockReceiptType = {
  __typename?: 'StockReceiptType';
  Data: Array<StockReceiptEntity>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type StockReconInput = {
  CycleCountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
};

export type StockReconItemInput = {
  CycleCountItemID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  WarehouseID?: Maybe<Scalars['String']>;
  OnHandQty?: Maybe<Scalars['Float']>;
  CountQty?: Maybe<Scalars['Float']>;
  AdjustQty?: Maybe<Scalars['Float']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
};

export type StockRequisitionCancelInput = {
  StockRequisitionID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type StockRequisitionDeleteInput = {
  StockRequisitionID: Scalars['String'];
};

export type StockRequisitionEntity = AuditEntity & {
  __typename?: 'StockRequisitionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  StockRequisitionID: Scalars['String'];
  CompanyID: Scalars['String'];
  WarehouseID: Scalars['String'];
  Warehouse?: Maybe<WarehouseEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  RequestDate: Scalars['String'];
  ExpectedDate?: Maybe<Scalars['String']>;
  StockRequisitionItem?: Maybe<Array<StockRequisitionItemEntity>>;
  StockIssue?: Maybe<Array<StockIssueEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type StockRequisitionInput = {
  StockRequisitionID?: Maybe<Scalars['String']>;
  RequestDate?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  WarehouseID?: Maybe<Scalars['String']>;
};

export type StockRequisitionItemDeleteInput = {
  StockRequisitionItemID: Scalars['String'];
};

export type StockRequisitionItemEntity = AuditEntity & {
  __typename?: 'StockRequisitionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DefaultUOMID?: Maybe<Scalars['String']>;
  DefaultUOMQty?: Maybe<Scalars['Float']>;
  DefaultUOMUnitCost?: Maybe<Scalars['Float']>;
  DefaultUOMTotalCost?: Maybe<Scalars['Float']>;
  StockRequisitionItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  StockRequisitionID: Scalars['String'];
  StockRequisition?: Maybe<StockRequisitionEntity>;
  StockItemID: Scalars['String'];
  StockItem?: Maybe<StockItemEntity>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  WarehouseID?: Maybe<Scalars['String']>;
  Warehouse?: Maybe<WarehouseEntity>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<TransactionTypeEntity>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  TotalCost?: Maybe<Scalars['Float']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  StockIssueItem?: Maybe<Array<StockIssueItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type StockRequisitionItemInput = {
  StockRequisitionItemID?: Maybe<Scalars['String']>;
  StockRequisitionID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  UnitCost?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  Sequence?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type StockRequisitionRejectInput = {
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type StockRequisitionType = {
  __typename?: 'StockRequisitionType';
  Data?: Maybe<Array<StockRequisitionEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type StockTransferDeleteInput = {
  StockTransferID: Scalars['String'];
};

export type StockTransferEntity = AuditEntity & {
  __typename?: 'StockTransferEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  StockTransferID: Scalars['String'];
  CompanyID: Scalars['String'];
  FromWarehouseID: Scalars['String'];
  FromWarehouse?: Maybe<WarehouseEntity>;
  ToWarehouseID: Scalars['String'];
  ToWarehouse?: Maybe<WarehouseEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  StockTransferItem?: Maybe<Array<StockTransferItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type StockTransferInput = {
  StockTransferID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  FromWarehouseID?: Maybe<Scalars['String']>;
  ToWarehouseID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
};

export type StockTransferItemDeleteInput = {
  StockTransferItemID: Scalars['String'];
};

export type StockTransferItemEntity = AuditEntity & {
  __typename?: 'StockTransferItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DefaultUOMID?: Maybe<Scalars['String']>;
  DefaultUOMQty?: Maybe<Scalars['Float']>;
  DefaultUOMUnitCost?: Maybe<Scalars['Float']>;
  DefaultUOMTotalCost?: Maybe<Scalars['Float']>;
  StockTransferItemID: Scalars['String'];
  Quantity?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  TotalCost?: Maybe<Scalars['Float']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  StockTransferID: Scalars['String'];
  StockTransfer?: Maybe<StockTransferEntity>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  StockItemID: Scalars['String'];
  StockItem?: Maybe<StockItemEntity>;
  ReasonID?: Maybe<Scalars['String']>;
  Reason?: Maybe<StockAdjustmentReasonEntity>;
  FromWarehouseID?: Maybe<Scalars['String']>;
  ToWarehouseID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  StockItemWarehouse?: Maybe<StockItemWarehouseEntity>;
};

export type StockTransferItemInput = {
  StockTransferItemID?: Maybe<Scalars['String']>;
  StockTransferID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Sequence?: Maybe<Scalars['Float']>;
  FromWarehouseID?: Maybe<Scalars['String']>;
  ToWarehouseID?: Maybe<Scalars['String']>;
};

export type StockTransferRejectInput = {
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type StockTransferRequisitionCancelInput = {
  StockTransferRequisitionID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type StockTransferRequisitionDeleteInput = {
  StockTransferRequisitionID: Scalars['String'];
};

export type StockTransferRequisitionEntity = AuditEntity & {
  __typename?: 'StockTransferRequisitionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  StockTransferRequisitionID: Scalars['String'];
  CompanyID: Scalars['String'];
  FromWarehouseID: Scalars['String'];
  FromWarehouse?: Maybe<WarehouseEntity>;
  ToWarehouseID: Scalars['String'];
  ToWarehouse?: Maybe<WarehouseEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  StockTransferRequisitionItem?: Maybe<Array<StockTransferRequisitionItemEntity>>;
  StockTransfer?: Maybe<Array<StockTransferEntity>>;
  RequestDate: Scalars['String'];
  ExpectedDate?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type StockTransferRequisitionInput = {
  StockTransferRequisitionID?: Maybe<Scalars['String']>;
  RequestDate?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  FromWarehouseID?: Maybe<Scalars['String']>;
  ToWarehouseID?: Maybe<Scalars['String']>;
};

export type StockTransferRequisitionItemDeleteInput = {
  StockTransferRequisitionItemID: Scalars['String'];
};

export type StockTransferRequisitionItemEntity = AuditEntity & {
  __typename?: 'StockTransferRequisitionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DefaultUOMID?: Maybe<Scalars['String']>;
  DefaultUOMQty?: Maybe<Scalars['Float']>;
  DefaultUOMUnitCost?: Maybe<Scalars['Float']>;
  DefaultUOMTotalCost?: Maybe<Scalars['Float']>;
  StockTransferRequisitionItemID: Scalars['String'];
  Quantity?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  TotalCost?: Maybe<Scalars['Float']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  StockTransferRequisitionID: Scalars['String'];
  StockTransferRequisition?: Maybe<StockTransferRequisitionEntity>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  StockItemID: Scalars['String'];
  StockItem?: Maybe<StockItemEntity>;
  ReasonID?: Maybe<Scalars['String']>;
  Reason?: Maybe<StockAdjustmentReasonEntity>;
  FromWarehouseID?: Maybe<Scalars['String']>;
  ToWarehouseID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  StockItemWarehouse?: Maybe<StockItemWarehouseEntity>;
};

export type StockTransferRequisitionItemInput = {
  StockTransferRequisitionItemID?: Maybe<Scalars['String']>;
  StockTransferRequisitionID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  UnitCost?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  Sequence?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  FromWarehouseID?: Maybe<Scalars['String']>;
  ToWarehouseID?: Maybe<Scalars['String']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
};

export type StockTransferRequisitionRejectInput = {
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type StockTransferRequisitionType = {
  __typename?: 'StockTransferRequisitionType';
  Data?: Maybe<Array<StockTransferRequisitionEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

/** JsonType */
export type StockTransferType = {
  __typename?: 'StockTransferType';
  Data: Array<StockTransferEntity>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type StockWeightedAvgEntity = AuditEntity & {
  __typename?: 'StockWeightedAvgEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  StockWeightedAvgID: Scalars['String'];
  CompanyID: Scalars['String'];
  WarehouseID: Scalars['String'];
  Warehouse?: Maybe<WarehouseEntity>;
  StockItemID: Scalars['String'];
  StockItem?: Maybe<StockItemEntity>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  ReserveQty: Scalars['Float'];
  BalanceQty: Scalars['Float'];
  UnitCost: Scalars['Float'];
  TotalValue: Scalars['Float'];
  SubmitQty: Scalars['Float'];
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type SubLocalBankInput = {
  ID?: Maybe<Scalars['String']>;
  countryID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  contactNo?: Maybe<Scalars['String']>;
  faxNo?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<Scalars['String']>;
};

export type TaxDetails = {
  __typename?: 'TaxDetails';
  taxRefNo?: Maybe<Scalars['String']>;
  taxExpDate?: Maybe<Scalars['DateTime']>;
};

export type TaxDetailsInput = {
  taxRefNo?: Maybe<Scalars['String']>;
  taxExpDate?: Maybe<Scalars['DateTime']>;
};

export type TaxEffective = {
  __typename?: 'TaxEffective';
  Date?: Maybe<Scalars['String']>;
  Rate?: Maybe<Scalars['Float']>;
};

export type TaxEffectiveDateEntity = AuditEntity & {
  __typename?: 'TaxEffectiveDateEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  TaxEffectiveDateID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  Date: Scalars['String'];
  Rate: Scalars['Float'];
  TaxScheme?: Maybe<TaxSchemeEntity>;
};

export type TaxEffectiveDateInput = {
  TaxSchemeID: Scalars['String'];
  Date: Scalars['DateTime'];
  Description: Scalars['String'];
};

export type TaxEffectiveDateRateInput = {
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  TaxRate?: Maybe<Scalars['Float']>;
};

export type TaxEffectiveInput = {
  Date?: Maybe<Scalars['String']>;
  Rate?: Maybe<Scalars['Float']>;
};

export type TaxSchemeEffectiveInput = {
  TaxSchemeID: Scalars['String'];
  TaxTypeID?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  MasterCOAID: Scalars['String'];
  AccTaxCategory?: Maybe<AccTaxCategory>;
  AccTaxClass?: Maybe<AccTaxClass>;
  RecordStatus?: Maybe<RecordStatus>;
  EffectiveDateRate: Array<TaxEffectiveDateRateInput>;
};

export type TaxSchemeEntity = AuditEntity & {
  __typename?: 'TaxSchemeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  TaxSchemeID: Scalars['String'];
  TaxTypeID: Scalars['String'];
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  AccTaxCategory: AccTaxCategory;
  AccTaxClass: AccTaxClass;
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxType?: Maybe<TaxTypeEntity>;
  TaxEffective?: Maybe<Array<TaxEffectiveDateEntity>>;
  /** CustomFieldResolver */
  LatestTax?: Maybe<AccTax>;
  PaymentItem?: Maybe<Array<PaymentItemEntity>>;
  LedgerItem?: Maybe<Array<LedgerItemEntity>>;
  ReceiptItem?: Maybe<Array<ReceiptItemEntity>>;
  FavoritePaymentItem?: Maybe<Array<FavoritePaymentItemEntity>>;
  FavoriteReceiptItem?: Maybe<Array<FavoriteReceiptItemEntity>>;
  FavoriteInvoiceItem?: Maybe<Array<AccArFavoriteInvoiceItemEntity>>;
  CreditorAccount?: Maybe<Array<CreditorAccountEntity>>;
  ARAdvance?: Maybe<Array<AccArAdvanceEntity>>;
  APInvoiceItem?: Maybe<Array<AccApInvoiceItemEntity>>;
  ARInvoiceItem?: Maybe<Array<AccArInvoiceItemEntity>>;
  APCreditNoteItem?: Maybe<Array<AccApCreditNoteItemEntity>>;
  APCreditorCreditNoteItem?: Maybe<Array<AccApCreditorCreditNoteItemEntity>>;
  APDebitNoteItem?: Maybe<Array<AccApDebitNoteItemEntity>>;
  APSelfBilledItem?: Maybe<Array<AccApSelfBilledItemEntity>>;
  APCreditorDebitNoteItem?: Maybe<Array<AccApCreditorDebitNoteItemEntity>>;
  ARCreditNoteItem?: Maybe<Array<AccArCreditNoteItemEntity>>;
  ARDebitNoteItem?: Maybe<Array<AccArDebitNoteItemEntity>>;
  StockItem?: Maybe<Array<StockItemEntity>>;
  ExpenseItem?: Maybe<Array<ExpenseItemEntity>>;
  BillItem?: Maybe<Array<BillItemEntity>>;
  DebtorAccount?: Maybe<Array<DebtorAccountEntity>>;
};

export type TaxSchemeInput = {
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxTypeID?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  MasterCOAID: Scalars['String'];
  AccTaxCategory?: Maybe<AccTaxCategory>;
  AccTaxClass?: Maybe<AccTaxClass>;
  RecordStatus?: Maybe<RecordStatus>;
};

export type TaxTypeEntity = AuditEntity & {
  __typename?: 'TaxTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  TaxTypeID: Scalars['String'];
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  RecordStatus: RecordStatus;
  TaxScheme?: Maybe<Array<TaxSchemeEntity>>;
};

export type TaxTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
};

/** JsonType */
export type ToDo = {
  __typename?: 'ToDo';
  createdBy?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['DateTime']>;
  RefID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['DateTime']>;
  RefTable?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
  BankCode?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  FromBankAccountID?: Maybe<Scalars['String']>;
  FromBankCode?: Maybe<Scalars['String']>;
  FromBankAccountNo?: Maybe<Scalars['String']>;
  ToBankAccountID?: Maybe<Scalars['String']>;
  ToBankCode?: Maybe<Scalars['String']>;
  ToBankAccountNo?: Maybe<Scalars['String']>;
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  JournalType?: Maybe<Scalars['String']>;
};

export type TotpSecretTyes = {
  __typename?: 'TotpSecretTyes';
  is2FA?: Maybe<Scalars['Boolean']>;
  totpSecret?: Maybe<Scalars['String']>;
  dataURL?: Maybe<Scalars['String']>;
  otpURL?: Maybe<Scalars['String']>;
};

export enum TransactionType {
  CcReceipt = 'CC_RECEIPT',
  CcIssue = 'CC_ISSUE',
  Receipt = 'RECEIPT',
  Issue = 'ISSUE',
  Grn = 'GRN',
  Grtn = 'GRTN'
}

export type TransactionTypeDeleteInput = {
  TransactionTypeID: Scalars['String'];
};

export type TransactionTypeEntity = AuditEntity & {
  __typename?: 'TransactionTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  TransactionTypeID: Scalars['String'];
  Name: Scalars['String'];
  Type: TransactionType;
  IsSystem: Scalars['Boolean'];
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  StockReceiptItem?: Maybe<Array<StockReceiptItemEntity>>;
  StockAdjustmentItem?: Maybe<Array<StockAdjustmentItemEntity>>;
  StockIssueItem?: Maybe<Array<StockIssueItemEntity>>;
  CycleCountItem?: Maybe<Array<CycleCountItemEntity>>;
  StockCostAdjustmentItem?: Maybe<Array<StockCostAdjustmentItemEntity>>;
  StockRequisitionItem?: Maybe<Array<StockRequisitionItemEntity>>;
  FavoriteStockRequisitionItem?: Maybe<Array<FavoriteStockRequisitionItemEntity>>;
};

export type TransactionTypeInput = {
  TransactionTypeID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
  RecordStatus?: Maybe<Scalars['String']>;
};

export type UomDeleteInput = {
  UOMID: Scalars['String'];
};

export type UomInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  UOMID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type UomExchangeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  FromUomID?: Maybe<Scalars['String']>;
  ToUomID?: Maybe<Scalars['String']>;
  FromAmt?: Maybe<Scalars['Float']>;
  ToAmt?: Maybe<Scalars['Float']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  UomLog?: Maybe<Array<ExchangeLogInput>>;
};

export type UpdateAuditInput = {
  modBy?: Maybe<Scalars['String']>;
};

export type UpdateDoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  ContactPerson?: Maybe<ContactPersonInput>;
  SupplierID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  DoNo: Scalars['String'];
  CompanyID: Scalars['String'];
  File?: Maybe<Array<Scalars['Upload']>>;
};


export type UserApprovalAssignmentEntity = AuditEntity & {
  __typename?: 'UserApprovalAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  UserApprovalAssignmentID: Scalars['String'];
  UserID: Scalars['String'];
  RoleID: Scalars['String'];
  DocRefID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  RefTable: Scalars['String'];
};

export type UserEntity = AuditEntity & {
  __typename?: 'UserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  is2FA?: Maybe<Scalars['Boolean']>;
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  softwareCode: Scalars['String'];
  loginAttempt: Scalars['Float'];
  blockDuration: Scalars['Float'];
  blockCount: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  deviceIP?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  roleDetail?: Maybe<RoleInfo>;
  /** CustomFieldResolver */
  companyAssigned?: Maybe<Array<CompanyInfo>>;
};

/** JsonType */
export type UserInfo = {
  __typename?: 'UserInfo';
  Name?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
};

export type UserInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  contactNo: Scalars['String'];
  email: Scalars['String'];
  hashed?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  superUser?: Maybe<Scalars['Boolean']>;
  department?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  commonStatus?: Maybe<CommonStatus>;
};

export type UserListInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UserResponse = AuditEntity & {
  __typename?: 'UserResponse';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  confirmed: Scalars['Boolean'];
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  commonStatus: CommonStatus;
  password?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  loginTs?: Maybe<Scalars['DateTime']>;
  blockDuration?: Maybe<Scalars['Float']>;
  blockCount?: Maybe<Scalars['Float']>;
  loginAttempt?: Maybe<Scalars['Float']>;
  is2FA?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  errors?: Maybe<Array<FieldError>>;
};

export type UserRoleEntity = AuditEntity & {
  __typename?: 'UserRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  UserRoleID: Scalars['String'];
  UserID: Scalars['String'];
  RoleID?: Maybe<Scalars['String']>;
};

export enum UserSelection {
  All = 'ALL',
  Superuser = 'SUPERUSER',
  Nonsuper = 'NONSUPER'
}

export type WarehouseDeleteInput = {
  WarehouseID: Scalars['String'];
};

export type WarehouseEntity = AuditEntity & {
  __typename?: 'WarehouseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  WarehouseID: Scalars['String'];
  CompanyID: Scalars['String'];
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  Code?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Address?: Maybe<Scalars['JSON']>;
  ContactNo: Scalars['String'];
  Designation?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  StockItemWarehouse?: Maybe<Array<StockItemWarehouseEntity>>;
  StockReceipt?: Maybe<Array<StockReceiptEntity>>;
  StockIssue?: Maybe<Array<StockIssueEntity>>;
  FromStockTransfer?: Maybe<Array<StockTransferEntity>>;
  ToStockTransfer?: Maybe<Array<StockTransferEntity>>;
  CycleCount?: Maybe<Array<CycleCountEntity>>;
  StockAdjustment?: Maybe<Array<StockAdjustmentEntity>>;
  StockCostAdjustment?: Maybe<Array<StockCostAdjustmentEntity>>;
  StockWeightedAvg?: Maybe<Array<StockWeightedAvgEntity>>;
  StockRequisition?: Maybe<Array<StockRequisitionEntity>>;
  StockRequisitionItem?: Maybe<Array<StockRequisitionItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  UsedWarehouse?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  userAssigned?: Maybe<Array<UserInfo>>;
  /** CustomFieldResolver */
  stockItemAssigned?: Maybe<Array<StockItemInfo>>;
};

/** JsonType */
export type WarehouseInfo = {
  __typename?: 'WarehouseInfo';
  AccountID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
};

export type WarehouseInput = {
  WarehouseID?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['JSON']>;
  ContactNo?: Maybe<Scalars['String']>;
  Designation?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type WarehouseItemAssignmentInput = {
  StockItemWarehouseID?: Maybe<Scalars['String']>;
  WarehouseID: Scalars['String'];
  CompanyID: Scalars['String'];
  stockItemID: Array<Scalars['String']>;
};

export type WarehouseUserAssignmentEntity = AuditEntity & {
  __typename?: 'WarehouseUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  WarehouseUserAssignmentID: Scalars['String'];
  UserID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
};

export type WarehouseUserAssignmentInput = {
  WarehouseUserAssignmentID?: Maybe<Scalars['String']>;
  WarehouseID: Scalars['String'];
  CompanyID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type Weekend = {
  __typename?: 'Weekend';
  monday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  wednesday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  friday?: Maybe<Scalars['Boolean']>;
  saturday?: Maybe<Scalars['Boolean']>;
  sunday?: Maybe<Scalars['Boolean']>;
};

export type WeekendInput = {
  monday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  wednesday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  friday?: Maybe<Scalars['Boolean']>;
  saturday?: Maybe<Scalars['Boolean']>;
  sunday?: Maybe<Scalars['Boolean']>;
};

export type WorkDeskInput = {
  RefID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type WorkFlowInput = {
  AccountID?: Maybe<Scalars['String']>;
  ApprovalPolicyAssignmentID?: Maybe<Scalars['String']>;
  SubmitterID?: Maybe<Scalars['String']>;
  SubmitDate?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WorkFlowStatus?: Maybe<ApprovalStatus>;
  CreatedDT?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type WorkFlowStepInput = {
  WorkFlowStepID?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  RoleID?: Maybe<Scalars['String']>;
  StepNo?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  ApprovalPolicyAssignmentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  UserList?: Maybe<Array<UserListInput>>;
  Predicate?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
};

export enum YearPeriodType {
  Relative = 'RELATIVE',
  Fixed = 'FIXED'
}

export type EInvoiceClassificationEntity = {
  __typename?: 'eInvoiceClassificationEntity';
  id: Scalars['String'];
  classificationCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type EInvoiceMsicEntity = {
  __typename?: 'eInvoiceMsicEntity';
  id: Scalars['String'];
  msicCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  categoryReference?: Maybe<Scalars['String']>;
};

export type GeneralPrItemInfo = {
  __typename?: 'generalPRItemInfo';
  generalPRItemID?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  generalPRID?: Maybe<Scalars['String']>;
  purchaseItemID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  uomCode?: Maybe<Scalars['String']>;
  generalRFQItemID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  purchaseItemName?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  outstandingQty?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Scalars['JSON']>;
  address?: Maybe<Scalars['JSON']>;
  docDate?: Maybe<Scalars['DateTime']>;
  expectedDate?: Maybe<Scalars['DateTime']>;
};

export type PasswordAccInfo = {
  ID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  confirmedPassword: Scalars['String'];
};

export type PoUomExchange = {
  __typename?: 'poUOMExchange';
  uomid: Scalars['String'];
  uomname: Scalars['String'];
  uomcode: Scalars['String'];
};

export type PurchaserUserRoleId = {
  __typename?: 'purchaserUserRoleID';
  roleID: Scalars['String'];
  userID: Scalars['String'];
};

export type LoggedInUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedInUserProfileQuery = (
  { __typename?: 'Query' }
  & { loggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'accountID' | 'avatar' | 'name' | 'email' | 'contactNo' | 'department' | 'superUser' | 'userName' | 'jobTitle'>
  ) }
);

export type ProcurementLoggedInUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type ProcurementLoggedInUserProfileQuery = (
  { __typename?: 'Query' }
  & { procurementLoggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'accountID' | 'avatar' | 'name' | 'email' | 'contactNo' | 'department' | 'superUser' | 'userName' | 'jobTitle' | 'lastestAccessedEntity'>
  ) }
);

export type ProcurementLoginMutationVariables = Exact<{
  loginId: Scalars['String'];
  password: Scalars['String'];
}>;


export type ProcurementLoginMutation = (
  { __typename?: 'Mutation' }
  & { procurementLogin: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'accessToken'>
    & { user: (
      { __typename?: 'UserResponse' }
      & Pick<UserResponse, 'ID' | 'name' | 'userName' | 'email' | 'contactNo' | 'superUser' | 'department' | 'jobTitle' | 'avatar' | 'lastestAccessedEntity'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'country' | 'city' | 'district' | 'state' | 'postCode'>
      )> }
    ) }
  ) }
);

export type ChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  userID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type LogoutMutationVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type ActivateUserMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ActivateUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateUser'>
);

export type CreatePasswordMutationVariables = Exact<{
  input: PasswordAccInfo;
}>;


export type CreatePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPassword'>
);

export type GetCompanySettingSummaryQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type GetCompanySettingSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'lastApprovalPolicy' | 'lastNumberingStructure' | 'activeDeliveryLocationCount'>
);

export type GetDeliveryLocationQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetDeliveryLocationQuery = (
  { __typename?: 'Query' }
  & { getDeliveryLocation: Array<(
    { __typename?: 'DeliveryLocationEntity' }
    & Pick<DeliveryLocationEntity, 'DeliveryLocationID' | 'Name' | 'Address' | 'ContactNo' | 'MainLocation' | 'CompanyID'>
  )> }
);

export type GetDeliveryLocationNameQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetDeliveryLocationNameQuery = (
  { __typename?: 'Query' }
  & { getDeliveryLocation: Array<(
    { __typename?: 'DeliveryLocationEntity' }
    & Pick<DeliveryLocationEntity, 'DeliveryLocationID' | 'Name'>
  )> }
);

export type CreateDeliveryLocationMutationVariables = Exact<{
  input: DeliveryLocationInput;
}>;


export type CreateDeliveryLocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createDeliveryLoc'>
);

export type UpdateDeliveryLocationMutationVariables = Exact<{
  input: DeliveryLocationInput;
}>;


export type UpdateDeliveryLocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDeliveryLoc'>
);

export type DeleteDeliveryLocationMutationVariables = Exact<{
  input: DeliveryLocationDeleteInput;
}>;


export type DeleteDeliveryLocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDeliveryLocation'>
);

export type GetBankProfileQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
}>;


export type GetBankProfileQuery = (
  { __typename?: 'Query' }
  & { getBankProfile: Array<(
    { __typename?: 'BankProfileEntity' }
    & Pick<BankProfileEntity, 'BankProfileID' | 'Name' | 'Address' | 'ContactNo' | 'Email'>
  )> }
);

export type GetLocalBankQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetLocalBankQuery = (
  { __typename?: 'Query' }
  & { getLocalBank: Array<(
    { __typename?: 'LocalBankEntity' }
    & Pick<LocalBankEntity, 'ID' | 'name' | 'code'>
    & { address: (
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'district' | 'state' | 'postCode' | 'country'>
    ) }
  )> }
);

export type GetCompanyQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  ParentCompanyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
}>;


export type GetCompanyQuery = (
  { __typename?: 'Query' }
  & { getCompany: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'ParentCompanyID' | 'Name' | 'Code' | 'CompanyRegNo' | 'CompanyTax' | 'SSTNo' | 'GSTNo' | 'GSTExpiryDate' | 'ContactNo' | 'BaseCurrencyID'>
    & { Address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'ID' | 'address' | 'address2' | 'siteName' | 'country' | 'city' | 'district' | 'state' | 'postCode' | 'postCodeName' | 'AddressType'>
    )>, Location?: Maybe<(
      { __typename?: 'DeliveryLocationEntity' }
      & Pick<DeliveryLocationEntity, 'DeliveryLocationID' | 'Name'>
    )> }
  )> }
);

export type GetParentCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetParentCompanyQuery = (
  { __typename?: 'Query' }
  & { getParentCompany: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'Name'>
  )> }
);

export type GetCompanyNameQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  RecordStatus?: Maybe<RecordStatus>;
}>;


export type GetCompanyNameQuery = (
  { __typename?: 'Query' }
  & { getCompany: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'ParentCompanyID' | 'Name' | 'Code' | 'CompanyRegNo' | 'CompanyTax' | 'ContactNo' | 'GSTNo' | 'SSTNo' | 'RecordStatus'>
    & { Address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'ID' | 'address' | 'address2' | 'siteName' | 'country' | 'city' | 'district' | 'state' | 'postCode' | 'postCodeName' | 'AddressType'>
    )> }
  )> }
);

export type GetAssignedCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAssignedCompanyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAssignedCompanyByUser'>
);

export type GetActiveCompanyCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActiveCompanyCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getActiveCompanyCount'>
);

export type GetCompanyNameWithAccountQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetCompanyNameWithAccountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCompanyNameWithAccount'>
);

export type GetCostCentreQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  IsLastNode?: Maybe<Scalars['Boolean']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  ItemID?: Maybe<Scalars['String']>;
}>;


export type GetCostCentreQuery = (
  { __typename?: 'Query' }
  & { getCostCentre: Array<(
    { __typename?: 'CostCentreEntity' }
    & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'CompanyID' | 'ParentCostCentreID' | 'Level' | 'Code' | 'MasterCode' | 'IsLastNode' | 'IsDefault'>
  )> }
);

export type GetCostCentreListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetCostCentreListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCostCentreListing'>
);

export type GetCostCentreDefinitionAsgmtQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type GetCostCentreDefinitionAsgmtQuery = (
  { __typename?: 'Query' }
  & { getCostCentreDefinitionAsgmt: Array<(
    { __typename?: 'CostCentreDefinitionAsgmtEntity' }
    & Pick<CostCentreDefinitionAsgmtEntity, 'CostCentreDefinitionAsgmtID' | 'CostCentreDefinitionHeaderID' | 'CompanyID'>
    & { CostCentreDefinitionHeader?: Maybe<(
      { __typename?: 'CostCentreDefinitionHeaderEntity' }
      & Pick<CostCentreDefinitionHeaderEntity, 'Title'>
      & { CostCentreDefinitionDetail?: Maybe<Array<(
        { __typename?: 'CostCentreDefinitionDetailEntity' }
        & Pick<CostCentreDefinitionDetailEntity, 'Name' | 'Level' | 'IsLastNode'>
      )>> }
    )> }
  )> }
);

export type GetCostCentreDefinitionDetailQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type GetCostCentreDefinitionDetailQuery = (
  { __typename?: 'Query' }
  & { getCostCentreDefinitionDetail: Array<(
    { __typename?: 'CostCentreDefinitionDetailEntity' }
    & Pick<CostCentreDefinitionDetailEntity, 'CostCentreDefinitionDetailID' | 'ParentCostCentreDefinitionDetailID' | 'CostCentreDefinitionHeaderID' | 'Name' | 'Level'>
  )> }
);

export type GetNextLevelCcDefinitionQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  CostCentreID?: Maybe<Scalars['String']>;
}>;


export type GetNextLevelCcDefinitionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getNextLevelCCDefinition'>
);

export type GetParentCostCentreForTreeQueryVariables = Exact<{
  CostCentreArr?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetParentCostCentreForTreeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getParentCostCentreForTree'>
);

export type SetDefaultCostCentreMutationVariables = Exact<{
  CostCentreID: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type SetDefaultCostCentreMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setDefaultCostCentre'>
);

export type GetCostCentreCodeQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  IsLastNode?: Maybe<Scalars['Boolean']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  AccountID?: Maybe<Scalars['String']>;
}>;


export type GetCostCentreCodeQuery = (
  { __typename?: 'Query' }
  & { getCostCentre: Array<(
    { __typename?: 'CostCentreEntity' }
    & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code' | 'IsDefault'>
  )> }
);

export type GetMasterCoaQueryVariables = Exact<{
  AccountType?: Maybe<Scalars['String']>;
  IsLastNode?: Maybe<Scalars['Boolean']>;
  orderByAsc?: Maybe<Scalars['String']>;
  IsControl?: Maybe<Scalars['Boolean']>;
  AccountID?: Maybe<Scalars['String']>;
}>;


export type GetMasterCoaQuery = (
  { __typename?: 'Query' }
  & { getMasterCOA: Array<(
    { __typename?: 'MasterCOAEntity' }
    & Pick<MasterCoaEntity, 'MasterCOAID' | 'ParentMasterCOAID' | 'Name' | 'Code' | 'IsControl' | 'AccountType' | 'IsLastNode' | 'Level' | 'RecordStatus'>
  )> }
);

export type GetMasterCoaCheckingQueryVariables = Exact<{
  IsLastNode?: Maybe<Scalars['Boolean']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetMasterCoaCheckingQuery = (
  { __typename?: 'Query' }
  & { getMasterCOA: Array<(
    { __typename?: 'MasterCOAEntity' }
    & Pick<MasterCoaEntity, 'MasterCOAID' | 'AccountType'>
  )> }
);

export type GetMasterCoaCodeQueryVariables = Exact<{
  IsLastNode?: Maybe<Scalars['Boolean']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetMasterCoaCodeQuery = (
  { __typename?: 'Query' }
  & { getMasterCOA: Array<(
    { __typename?: 'MasterCOAEntity' }
    & Pick<MasterCoaEntity, 'MasterCOAID' | 'Name' | 'Code' | 'AccountType' | 'IsLastNode' | 'IsControl'>
  )> }
);

export type GetStockItemQueryVariables = Exact<{
  StockItemID?: Maybe<Scalars['String']>;
  StockCategoryID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetStockItemQuery = (
  { __typename?: 'Query' }
  & { getStockItem: Array<(
    { __typename?: 'StockItemEntity' }
    & Pick<StockItemEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'AccountID' | 'StockItemID' | 'StockCategoryID' | 'RecordStatus' | 'Name' | 'ABCClass' | 'ShelfLife' | 'StandardCost' | 'UOMID'>
  )> }
);

export type GetCreditorProfileWithAccountQueryVariables = Exact<{
  accountID: Scalars['String'];
}>;


export type GetCreditorProfileWithAccountQuery = (
  { __typename?: 'Query' }
  & { getCreditorProfileWithAccount: Array<(
    { __typename?: 'CreditorAccountEntity' }
    & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CreditorTypeID' | 'CompanyRegNo' | 'ContactNo' | 'AccountID' | 'Email' | 'CreditTerm' | 'TaxSchemeID' | 'Region' | 'GSTRegNo' | 'TaxIdentificationNo' | 'Remark' | 'BankName' | 'BankAccountNo' | 'CreditorTypeEnum' | 'CompanyName' | 'modTs' | 'Address' | 'CreditorStatus'>
    & { CreditorContact?: Maybe<Array<(
      { __typename?: 'CreditorContactEntity' }
      & Pick<CreditorContactEntity, 'CreditorContactID' | 'CreditorAccountID' | 'Name' | 'ContactNo' | 'Designation' | 'Email' | 'IsDefault'>
    )>>, CreditorDirector?: Maybe<Array<(
      { __typename?: 'CreditorDirectorEntity' }
      & Pick<CreditorDirectorEntity, 'CreditorDirectorID' | 'CreditorAccountID' | 'Name' | 'ContactNo' | 'Email' | 'IdentityNo' | 'IdentityType'>
    )>> }
  )> }
);

export type GetCreditorAccountQueryVariables = Exact<{
  orderByAsc?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  CreditorTypeID?: Maybe<Scalars['String']>;
  CreditorTypeEnum?: Maybe<CreditorType>;
}>;


export type GetCreditorAccountQuery = (
  { __typename?: 'Query' }
  & { getCreditorAccount: Array<(
    { __typename?: 'CreditorAccountEntity' }
    & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CreditorTypeID' | 'CompanyRegNo' | 'ContactNo' | 'DocNoList' | 'Email' | 'CreditTerm' | 'TaxSchemeID' | 'TaxIdentificationNo' | 'Region' | 'GSTRegNo' | 'SSTRegNo' | 'Remark' | 'BankName' | 'BankAccountNo' | 'CreditorTypeEnum' | 'PayeeName' | 'SWIFTCode' | 'CompanyName' | 'modTs' | 'Address' | 'CreditorStatus' | 'einvoice_msic_code_id' | 'einvoice_classification_code_id' | 'einvoice_foreign_supplier' | 'einvoice_self_billed' | 'einvoice_type' | 'einvoice_TIN' | 'IsCompany' | 'ClassificationCode' | 'MSICCode'>
    & { CreditorType?: Maybe<(
      { __typename?: 'CreditorTypeEntity' }
      & Pick<CreditorTypeEntity, 'Code' | 'Description'>
    )>, TaxScheme?: Maybe<(
      { __typename?: 'TaxSchemeEntity' }
      & Pick<TaxSchemeEntity, 'Code' | 'Description'>
    )>, CreditorContact?: Maybe<Array<(
      { __typename?: 'CreditorContactEntity' }
      & Pick<CreditorContactEntity, 'CreditorContactID' | 'CreditorAccountID' | 'Name' | 'ContactNo' | 'Designation' | 'Email' | 'IsDefault'>
    )>>, CreditorDirector?: Maybe<Array<(
      { __typename?: 'CreditorDirectorEntity' }
      & Pick<CreditorDirectorEntity, 'CreditorDirectorID' | 'CreditorAccountID' | 'Name' | 'ContactNo' | 'Email' | 'IdentityNo' | 'IdentityType'>
    )>> }
  )> }
);

export type GetCreditorAccountListingQueryVariables = Exact<{
  orderByAsc?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  CreditorTypeID?: Maybe<Scalars['String']>;
  CreditorTypeEnum?: Maybe<CreditorType>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetCreditorAccountListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCreditorAccountCount'>
  & { getCreditorAccount: Array<(
    { __typename?: 'CreditorAccountEntity' }
    & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CreditorTypeID' | 'CompanyRegNo' | 'ContactNo' | 'DocNoList' | 'Email' | 'CreditTerm' | 'TaxSchemeID' | 'TaxIdentificationNo' | 'Region' | 'GSTRegNo' | 'SSTRegNo' | 'Remark' | 'BankName' | 'BankAccountNo' | 'CreditorTypeEnum' | 'PayeeName' | 'SWIFTCode' | 'CompanyName' | 'einvoice_foreign_supplier' | 'einvoice_self_billed' | 'einvoice_msic_code_id' | 'einvoice_classification_code_id' | 'ClassificationCode' | 'MSICCode' | 'einvoice_type' | 'einvoice_TIN' | 'IsCompany' | 'modTs' | 'Address' | 'CreditorStatus'>
    & { CreditorType?: Maybe<(
      { __typename?: 'CreditorTypeEntity' }
      & Pick<CreditorTypeEntity, 'Code' | 'Description'>
    )>, TaxScheme?: Maybe<(
      { __typename?: 'TaxSchemeEntity' }
      & Pick<TaxSchemeEntity, 'Code' | 'Description'>
    )>, CreditorContact?: Maybe<Array<(
      { __typename?: 'CreditorContactEntity' }
      & Pick<CreditorContactEntity, 'CreditorContactID' | 'CreditorAccountID' | 'Name' | 'ContactNo' | 'Designation' | 'Email' | 'IsDefault'>
    )>>, CreditorDirector?: Maybe<Array<(
      { __typename?: 'CreditorDirectorEntity' }
      & Pick<CreditorDirectorEntity, 'CreditorDirectorID' | 'CreditorAccountID' | 'Name' | 'ContactNo' | 'Email' | 'IdentityNo' | 'IdentityType'>
    )>> }
  )> }
);

export type GetCreditorAccountDetailQueryVariables = Exact<{
  CreditorAccountID?: Maybe<Scalars['String']>;
  CreditorTypeID?: Maybe<Scalars['String']>;
  CreditorTypeEnum?: Maybe<CreditorType>;
}>;


export type GetCreditorAccountDetailQuery = (
  { __typename?: 'Query' }
  & { getCreditorAccount: Array<(
    { __typename?: 'CreditorAccountEntity' }
    & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CreditorTypeID' | 'CompanyRegNo' | 'ContactNo' | 'DocNoList' | 'Email' | 'CreditTerm' | 'TaxSchemeID' | 'TaxIdentificationNo' | 'Region' | 'GSTRegNo' | 'SSTRegNo' | 'Remark' | 'BankName' | 'BankAccountNo' | 'CreditorTypeEnum' | 'PayeeName' | 'SWIFTCode' | 'CompanyName' | 'modTs' | 'Address' | 'CreditorStatus' | 'einvoice_msic_code_id' | 'einvoice_classification_code_id' | 'einvoice_foreign_supplier' | 'einvoice_self_billed' | 'einvoice_type' | 'einvoice_TIN' | 'IsCompany' | 'ClassificationCode' | 'MSICCode'>
    & { CreditorType?: Maybe<(
      { __typename?: 'CreditorTypeEntity' }
      & Pick<CreditorTypeEntity, 'Code' | 'Description'>
    )>, TaxScheme?: Maybe<(
      { __typename?: 'TaxSchemeEntity' }
      & Pick<TaxSchemeEntity, 'Code' | 'Description'>
    )>, CreditorContact?: Maybe<Array<(
      { __typename?: 'CreditorContactEntity' }
      & Pick<CreditorContactEntity, 'CreditorContactID' | 'CreditorAccountID' | 'Name' | 'ContactNo' | 'Designation' | 'Email' | 'IsDefault'>
    )>>, CreditorDirector?: Maybe<Array<(
      { __typename?: 'CreditorDirectorEntity' }
      & Pick<CreditorDirectorEntity, 'CreditorDirectorID' | 'CreditorAccountID' | 'Name' | 'ContactNo' | 'Email' | 'IdentityNo' | 'IdentityType'>
    )>> }
  )>, getCreditorContact: Array<(
    { __typename?: 'CreditorContactEntity' }
    & Pick<CreditorContactEntity, 'CreditorContactID' | 'CreditorAccountID' | 'Name' | 'ContactNo' | 'Designation' | 'Email' | 'IsDefault'>
  )>, getCreditorDirector: Array<(
    { __typename?: 'CreditorDirectorEntity' }
    & Pick<CreditorDirectorEntity, 'CreditorDirectorID' | 'CreditorAccountID' | 'Name' | 'ContactNo' | 'Email' | 'IdentityNo' | 'IdentityType'>
  )> }
);

export type CreateCreditorAccountMutationVariables = Exact<{
  input: CreditorAccountInput;
  contactItem?: Maybe<Array<CreditorContactInput> | CreditorContactInput>;
  directorItem?: Maybe<Array<CreditorDirectorInput> | CreditorDirectorInput>;
}>;


export type CreateCreditorAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateCreditorAccount'>
);

export type UpdateCreditorAccountMutationVariables = Exact<{
  input: CreditorAccountInput;
}>;


export type UpdateCreditorAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateCreditorAccount: (
    { __typename?: 'CreditorAccountEntity' }
    & Pick<CreditorAccountEntity, 'CreditorAccountID'>
  ) }
);

export type UpdateCreditorAccountWithItemMutationVariables = Exact<{
  input: CreditorAccountInput;
  contactItem?: Maybe<Array<CreditorContactInput> | CreditorContactInput>;
  directorItem?: Maybe<Array<CreditorDirectorInput> | CreditorDirectorInput>;
}>;


export type UpdateCreditorAccountWithItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateCreditorAccountWithItem'>
);

export type GetCreditorAccountCompanyAssignmentQueryVariables = Exact<{
  CreditorAccountCompanyAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  searchValue?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
}>;


export type GetCreditorAccountCompanyAssignmentQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCreditorAccountCompanyAssignmentCount'>
  & { getCreditorAccountCompanyAssignment: Array<(
    { __typename?: 'CreditorAccountCompanyAssignmentEntity' }
    & Pick<CreditorAccountCompanyAssignmentEntity, 'CreditorAccountCompanyAssignmentID' | 'CreditorAccountID' | 'CompanyID'>
    & { CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'createdTs' | 'modTs' | 'CreditorAccountID' | 'TotalOutstandingAmt' | 'CreditorTypeID' | 'CompanyRegNo' | 'DocNoList' | 'ContactNo' | 'Email' | 'CreditTerm' | 'TaxSchemeID' | 'Region' | 'GSTRegNo' | 'SSTRegNo' | 'Remark' | 'BankName' | 'TaxIdentificationNo' | 'BankAccountNo' | 'CreditorTypeEnum' | 'CompanyName' | 'Address' | 'CreditorStatus'>
      & { CreditorContact?: Maybe<Array<(
        { __typename?: 'CreditorContactEntity' }
        & Pick<CreditorContactEntity, 'CreditorContactID' | 'CreditorAccountID' | 'Name' | 'ContactNo' | 'Designation' | 'Email' | 'IsDefault'>
      )>>, CreditorDirector?: Maybe<Array<(
        { __typename?: 'CreditorDirectorEntity' }
        & Pick<CreditorDirectorEntity, 'CreditorDirectorID' | 'CreditorAccountID' | 'Name' | 'ContactNo' | 'Email' | 'IdentityNo' | 'IdentityType'>
      )>> }
    )> }
  )> }
);

export type AssignCreditorAccountCompanyMutationVariables = Exact<{
  input: CreditorAccountCompanyAssignmentInput;
}>;


export type AssignCreditorAccountCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AssignCreditorAccountComapany'>
);

export type UnassignCreditorAccountCompanyMutationVariables = Exact<{
  input: CreditorAccountCompanyAssignmentInput;
}>;


export type UnassignCreditorAccountCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UnassignCreditorAccountComapany'>
);

export type CreditorAccountTotalDueQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type CreditorAccountTotalDueQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CreditorAccountTotalDue'>
);

export type GetCreditorAccountCompanyAssignmentNameQueryVariables = Exact<{
  CreditorAccountCompanyAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetCreditorAccountCompanyAssignmentNameQuery = (
  { __typename?: 'Query' }
  & { getCreditorAccountCompanyAssignment: Array<(
    { __typename?: 'CreditorAccountCompanyAssignmentEntity' }
    & Pick<CreditorAccountCompanyAssignmentEntity, 'CreditorAccountID' | 'CompanyID'>
    & { CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CreditTerm' | 'CreditorAccountID' | 'CompanyName' | 'DocNoList'>
      & { CreditorContact?: Maybe<Array<(
        { __typename?: 'CreditorContactEntity' }
        & Pick<CreditorContactEntity, 'Name' | 'ContactNo'>
      )>> }
    )> }
  )> }
);

export type GetCreditorTypeQueryVariables = Exact<{
  CreditorTypeID?: Maybe<Scalars['String']>;
  CreditorType?: Maybe<CreditorType>;
}>;


export type GetCreditorTypeQuery = (
  { __typename?: 'Query' }
  & { getCreditorType: Array<(
    { __typename?: 'CreditorTypeEntity' }
    & Pick<CreditorTypeEntity, 'CreditorTypeID' | 'MasterCOAID' | 'Description' | 'CreditorType' | 'AccrualCOAID' | 'Code'>
    & { MasterCOA?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'Name' | 'Code'>
    )> }
  )> }
);

export type GetCreditorTypeWithAccountQueryVariables = Exact<{
  accountID: Scalars['String'];
  CompanyID: Scalars['String'];
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetCreditorTypeWithAccountQuery = (
  { __typename?: 'Query' }
  & { getCreditorTypeWithAccount: Array<(
    { __typename?: 'CreditorTypeEntity' }
    & Pick<CreditorTypeEntity, 'CreditorTypeID' | 'MasterCOAID' | 'Description' | 'CreditorType' | 'Code'>
    & { CreditorAccount?: Maybe<Array<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CreditorTypeID' | 'CompanyRegNo' | 'CompanyName' | 'ContactNo'>
    )>>, MasterCOA?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'Name' | 'Code'>
    )> }
  )> }
);

export type GetMasterCoaWithAccountQueryVariables = Exact<{
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
}>;


export type GetMasterCoaWithAccountQuery = (
  { __typename?: 'Query' }
  & { getMasterCOAWithAccount: Array<(
    { __typename?: 'MasterCOAEntity' }
    & Pick<MasterCoaEntity, 'MasterCOAID' | 'ParentMasterCOAID' | 'Name' | 'Code' | 'IsControl' | 'AccountType' | 'IsLastNode' | 'Level'>
    & { EntityCOA?: Maybe<Array<(
      { __typename?: 'EntityCOAEntity' }
      & Pick<EntityCoaEntity, 'EntityCOAID' | 'CompanyID'>
    )>> }
  )> }
);

export type CreateCreditorTypeWithAccountMutationVariables = Exact<{
  input: CreditorTypeInput;
}>;


export type CreateCreditorTypeWithAccountMutation = (
  { __typename?: 'Mutation' }
  & { createCreditorType: (
    { __typename?: 'CreditorTypeEntity' }
    & Pick<CreditorTypeEntity, 'CreditorTypeID'>
  ) }
);

export type UpdateCreditorTypeWithAccountMutationVariables = Exact<{
  input: CreditorTypeInput;
}>;


export type UpdateCreditorTypeWithAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateCreditorType: (
    { __typename?: 'CreditorTypeEntity' }
    & Pick<CreditorTypeEntity, 'CreditorTypeID'>
  ) }
);

export type DeleteCreditorTypeWithAccountMutationVariables = Exact<{
  input: CreditorTypeDeleteInput;
}>;


export type DeleteCreditorTypeWithAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCreditorType'>
);

export type CreateCreditorTypeMutationVariables = Exact<{
  input: CreditorTypeInput;
}>;


export type CreateCreditorTypeMutation = (
  { __typename?: 'Mutation' }
  & { createCreditorType: (
    { __typename?: 'CreditorTypeEntity' }
    & Pick<CreditorTypeEntity, 'CreditorTypeID'>
  ) }
);

export type UpdateCreditorTypeMutationVariables = Exact<{
  input: CreditorTypeInput;
}>;


export type UpdateCreditorTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateCreditorType: (
    { __typename?: 'CreditorTypeEntity' }
    & Pick<CreditorTypeEntity, 'CreditorTypeID'>
  ) }
);

export type DeleteCreditorTypeMutationVariables = Exact<{
  input: CreditorTypeDeleteInput;
}>;


export type DeleteCreditorTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCreditorType'>
);

export type GetTaxSchemeQueryVariables = Exact<{
  TaxSchemeID?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  AccTaxClass?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetTaxSchemeQuery = (
  { __typename?: 'Query' }
  & { getTaxScheme: Array<(
    { __typename?: 'TaxSchemeEntity' }
    & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'RecordStatus' | 'Code' | 'Description' | 'AccTaxCategory' | 'AccTaxClass' | 'MasterCOAID'>
    & { TaxEffective?: Maybe<Array<(
      { __typename?: 'TaxEffectiveDateEntity' }
      & Pick<TaxEffectiveDateEntity, 'Date' | 'Rate'>
    )>>, TaxType?: Maybe<(
      { __typename?: 'TaxTypeEntity' }
      & Pick<TaxTypeEntity, 'TaxTypeID' | 'Code' | 'Description' | 'RecordStatus'>
      & { TaxScheme?: Maybe<Array<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code' | 'Description'>
      )>> }
    )>, LatestTax?: Maybe<(
      { __typename?: 'AccTax' }
      & Pick<AccTax, 'Rate' | 'Date'>
    )> }
  )> }
);

export type GetTaxSchemeCodeQueryVariables = Exact<{
  TaxSchemeID?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  AccTaxClass?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetTaxSchemeCodeQuery = (
  { __typename?: 'Query' }
  & { getTaxScheme: Array<(
    { __typename?: 'TaxSchemeEntity' }
    & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'RecordStatus' | 'Code' | 'Description' | 'AccTaxCategory' | 'AccTaxClass' | 'MasterCOAID'>
    & { TaxEffective?: Maybe<Array<(
      { __typename?: 'TaxEffectiveDateEntity' }
      & Pick<TaxEffectiveDateEntity, 'Date' | 'Rate'>
    )>>, TaxType?: Maybe<(
      { __typename?: 'TaxTypeEntity' }
      & Pick<TaxTypeEntity, 'TaxTypeID' | 'Code' | 'Description' | 'RecordStatus'>
      & { TaxScheme?: Maybe<Array<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code' | 'Description'>
      )>> }
    )>, LatestTax?: Maybe<(
      { __typename?: 'AccTax' }
      & Pick<AccTax, 'Rate' | 'Date'>
    )> }
  )> }
);

export type GetUomQueryVariables = Exact<{
  UOMID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
}>;


export type GetUomQuery = (
  { __typename?: 'Query' }
  & { getUOM: Array<(
    { __typename?: 'AccUOMEntity' }
    & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code' | 'RecordStatus'>
  )> }
);

export type CreateUomMutationVariables = Exact<{
  input: UomInput;
}>;


export type CreateUomMutation = (
  { __typename?: 'Mutation' }
  & { createUOM: (
    { __typename?: 'AccUOMEntity' }
    & Pick<AccUomEntity, 'UOMID'>
  ) }
);

export type UpdateUomMutationVariables = Exact<{
  input: UomInput;
}>;


export type UpdateUomMutation = (
  { __typename?: 'Mutation' }
  & { updateUOM: (
    { __typename?: 'AccUOMEntity' }
    & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code'>
  ) }
);

export type DeleteUomMutationVariables = Exact<{
  input: UomDeleteInput;
}>;


export type DeleteUomMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUOM'>
);

export type GetUomExchangeQueryVariables = Exact<{
  FromUomID?: Maybe<Scalars['String']>;
  ToUomID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetUomExchangeQuery = (
  { __typename?: 'Query' }
  & { getUOMExchange: Array<(
    { __typename?: 'AccUOMExchangeEntity' }
    & Pick<AccUomExchangeEntity, 'ID' | 'FromUomID' | 'ToUomID' | 'FromAmt' | 'ToAmt' | 'ExchangeRate' | 'UomLog'>
  )> }
);

export type GetUomExchangeDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUomExchangeDataQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUomExchangeData'>
);

export type GetUomExchangeListQueryVariables = Exact<{
  UOMID: Scalars['String'];
}>;


export type GetUomExchangeListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUOMExchangeList'>
);

export type CreateUomExchangeMutationVariables = Exact<{
  input: UomExchangeInput;
}>;


export type CreateUomExchangeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createUOMExchange'>
);

export type UpdateUomExchangeMutationVariables = Exact<{
  input: UomExchangeInput;
}>;


export type UpdateUomExchangeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUOMExchange'>
);

export type DeleteUomExchangeMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteUomExchangeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUOMExchange'>
);

export type DocumentListingQueryVariables = Exact<{
  refID?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}>;


export type DocumentListingQuery = (
  { __typename?: 'Query' }
  & { DocumentListing?: Maybe<Array<(
    { __typename?: 'AttachmentEntity' }
    & Pick<AttachmentEntity, 'AttachmentID' | 'fileName' | 'fileURL' | 'mediaType' | 'createdTs' | 'description' | 'refID'>
  )>> }
);

export type GetShareAttachmentMutationVariables = Exact<{
  file: Scalars['Upload'];
  CompanyID: Scalars['String'];
}>;


export type GetShareAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { getShareAttachment?: Maybe<(
    { __typename?: 'AttachmentEntity' }
    & Pick<AttachmentEntity, 'fileName' | 'fileURL' | 'mediaType' | 'createdTs' | 'description'>
  )> }
);

export type GetFavoritePrHeaderQueryVariables = Exact<{
  UserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  FavoritePRHeaderID?: Maybe<Scalars['String']>;
}>;


export type GetFavoritePrHeaderQuery = (
  { __typename?: 'Query' }
  & { getFavoritePRHeader: Array<(
    { __typename?: 'FavoritePREntity' }
    & Pick<FavoritePrEntity, 'createdBy' | 'FavoritePRHeaderID' | 'Name' | 'RequestedBy' | 'DeliveryLocationID' | 'ContactPerson' | 'Instruction' | 'ContactNo'>
    & { DeliveryLocation?: Maybe<(
      { __typename?: 'DeliveryLocationEntity' }
      & Pick<DeliveryLocationEntity, 'DeliveryLocationID' | 'Name' | 'Address' | 'ContactNo'>
    )>, FavoritePRItem?: Maybe<Array<(
      { __typename?: 'FavoritePRItemEntity' }
      & Pick<FavoritePrItemEntity, 'FavoritePRHeaderID' | 'FavoritePRItemID' | 'ItemID' | 'UOMID' | 'DeliveryLocationID' | 'WarehouseID' | 'CostCentreID' | 'Quantity' | 'OutstandingQty' | 'Remark'>
      & { Item?: Maybe<(
        { __typename?: 'ItemEntity' }
        & Pick<ItemEntity, 'ItemID' | 'Name' | 'Description' | 'UOMID'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code'>
      )>, DeliveryLocation?: Maybe<(
        { __typename?: 'DeliveryLocationEntity' }
        & Pick<DeliveryLocationEntity, 'DeliveryLocationID' | 'Name' | 'Address'>
      )>, Warehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'Name' | 'Code' | 'Address'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
      )> }
    )>> }
  )> }
);

export type CreateFavoritePrMutationVariables = Exact<{
  FavoritePRInput: FavoritePrInput;
  FavoritePRItemInput: Array<FavoritePrItemInput> | FavoritePrItemInput;
}>;


export type CreateFavoritePrMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createFavoritePR'>
);

export type DeleteFavoritePrMutationVariables = Exact<{
  FavoritePRHeaderID: Scalars['String'];
}>;


export type DeleteFavoritePrMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteFavoritePR'>
);

export type GetGeneralGrtNsQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
}>;


export type GetGeneralGrtNsQuery = (
  { __typename?: 'Query' }
  & { getGeneralGRTNs: Array<(
    { __typename?: 'GRTNHeaderEntity' }
    & Pick<GrtnHeaderEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'GRTNHeaderID' | 'AccountID' | 'SupplierID' | 'DeliveryLocationID' | 'CompanyID' | 'WarehouseID' | 'ApprovalStatus' | 'RecordStatus' | 'DocDate' | 'DocNo' | 'RefNo' | 'Description' | 'TransactionDate'>
    & { Supplier?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CompanyName' | 'CompanyRegNo' | 'GSTRegNo' | 'Address' | 'CreditTerm' | 'Email'>
      & { CreditorContact?: Maybe<Array<(
        { __typename?: 'CreditorContactEntity' }
        & Pick<CreditorContactEntity, 'Name' | 'ContactNo' | 'Email'>
      )>> }
    )>, DeliveryLocation?: Maybe<(
      { __typename?: 'DeliveryLocationEntity' }
      & Pick<DeliveryLocationEntity, 'Name' | 'Address' | 'ContactNo' | 'Latitude' | 'Longitude' | 'MainLocation'>
    )>, Warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'Name' | 'Code' | 'Address'>
    )>, GRTNHeaderItem?: Maybe<Array<(
      { __typename?: 'GRTNItemEntity' }
      & Pick<GrtnItemEntity, 'GRTNItemID' | 'DOItemID' | 'OutstandingQty' | 'ReturnedQty' | 'ReturnedAmt' | 'Replacement' | 'GRTNHeaderID'>
      & { DOItem?: Maybe<(
        { __typename?: 'DOItemEntity' }
        & Pick<DoItemEntity, 'DOItemID' | 'DOHeaderID' | 'POItemID' | 'UpToDateQty' | 'AcceptedQty' | 'ReturnedQty'>
        & { POItem?: Maybe<(
          { __typename?: 'POItemEntity' }
          & Pick<PoItemEntity, 'POHeaderID' | 'POItemID' | 'PRItemID' | 'UnitPrice' | 'BaseAmt' | 'TaxAmt' | 'TotalAmt' | 'Remarks' | 'OrderedQty' | 'OutstandingQty' | 'CostCentreID' | 'UOMID'>
          & { POHeader?: Maybe<(
            { __typename?: 'POHeaderEntity' }
            & Pick<PoHeaderEntity, 'DocNo'>
          )>, CostCentre?: Maybe<(
            { __typename?: 'CostCentreEntity' }
            & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
          )>, Item?: Maybe<(
            { __typename?: 'ItemEntity' }
            & Pick<ItemEntity, 'ItemID' | 'Name' | 'VariancePerc'>
            & { UOM?: Maybe<(
              { __typename?: 'AccUOMEntity' }
              & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code'>
            )> }
          )> }
        )>, DOHeader?: Maybe<(
          { __typename?: 'DOHeaderEntity' }
          & Pick<DoHeaderEntity, 'DocNo' | 'DOHeaderID' | 'DoNo' | 'Description'>
        )> }
      )>, ReturnedDoItem?: Maybe<Array<(
        { __typename?: 'DOItemEntity' }
        & Pick<DoItemEntity, 'DOHeaderID' | 'DOItemID' | 'UpToDateQty'>
      )>>, GRTNHeader?: Maybe<(
        { __typename?: 'GRTNHeaderEntity' }
        & Pick<GrtnHeaderEntity, 'DocNo' | 'DocDate' | 'RefNo' | 'Description'>
      )> }
    )>> }
  )> }
);

export type GetGeneralPOsQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  DOHeaderID?: Maybe<Scalars['String']>;
}>;


export type GetGeneralPOsQuery = (
  { __typename?: 'Query' }
  & { getGeneralPOs: Array<(
    { __typename?: 'POHeaderEntity' }
    & Pick<PoHeaderEntity, 'POHeaderID' | 'AccountID' | 'SupplierID' | 'DeliveryLocationID' | 'ContactPerson' | 'CompanyID' | 'DocNo' | 'DocDate' | 'ExpectedDate' | 'RecordStatus' | 'ApprovalStatus' | 'Instruction'>
    & { Supplier?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CompanyName' | 'CompanyRegNo'>
    )>, Company: (
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'Name' | 'CompanyRegNo' | 'CompanyTax' | 'ContactNo' | 'SSTNo' | 'GSTNo'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'ID' | 'address' | 'address2' | 'siteName' | 'country' | 'city' | 'district' | 'state' | 'postCode' | 'postCodeName' | 'AddressType'>
      )> }
    ), DeliveryLocation?: Maybe<(
      { __typename?: 'DeliveryLocationEntity' }
      & Pick<DeliveryLocationEntity, 'Name' | 'Address' | 'ContactNo' | 'Latitude' | 'Longitude' | 'MainLocation'>
    )>, POItem?: Maybe<Array<(
      { __typename?: 'POItemEntity' }
      & Pick<PoItemEntity, 'POItemID' | 'AccountID' | 'WarehouseID' | 'PRItemID' | 'POHeaderID' | 'TotalAmt' | 'TaxAmt' | 'BaseAmt' | 'UOMID' | 'DeliveryLocationID' | 'OrderedQty' | 'ItemID' | 'UnitPrice' | 'OutstandingQty' | 'TaxSchemeID' | 'Remarks'>
      & { CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'Name'>
      )>, PRItem?: Maybe<(
        { __typename?: 'PRItemEntity' }
        & Pick<PrItemEntity, 'PRItemID' | 'CostCentreID' | 'Quantity' | 'DeliveryLocationID' | 'Remark'>
        & { PRHeader?: Maybe<(
          { __typename?: 'PRHeaderEntity' }
          & Pick<PrHeaderEntity, 'PRHeaderID' | 'DocDate' | 'DocNo' | 'Instruction' | 'ContactNo' | 'ContactPerson' | 'DeliveryLocationID'>
        )>, Item?: Maybe<(
          { __typename?: 'ItemEntity' }
          & Pick<ItemEntity, 'Name'>
        )> }
      )>, DOItem?: Maybe<Array<(
        { __typename?: 'DOItemEntity' }
        & Pick<DoItemEntity, 'DOHeaderID' | 'DOItemID' | 'POItemID' | 'UpToDateQty' | 'AcceptedQty'>
      )>>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'UOMID' | 'Code' | 'Name'>
      )>, UOMExchange?: Maybe<Array<(
        { __typename?: 'poUOMExchange' }
        & Pick<PoUomExchange, 'uomid' | 'uomcode' | 'uomname'>
      )>>, Item?: Maybe<(
        { __typename?: 'ItemEntity' }
        & Pick<ItemEntity, 'Name' | 'Description' | 'VariancePerc'>
      )>, TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'Code' | 'Description'>
      )> }
    )>> }
  )> }
);

export type GetGeneralDoQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  SupplierID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  DOHeaderID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetGeneralDoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGeneralDOCount'>
  & { getGeneralDO: Array<(
    { __typename?: 'DOHeaderEntity' }
    & Pick<DoHeaderEntity, 'DOHeaderID' | 'AccountID' | 'SupplierID' | 'CompanyID' | 'DeliveryLocationID' | 'WarehouseID' | 'DocDate' | 'DoNo' | 'DocNo' | 'Description' | 'TransactionDate' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'RecordStatus' | 'ApprovalStatus' | 'RejectionDate' | 'RejectionRemark' | 'PurchaseOrder' | 'UpToDateAmt'>
    & { Supplier?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CompanyName' | 'CompanyRegNo' | 'GSTRegNo' | 'Address' | 'CreditTerm' | 'Email'>
      & { CreditorContact?: Maybe<Array<(
        { __typename?: 'CreditorContactEntity' }
        & Pick<CreditorContactEntity, 'Name' | 'ContactNo' | 'Email'>
      )>> }
    )>, DeliveryLocation?: Maybe<(
      { __typename?: 'DeliveryLocationEntity' }
      & Pick<DeliveryLocationEntity, 'DeliveryLocationID' | 'Name' | 'Address'>
    )>, DOItem?: Maybe<Array<(
      { __typename?: 'DOItemEntity' }
      & Pick<DoItemEntity, 'DOItemID' | 'AcceptedQty' | 'AcceptedAmt' | 'DOHeaderID' | 'POItemID' | 'GRTNItemID' | 'RecordStatus' | 'UpToDateQty' | 'ReturnedQty'>
      & { DOHeader?: Maybe<(
        { __typename?: 'DOHeaderEntity' }
        & Pick<DoHeaderEntity, 'DOHeaderID' | 'Description'>
      )>, POItem?: Maybe<(
        { __typename?: 'POItemEntity' }
        & Pick<PoItemEntity, 'POItemID' | 'OutstandingQty' | 'UnitPrice' | 'OrderedQty' | 'CostCentreID' | 'Remarks' | 'RecordStatus'>
        & { POHeader?: Maybe<(
          { __typename?: 'POHeaderEntity' }
          & Pick<PoHeaderEntity, 'POHeaderID' | 'DocNo' | 'DocDate' | 'ApprovalStatus' | 'RecordStatus'>
        )>, CostCentre?: Maybe<(
          { __typename?: 'CostCentreEntity' }
          & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
        )>, Item?: Maybe<(
          { __typename?: 'ItemEntity' }
          & Pick<ItemEntity, 'ItemID' | 'Description' | 'Name' | 'IsInventory'>
        )>, DeliveryLocation?: Maybe<(
          { __typename?: 'DeliveryLocationEntity' }
          & Pick<DeliveryLocationEntity, 'Name' | 'Address' | 'MainLocation'>
        )>, Warehouse?: Maybe<(
          { __typename?: 'WarehouseEntity' }
          & Pick<WarehouseEntity, 'WarehouseID' | 'Name'>
        )>, UOM?: Maybe<(
          { __typename?: 'AccUOMEntity' }
          & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code'>
        )> }
      )>, GRTNItem?: Maybe<Array<(
        { __typename?: 'GRTNItemEntity' }
        & Pick<GrtnItemEntity, 'GRTNHeaderID' | 'GRTNItemID' | 'ReturnedQty' | 'ReturnedAmt' | 'Replacement' | 'OutstandingQty'>
      )>>, ReplacedGRTNItem?: Maybe<(
        { __typename?: 'GRTNItemEntity' }
        & Pick<GrtnItemEntity, 'GRTNItemID' | 'GRTNHeaderID' | 'DOItemID' | 'OutstandingQty' | 'ReturnedQty' | 'ReturnedAmt' | 'Replacement'>
        & { DOItem?: Maybe<(
          { __typename?: 'DOItemEntity' }
          & Pick<DoItemEntity, 'DOItemID' | 'DOHeaderID' | 'POItemID' | 'UpToDateQty' | 'AcceptedQty' | 'ReturnedQty'>
          & { POItem?: Maybe<(
            { __typename?: 'POItemEntity' }
            & Pick<PoItemEntity, 'PRItemID' | 'ItemID' | 'UnitPrice' | 'BaseAmt' | 'TaxAmt' | 'TotalAmt' | 'Remarks' | 'OrderedQty' | 'OutstandingQty' | 'CostCentreID'>
            & { POHeader?: Maybe<(
              { __typename?: 'POHeaderEntity' }
              & Pick<PoHeaderEntity, 'POHeaderID' | 'DocNo' | 'DocDate' | 'ApprovalStatus' | 'RecordStatus'>
            )>, CostCentre?: Maybe<(
              { __typename?: 'CostCentreEntity' }
              & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
            )>, Item?: Maybe<(
              { __typename?: 'ItemEntity' }
              & Pick<ItemEntity, 'Name' | 'IsInventory'>
              & { UOM?: Maybe<(
                { __typename?: 'AccUOMEntity' }
                & Pick<AccUomEntity, 'Name' | 'Code'>
              )> }
            )>, DeliveryLocation?: Maybe<(
              { __typename?: 'DeliveryLocationEntity' }
              & Pick<DeliveryLocationEntity, 'Name' | 'Address' | 'MainLocation'>
            )>, Warehouse?: Maybe<(
              { __typename?: 'WarehouseEntity' }
              & Pick<WarehouseEntity, 'WarehouseID' | 'Name'>
            )>, UOM?: Maybe<(
              { __typename?: 'AccUOMEntity' }
              & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code'>
            )> }
          )>, DOHeader?: Maybe<(
            { __typename?: 'DOHeaderEntity' }
            & Pick<DoHeaderEntity, 'DocNo' | 'DOHeaderID' | 'DoNo' | 'Description'>
          )> }
        )>, GRTNHeader?: Maybe<(
          { __typename?: 'GRTNHeaderEntity' }
          & Pick<GrtnHeaderEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'GRTNHeaderID' | 'AccountID' | 'SupplierID' | 'CompanyID' | 'DeliveryLocationID' | 'WarehouseID' | 'DocDate' | 'TransactionDate' | 'DocNo' | 'RefNo' | 'Description' | 'RecordStatus' | 'ApprovalStatus'>
          & { DeliveryLocation?: Maybe<(
            { __typename?: 'DeliveryLocationEntity' }
            & Pick<DeliveryLocationEntity, 'Name' | 'Address'>
          )>, Warehouse?: Maybe<(
            { __typename?: 'WarehouseEntity' }
            & Pick<WarehouseEntity, 'WarehouseID' | 'Name' | 'Code' | 'Address'>
          )>, Supplier?: Maybe<(
            { __typename?: 'CreditorAccountEntity' }
            & Pick<CreditorAccountEntity, 'CompanyName'>
          )>, GRTNHeaderItem?: Maybe<Array<(
            { __typename?: 'GRTNItemEntity' }
            & Pick<GrtnItemEntity, 'GRTNItemID' | 'DOItemID' | 'OutstandingQty' | 'ReturnedQty' | 'ReturnedAmt' | 'Replacement'>
            & { DOItem?: Maybe<(
              { __typename?: 'DOItemEntity' }
              & Pick<DoItemEntity, 'DOItemID' | 'DOHeaderID' | 'POItemID' | 'UpToDateQty' | 'AcceptedQty' | 'ReturnedQty'>
              & { POItem?: Maybe<(
                { __typename?: 'POItemEntity' }
                & Pick<PoItemEntity, 'PRItemID' | 'UnitPrice' | 'BaseAmt' | 'TaxAmt' | 'TotalAmt' | 'Remarks' | 'OrderedQty' | 'OutstandingQty'>
                & { Item?: Maybe<(
                  { __typename?: 'ItemEntity' }
                  & Pick<ItemEntity, 'Name'>
                  & { UOM?: Maybe<(
                    { __typename?: 'AccUOMEntity' }
                    & Pick<AccUomEntity, 'Name' | 'Code'>
                  )> }
                )> }
              )>, DOHeader?: Maybe<(
                { __typename?: 'DOHeaderEntity' }
                & Pick<DoHeaderEntity, 'DocNo' | 'DOHeaderID' | 'DoNo' | 'Description'>
              )> }
            )>, GRTNHeader?: Maybe<(
              { __typename?: 'GRTNHeaderEntity' }
              & Pick<GrtnHeaderEntity, 'GRTNHeaderID' | 'DocNo' | 'DocDate' | 'RefNo' | 'Description'>
            )> }
          )>> }
        )> }
      )> }
    )>> }
  )> }
);

export type SubmitGeneralDoMutationVariables = Exact<{
  DOHeaderID?: Maybe<Scalars['String']>;
  doInput: DoInput;
  doItemInput: Array<DoItemInput> | DoItemInput;
}>;


export type SubmitGeneralDoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitGeneralDO'>
);

export type GetSupplierByDoStatusQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  ApprovalStatus: ApprovalStatus;
}>;


export type GetSupplierByDoStatusQuery = (
  { __typename?: 'Query' }
  & { getSupplierByDOStatus: Array<(
    { __typename?: 'CreditorAccountEntity' }
    & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'AccountID' | 'CompanyRegNo' | 'CreditorTypeID' | 'TaxSchemeID' | 'ContactNo' | 'Email' | 'CreditTerm' | 'GSTRegNo' | 'Remark' | 'BankName' | 'BankAccountNo' | 'Address' | 'CompanyName' | 'CreditorStatus'>
  )> }
);

export type GetWarehouseDeliveryLocationQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type GetWarehouseDeliveryLocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWarehouseDeliveryLocation'>
);

export type DeleteGeneralDoMutationVariables = Exact<{
  DOHeaderID: Scalars['String'];
}>;


export type DeleteGeneralDoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteGeneralDO'>
);

export type ActionOnGdoStatusMutationVariables = Exact<{
  input: GdoActionInput;
  DOHeaderID: Array<Scalars['String']> | Scalars['String'];
}>;


export type ActionOnGdoStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnGDOStatus'>
);

export type GetGrtnHeaderQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  GRTNHeaderID?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetGrtnHeaderQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGRTNHeaderCount'>
  & { getGRTNHeader: Array<(
    { __typename?: 'GRTNHeaderEntity' }
    & Pick<GrtnHeaderEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'GRTNHeaderID' | 'AccountID' | 'SupplierID' | 'CompanyID' | 'DeliveryLocationID' | 'WarehouseID' | 'DocDate' | 'TransactionDate' | 'DocNo' | 'RefNo' | 'Description' | 'RecordStatus' | 'ApprovalStatus'>
    & { DeliveryLocation?: Maybe<(
      { __typename?: 'DeliveryLocationEntity' }
      & Pick<DeliveryLocationEntity, 'Name' | 'Address'>
    )>, Warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'WarehouseID' | 'Name' | 'Code' | 'Address'>
    )>, Supplier?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CompanyName' | 'CompanyRegNo' | 'GSTRegNo' | 'Address' | 'CreditTerm' | 'Email'>
      & { CreditorContact?: Maybe<Array<(
        { __typename?: 'CreditorContactEntity' }
        & Pick<CreditorContactEntity, 'Name' | 'ContactNo' | 'Email'>
      )>> }
    )>, GRTNHeaderItem?: Maybe<Array<(
      { __typename?: 'GRTNItemEntity' }
      & Pick<GrtnItemEntity, 'GRTNItemID' | 'DOItemID' | 'OutstandingQty' | 'ReturnedQty' | 'ReturnedAmt' | 'Replacement'>
      & { DOItem?: Maybe<(
        { __typename?: 'DOItemEntity' }
        & Pick<DoItemEntity, 'DOItemID' | 'DOHeaderID' | 'POItemID' | 'UpToDateQty' | 'AcceptedQty' | 'ReturnedQty'>
        & { POItem?: Maybe<(
          { __typename?: 'POItemEntity' }
          & Pick<PoItemEntity, 'PRItemID' | 'UnitPrice' | 'BaseAmt' | 'TaxAmt' | 'TotalAmt' | 'Remarks' | 'OrderedQty' | 'OutstandingQty' | 'CostCentreID'>
          & { POHeader?: Maybe<(
            { __typename?: 'POHeaderEntity' }
            & Pick<PoHeaderEntity, 'DocNo' | 'Remarks'>
          )>, CostCentre?: Maybe<(
            { __typename?: 'CostCentreEntity' }
            & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
          )>, Item?: Maybe<(
            { __typename?: 'ItemEntity' }
            & Pick<ItemEntity, 'Name'>
            & { UOM?: Maybe<(
              { __typename?: 'AccUOMEntity' }
              & Pick<AccUomEntity, 'Name' | 'Code'>
            )> }
          )> }
        )>, DOHeader?: Maybe<(
          { __typename?: 'DOHeaderEntity' }
          & Pick<DoHeaderEntity, 'DocNo' | 'DOHeaderID' | 'DoNo' | 'Description'>
        )> }
      )>, GRTNHeader?: Maybe<(
        { __typename?: 'GRTNHeaderEntity' }
        & Pick<GrtnHeaderEntity, 'DocNo' | 'DocDate' | 'RefNo' | 'Description'>
      )> }
    )>> }
  )> }
);

export type GetGeneralDOforGrtnQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  SupplierID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  DOHeaderID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetGeneralDOforGrtnQuery = (
  { __typename?: 'Query' }
  & { getGeneralDO: Array<(
    { __typename?: 'DOHeaderEntity' }
    & Pick<DoHeaderEntity, 'DOHeaderID' | 'DocDate' | 'DocNo' | 'DoNo'>
    & { DOItem?: Maybe<Array<(
      { __typename?: 'DOItemEntity' }
      & Pick<DoItemEntity, 'DOItemID' | 'DOHeaderID' | 'AcceptedQty' | 'AcceptedAmt' | 'UpToDateQty'>
      & { POItem?: Maybe<(
        { __typename?: 'POItemEntity' }
        & Pick<PoItemEntity, 'UnitPrice' | 'Remarks'>
        & { CostCentre?: Maybe<(
          { __typename?: 'CostCentreEntity' }
          & Pick<CostCentreEntity, 'Name'>
        )>, Item?: Maybe<(
          { __typename?: 'ItemEntity' }
          & Pick<ItemEntity, 'Name'>
        )>, UOM?: Maybe<(
          { __typename?: 'AccUOMEntity' }
          & Pick<AccUomEntity, 'Code'>
        )>, POHeader?: Maybe<(
          { __typename?: 'POHeaderEntity' }
          & Pick<PoHeaderEntity, 'DocNo'>
        )> }
      )>, ReplacedGRTNItem?: Maybe<(
        { __typename?: 'GRTNItemEntity' }
        & Pick<GrtnItemEntity, 'OutstandingQty' | 'ReturnedQty' | 'ReturnedAmt' | 'Replacement'>
        & { DOItem?: Maybe<(
          { __typename?: 'DOItemEntity' }
          & Pick<DoItemEntity, 'UpToDateQty' | 'AcceptedQty' | 'ReturnedQty'>
          & { POItem?: Maybe<(
            { __typename?: 'POItemEntity' }
            & Pick<PoItemEntity, 'UnitPrice' | 'Remarks'>
            & { CostCentre?: Maybe<(
              { __typename?: 'CostCentreEntity' }
              & Pick<CostCentreEntity, 'Name'>
            )>, Item?: Maybe<(
              { __typename?: 'ItemEntity' }
              & Pick<ItemEntity, 'Name'>
            )>, UOM?: Maybe<(
              { __typename?: 'AccUOMEntity' }
              & Pick<AccUomEntity, 'Code'>
            )> }
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type CreateUpdateGrtnMutationVariables = Exact<{
  GRTNHeaderID?: Maybe<Scalars['String']>;
  grtnInput: GeneralGrtnInput;
  grtnItemInput: Array<GeneralGrtnItemInput> | GeneralGrtnItemInput;
}>;


export type CreateUpdateGrtnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createUpdateGRTN'>
);

export type CreateGrtnMutationVariables = Exact<{
  grtnInput: GeneralGrtnInput;
  grtnItemInput: Array<GeneralGrtnItemInput> | GeneralGrtnItemInput;
}>;


export type CreateGrtnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateGRTN'>
);

export type UpdateGrtnMutationVariables = Exact<{
  GRTNHeaderID?: Maybe<Scalars['String']>;
  grtnInput: GeneralGrtnInput;
  grtnItemInput: Array<GeneralGrtnItemInput> | GeneralGrtnItemInput;
}>;


export type UpdateGrtnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateGRTN'>
);

export type GetWarehouseDeliveryQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type GetWarehouseDeliveryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWarehouseDeliveryLocation'>
);

export type DeleteGrtnMutationVariables = Exact<{
  GRTNHeaderID: Scalars['String'];
}>;


export type DeleteGrtnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteGRTN'>
);

export type ActionOnGrtnStatusMutationVariables = Exact<{
  GRTNHeaderID: Array<Scalars['String']> | Scalars['String'];
  ApprovalStatus: ApprovalStatus;
  input: GeneralGrtnRejectInput;
  CompanyID: Scalars['String'];
}>;


export type ActionOnGrtnStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnGRTNStatus'>
);

export type GetPoHeaderQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  POHeaderID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  POType?: Maybe<PoType>;
}>;


export type GetPoHeaderQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPOHeaderCount'>
  & { getPOHeader: Array<(
    { __typename?: 'POHeaderEntity' }
    & Pick<PoHeaderEntity, 'POHeaderID' | 'AccountID' | 'modTs' | 'modBy' | 'createdTs' | 'createdBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'SupplierID' | 'CreditTerm' | 'POType' | 'ContactPerson' | 'DeliveryLocationID' | 'ApprovalWorkflowPO' | 'CompanyID' | 'DocNo' | 'TotalAmt' | 'DocDate' | 'ExpectedDate' | 'RecordStatus' | 'ApprovalStatus' | 'Instruction'>
    & { Supplier?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CompanyName' | 'CompanyRegNo' | 'GSTRegNo' | 'Address' | 'CreditTerm' | 'Email'>
      & { CreditorContact?: Maybe<Array<(
        { __typename?: 'CreditorContactEntity' }
        & Pick<CreditorContactEntity, 'Name' | 'ContactNo' | 'Email'>
      )>> }
    )>, Company: (
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'Name' | 'CompanyRegNo' | 'ContactNo' | 'CompanyTax' | 'SSTNo' | 'GSTNo'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'ID' | 'address' | 'address2' | 'siteName' | 'country' | 'city' | 'district' | 'state' | 'postCode' | 'postCodeName' | 'AddressType'>
      )> }
    ), DeliveryLocation?: Maybe<(
      { __typename?: 'DeliveryLocationEntity' }
      & Pick<DeliveryLocationEntity, 'Name' | 'Address' | 'ContactNo' | 'Latitude' | 'Longitude' | 'MainLocation'>
    )>, POItem?: Maybe<Array<(
      { __typename?: 'POItemEntity' }
      & Pick<PoItemEntity, 'POItemID' | 'AccountID' | 'PRItemID' | 'POHeaderID' | 'TotalAmt' | 'TaxAmt' | 'BaseAmt' | 'UOMID' | 'CostCentreID' | 'DeliveryLocationID' | 'WarehouseID' | 'OrderedQty' | 'ItemID' | 'UnitPrice' | 'OutstandingQty' | 'TaxSchemeID' | 'Remarks' | 'ScheduledPurchasesItemID' | 'ScheduledPurchaseDetail'>
      & { CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'Name' | 'Level' | 'Code'>
      )>, DeliveryLocation?: Maybe<(
        { __typename?: 'DeliveryLocationEntity' }
        & Pick<DeliveryLocationEntity, 'Name' | 'Address' | 'ContactNo' | 'Latitude' | 'Longitude' | 'MainLocation'>
      )>, Warehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'Name' | 'Code' | 'Address'>
      )>, PRItem?: Maybe<(
        { __typename?: 'PRItemEntity' }
        & Pick<PrItemEntity, 'PRItemID' | 'CostCentreID' | 'Quantity' | 'OutstandingQty' | 'DeliveryLocationID' | 'Remark'>
        & { PRHeader?: Maybe<(
          { __typename?: 'PRHeaderEntity' }
          & Pick<PrHeaderEntity, 'PRHeaderID' | 'DocDate' | 'DocNo' | 'Instruction' | 'ContactNo' | 'ContactPerson' | 'DeliveryLocationID'>
        )>, Item?: Maybe<(
          { __typename?: 'ItemEntity' }
          & Pick<ItemEntity, 'Name' | 'IsInventory' | 'IsCentralised'>
        )> }
      )>, DOItem?: Maybe<Array<(
        { __typename?: 'DOItemEntity' }
        & Pick<DoItemEntity, 'DOHeaderID' | 'AcceptedQty'>
      )>>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'UOMID' | 'Code' | 'Name'>
      )>, UOMExchange?: Maybe<Array<(
        { __typename?: 'poUOMExchange' }
        & Pick<PoUomExchange, 'uomid' | 'uomcode' | 'uomname'>
      )>>, Item?: Maybe<(
        { __typename?: 'ItemEntity' }
        & Pick<ItemEntity, 'Name' | 'Description' | 'IsInventory'>
      )>, TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'AccountID' | 'TaxSchemeID' | 'Code' | 'Description'>
      )> }
    )>> }
  )> }
);

export type GetSiteWithAccountQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetSiteWithAccountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSiteWithAccount'>
);

export type GetOsGeneralPrQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetOsGeneralPrQuery = (
  { __typename?: 'Query' }
  & { getOSGeneralPR: Array<(
    { __typename?: 'PRHeaderEntity' }
    & Pick<PrHeaderEntity, 'PRHeaderID' | 'AccountID' | 'CompanyID' | 'DocNo' | 'DocDate' | 'ExpectedDate' | 'RequestedBy' | 'DeliveryLocationID' | 'ContactPerson' | 'ContactNo' | 'Instruction'>
    & { PRItem?: Maybe<Array<(
      { __typename?: 'PRItemEntity' }
      & Pick<PrItemEntity, 'PRItemID' | 'CostCentreID' | 'Quantity' | 'ItemID'>
      & { CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'CostCentreID' | 'CompanyID' | 'Name'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Code' | 'Name'>
      )>, Item?: Maybe<(
        { __typename?: 'ItemEntity' }
        & Pick<ItemEntity, 'Name'>
      )>, POItem?: Maybe<Array<(
        { __typename?: 'POItemEntity' }
        & Pick<PoItemEntity, 'UOMID' | 'TaxSchemeID' | 'OutstandingQty' | 'UnitPrice'>
      )>> }
    )>> }
  )> }
);

export type GetTaxSchemeWithAccountQueryVariables = Exact<{
  accountID: Scalars['String'];
}>;


export type GetTaxSchemeWithAccountQuery = (
  { __typename?: 'Query' }
  & { getTaxSchemeWithAccount: Array<(
    { __typename?: 'TaxSchemeEntity' }
    & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code' | 'Description' | 'TaxTypeID' | 'AccTaxCategory' | 'AccTaxClass' | 'MasterCOAID'>
    & { TaxType?: Maybe<(
      { __typename?: 'TaxTypeEntity' }
      & Pick<TaxTypeEntity, 'Code' | 'Description'>
    )>, LatestTax?: Maybe<(
      { __typename?: 'AccTax' }
      & Pick<AccTax, 'Rate' | 'Date'>
    )> }
  )> }
);

export type CreateDirectPoMutationVariables = Exact<{
  input: DirectPoInput;
}>;


export type CreateDirectPoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createDirectPO'>
);

export type CombinePoMutationVariables = Exact<{
  input: Array<DirectPoInput> | DirectPoInput;
}>;


export type CombinePoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'combinePO'>
);

export type UpdateDirectPoMutationVariables = Exact<{
  POHeaderID?: Maybe<Scalars['String']>;
  input: DirectPoInput;
}>;


export type UpdateDirectPoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDirectPO'>
);

export type DeletePoMutationVariables = Exact<{
  POHeaderID: Scalars['String'];
}>;


export type DeletePoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePO'>
);

export type ActionOnGpoStatusMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  ApprovalStatus: ApprovalStatus;
  input: PoRejectInput;
  POHeaderID: Array<Scalars['String']> | Scalars['String'];
}>;


export type ActionOnGpoStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnGPOStatus'>
);

export type GetWarehouseQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetWarehouseQuery = (
  { __typename?: 'Query' }
  & { getWarehouse: Array<(
    { __typename?: 'WarehouseEntity' }
    & Pick<WarehouseEntity, 'WarehouseID' | 'Name' | 'Code' | 'Address' | 'CostCentreID'>
  )> }
);

export type GetCreditorSupplierTypeQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  CreditorTypeEnum?: Maybe<CreditorType>;
}>;


export type GetCreditorSupplierTypeQuery = (
  { __typename?: 'Query' }
  & { getCreditorAccount: Array<(
    { __typename?: 'CreditorAccountEntity' }
    & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CreditorTypeID' | 'CompanyName' | 'ContactNo' | 'TaxSchemeID' | 'CreditorTypeEnum' | 'CreditorStatus'>
  )> }
);

export type CreateGeneralPoMutationVariables = Exact<{
  generalPOInput: Array<PoInput> | PoInput;
}>;


export type CreateGeneralPoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createGeneralPO'>
);

export type GetSupplierAccountByCompanyQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  CreditorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetSupplierAccountByCompanyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSupplierAccountByCompany'>
);

export type CheckingCancelClosePoQueryVariables = Exact<{
  POHeaderID: Scalars['String'];
}>;


export type CheckingCancelClosePoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkingCancelClosePO'>
);

export type CancelPoMutationVariables = Exact<{
  input: PurchaseActionInput;
  POHeaderID: Scalars['String'];
}>;


export type CancelPoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelClosePO'>
);

export type GetCentralisedPrItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCentralisedPrItemsQuery = (
  { __typename?: 'Query' }
  & { getCentralisedPRItems: Array<(
    { __typename?: 'PRItemEntity' }
    & Pick<PrItemEntity, 'PRItemID' | 'WarehouseID' | 'PRHeaderID' | 'Quantity' | 'ItemID' | 'UOMID' | 'CostCentreID' | 'Remark' | 'IsPOGenerated' | 'rfqSubmissionSelected' | 'bulkPurchaseSelected' | 'masterFileSelected'>
    & { Warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'Name'>
    )>, PRHeader?: Maybe<(
      { __typename?: 'PRHeaderEntity' }
      & Pick<PrHeaderEntity, 'CompanyID' | 'ExpectedDate' | 'DocNo' | 'DocDate' | 'RequestedBy' | 'ContactPerson' | 'ContactNo' | 'Instruction' | 'DeliveryLocationID'>
      & { DeliveryLocation?: Maybe<(
        { __typename?: 'DeliveryLocationEntity' }
        & Pick<DeliveryLocationEntity, 'CompanyID' | 'Name' | 'Address' | 'ContactNo' | 'RecordStatus'>
      )> }
    )>, Item?: Maybe<(
      { __typename?: 'ItemEntity' }
      & Pick<ItemEntity, 'RecordStatus' | 'Name' | 'Description' | 'UOMID' | 'TaxSchemeID'>
      & { UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name' | 'Code' | 'RecordStatus'>
      )>, TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'Code' | 'Description'>
      )> }
    )>, UOM?: Maybe<(
      { __typename?: 'AccUOMEntity' }
      & Pick<AccUomEntity, 'Name' | 'Code' | 'RecordStatus'>
    )> }
  )> }
);

export type GetPrHeaderQueryVariables = Exact<{
  PRHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetPrHeaderQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPRHeaderCount'>
  & { getPRHeader: Array<(
    { __typename?: 'PRHeaderEntity' }
    & Pick<PrHeaderEntity, 'modTs' | 'modBy' | 'createdTs' | 'createdBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'PRHeaderID' | 'AccountID' | 'DocDate' | 'ExpectedDate' | 'RequestedBy' | 'DeliveryLocationID' | 'ContactPerson' | 'Instruction' | 'ContactNo' | 'DocNo' | 'RecordStatus' | 'ApprovalStatus' | 'CancelCloseRemark' | 'CompanyID' | 'CheckboxPRDisabled' | 'ApprovalWorkflowPR'>
    & { DeliveryLocation?: Maybe<(
      { __typename?: 'DeliveryLocationEntity' }
      & Pick<DeliveryLocationEntity, 'DeliveryLocationID' | 'Name' | 'Address' | 'ContactNo'>
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'Name' | 'CompanyTax' | 'ContactNo' | 'CompanyRegNo' | 'GSTNo' | 'SSTNo'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'ID' | 'address' | 'address2' | 'siteName' | 'country' | 'city' | 'district' | 'state' | 'postCode' | 'postCodeName' | 'AddressType'>
      )> }
    )>, PRItem?: Maybe<Array<(
      { __typename?: 'PRItemEntity' }
      & Pick<PrItemEntity, 'PRItemID' | 'PRHeaderID' | 'ItemID' | 'UOMID' | 'DeliveryLocationID' | 'WarehouseID' | 'CostCentreID' | 'Quantity' | 'OutstandingQty' | 'Remark' | 'UnitCost' | 'TotalUnitCost'>
      & { Item?: Maybe<(
        { __typename?: 'ItemEntity' }
        & Pick<ItemEntity, 'ItemID' | 'Name' | 'Description' | 'UOMID'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code'>
      )>, DeliveryLocation?: Maybe<(
        { __typename?: 'DeliveryLocationEntity' }
        & Pick<DeliveryLocationEntity, 'DeliveryLocationID' | 'Name' | 'Address'>
      )>, Warehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'Name' | 'Code' | 'Address'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
      )> }
    )>> }
  )> }
);

export type GetArAgingQueryVariables = Exact<{
  AccountType?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  AgingID?: Maybe<Scalars['String']>;
}>;


export type GetArAgingQuery = (
  { __typename?: 'Query' }
  & { getARAging: Array<(
    { __typename?: 'ARAgingEntity' }
    & Pick<ArAgingEntity, 'AccountID' | 'AgingID' | 'CompanyID' | 'Period1' | 'Period2' | 'Period3' | 'Period4' | 'AccountType'>
  )> }
);

export type GetUserQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'department' | 'email' | 'jobTitle' | 'contactNo' | 'superUser' | 'commonStatus' | 'userName'>
  ) }
);

export type CreateGeneralPrMutationVariables = Exact<{
  generalPRInput: PrInput;
  generalPRItemInput: Array<PrItemInput> | PrItemInput;
}>;


export type CreateGeneralPrMutation = (
  { __typename?: 'Mutation' }
  & { createGeneralPR: (
    { __typename?: 'PRHeaderEntity' }
    & Pick<PrHeaderEntity, 'PRHeaderID' | 'DocNo'>
  ) }
);

export type UpdateGeneralPrMutationVariables = Exact<{
  generalPRInput: PrInput;
  generalPRItemInput: Array<PrItemInput> | PrItemInput;
  PRHeaderID: Scalars['String'];
}>;


export type UpdateGeneralPrMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateGeneralPR'>
);

export type GetGeneralPItemQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGeneralPItemQuery = (
  { __typename?: 'Query' }
  & { getItem: Array<(
    { __typename?: 'ItemEntity' }
    & Pick<ItemEntity, 'ItemID' | 'Name' | 'UOMID'>
  )> }
);

export type GetOutstandingGeneralPrItemsQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetOutstandingGeneralPrItemsQuery = (
  { __typename?: 'Query' }
  & { getOutstandingGeneralPRItems: Array<(
    { __typename?: 'PRItemEntity' }
    & Pick<PrItemEntity, 'PRItemID' | 'WarehouseID' | 'PRHeaderID' | 'Quantity' | 'ItemID' | 'UnitCost' | 'TotalUnitCost' | 'UOMID' | 'CostCentreID' | 'Remark' | 'IsPOGenerated' | 'rfqSubmissionSelected' | 'bulkPurchaseSelected' | 'masterFileSelected'>
    & { Warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'CompanyID' | 'Name' | 'Address' | 'ContactNo' | 'RecordStatus'>
    )>, PRHeader?: Maybe<(
      { __typename?: 'PRHeaderEntity' }
      & Pick<PrHeaderEntity, 'CompanyID' | 'ExpectedDate' | 'DocNo' | 'DocDate' | 'RequestedBy' | 'ContactPerson' | 'ContactNo' | 'Instruction' | 'DeliveryLocationID'>
      & { DeliveryLocation?: Maybe<(
        { __typename?: 'DeliveryLocationEntity' }
        & Pick<DeliveryLocationEntity, 'CompanyID' | 'Name' | 'Address' | 'ContactNo' | 'RecordStatus'>
      )> }
    )>, Item?: Maybe<(
      { __typename?: 'ItemEntity' }
      & Pick<ItemEntity, 'RecordStatus' | 'Name' | 'Description' | 'UOMID' | 'TaxSchemeID' | 'LatestPurchasePrice'>
      & { UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name' | 'Code' | 'RecordStatus'>
      )>, TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'Code' | 'Description'>
      )> }
    )>, UOM?: Maybe<(
      { __typename?: 'AccUOMEntity' }
      & Pick<AccUomEntity, 'Name' | 'Code' | 'RecordStatus'>
    )> }
  )> }
);

export type GetOutstandingGeneralPrItemsListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  searchValue?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
}>;


export type GetOutstandingGeneralPrItemsListingQuery = (
  { __typename?: 'Query' }
  & { getOutstandingGeneralPRItemsListing: (
    { __typename?: 'OSPRItemReturnData' }
    & Pick<OsprItemReturnData, 'TotalLength'>
    & { Data?: Maybe<Array<(
      { __typename?: 'PRItemEntity' }
      & Pick<PrItemEntity, 'PRItemID' | 'WarehouseID' | 'PRHeaderID' | 'Quantity' | 'ItemID' | 'UnitCost' | 'TotalUnitCost' | 'UOMID' | 'CostCentreID' | 'Remark' | 'IsPOGenerated' | 'rfqSubmissionSelected' | 'bulkPurchaseSelected' | 'masterFileSelected'>
      & { Warehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'CompanyID' | 'Name' | 'Address' | 'ContactNo' | 'RecordStatus'>
      )>, PRHeader?: Maybe<(
        { __typename?: 'PRHeaderEntity' }
        & Pick<PrHeaderEntity, 'CompanyID' | 'ExpectedDate' | 'DocNo' | 'DocDate' | 'RequestedBy' | 'ContactPerson' | 'ContactNo' | 'Instruction' | 'DeliveryLocationID'>
        & { DeliveryLocation?: Maybe<(
          { __typename?: 'DeliveryLocationEntity' }
          & Pick<DeliveryLocationEntity, 'CompanyID' | 'Name' | 'Address' | 'ContactNo' | 'RecordStatus'>
        )> }
      )>, Item?: Maybe<(
        { __typename?: 'ItemEntity' }
        & Pick<ItemEntity, 'RecordStatus' | 'Name' | 'Description' | 'UOMID' | 'TaxSchemeID' | 'LatestPurchasePrice'>
        & { UOM?: Maybe<(
          { __typename?: 'AccUOMEntity' }
          & Pick<AccUomEntity, 'Name' | 'Code' | 'RecordStatus'>
        )>, TaxScheme?: Maybe<(
          { __typename?: 'TaxSchemeEntity' }
          & Pick<TaxSchemeEntity, 'Code' | 'Description'>
        )> }
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name' | 'Code' | 'RecordStatus'>
      )> }
    )>> }
  ) }
);

export type ActionOnGprStatusMutationVariables = Exact<{
  input: GprActionInput;
  GeneralPRIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type ActionOnGprStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnGPRStatus'>
);

export type DeleteGeneralPrMutationVariables = Exact<{
  PRHeaderID: Scalars['String'];
}>;


export type DeleteGeneralPrMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteGeneralPR'>
);

export type CancelClosePrMutationVariables = Exact<{
  PRHeaderID: Scalars['String'];
  input: GprActionInput;
}>;


export type CancelClosePrMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelClosePR'>
);

export type CheckingCancelClosePrQueryVariables = Exact<{
  PRHeaderID: Scalars['String'];
}>;


export type CheckingCancelClosePrQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkingCancelClosePR'>
);

export type GetRfqHeaderQueryVariables = Exact<{
  RFQHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetRfqHeaderQuery = (
  { __typename?: 'Query' }
  & { getRFQHeader: Array<(
    { __typename?: 'RFQHeaderEntity' }
    & Pick<RfqHeaderEntity, 'modTs' | 'modBy' | 'createdTs' | 'createdBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'RFQHeaderID' | 'AccountID' | 'DocNo' | 'DocDate' | 'Description' | 'ExpectedDate' | 'CancelCloseDate' | 'CancelCloseRemark' | 'RecordStatus' | 'ApprovalStatus' | 'ProfileInfo' | 'PRHeaderInfo'>
    & { RFQSupplierInvitation?: Maybe<Array<(
      { __typename?: 'RFQSupplierInvitationEntity' }
      & Pick<RfqSupplierInvitationEntity, 'RFQSupplierInvitationID' | 'SupplierID' | 'RecordStatus'>
      & { Supplier?: Maybe<(
        { __typename?: 'CreditorAccountEntity' }
        & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CompanyName' | 'CreditTerm' | 'ContactNo' | 'Email'>
      )>, RFQSupplierSubmission?: Maybe<Array<(
        { __typename?: 'RFQSupplierSubmissionEntity' }
        & Pick<RfqSupplierSubmissionEntity, 'RFQSupplierSubmissionID' | 'RefNo' | 'CreditTerm' | 'SubmittedDate' | 'EffectiveDate' | 'ExpiryDate' | 'Remarks'>
      )>>, LatestSubmittedQuotation?: Maybe<(
        { __typename?: 'RFQSupplierSubmissionEntity' }
        & Pick<RfqSupplierSubmissionEntity, 'RefNo' | 'CreditTerm' | 'SubmittedDate' | 'EffectiveDate' | 'ExpiryDate' | 'Remarks'>
        & { RFQSubmissionItem?: Maybe<Array<(
          { __typename?: 'RFQSubmissionItemEntity' }
          & Pick<RfqSubmissionItemEntity, 'RFQSubmissionItemID' | 'RFQSupplierSubmissionID' | 'RFQItemID' | 'UnitPrice' | 'Remarks' | 'IsSelected' | 'Cheapest' | 'TotalPrice'>
        )>> }
      )> }
    )>>, RFQItem?: Maybe<Array<(
      { __typename?: 'RFQItemEntity' }
      & Pick<RfqItemEntity, 'RFQHeaderID' | 'RFQItemID'>
      & { UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code'>
      )>, PRItem?: Maybe<(
        { __typename?: 'PRItemEntity' }
        & Pick<PrItemEntity, 'ItemID' | 'PRItemID' | 'PRHeaderID' | 'Remark' | 'Quantity' | 'UOMID'>
        & { UOM?: Maybe<(
          { __typename?: 'AccUOMEntity' }
          & Pick<AccUomEntity, 'UOMID' | 'Code' | 'Name'>
        )>, Item?: Maybe<(
          { __typename?: 'ItemEntity' }
          & Pick<ItemEntity, 'ItemID' | 'Name' | 'LatestPurchase'>
        )> }
      )>, RFQSubmissionItem?: Maybe<Array<(
        { __typename?: 'RFQSubmissionItemEntity' }
        & Pick<RfqSubmissionItemEntity, 'RFQSubmissionItemID' | 'RFQSupplierSubmissionID' | 'RFQItemID' | 'UnitPrice' | 'Remarks' | 'IsSelected' | 'Cheapest'>
      )>> }
    )>> }
  )> }
);

export type GetGeneralPrForRfqQueryVariables = Exact<{
  PRHeaderID?: Maybe<Scalars['String']>;
}>;


export type GetGeneralPrForRfqQuery = (
  { __typename?: 'Query' }
  & { getPRHeader: Array<(
    { __typename?: 'PRHeaderEntity' }
    & Pick<PrHeaderEntity, 'PRHeaderID' | 'ExpectedDate' | 'DocNo' | 'RecordStatus'>
  )> }
);

export type GetRfqSupplierInvitationQueryVariables = Exact<{
  RFQHeaderID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetRfqSupplierInvitationQuery = (
  { __typename?: 'Query' }
  & { getRFQSupplierInvitation: Array<(
    { __typename?: 'RFQSupplierInvitationEntity' }
    & Pick<RfqSupplierInvitationEntity, 'RFQSupplierInvitationID' | 'RFQHeaderID' | 'SupplierID'>
    & { RFQHeader?: Maybe<(
      { __typename?: 'RFQHeaderEntity' }
      & Pick<RfqHeaderEntity, 'RFQHeaderID'>
      & { RFQSupplierInvitation?: Maybe<Array<(
        { __typename?: 'RFQSupplierInvitationEntity' }
        & Pick<RfqSupplierInvitationEntity, 'RFQSupplierInvitationID' | 'SupplierID'>
        & { Supplier?: Maybe<(
          { __typename?: 'CreditorAccountEntity' }
          & Pick<CreditorAccountEntity, 'CompanyName' | 'CompanyRegNo' | 'ContactNo' | 'Address' | 'CreditTerm' | 'Remark'>
        )> }
      )>> }
    )>, RFQSupplierSubmission?: Maybe<Array<(
      { __typename?: 'RFQSupplierSubmissionEntity' }
      & Pick<RfqSupplierSubmissionEntity, 'RFQSupplierSubmissionID' | 'RefNo' | 'CreditTerm' | 'SubmittedDate' | 'EffectiveDate' | 'ExpiryDate' | 'Remarks'>
    )>>, LatestSubmittedQuotation?: Maybe<(
      { __typename?: 'RFQSupplierSubmissionEntity' }
      & Pick<RfqSupplierSubmissionEntity, 'RFQSupplierSubmissionID' | 'RefNo' | 'CreditTerm' | 'SubmittedDate'>
    )>, Supplier?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CompanyName'>
    )> }
  )> }
);

export type GetRfqSupplierSubmissionQueryVariables = Exact<{
  RFQSupplierSubmissionID?: Maybe<Scalars['String']>;
  RFQHeaderID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  RFQSupplierInvitationID?: Maybe<Scalars['String']>;
}>;


export type GetRfqSupplierSubmissionQuery = (
  { __typename?: 'Query' }
  & { getRFQSupplierSubmission: Array<(
    { __typename?: 'RFQSupplierSubmissionEntity' }
    & Pick<RfqSupplierSubmissionEntity, 'RFQSupplierSubmissionID' | 'RFQSupplierInvitationID' | 'RefNo' | 'CreditTerm' | 'SubmittedDate' | 'EffectiveDate' | 'ExpiryDate' | 'Remarks' | 'RecordStatus'>
    & { RFQSubmissionItem?: Maybe<Array<(
      { __typename?: 'RFQSubmissionItemEntity' }
      & Pick<RfqSubmissionItemEntity, 'RFQSubmissionItemID' | 'UnitPrice' | 'Remarks' | 'RFQItemID'>
      & { RFQItem?: Maybe<(
        { __typename?: 'RFQItemEntity' }
        & Pick<RfqItemEntity, 'RFQItemID' | 'PRItemID'>
        & { PRItem?: Maybe<(
          { __typename?: 'PRItemEntity' }
          & Pick<PrItemEntity, 'PRItemID' | 'Quantity' | 'ItemID'>
          & { Item?: Maybe<(
            { __typename?: 'ItemEntity' }
            & Pick<ItemEntity, 'ItemID' | 'Name' | 'UOMID'>
            & { UOM?: Maybe<(
              { __typename?: 'AccUOMEntity' }
              & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code'>
            )> }
          )> }
        )> }
      )> }
    )>>, Supplier?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CompanyName'>
    )> }
  )> }
);

export type GetInvitedSupplierQueryVariables = Exact<{
  RFQHeaderID: Scalars['String'];
  companyID: Scalars['String'];
}>;


export type GetInvitedSupplierQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getInvitedSupplier'>
);

export type CreateGeneralRfqMutationVariables = Exact<{
  RFQInput: RfqInput;
  RFQItemInput: Array<RfqItemInput> | RfqItemInput;
}>;


export type CreateGeneralRfqMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createGeneralRFQ'>
);

export type UpdateGeneralRfqMutationVariables = Exact<{
  generalRFQInput: RfqInput;
  generalRFQItemInput: Array<RfqItemInput> | RfqItemInput;
  RFQHeaderID: Scalars['String'];
}>;


export type UpdateGeneralRfqMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateGeneralRFQ'>
);

export type DeleteGeneralRfqMutationVariables = Exact<{
  RFQHeaderID: Scalars['String'];
}>;


export type DeleteGeneralRfqMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteGeneralRFQ'>
);

export type GetPrItemByPridForRfqQueryVariables = Exact<{
  PRHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type GetPrItemByPridForRfqQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPRItemByPRIDForRFQ'>
);

export type InviteSupplierMutationVariables = Exact<{
  RFQHeaderID: Scalars['String'];
  SupplierIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type InviteSupplierMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'inviteSupplier'>
);

export type UninviteSupplierMutationVariables = Exact<{
  RFQHeaderID: Scalars['String'];
  SupplierID: Scalars['String'];
}>;


export type UninviteSupplierMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uninviteSupplier'>
);

export type SubmitGeneralRfqQuoteMutationVariables = Exact<{
  input: RfqSupplierSubmissionInput;
}>;


export type SubmitGeneralRfqQuoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitGeneralRFQQuote'>
);

export type DeleteRfqSupplierSubmissionMutationVariables = Exact<{
  RFQSupplierSubmissionID: Scalars['String'];
}>;


export type DeleteRfqSupplierSubmissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteRFQSupplierSubmission'>
);

export type SubmitEvaluationSelectionMutationVariables = Exact<{
  RFQInput: RfqEvalInput;
  RFQEvalSelectInput: Array<RfqEvalSelectInput> | RfqEvalSelectInput;
}>;


export type SubmitEvaluationSelectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitEvaluationSelection'>
);

export type ActionOnRfqStatusMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  input: RfqRejectInput;
  RFQHeaderID: Array<Scalars['String']> | Scalars['String'];
}>;


export type ActionOnRfqStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnRFQStatus'>
);

export type GetCreditorContactQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CreditorContactID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetCreditorContactQuery = (
  { __typename?: 'Query' }
  & { getCreditorContact: Array<(
    { __typename?: 'CreditorContactEntity' }
    & Pick<CreditorContactEntity, 'CreditorContactID' | 'Name' | 'ContactNo' | 'Designation' | 'Email' | 'IsDefault'>
  )> }
);

export type GetRfQbyStatusQueryVariables = Exact<{
  RFQHeaderID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetRfQbyStatusQuery = (
  { __typename?: 'Query' }
  & { getRFQbyStatus: Array<(
    { __typename?: 'RFQHeaderEntity' }
    & Pick<RfqHeaderEntity, 'modTs' | 'modBy' | 'createdTs' | 'createdBy' | 'RFQHeaderID' | 'AccountID' | 'DocNo' | 'DocDate' | 'Description' | 'ExpectedDate' | 'CancelCloseDate' | 'CancelCloseRemark' | 'RecordStatus' | 'ApprovalStatus' | 'ProfileInfo' | 'PRHeaderInfo'>
    & { RFQSupplierInvitation?: Maybe<Array<(
      { __typename?: 'RFQSupplierInvitationEntity' }
      & Pick<RfqSupplierInvitationEntity, 'RFQSupplierInvitationID' | 'SupplierID' | 'RecordStatus'>
      & { Supplier?: Maybe<(
        { __typename?: 'CreditorAccountEntity' }
        & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CompanyName' | 'CreditTerm' | 'ContactNo' | 'Email'>
      )>, RFQSupplierSubmission?: Maybe<Array<(
        { __typename?: 'RFQSupplierSubmissionEntity' }
        & Pick<RfqSupplierSubmissionEntity, 'RFQSupplierSubmissionID' | 'RefNo' | 'CreditTerm' | 'SubmittedDate' | 'EffectiveDate' | 'ExpiryDate' | 'Remarks'>
      )>>, LatestSubmittedQuotation?: Maybe<(
        { __typename?: 'RFQSupplierSubmissionEntity' }
        & Pick<RfqSupplierSubmissionEntity, 'RefNo' | 'CreditTerm' | 'SubmittedDate' | 'EffectiveDate' | 'ExpiryDate' | 'RecordStatus' | 'Remarks'>
        & { RFQSubmissionItem?: Maybe<Array<(
          { __typename?: 'RFQSubmissionItemEntity' }
          & Pick<RfqSubmissionItemEntity, 'RFQSubmissionItemID' | 'RFQSupplierSubmissionID' | 'RFQItemID' | 'UnitPrice' | 'Remarks' | 'IsSelected' | 'Cheapest' | 'TotalPrice'>
        )>> }
      )> }
    )>>, RFQItem?: Maybe<Array<(
      { __typename?: 'RFQItemEntity' }
      & Pick<RfqItemEntity, 'RFQHeaderID' | 'RFQItemID'>
      & { UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code'>
      )>, PRItem?: Maybe<(
        { __typename?: 'PRItemEntity' }
        & Pick<PrItemEntity, 'ItemID' | 'PRItemID' | 'PRHeaderID' | 'Remark' | 'Quantity' | 'UOMID'>
        & { UOM?: Maybe<(
          { __typename?: 'AccUOMEntity' }
          & Pick<AccUomEntity, 'UOMID' | 'Code' | 'Name'>
        )>, Item?: Maybe<(
          { __typename?: 'ItemEntity' }
          & Pick<ItemEntity, 'ItemID' | 'Name' | 'LatestPurchase'>
        )> }
      )>, RFQSubmissionItem?: Maybe<Array<(
        { __typename?: 'RFQSubmissionItemEntity' }
        & Pick<RfqSubmissionItemEntity, 'RFQSubmissionItemID' | 'RFQSupplierSubmissionID' | 'RFQItemID' | 'UnitPrice' | 'Remarks' | 'IsSelected' | 'Cheapest'>
      )>> }
    )>> }
  )> }
);

export type GetPrNoListQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  PRHeaderID?: Maybe<Scalars['String']>;
}>;


export type GetPrNoListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPRNoList'>
);

export type GetRfQbyStatusListingQueryVariables = Exact<{
  RFQHeaderID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetRfQbyStatusListingQuery = (
  { __typename?: 'Query' }
  & { getRFQbyStatusListing: (
    { __typename?: 'RFQHeaderReturnData' }
    & Pick<RfqHeaderReturnData, 'TotalLength'>
    & { Data?: Maybe<Array<(
      { __typename?: 'RFQHeaderEntity' }
      & Pick<RfqHeaderEntity, 'modTs' | 'modBy' | 'createdTs' | 'createdBy' | 'RFQHeaderID' | 'AccountID' | 'DocNo' | 'DocDate' | 'Description' | 'ApprovalWorkflowRFQ' | 'ExpectedDate' | 'CancelCloseDate' | 'CancelCloseRemark' | 'RecordStatus' | 'ApprovalStatus' | 'ProfileInfo' | 'PRHeaderInfo'>
      & { RFQSupplierInvitation?: Maybe<Array<(
        { __typename?: 'RFQSupplierInvitationEntity' }
        & Pick<RfqSupplierInvitationEntity, 'RFQSupplierInvitationID' | 'SupplierID' | 'RecordStatus'>
        & { Supplier?: Maybe<(
          { __typename?: 'CreditorAccountEntity' }
          & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CompanyName' | 'CreditTerm' | 'ContactNo' | 'Email'>
        )>, RFQSupplierSubmission?: Maybe<Array<(
          { __typename?: 'RFQSupplierSubmissionEntity' }
          & Pick<RfqSupplierSubmissionEntity, 'RFQSupplierSubmissionID' | 'RefNo' | 'CreditTerm' | 'SubmittedDate' | 'EffectiveDate' | 'ExpiryDate' | 'Remarks'>
        )>>, LatestSubmittedQuotation?: Maybe<(
          { __typename?: 'RFQSupplierSubmissionEntity' }
          & Pick<RfqSupplierSubmissionEntity, 'RefNo' | 'CreditTerm' | 'SubmittedDate' | 'EffectiveDate' | 'ExpiryDate' | 'RecordStatus' | 'Remarks'>
          & { RFQSubmissionItem?: Maybe<Array<(
            { __typename?: 'RFQSubmissionItemEntity' }
            & Pick<RfqSubmissionItemEntity, 'RFQSubmissionItemID' | 'RFQSupplierSubmissionID' | 'RFQItemID' | 'UnitPrice' | 'Remarks' | 'IsSelected' | 'Cheapest' | 'TotalPrice'>
          )>> }
        )> }
      )>>, RFQItem?: Maybe<Array<(
        { __typename?: 'RFQItemEntity' }
        & Pick<RfqItemEntity, 'RFQHeaderID' | 'RFQItemID'>
        & { UOM?: Maybe<(
          { __typename?: 'AccUOMEntity' }
          & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code'>
        )>, PRItem?: Maybe<(
          { __typename?: 'PRItemEntity' }
          & Pick<PrItemEntity, 'ItemID' | 'PRItemID' | 'PRHeaderID' | 'Remark' | 'Quantity' | 'UOMID'>
          & { UOM?: Maybe<(
            { __typename?: 'AccUOMEntity' }
            & Pick<AccUomEntity, 'UOMID' | 'Code' | 'Name'>
          )>, Item?: Maybe<(
            { __typename?: 'ItemEntity' }
            & Pick<ItemEntity, 'ItemID' | 'Name' | 'LatestPurchase'>
          )> }
        )>, RFQSubmissionItem?: Maybe<Array<(
          { __typename?: 'RFQSubmissionItemEntity' }
          & Pick<RfqSubmissionItemEntity, 'RFQSubmissionItemID' | 'RFQSupplierSubmissionID' | 'RFQItemID' | 'UnitPrice' | 'Remarks' | 'IsSelected' | 'Cheapest'>
        )>> }
      )>> }
    )>> }
  ) }
);

export type LatestOpenPeriodCheckingDateQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type LatestOpenPeriodCheckingDateQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'latestOpenPeriodCheckingDate'>
);

export type GetScheduledPurchasesQueryVariables = Exact<{
  ScheduledPurchasesID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetScheduledPurchasesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getScheduledPurchasesCount'>
  & { getScheduledPurchases: Array<(
    { __typename?: 'ScheduledPurchasesEntity' }
    & Pick<ScheduledPurchasesEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'RecordStatus' | 'ScheduledPurchasesID' | 'CompanyID' | 'DeliveryLocationID' | 'Title' | 'RequestedBy' | 'RequestStatus' | 'ScheduledDate' | 'ScheduledFrequency' | 'Description'>
    & { DeliveryLocation?: Maybe<(
      { __typename?: 'DeliveryLocationEntity' }
      & Pick<DeliveryLocationEntity, 'Name' | 'Address'>
    )>, ScheduledPurchasesItem?: Maybe<Array<(
      { __typename?: 'ScheduledPurchasesItemEntity' }
      & Pick<ScheduledPurchasesItemEntity, 'ScheduledPurchasesItemID' | 'ItemID' | 'SupplierID' | 'CostCentreID' | 'WarehouseID' | 'Quantity' | 'UOMID' | 'UnitPrice' | 'TotalAmt' | 'TaxAmt' | 'TaxSchemeID' | 'Remarks' | 'UOMExchangeList'>
      & { Item?: Maybe<(
        { __typename?: 'ItemEntity' }
        & Pick<ItemEntity, 'Name' | 'IsCentralised' | 'IsInventory'>
      )>, TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'AccountID' | 'TaxSchemeID' | 'Code' | 'Description'>
        & { LatestTax?: Maybe<(
          { __typename?: 'AccTax' }
          & Pick<AccTax, 'Rate'>
        )> }
      )>, Supplier?: Maybe<(
        { __typename?: 'CreditorAccountEntity' }
        & Pick<CreditorAccountEntity, 'CompanyName'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code'>
      )> }
    )>> }
  )> }
);

export type GetLatestPurchaseSupplierFromPoQueryVariables = Exact<{
  ItemID?: Maybe<Scalars['String']>;
}>;


export type GetLatestPurchaseSupplierFromPoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getLatestPurchaseSupplierFromPO'>
);

export type CreateScheduledPurchasesMutationVariables = Exact<{
  input: ScheduledPurchasesInput;
}>;


export type CreateScheduledPurchasesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createScheduledPurchases'>
);

export type UpdateScheduledPurchasesMutationVariables = Exact<{
  input: ScheduledPurchasesInput;
  ScheduledPurchasesID: Scalars['String'];
}>;


export type UpdateScheduledPurchasesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateScheduledPurchases'>
);

export type CreatePreparePoMutationVariables = Exact<{
  input: Array<PreparePoInput> | PreparePoInput;
  ScheduledPurchasesID: Scalars['String'];
}>;


export type CreatePreparePoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPreparePO'>
);

export type DeleteScheduledPurchasesMutationVariables = Exact<{
  ScheduledPurchasesID: Scalars['String'];
}>;


export type DeleteScheduledPurchasesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteScheduledPurchases'>
);

export type GetLatestTaxByTaxSchemeIDsQueryVariables = Exact<{
  taxSchemeIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetLatestTaxByTaxSchemeIDsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getLatestTaxByTaxSchemeIDs'>
);

export type GetUsersByAccountAndSoftwareQueryVariables = Exact<{
  superUserBool?: Maybe<UserSelection>;
  status?: Maybe<CommonStatus>;
}>;


export type GetUsersByAccountAndSoftwareQuery = (
  { __typename?: 'Query' }
  & { getUsersByAccountAndSoftware: Array<(
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'department' | 'email' | 'contactNo' | 'confirmed' | 'superUser' | 'commonStatus' | 'jobTitle' | 'userName' | 'blockCount'>
  )> }
);

export type GetApprovalPolicyQueryVariables = Exact<{
  Category: Scalars['String'];
  ApprovalPolicyID?: Maybe<Scalars['String']>;
}>;


export type GetApprovalPolicyQuery = (
  { __typename?: 'Query' }
  & { getApprovalPolicy: Array<(
    { __typename?: 'AccApprovalPolicyEntity' }
    & Pick<AccApprovalPolicyEntity, 'createdTs' | 'modTs' | 'ApprovalPolicyID' | 'AccountID' | 'lowerLimit' | 'upperLimit' | 'Category' | 'ApprovalList'>
  )> }
);

export type GetAllApprovalPolicyQueryVariables = Exact<{
  Category: Scalars['String'];
}>;


export type GetAllApprovalPolicyQuery = (
  { __typename?: 'Query' }
  & { getAllApprovalPolicy?: Maybe<Array<(
    { __typename?: 'ProcurementApprovalPolicyEntity' }
    & Pick<ProcurementApprovalPolicyEntity, 'ApprovalPolicyID' | 'createdTs' | 'modTs' | 'Category' | 'ApprovalList'>
  )>> }
);

export type GetApprovalPolicyAssignmentQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Category?: Maybe<Scalars['String']>;
}>;


export type GetApprovalPolicyAssignmentQuery = (
  { __typename?: 'Query' }
  & { getApprovalPolicyAssignment?: Maybe<(
    { __typename?: 'ProcurementApprovalPolicyAssignmentEntity' }
    & Pick<ProcurementApprovalPolicyAssignmentEntity, 'createdTs' | 'modTs' | 'modBy' | 'ApprovalPolicyAssignmentID' | 'ApprovalPolicyID' | 'AccountID' | 'CompanyID' | 'Category' | 'ApprovalList' | 'LowerLimit' | 'UpperLimit'>
  )> }
);

export type CreateApprovalPolicyMutationVariables = Exact<{
  input: ApprovalPolicyInput;
}>;


export type CreateApprovalPolicyMutation = (
  { __typename?: 'Mutation' }
  & { createApprovalPolicy: (
    { __typename?: 'AccApprovalPolicyEntity' }
    & Pick<AccApprovalPolicyEntity, 'ApprovalPolicyID'>
  ) }
);

export type CreateApprovalPolicyAssignmentMutationVariables = Exact<{
  input: ApprovalPolicyAssignmentInput;
}>;


export type CreateApprovalPolicyAssignmentMutation = (
  { __typename?: 'Mutation' }
  & { createApprovalPolicyAssignment: (
    { __typename?: 'AccApprovalPolicyAssignmentEntity' }
    & Pick<AccApprovalPolicyAssignmentEntity, 'ApprovalPolicyAssignmentID'>
  ) }
);

export type UpdateApprovalPolicyMutationVariables = Exact<{
  input: ApprovalPolicyInput;
}>;


export type UpdateApprovalPolicyMutation = (
  { __typename?: 'Mutation' }
  & { updateApprovalPolicy: (
    { __typename?: 'AccApprovalPolicyEntity' }
    & Pick<AccApprovalPolicyEntity, 'ApprovalPolicyID'>
  ) }
);

export type UpdateApprovalPolicyAssignmentMutationVariables = Exact<{
  input: ApprovalPolicyAssignmentInput;
}>;


export type UpdateApprovalPolicyAssignmentMutation = (
  { __typename?: 'Mutation' }
  & { updateApprovalPolicyAssignment: (
    { __typename?: 'AccApprovalPolicyAssignmentEntity' }
    & Pick<AccApprovalPolicyAssignmentEntity, 'ApprovalPolicyAssignmentID'>
  ) }
);

export type DeleteApprovalPolicyMutationVariables = Exact<{
  input: ApprovalPolicyDeleteInput;
}>;


export type DeleteApprovalPolicyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteApprovalPolicy'>
);

export type DeleteApprovalPolicyAssignmentMutationVariables = Exact<{
  input: ApprovalPolicyAssignmentDeleteInput;
}>;


export type DeleteApprovalPolicyAssignmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteApprovalPolicyAssignment'>
);

export type GetApprovalLogQueryVariables = Exact<{
  RefID: Scalars['String'];
}>;


export type GetApprovalLogQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getApprovalLog'>
);

export type GetGpApprovalPolicyInfolineQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGpApprovalPolicyInfolineQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGPApprovalPolicyInfoline'>
);

export type GetPendingGpQueryVariables = Exact<{
  RefTable: Scalars['String'];
}>;


export type GetPendingGpQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPendingGP'>
);

export type GetBulkPurchasePriceQueryVariables = Exact<{
  ItemID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  BulkPurchasePriceHeaderID?: Maybe<Scalars['String']>;
}>;


export type GetBulkPurchasePriceQuery = (
  { __typename?: 'Query' }
  & { getBulkPurchasePrice: Array<(
    { __typename?: 'BulkPurchasePriceHeaderEntity' }
    & Pick<BulkPurchasePriceHeaderEntity, 'BulkPurchasePriceHeaderID' | 'SupplierID' | 'EffectiveDate' | 'ExpiryDate' | 'Remarks'>
    & { Supplier?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CompanyName' | 'CompanyRegNo' | 'ContactNo' | 'Email' | 'CreditTerm' | 'TaxSchemeID'>
    )>, BulkPurchasePriceItem?: Maybe<Array<(
      { __typename?: 'BulkPurchasePriceItemEntity' }
      & Pick<BulkPurchasePriceItemEntity, 'BulkPurchasePriceItemID' | 'BulkPurchasePriceHeaderID' | 'ItemID' | 'UOMID'>
      & { Item?: Maybe<(
        { __typename?: 'ItemEntity' }
        & Pick<ItemEntity, 'Name' | 'Description'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Code' | 'Name'>
      )>, BulkPurchasePriceDetail?: Maybe<Array<(
        { __typename?: 'BulkPurchasePriceDetailEntity' }
        & Pick<BulkPurchasePriceDetailEntity, 'LowerLimit' | 'UpperLimit' | 'UnitPrice'>
      )>> }
    )>> }
  )> }
);

export type CreateBulkPurchasePriceMutationVariables = Exact<{
  input: BulkPurchasePriceHeaderInput;
  bulkPurchaseItemInput: Array<BulkPurchasePriceItemInput> | BulkPurchasePriceItemInput;
}>;


export type CreateBulkPurchasePriceMutation = (
  { __typename?: 'Mutation' }
  & { createBulkPurchasePrice: (
    { __typename?: 'BulkPurchasePriceHeaderEntity' }
    & Pick<BulkPurchasePriceHeaderEntity, 'SupplierID' | 'EffectiveDate' | 'ExpiryDate' | 'Remarks'>
    & { BulkPurchasePriceItem?: Maybe<Array<(
      { __typename?: 'BulkPurchasePriceItemEntity' }
      & Pick<BulkPurchasePriceItemEntity, 'ItemID' | 'UOMID'>
      & { BulkPurchasePriceDetail?: Maybe<Array<(
        { __typename?: 'BulkPurchasePriceDetailEntity' }
        & Pick<BulkPurchasePriceDetailEntity, 'LowerLimit' | 'UpperLimit' | 'UnitPrice'>
      )>> }
    )>> }
  ) }
);

export type UpdateBulkPurchasePriceMutationVariables = Exact<{
  BulkPurchasePriceHeaderID: Scalars['String'];
  input: BulkPurchasePriceHeaderInput;
  bulkPurchaseItemInput: Array<BulkPurchasePriceItemInput> | BulkPurchasePriceItemInput;
}>;


export type UpdateBulkPurchasePriceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateBulkPurchasePrice'>
);

export type DeleteBulkPurchasePriceMutationVariables = Exact<{
  BulkPurchasePriceHeaderID: Scalars['String'];
}>;


export type DeleteBulkPurchasePriceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBulkPurchasePrice'>
);

export type GetClassificationCodeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClassificationCodeQuery = (
  { __typename?: 'Query' }
  & { getClassificationCode: Array<(
    { __typename?: 'eInvoiceClassificationEntity' }
    & Pick<EInvoiceClassificationEntity, 'id' | 'classificationCode' | 'description'>
  )> }
);

export type GetMsicCodeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMsicCodeQuery = (
  { __typename?: 'Query' }
  & { getMSICCode: Array<(
    { __typename?: 'eInvoiceMsicEntity' }
    & Pick<EInvoiceMsicEntity, 'id' | 'msicCode' | 'description' | 'categoryReference'>
  )> }
);

export type GetGeneralPurchasePolicyQueryVariables = Exact<{
  GeneralPurchasePolicyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
}>;


export type GetGeneralPurchasePolicyQuery = (
  { __typename?: 'Query' }
  & { getGeneralPurchasePolicy: Array<(
    { __typename?: 'GeneralPurchasePolicyEntity' }
    & Pick<GeneralPurchasePolicyEntity, 'GeneralPurchasePolicyID' | 'AccountID' | 'VariancePerc' | 'RFQRequired' | 'MasterCOAID' | 'RecordStatus'>
  )> }
);

export type CreateGeneralPurchasePolicyMutationVariables = Exact<{
  GeneralPurchasePolicyInput: GeneralPurchasePolicyInput;
}>;


export type CreateGeneralPurchasePolicyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createGeneralPurchasePolicy'>
);

export type UpdateGeneralPurchasePolicyMutationVariables = Exact<{
  GeneralPurchasePolicyID?: Maybe<Scalars['String']>;
  GeneralPurchasePolicyInput: GeneralPurchasePolicyInput;
}>;


export type UpdateGeneralPurchasePolicyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateGeneralPurchasePolicy'>
);

export type GetGeneralSettingSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGeneralSettingSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'activeSupplierCategoryCount' | 'activeSupplierProfileCount' | 'activeUomCount' | 'lastUomExchange' | 'activePurchaseCategoryCount' | 'activeItemCount' | 'activeBulkPurchaseCount' | 'lastPurchaseVariance' | 'purchaseVariancePercenetage'>
);

export type GetRoleWithAccountQueryVariables = Exact<{
  DifferentConnection?: Maybe<Scalars['String']>;
}>;


export type GetRoleWithAccountQuery = (
  { __typename?: 'Query' }
  & { getRoleWithAccount: Array<(
    { __typename?: 'AccRoleEntity' }
    & Pick<AccRoleEntity, 'ID' | 'accountID' | 'name' | 'createdTs' | 'createdBy' | 'modTs'>
  )> }
);

export type GetItemQueryVariables = Exact<{
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ItemID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  ItemCategoryID?: Maybe<Scalars['String']>;
}>;


export type GetItemQuery = (
  { __typename?: 'Query' }
  & { getItem: Array<(
    { __typename?: 'ItemEntity' }
    & Pick<ItemEntity, 'ItemID' | 'ItemCategoryID' | 'AccountID' | 'Name' | 'Description' | 'IsCentralised' | 'IsInventory' | 'RecordStatus' | 'DefaultSupplierID' | 'UOMID' | 'TaxSchemeID' | 'Remark' | 'VariancePerc' | 'IsUse' | 'UsedInIC' | 'LatestUOMID' | 'MarketPrice' | 'LatestPurchasePrice' | 'LatestUOMCode'>
    & { ItemCategory?: Maybe<(
      { __typename?: 'ItemCategoryEntity' }
      & Pick<ItemCategoryEntity, 'MasterCOAID' | 'ParentCategoryMCOA' | 'Name'>
      & { MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'MasterCOAID' | 'Name' | 'Code'>
      )> }
    )>, DefaultSupplier?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CompanyName' | 'CompanyRegNo'>
    )>, PRItem?: Maybe<Array<(
      { __typename?: 'PRItemEntity' }
      & Pick<PrItemEntity, 'PRItemID'>
    )>>, UOM?: Maybe<(
      { __typename?: 'AccUOMEntity' }
      & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code'>
    )>, TaxScheme?: Maybe<(
      { __typename?: 'TaxSchemeEntity' }
      & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code' | 'Description' | 'AccTaxCategory'>
    )> }
  )> }
);

export type GetItemNameQueryVariables = Exact<{
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ItemID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  ItemCategoryID?: Maybe<Scalars['String']>;
}>;


export type GetItemNameQuery = (
  { __typename?: 'Query' }
  & { getItem: Array<(
    { __typename?: 'ItemEntity' }
    & Pick<ItemEntity, 'ItemID' | 'ItemCategoryID' | 'Name'>
  )> }
);

export type DeleteItemMutationVariables = Exact<{
  ItemID: Scalars['String'];
}>;


export type DeleteItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteItem'>
);

export type GetCheckingValidationItemQueryVariables = Exact<{
  ItemExcelInput: Array<ItemExcelInput> | ItemExcelInput;
}>;


export type GetCheckingValidationItemQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCheckingValidationItem'>
);

export type CreateItemMutationVariables = Exact<{
  input: ItemInput;
}>;


export type CreateItemMutation = (
  { __typename?: 'Mutation' }
  & { createItem: (
    { __typename?: 'ItemEntity' }
    & Pick<ItemEntity, 'ItemID' | 'createdBy'>
  ) }
);

export type UpdateItemMutationVariables = Exact<{
  input: ItemInput;
}>;


export type UpdateItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateItem'>
);

export type CreateBatchItemMutationVariables = Exact<{
  input: Array<ItemExcelInput> | ItemExcelInput;
}>;


export type CreateBatchItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBatchItem'>
);

export type CancelItemMutationVariables = Exact<{
  ItemID: Scalars['String'];
}>;


export type CancelItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelItem'>
);

export type GetApItemMappingQueryVariables = Exact<{
  ExpenseItemID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetApItemMappingQuery = (
  { __typename?: 'Query' }
  & { getExpenseItem: Array<(
    { __typename?: 'ExpenseItemEntity' }
    & Pick<ExpenseItemEntity, 'ExpenseItemID' | 'ExpenseClassID' | 'ItemName' | 'Description' | 'Remark' | 'TaxSchemeID' | 'TaxRate'>
  )> }
);

export type GetWarehouseByStockItemQueryVariables = Exact<{
  StockItemID: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type GetWarehouseByStockItemQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWarehouseByStockItem'>
);

export type GetItemByCompanyAssignmentQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetItemByCompanyAssignmentQuery = (
  { __typename?: 'Query' }
  & { getItemByCompanyAssignment: Array<(
    { __typename?: 'ItemEntity' }
    & Pick<ItemEntity, 'ItemID' | 'ItemCategoryID' | 'Name' | 'IsCentralised' | 'IsInventory' | 'DefaultSupplierID' | 'UOMID' | 'LatestUOMID' | 'TaxSchemeID' | 'MarketPrice' | 'VariancePerc' | 'LatestPurchasePrice' | 'LatestUOMCode'>
    & { PRItem?: Maybe<Array<(
      { __typename?: 'PRItemEntity' }
      & Pick<PrItemEntity, 'PRItemID'>
    )>>, UOM?: Maybe<(
      { __typename?: 'AccUOMEntity' }
      & Pick<AccUomEntity, 'Code'>
    )> }
  )> }
);

export type GetItemByCompanyAssignmentListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetItemByCompanyAssignmentListingQuery = (
  { __typename?: 'Query' }
  & { getItemByCompanyAssignment: Array<(
    { __typename?: 'ItemEntity' }
    & Pick<ItemEntity, 'ItemID' | 'ItemCategoryID' | 'Name' | 'IsCentralised' | 'IsInventory' | 'DefaultSupplierID' | 'UOMID' | 'LatestUOMID' | 'TaxSchemeID' | 'MarketPrice' | 'VariancePerc' | 'LatestPurchasePrice' | 'LatestUOMCode'>
    & { UOM?: Maybe<(
      { __typename?: 'AccUOMEntity' }
      & Pick<AccUomEntity, 'Code'>
    )> }
  )> }
);

export type GetItemCategoryQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  ItemCategoryID?: Maybe<Scalars['String']>;
}>;


export type GetItemCategoryQuery = (
  { __typename?: 'Query' }
  & { getItemCategory: Array<(
    { __typename?: 'ItemCategoryEntity' }
    & Pick<ItemCategoryEntity, 'ItemCategoryID' | 'Name' | 'ParentItemCategoryID' | 'Level' | 'IsLastNode' | 'MasterCOAID' | 'ParentCategoryMCOA'>
    & { MasterCOA?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'MasterCOAID' | 'Code' | 'Name'>
    )>, Item?: Maybe<Array<(
      { __typename?: 'ItemEntity' }
      & Pick<ItemEntity, 'Name' | 'Description'>
    )>> }
  )> }
);

export type GetItemCategoryNameQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  ItemCategoryID?: Maybe<Scalars['String']>;
}>;


export type GetItemCategoryNameQuery = (
  { __typename?: 'Query' }
  & { getItemCategory: Array<(
    { __typename?: 'ItemCategoryEntity' }
    & Pick<ItemCategoryEntity, 'ItemCategoryID' | 'Name' | 'ParentItemCategoryID' | 'Level' | 'IsLastNode'>
  )> }
);

export type GetItemCategoryCompanyAssignmentQueryVariables = Exact<{
  ItemCategoryCompanyAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ItemCategoryID?: Maybe<Scalars['String']>;
}>;


export type GetItemCategoryCompanyAssignmentQuery = (
  { __typename?: 'Query' }
  & { getItemCategoryCompanyAssignment: Array<(
    { __typename?: 'ItemCategoryCompanyAssignmentEntity' }
    & Pick<ItemCategoryCompanyAssignmentEntity, 'ItemCategoryCompanyAssignmentID' | 'ItemCategoryID' | 'CompanyID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'RecordStatus'>
    & { ItemCategory?: Maybe<(
      { __typename?: 'ItemCategoryEntity' }
      & Pick<ItemCategoryEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ItemCategoryID' | 'MasterCOAID' | 'RecordStatus' | 'Name'>
    )> }
  )> }
);

export type CreateItemCategoryMutationVariables = Exact<{
  ItemCategoryInput: ItemCategoryInput;
}>;


export type CreateItemCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createItemCategory: (
    { __typename?: 'ItemCategoryEntity' }
    & Pick<ItemCategoryEntity, 'ItemCategoryID' | 'createdBy' | 'Name' | 'MasterCOAID'>
  ) }
);

export type UpdateItemCategoryMutationVariables = Exact<{
  ItemCategoryInput: ItemCategoryInput;
}>;


export type UpdateItemCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateItemCategory'>
);

export type DeleteItemCategoryMutationVariables = Exact<{
  ItemCategoryID: Scalars['String'];
}>;


export type DeleteItemCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteItemCategory'>
);

export type AssignItemCategoryCompanyMutationVariables = Exact<{
  input: ItemCategoryCompanyAssignmentInput;
}>;


export type AssignItemCategoryCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AssignItemCategoryCompany'>
);

export type UnassignItemCategoryCompanyMutationVariables = Exact<{
  input: ItemCategoryCompanyAssignmentInput;
}>;


export type UnassignItemCategoryCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UnassignItemCategoryCompany'>
);

export type GetDocNumQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Type?: Maybe<DocumentType>;
  RefTable?: Maybe<Scalars['String']>;
}>;


export type GetDocNumQuery = (
  { __typename?: 'Query' }
  & { getDocumentNumberHeader: Array<(
    { __typename?: 'DocumentNumberHeaderEntity' }
    & Pick<DocumentNumberHeaderEntity, 'DocumentNumberHeaderID' | 'SampleOutput' | 'IsAutoDocNo' | 'DocNumGenerated'>
    & { DocumentNumberDetail?: Maybe<Array<(
      { __typename?: 'DocumentNumberDetailEntity' }
      & Pick<DocumentNumberDetailEntity, 'DocumentNumberDetailID' | 'Sequence' | 'FormatType' | 'FormatValue' | 'NextNumber' | 'StartFrom' | 'ResetBy'>
    )>> }
  )>, getDocumentNumberDetail: Array<(
    { __typename?: 'DocumentNumberDetailEntity' }
    & Pick<DocumentNumberDetailEntity, 'Sequence' | 'FormatType' | 'FormatValue' | 'NextNumber' | 'StartFrom' | 'ResetBy'>
  )> }
);

export type GetDocumentNumberHeaderQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Type?: Maybe<DocumentType>;
  RefTable?: Maybe<Scalars['String']>;
}>;


export type GetDocumentNumberHeaderQuery = (
  { __typename?: 'Query' }
  & { getDocumentNumberHeader: Array<(
    { __typename?: 'DocumentNumberHeaderEntity' }
    & Pick<DocumentNumberHeaderEntity, 'DocumentNumberHeaderID' | 'SampleOutput' | 'IsAutoDocNo' | 'DocNumGenerated' | 'RefTable'>
  )> }
);

export type GetDocumentNumberDetailQueryVariables = Exact<{
  DocumentNumberHeaderID: Scalars['String'];
}>;


export type GetDocumentNumberDetailQuery = (
  { __typename?: 'Query' }
  & { getDocumentNumberDetail: Array<(
    { __typename?: 'DocumentNumberDetailEntity' }
    & Pick<DocumentNumberDetailEntity, 'DocumentNumberDetailID' | 'Sequence' | 'FormatType' | 'FormatValue' | 'NextNumber' | 'StartFrom' | 'ResetBy'>
  )> }
);

export type GetDocNumTitleQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Type?: Maybe<DocumentType>;
  RefTable?: Maybe<Scalars['String']>;
}>;


export type GetDocNumTitleQuery = (
  { __typename?: 'Query' }
  & { getDocumentNumberHeader: Array<(
    { __typename?: 'DocumentNumberHeaderEntity' }
    & Pick<DocumentNumberHeaderEntity, 'SampleOutput' | 'DocumentNumberHeaderID' | 'Type' | 'RefTable' | 'IsAutoDocNo' | 'DocNumGenerated'>
  )> }
);

export type UpdateDocumentNumberHeaderMutationVariables = Exact<{
  DocumentNumberDetailInput: Array<DocumentNumberDetailInput> | DocumentNumberDetailInput;
  DocumentNumberHeaderInput: DocumentNumberHeaderInput;
  DifferentConnection?: Maybe<Scalars['String']>;
}>;


export type UpdateDocumentNumberHeaderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDocumentNumberHeader'>
);

export type UpdateIsAutoDocNoMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  RefTable: Scalars['String'];
  IsAutoDocNo: Scalars['Boolean'];
  input: DocumentNumberHeaderInput;
}>;


export type UpdateIsAutoDocNoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateIsAutoDocNo'>
);

export type GetPurchaseItemVariancePolicyQueryVariables = Exact<{
  PurchaseItemVariancePolicyID?: Maybe<Scalars['String']>;
}>;


export type GetPurchaseItemVariancePolicyQuery = (
  { __typename?: 'Query' }
  & { getPurchaseItemVariancePolicy: Array<(
    { __typename?: 'PurchaseItemVariancePolicyEntity' }
    & Pick<PurchaseItemVariancePolicyEntity, 'PurchaseItemVariancePolicyID' | 'VariancePerc'>
  )> }
);

export type CreatePurchaseItemVariancePolicyMutationVariables = Exact<{
  PurchaseItemVariancePolicyInput: PurchaseItemVariancePolicyInput;
}>;


export type CreatePurchaseItemVariancePolicyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPurchaseItemVariancePolicy'>
);

export type UpdatePurchaseItemVariancePolicyMutationVariables = Exact<{
  PurchaseItemVariancePolicyID: Scalars['String'];
  PurchaseItemVariancePolicyInput: PurchaseItemVariancePolicyInput;
}>;


export type UpdatePurchaseItemVariancePolicyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePurchaseItemVariancePolicy'>
);

export type GetRoleQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetRoleQuery = (
  { __typename?: 'Query' }
  & { getRole: Array<(
    { __typename?: 'AccRoleEntity' }
    & Pick<AccRoleEntity, 'ID' | 'name'>
  )> }
);

export type GetGeneralPurchaseSummaryQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type GetGeneralPurchaseSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'scheduledPurchaseInfoCount' | 'prInfoCount' | 'rfqCount' | 'poInfoCount' | 'doInfoCount' | 'lastDODate' | 'grtnInfoCount' | 'lastGRTNDate' | 'getRolePermission' | 'getAccountPermission'>
  & { procurementLoggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'accountID' | 'avatar' | 'name' | 'email' | 'contactNo' | 'department' | 'superUser' | 'userName' | 'jobTitle' | 'lastestAccessedEntity'>
  ) }
);


export const LoggedInUserProfileDocument = gql`
    query loggedInUserProfile {
  loggedInUserProfile {
    ID
    accountID
    avatar
    name
    email
    contactNo
    department
    superUser
    userName
    jobTitle
  }
}
    `;

/**
 * __useLoggedInUserProfileQuery__
 *
 * To run a query within a React component, call `useLoggedInUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInUserProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
      }
export function useLoggedInUserProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
        }
export type LoggedInUserProfileQueryHookResult = ReturnType<typeof useLoggedInUserProfileQuery>;
export type LoggedInUserProfileLazyQueryHookResult = ReturnType<typeof useLoggedInUserProfileLazyQuery>;
export type LoggedInUserProfileQueryResult = ApolloReactCommon.QueryResult<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>;
export const ProcurementLoggedInUserProfileDocument = gql`
    query procurementLoggedInUserProfile {
  procurementLoggedInUserProfile {
    ID
    accountID
    avatar
    name
    email
    contactNo
    department
    superUser
    userName
    jobTitle
    lastestAccessedEntity
  }
}
    `;

/**
 * __useProcurementLoggedInUserProfileQuery__
 *
 * To run a query within a React component, call `useProcurementLoggedInUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcurementLoggedInUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcurementLoggedInUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useProcurementLoggedInUserProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProcurementLoggedInUserProfileQuery, ProcurementLoggedInUserProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<ProcurementLoggedInUserProfileQuery, ProcurementLoggedInUserProfileQueryVariables>(ProcurementLoggedInUserProfileDocument, baseOptions);
      }
export function useProcurementLoggedInUserProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProcurementLoggedInUserProfileQuery, ProcurementLoggedInUserProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProcurementLoggedInUserProfileQuery, ProcurementLoggedInUserProfileQueryVariables>(ProcurementLoggedInUserProfileDocument, baseOptions);
        }
export type ProcurementLoggedInUserProfileQueryHookResult = ReturnType<typeof useProcurementLoggedInUserProfileQuery>;
export type ProcurementLoggedInUserProfileLazyQueryHookResult = ReturnType<typeof useProcurementLoggedInUserProfileLazyQuery>;
export type ProcurementLoggedInUserProfileQueryResult = ApolloReactCommon.QueryResult<ProcurementLoggedInUserProfileQuery, ProcurementLoggedInUserProfileQueryVariables>;
export const ProcurementLoginDocument = gql`
    mutation procurementLogin($loginId: String!, $password: String!) {
  procurementLogin(loginId: $loginId, password: $password) {
    accessToken
    user {
      ID
      name
      userName
      email
      contactNo
      superUser
      department
      jobTitle
      avatar
      address {
        address
        country
        city
        district
        state
        postCode
      }
      lastestAccessedEntity
    }
  }
}
    `;
export type ProcurementLoginMutationFn = ApolloReactCommon.MutationFunction<ProcurementLoginMutation, ProcurementLoginMutationVariables>;

/**
 * __useProcurementLoginMutation__
 *
 * To run a mutation, you first call `useProcurementLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcurementLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [procurementLoginMutation, { data, loading, error }] = useProcurementLoginMutation({
 *   variables: {
 *      loginId: // value for 'loginId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useProcurementLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProcurementLoginMutation, ProcurementLoginMutationVariables>) {
        return ApolloReactHooks.useMutation<ProcurementLoginMutation, ProcurementLoginMutationVariables>(ProcurementLoginDocument, baseOptions);
      }
export type ProcurementLoginMutationHookResult = ReturnType<typeof useProcurementLoginMutation>;
export type ProcurementLoginMutationResult = ApolloReactCommon.MutationResult<ProcurementLoginMutation>;
export type ProcurementLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<ProcurementLoginMutation, ProcurementLoginMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($newPassword: String!, $currentPassword: String!) {
  changePassword(newPassword: $newPassword, currentPassword: $currentPassword)
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = ApolloReactCommon.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = ApolloReactCommon.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($userID: String, $password: String!, $token: String!) {
  resetPassword(userID: $userID, password: $password, token: $token)
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const LogoutDocument = gql`
    mutation logout($ID: String) {
  logout(ID: $ID)
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ActivateUserDocument = gql`
    mutation activateUser($token: String!) {
  activateUser(token: $token)
}
    `;
export type ActivateUserMutationFn = ApolloReactCommon.MutationFunction<ActivateUserMutation, ActivateUserMutationVariables>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useActivateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateUserMutation, ActivateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateUserMutation, ActivateUserMutationVariables>(ActivateUserDocument, baseOptions);
      }
export type ActivateUserMutationHookResult = ReturnType<typeof useActivateUserMutation>;
export type ActivateUserMutationResult = ApolloReactCommon.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateUserMutation, ActivateUserMutationVariables>;
export const CreatePasswordDocument = gql`
    mutation createPassword($input: passwordAccInfo!) {
  createPassword(input: $input)
}
    `;
export type CreatePasswordMutationFn = ApolloReactCommon.MutationFunction<CreatePasswordMutation, CreatePasswordMutationVariables>;

/**
 * __useCreatePasswordMutation__
 *
 * To run a mutation, you first call `useCreatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPasswordMutation, { data, loading, error }] = useCreatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePasswordMutation, CreatePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePasswordMutation, CreatePasswordMutationVariables>(CreatePasswordDocument, baseOptions);
      }
export type CreatePasswordMutationHookResult = ReturnType<typeof useCreatePasswordMutation>;
export type CreatePasswordMutationResult = ApolloReactCommon.MutationResult<CreatePasswordMutation>;
export type CreatePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePasswordMutation, CreatePasswordMutationVariables>;
export const GetCompanySettingSummaryDocument = gql`
    query getCompanySettingSummary($CompanyID: String) {
  lastApprovalPolicy(CompanyID: $CompanyID)
  lastNumberingStructure(CompanyID: $CompanyID)
  activeDeliveryLocationCount(CompanyID: $CompanyID)
}
    `;

/**
 * __useGetCompanySettingSummaryQuery__
 *
 * To run a query within a React component, call `useGetCompanySettingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanySettingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanySettingSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetCompanySettingSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanySettingSummaryQuery, GetCompanySettingSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanySettingSummaryQuery, GetCompanySettingSummaryQueryVariables>(GetCompanySettingSummaryDocument, baseOptions);
      }
export function useGetCompanySettingSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanySettingSummaryQuery, GetCompanySettingSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanySettingSummaryQuery, GetCompanySettingSummaryQueryVariables>(GetCompanySettingSummaryDocument, baseOptions);
        }
export type GetCompanySettingSummaryQueryHookResult = ReturnType<typeof useGetCompanySettingSummaryQuery>;
export type GetCompanySettingSummaryLazyQueryHookResult = ReturnType<typeof useGetCompanySettingSummaryLazyQuery>;
export type GetCompanySettingSummaryQueryResult = ApolloReactCommon.QueryResult<GetCompanySettingSummaryQuery, GetCompanySettingSummaryQueryVariables>;
export const GetDeliveryLocationDocument = gql`
    query getDeliveryLocation($CompanyID: String, $orderByAsc: String) {
  getDeliveryLocation(CompanyID: $CompanyID, orderByAsc: $orderByAsc) {
    DeliveryLocationID
    Name
    Address
    ContactNo
    MainLocation
    CompanyID
  }
}
    `;

/**
 * __useGetDeliveryLocationQuery__
 *
 * To run a query within a React component, call `useGetDeliveryLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryLocationQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetDeliveryLocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDeliveryLocationQuery, GetDeliveryLocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDeliveryLocationQuery, GetDeliveryLocationQueryVariables>(GetDeliveryLocationDocument, baseOptions);
      }
export function useGetDeliveryLocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDeliveryLocationQuery, GetDeliveryLocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDeliveryLocationQuery, GetDeliveryLocationQueryVariables>(GetDeliveryLocationDocument, baseOptions);
        }
export type GetDeliveryLocationQueryHookResult = ReturnType<typeof useGetDeliveryLocationQuery>;
export type GetDeliveryLocationLazyQueryHookResult = ReturnType<typeof useGetDeliveryLocationLazyQuery>;
export type GetDeliveryLocationQueryResult = ApolloReactCommon.QueryResult<GetDeliveryLocationQuery, GetDeliveryLocationQueryVariables>;
export const GetDeliveryLocationNameDocument = gql`
    query getDeliveryLocationName($CompanyID: String, $orderByAsc: String) {
  getDeliveryLocation(CompanyID: $CompanyID, orderByAsc: $orderByAsc) {
    DeliveryLocationID
    Name
  }
}
    `;

/**
 * __useGetDeliveryLocationNameQuery__
 *
 * To run a query within a React component, call `useGetDeliveryLocationNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryLocationNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryLocationNameQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetDeliveryLocationNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDeliveryLocationNameQuery, GetDeliveryLocationNameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDeliveryLocationNameQuery, GetDeliveryLocationNameQueryVariables>(GetDeliveryLocationNameDocument, baseOptions);
      }
export function useGetDeliveryLocationNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDeliveryLocationNameQuery, GetDeliveryLocationNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDeliveryLocationNameQuery, GetDeliveryLocationNameQueryVariables>(GetDeliveryLocationNameDocument, baseOptions);
        }
export type GetDeliveryLocationNameQueryHookResult = ReturnType<typeof useGetDeliveryLocationNameQuery>;
export type GetDeliveryLocationNameLazyQueryHookResult = ReturnType<typeof useGetDeliveryLocationNameLazyQuery>;
export type GetDeliveryLocationNameQueryResult = ApolloReactCommon.QueryResult<GetDeliveryLocationNameQuery, GetDeliveryLocationNameQueryVariables>;
export const CreateDeliveryLocationDocument = gql`
    mutation createDeliveryLocation($input: DeliveryLocationInput!) {
  createDeliveryLoc(input: $input)
}
    `;
export type CreateDeliveryLocationMutationFn = ApolloReactCommon.MutationFunction<CreateDeliveryLocationMutation, CreateDeliveryLocationMutationVariables>;

/**
 * __useCreateDeliveryLocationMutation__
 *
 * To run a mutation, you first call `useCreateDeliveryLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeliveryLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeliveryLocationMutation, { data, loading, error }] = useCreateDeliveryLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDeliveryLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDeliveryLocationMutation, CreateDeliveryLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDeliveryLocationMutation, CreateDeliveryLocationMutationVariables>(CreateDeliveryLocationDocument, baseOptions);
      }
export type CreateDeliveryLocationMutationHookResult = ReturnType<typeof useCreateDeliveryLocationMutation>;
export type CreateDeliveryLocationMutationResult = ApolloReactCommon.MutationResult<CreateDeliveryLocationMutation>;
export type CreateDeliveryLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDeliveryLocationMutation, CreateDeliveryLocationMutationVariables>;
export const UpdateDeliveryLocationDocument = gql`
    mutation updateDeliveryLocation($input: DeliveryLocationInput!) {
  updateDeliveryLoc(input: $input)
}
    `;
export type UpdateDeliveryLocationMutationFn = ApolloReactCommon.MutationFunction<UpdateDeliveryLocationMutation, UpdateDeliveryLocationMutationVariables>;

/**
 * __useUpdateDeliveryLocationMutation__
 *
 * To run a mutation, you first call `useUpdateDeliveryLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeliveryLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeliveryLocationMutation, { data, loading, error }] = useUpdateDeliveryLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDeliveryLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDeliveryLocationMutation, UpdateDeliveryLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDeliveryLocationMutation, UpdateDeliveryLocationMutationVariables>(UpdateDeliveryLocationDocument, baseOptions);
      }
export type UpdateDeliveryLocationMutationHookResult = ReturnType<typeof useUpdateDeliveryLocationMutation>;
export type UpdateDeliveryLocationMutationResult = ApolloReactCommon.MutationResult<UpdateDeliveryLocationMutation>;
export type UpdateDeliveryLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDeliveryLocationMutation, UpdateDeliveryLocationMutationVariables>;
export const DeleteDeliveryLocationDocument = gql`
    mutation deleteDeliveryLocation($input: DeliveryLocationDeleteInput!) {
  deleteDeliveryLocation(input: $input)
}
    `;
export type DeleteDeliveryLocationMutationFn = ApolloReactCommon.MutationFunction<DeleteDeliveryLocationMutation, DeleteDeliveryLocationMutationVariables>;

/**
 * __useDeleteDeliveryLocationMutation__
 *
 * To run a mutation, you first call `useDeleteDeliveryLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeliveryLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeliveryLocationMutation, { data, loading, error }] = useDeleteDeliveryLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDeliveryLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDeliveryLocationMutation, DeleteDeliveryLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDeliveryLocationMutation, DeleteDeliveryLocationMutationVariables>(DeleteDeliveryLocationDocument, baseOptions);
      }
export type DeleteDeliveryLocationMutationHookResult = ReturnType<typeof useDeleteDeliveryLocationMutation>;
export type DeleteDeliveryLocationMutationResult = ApolloReactCommon.MutationResult<DeleteDeliveryLocationMutation>;
export type DeleteDeliveryLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDeliveryLocationMutation, DeleteDeliveryLocationMutationVariables>;
export const GetBankProfileDocument = gql`
    query getBankProfile($AccountID: String) {
  getBankProfile(AccountID: $AccountID) {
    BankProfileID
    Name
    Address
    ContactNo
    Email
  }
}
    `;

/**
 * __useGetBankProfileQuery__
 *
 * To run a query within a React component, call `useGetBankProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankProfileQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useGetBankProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBankProfileQuery, GetBankProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBankProfileQuery, GetBankProfileQueryVariables>(GetBankProfileDocument, baseOptions);
      }
export function useGetBankProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBankProfileQuery, GetBankProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBankProfileQuery, GetBankProfileQueryVariables>(GetBankProfileDocument, baseOptions);
        }
export type GetBankProfileQueryHookResult = ReturnType<typeof useGetBankProfileQuery>;
export type GetBankProfileLazyQueryHookResult = ReturnType<typeof useGetBankProfileLazyQuery>;
export type GetBankProfileQueryResult = ApolloReactCommon.QueryResult<GetBankProfileQuery, GetBankProfileQueryVariables>;
export const GetLocalBankDocument = gql`
    query getLocalBank($ID: String, $code: String, $orderByAsc: String, $orderByDesc: String) {
  getLocalBank(ID: $ID, code: $code, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ID
    name
    code
    address {
      city
      district
      state
      postCode
      country
    }
  }
}
    `;

/**
 * __useGetLocalBankQuery__
 *
 * To run a query within a React component, call `useGetLocalBankQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocalBankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocalBankQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      code: // value for 'code'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetLocalBankQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocalBankQuery, GetLocalBankQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLocalBankQuery, GetLocalBankQueryVariables>(GetLocalBankDocument, baseOptions);
      }
export function useGetLocalBankLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocalBankQuery, GetLocalBankQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLocalBankQuery, GetLocalBankQueryVariables>(GetLocalBankDocument, baseOptions);
        }
export type GetLocalBankQueryHookResult = ReturnType<typeof useGetLocalBankQuery>;
export type GetLocalBankLazyQueryHookResult = ReturnType<typeof useGetLocalBankLazyQuery>;
export type GetLocalBankQueryResult = ApolloReactCommon.QueryResult<GetLocalBankQuery, GetLocalBankQueryVariables>;
export const GetCompanyDocument = gql`
    query getCompany($CompanyID: String, $ParentCompanyID: String, $orderByAsc: String, $RecordStatus: RecordStatus) {
  getCompany(CompanyID: $CompanyID, ParentCompanyID: $ParentCompanyID, orderByAsc: $orderByAsc, RecordStatus: $RecordStatus) {
    CompanyID
    ParentCompanyID
    Name
    Code
    CompanyRegNo
    CompanyTax
    SSTNo
    GSTNo
    GSTExpiryDate
    Address {
      ID
      address
      address2
      siteName
      country
      city
      district
      state
      postCode
      postCodeName
      AddressType
    }
    ContactNo
    BaseCurrencyID
    Location {
      DeliveryLocationID
      Name
    }
  }
}
    `;

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ParentCompanyID: // value for 'ParentCompanyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      RecordStatus: // value for 'RecordStatus'
 *   },
 * });
 */
export function useGetCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, baseOptions);
      }
export function useGetCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, baseOptions);
        }
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>;
export type GetCompanyLazyQueryHookResult = ReturnType<typeof useGetCompanyLazyQuery>;
export type GetCompanyQueryResult = ApolloReactCommon.QueryResult<GetCompanyQuery, GetCompanyQueryVariables>;
export const GetParentCompanyDocument = gql`
    query getParentCompany {
  getParentCompany {
    CompanyID
    Name
  }
}
    `;

/**
 * __useGetParentCompanyQuery__
 *
 * To run a query within a React component, call `useGetParentCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetParentCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetParentCompanyQuery, GetParentCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetParentCompanyQuery, GetParentCompanyQueryVariables>(GetParentCompanyDocument, baseOptions);
      }
export function useGetParentCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetParentCompanyQuery, GetParentCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetParentCompanyQuery, GetParentCompanyQueryVariables>(GetParentCompanyDocument, baseOptions);
        }
export type GetParentCompanyQueryHookResult = ReturnType<typeof useGetParentCompanyQuery>;
export type GetParentCompanyLazyQueryHookResult = ReturnType<typeof useGetParentCompanyLazyQuery>;
export type GetParentCompanyQueryResult = ApolloReactCommon.QueryResult<GetParentCompanyQuery, GetParentCompanyQueryVariables>;
export const GetCompanyNameDocument = gql`
    query getCompanyName($CompanyID: String, $orderByAsc: String, $take: Float, $RecordStatus: RecordStatus) {
  getCompany(CompanyID: $CompanyID, orderByAsc: $orderByAsc, take: $take, RecordStatus: $RecordStatus) {
    CompanyID
    ParentCompanyID
    Name
    Code
    CompanyRegNo
    Address {
      ID
      address
      address2
      siteName
      country
      city
      district
      state
      postCode
      postCodeName
      AddressType
    }
    CompanyTax
    ContactNo
    GSTNo
    SSTNo
    RecordStatus
  }
}
    `;

/**
 * __useGetCompanyNameQuery__
 *
 * To run a query within a React component, call `useGetCompanyNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyNameQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      take: // value for 'take'
 *      RecordStatus: // value for 'RecordStatus'
 *   },
 * });
 */
export function useGetCompanyNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyNameQuery, GetCompanyNameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyNameQuery, GetCompanyNameQueryVariables>(GetCompanyNameDocument, baseOptions);
      }
export function useGetCompanyNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyNameQuery, GetCompanyNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyNameQuery, GetCompanyNameQueryVariables>(GetCompanyNameDocument, baseOptions);
        }
export type GetCompanyNameQueryHookResult = ReturnType<typeof useGetCompanyNameQuery>;
export type GetCompanyNameLazyQueryHookResult = ReturnType<typeof useGetCompanyNameLazyQuery>;
export type GetCompanyNameQueryResult = ApolloReactCommon.QueryResult<GetCompanyNameQuery, GetCompanyNameQueryVariables>;
export const GetAssignedCompanyDocument = gql`
    query getAssignedCompany {
  getAssignedCompanyByUser
}
    `;

/**
 * __useGetAssignedCompanyQuery__
 *
 * To run a query within a React component, call `useGetAssignedCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignedCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignedCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAssignedCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssignedCompanyQuery, GetAssignedCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAssignedCompanyQuery, GetAssignedCompanyQueryVariables>(GetAssignedCompanyDocument, baseOptions);
      }
export function useGetAssignedCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssignedCompanyQuery, GetAssignedCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAssignedCompanyQuery, GetAssignedCompanyQueryVariables>(GetAssignedCompanyDocument, baseOptions);
        }
export type GetAssignedCompanyQueryHookResult = ReturnType<typeof useGetAssignedCompanyQuery>;
export type GetAssignedCompanyLazyQueryHookResult = ReturnType<typeof useGetAssignedCompanyLazyQuery>;
export type GetAssignedCompanyQueryResult = ApolloReactCommon.QueryResult<GetAssignedCompanyQuery, GetAssignedCompanyQueryVariables>;
export const GetActiveCompanyCountDocument = gql`
    query getActiveCompanyCount {
  getActiveCompanyCount
}
    `;

/**
 * __useGetActiveCompanyCountQuery__
 *
 * To run a query within a React component, call `useGetActiveCompanyCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveCompanyCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveCompanyCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveCompanyCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetActiveCompanyCountQuery, GetActiveCompanyCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetActiveCompanyCountQuery, GetActiveCompanyCountQueryVariables>(GetActiveCompanyCountDocument, baseOptions);
      }
export function useGetActiveCompanyCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetActiveCompanyCountQuery, GetActiveCompanyCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetActiveCompanyCountQuery, GetActiveCompanyCountQueryVariables>(GetActiveCompanyCountDocument, baseOptions);
        }
export type GetActiveCompanyCountQueryHookResult = ReturnType<typeof useGetActiveCompanyCountQuery>;
export type GetActiveCompanyCountLazyQueryHookResult = ReturnType<typeof useGetActiveCompanyCountLazyQuery>;
export type GetActiveCompanyCountQueryResult = ApolloReactCommon.QueryResult<GetActiveCompanyCountQuery, GetActiveCompanyCountQueryVariables>;
export const GetCompanyNameWithAccountDocument = gql`
    query getCompanyNameWithAccount($CompanyID: String, $accountID: String!, $orderByAsc: String) {
  getCompanyNameWithAccount(CompanyID: $CompanyID, accountID: $accountID, orderByAsc: $orderByAsc)
}
    `;

/**
 * __useGetCompanyNameWithAccountQuery__
 *
 * To run a query within a React component, call `useGetCompanyNameWithAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyNameWithAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyNameWithAccountQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      accountID: // value for 'accountID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetCompanyNameWithAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyNameWithAccountQuery, GetCompanyNameWithAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyNameWithAccountQuery, GetCompanyNameWithAccountQueryVariables>(GetCompanyNameWithAccountDocument, baseOptions);
      }
export function useGetCompanyNameWithAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyNameWithAccountQuery, GetCompanyNameWithAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyNameWithAccountQuery, GetCompanyNameWithAccountQueryVariables>(GetCompanyNameWithAccountDocument, baseOptions);
        }
export type GetCompanyNameWithAccountQueryHookResult = ReturnType<typeof useGetCompanyNameWithAccountQuery>;
export type GetCompanyNameWithAccountLazyQueryHookResult = ReturnType<typeof useGetCompanyNameWithAccountLazyQuery>;
export type GetCompanyNameWithAccountQueryResult = ApolloReactCommon.QueryResult<GetCompanyNameWithAccountQuery, GetCompanyNameWithAccountQueryVariables>;
export const GetCostCentreDocument = gql`
    query getCostCentre($CompanyID: String, $CostCentreID: String, $MasterCOAID: String, $AccountID: String, $orderByAsc: String, $IsLastNode: Boolean, $IsDefault: Boolean, $ItemID: String) {
  getCostCentre(CompanyID: $CompanyID, CostCentreID: $CostCentreID, AccountID: $AccountID, MasterCOAID: $MasterCOAID, orderByAsc: $orderByAsc, IsLastNode: $IsLastNode, IsDefault: $IsDefault, ItemID: $ItemID) {
    CostCentreID
    Name
    CompanyID
    ParentCostCentreID
    Level
    Code
    MasterCode
    IsLastNode
    IsDefault
  }
}
    `;

/**
 * __useGetCostCentreQuery__
 *
 * To run a query within a React component, call `useGetCostCentreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCentreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCentreQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      CostCentreID: // value for 'CostCentreID'
 *      MasterCOAID: // value for 'MasterCOAID'
 *      AccountID: // value for 'AccountID'
 *      orderByAsc: // value for 'orderByAsc'
 *      IsLastNode: // value for 'IsLastNode'
 *      IsDefault: // value for 'IsDefault'
 *      ItemID: // value for 'ItemID'
 *   },
 * });
 */
export function useGetCostCentreQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCentreQuery, GetCostCentreQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCentreQuery, GetCostCentreQueryVariables>(GetCostCentreDocument, baseOptions);
      }
export function useGetCostCentreLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCentreQuery, GetCostCentreQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCentreQuery, GetCostCentreQueryVariables>(GetCostCentreDocument, baseOptions);
        }
export type GetCostCentreQueryHookResult = ReturnType<typeof useGetCostCentreQuery>;
export type GetCostCentreLazyQueryHookResult = ReturnType<typeof useGetCostCentreLazyQuery>;
export type GetCostCentreQueryResult = ApolloReactCommon.QueryResult<GetCostCentreQuery, GetCostCentreQueryVariables>;
export const GetCostCentreListingDocument = gql`
    query getCostCentreListing($CompanyID: String!) {
  getCostCentreListing(CompanyID: $CompanyID)
}
    `;

/**
 * __useGetCostCentreListingQuery__
 *
 * To run a query within a React component, call `useGetCostCentreListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCentreListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCentreListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetCostCentreListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCentreListingQuery, GetCostCentreListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCentreListingQuery, GetCostCentreListingQueryVariables>(GetCostCentreListingDocument, baseOptions);
      }
export function useGetCostCentreListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCentreListingQuery, GetCostCentreListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCentreListingQuery, GetCostCentreListingQueryVariables>(GetCostCentreListingDocument, baseOptions);
        }
export type GetCostCentreListingQueryHookResult = ReturnType<typeof useGetCostCentreListingQuery>;
export type GetCostCentreListingLazyQueryHookResult = ReturnType<typeof useGetCostCentreListingLazyQuery>;
export type GetCostCentreListingQueryResult = ApolloReactCommon.QueryResult<GetCostCentreListingQuery, GetCostCentreListingQueryVariables>;
export const GetCostCentreDefinitionAsgmtDocument = gql`
    query getCostCentreDefinitionAsgmt($CompanyID: String) {
  getCostCentreDefinitionAsgmt(CompanyID: $CompanyID) {
    CostCentreDefinitionAsgmtID
    CostCentreDefinitionHeaderID
    CompanyID
    CostCentreDefinitionHeader {
      Title
      CostCentreDefinitionDetail {
        Name
        Level
        IsLastNode
      }
    }
  }
}
    `;

/**
 * __useGetCostCentreDefinitionAsgmtQuery__
 *
 * To run a query within a React component, call `useGetCostCentreDefinitionAsgmtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCentreDefinitionAsgmtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCentreDefinitionAsgmtQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetCostCentreDefinitionAsgmtQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCentreDefinitionAsgmtQuery, GetCostCentreDefinitionAsgmtQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCentreDefinitionAsgmtQuery, GetCostCentreDefinitionAsgmtQueryVariables>(GetCostCentreDefinitionAsgmtDocument, baseOptions);
      }
export function useGetCostCentreDefinitionAsgmtLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCentreDefinitionAsgmtQuery, GetCostCentreDefinitionAsgmtQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCentreDefinitionAsgmtQuery, GetCostCentreDefinitionAsgmtQueryVariables>(GetCostCentreDefinitionAsgmtDocument, baseOptions);
        }
export type GetCostCentreDefinitionAsgmtQueryHookResult = ReturnType<typeof useGetCostCentreDefinitionAsgmtQuery>;
export type GetCostCentreDefinitionAsgmtLazyQueryHookResult = ReturnType<typeof useGetCostCentreDefinitionAsgmtLazyQuery>;
export type GetCostCentreDefinitionAsgmtQueryResult = ApolloReactCommon.QueryResult<GetCostCentreDefinitionAsgmtQuery, GetCostCentreDefinitionAsgmtQueryVariables>;
export const GetCostCentreDefinitionDetailDocument = gql`
    query getCostCentreDefinitionDetail($CompanyID: String) {
  getCostCentreDefinitionDetail(CompanyID: $CompanyID) {
    CostCentreDefinitionDetailID
    ParentCostCentreDefinitionDetailID
    CostCentreDefinitionHeaderID
    Name
    Level
  }
}
    `;

/**
 * __useGetCostCentreDefinitionDetailQuery__
 *
 * To run a query within a React component, call `useGetCostCentreDefinitionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCentreDefinitionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCentreDefinitionDetailQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetCostCentreDefinitionDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCentreDefinitionDetailQuery, GetCostCentreDefinitionDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCentreDefinitionDetailQuery, GetCostCentreDefinitionDetailQueryVariables>(GetCostCentreDefinitionDetailDocument, baseOptions);
      }
export function useGetCostCentreDefinitionDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCentreDefinitionDetailQuery, GetCostCentreDefinitionDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCentreDefinitionDetailQuery, GetCostCentreDefinitionDetailQueryVariables>(GetCostCentreDefinitionDetailDocument, baseOptions);
        }
export type GetCostCentreDefinitionDetailQueryHookResult = ReturnType<typeof useGetCostCentreDefinitionDetailQuery>;
export type GetCostCentreDefinitionDetailLazyQueryHookResult = ReturnType<typeof useGetCostCentreDefinitionDetailLazyQuery>;
export type GetCostCentreDefinitionDetailQueryResult = ApolloReactCommon.QueryResult<GetCostCentreDefinitionDetailQuery, GetCostCentreDefinitionDetailQueryVariables>;
export const GetNextLevelCcDefinitionDocument = gql`
    query getNextLevelCCDefinition($CompanyID: String!, $CostCentreID: String) {
  getNextLevelCCDefinition(CompanyID: $CompanyID, CostCentreID: $CostCentreID)
}
    `;

/**
 * __useGetNextLevelCcDefinitionQuery__
 *
 * To run a query within a React component, call `useGetNextLevelCcDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextLevelCcDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextLevelCcDefinitionQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      CostCentreID: // value for 'CostCentreID'
 *   },
 * });
 */
export function useGetNextLevelCcDefinitionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNextLevelCcDefinitionQuery, GetNextLevelCcDefinitionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNextLevelCcDefinitionQuery, GetNextLevelCcDefinitionQueryVariables>(GetNextLevelCcDefinitionDocument, baseOptions);
      }
export function useGetNextLevelCcDefinitionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNextLevelCcDefinitionQuery, GetNextLevelCcDefinitionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNextLevelCcDefinitionQuery, GetNextLevelCcDefinitionQueryVariables>(GetNextLevelCcDefinitionDocument, baseOptions);
        }
export type GetNextLevelCcDefinitionQueryHookResult = ReturnType<typeof useGetNextLevelCcDefinitionQuery>;
export type GetNextLevelCcDefinitionLazyQueryHookResult = ReturnType<typeof useGetNextLevelCcDefinitionLazyQuery>;
export type GetNextLevelCcDefinitionQueryResult = ApolloReactCommon.QueryResult<GetNextLevelCcDefinitionQuery, GetNextLevelCcDefinitionQueryVariables>;
export const GetParentCostCentreForTreeDocument = gql`
    query getParentCostCentreForTree($CostCentreArr: [String!]) {
  getParentCostCentreForTree(CostCentreArr: $CostCentreArr)
}
    `;

/**
 * __useGetParentCostCentreForTreeQuery__
 *
 * To run a query within a React component, call `useGetParentCostCentreForTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentCostCentreForTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentCostCentreForTreeQuery({
 *   variables: {
 *      CostCentreArr: // value for 'CostCentreArr'
 *   },
 * });
 */
export function useGetParentCostCentreForTreeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetParentCostCentreForTreeQuery, GetParentCostCentreForTreeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetParentCostCentreForTreeQuery, GetParentCostCentreForTreeQueryVariables>(GetParentCostCentreForTreeDocument, baseOptions);
      }
export function useGetParentCostCentreForTreeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetParentCostCentreForTreeQuery, GetParentCostCentreForTreeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetParentCostCentreForTreeQuery, GetParentCostCentreForTreeQueryVariables>(GetParentCostCentreForTreeDocument, baseOptions);
        }
export type GetParentCostCentreForTreeQueryHookResult = ReturnType<typeof useGetParentCostCentreForTreeQuery>;
export type GetParentCostCentreForTreeLazyQueryHookResult = ReturnType<typeof useGetParentCostCentreForTreeLazyQuery>;
export type GetParentCostCentreForTreeQueryResult = ApolloReactCommon.QueryResult<GetParentCostCentreForTreeQuery, GetParentCostCentreForTreeQueryVariables>;
export const SetDefaultCostCentreDocument = gql`
    mutation setDefaultCostCentre($CostCentreID: String!, $CompanyID: String!) {
  setDefaultCostCentre(CostCentreID: $CostCentreID, CompanyID: $CompanyID)
}
    `;
export type SetDefaultCostCentreMutationFn = ApolloReactCommon.MutationFunction<SetDefaultCostCentreMutation, SetDefaultCostCentreMutationVariables>;

/**
 * __useSetDefaultCostCentreMutation__
 *
 * To run a mutation, you first call `useSetDefaultCostCentreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultCostCentreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultCostCentreMutation, { data, loading, error }] = useSetDefaultCostCentreMutation({
 *   variables: {
 *      CostCentreID: // value for 'CostCentreID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useSetDefaultCostCentreMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetDefaultCostCentreMutation, SetDefaultCostCentreMutationVariables>) {
        return ApolloReactHooks.useMutation<SetDefaultCostCentreMutation, SetDefaultCostCentreMutationVariables>(SetDefaultCostCentreDocument, baseOptions);
      }
export type SetDefaultCostCentreMutationHookResult = ReturnType<typeof useSetDefaultCostCentreMutation>;
export type SetDefaultCostCentreMutationResult = ApolloReactCommon.MutationResult<SetDefaultCostCentreMutation>;
export type SetDefaultCostCentreMutationOptions = ApolloReactCommon.BaseMutationOptions<SetDefaultCostCentreMutation, SetDefaultCostCentreMutationVariables>;
export const GetCostCentreCodeDocument = gql`
    query getCostCentreCode($CompanyID: String, $IsLastNode: Boolean, $MasterCOAID: String, $orderByAsc: String, $IsDefault: Boolean, $AccountID: String) {
  getCostCentre(CompanyID: $CompanyID, MasterCOAID: $MasterCOAID, IsLastNode: $IsLastNode, orderByAsc: $orderByAsc, IsDefault: $IsDefault, AccountID: $AccountID) {
    CostCentreID
    Name
    Code
    IsDefault
  }
}
    `;

/**
 * __useGetCostCentreCodeQuery__
 *
 * To run a query within a React component, call `useGetCostCentreCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCentreCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCentreCodeQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      IsLastNode: // value for 'IsLastNode'
 *      MasterCOAID: // value for 'MasterCOAID'
 *      orderByAsc: // value for 'orderByAsc'
 *      IsDefault: // value for 'IsDefault'
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useGetCostCentreCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCentreCodeQuery, GetCostCentreCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCentreCodeQuery, GetCostCentreCodeQueryVariables>(GetCostCentreCodeDocument, baseOptions);
      }
export function useGetCostCentreCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCentreCodeQuery, GetCostCentreCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCentreCodeQuery, GetCostCentreCodeQueryVariables>(GetCostCentreCodeDocument, baseOptions);
        }
export type GetCostCentreCodeQueryHookResult = ReturnType<typeof useGetCostCentreCodeQuery>;
export type GetCostCentreCodeLazyQueryHookResult = ReturnType<typeof useGetCostCentreCodeLazyQuery>;
export type GetCostCentreCodeQueryResult = ApolloReactCommon.QueryResult<GetCostCentreCodeQuery, GetCostCentreCodeQueryVariables>;
export const GetMasterCoaDocument = gql`
    query getMasterCOA($AccountType: String, $IsLastNode: Boolean, $orderByAsc: String, $IsControl: Boolean, $AccountID: String) {
  getMasterCOA(AccountType: $AccountType, IsLastNode: $IsLastNode, orderByAsc: $orderByAsc, IsControl: $IsControl, AccountID: $AccountID) {
    MasterCOAID
    ParentMasterCOAID
    Name
    Code
    IsControl
    AccountType
    IsLastNode
    Level
    RecordStatus
  }
}
    `;

/**
 * __useGetMasterCoaQuery__
 *
 * To run a query within a React component, call `useGetMasterCoaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterCoaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterCoaQuery({
 *   variables: {
 *      AccountType: // value for 'AccountType'
 *      IsLastNode: // value for 'IsLastNode'
 *      orderByAsc: // value for 'orderByAsc'
 *      IsControl: // value for 'IsControl'
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useGetMasterCoaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMasterCoaQuery, GetMasterCoaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMasterCoaQuery, GetMasterCoaQueryVariables>(GetMasterCoaDocument, baseOptions);
      }
export function useGetMasterCoaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMasterCoaQuery, GetMasterCoaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMasterCoaQuery, GetMasterCoaQueryVariables>(GetMasterCoaDocument, baseOptions);
        }
export type GetMasterCoaQueryHookResult = ReturnType<typeof useGetMasterCoaQuery>;
export type GetMasterCoaLazyQueryHookResult = ReturnType<typeof useGetMasterCoaLazyQuery>;
export type GetMasterCoaQueryResult = ApolloReactCommon.QueryResult<GetMasterCoaQuery, GetMasterCoaQueryVariables>;
export const GetMasterCoaCheckingDocument = gql`
    query getMasterCOAChecking($IsLastNode: Boolean, $orderByAsc: String) {
  getMasterCOA(IsLastNode: $IsLastNode, orderByAsc: $orderByAsc) {
    MasterCOAID
    AccountType
  }
}
    `;

/**
 * __useGetMasterCoaCheckingQuery__
 *
 * To run a query within a React component, call `useGetMasterCoaCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterCoaCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterCoaCheckingQuery({
 *   variables: {
 *      IsLastNode: // value for 'IsLastNode'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetMasterCoaCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMasterCoaCheckingQuery, GetMasterCoaCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMasterCoaCheckingQuery, GetMasterCoaCheckingQueryVariables>(GetMasterCoaCheckingDocument, baseOptions);
      }
export function useGetMasterCoaCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMasterCoaCheckingQuery, GetMasterCoaCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMasterCoaCheckingQuery, GetMasterCoaCheckingQueryVariables>(GetMasterCoaCheckingDocument, baseOptions);
        }
export type GetMasterCoaCheckingQueryHookResult = ReturnType<typeof useGetMasterCoaCheckingQuery>;
export type GetMasterCoaCheckingLazyQueryHookResult = ReturnType<typeof useGetMasterCoaCheckingLazyQuery>;
export type GetMasterCoaCheckingQueryResult = ApolloReactCommon.QueryResult<GetMasterCoaCheckingQuery, GetMasterCoaCheckingQueryVariables>;
export const GetMasterCoaCodeDocument = gql`
    query getMasterCOACode($IsLastNode: Boolean, $orderByAsc: String) {
  getMasterCOA(IsLastNode: $IsLastNode, orderByAsc: $orderByAsc) {
    MasterCOAID
    Name
    Code
    AccountType
    IsLastNode
    IsControl
  }
}
    `;

/**
 * __useGetMasterCoaCodeQuery__
 *
 * To run a query within a React component, call `useGetMasterCoaCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterCoaCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterCoaCodeQuery({
 *   variables: {
 *      IsLastNode: // value for 'IsLastNode'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetMasterCoaCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMasterCoaCodeQuery, GetMasterCoaCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMasterCoaCodeQuery, GetMasterCoaCodeQueryVariables>(GetMasterCoaCodeDocument, baseOptions);
      }
export function useGetMasterCoaCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMasterCoaCodeQuery, GetMasterCoaCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMasterCoaCodeQuery, GetMasterCoaCodeQueryVariables>(GetMasterCoaCodeDocument, baseOptions);
        }
export type GetMasterCoaCodeQueryHookResult = ReturnType<typeof useGetMasterCoaCodeQuery>;
export type GetMasterCoaCodeLazyQueryHookResult = ReturnType<typeof useGetMasterCoaCodeLazyQuery>;
export type GetMasterCoaCodeQueryResult = ApolloReactCommon.QueryResult<GetMasterCoaCodeQuery, GetMasterCoaCodeQueryVariables>;
export const GetStockItemDocument = gql`
    query getStockItem($StockItemID: String, $StockCategoryID: String, $orderByAsc: String) {
  getStockItem(StockItemID: $StockItemID, StockCategoryID: $StockCategoryID, orderByAsc: $orderByAsc) {
    createdTs
    createdBy
    modTs
    modBy
    AccountID
    StockItemID
    StockCategoryID
    RecordStatus
    Name
    ABCClass
    ShelfLife
    StandardCost
    UOMID
  }
}
    `;

/**
 * __useGetStockItemQuery__
 *
 * To run a query within a React component, call `useGetStockItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockItemQuery({
 *   variables: {
 *      StockItemID: // value for 'StockItemID'
 *      StockCategoryID: // value for 'StockCategoryID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetStockItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockItemQuery, GetStockItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockItemQuery, GetStockItemQueryVariables>(GetStockItemDocument, baseOptions);
      }
export function useGetStockItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockItemQuery, GetStockItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockItemQuery, GetStockItemQueryVariables>(GetStockItemDocument, baseOptions);
        }
export type GetStockItemQueryHookResult = ReturnType<typeof useGetStockItemQuery>;
export type GetStockItemLazyQueryHookResult = ReturnType<typeof useGetStockItemLazyQuery>;
export type GetStockItemQueryResult = ApolloReactCommon.QueryResult<GetStockItemQuery, GetStockItemQueryVariables>;
export const GetCreditorProfileWithAccountDocument = gql`
    query getCreditorProfileWithAccount($accountID: String!) {
  getCreditorProfileWithAccount(accountID: $accountID) {
    CreditorAccountID
    CreditorTypeID
    CompanyRegNo
    ContactNo
    AccountID
    Email
    CreditTerm
    TaxSchemeID
    Region
    GSTRegNo
    TaxIdentificationNo
    Remark
    BankName
    BankAccountNo
    CreditorTypeEnum
    CompanyName
    modTs
    Address
    CreditorStatus
    CreditorContact {
      CreditorContactID
      CreditorAccountID
      Name
      ContactNo
      Designation
      Email
      IsDefault
    }
    CreditorDirector {
      CreditorDirectorID
      CreditorAccountID
      Name
      ContactNo
      Email
      IdentityNo
      IdentityType
    }
  }
}
    `;

/**
 * __useGetCreditorProfileWithAccountQuery__
 *
 * To run a query within a React component, call `useGetCreditorProfileWithAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorProfileWithAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorProfileWithAccountQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useGetCreditorProfileWithAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorProfileWithAccountQuery, GetCreditorProfileWithAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorProfileWithAccountQuery, GetCreditorProfileWithAccountQueryVariables>(GetCreditorProfileWithAccountDocument, baseOptions);
      }
export function useGetCreditorProfileWithAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorProfileWithAccountQuery, GetCreditorProfileWithAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorProfileWithAccountQuery, GetCreditorProfileWithAccountQueryVariables>(GetCreditorProfileWithAccountDocument, baseOptions);
        }
export type GetCreditorProfileWithAccountQueryHookResult = ReturnType<typeof useGetCreditorProfileWithAccountQuery>;
export type GetCreditorProfileWithAccountLazyQueryHookResult = ReturnType<typeof useGetCreditorProfileWithAccountLazyQuery>;
export type GetCreditorProfileWithAccountQueryResult = ApolloReactCommon.QueryResult<GetCreditorProfileWithAccountQuery, GetCreditorProfileWithAccountQueryVariables>;
export const GetCreditorAccountDocument = gql`
    query getCreditorAccount($orderByAsc: String, $CreditorAccountID: String, $CreditorTypeID: String, $CreditorTypeEnum: CreditorType) {
  getCreditorAccount(orderByAsc: $orderByAsc, CreditorAccountID: $CreditorAccountID, CreditorTypeEnum: $CreditorTypeEnum, CreditorTypeID: $CreditorTypeID) {
    CreditorAccountID
    CreditorTypeID
    CreditorType {
      Code
      Description
    }
    CompanyRegNo
    ContactNo
    DocNoList
    Email
    CreditTerm
    TaxSchemeID
    TaxIdentificationNo
    TaxScheme {
      Code
      Description
    }
    Region
    GSTRegNo
    SSTRegNo
    Remark
    BankName
    BankAccountNo
    CreditorTypeEnum
    PayeeName
    SWIFTCode
    CompanyName
    modTs
    Address
    CreditorStatus
    CreditorContact {
      CreditorContactID
      CreditorAccountID
      Name
      ContactNo
      Designation
      Email
      IsDefault
    }
    CreditorDirector {
      CreditorDirectorID
      CreditorAccountID
      Name
      ContactNo
      Email
      IdentityNo
      IdentityType
    }
    einvoice_msic_code_id
    einvoice_classification_code_id
    einvoice_foreign_supplier
    einvoice_self_billed
    einvoice_type
    einvoice_TIN
    IsCompany
    ClassificationCode
    MSICCode
  }
}
    `;

/**
 * __useGetCreditorAccountQuery__
 *
 * To run a query within a React component, call `useGetCreditorAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorAccountQuery({
 *   variables: {
 *      orderByAsc: // value for 'orderByAsc'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      CreditorTypeID: // value for 'CreditorTypeID'
 *      CreditorTypeEnum: // value for 'CreditorTypeEnum'
 *   },
 * });
 */
export function useGetCreditorAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorAccountQuery, GetCreditorAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorAccountQuery, GetCreditorAccountQueryVariables>(GetCreditorAccountDocument, baseOptions);
      }
export function useGetCreditorAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorAccountQuery, GetCreditorAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorAccountQuery, GetCreditorAccountQueryVariables>(GetCreditorAccountDocument, baseOptions);
        }
export type GetCreditorAccountQueryHookResult = ReturnType<typeof useGetCreditorAccountQuery>;
export type GetCreditorAccountLazyQueryHookResult = ReturnType<typeof useGetCreditorAccountLazyQuery>;
export type GetCreditorAccountQueryResult = ApolloReactCommon.QueryResult<GetCreditorAccountQuery, GetCreditorAccountQueryVariables>;
export const GetCreditorAccountListingDocument = gql`
    query getCreditorAccountListing($orderByAsc: String, $CreditorAccountID: String, $CreditorTypeID: String, $CreditorTypeEnum: CreditorType, $skip: Float, $take: Float, $searchValue: String) {
  getCreditorAccount(orderByAsc: $orderByAsc, CreditorAccountID: $CreditorAccountID, CreditorTypeEnum: $CreditorTypeEnum, CreditorTypeID: $CreditorTypeID, skip: $skip, take: $take, searchValue: $searchValue) {
    CreditorAccountID
    CreditorTypeID
    CreditorType {
      Code
      Description
    }
    CompanyRegNo
    ContactNo
    DocNoList
    Email
    CreditTerm
    TaxSchemeID
    TaxIdentificationNo
    TaxScheme {
      Code
      Description
    }
    Region
    GSTRegNo
    SSTRegNo
    Remark
    BankName
    BankAccountNo
    CreditorTypeEnum
    PayeeName
    SWIFTCode
    CompanyName
    einvoice_foreign_supplier
    einvoice_self_billed
    einvoice_msic_code_id
    einvoice_classification_code_id
    ClassificationCode
    MSICCode
    einvoice_type
    einvoice_TIN
    IsCompany
    modTs
    Address
    CreditorStatus
    CreditorContact {
      CreditorContactID
      CreditorAccountID
      Name
      ContactNo
      Designation
      Email
      IsDefault
    }
    CreditorDirector {
      CreditorDirectorID
      CreditorAccountID
      Name
      ContactNo
      Email
      IdentityNo
      IdentityType
    }
  }
  getCreditorAccountCount(CreditorAccountID: $CreditorAccountID, CreditorTypeEnum: $CreditorTypeEnum)
}
    `;

/**
 * __useGetCreditorAccountListingQuery__
 *
 * To run a query within a React component, call `useGetCreditorAccountListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorAccountListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorAccountListingQuery({
 *   variables: {
 *      orderByAsc: // value for 'orderByAsc'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      CreditorTypeID: // value for 'CreditorTypeID'
 *      CreditorTypeEnum: // value for 'CreditorTypeEnum'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetCreditorAccountListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorAccountListingQuery, GetCreditorAccountListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorAccountListingQuery, GetCreditorAccountListingQueryVariables>(GetCreditorAccountListingDocument, baseOptions);
      }
export function useGetCreditorAccountListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorAccountListingQuery, GetCreditorAccountListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorAccountListingQuery, GetCreditorAccountListingQueryVariables>(GetCreditorAccountListingDocument, baseOptions);
        }
export type GetCreditorAccountListingQueryHookResult = ReturnType<typeof useGetCreditorAccountListingQuery>;
export type GetCreditorAccountListingLazyQueryHookResult = ReturnType<typeof useGetCreditorAccountListingLazyQuery>;
export type GetCreditorAccountListingQueryResult = ApolloReactCommon.QueryResult<GetCreditorAccountListingQuery, GetCreditorAccountListingQueryVariables>;
export const GetCreditorAccountDetailDocument = gql`
    query getCreditorAccountDetail($CreditorAccountID: String, $CreditorTypeID: String, $CreditorTypeEnum: CreditorType) {
  getCreditorAccount(CreditorAccountID: $CreditorAccountID, CreditorTypeEnum: $CreditorTypeEnum, CreditorTypeID: $CreditorTypeID) {
    CreditorAccountID
    CreditorTypeID
    CreditorType {
      Code
      Description
    }
    CompanyRegNo
    ContactNo
    DocNoList
    Email
    CreditTerm
    TaxSchemeID
    TaxIdentificationNo
    TaxScheme {
      Code
      Description
    }
    Region
    GSTRegNo
    SSTRegNo
    Remark
    BankName
    BankAccountNo
    CreditorTypeEnum
    PayeeName
    SWIFTCode
    CompanyName
    modTs
    Address
    CreditorStatus
    CreditorContact {
      CreditorContactID
      CreditorAccountID
      Name
      ContactNo
      Designation
      Email
      IsDefault
    }
    CreditorDirector {
      CreditorDirectorID
      CreditorAccountID
      Name
      ContactNo
      Email
      IdentityNo
      IdentityType
    }
    einvoice_msic_code_id
    einvoice_classification_code_id
    einvoice_foreign_supplier
    einvoice_self_billed
    einvoice_type
    einvoice_TIN
    IsCompany
    ClassificationCode
    MSICCode
  }
  getCreditorContact(CreditorAccountID: $CreditorAccountID) {
    CreditorContactID
    CreditorAccountID
    Name
    ContactNo
    Designation
    Email
    IsDefault
  }
  getCreditorDirector(CreditorAccountID: $CreditorAccountID) {
    CreditorDirectorID
    CreditorAccountID
    Name
    ContactNo
    Email
    IdentityNo
    IdentityType
  }
}
    `;

/**
 * __useGetCreditorAccountDetailQuery__
 *
 * To run a query within a React component, call `useGetCreditorAccountDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorAccountDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorAccountDetailQuery({
 *   variables: {
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      CreditorTypeID: // value for 'CreditorTypeID'
 *      CreditorTypeEnum: // value for 'CreditorTypeEnum'
 *   },
 * });
 */
export function useGetCreditorAccountDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorAccountDetailQuery, GetCreditorAccountDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorAccountDetailQuery, GetCreditorAccountDetailQueryVariables>(GetCreditorAccountDetailDocument, baseOptions);
      }
export function useGetCreditorAccountDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorAccountDetailQuery, GetCreditorAccountDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorAccountDetailQuery, GetCreditorAccountDetailQueryVariables>(GetCreditorAccountDetailDocument, baseOptions);
        }
export type GetCreditorAccountDetailQueryHookResult = ReturnType<typeof useGetCreditorAccountDetailQuery>;
export type GetCreditorAccountDetailLazyQueryHookResult = ReturnType<typeof useGetCreditorAccountDetailLazyQuery>;
export type GetCreditorAccountDetailQueryResult = ApolloReactCommon.QueryResult<GetCreditorAccountDetailQuery, GetCreditorAccountDetailQueryVariables>;
export const CreateCreditorAccountDocument = gql`
    mutation CreateCreditorAccount($input: CreditorAccountInput!, $contactItem: [CreditorContactInput!], $directorItem: [CreditorDirectorInput!]) {
  CreateCreditorAccount(input: $input, contactItem: $contactItem, directorItem: $directorItem)
}
    `;
export type CreateCreditorAccountMutationFn = ApolloReactCommon.MutationFunction<CreateCreditorAccountMutation, CreateCreditorAccountMutationVariables>;

/**
 * __useCreateCreditorAccountMutation__
 *
 * To run a mutation, you first call `useCreateCreditorAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCreditorAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCreditorAccountMutation, { data, loading, error }] = useCreateCreditorAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *      contactItem: // value for 'contactItem'
 *      directorItem: // value for 'directorItem'
 *   },
 * });
 */
export function useCreateCreditorAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCreditorAccountMutation, CreateCreditorAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCreditorAccountMutation, CreateCreditorAccountMutationVariables>(CreateCreditorAccountDocument, baseOptions);
      }
export type CreateCreditorAccountMutationHookResult = ReturnType<typeof useCreateCreditorAccountMutation>;
export type CreateCreditorAccountMutationResult = ApolloReactCommon.MutationResult<CreateCreditorAccountMutation>;
export type CreateCreditorAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCreditorAccountMutation, CreateCreditorAccountMutationVariables>;
export const UpdateCreditorAccountDocument = gql`
    mutation updateCreditorAccount($input: CreditorAccountInput!) {
  updateCreditorAccount(input: $input) {
    CreditorAccountID
  }
}
    `;
export type UpdateCreditorAccountMutationFn = ApolloReactCommon.MutationFunction<UpdateCreditorAccountMutation, UpdateCreditorAccountMutationVariables>;

/**
 * __useUpdateCreditorAccountMutation__
 *
 * To run a mutation, you first call `useUpdateCreditorAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreditorAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreditorAccountMutation, { data, loading, error }] = useUpdateCreditorAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCreditorAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCreditorAccountMutation, UpdateCreditorAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCreditorAccountMutation, UpdateCreditorAccountMutationVariables>(UpdateCreditorAccountDocument, baseOptions);
      }
export type UpdateCreditorAccountMutationHookResult = ReturnType<typeof useUpdateCreditorAccountMutation>;
export type UpdateCreditorAccountMutationResult = ApolloReactCommon.MutationResult<UpdateCreditorAccountMutation>;
export type UpdateCreditorAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCreditorAccountMutation, UpdateCreditorAccountMutationVariables>;
export const UpdateCreditorAccountWithItemDocument = gql`
    mutation UpdateCreditorAccountWithItem($input: CreditorAccountInput!, $contactItem: [CreditorContactInput!], $directorItem: [CreditorDirectorInput!]) {
  UpdateCreditorAccountWithItem(input: $input, contactItem: $contactItem, directorItem: $directorItem)
}
    `;
export type UpdateCreditorAccountWithItemMutationFn = ApolloReactCommon.MutationFunction<UpdateCreditorAccountWithItemMutation, UpdateCreditorAccountWithItemMutationVariables>;

/**
 * __useUpdateCreditorAccountWithItemMutation__
 *
 * To run a mutation, you first call `useUpdateCreditorAccountWithItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreditorAccountWithItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreditorAccountWithItemMutation, { data, loading, error }] = useUpdateCreditorAccountWithItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *      contactItem: // value for 'contactItem'
 *      directorItem: // value for 'directorItem'
 *   },
 * });
 */
export function useUpdateCreditorAccountWithItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCreditorAccountWithItemMutation, UpdateCreditorAccountWithItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCreditorAccountWithItemMutation, UpdateCreditorAccountWithItemMutationVariables>(UpdateCreditorAccountWithItemDocument, baseOptions);
      }
export type UpdateCreditorAccountWithItemMutationHookResult = ReturnType<typeof useUpdateCreditorAccountWithItemMutation>;
export type UpdateCreditorAccountWithItemMutationResult = ApolloReactCommon.MutationResult<UpdateCreditorAccountWithItemMutation>;
export type UpdateCreditorAccountWithItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCreditorAccountWithItemMutation, UpdateCreditorAccountWithItemMutationVariables>;
export const GetCreditorAccountCompanyAssignmentDocument = gql`
    query getCreditorAccountCompanyAssignment($CreditorAccountCompanyAssignmentID: String, $CompanyID: String, $CreditorAccountID: String, $orderByAsc: String, $searchValue: String, $take: Float, $skip: Float) {
  getCreditorAccountCompanyAssignment(CreditorAccountCompanyAssignmentID: $CreditorAccountCompanyAssignmentID, CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID, orderByAsc: $orderByAsc, searchValue: $searchValue, take: $take, skip: $skip) {
    CreditorAccountCompanyAssignmentID
    CreditorAccountID
    CompanyID
    CreditorAccount {
      createdTs
      modTs
      CreditorAccountID
      TotalOutstandingAmt(CompanyID: $CompanyID)
      CreditorTypeID
      CompanyRegNo
      DocNoList
      ContactNo
      Email
      CreditTerm
      TaxSchemeID
      Region
      GSTRegNo
      SSTRegNo
      Remark
      BankName
      TaxIdentificationNo
      BankAccountNo
      CreditorTypeEnum
      CompanyName
      modTs
      Address
      CreditorStatus
      CreditorContact {
        CreditorContactID
        CreditorAccountID
        Name
        ContactNo
        Designation
        Email
        IsDefault
      }
      CreditorDirector {
        CreditorDirectorID
        CreditorAccountID
        Name
        ContactNo
        Email
        IdentityNo
        IdentityType
      }
    }
  }
  getCreditorAccountCompanyAssignmentCount(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID)
}
    `;

/**
 * __useGetCreditorAccountCompanyAssignmentQuery__
 *
 * To run a query within a React component, call `useGetCreditorAccountCompanyAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorAccountCompanyAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorAccountCompanyAssignmentQuery({
 *   variables: {
 *      CreditorAccountCompanyAssignmentID: // value for 'CreditorAccountCompanyAssignmentID'
 *      CompanyID: // value for 'CompanyID'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      orderByAsc: // value for 'orderByAsc'
 *      searchValue: // value for 'searchValue'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetCreditorAccountCompanyAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorAccountCompanyAssignmentQuery, GetCreditorAccountCompanyAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorAccountCompanyAssignmentQuery, GetCreditorAccountCompanyAssignmentQueryVariables>(GetCreditorAccountCompanyAssignmentDocument, baseOptions);
      }
export function useGetCreditorAccountCompanyAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorAccountCompanyAssignmentQuery, GetCreditorAccountCompanyAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorAccountCompanyAssignmentQuery, GetCreditorAccountCompanyAssignmentQueryVariables>(GetCreditorAccountCompanyAssignmentDocument, baseOptions);
        }
export type GetCreditorAccountCompanyAssignmentQueryHookResult = ReturnType<typeof useGetCreditorAccountCompanyAssignmentQuery>;
export type GetCreditorAccountCompanyAssignmentLazyQueryHookResult = ReturnType<typeof useGetCreditorAccountCompanyAssignmentLazyQuery>;
export type GetCreditorAccountCompanyAssignmentQueryResult = ApolloReactCommon.QueryResult<GetCreditorAccountCompanyAssignmentQuery, GetCreditorAccountCompanyAssignmentQueryVariables>;
export const AssignCreditorAccountCompanyDocument = gql`
    mutation assignCreditorAccountCompany($input: CreditorAccountCompanyAssignmentInput!) {
  AssignCreditorAccountComapany(input: $input)
}
    `;
export type AssignCreditorAccountCompanyMutationFn = ApolloReactCommon.MutationFunction<AssignCreditorAccountCompanyMutation, AssignCreditorAccountCompanyMutationVariables>;

/**
 * __useAssignCreditorAccountCompanyMutation__
 *
 * To run a mutation, you first call `useAssignCreditorAccountCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignCreditorAccountCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignCreditorAccountCompanyMutation, { data, loading, error }] = useAssignCreditorAccountCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignCreditorAccountCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignCreditorAccountCompanyMutation, AssignCreditorAccountCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignCreditorAccountCompanyMutation, AssignCreditorAccountCompanyMutationVariables>(AssignCreditorAccountCompanyDocument, baseOptions);
      }
export type AssignCreditorAccountCompanyMutationHookResult = ReturnType<typeof useAssignCreditorAccountCompanyMutation>;
export type AssignCreditorAccountCompanyMutationResult = ApolloReactCommon.MutationResult<AssignCreditorAccountCompanyMutation>;
export type AssignCreditorAccountCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignCreditorAccountCompanyMutation, AssignCreditorAccountCompanyMutationVariables>;
export const UnassignCreditorAccountCompanyDocument = gql`
    mutation unassignCreditorAccountCompany($input: CreditorAccountCompanyAssignmentInput!) {
  UnassignCreditorAccountComapany(input: $input)
}
    `;
export type UnassignCreditorAccountCompanyMutationFn = ApolloReactCommon.MutationFunction<UnassignCreditorAccountCompanyMutation, UnassignCreditorAccountCompanyMutationVariables>;

/**
 * __useUnassignCreditorAccountCompanyMutation__
 *
 * To run a mutation, you first call `useUnassignCreditorAccountCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignCreditorAccountCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignCreditorAccountCompanyMutation, { data, loading, error }] = useUnassignCreditorAccountCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnassignCreditorAccountCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnassignCreditorAccountCompanyMutation, UnassignCreditorAccountCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UnassignCreditorAccountCompanyMutation, UnassignCreditorAccountCompanyMutationVariables>(UnassignCreditorAccountCompanyDocument, baseOptions);
      }
export type UnassignCreditorAccountCompanyMutationHookResult = ReturnType<typeof useUnassignCreditorAccountCompanyMutation>;
export type UnassignCreditorAccountCompanyMutationResult = ApolloReactCommon.MutationResult<UnassignCreditorAccountCompanyMutation>;
export type UnassignCreditorAccountCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UnassignCreditorAccountCompanyMutation, UnassignCreditorAccountCompanyMutationVariables>;
export const CreditorAccountTotalDueDocument = gql`
    query CreditorAccountTotalDue($CompanyID: String!) {
  CreditorAccountTotalDue(CompanyID: $CompanyID)
}
    `;

/**
 * __useCreditorAccountTotalDueQuery__
 *
 * To run a query within a React component, call `useCreditorAccountTotalDueQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditorAccountTotalDueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditorAccountTotalDueQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useCreditorAccountTotalDueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CreditorAccountTotalDueQuery, CreditorAccountTotalDueQueryVariables>) {
        return ApolloReactHooks.useQuery<CreditorAccountTotalDueQuery, CreditorAccountTotalDueQueryVariables>(CreditorAccountTotalDueDocument, baseOptions);
      }
export function useCreditorAccountTotalDueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CreditorAccountTotalDueQuery, CreditorAccountTotalDueQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CreditorAccountTotalDueQuery, CreditorAccountTotalDueQueryVariables>(CreditorAccountTotalDueDocument, baseOptions);
        }
export type CreditorAccountTotalDueQueryHookResult = ReturnType<typeof useCreditorAccountTotalDueQuery>;
export type CreditorAccountTotalDueLazyQueryHookResult = ReturnType<typeof useCreditorAccountTotalDueLazyQuery>;
export type CreditorAccountTotalDueQueryResult = ApolloReactCommon.QueryResult<CreditorAccountTotalDueQuery, CreditorAccountTotalDueQueryVariables>;
export const GetCreditorAccountCompanyAssignmentNameDocument = gql`
    query getCreditorAccountCompanyAssignmentName($CreditorAccountCompanyAssignmentID: String, $CompanyID: String, $CreditorAccountID: String, $orderByAsc: String) {
  getCreditorAccountCompanyAssignment(CreditorAccountCompanyAssignmentID: $CreditorAccountCompanyAssignmentID, CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID, orderByAsc: $orderByAsc) {
    CreditorAccountID
    CompanyID
    CreditorAccount {
      CreditTerm
      CreditorAccountID
      CompanyName
      DocNoList
      CreditorContact {
        Name
        ContactNo
      }
    }
  }
}
    `;

/**
 * __useGetCreditorAccountCompanyAssignmentNameQuery__
 *
 * To run a query within a React component, call `useGetCreditorAccountCompanyAssignmentNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorAccountCompanyAssignmentNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorAccountCompanyAssignmentNameQuery({
 *   variables: {
 *      CreditorAccountCompanyAssignmentID: // value for 'CreditorAccountCompanyAssignmentID'
 *      CompanyID: // value for 'CompanyID'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetCreditorAccountCompanyAssignmentNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorAccountCompanyAssignmentNameQuery, GetCreditorAccountCompanyAssignmentNameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorAccountCompanyAssignmentNameQuery, GetCreditorAccountCompanyAssignmentNameQueryVariables>(GetCreditorAccountCompanyAssignmentNameDocument, baseOptions);
      }
export function useGetCreditorAccountCompanyAssignmentNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorAccountCompanyAssignmentNameQuery, GetCreditorAccountCompanyAssignmentNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorAccountCompanyAssignmentNameQuery, GetCreditorAccountCompanyAssignmentNameQueryVariables>(GetCreditorAccountCompanyAssignmentNameDocument, baseOptions);
        }
export type GetCreditorAccountCompanyAssignmentNameQueryHookResult = ReturnType<typeof useGetCreditorAccountCompanyAssignmentNameQuery>;
export type GetCreditorAccountCompanyAssignmentNameLazyQueryHookResult = ReturnType<typeof useGetCreditorAccountCompanyAssignmentNameLazyQuery>;
export type GetCreditorAccountCompanyAssignmentNameQueryResult = ApolloReactCommon.QueryResult<GetCreditorAccountCompanyAssignmentNameQuery, GetCreditorAccountCompanyAssignmentNameQueryVariables>;
export const GetCreditorTypeDocument = gql`
    query getCreditorType($CreditorTypeID: String, $CreditorType: CreditorType) {
  getCreditorType(CreditorTypeID: $CreditorTypeID, CreditorType: $CreditorType) {
    CreditorTypeID
    MasterCOAID
    Description
    CreditorType
    AccrualCOAID
    Code
    MasterCOA {
      Name
      Code
    }
  }
}
    `;

/**
 * __useGetCreditorTypeQuery__
 *
 * To run a query within a React component, call `useGetCreditorTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorTypeQuery({
 *   variables: {
 *      CreditorTypeID: // value for 'CreditorTypeID'
 *      CreditorType: // value for 'CreditorType'
 *   },
 * });
 */
export function useGetCreditorTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorTypeQuery, GetCreditorTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorTypeQuery, GetCreditorTypeQueryVariables>(GetCreditorTypeDocument, baseOptions);
      }
export function useGetCreditorTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorTypeQuery, GetCreditorTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorTypeQuery, GetCreditorTypeQueryVariables>(GetCreditorTypeDocument, baseOptions);
        }
export type GetCreditorTypeQueryHookResult = ReturnType<typeof useGetCreditorTypeQuery>;
export type GetCreditorTypeLazyQueryHookResult = ReturnType<typeof useGetCreditorTypeLazyQuery>;
export type GetCreditorTypeQueryResult = ApolloReactCommon.QueryResult<GetCreditorTypeQuery, GetCreditorTypeQueryVariables>;
export const GetCreditorTypeWithAccountDocument = gql`
    query getCreditorTypeWithAccount($accountID: String!, $CompanyID: String!, $orderByAsc: String, $orderByDesc: String) {
  getCreditorTypeWithAccount(accountID: $accountID, CompanyID: $CompanyID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    CreditorTypeID
    MasterCOAID
    Description
    CreditorType
    Code
    CreditorAccount {
      CreditorAccountID
      CreditorTypeID
      CompanyRegNo
      CompanyName
      ContactNo
    }
    MasterCOA {
      Name
      Code
    }
  }
}
    `;

/**
 * __useGetCreditorTypeWithAccountQuery__
 *
 * To run a query within a React component, call `useGetCreditorTypeWithAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorTypeWithAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorTypeWithAccountQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      CompanyID: // value for 'CompanyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetCreditorTypeWithAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorTypeWithAccountQuery, GetCreditorTypeWithAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorTypeWithAccountQuery, GetCreditorTypeWithAccountQueryVariables>(GetCreditorTypeWithAccountDocument, baseOptions);
      }
export function useGetCreditorTypeWithAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorTypeWithAccountQuery, GetCreditorTypeWithAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorTypeWithAccountQuery, GetCreditorTypeWithAccountQueryVariables>(GetCreditorTypeWithAccountDocument, baseOptions);
        }
export type GetCreditorTypeWithAccountQueryHookResult = ReturnType<typeof useGetCreditorTypeWithAccountQuery>;
export type GetCreditorTypeWithAccountLazyQueryHookResult = ReturnType<typeof useGetCreditorTypeWithAccountLazyQuery>;
export type GetCreditorTypeWithAccountQueryResult = ApolloReactCommon.QueryResult<GetCreditorTypeWithAccountQuery, GetCreditorTypeWithAccountQueryVariables>;
export const GetMasterCoaWithAccountDocument = gql`
    query getMasterCOAWithAccount($take: Float, $orderByDesc: String, $orderByAsc: String, $accountID: String!) {
  getMasterCOAWithAccount(take: $take, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, accountID: $accountID) {
    MasterCOAID
    ParentMasterCOAID
    Name
    Code
    IsControl
    AccountType
    IsLastNode
    Level
    EntityCOA {
      EntityCOAID
      CompanyID
    }
  }
}
    `;

/**
 * __useGetMasterCoaWithAccountQuery__
 *
 * To run a query within a React component, call `useGetMasterCoaWithAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterCoaWithAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterCoaWithAccountQuery({
 *   variables: {
 *      take: // value for 'take'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useGetMasterCoaWithAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMasterCoaWithAccountQuery, GetMasterCoaWithAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMasterCoaWithAccountQuery, GetMasterCoaWithAccountQueryVariables>(GetMasterCoaWithAccountDocument, baseOptions);
      }
export function useGetMasterCoaWithAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMasterCoaWithAccountQuery, GetMasterCoaWithAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMasterCoaWithAccountQuery, GetMasterCoaWithAccountQueryVariables>(GetMasterCoaWithAccountDocument, baseOptions);
        }
export type GetMasterCoaWithAccountQueryHookResult = ReturnType<typeof useGetMasterCoaWithAccountQuery>;
export type GetMasterCoaWithAccountLazyQueryHookResult = ReturnType<typeof useGetMasterCoaWithAccountLazyQuery>;
export type GetMasterCoaWithAccountQueryResult = ApolloReactCommon.QueryResult<GetMasterCoaWithAccountQuery, GetMasterCoaWithAccountQueryVariables>;
export const CreateCreditorTypeWithAccountDocument = gql`
    mutation createCreditorTypeWithAccount($input: CreditorTypeInput!) {
  createCreditorType(input: $input) {
    CreditorTypeID
  }
}
    `;
export type CreateCreditorTypeWithAccountMutationFn = ApolloReactCommon.MutationFunction<CreateCreditorTypeWithAccountMutation, CreateCreditorTypeWithAccountMutationVariables>;

/**
 * __useCreateCreditorTypeWithAccountMutation__
 *
 * To run a mutation, you first call `useCreateCreditorTypeWithAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCreditorTypeWithAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCreditorTypeWithAccountMutation, { data, loading, error }] = useCreateCreditorTypeWithAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCreditorTypeWithAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCreditorTypeWithAccountMutation, CreateCreditorTypeWithAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCreditorTypeWithAccountMutation, CreateCreditorTypeWithAccountMutationVariables>(CreateCreditorTypeWithAccountDocument, baseOptions);
      }
export type CreateCreditorTypeWithAccountMutationHookResult = ReturnType<typeof useCreateCreditorTypeWithAccountMutation>;
export type CreateCreditorTypeWithAccountMutationResult = ApolloReactCommon.MutationResult<CreateCreditorTypeWithAccountMutation>;
export type CreateCreditorTypeWithAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCreditorTypeWithAccountMutation, CreateCreditorTypeWithAccountMutationVariables>;
export const UpdateCreditorTypeWithAccountDocument = gql`
    mutation updateCreditorTypeWithAccount($input: CreditorTypeInput!) {
  updateCreditorType(input: $input) {
    CreditorTypeID
  }
}
    `;
export type UpdateCreditorTypeWithAccountMutationFn = ApolloReactCommon.MutationFunction<UpdateCreditorTypeWithAccountMutation, UpdateCreditorTypeWithAccountMutationVariables>;

/**
 * __useUpdateCreditorTypeWithAccountMutation__
 *
 * To run a mutation, you first call `useUpdateCreditorTypeWithAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreditorTypeWithAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreditorTypeWithAccountMutation, { data, loading, error }] = useUpdateCreditorTypeWithAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCreditorTypeWithAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCreditorTypeWithAccountMutation, UpdateCreditorTypeWithAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCreditorTypeWithAccountMutation, UpdateCreditorTypeWithAccountMutationVariables>(UpdateCreditorTypeWithAccountDocument, baseOptions);
      }
export type UpdateCreditorTypeWithAccountMutationHookResult = ReturnType<typeof useUpdateCreditorTypeWithAccountMutation>;
export type UpdateCreditorTypeWithAccountMutationResult = ApolloReactCommon.MutationResult<UpdateCreditorTypeWithAccountMutation>;
export type UpdateCreditorTypeWithAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCreditorTypeWithAccountMutation, UpdateCreditorTypeWithAccountMutationVariables>;
export const DeleteCreditorTypeWithAccountDocument = gql`
    mutation deleteCreditorTypeWithAccount($input: CreditorTypeDeleteInput!) {
  deleteCreditorType(input: $input)
}
    `;
export type DeleteCreditorTypeWithAccountMutationFn = ApolloReactCommon.MutationFunction<DeleteCreditorTypeWithAccountMutation, DeleteCreditorTypeWithAccountMutationVariables>;

/**
 * __useDeleteCreditorTypeWithAccountMutation__
 *
 * To run a mutation, you first call `useDeleteCreditorTypeWithAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCreditorTypeWithAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCreditorTypeWithAccountMutation, { data, loading, error }] = useDeleteCreditorTypeWithAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCreditorTypeWithAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCreditorTypeWithAccountMutation, DeleteCreditorTypeWithAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCreditorTypeWithAccountMutation, DeleteCreditorTypeWithAccountMutationVariables>(DeleteCreditorTypeWithAccountDocument, baseOptions);
      }
export type DeleteCreditorTypeWithAccountMutationHookResult = ReturnType<typeof useDeleteCreditorTypeWithAccountMutation>;
export type DeleteCreditorTypeWithAccountMutationResult = ApolloReactCommon.MutationResult<DeleteCreditorTypeWithAccountMutation>;
export type DeleteCreditorTypeWithAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCreditorTypeWithAccountMutation, DeleteCreditorTypeWithAccountMutationVariables>;
export const CreateCreditorTypeDocument = gql`
    mutation createCreditorType($input: CreditorTypeInput!) {
  createCreditorType(input: $input) {
    CreditorTypeID
  }
}
    `;
export type CreateCreditorTypeMutationFn = ApolloReactCommon.MutationFunction<CreateCreditorTypeMutation, CreateCreditorTypeMutationVariables>;

/**
 * __useCreateCreditorTypeMutation__
 *
 * To run a mutation, you first call `useCreateCreditorTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCreditorTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCreditorTypeMutation, { data, loading, error }] = useCreateCreditorTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCreditorTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCreditorTypeMutation, CreateCreditorTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCreditorTypeMutation, CreateCreditorTypeMutationVariables>(CreateCreditorTypeDocument, baseOptions);
      }
export type CreateCreditorTypeMutationHookResult = ReturnType<typeof useCreateCreditorTypeMutation>;
export type CreateCreditorTypeMutationResult = ApolloReactCommon.MutationResult<CreateCreditorTypeMutation>;
export type CreateCreditorTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCreditorTypeMutation, CreateCreditorTypeMutationVariables>;
export const UpdateCreditorTypeDocument = gql`
    mutation updateCreditorType($input: CreditorTypeInput!) {
  updateCreditorType(input: $input) {
    CreditorTypeID
  }
}
    `;
export type UpdateCreditorTypeMutationFn = ApolloReactCommon.MutationFunction<UpdateCreditorTypeMutation, UpdateCreditorTypeMutationVariables>;

/**
 * __useUpdateCreditorTypeMutation__
 *
 * To run a mutation, you first call `useUpdateCreditorTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreditorTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreditorTypeMutation, { data, loading, error }] = useUpdateCreditorTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCreditorTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCreditorTypeMutation, UpdateCreditorTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCreditorTypeMutation, UpdateCreditorTypeMutationVariables>(UpdateCreditorTypeDocument, baseOptions);
      }
export type UpdateCreditorTypeMutationHookResult = ReturnType<typeof useUpdateCreditorTypeMutation>;
export type UpdateCreditorTypeMutationResult = ApolloReactCommon.MutationResult<UpdateCreditorTypeMutation>;
export type UpdateCreditorTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCreditorTypeMutation, UpdateCreditorTypeMutationVariables>;
export const DeleteCreditorTypeDocument = gql`
    mutation deleteCreditorType($input: CreditorTypeDeleteInput!) {
  deleteCreditorType(input: $input)
}
    `;
export type DeleteCreditorTypeMutationFn = ApolloReactCommon.MutationFunction<DeleteCreditorTypeMutation, DeleteCreditorTypeMutationVariables>;

/**
 * __useDeleteCreditorTypeMutation__
 *
 * To run a mutation, you first call `useDeleteCreditorTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCreditorTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCreditorTypeMutation, { data, loading, error }] = useDeleteCreditorTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCreditorTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCreditorTypeMutation, DeleteCreditorTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCreditorTypeMutation, DeleteCreditorTypeMutationVariables>(DeleteCreditorTypeDocument, baseOptions);
      }
export type DeleteCreditorTypeMutationHookResult = ReturnType<typeof useDeleteCreditorTypeMutation>;
export type DeleteCreditorTypeMutationResult = ApolloReactCommon.MutationResult<DeleteCreditorTypeMutation>;
export type DeleteCreditorTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCreditorTypeMutation, DeleteCreditorTypeMutationVariables>;
export const GetTaxSchemeDocument = gql`
    query getTaxScheme($TaxSchemeID: String, $Code: String, $AccTaxClass: String, $RecordStatus: RecordStatus, $orderByAsc: String) {
  getTaxScheme(TaxSchemeID: $TaxSchemeID, Code: $Code, AccTaxClass: $AccTaxClass, RecordStatus: $RecordStatus, orderByAsc: $orderByAsc) {
    TaxSchemeID
    RecordStatus
    Code
    Description
    AccTaxCategory
    AccTaxClass
    RecordStatus
    MasterCOAID
    TaxEffective {
      Date
      Rate
    }
    TaxType {
      TaxTypeID
      Code
      Description
      RecordStatus
      TaxScheme {
        TaxSchemeID
        Code
        Description
      }
    }
    LatestTax {
      Rate
      Date
    }
  }
}
    `;

/**
 * __useGetTaxSchemeQuery__
 *
 * To run a query within a React component, call `useGetTaxSchemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxSchemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxSchemeQuery({
 *   variables: {
 *      TaxSchemeID: // value for 'TaxSchemeID'
 *      Code: // value for 'Code'
 *      AccTaxClass: // value for 'AccTaxClass'
 *      RecordStatus: // value for 'RecordStatus'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetTaxSchemeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxSchemeQuery, GetTaxSchemeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxSchemeQuery, GetTaxSchemeQueryVariables>(GetTaxSchemeDocument, baseOptions);
      }
export function useGetTaxSchemeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxSchemeQuery, GetTaxSchemeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxSchemeQuery, GetTaxSchemeQueryVariables>(GetTaxSchemeDocument, baseOptions);
        }
export type GetTaxSchemeQueryHookResult = ReturnType<typeof useGetTaxSchemeQuery>;
export type GetTaxSchemeLazyQueryHookResult = ReturnType<typeof useGetTaxSchemeLazyQuery>;
export type GetTaxSchemeQueryResult = ApolloReactCommon.QueryResult<GetTaxSchemeQuery, GetTaxSchemeQueryVariables>;
export const GetTaxSchemeCodeDocument = gql`
    query getTaxSchemeCode($TaxSchemeID: String, $Code: String, $AccTaxClass: String, $RecordStatus: RecordStatus, $orderByAsc: String) {
  getTaxScheme(TaxSchemeID: $TaxSchemeID, Code: $Code, AccTaxClass: $AccTaxClass, RecordStatus: $RecordStatus, orderByAsc: $orderByAsc) {
    TaxSchemeID
    RecordStatus
    Code
    Description
    AccTaxCategory
    AccTaxClass
    RecordStatus
    MasterCOAID
    TaxEffective {
      Date
      Rate
    }
    TaxType {
      TaxTypeID
      Code
      Description
      RecordStatus
      TaxScheme {
        TaxSchemeID
        Code
        Description
      }
    }
    LatestTax {
      Rate
      Date
    }
  }
}
    `;

/**
 * __useGetTaxSchemeCodeQuery__
 *
 * To run a query within a React component, call `useGetTaxSchemeCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxSchemeCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxSchemeCodeQuery({
 *   variables: {
 *      TaxSchemeID: // value for 'TaxSchemeID'
 *      Code: // value for 'Code'
 *      AccTaxClass: // value for 'AccTaxClass'
 *      RecordStatus: // value for 'RecordStatus'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetTaxSchemeCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxSchemeCodeQuery, GetTaxSchemeCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxSchemeCodeQuery, GetTaxSchemeCodeQueryVariables>(GetTaxSchemeCodeDocument, baseOptions);
      }
export function useGetTaxSchemeCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxSchemeCodeQuery, GetTaxSchemeCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxSchemeCodeQuery, GetTaxSchemeCodeQueryVariables>(GetTaxSchemeCodeDocument, baseOptions);
        }
export type GetTaxSchemeCodeQueryHookResult = ReturnType<typeof useGetTaxSchemeCodeQuery>;
export type GetTaxSchemeCodeLazyQueryHookResult = ReturnType<typeof useGetTaxSchemeCodeLazyQuery>;
export type GetTaxSchemeCodeQueryResult = ApolloReactCommon.QueryResult<GetTaxSchemeCodeQuery, GetTaxSchemeCodeQueryVariables>;
export const GetUomDocument = gql`
    query getUOM($UOMID: String, $orderByAsc: String, $RecordStatus: RecordStatus) {
  getUOM(UOMID: $UOMID, orderByAsc: $orderByAsc, RecordStatus: $RecordStatus) {
    UOMID
    Name
    Code
    RecordStatus
  }
}
    `;

/**
 * __useGetUomQuery__
 *
 * To run a query within a React component, call `useGetUomQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomQuery({
 *   variables: {
 *      UOMID: // value for 'UOMID'
 *      orderByAsc: // value for 'orderByAsc'
 *      RecordStatus: // value for 'RecordStatus'
 *   },
 * });
 */
export function useGetUomQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomQuery, GetUomQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomQuery, GetUomQueryVariables>(GetUomDocument, baseOptions);
      }
export function useGetUomLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomQuery, GetUomQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomQuery, GetUomQueryVariables>(GetUomDocument, baseOptions);
        }
export type GetUomQueryHookResult = ReturnType<typeof useGetUomQuery>;
export type GetUomLazyQueryHookResult = ReturnType<typeof useGetUomLazyQuery>;
export type GetUomQueryResult = ApolloReactCommon.QueryResult<GetUomQuery, GetUomQueryVariables>;
export const CreateUomDocument = gql`
    mutation createUOM($input: UOMInput!) {
  createUOM(input: $input) {
    UOMID
  }
}
    `;
export type CreateUomMutationFn = ApolloReactCommon.MutationFunction<CreateUomMutation, CreateUomMutationVariables>;

/**
 * __useCreateUomMutation__
 *
 * To run a mutation, you first call `useCreateUomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUomMutation, { data, loading, error }] = useCreateUomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUomMutation, CreateUomMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUomMutation, CreateUomMutationVariables>(CreateUomDocument, baseOptions);
      }
export type CreateUomMutationHookResult = ReturnType<typeof useCreateUomMutation>;
export type CreateUomMutationResult = ApolloReactCommon.MutationResult<CreateUomMutation>;
export type CreateUomMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUomMutation, CreateUomMutationVariables>;
export const UpdateUomDocument = gql`
    mutation updateUOM($input: UOMInput!) {
  updateUOM(input: $input) {
    UOMID
    Name
    Code
  }
}
    `;
export type UpdateUomMutationFn = ApolloReactCommon.MutationFunction<UpdateUomMutation, UpdateUomMutationVariables>;

/**
 * __useUpdateUomMutation__
 *
 * To run a mutation, you first call `useUpdateUomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUomMutation, { data, loading, error }] = useUpdateUomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUomMutation, UpdateUomMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUomMutation, UpdateUomMutationVariables>(UpdateUomDocument, baseOptions);
      }
export type UpdateUomMutationHookResult = ReturnType<typeof useUpdateUomMutation>;
export type UpdateUomMutationResult = ApolloReactCommon.MutationResult<UpdateUomMutation>;
export type UpdateUomMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUomMutation, UpdateUomMutationVariables>;
export const DeleteUomDocument = gql`
    mutation deleteUOM($input: UOMDeleteInput!) {
  deleteUOM(input: $input)
}
    `;
export type DeleteUomMutationFn = ApolloReactCommon.MutationFunction<DeleteUomMutation, DeleteUomMutationVariables>;

/**
 * __useDeleteUomMutation__
 *
 * To run a mutation, you first call `useDeleteUomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUomMutation, { data, loading, error }] = useDeleteUomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUomMutation, DeleteUomMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUomMutation, DeleteUomMutationVariables>(DeleteUomDocument, baseOptions);
      }
export type DeleteUomMutationHookResult = ReturnType<typeof useDeleteUomMutation>;
export type DeleteUomMutationResult = ApolloReactCommon.MutationResult<DeleteUomMutation>;
export type DeleteUomMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUomMutation, DeleteUomMutationVariables>;
export const GetUomExchangeDocument = gql`
    query getUOMExchange($FromUomID: String, $ToUomID: String, $orderByAsc: String, $orderByDesc: String) {
  getUOMExchange(FromUomID: $FromUomID, ToUomID: $ToUomID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ID
    FromUomID
    ToUomID
    FromAmt
    ToAmt
    ExchangeRate
    UomLog
  }
}
    `;

/**
 * __useGetUomExchangeQuery__
 *
 * To run a query within a React component, call `useGetUomExchangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomExchangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomExchangeQuery({
 *   variables: {
 *      FromUomID: // value for 'FromUomID'
 *      ToUomID: // value for 'ToUomID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetUomExchangeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomExchangeQuery, GetUomExchangeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomExchangeQuery, GetUomExchangeQueryVariables>(GetUomExchangeDocument, baseOptions);
      }
export function useGetUomExchangeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomExchangeQuery, GetUomExchangeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomExchangeQuery, GetUomExchangeQueryVariables>(GetUomExchangeDocument, baseOptions);
        }
export type GetUomExchangeQueryHookResult = ReturnType<typeof useGetUomExchangeQuery>;
export type GetUomExchangeLazyQueryHookResult = ReturnType<typeof useGetUomExchangeLazyQuery>;
export type GetUomExchangeQueryResult = ApolloReactCommon.QueryResult<GetUomExchangeQuery, GetUomExchangeQueryVariables>;
export const GetUomExchangeDataDocument = gql`
    query getUomExchangeData {
  getUomExchangeData
}
    `;

/**
 * __useGetUomExchangeDataQuery__
 *
 * To run a query within a React component, call `useGetUomExchangeDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomExchangeDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomExchangeDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUomExchangeDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomExchangeDataQuery, GetUomExchangeDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomExchangeDataQuery, GetUomExchangeDataQueryVariables>(GetUomExchangeDataDocument, baseOptions);
      }
export function useGetUomExchangeDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomExchangeDataQuery, GetUomExchangeDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomExchangeDataQuery, GetUomExchangeDataQueryVariables>(GetUomExchangeDataDocument, baseOptions);
        }
export type GetUomExchangeDataQueryHookResult = ReturnType<typeof useGetUomExchangeDataQuery>;
export type GetUomExchangeDataLazyQueryHookResult = ReturnType<typeof useGetUomExchangeDataLazyQuery>;
export type GetUomExchangeDataQueryResult = ApolloReactCommon.QueryResult<GetUomExchangeDataQuery, GetUomExchangeDataQueryVariables>;
export const GetUomExchangeListDocument = gql`
    query getUomExchangeList($UOMID: String!) {
  getUOMExchangeList(UOMID: $UOMID)
}
    `;

/**
 * __useGetUomExchangeListQuery__
 *
 * To run a query within a React component, call `useGetUomExchangeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomExchangeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomExchangeListQuery({
 *   variables: {
 *      UOMID: // value for 'UOMID'
 *   },
 * });
 */
export function useGetUomExchangeListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>(GetUomExchangeListDocument, baseOptions);
      }
export function useGetUomExchangeListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>(GetUomExchangeListDocument, baseOptions);
        }
export type GetUomExchangeListQueryHookResult = ReturnType<typeof useGetUomExchangeListQuery>;
export type GetUomExchangeListLazyQueryHookResult = ReturnType<typeof useGetUomExchangeListLazyQuery>;
export type GetUomExchangeListQueryResult = ApolloReactCommon.QueryResult<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>;
export const CreateUomExchangeDocument = gql`
    mutation createUOMExchange($input: UomExchangeInput!) {
  createUOMExchange(input: $input)
}
    `;
export type CreateUomExchangeMutationFn = ApolloReactCommon.MutationFunction<CreateUomExchangeMutation, CreateUomExchangeMutationVariables>;

/**
 * __useCreateUomExchangeMutation__
 *
 * To run a mutation, you first call `useCreateUomExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUomExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUomExchangeMutation, { data, loading, error }] = useCreateUomExchangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUomExchangeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUomExchangeMutation, CreateUomExchangeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUomExchangeMutation, CreateUomExchangeMutationVariables>(CreateUomExchangeDocument, baseOptions);
      }
export type CreateUomExchangeMutationHookResult = ReturnType<typeof useCreateUomExchangeMutation>;
export type CreateUomExchangeMutationResult = ApolloReactCommon.MutationResult<CreateUomExchangeMutation>;
export type CreateUomExchangeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUomExchangeMutation, CreateUomExchangeMutationVariables>;
export const UpdateUomExchangeDocument = gql`
    mutation updateUOMExchange($input: UomExchangeInput!) {
  updateUOMExchange(input: $input)
}
    `;
export type UpdateUomExchangeMutationFn = ApolloReactCommon.MutationFunction<UpdateUomExchangeMutation, UpdateUomExchangeMutationVariables>;

/**
 * __useUpdateUomExchangeMutation__
 *
 * To run a mutation, you first call `useUpdateUomExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUomExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUomExchangeMutation, { data, loading, error }] = useUpdateUomExchangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUomExchangeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUomExchangeMutation, UpdateUomExchangeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUomExchangeMutation, UpdateUomExchangeMutationVariables>(UpdateUomExchangeDocument, baseOptions);
      }
export type UpdateUomExchangeMutationHookResult = ReturnType<typeof useUpdateUomExchangeMutation>;
export type UpdateUomExchangeMutationResult = ApolloReactCommon.MutationResult<UpdateUomExchangeMutation>;
export type UpdateUomExchangeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUomExchangeMutation, UpdateUomExchangeMutationVariables>;
export const DeleteUomExchangeDocument = gql`
    mutation deleteUOMExchange($ID: String!) {
  deleteUOMExchange(ID: $ID)
}
    `;
export type DeleteUomExchangeMutationFn = ApolloReactCommon.MutationFunction<DeleteUomExchangeMutation, DeleteUomExchangeMutationVariables>;

/**
 * __useDeleteUomExchangeMutation__
 *
 * To run a mutation, you first call `useDeleteUomExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUomExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUomExchangeMutation, { data, loading, error }] = useDeleteUomExchangeMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteUomExchangeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUomExchangeMutation, DeleteUomExchangeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUomExchangeMutation, DeleteUomExchangeMutationVariables>(DeleteUomExchangeDocument, baseOptions);
      }
export type DeleteUomExchangeMutationHookResult = ReturnType<typeof useDeleteUomExchangeMutation>;
export type DeleteUomExchangeMutationResult = ApolloReactCommon.MutationResult<DeleteUomExchangeMutation>;
export type DeleteUomExchangeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUomExchangeMutation, DeleteUomExchangeMutationVariables>;
export const DocumentListingDocument = gql`
    query DocumentListing($refID: String, $refTable: String, $description: String) {
  DocumentListing(refID: $refID, refTable: $refTable, description: $description) {
    AttachmentID
    fileName
    fileURL
    mediaType
    createdTs
    description
    refID
  }
}
    `;

/**
 * __useDocumentListingQuery__
 *
 * To run a query within a React component, call `useDocumentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentListingQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *      refTable: // value for 'refTable'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useDocumentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DocumentListingQuery, DocumentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DocumentListingQuery, DocumentListingQueryVariables>(DocumentListingDocument, baseOptions);
      }
export function useDocumentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentListingQuery, DocumentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DocumentListingQuery, DocumentListingQueryVariables>(DocumentListingDocument, baseOptions);
        }
export type DocumentListingQueryHookResult = ReturnType<typeof useDocumentListingQuery>;
export type DocumentListingLazyQueryHookResult = ReturnType<typeof useDocumentListingLazyQuery>;
export type DocumentListingQueryResult = ApolloReactCommon.QueryResult<DocumentListingQuery, DocumentListingQueryVariables>;
export const GetShareAttachmentDocument = gql`
    mutation getShareAttachment($file: Upload!, $CompanyID: String!) {
  getShareAttachment(file: $file, CompanyID: $CompanyID) {
    fileName
    fileURL
    mediaType
    createdTs
    description
  }
}
    `;
export type GetShareAttachmentMutationFn = ApolloReactCommon.MutationFunction<GetShareAttachmentMutation, GetShareAttachmentMutationVariables>;

/**
 * __useGetShareAttachmentMutation__
 *
 * To run a mutation, you first call `useGetShareAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetShareAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getShareAttachmentMutation, { data, loading, error }] = useGetShareAttachmentMutation({
 *   variables: {
 *      file: // value for 'file'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetShareAttachmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GetShareAttachmentMutation, GetShareAttachmentMutationVariables>) {
        return ApolloReactHooks.useMutation<GetShareAttachmentMutation, GetShareAttachmentMutationVariables>(GetShareAttachmentDocument, baseOptions);
      }
export type GetShareAttachmentMutationHookResult = ReturnType<typeof useGetShareAttachmentMutation>;
export type GetShareAttachmentMutationResult = ApolloReactCommon.MutationResult<GetShareAttachmentMutation>;
export type GetShareAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<GetShareAttachmentMutation, GetShareAttachmentMutationVariables>;
export const GetFavoritePrHeaderDocument = gql`
    query getFavoritePRHeader($UserID: String, $CompanyID: String, $FavoritePRHeaderID: String) {
  getFavoritePRHeader(UserID: $UserID, CompanyID: $CompanyID, FavoritePRHeaderID: $FavoritePRHeaderID) {
    createdBy
    FavoritePRHeaderID
    Name
    RequestedBy
    DeliveryLocationID
    DeliveryLocation {
      DeliveryLocationID
      Name
      Address
      ContactNo
    }
    ContactPerson
    Instruction
    ContactNo
    FavoritePRItem {
      FavoritePRHeaderID
      FavoritePRItemID
      ItemID
      Item {
        ItemID
        Name
        Description
        UOMID
      }
      UOMID
      UOM {
        UOMID
        Name
        Code
      }
      DeliveryLocationID
      DeliveryLocation {
        DeliveryLocationID
        Name
        Address
      }
      WarehouseID
      Warehouse {
        Name
        Code
        Address
      }
      CostCentreID
      CostCentre {
        CostCentreID
        Name
        Code
      }
      Quantity
      OutstandingQty
      Remark
    }
  }
}
    `;

/**
 * __useGetFavoritePrHeaderQuery__
 *
 * To run a query within a React component, call `useGetFavoritePrHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoritePrHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoritePrHeaderQuery({
 *   variables: {
 *      UserID: // value for 'UserID'
 *      CompanyID: // value for 'CompanyID'
 *      FavoritePRHeaderID: // value for 'FavoritePRHeaderID'
 *   },
 * });
 */
export function useGetFavoritePrHeaderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFavoritePrHeaderQuery, GetFavoritePrHeaderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFavoritePrHeaderQuery, GetFavoritePrHeaderQueryVariables>(GetFavoritePrHeaderDocument, baseOptions);
      }
export function useGetFavoritePrHeaderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFavoritePrHeaderQuery, GetFavoritePrHeaderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFavoritePrHeaderQuery, GetFavoritePrHeaderQueryVariables>(GetFavoritePrHeaderDocument, baseOptions);
        }
export type GetFavoritePrHeaderQueryHookResult = ReturnType<typeof useGetFavoritePrHeaderQuery>;
export type GetFavoritePrHeaderLazyQueryHookResult = ReturnType<typeof useGetFavoritePrHeaderLazyQuery>;
export type GetFavoritePrHeaderQueryResult = ApolloReactCommon.QueryResult<GetFavoritePrHeaderQuery, GetFavoritePrHeaderQueryVariables>;
export const CreateFavoritePrDocument = gql`
    mutation createFavoritePR($FavoritePRInput: FavoritePRInput!, $FavoritePRItemInput: [FavoritePRItemInput!]!) {
  createFavoritePR(FavoritePRInput: $FavoritePRInput, FavoritePRItemInput: $FavoritePRItemInput)
}
    `;
export type CreateFavoritePrMutationFn = ApolloReactCommon.MutationFunction<CreateFavoritePrMutation, CreateFavoritePrMutationVariables>;

/**
 * __useCreateFavoritePrMutation__
 *
 * To run a mutation, you first call `useCreateFavoritePrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFavoritePrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFavoritePrMutation, { data, loading, error }] = useCreateFavoritePrMutation({
 *   variables: {
 *      FavoritePRInput: // value for 'FavoritePRInput'
 *      FavoritePRItemInput: // value for 'FavoritePRItemInput'
 *   },
 * });
 */
export function useCreateFavoritePrMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFavoritePrMutation, CreateFavoritePrMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFavoritePrMutation, CreateFavoritePrMutationVariables>(CreateFavoritePrDocument, baseOptions);
      }
export type CreateFavoritePrMutationHookResult = ReturnType<typeof useCreateFavoritePrMutation>;
export type CreateFavoritePrMutationResult = ApolloReactCommon.MutationResult<CreateFavoritePrMutation>;
export type CreateFavoritePrMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFavoritePrMutation, CreateFavoritePrMutationVariables>;
export const DeleteFavoritePrDocument = gql`
    mutation deleteFavoritePR($FavoritePRHeaderID: String!) {
  deleteFavoritePR(FavoritePRHeaderID: $FavoritePRHeaderID)
}
    `;
export type DeleteFavoritePrMutationFn = ApolloReactCommon.MutationFunction<DeleteFavoritePrMutation, DeleteFavoritePrMutationVariables>;

/**
 * __useDeleteFavoritePrMutation__
 *
 * To run a mutation, you first call `useDeleteFavoritePrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFavoritePrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFavoritePrMutation, { data, loading, error }] = useDeleteFavoritePrMutation({
 *   variables: {
 *      FavoritePRHeaderID: // value for 'FavoritePRHeaderID'
 *   },
 * });
 */
export function useDeleteFavoritePrMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFavoritePrMutation, DeleteFavoritePrMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteFavoritePrMutation, DeleteFavoritePrMutationVariables>(DeleteFavoritePrDocument, baseOptions);
      }
export type DeleteFavoritePrMutationHookResult = ReturnType<typeof useDeleteFavoritePrMutation>;
export type DeleteFavoritePrMutationResult = ApolloReactCommon.MutationResult<DeleteFavoritePrMutation>;
export type DeleteFavoritePrMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFavoritePrMutation, DeleteFavoritePrMutationVariables>;
export const GetGeneralGrtNsDocument = gql`
    query getGeneralGRTNs($CompanyID: String, $SupplierID: String, $DeliveryLocationID: String, $WarehouseID: String) {
  getGeneralGRTNs(CompanyID: $CompanyID, SupplierID: $SupplierID, DeliveryLocationID: $DeliveryLocationID, WarehouseID: $WarehouseID) {
    createdTs
    createdBy
    modTs
    modBy
    GRTNHeaderID
    AccountID
    SupplierID
    DeliveryLocationID
    Supplier {
      CompanyName
      CompanyRegNo
      GSTRegNo
      Address
      CreditTerm
      Email
      CreditorContact {
        Name
        ContactNo
        Email
      }
    }
    DeliveryLocationID
    CompanyID
    DeliveryLocation {
      Name
      Address
      ContactNo
      Latitude
      Longitude
      MainLocation
    }
    WarehouseID
    Warehouse {
      Name
      Code
      Address
    }
    ApprovalStatus
    RecordStatus
    DocDate
    DocNo
    RefNo
    Description
    TransactionDate
    GRTNHeaderItem {
      GRTNItemID
      DOItemID
      DOItem {
        DOItemID
        DOHeaderID
        POItemID
        POItem {
          POHeader {
            DocNo
          }
          POHeaderID
          POItemID
          PRItemID
          UnitPrice
          BaseAmt
          TaxAmt
          TotalAmt
          Remarks
          OrderedQty
          OutstandingQty
          CostCentreID
          CostCentre {
            CostCentreID
            Name
            Code
          }
          UOMID
          Item {
            ItemID
            Name
            UOM {
              UOMID
              Name
              Code
            }
            VariancePerc
          }
        }
        UpToDateQty
        AcceptedQty
        ReturnedQty
        DOHeader {
          DocNo
          DOHeaderID
          DoNo
          Description
        }
      }
      ReturnedDoItem {
        DOHeaderID
        DOItemID
        UpToDateQty
      }
      OutstandingQty
      ReturnedQty
      ReturnedAmt
      Replacement
      GRTNHeaderID
      GRTNHeader {
        DocNo
        DocDate
        RefNo
        Description
      }
    }
  }
}
    `;

/**
 * __useGetGeneralGrtNsQuery__
 *
 * To run a query within a React component, call `useGetGeneralGrtNsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralGrtNsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralGrtNsQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      SupplierID: // value for 'SupplierID'
 *      DeliveryLocationID: // value for 'DeliveryLocationID'
 *      WarehouseID: // value for 'WarehouseID'
 *   },
 * });
 */
export function useGetGeneralGrtNsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralGrtNsQuery, GetGeneralGrtNsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralGrtNsQuery, GetGeneralGrtNsQueryVariables>(GetGeneralGrtNsDocument, baseOptions);
      }
export function useGetGeneralGrtNsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralGrtNsQuery, GetGeneralGrtNsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralGrtNsQuery, GetGeneralGrtNsQueryVariables>(GetGeneralGrtNsDocument, baseOptions);
        }
export type GetGeneralGrtNsQueryHookResult = ReturnType<typeof useGetGeneralGrtNsQuery>;
export type GetGeneralGrtNsLazyQueryHookResult = ReturnType<typeof useGetGeneralGrtNsLazyQuery>;
export type GetGeneralGrtNsQueryResult = ApolloReactCommon.QueryResult<GetGeneralGrtNsQuery, GetGeneralGrtNsQueryVariables>;
export const GetGeneralPOsDocument = gql`
    query getGeneralPOs($CompanyID: String, $SupplierID: String, $DeliveryLocationID: String, $DOHeaderID: String) {
  getGeneralPOs(CompanyID: $CompanyID, SupplierID: $SupplierID, DeliveryLocationID: $DeliveryLocationID, DOHeaderID: $DOHeaderID) {
    POHeaderID
    AccountID
    SupplierID
    DeliveryLocationID
    Supplier {
      CompanyName
      CompanyRegNo
    }
    ContactPerson
    Company {
      Name
      CompanyRegNo
      CompanyTax
      ContactNo
      CompanyTax
      Address {
        ID
        address
        address2
        siteName
        country
        city
        district
        state
        postCode
        postCodeName
        AddressType
      }
      SSTNo
      GSTNo
    }
    DeliveryLocationID
    CompanyID
    DeliveryLocation {
      Name
      Address
      ContactNo
      Latitude
      Longitude
      MainLocation
    }
    DocNo
    DocDate
    ExpectedDate
    RecordStatus
    ApprovalStatus
    ContactPerson
    Instruction
    POItem {
      POItemID
      AccountID
      WarehouseID
      CostCentre {
        Name
      }
      PRItemID
      POHeaderID
      TotalAmt
      TaxAmt
      BaseAmt
      UOMID
      DeliveryLocationID
      OrderedQty
      PRItemID
      PRItem {
        PRItemID
        CostCentreID
        Quantity
        DeliveryLocationID
        Remark
        PRHeader {
          PRHeaderID
          DocDate
          DocNo
          Instruction
          ContactNo
          ContactPerson
          DeliveryLocationID
        }
        Item {
          Name
        }
      }
      DOItem {
        DOHeaderID
        DOItemID
        POItemID
        UpToDateQty
        AcceptedQty
      }
      UOMID
      UOM {
        UOMID
        Code
        Name
      }
      UOMExchange {
        uomid
        uomcode
        uomname
      }
      POHeaderID
      ItemID
      Item {
        Name
        Description
        VariancePerc
      }
      UnitPrice
      OutstandingQty
      TaxSchemeID
      TaxScheme {
        Code
        Description
      }
      Remarks
    }
  }
}
    `;

/**
 * __useGetGeneralPOsQuery__
 *
 * To run a query within a React component, call `useGetGeneralPOsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralPOsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralPOsQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      SupplierID: // value for 'SupplierID'
 *      DeliveryLocationID: // value for 'DeliveryLocationID'
 *      DOHeaderID: // value for 'DOHeaderID'
 *   },
 * });
 */
export function useGetGeneralPOsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralPOsQuery, GetGeneralPOsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralPOsQuery, GetGeneralPOsQueryVariables>(GetGeneralPOsDocument, baseOptions);
      }
export function useGetGeneralPOsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralPOsQuery, GetGeneralPOsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralPOsQuery, GetGeneralPOsQueryVariables>(GetGeneralPOsDocument, baseOptions);
        }
export type GetGeneralPOsQueryHookResult = ReturnType<typeof useGetGeneralPOsQuery>;
export type GetGeneralPOsLazyQueryHookResult = ReturnType<typeof useGetGeneralPOsLazyQuery>;
export type GetGeneralPOsQueryResult = ApolloReactCommon.QueryResult<GetGeneralPOsQuery, GetGeneralPOsQueryVariables>;
export const GetGeneralDoDocument = gql`
    query getGeneralDO($CompanyID: String, $ApprovalStatus: ApprovalStatus, $SupplierID: String, $DeliveryLocationID: String, $WarehouseID: String, $DOHeaderID: String, $orderByDesc: String, $skip: Float, $take: Float, $searchValue: String) {
  getGeneralDO(CompanyID: $CompanyID, ApprovalStatus: $ApprovalStatus, SupplierID: $SupplierID, DeliveryLocationID: $DeliveryLocationID, WarehouseID: $WarehouseID, DOHeaderID: $DOHeaderID, orderByDesc: $orderByDesc, skip: $skip, take: $take, searchValue: $searchValue) {
    DOHeaderID
    AccountID
    SupplierID
    CompanyID
    DeliveryLocationID
    WarehouseID
    DocDate
    DoNo
    DocNo
    Description
    TransactionDate
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    RecordStatus
    ApprovalStatus
    Supplier {
      CompanyName
      CompanyRegNo
      GSTRegNo
      Address
      CreditTerm
      Email
      CreditorContact {
        Name
        ContactNo
        Email
      }
    }
    Description
    DeliveryLocationID
    DeliveryLocation {
      DeliveryLocationID
      Name
      Address
    }
    RejectionDate
    RejectionRemark
    PurchaseOrder
    DOItem {
      DOItemID
      AcceptedQty
      AcceptedAmt
      DOHeaderID
      DOHeader {
        DOHeaderID
        Description
      }
      POItemID
      POItem {
        POItemID
        OutstandingQty
        UnitPrice
        OrderedQty
        CostCentreID
        Remarks
        POHeader {
          POHeaderID
          DocNo
          DocDate
          ApprovalStatus
          RecordStatus
        }
        CostCentre {
          CostCentreID
          Name
          Code
        }
        Item {
          ItemID
          Description
          Name
          IsInventory
        }
        DeliveryLocation {
          Name
          Address
          MainLocation
        }
        Warehouse {
          WarehouseID
          Name
        }
        UOM {
          UOMID
          Name
          Code
        }
        RecordStatus
      }
      GRTNItemID
      GRTNItem {
        GRTNHeaderID
        GRTNItemID
        ReturnedQty
        ReturnedAmt
        Replacement
        OutstandingQty
      }
      ReplacedGRTNItem {
        GRTNItemID
        GRTNHeaderID
        DOItemID
        DOItem {
          DOItemID
          DOHeaderID
          POItemID
          POItem {
            PRItemID
            ItemID
            UnitPrice
            BaseAmt
            TaxAmt
            TotalAmt
            Remarks
            OrderedQty
            OutstandingQty
            CostCentreID
            POHeader {
              POHeaderID
              DocNo
              DocDate
              ApprovalStatus
              RecordStatus
            }
            CostCentre {
              CostCentreID
              Name
              Code
            }
            Item {
              Name
              IsInventory
              UOM {
                Name
                Code
              }
            }
            DeliveryLocation {
              Name
              Address
              MainLocation
            }
            Warehouse {
              WarehouseID
              Name
            }
            UOM {
              UOMID
              Name
              Code
            }
            Remarks
          }
          UpToDateQty
          AcceptedQty
          ReturnedQty
          DOHeader {
            DocNo
            DOHeaderID
            DoNo
            Description
          }
        }
        OutstandingQty
        ReturnedQty
        ReturnedAmt
        Replacement
        GRTNHeader {
          createdTs
          createdBy
          modTs
          modBy
          submittedTs
          submittedBy
          approvedTs
          approvedBy
          GRTNHeaderID
          AccountID
          SupplierID
          CompanyID
          DeliveryLocationID
          DeliveryLocation {
            Name
            Address
          }
          WarehouseID
          Warehouse {
            WarehouseID
            Name
            Code
            Address
          }
          DocDate
          TransactionDate
          DocNo
          RefNo
          Description
          RecordStatus
          Supplier {
            CompanyName
          }
          ApprovalStatus
          GRTNHeaderItem {
            GRTNItemID
            DOItemID
            DOItem {
              DOItemID
              DOHeaderID
              POItemID
              POItem {
                PRItemID
                UnitPrice
                BaseAmt
                TaxAmt
                TotalAmt
                Remarks
                OrderedQty
                OutstandingQty
                Item {
                  Name
                  UOM {
                    Name
                    Code
                  }
                }
              }
              UpToDateQty
              AcceptedQty
              ReturnedQty
              DOHeader {
                DocNo
                DOHeaderID
                DoNo
                Description
              }
            }
            OutstandingQty
            ReturnedQty
            ReturnedAmt
            Replacement
            GRTNHeader {
              GRTNHeaderID
              DocNo
              DocDate
              RefNo
              Description
            }
          }
        }
      }
      RecordStatus
      UpToDateQty
      ReturnedQty
    }
    UpToDateAmt
  }
  getGeneralDOCount(CompanyID: $CompanyID, ApprovalStatus: $ApprovalStatus, SupplierID: $SupplierID, DeliveryLocationID: $DeliveryLocationID, WarehouseID: $WarehouseID, DOHeaderID: $DOHeaderID)
}
    `;

/**
 * __useGetGeneralDoQuery__
 *
 * To run a query within a React component, call `useGetGeneralDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralDoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralDoQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      SupplierID: // value for 'SupplierID'
 *      DeliveryLocationID: // value for 'DeliveryLocationID'
 *      WarehouseID: // value for 'WarehouseID'
 *      DOHeaderID: // value for 'DOHeaderID'
 *      orderByDesc: // value for 'orderByDesc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetGeneralDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralDoQuery, GetGeneralDoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralDoQuery, GetGeneralDoQueryVariables>(GetGeneralDoDocument, baseOptions);
      }
export function useGetGeneralDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralDoQuery, GetGeneralDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralDoQuery, GetGeneralDoQueryVariables>(GetGeneralDoDocument, baseOptions);
        }
export type GetGeneralDoQueryHookResult = ReturnType<typeof useGetGeneralDoQuery>;
export type GetGeneralDoLazyQueryHookResult = ReturnType<typeof useGetGeneralDoLazyQuery>;
export type GetGeneralDoQueryResult = ApolloReactCommon.QueryResult<GetGeneralDoQuery, GetGeneralDoQueryVariables>;
export const SubmitGeneralDoDocument = gql`
    mutation submitGeneralDO($DOHeaderID: String, $doInput: DOInput!, $doItemInput: [DOItemInput!]!) {
  submitGeneralDO(DOHeaderID: $DOHeaderID, doInput: $doInput, doItemInput: $doItemInput)
}
    `;
export type SubmitGeneralDoMutationFn = ApolloReactCommon.MutationFunction<SubmitGeneralDoMutation, SubmitGeneralDoMutationVariables>;

/**
 * __useSubmitGeneralDoMutation__
 *
 * To run a mutation, you first call `useSubmitGeneralDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitGeneralDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitGeneralDoMutation, { data, loading, error }] = useSubmitGeneralDoMutation({
 *   variables: {
 *      DOHeaderID: // value for 'DOHeaderID'
 *      doInput: // value for 'doInput'
 *      doItemInput: // value for 'doItemInput'
 *   },
 * });
 */
export function useSubmitGeneralDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitGeneralDoMutation, SubmitGeneralDoMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitGeneralDoMutation, SubmitGeneralDoMutationVariables>(SubmitGeneralDoDocument, baseOptions);
      }
export type SubmitGeneralDoMutationHookResult = ReturnType<typeof useSubmitGeneralDoMutation>;
export type SubmitGeneralDoMutationResult = ApolloReactCommon.MutationResult<SubmitGeneralDoMutation>;
export type SubmitGeneralDoMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitGeneralDoMutation, SubmitGeneralDoMutationVariables>;
export const GetSupplierByDoStatusDocument = gql`
    query getSupplierByDOStatus($CompanyID: String!, $ApprovalStatus: ApprovalStatus!) {
  getSupplierByDOStatus(CompanyID: $CompanyID, ApprovalStatus: $ApprovalStatus) {
    CreditorAccountID
    AccountID
    CompanyRegNo
    CreditorTypeID
    TaxSchemeID
    CompanyRegNo
    ContactNo
    Email
    CreditTerm
    GSTRegNo
    Remark
    BankName
    BankAccountNo
    Address
    CompanyName
    CreditorStatus
  }
}
    `;

/**
 * __useGetSupplierByDoStatusQuery__
 *
 * To run a query within a React component, call `useGetSupplierByDoStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierByDoStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierByDoStatusQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *   },
 * });
 */
export function useGetSupplierByDoStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierByDoStatusQuery, GetSupplierByDoStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierByDoStatusQuery, GetSupplierByDoStatusQueryVariables>(GetSupplierByDoStatusDocument, baseOptions);
      }
export function useGetSupplierByDoStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierByDoStatusQuery, GetSupplierByDoStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierByDoStatusQuery, GetSupplierByDoStatusQueryVariables>(GetSupplierByDoStatusDocument, baseOptions);
        }
export type GetSupplierByDoStatusQueryHookResult = ReturnType<typeof useGetSupplierByDoStatusQuery>;
export type GetSupplierByDoStatusLazyQueryHookResult = ReturnType<typeof useGetSupplierByDoStatusLazyQuery>;
export type GetSupplierByDoStatusQueryResult = ApolloReactCommon.QueryResult<GetSupplierByDoStatusQuery, GetSupplierByDoStatusQueryVariables>;
export const GetWarehouseDeliveryLocationDocument = gql`
    query getWarehouseDeliveryLocation($CompanyID: String) {
  getWarehouseDeliveryLocation(CompanyID: $CompanyID)
}
    `;

/**
 * __useGetWarehouseDeliveryLocationQuery__
 *
 * To run a query within a React component, call `useGetWarehouseDeliveryLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehouseDeliveryLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehouseDeliveryLocationQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetWarehouseDeliveryLocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWarehouseDeliveryLocationQuery, GetWarehouseDeliveryLocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWarehouseDeliveryLocationQuery, GetWarehouseDeliveryLocationQueryVariables>(GetWarehouseDeliveryLocationDocument, baseOptions);
      }
export function useGetWarehouseDeliveryLocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWarehouseDeliveryLocationQuery, GetWarehouseDeliveryLocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWarehouseDeliveryLocationQuery, GetWarehouseDeliveryLocationQueryVariables>(GetWarehouseDeliveryLocationDocument, baseOptions);
        }
export type GetWarehouseDeliveryLocationQueryHookResult = ReturnType<typeof useGetWarehouseDeliveryLocationQuery>;
export type GetWarehouseDeliveryLocationLazyQueryHookResult = ReturnType<typeof useGetWarehouseDeliveryLocationLazyQuery>;
export type GetWarehouseDeliveryLocationQueryResult = ApolloReactCommon.QueryResult<GetWarehouseDeliveryLocationQuery, GetWarehouseDeliveryLocationQueryVariables>;
export const DeleteGeneralDoDocument = gql`
    mutation deleteGeneralDO($DOHeaderID: String!) {
  deleteGeneralDO(DOHeaderID: $DOHeaderID)
}
    `;
export type DeleteGeneralDoMutationFn = ApolloReactCommon.MutationFunction<DeleteGeneralDoMutation, DeleteGeneralDoMutationVariables>;

/**
 * __useDeleteGeneralDoMutation__
 *
 * To run a mutation, you first call `useDeleteGeneralDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGeneralDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGeneralDoMutation, { data, loading, error }] = useDeleteGeneralDoMutation({
 *   variables: {
 *      DOHeaderID: // value for 'DOHeaderID'
 *   },
 * });
 */
export function useDeleteGeneralDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteGeneralDoMutation, DeleteGeneralDoMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteGeneralDoMutation, DeleteGeneralDoMutationVariables>(DeleteGeneralDoDocument, baseOptions);
      }
export type DeleteGeneralDoMutationHookResult = ReturnType<typeof useDeleteGeneralDoMutation>;
export type DeleteGeneralDoMutationResult = ApolloReactCommon.MutationResult<DeleteGeneralDoMutation>;
export type DeleteGeneralDoMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteGeneralDoMutation, DeleteGeneralDoMutationVariables>;
export const ActionOnGdoStatusDocument = gql`
    mutation actionOnGDOStatus($input: GDOActionInput!, $DOHeaderID: [String!]!) {
  actionOnGDOStatus(input: $input, DOHeaderID: $DOHeaderID)
}
    `;
export type ActionOnGdoStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnGdoStatusMutation, ActionOnGdoStatusMutationVariables>;

/**
 * __useActionOnGdoStatusMutation__
 *
 * To run a mutation, you first call `useActionOnGdoStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnGdoStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnGdoStatusMutation, { data, loading, error }] = useActionOnGdoStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *      DOHeaderID: // value for 'DOHeaderID'
 *   },
 * });
 */
export function useActionOnGdoStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnGdoStatusMutation, ActionOnGdoStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnGdoStatusMutation, ActionOnGdoStatusMutationVariables>(ActionOnGdoStatusDocument, baseOptions);
      }
export type ActionOnGdoStatusMutationHookResult = ReturnType<typeof useActionOnGdoStatusMutation>;
export type ActionOnGdoStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnGdoStatusMutation>;
export type ActionOnGdoStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnGdoStatusMutation, ActionOnGdoStatusMutationVariables>;
export const GetGrtnHeaderDocument = gql`
    query getGRTNHeader($CompanyID: String, $orderByDesc: String, $ApprovalStatus: ApprovalStatus, $GRTNHeaderID: String, $skip: Float, $take: Float, $searchValue: String) {
  getGRTNHeader(CompanyID: $CompanyID, orderByDesc: $orderByDesc, ApprovalStatus: $ApprovalStatus, GRTNHeaderID: $GRTNHeaderID, skip: $skip, take: $take, searchValue: $searchValue) {
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    GRTNHeaderID
    AccountID
    SupplierID
    CompanyID
    DeliveryLocationID
    DeliveryLocation {
      Name
      Address
    }
    WarehouseID
    Warehouse {
      WarehouseID
      Name
      Code
      Address
    }
    DocDate
    TransactionDate
    DocNo
    RefNo
    Description
    RecordStatus
    Supplier {
      CompanyName
      CompanyRegNo
      GSTRegNo
      Address
      CreditTerm
      Email
      CreditorContact {
        Name
        ContactNo
        Email
      }
    }
    ApprovalStatus
    GRTNHeaderItem {
      GRTNItemID
      DOItemID
      DOItem {
        DOItemID
        DOHeaderID
        POItemID
        POItem {
          PRItemID
          UnitPrice
          BaseAmt
          TaxAmt
          TotalAmt
          Remarks
          OrderedQty
          OutstandingQty
          CostCentreID
          POHeader {
            DocNo
            Remarks
          }
          CostCentre {
            CostCentreID
            Name
            Code
          }
          Item {
            Name
            UOM {
              Name
              Code
            }
          }
        }
        UpToDateQty
        AcceptedQty
        ReturnedQty
        DOHeader {
          DocNo
          DOHeaderID
          DoNo
          Description
        }
      }
      OutstandingQty
      ReturnedQty
      ReturnedAmt
      Replacement
      GRTNHeader {
        DocNo
        DocDate
        RefNo
        Description
      }
    }
  }
  getGRTNHeaderCount(CompanyID: $CompanyID, ApprovalStatus: $ApprovalStatus, GRTNHeaderID: $GRTNHeaderID)
}
    `;

/**
 * __useGetGrtnHeaderQuery__
 *
 * To run a query within a React component, call `useGetGrtnHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGrtnHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGrtnHeaderQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      orderByDesc: // value for 'orderByDesc'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      GRTNHeaderID: // value for 'GRTNHeaderID'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetGrtnHeaderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGrtnHeaderQuery, GetGrtnHeaderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGrtnHeaderQuery, GetGrtnHeaderQueryVariables>(GetGrtnHeaderDocument, baseOptions);
      }
export function useGetGrtnHeaderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGrtnHeaderQuery, GetGrtnHeaderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGrtnHeaderQuery, GetGrtnHeaderQueryVariables>(GetGrtnHeaderDocument, baseOptions);
        }
export type GetGrtnHeaderQueryHookResult = ReturnType<typeof useGetGrtnHeaderQuery>;
export type GetGrtnHeaderLazyQueryHookResult = ReturnType<typeof useGetGrtnHeaderLazyQuery>;
export type GetGrtnHeaderQueryResult = ApolloReactCommon.QueryResult<GetGrtnHeaderQuery, GetGrtnHeaderQueryVariables>;
export const GetGeneralDOforGrtnDocument = gql`
    query getGeneralDOforGRTN($CompanyID: String, $ApprovalStatus: ApprovalStatus, $SupplierID: String, $DeliveryLocationID: String, $WarehouseID: String, $DOHeaderID: String, $orderByDesc: String, $skip: Float, $take: Float, $searchValue: String) {
  getGeneralDO(CompanyID: $CompanyID, ApprovalStatus: $ApprovalStatus, SupplierID: $SupplierID, DeliveryLocationID: $DeliveryLocationID, WarehouseID: $WarehouseID, DOHeaderID: $DOHeaderID, orderByDesc: $orderByDesc, skip: $skip, take: $take, searchValue: $searchValue) {
    DOHeaderID
    DocDate
    DocNo
    DoNo
    DOItem {
      DOItemID
      DOHeaderID
      AcceptedQty
      AcceptedAmt
      UpToDateQty
      POItem {
        UnitPrice
        Remarks
        CostCentre {
          Name
        }
        Item {
          Name
        }
        UOM {
          Code
        }
        POHeader {
          DocNo
        }
      }
      ReplacedGRTNItem {
        DOItem {
          POItem {
            UnitPrice
            Remarks
            CostCentre {
              Name
            }
            Item {
              Name
            }
            UOM {
              Code
            }
            Remarks
          }
          UpToDateQty
          AcceptedQty
          ReturnedQty
        }
        OutstandingQty
        ReturnedQty
        ReturnedAmt
        Replacement
      }
    }
  }
}
    `;

/**
 * __useGetGeneralDOforGrtnQuery__
 *
 * To run a query within a React component, call `useGetGeneralDOforGrtnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralDOforGrtnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralDOforGrtnQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      SupplierID: // value for 'SupplierID'
 *      DeliveryLocationID: // value for 'DeliveryLocationID'
 *      WarehouseID: // value for 'WarehouseID'
 *      DOHeaderID: // value for 'DOHeaderID'
 *      orderByDesc: // value for 'orderByDesc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetGeneralDOforGrtnQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralDOforGrtnQuery, GetGeneralDOforGrtnQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralDOforGrtnQuery, GetGeneralDOforGrtnQueryVariables>(GetGeneralDOforGrtnDocument, baseOptions);
      }
export function useGetGeneralDOforGrtnLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralDOforGrtnQuery, GetGeneralDOforGrtnQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralDOforGrtnQuery, GetGeneralDOforGrtnQueryVariables>(GetGeneralDOforGrtnDocument, baseOptions);
        }
export type GetGeneralDOforGrtnQueryHookResult = ReturnType<typeof useGetGeneralDOforGrtnQuery>;
export type GetGeneralDOforGrtnLazyQueryHookResult = ReturnType<typeof useGetGeneralDOforGrtnLazyQuery>;
export type GetGeneralDOforGrtnQueryResult = ApolloReactCommon.QueryResult<GetGeneralDOforGrtnQuery, GetGeneralDOforGrtnQueryVariables>;
export const CreateUpdateGrtnDocument = gql`
    mutation createUpdateGRTN($GRTNHeaderID: String, $grtnInput: GeneralGRTNInput!, $grtnItemInput: [GeneralGRTNItemInput!]!) {
  createUpdateGRTN(GRTNHeaderID: $GRTNHeaderID, grtnInput: $grtnInput, grtnItemInput: $grtnItemInput)
}
    `;
export type CreateUpdateGrtnMutationFn = ApolloReactCommon.MutationFunction<CreateUpdateGrtnMutation, CreateUpdateGrtnMutationVariables>;

/**
 * __useCreateUpdateGrtnMutation__
 *
 * To run a mutation, you first call `useCreateUpdateGrtnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateGrtnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateGrtnMutation, { data, loading, error }] = useCreateUpdateGrtnMutation({
 *   variables: {
 *      GRTNHeaderID: // value for 'GRTNHeaderID'
 *      grtnInput: // value for 'grtnInput'
 *      grtnItemInput: // value for 'grtnItemInput'
 *   },
 * });
 */
export function useCreateUpdateGrtnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUpdateGrtnMutation, CreateUpdateGrtnMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUpdateGrtnMutation, CreateUpdateGrtnMutationVariables>(CreateUpdateGrtnDocument, baseOptions);
      }
export type CreateUpdateGrtnMutationHookResult = ReturnType<typeof useCreateUpdateGrtnMutation>;
export type CreateUpdateGrtnMutationResult = ApolloReactCommon.MutationResult<CreateUpdateGrtnMutation>;
export type CreateUpdateGrtnMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUpdateGrtnMutation, CreateUpdateGrtnMutationVariables>;
export const CreateGrtnDocument = gql`
    mutation CreateGRTN($grtnInput: GeneralGRTNInput!, $grtnItemInput: [GeneralGRTNItemInput!]!) {
  CreateGRTN(grtnInput: $grtnInput, grtnItemInput: $grtnItemInput)
}
    `;
export type CreateGrtnMutationFn = ApolloReactCommon.MutationFunction<CreateGrtnMutation, CreateGrtnMutationVariables>;

/**
 * __useCreateGrtnMutation__
 *
 * To run a mutation, you first call `useCreateGrtnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGrtnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGrtnMutation, { data, loading, error }] = useCreateGrtnMutation({
 *   variables: {
 *      grtnInput: // value for 'grtnInput'
 *      grtnItemInput: // value for 'grtnItemInput'
 *   },
 * });
 */
export function useCreateGrtnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGrtnMutation, CreateGrtnMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGrtnMutation, CreateGrtnMutationVariables>(CreateGrtnDocument, baseOptions);
      }
export type CreateGrtnMutationHookResult = ReturnType<typeof useCreateGrtnMutation>;
export type CreateGrtnMutationResult = ApolloReactCommon.MutationResult<CreateGrtnMutation>;
export type CreateGrtnMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGrtnMutation, CreateGrtnMutationVariables>;
export const UpdateGrtnDocument = gql`
    mutation UpdateGRTN($GRTNHeaderID: String, $grtnInput: GeneralGRTNInput!, $grtnItemInput: [GeneralGRTNItemInput!]!) {
  UpdateGRTN(GRTNHeaderID: $GRTNHeaderID, grtnInput: $grtnInput, grtnItemInput: $grtnItemInput)
}
    `;
export type UpdateGrtnMutationFn = ApolloReactCommon.MutationFunction<UpdateGrtnMutation, UpdateGrtnMutationVariables>;

/**
 * __useUpdateGrtnMutation__
 *
 * To run a mutation, you first call `useUpdateGrtnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGrtnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGrtnMutation, { data, loading, error }] = useUpdateGrtnMutation({
 *   variables: {
 *      GRTNHeaderID: // value for 'GRTNHeaderID'
 *      grtnInput: // value for 'grtnInput'
 *      grtnItemInput: // value for 'grtnItemInput'
 *   },
 * });
 */
export function useUpdateGrtnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateGrtnMutation, UpdateGrtnMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateGrtnMutation, UpdateGrtnMutationVariables>(UpdateGrtnDocument, baseOptions);
      }
export type UpdateGrtnMutationHookResult = ReturnType<typeof useUpdateGrtnMutation>;
export type UpdateGrtnMutationResult = ApolloReactCommon.MutationResult<UpdateGrtnMutation>;
export type UpdateGrtnMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateGrtnMutation, UpdateGrtnMutationVariables>;
export const GetWarehouseDeliveryDocument = gql`
    query getWarehouseDelivery($CompanyID: String) {
  getWarehouseDeliveryLocation(CompanyID: $CompanyID)
}
    `;

/**
 * __useGetWarehouseDeliveryQuery__
 *
 * To run a query within a React component, call `useGetWarehouseDeliveryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehouseDeliveryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehouseDeliveryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetWarehouseDeliveryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWarehouseDeliveryQuery, GetWarehouseDeliveryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWarehouseDeliveryQuery, GetWarehouseDeliveryQueryVariables>(GetWarehouseDeliveryDocument, baseOptions);
      }
export function useGetWarehouseDeliveryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWarehouseDeliveryQuery, GetWarehouseDeliveryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWarehouseDeliveryQuery, GetWarehouseDeliveryQueryVariables>(GetWarehouseDeliveryDocument, baseOptions);
        }
export type GetWarehouseDeliveryQueryHookResult = ReturnType<typeof useGetWarehouseDeliveryQuery>;
export type GetWarehouseDeliveryLazyQueryHookResult = ReturnType<typeof useGetWarehouseDeliveryLazyQuery>;
export type GetWarehouseDeliveryQueryResult = ApolloReactCommon.QueryResult<GetWarehouseDeliveryQuery, GetWarehouseDeliveryQueryVariables>;
export const DeleteGrtnDocument = gql`
    mutation DeleteGRTN($GRTNHeaderID: String!) {
  deleteGRTN(GRTNHeaderID: $GRTNHeaderID)
}
    `;
export type DeleteGrtnMutationFn = ApolloReactCommon.MutationFunction<DeleteGrtnMutation, DeleteGrtnMutationVariables>;

/**
 * __useDeleteGrtnMutation__
 *
 * To run a mutation, you first call `useDeleteGrtnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGrtnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGrtnMutation, { data, loading, error }] = useDeleteGrtnMutation({
 *   variables: {
 *      GRTNHeaderID: // value for 'GRTNHeaderID'
 *   },
 * });
 */
export function useDeleteGrtnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteGrtnMutation, DeleteGrtnMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteGrtnMutation, DeleteGrtnMutationVariables>(DeleteGrtnDocument, baseOptions);
      }
export type DeleteGrtnMutationHookResult = ReturnType<typeof useDeleteGrtnMutation>;
export type DeleteGrtnMutationResult = ApolloReactCommon.MutationResult<DeleteGrtnMutation>;
export type DeleteGrtnMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteGrtnMutation, DeleteGrtnMutationVariables>;
export const ActionOnGrtnStatusDocument = gql`
    mutation actionOnGRTNStatus($GRTNHeaderID: [String!]!, $ApprovalStatus: ApprovalStatus!, $input: GeneralGRTNRejectInput!, $CompanyID: String!) {
  actionOnGRTNStatus(GRTNHeaderID: $GRTNHeaderID, ApprovalStatus: $ApprovalStatus, input: $input, CompanyID: $CompanyID)
}
    `;
export type ActionOnGrtnStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnGrtnStatusMutation, ActionOnGrtnStatusMutationVariables>;

/**
 * __useActionOnGrtnStatusMutation__
 *
 * To run a mutation, you first call `useActionOnGrtnStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnGrtnStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnGrtnStatusMutation, { data, loading, error }] = useActionOnGrtnStatusMutation({
 *   variables: {
 *      GRTNHeaderID: // value for 'GRTNHeaderID'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      input: // value for 'input'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useActionOnGrtnStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnGrtnStatusMutation, ActionOnGrtnStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnGrtnStatusMutation, ActionOnGrtnStatusMutationVariables>(ActionOnGrtnStatusDocument, baseOptions);
      }
export type ActionOnGrtnStatusMutationHookResult = ReturnType<typeof useActionOnGrtnStatusMutation>;
export type ActionOnGrtnStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnGrtnStatusMutation>;
export type ActionOnGrtnStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnGrtnStatusMutation, ActionOnGrtnStatusMutationVariables>;
export const GetPoHeaderDocument = gql`
    query getPOHeader($orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float, $searchValue: String, $POHeaderID: String, $SupplierID: String, $CompanyID: String, $ApprovalStatus: ApprovalStatus, $POType: POType) {
  getPOHeader(orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, skip: $skip, take: $take, searchValue: $searchValue, POHeaderID: $POHeaderID, SupplierID: $SupplierID, CompanyID: $CompanyID, ApprovalStatus: $ApprovalStatus, POType: $POType) {
    POHeaderID
    AccountID
    modTs
    modBy
    createdTs
    createdBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    SupplierID
    CreditTerm
    POType
    Supplier {
      CompanyName
      CompanyRegNo
      GSTRegNo
      Address
      CreditTerm
      Email
      CreditorContact {
        Name
        ContactNo
        Email
      }
    }
    ContactPerson
    Company {
      Name
      CompanyRegNo
      ContactNo
      CompanyTax
      Address {
        ID
        address
        address2
        siteName
        country
        city
        district
        state
        postCode
        postCodeName
        AddressType
      }
      SSTNo
      GSTNo
    }
    DeliveryLocationID
    ApprovalWorkflowPO
    CompanyID
    DeliveryLocation {
      Name
      Address
      ContactNo
      Latitude
      Longitude
      MainLocation
    }
    DocNo
    TotalAmt
    DocDate
    ExpectedDate
    RecordStatus
    ApprovalStatus
    ContactPerson
    Instruction
    POItem {
      POItemID
      AccountID
      PRItemID
      POHeaderID
      TotalAmt
      TaxAmt
      BaseAmt
      UOMID
      CostCentreID
      CostCentre {
        Name
        Level
        Code
      }
      DeliveryLocationID
      DeliveryLocation {
        Name
        Address
        ContactNo
        Latitude
        Longitude
        MainLocation
      }
      WarehouseID
      Warehouse {
        Name
        Code
        Address
      }
      OrderedQty
      PRItemID
      PRItem {
        PRItemID
        CostCentreID
        Quantity
        OutstandingQty
        DeliveryLocationID
        Remark
        PRHeader {
          PRHeaderID
          DocDate
          DocNo
          Instruction
          ContactNo
          ContactPerson
          DeliveryLocationID
        }
        Item {
          Name
          IsInventory
          IsCentralised
        }
      }
      DOItem {
        DOHeaderID
        AcceptedQty
      }
      UOMID
      UOM {
        UOMID
        Code
        Name
      }
      UOMExchange {
        uomid
        uomcode
        uomname
      }
      POHeaderID
      ItemID
      Item {
        Name
        Description
        IsInventory
      }
      UnitPrice
      OutstandingQty
      TaxSchemeID
      TaxScheme {
        AccountID
        TaxSchemeID
        Code
        Description
      }
      Remarks
      ScheduledPurchasesItemID
      ScheduledPurchaseDetail
    }
  }
  getPOHeaderCount(POHeaderID: $POHeaderID, CompanyID: $CompanyID, ApprovalStatus: $ApprovalStatus, POType: $POType)
}
    `;

/**
 * __useGetPoHeaderQuery__
 *
 * To run a query within a React component, call `useGetPoHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoHeaderQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *      POHeaderID: // value for 'POHeaderID'
 *      SupplierID: // value for 'SupplierID'
 *      CompanyID: // value for 'CompanyID'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      POType: // value for 'POType'
 *   },
 * });
 */
export function useGetPoHeaderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPoHeaderQuery, GetPoHeaderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPoHeaderQuery, GetPoHeaderQueryVariables>(GetPoHeaderDocument, baseOptions);
      }
export function useGetPoHeaderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPoHeaderQuery, GetPoHeaderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPoHeaderQuery, GetPoHeaderQueryVariables>(GetPoHeaderDocument, baseOptions);
        }
export type GetPoHeaderQueryHookResult = ReturnType<typeof useGetPoHeaderQuery>;
export type GetPoHeaderLazyQueryHookResult = ReturnType<typeof useGetPoHeaderLazyQuery>;
export type GetPoHeaderQueryResult = ApolloReactCommon.QueryResult<GetPoHeaderQuery, GetPoHeaderQueryVariables>;
export const GetSiteWithAccountDocument = gql`
    query getSiteWithAccount($ID: String) {
  getSiteWithAccount(ID: $ID)
}
    `;

/**
 * __useGetSiteWithAccountQuery__
 *
 * To run a query within a React component, call `useGetSiteWithAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteWithAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteWithAccountQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetSiteWithAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteWithAccountQuery, GetSiteWithAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteWithAccountQuery, GetSiteWithAccountQueryVariables>(GetSiteWithAccountDocument, baseOptions);
      }
export function useGetSiteWithAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteWithAccountQuery, GetSiteWithAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteWithAccountQuery, GetSiteWithAccountQueryVariables>(GetSiteWithAccountDocument, baseOptions);
        }
export type GetSiteWithAccountQueryHookResult = ReturnType<typeof useGetSiteWithAccountQuery>;
export type GetSiteWithAccountLazyQueryHookResult = ReturnType<typeof useGetSiteWithAccountLazyQuery>;
export type GetSiteWithAccountQueryResult = ApolloReactCommon.QueryResult<GetSiteWithAccountQuery, GetSiteWithAccountQueryVariables>;
export const GetOsGeneralPrDocument = gql`
    query getOSGeneralPR($CompanyID: String!) {
  getOSGeneralPR(CompanyID: $CompanyID) {
    PRHeaderID
    AccountID
    CompanyID
    DocNo
    DocDate
    ExpectedDate
    RequestedBy
    DeliveryLocationID
    ContactPerson
    ContactNo
    Instruction
    PRItem {
      PRItemID
      CostCentreID
      CostCentre {
        CostCentreID
        CompanyID
        Name
      }
      UOM {
        Code
        Name
      }
      Item {
        Name
      }
      Quantity
      POItem {
        UOMID
        TaxSchemeID
        OutstandingQty
        UnitPrice
      }
      Quantity
      ItemID
      POItem {
        OutstandingQty
        UnitPrice
      }
    }
  }
}
    `;

/**
 * __useGetOsGeneralPrQuery__
 *
 * To run a query within a React component, call `useGetOsGeneralPrQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOsGeneralPrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOsGeneralPrQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetOsGeneralPrQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOsGeneralPrQuery, GetOsGeneralPrQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOsGeneralPrQuery, GetOsGeneralPrQueryVariables>(GetOsGeneralPrDocument, baseOptions);
      }
export function useGetOsGeneralPrLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOsGeneralPrQuery, GetOsGeneralPrQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOsGeneralPrQuery, GetOsGeneralPrQueryVariables>(GetOsGeneralPrDocument, baseOptions);
        }
export type GetOsGeneralPrQueryHookResult = ReturnType<typeof useGetOsGeneralPrQuery>;
export type GetOsGeneralPrLazyQueryHookResult = ReturnType<typeof useGetOsGeneralPrLazyQuery>;
export type GetOsGeneralPrQueryResult = ApolloReactCommon.QueryResult<GetOsGeneralPrQuery, GetOsGeneralPrQueryVariables>;
export const GetTaxSchemeWithAccountDocument = gql`
    query getTaxSchemeWithAccount($accountID: String!) {
  getTaxSchemeWithAccount(accountID: $accountID) {
    TaxSchemeID
    Code
    Description
    TaxTypeID
    TaxType {
      Code
      Description
    }
    AccTaxCategory
    AccTaxClass
    MasterCOAID
    LatestTax {
      Rate
      Date
    }
  }
}
    `;

/**
 * __useGetTaxSchemeWithAccountQuery__
 *
 * To run a query within a React component, call `useGetTaxSchemeWithAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxSchemeWithAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxSchemeWithAccountQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useGetTaxSchemeWithAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxSchemeWithAccountQuery, GetTaxSchemeWithAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxSchemeWithAccountQuery, GetTaxSchemeWithAccountQueryVariables>(GetTaxSchemeWithAccountDocument, baseOptions);
      }
export function useGetTaxSchemeWithAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxSchemeWithAccountQuery, GetTaxSchemeWithAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxSchemeWithAccountQuery, GetTaxSchemeWithAccountQueryVariables>(GetTaxSchemeWithAccountDocument, baseOptions);
        }
export type GetTaxSchemeWithAccountQueryHookResult = ReturnType<typeof useGetTaxSchemeWithAccountQuery>;
export type GetTaxSchemeWithAccountLazyQueryHookResult = ReturnType<typeof useGetTaxSchemeWithAccountLazyQuery>;
export type GetTaxSchemeWithAccountQueryResult = ApolloReactCommon.QueryResult<GetTaxSchemeWithAccountQuery, GetTaxSchemeWithAccountQueryVariables>;
export const CreateDirectPoDocument = gql`
    mutation createDirectPO($input: DirectPOInput!) {
  createDirectPO(input: $input)
}
    `;
export type CreateDirectPoMutationFn = ApolloReactCommon.MutationFunction<CreateDirectPoMutation, CreateDirectPoMutationVariables>;

/**
 * __useCreateDirectPoMutation__
 *
 * To run a mutation, you first call `useCreateDirectPoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDirectPoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDirectPoMutation, { data, loading, error }] = useCreateDirectPoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDirectPoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDirectPoMutation, CreateDirectPoMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDirectPoMutation, CreateDirectPoMutationVariables>(CreateDirectPoDocument, baseOptions);
      }
export type CreateDirectPoMutationHookResult = ReturnType<typeof useCreateDirectPoMutation>;
export type CreateDirectPoMutationResult = ApolloReactCommon.MutationResult<CreateDirectPoMutation>;
export type CreateDirectPoMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDirectPoMutation, CreateDirectPoMutationVariables>;
export const CombinePoDocument = gql`
    mutation combinePO($input: [DirectPOInput!]!) {
  combinePO(input: $input)
}
    `;
export type CombinePoMutationFn = ApolloReactCommon.MutationFunction<CombinePoMutation, CombinePoMutationVariables>;

/**
 * __useCombinePoMutation__
 *
 * To run a mutation, you first call `useCombinePoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCombinePoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [combinePoMutation, { data, loading, error }] = useCombinePoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCombinePoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CombinePoMutation, CombinePoMutationVariables>) {
        return ApolloReactHooks.useMutation<CombinePoMutation, CombinePoMutationVariables>(CombinePoDocument, baseOptions);
      }
export type CombinePoMutationHookResult = ReturnType<typeof useCombinePoMutation>;
export type CombinePoMutationResult = ApolloReactCommon.MutationResult<CombinePoMutation>;
export type CombinePoMutationOptions = ApolloReactCommon.BaseMutationOptions<CombinePoMutation, CombinePoMutationVariables>;
export const UpdateDirectPoDocument = gql`
    mutation updateDirectPO($POHeaderID: String, $input: DirectPOInput!) {
  updateDirectPO(POHeaderID: $POHeaderID, input: $input)
}
    `;
export type UpdateDirectPoMutationFn = ApolloReactCommon.MutationFunction<UpdateDirectPoMutation, UpdateDirectPoMutationVariables>;

/**
 * __useUpdateDirectPoMutation__
 *
 * To run a mutation, you first call `useUpdateDirectPoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDirectPoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDirectPoMutation, { data, loading, error }] = useUpdateDirectPoMutation({
 *   variables: {
 *      POHeaderID: // value for 'POHeaderID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDirectPoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDirectPoMutation, UpdateDirectPoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDirectPoMutation, UpdateDirectPoMutationVariables>(UpdateDirectPoDocument, baseOptions);
      }
export type UpdateDirectPoMutationHookResult = ReturnType<typeof useUpdateDirectPoMutation>;
export type UpdateDirectPoMutationResult = ApolloReactCommon.MutationResult<UpdateDirectPoMutation>;
export type UpdateDirectPoMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDirectPoMutation, UpdateDirectPoMutationVariables>;
export const DeletePoDocument = gql`
    mutation deletePO($POHeaderID: String!) {
  deletePO(POHeaderID: $POHeaderID)
}
    `;
export type DeletePoMutationFn = ApolloReactCommon.MutationFunction<DeletePoMutation, DeletePoMutationVariables>;

/**
 * __useDeletePoMutation__
 *
 * To run a mutation, you first call `useDeletePoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePoMutation, { data, loading, error }] = useDeletePoMutation({
 *   variables: {
 *      POHeaderID: // value for 'POHeaderID'
 *   },
 * });
 */
export function useDeletePoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePoMutation, DeletePoMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePoMutation, DeletePoMutationVariables>(DeletePoDocument, baseOptions);
      }
export type DeletePoMutationHookResult = ReturnType<typeof useDeletePoMutation>;
export type DeletePoMutationResult = ApolloReactCommon.MutationResult<DeletePoMutation>;
export type DeletePoMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePoMutation, DeletePoMutationVariables>;
export const ActionOnGpoStatusDocument = gql`
    mutation actionOnGPOStatus($CompanyID: String!, $ApprovalStatus: ApprovalStatus!, $input: PORejectInput!, $POHeaderID: [String!]!) {
  actionOnGPOStatus(CompanyID: $CompanyID, ApprovalStatus: $ApprovalStatus, input: $input, POHeaderID: $POHeaderID)
}
    `;
export type ActionOnGpoStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnGpoStatusMutation, ActionOnGpoStatusMutationVariables>;

/**
 * __useActionOnGpoStatusMutation__
 *
 * To run a mutation, you first call `useActionOnGpoStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnGpoStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnGpoStatusMutation, { data, loading, error }] = useActionOnGpoStatusMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      input: // value for 'input'
 *      POHeaderID: // value for 'POHeaderID'
 *   },
 * });
 */
export function useActionOnGpoStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnGpoStatusMutation, ActionOnGpoStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnGpoStatusMutation, ActionOnGpoStatusMutationVariables>(ActionOnGpoStatusDocument, baseOptions);
      }
export type ActionOnGpoStatusMutationHookResult = ReturnType<typeof useActionOnGpoStatusMutation>;
export type ActionOnGpoStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnGpoStatusMutation>;
export type ActionOnGpoStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnGpoStatusMutation, ActionOnGpoStatusMutationVariables>;
export const GetWarehouseDocument = gql`
    query getWarehouse($CompanyID: String, $AccountID: String, $orderByAsc: String) {
  getWarehouse(CompanyID: $CompanyID, AccountID: $AccountID, orderByAsc: $orderByAsc) {
    WarehouseID
    Name
    Code
    Address
    CostCentreID
  }
}
    `;

/**
 * __useGetWarehouseQuery__
 *
 * To run a query within a React component, call `useGetWarehouseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehouseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehouseQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      AccountID: // value for 'AccountID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetWarehouseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWarehouseQuery, GetWarehouseQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWarehouseQuery, GetWarehouseQueryVariables>(GetWarehouseDocument, baseOptions);
      }
export function useGetWarehouseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWarehouseQuery, GetWarehouseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWarehouseQuery, GetWarehouseQueryVariables>(GetWarehouseDocument, baseOptions);
        }
export type GetWarehouseQueryHookResult = ReturnType<typeof useGetWarehouseQuery>;
export type GetWarehouseLazyQueryHookResult = ReturnType<typeof useGetWarehouseLazyQuery>;
export type GetWarehouseQueryResult = ApolloReactCommon.QueryResult<GetWarehouseQuery, GetWarehouseQueryVariables>;
export const GetCreditorSupplierTypeDocument = gql`
    query getCreditorSupplierType($orderByDesc: String, $orderByAsc: String, $AccountID: String, $CreditorAccountID: String, $CreditorTypeEnum: CreditorType) {
  getCreditorAccount(orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, AccountID: $AccountID, CreditorAccountID: $CreditorAccountID, CreditorTypeEnum: $CreditorTypeEnum) {
    CreditorAccountID
    CreditorTypeID
    CompanyName
    ContactNo
    TaxSchemeID
    CreditorTypeEnum
    CreditorStatus
  }
}
    `;

/**
 * __useGetCreditorSupplierTypeQuery__
 *
 * To run a query within a React component, call `useGetCreditorSupplierTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorSupplierTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorSupplierTypeQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      AccountID: // value for 'AccountID'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      CreditorTypeEnum: // value for 'CreditorTypeEnum'
 *   },
 * });
 */
export function useGetCreditorSupplierTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorSupplierTypeQuery, GetCreditorSupplierTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorSupplierTypeQuery, GetCreditorSupplierTypeQueryVariables>(GetCreditorSupplierTypeDocument, baseOptions);
      }
export function useGetCreditorSupplierTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorSupplierTypeQuery, GetCreditorSupplierTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorSupplierTypeQuery, GetCreditorSupplierTypeQueryVariables>(GetCreditorSupplierTypeDocument, baseOptions);
        }
export type GetCreditorSupplierTypeQueryHookResult = ReturnType<typeof useGetCreditorSupplierTypeQuery>;
export type GetCreditorSupplierTypeLazyQueryHookResult = ReturnType<typeof useGetCreditorSupplierTypeLazyQuery>;
export type GetCreditorSupplierTypeQueryResult = ApolloReactCommon.QueryResult<GetCreditorSupplierTypeQuery, GetCreditorSupplierTypeQueryVariables>;
export const CreateGeneralPoDocument = gql`
    mutation createGeneralPO($generalPOInput: [POInput!]!) {
  createGeneralPO(generalPOInput: $generalPOInput)
}
    `;
export type CreateGeneralPoMutationFn = ApolloReactCommon.MutationFunction<CreateGeneralPoMutation, CreateGeneralPoMutationVariables>;

/**
 * __useCreateGeneralPoMutation__
 *
 * To run a mutation, you first call `useCreateGeneralPoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGeneralPoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGeneralPoMutation, { data, loading, error }] = useCreateGeneralPoMutation({
 *   variables: {
 *      generalPOInput: // value for 'generalPOInput'
 *   },
 * });
 */
export function useCreateGeneralPoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGeneralPoMutation, CreateGeneralPoMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGeneralPoMutation, CreateGeneralPoMutationVariables>(CreateGeneralPoDocument, baseOptions);
      }
export type CreateGeneralPoMutationHookResult = ReturnType<typeof useCreateGeneralPoMutation>;
export type CreateGeneralPoMutationResult = ApolloReactCommon.MutationResult<CreateGeneralPoMutation>;
export type CreateGeneralPoMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGeneralPoMutation, CreateGeneralPoMutationVariables>;
export const GetSupplierAccountByCompanyDocument = gql`
    query getSupplierAccountByCompany($CompanyID: String!, $CreditorAccountID: String) {
  getSupplierAccountByCompany(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID)
}
    `;

/**
 * __useGetSupplierAccountByCompanyQuery__
 *
 * To run a query within a React component, call `useGetSupplierAccountByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierAccountByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierAccountByCompanyQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *   },
 * });
 */
export function useGetSupplierAccountByCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierAccountByCompanyQuery, GetSupplierAccountByCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierAccountByCompanyQuery, GetSupplierAccountByCompanyQueryVariables>(GetSupplierAccountByCompanyDocument, baseOptions);
      }
export function useGetSupplierAccountByCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierAccountByCompanyQuery, GetSupplierAccountByCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierAccountByCompanyQuery, GetSupplierAccountByCompanyQueryVariables>(GetSupplierAccountByCompanyDocument, baseOptions);
        }
export type GetSupplierAccountByCompanyQueryHookResult = ReturnType<typeof useGetSupplierAccountByCompanyQuery>;
export type GetSupplierAccountByCompanyLazyQueryHookResult = ReturnType<typeof useGetSupplierAccountByCompanyLazyQuery>;
export type GetSupplierAccountByCompanyQueryResult = ApolloReactCommon.QueryResult<GetSupplierAccountByCompanyQuery, GetSupplierAccountByCompanyQueryVariables>;
export const CheckingCancelClosePoDocument = gql`
    query checkingCancelClosePO($POHeaderID: String!) {
  checkingCancelClosePO(POHeaderID: $POHeaderID)
}
    `;

/**
 * __useCheckingCancelClosePoQuery__
 *
 * To run a query within a React component, call `useCheckingCancelClosePoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckingCancelClosePoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckingCancelClosePoQuery({
 *   variables: {
 *      POHeaderID: // value for 'POHeaderID'
 *   },
 * });
 */
export function useCheckingCancelClosePoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckingCancelClosePoQuery, CheckingCancelClosePoQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckingCancelClosePoQuery, CheckingCancelClosePoQueryVariables>(CheckingCancelClosePoDocument, baseOptions);
      }
export function useCheckingCancelClosePoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckingCancelClosePoQuery, CheckingCancelClosePoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckingCancelClosePoQuery, CheckingCancelClosePoQueryVariables>(CheckingCancelClosePoDocument, baseOptions);
        }
export type CheckingCancelClosePoQueryHookResult = ReturnType<typeof useCheckingCancelClosePoQuery>;
export type CheckingCancelClosePoLazyQueryHookResult = ReturnType<typeof useCheckingCancelClosePoLazyQuery>;
export type CheckingCancelClosePoQueryResult = ApolloReactCommon.QueryResult<CheckingCancelClosePoQuery, CheckingCancelClosePoQueryVariables>;
export const CancelPoDocument = gql`
    mutation cancelPO($input: PurchaseActionInput!, $POHeaderID: String!) {
  cancelClosePO(input: $input, POHeaderID: $POHeaderID)
}
    `;
export type CancelPoMutationFn = ApolloReactCommon.MutationFunction<CancelPoMutation, CancelPoMutationVariables>;

/**
 * __useCancelPoMutation__
 *
 * To run a mutation, you first call `useCancelPoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPoMutation, { data, loading, error }] = useCancelPoMutation({
 *   variables: {
 *      input: // value for 'input'
 *      POHeaderID: // value for 'POHeaderID'
 *   },
 * });
 */
export function useCancelPoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelPoMutation, CancelPoMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelPoMutation, CancelPoMutationVariables>(CancelPoDocument, baseOptions);
      }
export type CancelPoMutationHookResult = ReturnType<typeof useCancelPoMutation>;
export type CancelPoMutationResult = ApolloReactCommon.MutationResult<CancelPoMutation>;
export type CancelPoMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelPoMutation, CancelPoMutationVariables>;
export const GetCentralisedPrItemsDocument = gql`
    query getCentralisedPRItems {
  getCentralisedPRItems {
    PRItemID
    WarehouseID
    Warehouse {
      Name
    }
    PRHeaderID
    PRHeader {
      CompanyID
      ExpectedDate
      DocNo
      DocDate
      RequestedBy
      ContactPerson
      ContactNo
      Instruction
      DeliveryLocationID
      DeliveryLocation {
        CompanyID
        Name
        Address
        ContactNo
        RecordStatus
      }
    }
    Quantity
    ItemID
    Item {
      RecordStatus
      Name
      Description
      UOMID
      UOM {
        Name
        Code
        RecordStatus
      }
      TaxSchemeID
      TaxScheme {
        Code
        Description
      }
    }
    UOMID
    UOM {
      Name
      Code
      RecordStatus
    }
    CostCentreID
    Remark
    IsPOGenerated
    rfqSubmissionSelected
    bulkPurchaseSelected
    masterFileSelected
  }
}
    `;

/**
 * __useGetCentralisedPrItemsQuery__
 *
 * To run a query within a React component, call `useGetCentralisedPrItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCentralisedPrItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCentralisedPrItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCentralisedPrItemsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCentralisedPrItemsQuery, GetCentralisedPrItemsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCentralisedPrItemsQuery, GetCentralisedPrItemsQueryVariables>(GetCentralisedPrItemsDocument, baseOptions);
      }
export function useGetCentralisedPrItemsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCentralisedPrItemsQuery, GetCentralisedPrItemsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCentralisedPrItemsQuery, GetCentralisedPrItemsQueryVariables>(GetCentralisedPrItemsDocument, baseOptions);
        }
export type GetCentralisedPrItemsQueryHookResult = ReturnType<typeof useGetCentralisedPrItemsQuery>;
export type GetCentralisedPrItemsLazyQueryHookResult = ReturnType<typeof useGetCentralisedPrItemsLazyQuery>;
export type GetCentralisedPrItemsQueryResult = ApolloReactCommon.QueryResult<GetCentralisedPrItemsQuery, GetCentralisedPrItemsQueryVariables>;
export const GetPrHeaderDocument = gql`
    query getPRHeader($PRHeaderID: String, $CompanyID: String, $orderByDesc: String, $ApprovalStatus: ApprovalStatus, $skip: Float, $take: Float, $searchValue: String) {
  getPRHeader(PRHeaderID: $PRHeaderID, CompanyID: $CompanyID, orderByDesc: $orderByDesc, ApprovalStatus: $ApprovalStatus, skip: $skip, take: $take, searchValue: $searchValue) {
    modTs
    modBy
    createdTs
    createdBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    PRHeaderID
    AccountID
    DocDate
    ExpectedDate
    RequestedBy
    DeliveryLocationID
    DeliveryLocation {
      DeliveryLocationID
      Name
      Address
      ContactNo
    }
    ContactPerson
    Instruction
    ContactNo
    DocNo
    DocDate
    RecordStatus
    ApprovalStatus
    CancelCloseRemark
    CancelCloseRemark
    CompanyID
    CheckboxPRDisabled
    ApprovalWorkflowPR
    Company {
      Name
      CompanyTax
      ContactNo
      CompanyRegNo
      Address {
        ID
        address
        address2
        siteName
        country
        city
        district
        state
        postCode
        postCodeName
        AddressType
      }
      GSTNo
      SSTNo
    }
    PRItem {
      PRItemID
      PRHeaderID
      ItemID
      Item {
        ItemID
        Name
        Description
        UOMID
      }
      UOMID
      UOM {
        UOMID
        Name
        Code
      }
      DeliveryLocationID
      DeliveryLocation {
        DeliveryLocationID
        Name
        Address
      }
      WarehouseID
      Warehouse {
        Name
        Code
        Address
      }
      CostCentreID
      CostCentre {
        CostCentreID
        Name
        Code
      }
      Quantity
      OutstandingQty
      Remark
      UnitCost
      TotalUnitCost
    }
  }
  getPRHeaderCount(PRHeaderID: $PRHeaderID, CompanyID: $CompanyID, ApprovalStatus: $ApprovalStatus)
}
    `;

/**
 * __useGetPrHeaderQuery__
 *
 * To run a query within a React component, call `useGetPrHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrHeaderQuery({
 *   variables: {
 *      PRHeaderID: // value for 'PRHeaderID'
 *      CompanyID: // value for 'CompanyID'
 *      orderByDesc: // value for 'orderByDesc'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetPrHeaderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPrHeaderQuery, GetPrHeaderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPrHeaderQuery, GetPrHeaderQueryVariables>(GetPrHeaderDocument, baseOptions);
      }
export function useGetPrHeaderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPrHeaderQuery, GetPrHeaderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPrHeaderQuery, GetPrHeaderQueryVariables>(GetPrHeaderDocument, baseOptions);
        }
export type GetPrHeaderQueryHookResult = ReturnType<typeof useGetPrHeaderQuery>;
export type GetPrHeaderLazyQueryHookResult = ReturnType<typeof useGetPrHeaderLazyQuery>;
export type GetPrHeaderQueryResult = ApolloReactCommon.QueryResult<GetPrHeaderQuery, GetPrHeaderQueryVariables>;
export const GetArAgingDocument = gql`
    query getARAging($AccountType: String, $CompanyID: String, $take: Float, $orderByDesc: String, $AgingID: String) {
  getARAging(AccountType: $AccountType, CompanyID: $CompanyID, take: $take, orderByDesc: $orderByDesc, AgingID: $AgingID) {
    AccountID
    AgingID
    CompanyID
    Period1
    Period2
    Period3
    Period4
    AccountType
  }
}
    `;

/**
 * __useGetArAgingQuery__
 *
 * To run a query within a React component, call `useGetArAgingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArAgingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArAgingQuery({
 *   variables: {
 *      AccountType: // value for 'AccountType'
 *      CompanyID: // value for 'CompanyID'
 *      take: // value for 'take'
 *      orderByDesc: // value for 'orderByDesc'
 *      AgingID: // value for 'AgingID'
 *   },
 * });
 */
export function useGetArAgingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArAgingQuery, GetArAgingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArAgingQuery, GetArAgingQueryVariables>(GetArAgingDocument, baseOptions);
      }
export function useGetArAgingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArAgingQuery, GetArAgingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArAgingQuery, GetArAgingQueryVariables>(GetArAgingDocument, baseOptions);
        }
export type GetArAgingQueryHookResult = ReturnType<typeof useGetArAgingQuery>;
export type GetArAgingLazyQueryHookResult = ReturnType<typeof useGetArAgingLazyQuery>;
export type GetArAgingQueryResult = ApolloReactCommon.QueryResult<GetArAgingQuery, GetArAgingQueryVariables>;
export const GetUserDocument = gql`
    query getUser($ID: String!) {
  getUser(ID: $ID) {
    ID
    name
    department
    email
    jobTitle
    contactNo
    superUser
    commonStatus
    userName
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
export function useGetUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = ApolloReactCommon.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const CreateGeneralPrDocument = gql`
    mutation createGeneralPR($generalPRInput: PRInput!, $generalPRItemInput: [PRItemInput!]!) {
  createGeneralPR(generalPRInput: $generalPRInput, generalPRItemInput: $generalPRItemInput) {
    PRHeaderID
    DocNo
  }
}
    `;
export type CreateGeneralPrMutationFn = ApolloReactCommon.MutationFunction<CreateGeneralPrMutation, CreateGeneralPrMutationVariables>;

/**
 * __useCreateGeneralPrMutation__
 *
 * To run a mutation, you first call `useCreateGeneralPrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGeneralPrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGeneralPrMutation, { data, loading, error }] = useCreateGeneralPrMutation({
 *   variables: {
 *      generalPRInput: // value for 'generalPRInput'
 *      generalPRItemInput: // value for 'generalPRItemInput'
 *   },
 * });
 */
export function useCreateGeneralPrMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGeneralPrMutation, CreateGeneralPrMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGeneralPrMutation, CreateGeneralPrMutationVariables>(CreateGeneralPrDocument, baseOptions);
      }
export type CreateGeneralPrMutationHookResult = ReturnType<typeof useCreateGeneralPrMutation>;
export type CreateGeneralPrMutationResult = ApolloReactCommon.MutationResult<CreateGeneralPrMutation>;
export type CreateGeneralPrMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGeneralPrMutation, CreateGeneralPrMutationVariables>;
export const UpdateGeneralPrDocument = gql`
    mutation updateGeneralPR($generalPRInput: PRInput!, $generalPRItemInput: [PRItemInput!]!, $PRHeaderID: String!) {
  updateGeneralPR(generalPRInput: $generalPRInput, generalPRItemInput: $generalPRItemInput, PRHeaderID: $PRHeaderID)
}
    `;
export type UpdateGeneralPrMutationFn = ApolloReactCommon.MutationFunction<UpdateGeneralPrMutation, UpdateGeneralPrMutationVariables>;

/**
 * __useUpdateGeneralPrMutation__
 *
 * To run a mutation, you first call `useUpdateGeneralPrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGeneralPrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGeneralPrMutation, { data, loading, error }] = useUpdateGeneralPrMutation({
 *   variables: {
 *      generalPRInput: // value for 'generalPRInput'
 *      generalPRItemInput: // value for 'generalPRItemInput'
 *      PRHeaderID: // value for 'PRHeaderID'
 *   },
 * });
 */
export function useUpdateGeneralPrMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateGeneralPrMutation, UpdateGeneralPrMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateGeneralPrMutation, UpdateGeneralPrMutationVariables>(UpdateGeneralPrDocument, baseOptions);
      }
export type UpdateGeneralPrMutationHookResult = ReturnType<typeof useUpdateGeneralPrMutation>;
export type UpdateGeneralPrMutationResult = ApolloReactCommon.MutationResult<UpdateGeneralPrMutation>;
export type UpdateGeneralPrMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateGeneralPrMutation, UpdateGeneralPrMutationVariables>;
export const GetGeneralPItemDocument = gql`
    query getGeneralPItem {
  getItem {
    ItemID
    Name
    UOMID
  }
}
    `;

/**
 * __useGetGeneralPItemQuery__
 *
 * To run a query within a React component, call `useGetGeneralPItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralPItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralPItemQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGeneralPItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralPItemQuery, GetGeneralPItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralPItemQuery, GetGeneralPItemQueryVariables>(GetGeneralPItemDocument, baseOptions);
      }
export function useGetGeneralPItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralPItemQuery, GetGeneralPItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralPItemQuery, GetGeneralPItemQueryVariables>(GetGeneralPItemDocument, baseOptions);
        }
export type GetGeneralPItemQueryHookResult = ReturnType<typeof useGetGeneralPItemQuery>;
export type GetGeneralPItemLazyQueryHookResult = ReturnType<typeof useGetGeneralPItemLazyQuery>;
export type GetGeneralPItemQueryResult = ApolloReactCommon.QueryResult<GetGeneralPItemQuery, GetGeneralPItemQueryVariables>;
export const GetOutstandingGeneralPrItemsDocument = gql`
    query getOutstandingGeneralPRItems($CompanyID: String!) {
  getOutstandingGeneralPRItems(CompanyID: $CompanyID) {
    PRItemID
    WarehouseID
    Warehouse {
      CompanyID
      Name
      Address
      ContactNo
      RecordStatus
    }
    PRHeaderID
    PRHeader {
      CompanyID
      ExpectedDate
      DocNo
      DocDate
      RequestedBy
      ContactPerson
      ContactNo
      Instruction
      DeliveryLocationID
      DeliveryLocation {
        CompanyID
        Name
        Address
        ContactNo
        RecordStatus
      }
    }
    Quantity
    ItemID
    UnitCost
    TotalUnitCost
    Item {
      RecordStatus
      Name
      Description
      UOMID
      UOM {
        Name
        Code
        RecordStatus
      }
      TaxSchemeID
      TaxScheme {
        Code
        Description
      }
      LatestPurchasePrice
    }
    UOMID
    UOM {
      Name
      Code
      RecordStatus
    }
    CostCentreID
    Remark
    IsPOGenerated
    rfqSubmissionSelected
    bulkPurchaseSelected
    masterFileSelected
  }
}
    `;

/**
 * __useGetOutstandingGeneralPrItemsQuery__
 *
 * To run a query within a React component, call `useGetOutstandingGeneralPrItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutstandingGeneralPrItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutstandingGeneralPrItemsQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetOutstandingGeneralPrItemsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOutstandingGeneralPrItemsQuery, GetOutstandingGeneralPrItemsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOutstandingGeneralPrItemsQuery, GetOutstandingGeneralPrItemsQueryVariables>(GetOutstandingGeneralPrItemsDocument, baseOptions);
      }
export function useGetOutstandingGeneralPrItemsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOutstandingGeneralPrItemsQuery, GetOutstandingGeneralPrItemsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOutstandingGeneralPrItemsQuery, GetOutstandingGeneralPrItemsQueryVariables>(GetOutstandingGeneralPrItemsDocument, baseOptions);
        }
export type GetOutstandingGeneralPrItemsQueryHookResult = ReturnType<typeof useGetOutstandingGeneralPrItemsQuery>;
export type GetOutstandingGeneralPrItemsLazyQueryHookResult = ReturnType<typeof useGetOutstandingGeneralPrItemsLazyQuery>;
export type GetOutstandingGeneralPrItemsQueryResult = ApolloReactCommon.QueryResult<GetOutstandingGeneralPrItemsQuery, GetOutstandingGeneralPrItemsQueryVariables>;
export const GetOutstandingGeneralPrItemsListingDocument = gql`
    query getOutstandingGeneralPRItemsListing($CompanyID: String!, $searchValue: String, $take: Float, $skip: Float) {
  getOutstandingGeneralPRItemsListing(CompanyID: $CompanyID, searchValue: $searchValue, take: $take, skip: $skip) {
    Data {
      PRItemID
      WarehouseID
      Warehouse {
        CompanyID
        Name
        Address
        ContactNo
        RecordStatus
      }
      PRHeaderID
      PRHeader {
        CompanyID
        ExpectedDate
        DocNo
        DocDate
        RequestedBy
        ContactPerson
        ContactNo
        Instruction
        DeliveryLocationID
        DeliveryLocation {
          CompanyID
          Name
          Address
          ContactNo
          RecordStatus
        }
      }
      Quantity
      ItemID
      UnitCost
      TotalUnitCost
      Item {
        RecordStatus
        Name
        Description
        UOMID
        UOM {
          Name
          Code
          RecordStatus
        }
        TaxSchemeID
        TaxScheme {
          Code
          Description
        }
        LatestPurchasePrice
      }
      UOMID
      UOM {
        Name
        Code
        RecordStatus
      }
      CostCentreID
      Remark
      IsPOGenerated
      rfqSubmissionSelected
      bulkPurchaseSelected
      masterFileSelected
    }
    TotalLength
  }
}
    `;

/**
 * __useGetOutstandingGeneralPrItemsListingQuery__
 *
 * To run a query within a React component, call `useGetOutstandingGeneralPrItemsListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutstandingGeneralPrItemsListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutstandingGeneralPrItemsListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      searchValue: // value for 'searchValue'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetOutstandingGeneralPrItemsListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOutstandingGeneralPrItemsListingQuery, GetOutstandingGeneralPrItemsListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOutstandingGeneralPrItemsListingQuery, GetOutstandingGeneralPrItemsListingQueryVariables>(GetOutstandingGeneralPrItemsListingDocument, baseOptions);
      }
export function useGetOutstandingGeneralPrItemsListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOutstandingGeneralPrItemsListingQuery, GetOutstandingGeneralPrItemsListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOutstandingGeneralPrItemsListingQuery, GetOutstandingGeneralPrItemsListingQueryVariables>(GetOutstandingGeneralPrItemsListingDocument, baseOptions);
        }
export type GetOutstandingGeneralPrItemsListingQueryHookResult = ReturnType<typeof useGetOutstandingGeneralPrItemsListingQuery>;
export type GetOutstandingGeneralPrItemsListingLazyQueryHookResult = ReturnType<typeof useGetOutstandingGeneralPrItemsListingLazyQuery>;
export type GetOutstandingGeneralPrItemsListingQueryResult = ApolloReactCommon.QueryResult<GetOutstandingGeneralPrItemsListingQuery, GetOutstandingGeneralPrItemsListingQueryVariables>;
export const ActionOnGprStatusDocument = gql`
    mutation actionOnGPRStatus($input: GPRActionInput!, $GeneralPRIDs: [String!]!) {
  actionOnGPRStatus(input: $input, GeneralPRIDs: $GeneralPRIDs)
}
    `;
export type ActionOnGprStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnGprStatusMutation, ActionOnGprStatusMutationVariables>;

/**
 * __useActionOnGprStatusMutation__
 *
 * To run a mutation, you first call `useActionOnGprStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnGprStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnGprStatusMutation, { data, loading, error }] = useActionOnGprStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *      GeneralPRIDs: // value for 'GeneralPRIDs'
 *   },
 * });
 */
export function useActionOnGprStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnGprStatusMutation, ActionOnGprStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnGprStatusMutation, ActionOnGprStatusMutationVariables>(ActionOnGprStatusDocument, baseOptions);
      }
export type ActionOnGprStatusMutationHookResult = ReturnType<typeof useActionOnGprStatusMutation>;
export type ActionOnGprStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnGprStatusMutation>;
export type ActionOnGprStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnGprStatusMutation, ActionOnGprStatusMutationVariables>;
export const DeleteGeneralPrDocument = gql`
    mutation deleteGeneralPR($PRHeaderID: String!) {
  deleteGeneralPR(PRHeaderID: $PRHeaderID)
}
    `;
export type DeleteGeneralPrMutationFn = ApolloReactCommon.MutationFunction<DeleteGeneralPrMutation, DeleteGeneralPrMutationVariables>;

/**
 * __useDeleteGeneralPrMutation__
 *
 * To run a mutation, you first call `useDeleteGeneralPrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGeneralPrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGeneralPrMutation, { data, loading, error }] = useDeleteGeneralPrMutation({
 *   variables: {
 *      PRHeaderID: // value for 'PRHeaderID'
 *   },
 * });
 */
export function useDeleteGeneralPrMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteGeneralPrMutation, DeleteGeneralPrMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteGeneralPrMutation, DeleteGeneralPrMutationVariables>(DeleteGeneralPrDocument, baseOptions);
      }
export type DeleteGeneralPrMutationHookResult = ReturnType<typeof useDeleteGeneralPrMutation>;
export type DeleteGeneralPrMutationResult = ApolloReactCommon.MutationResult<DeleteGeneralPrMutation>;
export type DeleteGeneralPrMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteGeneralPrMutation, DeleteGeneralPrMutationVariables>;
export const CancelClosePrDocument = gql`
    mutation cancelClosePR($PRHeaderID: String!, $input: GPRActionInput!) {
  cancelClosePR(PRHeaderID: $PRHeaderID, input: $input)
}
    `;
export type CancelClosePrMutationFn = ApolloReactCommon.MutationFunction<CancelClosePrMutation, CancelClosePrMutationVariables>;

/**
 * __useCancelClosePrMutation__
 *
 * To run a mutation, you first call `useCancelClosePrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelClosePrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelClosePrMutation, { data, loading, error }] = useCancelClosePrMutation({
 *   variables: {
 *      PRHeaderID: // value for 'PRHeaderID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelClosePrMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelClosePrMutation, CancelClosePrMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelClosePrMutation, CancelClosePrMutationVariables>(CancelClosePrDocument, baseOptions);
      }
export type CancelClosePrMutationHookResult = ReturnType<typeof useCancelClosePrMutation>;
export type CancelClosePrMutationResult = ApolloReactCommon.MutationResult<CancelClosePrMutation>;
export type CancelClosePrMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelClosePrMutation, CancelClosePrMutationVariables>;
export const CheckingCancelClosePrDocument = gql`
    query checkingCancelClosePR($PRHeaderID: String!) {
  checkingCancelClosePR(PRHeaderID: $PRHeaderID)
}
    `;

/**
 * __useCheckingCancelClosePrQuery__
 *
 * To run a query within a React component, call `useCheckingCancelClosePrQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckingCancelClosePrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckingCancelClosePrQuery({
 *   variables: {
 *      PRHeaderID: // value for 'PRHeaderID'
 *   },
 * });
 */
export function useCheckingCancelClosePrQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckingCancelClosePrQuery, CheckingCancelClosePrQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckingCancelClosePrQuery, CheckingCancelClosePrQueryVariables>(CheckingCancelClosePrDocument, baseOptions);
      }
export function useCheckingCancelClosePrLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckingCancelClosePrQuery, CheckingCancelClosePrQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckingCancelClosePrQuery, CheckingCancelClosePrQueryVariables>(CheckingCancelClosePrDocument, baseOptions);
        }
export type CheckingCancelClosePrQueryHookResult = ReturnType<typeof useCheckingCancelClosePrQuery>;
export type CheckingCancelClosePrLazyQueryHookResult = ReturnType<typeof useCheckingCancelClosePrLazyQuery>;
export type CheckingCancelClosePrQueryResult = ApolloReactCommon.QueryResult<CheckingCancelClosePrQuery, CheckingCancelClosePrQueryVariables>;
export const GetRfqHeaderDocument = gql`
    query getRFQHeader($RFQHeaderID: String, $CompanyID: String, $ApprovalStatus: ApprovalStatus, $orderByDesc: String) {
  getRFQHeader(RFQHeaderID: $RFQHeaderID, CompanyID: $CompanyID, ApprovalStatus: $ApprovalStatus, orderByDesc: $orderByDesc) {
    modTs
    modBy
    createdTs
    createdBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    RFQHeaderID
    AccountID
    DocNo
    DocDate
    Description
    ExpectedDate
    CancelCloseDate
    CancelCloseRemark
    RecordStatus
    ApprovalStatus
    ProfileInfo
    PRHeaderInfo
    RFQSupplierInvitation {
      RFQSupplierInvitationID
      SupplierID
      Supplier {
        CreditorAccountID
        CompanyName
        CreditTerm
        ContactNo
        Email
      }
      RecordStatus
      RFQSupplierSubmission {
        RFQSupplierSubmissionID
        RefNo
        CreditTerm
        SubmittedDate
        EffectiveDate
        ExpiryDate
        Remarks
      }
      LatestSubmittedQuotation {
        RefNo
        CreditTerm
        SubmittedDate
        EffectiveDate
        ExpiryDate
        Remarks
        RFQSubmissionItem {
          RFQSubmissionItemID
          RFQSupplierSubmissionID
          RFQItemID
          UnitPrice
          Remarks
          IsSelected
          Cheapest(RFQHeaderID: $RFQHeaderID)
          TotalPrice(RFQHeaderID: $RFQHeaderID)
        }
      }
    }
    RFQItem {
      RFQHeaderID
      RFQItemID
      UOM {
        UOMID
        Name
        Code
      }
      PRItem {
        ItemID
        PRItemID
        PRHeaderID
        Remark
        Quantity
        UOMID
        UOM {
          UOMID
          Code
          Name
        }
        Item {
          ItemID
          Name
          LatestPurchase
        }
      }
      RFQSubmissionItem {
        RFQSubmissionItemID
        RFQSupplierSubmissionID
        RFQItemID
        UnitPrice
        Remarks
        IsSelected
        Cheapest
      }
    }
  }
}
    `;

/**
 * __useGetRfqHeaderQuery__
 *
 * To run a query within a React component, call `useGetRfqHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRfqHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRfqHeaderQuery({
 *   variables: {
 *      RFQHeaderID: // value for 'RFQHeaderID'
 *      CompanyID: // value for 'CompanyID'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetRfqHeaderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRfqHeaderQuery, GetRfqHeaderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRfqHeaderQuery, GetRfqHeaderQueryVariables>(GetRfqHeaderDocument, baseOptions);
      }
export function useGetRfqHeaderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRfqHeaderQuery, GetRfqHeaderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRfqHeaderQuery, GetRfqHeaderQueryVariables>(GetRfqHeaderDocument, baseOptions);
        }
export type GetRfqHeaderQueryHookResult = ReturnType<typeof useGetRfqHeaderQuery>;
export type GetRfqHeaderLazyQueryHookResult = ReturnType<typeof useGetRfqHeaderLazyQuery>;
export type GetRfqHeaderQueryResult = ApolloReactCommon.QueryResult<GetRfqHeaderQuery, GetRfqHeaderQueryVariables>;
export const GetGeneralPrForRfqDocument = gql`
    query getGeneralPRForRFQ($PRHeaderID: String) {
  getPRHeader(PRHeaderID: $PRHeaderID) {
    PRHeaderID
    ExpectedDate
    DocNo
    RecordStatus
    ExpectedDate
  }
}
    `;

/**
 * __useGetGeneralPrForRfqQuery__
 *
 * To run a query within a React component, call `useGetGeneralPrForRfqQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralPrForRfqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralPrForRfqQuery({
 *   variables: {
 *      PRHeaderID: // value for 'PRHeaderID'
 *   },
 * });
 */
export function useGetGeneralPrForRfqQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralPrForRfqQuery, GetGeneralPrForRfqQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralPrForRfqQuery, GetGeneralPrForRfqQueryVariables>(GetGeneralPrForRfqDocument, baseOptions);
      }
export function useGetGeneralPrForRfqLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralPrForRfqQuery, GetGeneralPrForRfqQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralPrForRfqQuery, GetGeneralPrForRfqQueryVariables>(GetGeneralPrForRfqDocument, baseOptions);
        }
export type GetGeneralPrForRfqQueryHookResult = ReturnType<typeof useGetGeneralPrForRfqQuery>;
export type GetGeneralPrForRfqLazyQueryHookResult = ReturnType<typeof useGetGeneralPrForRfqLazyQuery>;
export type GetGeneralPrForRfqQueryResult = ApolloReactCommon.QueryResult<GetGeneralPrForRfqQuery, GetGeneralPrForRfqQueryVariables>;
export const GetRfqSupplierInvitationDocument = gql`
    query getRFQSupplierInvitation($RFQHeaderID: String, $orderByDesc: String) {
  getRFQSupplierInvitation(RFQHeaderID: $RFQHeaderID, orderByDesc: $orderByDesc) {
    RFQSupplierInvitationID
    RFQHeaderID
    SupplierID
    RFQHeader {
      RFQHeaderID
      RFQSupplierInvitation {
        RFQSupplierInvitationID
        SupplierID
        Supplier {
          CompanyName
          CompanyRegNo
          ContactNo
          Address
          CreditTerm
          Remark
        }
      }
    }
    RFQSupplierSubmission {
      RFQSupplierSubmissionID
      RefNo
      CreditTerm
      SubmittedDate
      EffectiveDate
      ExpiryDate
      Remarks
    }
    LatestSubmittedQuotation {
      RFQSupplierSubmissionID
      RefNo
      CreditTerm
      SubmittedDate
    }
    Supplier {
      CreditorAccountID
      CompanyName
    }
  }
}
    `;

/**
 * __useGetRfqSupplierInvitationQuery__
 *
 * To run a query within a React component, call `useGetRfqSupplierInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRfqSupplierInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRfqSupplierInvitationQuery({
 *   variables: {
 *      RFQHeaderID: // value for 'RFQHeaderID'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetRfqSupplierInvitationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRfqSupplierInvitationQuery, GetRfqSupplierInvitationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRfqSupplierInvitationQuery, GetRfqSupplierInvitationQueryVariables>(GetRfqSupplierInvitationDocument, baseOptions);
      }
export function useGetRfqSupplierInvitationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRfqSupplierInvitationQuery, GetRfqSupplierInvitationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRfqSupplierInvitationQuery, GetRfqSupplierInvitationQueryVariables>(GetRfqSupplierInvitationDocument, baseOptions);
        }
export type GetRfqSupplierInvitationQueryHookResult = ReturnType<typeof useGetRfqSupplierInvitationQuery>;
export type GetRfqSupplierInvitationLazyQueryHookResult = ReturnType<typeof useGetRfqSupplierInvitationLazyQuery>;
export type GetRfqSupplierInvitationQueryResult = ApolloReactCommon.QueryResult<GetRfqSupplierInvitationQuery, GetRfqSupplierInvitationQueryVariables>;
export const GetRfqSupplierSubmissionDocument = gql`
    query getRFQSupplierSubmission($RFQSupplierSubmissionID: String, $RFQHeaderID: String, $SupplierID: String, $orderByDesc: String, $RFQSupplierInvitationID: String) {
  getRFQSupplierSubmission(RFQSupplierSubmissionID: $RFQSupplierSubmissionID, RFQHeaderID: $RFQHeaderID, SupplierID: $SupplierID, orderByDesc: $orderByDesc, RFQSupplierInvitationID: $RFQSupplierInvitationID) {
    RFQSupplierSubmissionID
    RFQSupplierInvitationID
    RefNo
    CreditTerm
    SubmittedDate
    EffectiveDate
    ExpiryDate
    Remarks
    RecordStatus
    RFQSubmissionItem {
      RFQSubmissionItemID
      UnitPrice
      Remarks
      RFQItemID
      RFQItem {
        RFQItemID
        PRItemID
        PRItem {
          PRItemID
          Quantity
          ItemID
          Item {
            ItemID
            Name
            UOMID
            UOM {
              UOMID
              Name
              Code
            }
          }
        }
      }
    }
    Supplier {
      CreditorAccountID
      CompanyName
    }
  }
}
    `;

/**
 * __useGetRfqSupplierSubmissionQuery__
 *
 * To run a query within a React component, call `useGetRfqSupplierSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRfqSupplierSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRfqSupplierSubmissionQuery({
 *   variables: {
 *      RFQSupplierSubmissionID: // value for 'RFQSupplierSubmissionID'
 *      RFQHeaderID: // value for 'RFQHeaderID'
 *      SupplierID: // value for 'SupplierID'
 *      orderByDesc: // value for 'orderByDesc'
 *      RFQSupplierInvitationID: // value for 'RFQSupplierInvitationID'
 *   },
 * });
 */
export function useGetRfqSupplierSubmissionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRfqSupplierSubmissionQuery, GetRfqSupplierSubmissionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRfqSupplierSubmissionQuery, GetRfqSupplierSubmissionQueryVariables>(GetRfqSupplierSubmissionDocument, baseOptions);
      }
export function useGetRfqSupplierSubmissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRfqSupplierSubmissionQuery, GetRfqSupplierSubmissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRfqSupplierSubmissionQuery, GetRfqSupplierSubmissionQueryVariables>(GetRfqSupplierSubmissionDocument, baseOptions);
        }
export type GetRfqSupplierSubmissionQueryHookResult = ReturnType<typeof useGetRfqSupplierSubmissionQuery>;
export type GetRfqSupplierSubmissionLazyQueryHookResult = ReturnType<typeof useGetRfqSupplierSubmissionLazyQuery>;
export type GetRfqSupplierSubmissionQueryResult = ApolloReactCommon.QueryResult<GetRfqSupplierSubmissionQuery, GetRfqSupplierSubmissionQueryVariables>;
export const GetInvitedSupplierDocument = gql`
    query getInvitedSupplier($RFQHeaderID: String!, $companyID: String!) {
  getInvitedSupplier(RFQHeaderID: $RFQHeaderID, companyID: $companyID)
}
    `;

/**
 * __useGetInvitedSupplierQuery__
 *
 * To run a query within a React component, call `useGetInvitedSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitedSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitedSupplierQuery({
 *   variables: {
 *      RFQHeaderID: // value for 'RFQHeaderID'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetInvitedSupplierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInvitedSupplierQuery, GetInvitedSupplierQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInvitedSupplierQuery, GetInvitedSupplierQueryVariables>(GetInvitedSupplierDocument, baseOptions);
      }
export function useGetInvitedSupplierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInvitedSupplierQuery, GetInvitedSupplierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInvitedSupplierQuery, GetInvitedSupplierQueryVariables>(GetInvitedSupplierDocument, baseOptions);
        }
export type GetInvitedSupplierQueryHookResult = ReturnType<typeof useGetInvitedSupplierQuery>;
export type GetInvitedSupplierLazyQueryHookResult = ReturnType<typeof useGetInvitedSupplierLazyQuery>;
export type GetInvitedSupplierQueryResult = ApolloReactCommon.QueryResult<GetInvitedSupplierQuery, GetInvitedSupplierQueryVariables>;
export const CreateGeneralRfqDocument = gql`
    mutation createGeneralRFQ($RFQInput: RFQInput!, $RFQItemInput: [RFQItemInput!]!) {
  createGeneralRFQ(RFQInput: $RFQInput, RFQItemInput: $RFQItemInput)
}
    `;
export type CreateGeneralRfqMutationFn = ApolloReactCommon.MutationFunction<CreateGeneralRfqMutation, CreateGeneralRfqMutationVariables>;

/**
 * __useCreateGeneralRfqMutation__
 *
 * To run a mutation, you first call `useCreateGeneralRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGeneralRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGeneralRfqMutation, { data, loading, error }] = useCreateGeneralRfqMutation({
 *   variables: {
 *      RFQInput: // value for 'RFQInput'
 *      RFQItemInput: // value for 'RFQItemInput'
 *   },
 * });
 */
export function useCreateGeneralRfqMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGeneralRfqMutation, CreateGeneralRfqMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGeneralRfqMutation, CreateGeneralRfqMutationVariables>(CreateGeneralRfqDocument, baseOptions);
      }
export type CreateGeneralRfqMutationHookResult = ReturnType<typeof useCreateGeneralRfqMutation>;
export type CreateGeneralRfqMutationResult = ApolloReactCommon.MutationResult<CreateGeneralRfqMutation>;
export type CreateGeneralRfqMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGeneralRfqMutation, CreateGeneralRfqMutationVariables>;
export const UpdateGeneralRfqDocument = gql`
    mutation updateGeneralRFQ($generalRFQInput: RFQInput!, $generalRFQItemInput: [RFQItemInput!]!, $RFQHeaderID: String!) {
  updateGeneralRFQ(generalRFQInput: $generalRFQInput, generalRFQItemInput: $generalRFQItemInput, RFQHeaderID: $RFQHeaderID)
}
    `;
export type UpdateGeneralRfqMutationFn = ApolloReactCommon.MutationFunction<UpdateGeneralRfqMutation, UpdateGeneralRfqMutationVariables>;

/**
 * __useUpdateGeneralRfqMutation__
 *
 * To run a mutation, you first call `useUpdateGeneralRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGeneralRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGeneralRfqMutation, { data, loading, error }] = useUpdateGeneralRfqMutation({
 *   variables: {
 *      generalRFQInput: // value for 'generalRFQInput'
 *      generalRFQItemInput: // value for 'generalRFQItemInput'
 *      RFQHeaderID: // value for 'RFQHeaderID'
 *   },
 * });
 */
export function useUpdateGeneralRfqMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateGeneralRfqMutation, UpdateGeneralRfqMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateGeneralRfqMutation, UpdateGeneralRfqMutationVariables>(UpdateGeneralRfqDocument, baseOptions);
      }
export type UpdateGeneralRfqMutationHookResult = ReturnType<typeof useUpdateGeneralRfqMutation>;
export type UpdateGeneralRfqMutationResult = ApolloReactCommon.MutationResult<UpdateGeneralRfqMutation>;
export type UpdateGeneralRfqMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateGeneralRfqMutation, UpdateGeneralRfqMutationVariables>;
export const DeleteGeneralRfqDocument = gql`
    mutation deleteGeneralRFQ($RFQHeaderID: String!) {
  deleteGeneralRFQ(RFQHeaderID: $RFQHeaderID)
}
    `;
export type DeleteGeneralRfqMutationFn = ApolloReactCommon.MutationFunction<DeleteGeneralRfqMutation, DeleteGeneralRfqMutationVariables>;

/**
 * __useDeleteGeneralRfqMutation__
 *
 * To run a mutation, you first call `useDeleteGeneralRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGeneralRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGeneralRfqMutation, { data, loading, error }] = useDeleteGeneralRfqMutation({
 *   variables: {
 *      RFQHeaderID: // value for 'RFQHeaderID'
 *   },
 * });
 */
export function useDeleteGeneralRfqMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteGeneralRfqMutation, DeleteGeneralRfqMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteGeneralRfqMutation, DeleteGeneralRfqMutationVariables>(DeleteGeneralRfqDocument, baseOptions);
      }
export type DeleteGeneralRfqMutationHookResult = ReturnType<typeof useDeleteGeneralRfqMutation>;
export type DeleteGeneralRfqMutationResult = ApolloReactCommon.MutationResult<DeleteGeneralRfqMutation>;
export type DeleteGeneralRfqMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteGeneralRfqMutation, DeleteGeneralRfqMutationVariables>;
export const GetPrItemByPridForRfqDocument = gql`
    query getPRItemByPRIDForRFQ($PRHeaderID: String!, $CompanyID: String!) {
  getPRItemByPRIDForRFQ(PRHeaderID: $PRHeaderID, CompanyID: $CompanyID)
}
    `;

/**
 * __useGetPrItemByPridForRfqQuery__
 *
 * To run a query within a React component, call `useGetPrItemByPridForRfqQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrItemByPridForRfqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrItemByPridForRfqQuery({
 *   variables: {
 *      PRHeaderID: // value for 'PRHeaderID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetPrItemByPridForRfqQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPrItemByPridForRfqQuery, GetPrItemByPridForRfqQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPrItemByPridForRfqQuery, GetPrItemByPridForRfqQueryVariables>(GetPrItemByPridForRfqDocument, baseOptions);
      }
export function useGetPrItemByPridForRfqLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPrItemByPridForRfqQuery, GetPrItemByPridForRfqQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPrItemByPridForRfqQuery, GetPrItemByPridForRfqQueryVariables>(GetPrItemByPridForRfqDocument, baseOptions);
        }
export type GetPrItemByPridForRfqQueryHookResult = ReturnType<typeof useGetPrItemByPridForRfqQuery>;
export type GetPrItemByPridForRfqLazyQueryHookResult = ReturnType<typeof useGetPrItemByPridForRfqLazyQuery>;
export type GetPrItemByPridForRfqQueryResult = ApolloReactCommon.QueryResult<GetPrItemByPridForRfqQuery, GetPrItemByPridForRfqQueryVariables>;
export const InviteSupplierDocument = gql`
    mutation inviteSupplier($RFQHeaderID: String!, $SupplierIDs: [String!]!) {
  inviteSupplier(RFQHeaderID: $RFQHeaderID, SupplierIDs: $SupplierIDs)
}
    `;
export type InviteSupplierMutationFn = ApolloReactCommon.MutationFunction<InviteSupplierMutation, InviteSupplierMutationVariables>;

/**
 * __useInviteSupplierMutation__
 *
 * To run a mutation, you first call `useInviteSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteSupplierMutation, { data, loading, error }] = useInviteSupplierMutation({
 *   variables: {
 *      RFQHeaderID: // value for 'RFQHeaderID'
 *      SupplierIDs: // value for 'SupplierIDs'
 *   },
 * });
 */
export function useInviteSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InviteSupplierMutation, InviteSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<InviteSupplierMutation, InviteSupplierMutationVariables>(InviteSupplierDocument, baseOptions);
      }
export type InviteSupplierMutationHookResult = ReturnType<typeof useInviteSupplierMutation>;
export type InviteSupplierMutationResult = ApolloReactCommon.MutationResult<InviteSupplierMutation>;
export type InviteSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteSupplierMutation, InviteSupplierMutationVariables>;
export const UninviteSupplierDocument = gql`
    mutation uninviteSupplier($RFQHeaderID: String!, $SupplierID: String!) {
  uninviteSupplier(RFQHeaderID: $RFQHeaderID, SupplierID: $SupplierID)
}
    `;
export type UninviteSupplierMutationFn = ApolloReactCommon.MutationFunction<UninviteSupplierMutation, UninviteSupplierMutationVariables>;

/**
 * __useUninviteSupplierMutation__
 *
 * To run a mutation, you first call `useUninviteSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUninviteSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uninviteSupplierMutation, { data, loading, error }] = useUninviteSupplierMutation({
 *   variables: {
 *      RFQHeaderID: // value for 'RFQHeaderID'
 *      SupplierID: // value for 'SupplierID'
 *   },
 * });
 */
export function useUninviteSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UninviteSupplierMutation, UninviteSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<UninviteSupplierMutation, UninviteSupplierMutationVariables>(UninviteSupplierDocument, baseOptions);
      }
export type UninviteSupplierMutationHookResult = ReturnType<typeof useUninviteSupplierMutation>;
export type UninviteSupplierMutationResult = ApolloReactCommon.MutationResult<UninviteSupplierMutation>;
export type UninviteSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<UninviteSupplierMutation, UninviteSupplierMutationVariables>;
export const SubmitGeneralRfqQuoteDocument = gql`
    mutation submitGeneralRFQQuote($input: RFQSupplierSubmissionInput!) {
  submitGeneralRFQQuote(input: $input)
}
    `;
export type SubmitGeneralRfqQuoteMutationFn = ApolloReactCommon.MutationFunction<SubmitGeneralRfqQuoteMutation, SubmitGeneralRfqQuoteMutationVariables>;

/**
 * __useSubmitGeneralRfqQuoteMutation__
 *
 * To run a mutation, you first call `useSubmitGeneralRfqQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitGeneralRfqQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitGeneralRfqQuoteMutation, { data, loading, error }] = useSubmitGeneralRfqQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitGeneralRfqQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitGeneralRfqQuoteMutation, SubmitGeneralRfqQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitGeneralRfqQuoteMutation, SubmitGeneralRfqQuoteMutationVariables>(SubmitGeneralRfqQuoteDocument, baseOptions);
      }
export type SubmitGeneralRfqQuoteMutationHookResult = ReturnType<typeof useSubmitGeneralRfqQuoteMutation>;
export type SubmitGeneralRfqQuoteMutationResult = ApolloReactCommon.MutationResult<SubmitGeneralRfqQuoteMutation>;
export type SubmitGeneralRfqQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitGeneralRfqQuoteMutation, SubmitGeneralRfqQuoteMutationVariables>;
export const DeleteRfqSupplierSubmissionDocument = gql`
    mutation deleteRFQSupplierSubmission($RFQSupplierSubmissionID: String!) {
  deleteRFQSupplierSubmission(RFQSupplierSubmissionID: $RFQSupplierSubmissionID)
}
    `;
export type DeleteRfqSupplierSubmissionMutationFn = ApolloReactCommon.MutationFunction<DeleteRfqSupplierSubmissionMutation, DeleteRfqSupplierSubmissionMutationVariables>;

/**
 * __useDeleteRfqSupplierSubmissionMutation__
 *
 * To run a mutation, you first call `useDeleteRfqSupplierSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRfqSupplierSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRfqSupplierSubmissionMutation, { data, loading, error }] = useDeleteRfqSupplierSubmissionMutation({
 *   variables: {
 *      RFQSupplierSubmissionID: // value for 'RFQSupplierSubmissionID'
 *   },
 * });
 */
export function useDeleteRfqSupplierSubmissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRfqSupplierSubmissionMutation, DeleteRfqSupplierSubmissionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRfqSupplierSubmissionMutation, DeleteRfqSupplierSubmissionMutationVariables>(DeleteRfqSupplierSubmissionDocument, baseOptions);
      }
export type DeleteRfqSupplierSubmissionMutationHookResult = ReturnType<typeof useDeleteRfqSupplierSubmissionMutation>;
export type DeleteRfqSupplierSubmissionMutationResult = ApolloReactCommon.MutationResult<DeleteRfqSupplierSubmissionMutation>;
export type DeleteRfqSupplierSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRfqSupplierSubmissionMutation, DeleteRfqSupplierSubmissionMutationVariables>;
export const SubmitEvaluationSelectionDocument = gql`
    mutation submitEvaluationSelection($RFQInput: RFQEvalInput!, $RFQEvalSelectInput: [RFQEvalSelectInput!]!) {
  submitEvaluationSelection(RFQInput: $RFQInput, RFQEvalSelectInput: $RFQEvalSelectInput)
}
    `;
export type SubmitEvaluationSelectionMutationFn = ApolloReactCommon.MutationFunction<SubmitEvaluationSelectionMutation, SubmitEvaluationSelectionMutationVariables>;

/**
 * __useSubmitEvaluationSelectionMutation__
 *
 * To run a mutation, you first call `useSubmitEvaluationSelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitEvaluationSelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitEvaluationSelectionMutation, { data, loading, error }] = useSubmitEvaluationSelectionMutation({
 *   variables: {
 *      RFQInput: // value for 'RFQInput'
 *      RFQEvalSelectInput: // value for 'RFQEvalSelectInput'
 *   },
 * });
 */
export function useSubmitEvaluationSelectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitEvaluationSelectionMutation, SubmitEvaluationSelectionMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitEvaluationSelectionMutation, SubmitEvaluationSelectionMutationVariables>(SubmitEvaluationSelectionDocument, baseOptions);
      }
export type SubmitEvaluationSelectionMutationHookResult = ReturnType<typeof useSubmitEvaluationSelectionMutation>;
export type SubmitEvaluationSelectionMutationResult = ApolloReactCommon.MutationResult<SubmitEvaluationSelectionMutation>;
export type SubmitEvaluationSelectionMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitEvaluationSelectionMutation, SubmitEvaluationSelectionMutationVariables>;
export const ActionOnRfqStatusDocument = gql`
    mutation actionOnRFQStatus($CompanyID: String!, $input: RFQRejectInput!, $RFQHeaderID: [String!]!) {
  actionOnRFQStatus(CompanyID: $CompanyID, input: $input, RFQHeaderID: $RFQHeaderID)
}
    `;
export type ActionOnRfqStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnRfqStatusMutation, ActionOnRfqStatusMutationVariables>;

/**
 * __useActionOnRfqStatusMutation__
 *
 * To run a mutation, you first call `useActionOnRfqStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnRfqStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnRfqStatusMutation, { data, loading, error }] = useActionOnRfqStatusMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      input: // value for 'input'
 *      RFQHeaderID: // value for 'RFQHeaderID'
 *   },
 * });
 */
export function useActionOnRfqStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnRfqStatusMutation, ActionOnRfqStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnRfqStatusMutation, ActionOnRfqStatusMutationVariables>(ActionOnRfqStatusDocument, baseOptions);
      }
export type ActionOnRfqStatusMutationHookResult = ReturnType<typeof useActionOnRfqStatusMutation>;
export type ActionOnRfqStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnRfqStatusMutation>;
export type ActionOnRfqStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnRfqStatusMutation, ActionOnRfqStatusMutationVariables>;
export const GetCreditorContactDocument = gql`
    query getCreditorContact($orderByDesc: String, $AccountID: String, $CreditorContactID: String, $CreditorAccountID: String) {
  getCreditorContact(orderByDesc: $orderByDesc, AccountID: $AccountID, CreditorContactID: $CreditorContactID, CreditorAccountID: $CreditorAccountID) {
    CreditorContactID
    Name
    ContactNo
    Designation
    Email
    IsDefault
  }
}
    `;

/**
 * __useGetCreditorContactQuery__
 *
 * To run a query within a React component, call `useGetCreditorContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorContactQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      AccountID: // value for 'AccountID'
 *      CreditorContactID: // value for 'CreditorContactID'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *   },
 * });
 */
export function useGetCreditorContactQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorContactQuery, GetCreditorContactQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorContactQuery, GetCreditorContactQueryVariables>(GetCreditorContactDocument, baseOptions);
      }
export function useGetCreditorContactLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorContactQuery, GetCreditorContactQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorContactQuery, GetCreditorContactQueryVariables>(GetCreditorContactDocument, baseOptions);
        }
export type GetCreditorContactQueryHookResult = ReturnType<typeof useGetCreditorContactQuery>;
export type GetCreditorContactLazyQueryHookResult = ReturnType<typeof useGetCreditorContactLazyQuery>;
export type GetCreditorContactQueryResult = ApolloReactCommon.QueryResult<GetCreditorContactQuery, GetCreditorContactQueryVariables>;
export const GetRfQbyStatusDocument = gql`
    query getRFQbyStatus($RFQHeaderID: String, $CompanyID: String!, $StatusArr: [String!]!) {
  getRFQbyStatus(CompanyID: $CompanyID, StatusArr: $StatusArr) {
    modTs
    modBy
    createdTs
    createdBy
    RFQHeaderID
    AccountID
    DocNo
    DocDate
    Description
    ExpectedDate
    CancelCloseDate
    CancelCloseRemark
    RecordStatus
    ApprovalStatus
    ProfileInfo
    PRHeaderInfo
    RFQSupplierInvitation {
      RFQSupplierInvitationID
      SupplierID
      Supplier {
        CreditorAccountID
        CompanyName
        CreditTerm
        ContactNo
        Email
      }
      RecordStatus
      RFQSupplierSubmission {
        RFQSupplierSubmissionID
        RefNo
        CreditTerm
        SubmittedDate
        EffectiveDate
        ExpiryDate
        Remarks
      }
      LatestSubmittedQuotation {
        RefNo
        CreditTerm
        SubmittedDate
        EffectiveDate
        ExpiryDate
        RecordStatus
        Remarks
        RFQSubmissionItem {
          RFQSubmissionItemID
          RFQSupplierSubmissionID
          RFQItemID
          UnitPrice
          Remarks
          IsSelected
          Cheapest(RFQHeaderID: $RFQHeaderID)
          TotalPrice(RFQHeaderID: $RFQHeaderID)
        }
      }
    }
    RFQItem {
      RFQHeaderID
      RFQItemID
      UOM {
        UOMID
        Name
        Code
      }
      PRItem {
        ItemID
        PRItemID
        PRHeaderID
        Remark
        Quantity
        UOMID
        UOM {
          UOMID
          Code
          Name
        }
        Item {
          ItemID
          Name
          LatestPurchase
        }
      }
      RFQSubmissionItem {
        RFQSubmissionItemID
        RFQSupplierSubmissionID
        RFQItemID
        UnitPrice
        Remarks
        IsSelected
        Cheapest
      }
    }
  }
}
    `;

/**
 * __useGetRfQbyStatusQuery__
 *
 * To run a query within a React component, call `useGetRfQbyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRfQbyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRfQbyStatusQuery({
 *   variables: {
 *      RFQHeaderID: // value for 'RFQHeaderID'
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *   },
 * });
 */
export function useGetRfQbyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRfQbyStatusQuery, GetRfQbyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRfQbyStatusQuery, GetRfQbyStatusQueryVariables>(GetRfQbyStatusDocument, baseOptions);
      }
export function useGetRfQbyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRfQbyStatusQuery, GetRfQbyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRfQbyStatusQuery, GetRfQbyStatusQueryVariables>(GetRfQbyStatusDocument, baseOptions);
        }
export type GetRfQbyStatusQueryHookResult = ReturnType<typeof useGetRfQbyStatusQuery>;
export type GetRfQbyStatusLazyQueryHookResult = ReturnType<typeof useGetRfQbyStatusLazyQuery>;
export type GetRfQbyStatusQueryResult = ApolloReactCommon.QueryResult<GetRfQbyStatusQuery, GetRfQbyStatusQueryVariables>;
export const GetPrNoListDocument = gql`
    query getPRNoList($CompanyID: String!, $PRHeaderID: String) {
  getPRNoList(CompanyID: $CompanyID, PRHeaderID: $PRHeaderID)
}
    `;

/**
 * __useGetPrNoListQuery__
 *
 * To run a query within a React component, call `useGetPrNoListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrNoListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrNoListQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      PRHeaderID: // value for 'PRHeaderID'
 *   },
 * });
 */
export function useGetPrNoListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPrNoListQuery, GetPrNoListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPrNoListQuery, GetPrNoListQueryVariables>(GetPrNoListDocument, baseOptions);
      }
export function useGetPrNoListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPrNoListQuery, GetPrNoListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPrNoListQuery, GetPrNoListQueryVariables>(GetPrNoListDocument, baseOptions);
        }
export type GetPrNoListQueryHookResult = ReturnType<typeof useGetPrNoListQuery>;
export type GetPrNoListLazyQueryHookResult = ReturnType<typeof useGetPrNoListLazyQuery>;
export type GetPrNoListQueryResult = ApolloReactCommon.QueryResult<GetPrNoListQuery, GetPrNoListQueryVariables>;
export const GetRfQbyStatusListingDocument = gql`
    query getRFQbyStatusListing($RFQHeaderID: String, $CompanyID: String!, $StatusArr: [String!]!, $skip: Float, $take: Float, $searchValue: String) {
  getRFQbyStatusListing(CompanyID: $CompanyID, StatusArr: $StatusArr, skip: $skip, take: $take, searchValue: $searchValue) {
    Data {
      modTs
      modBy
      createdTs
      createdBy
      RFQHeaderID
      AccountID
      DocNo
      DocDate
      Description
      ApprovalWorkflowRFQ
      ExpectedDate
      CancelCloseDate
      CancelCloseRemark
      RecordStatus
      ApprovalStatus
      ProfileInfo
      PRHeaderInfo
      RFQSupplierInvitation {
        RFQSupplierInvitationID
        SupplierID
        Supplier {
          CreditorAccountID
          CompanyName
          CreditTerm
          ContactNo
          Email
        }
        RecordStatus
        RFQSupplierSubmission {
          RFQSupplierSubmissionID
          RefNo
          CreditTerm
          SubmittedDate
          EffectiveDate
          ExpiryDate
          Remarks
        }
        LatestSubmittedQuotation {
          RefNo
          CreditTerm
          SubmittedDate
          EffectiveDate
          ExpiryDate
          RecordStatus
          Remarks
          RFQSubmissionItem {
            RFQSubmissionItemID
            RFQSupplierSubmissionID
            RFQItemID
            UnitPrice
            Remarks
            IsSelected
            Cheapest(RFQHeaderID: $RFQHeaderID)
            TotalPrice(RFQHeaderID: $RFQHeaderID)
          }
        }
      }
      RFQItem {
        RFQHeaderID
        RFQItemID
        UOM {
          UOMID
          Name
          Code
        }
        PRItem {
          ItemID
          PRItemID
          PRHeaderID
          Remark
          Quantity
          UOMID
          UOM {
            UOMID
            Code
            Name
          }
          Item {
            ItemID
            Name
            LatestPurchase
          }
        }
        RFQSubmissionItem {
          RFQSubmissionItemID
          RFQSupplierSubmissionID
          RFQItemID
          UnitPrice
          Remarks
          IsSelected
          Cheapest
        }
      }
    }
    TotalLength
  }
}
    `;

/**
 * __useGetRfQbyStatusListingQuery__
 *
 * To run a query within a React component, call `useGetRfQbyStatusListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRfQbyStatusListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRfQbyStatusListingQuery({
 *   variables: {
 *      RFQHeaderID: // value for 'RFQHeaderID'
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetRfQbyStatusListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRfQbyStatusListingQuery, GetRfQbyStatusListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRfQbyStatusListingQuery, GetRfQbyStatusListingQueryVariables>(GetRfQbyStatusListingDocument, baseOptions);
      }
export function useGetRfQbyStatusListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRfQbyStatusListingQuery, GetRfQbyStatusListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRfQbyStatusListingQuery, GetRfQbyStatusListingQueryVariables>(GetRfQbyStatusListingDocument, baseOptions);
        }
export type GetRfQbyStatusListingQueryHookResult = ReturnType<typeof useGetRfQbyStatusListingQuery>;
export type GetRfQbyStatusListingLazyQueryHookResult = ReturnType<typeof useGetRfQbyStatusListingLazyQuery>;
export type GetRfQbyStatusListingQueryResult = ApolloReactCommon.QueryResult<GetRfQbyStatusListingQuery, GetRfQbyStatusListingQueryVariables>;
export const LatestOpenPeriodCheckingDateDocument = gql`
    query latestOpenPeriodCheckingDate($CompanyID: String!) {
  latestOpenPeriodCheckingDate(CompanyID: $CompanyID)
}
    `;

/**
 * __useLatestOpenPeriodCheckingDateQuery__
 *
 * To run a query within a React component, call `useLatestOpenPeriodCheckingDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestOpenPeriodCheckingDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestOpenPeriodCheckingDateQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useLatestOpenPeriodCheckingDateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LatestOpenPeriodCheckingDateQuery, LatestOpenPeriodCheckingDateQueryVariables>) {
        return ApolloReactHooks.useQuery<LatestOpenPeriodCheckingDateQuery, LatestOpenPeriodCheckingDateQueryVariables>(LatestOpenPeriodCheckingDateDocument, baseOptions);
      }
export function useLatestOpenPeriodCheckingDateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LatestOpenPeriodCheckingDateQuery, LatestOpenPeriodCheckingDateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LatestOpenPeriodCheckingDateQuery, LatestOpenPeriodCheckingDateQueryVariables>(LatestOpenPeriodCheckingDateDocument, baseOptions);
        }
export type LatestOpenPeriodCheckingDateQueryHookResult = ReturnType<typeof useLatestOpenPeriodCheckingDateQuery>;
export type LatestOpenPeriodCheckingDateLazyQueryHookResult = ReturnType<typeof useLatestOpenPeriodCheckingDateLazyQuery>;
export type LatestOpenPeriodCheckingDateQueryResult = ApolloReactCommon.QueryResult<LatestOpenPeriodCheckingDateQuery, LatestOpenPeriodCheckingDateQueryVariables>;
export const GetScheduledPurchasesDocument = gql`
    query getScheduledPurchases($ScheduledPurchasesID: String, $CompanyID: String, $DeliveryLocationID: String, $skip: Float, $take: Float, $searchValue: String, $orderByAsc: String) {
  getScheduledPurchases(ScheduledPurchasesID: $ScheduledPurchasesID, CompanyID: $CompanyID, DeliveryLocationID: $DeliveryLocationID, skip: $skip, take: $take, searchValue: $searchValue, orderByAsc: $orderByAsc) {
    createdTs
    createdBy
    modTs
    modBy
    RecordStatus
    ScheduledPurchasesID
    CompanyID
    DeliveryLocationID
    DeliveryLocation {
      Name
      Address
    }
    Title
    RequestedBy
    RequestStatus
    ScheduledDate
    ScheduledFrequency
    Description
    DeliveryLocationID
    ScheduledPurchasesItem {
      ScheduledPurchasesItemID
      ItemID
      Item {
        Name
        IsCentralised
        IsInventory
      }
      SupplierID
      CostCentreID
      WarehouseID
      Quantity
      UOMID
      UnitPrice
      TotalAmt
      TaxAmt
      TaxSchemeID
      TaxScheme {
        AccountID
        TaxSchemeID
        Code
        Description
        LatestTax {
          Rate
        }
      }
      Remarks
      Supplier {
        CompanyName
      }
      UOM {
        UOMID
        Name
        Code
      }
      UOMExchangeList
    }
  }
  getScheduledPurchasesCount(CompanyID: $CompanyID, ScheduledPurchasesID: $ScheduledPurchasesID)
}
    `;

/**
 * __useGetScheduledPurchasesQuery__
 *
 * To run a query within a React component, call `useGetScheduledPurchasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledPurchasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledPurchasesQuery({
 *   variables: {
 *      ScheduledPurchasesID: // value for 'ScheduledPurchasesID'
 *      CompanyID: // value for 'CompanyID'
 *      DeliveryLocationID: // value for 'DeliveryLocationID'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetScheduledPurchasesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetScheduledPurchasesQuery, GetScheduledPurchasesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetScheduledPurchasesQuery, GetScheduledPurchasesQueryVariables>(GetScheduledPurchasesDocument, baseOptions);
      }
export function useGetScheduledPurchasesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetScheduledPurchasesQuery, GetScheduledPurchasesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetScheduledPurchasesQuery, GetScheduledPurchasesQueryVariables>(GetScheduledPurchasesDocument, baseOptions);
        }
export type GetScheduledPurchasesQueryHookResult = ReturnType<typeof useGetScheduledPurchasesQuery>;
export type GetScheduledPurchasesLazyQueryHookResult = ReturnType<typeof useGetScheduledPurchasesLazyQuery>;
export type GetScheduledPurchasesQueryResult = ApolloReactCommon.QueryResult<GetScheduledPurchasesQuery, GetScheduledPurchasesQueryVariables>;
export const GetLatestPurchaseSupplierFromPoDocument = gql`
    query getLatestPurchaseSupplierFromPO($ItemID: String) {
  getLatestPurchaseSupplierFromPO(ItemID: $ItemID)
}
    `;

/**
 * __useGetLatestPurchaseSupplierFromPoQuery__
 *
 * To run a query within a React component, call `useGetLatestPurchaseSupplierFromPoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestPurchaseSupplierFromPoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestPurchaseSupplierFromPoQuery({
 *   variables: {
 *      ItemID: // value for 'ItemID'
 *   },
 * });
 */
export function useGetLatestPurchaseSupplierFromPoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLatestPurchaseSupplierFromPoQuery, GetLatestPurchaseSupplierFromPoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLatestPurchaseSupplierFromPoQuery, GetLatestPurchaseSupplierFromPoQueryVariables>(GetLatestPurchaseSupplierFromPoDocument, baseOptions);
      }
export function useGetLatestPurchaseSupplierFromPoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLatestPurchaseSupplierFromPoQuery, GetLatestPurchaseSupplierFromPoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLatestPurchaseSupplierFromPoQuery, GetLatestPurchaseSupplierFromPoQueryVariables>(GetLatestPurchaseSupplierFromPoDocument, baseOptions);
        }
export type GetLatestPurchaseSupplierFromPoQueryHookResult = ReturnType<typeof useGetLatestPurchaseSupplierFromPoQuery>;
export type GetLatestPurchaseSupplierFromPoLazyQueryHookResult = ReturnType<typeof useGetLatestPurchaseSupplierFromPoLazyQuery>;
export type GetLatestPurchaseSupplierFromPoQueryResult = ApolloReactCommon.QueryResult<GetLatestPurchaseSupplierFromPoQuery, GetLatestPurchaseSupplierFromPoQueryVariables>;
export const CreateScheduledPurchasesDocument = gql`
    mutation createScheduledPurchases($input: ScheduledPurchasesInput!) {
  createScheduledPurchases(input: $input)
}
    `;
export type CreateScheduledPurchasesMutationFn = ApolloReactCommon.MutationFunction<CreateScheduledPurchasesMutation, CreateScheduledPurchasesMutationVariables>;

/**
 * __useCreateScheduledPurchasesMutation__
 *
 * To run a mutation, you first call `useCreateScheduledPurchasesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduledPurchasesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduledPurchasesMutation, { data, loading, error }] = useCreateScheduledPurchasesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateScheduledPurchasesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateScheduledPurchasesMutation, CreateScheduledPurchasesMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateScheduledPurchasesMutation, CreateScheduledPurchasesMutationVariables>(CreateScheduledPurchasesDocument, baseOptions);
      }
export type CreateScheduledPurchasesMutationHookResult = ReturnType<typeof useCreateScheduledPurchasesMutation>;
export type CreateScheduledPurchasesMutationResult = ApolloReactCommon.MutationResult<CreateScheduledPurchasesMutation>;
export type CreateScheduledPurchasesMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateScheduledPurchasesMutation, CreateScheduledPurchasesMutationVariables>;
export const UpdateScheduledPurchasesDocument = gql`
    mutation updateScheduledPurchases($input: ScheduledPurchasesInput!, $ScheduledPurchasesID: String!) {
  updateScheduledPurchases(input: $input, ScheduledPurchasesID: $ScheduledPurchasesID)
}
    `;
export type UpdateScheduledPurchasesMutationFn = ApolloReactCommon.MutationFunction<UpdateScheduledPurchasesMutation, UpdateScheduledPurchasesMutationVariables>;

/**
 * __useUpdateScheduledPurchasesMutation__
 *
 * To run a mutation, you first call `useUpdateScheduledPurchasesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduledPurchasesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduledPurchasesMutation, { data, loading, error }] = useUpdateScheduledPurchasesMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ScheduledPurchasesID: // value for 'ScheduledPurchasesID'
 *   },
 * });
 */
export function useUpdateScheduledPurchasesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateScheduledPurchasesMutation, UpdateScheduledPurchasesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateScheduledPurchasesMutation, UpdateScheduledPurchasesMutationVariables>(UpdateScheduledPurchasesDocument, baseOptions);
      }
export type UpdateScheduledPurchasesMutationHookResult = ReturnType<typeof useUpdateScheduledPurchasesMutation>;
export type UpdateScheduledPurchasesMutationResult = ApolloReactCommon.MutationResult<UpdateScheduledPurchasesMutation>;
export type UpdateScheduledPurchasesMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateScheduledPurchasesMutation, UpdateScheduledPurchasesMutationVariables>;
export const CreatePreparePoDocument = gql`
    mutation createPreparePO($input: [PreparePOInput!]!, $ScheduledPurchasesID: String!) {
  createPreparePO(input: $input, ScheduledPurchasesID: $ScheduledPurchasesID)
}
    `;
export type CreatePreparePoMutationFn = ApolloReactCommon.MutationFunction<CreatePreparePoMutation, CreatePreparePoMutationVariables>;

/**
 * __useCreatePreparePoMutation__
 *
 * To run a mutation, you first call `useCreatePreparePoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePreparePoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPreparePoMutation, { data, loading, error }] = useCreatePreparePoMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ScheduledPurchasesID: // value for 'ScheduledPurchasesID'
 *   },
 * });
 */
export function useCreatePreparePoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePreparePoMutation, CreatePreparePoMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePreparePoMutation, CreatePreparePoMutationVariables>(CreatePreparePoDocument, baseOptions);
      }
export type CreatePreparePoMutationHookResult = ReturnType<typeof useCreatePreparePoMutation>;
export type CreatePreparePoMutationResult = ApolloReactCommon.MutationResult<CreatePreparePoMutation>;
export type CreatePreparePoMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePreparePoMutation, CreatePreparePoMutationVariables>;
export const DeleteScheduledPurchasesDocument = gql`
    mutation deleteScheduledPurchases($ScheduledPurchasesID: String!) {
  deleteScheduledPurchases(ScheduledPurchasesID: $ScheduledPurchasesID)
}
    `;
export type DeleteScheduledPurchasesMutationFn = ApolloReactCommon.MutationFunction<DeleteScheduledPurchasesMutation, DeleteScheduledPurchasesMutationVariables>;

/**
 * __useDeleteScheduledPurchasesMutation__
 *
 * To run a mutation, you first call `useDeleteScheduledPurchasesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScheduledPurchasesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScheduledPurchasesMutation, { data, loading, error }] = useDeleteScheduledPurchasesMutation({
 *   variables: {
 *      ScheduledPurchasesID: // value for 'ScheduledPurchasesID'
 *   },
 * });
 */
export function useDeleteScheduledPurchasesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteScheduledPurchasesMutation, DeleteScheduledPurchasesMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteScheduledPurchasesMutation, DeleteScheduledPurchasesMutationVariables>(DeleteScheduledPurchasesDocument, baseOptions);
      }
export type DeleteScheduledPurchasesMutationHookResult = ReturnType<typeof useDeleteScheduledPurchasesMutation>;
export type DeleteScheduledPurchasesMutationResult = ApolloReactCommon.MutationResult<DeleteScheduledPurchasesMutation>;
export type DeleteScheduledPurchasesMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteScheduledPurchasesMutation, DeleteScheduledPurchasesMutationVariables>;
export const GetLatestTaxByTaxSchemeIDsDocument = gql`
    query getLatestTaxByTaxSchemeIDs($taxSchemeIDs: [String!]!) {
  getLatestTaxByTaxSchemeIDs(taxSchemeIDs: $taxSchemeIDs)
}
    `;

/**
 * __useGetLatestTaxByTaxSchemeIDsQuery__
 *
 * To run a query within a React component, call `useGetLatestTaxByTaxSchemeIDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestTaxByTaxSchemeIDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestTaxByTaxSchemeIDsQuery({
 *   variables: {
 *      taxSchemeIDs: // value for 'taxSchemeIDs'
 *   },
 * });
 */
export function useGetLatestTaxByTaxSchemeIDsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLatestTaxByTaxSchemeIDsQuery, GetLatestTaxByTaxSchemeIDsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLatestTaxByTaxSchemeIDsQuery, GetLatestTaxByTaxSchemeIDsQueryVariables>(GetLatestTaxByTaxSchemeIDsDocument, baseOptions);
      }
export function useGetLatestTaxByTaxSchemeIDsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLatestTaxByTaxSchemeIDsQuery, GetLatestTaxByTaxSchemeIDsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLatestTaxByTaxSchemeIDsQuery, GetLatestTaxByTaxSchemeIDsQueryVariables>(GetLatestTaxByTaxSchemeIDsDocument, baseOptions);
        }
export type GetLatestTaxByTaxSchemeIDsQueryHookResult = ReturnType<typeof useGetLatestTaxByTaxSchemeIDsQuery>;
export type GetLatestTaxByTaxSchemeIDsLazyQueryHookResult = ReturnType<typeof useGetLatestTaxByTaxSchemeIDsLazyQuery>;
export type GetLatestTaxByTaxSchemeIDsQueryResult = ApolloReactCommon.QueryResult<GetLatestTaxByTaxSchemeIDsQuery, GetLatestTaxByTaxSchemeIDsQueryVariables>;
export const GetUsersByAccountAndSoftwareDocument = gql`
    query getUsersByAccountAndSoftware($superUserBool: UserSelection, $status: CommonStatus) {
  getUsersByAccountAndSoftware(superUserBool: $superUserBool, status: $status) {
    ID
    name
    department
    email
    contactNo
    confirmed
    superUser
    commonStatus
    jobTitle
    userName
    blockCount
  }
}
    `;

/**
 * __useGetUsersByAccountAndSoftwareQuery__
 *
 * To run a query within a React component, call `useGetUsersByAccountAndSoftwareQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByAccountAndSoftwareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByAccountAndSoftwareQuery({
 *   variables: {
 *      superUserBool: // value for 'superUserBool'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetUsersByAccountAndSoftwareQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>(GetUsersByAccountAndSoftwareDocument, baseOptions);
      }
export function useGetUsersByAccountAndSoftwareLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>(GetUsersByAccountAndSoftwareDocument, baseOptions);
        }
export type GetUsersByAccountAndSoftwareQueryHookResult = ReturnType<typeof useGetUsersByAccountAndSoftwareQuery>;
export type GetUsersByAccountAndSoftwareLazyQueryHookResult = ReturnType<typeof useGetUsersByAccountAndSoftwareLazyQuery>;
export type GetUsersByAccountAndSoftwareQueryResult = ApolloReactCommon.QueryResult<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>;
export const GetApprovalPolicyDocument = gql`
    query getApprovalPolicy($Category: String!, $ApprovalPolicyID: String) {
  getApprovalPolicy(Category: $Category, ApprovalPolicyID: $ApprovalPolicyID) {
    createdTs
    modTs
    ApprovalPolicyID
    AccountID
    lowerLimit
    upperLimit
    Category
    ApprovalList
  }
}
    `;

/**
 * __useGetApprovalPolicyQuery__
 *
 * To run a query within a React component, call `useGetApprovalPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalPolicyQuery({
 *   variables: {
 *      Category: // value for 'Category'
 *      ApprovalPolicyID: // value for 'ApprovalPolicyID'
 *   },
 * });
 */
export function useGetApprovalPolicyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApprovalPolicyQuery, GetApprovalPolicyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApprovalPolicyQuery, GetApprovalPolicyQueryVariables>(GetApprovalPolicyDocument, baseOptions);
      }
export function useGetApprovalPolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApprovalPolicyQuery, GetApprovalPolicyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApprovalPolicyQuery, GetApprovalPolicyQueryVariables>(GetApprovalPolicyDocument, baseOptions);
        }
export type GetApprovalPolicyQueryHookResult = ReturnType<typeof useGetApprovalPolicyQuery>;
export type GetApprovalPolicyLazyQueryHookResult = ReturnType<typeof useGetApprovalPolicyLazyQuery>;
export type GetApprovalPolicyQueryResult = ApolloReactCommon.QueryResult<GetApprovalPolicyQuery, GetApprovalPolicyQueryVariables>;
export const GetAllApprovalPolicyDocument = gql`
    query getAllApprovalPolicy($Category: String!) {
  getAllApprovalPolicy(Category: $Category) {
    ApprovalPolicyID
    createdTs
    modTs
    Category
    ApprovalList
  }
}
    `;

/**
 * __useGetAllApprovalPolicyQuery__
 *
 * To run a query within a React component, call `useGetAllApprovalPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllApprovalPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllApprovalPolicyQuery({
 *   variables: {
 *      Category: // value for 'Category'
 *   },
 * });
 */
export function useGetAllApprovalPolicyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllApprovalPolicyQuery, GetAllApprovalPolicyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllApprovalPolicyQuery, GetAllApprovalPolicyQueryVariables>(GetAllApprovalPolicyDocument, baseOptions);
      }
export function useGetAllApprovalPolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllApprovalPolicyQuery, GetAllApprovalPolicyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllApprovalPolicyQuery, GetAllApprovalPolicyQueryVariables>(GetAllApprovalPolicyDocument, baseOptions);
        }
export type GetAllApprovalPolicyQueryHookResult = ReturnType<typeof useGetAllApprovalPolicyQuery>;
export type GetAllApprovalPolicyLazyQueryHookResult = ReturnType<typeof useGetAllApprovalPolicyLazyQuery>;
export type GetAllApprovalPolicyQueryResult = ApolloReactCommon.QueryResult<GetAllApprovalPolicyQuery, GetAllApprovalPolicyQueryVariables>;
export const GetApprovalPolicyAssignmentDocument = gql`
    query getApprovalPolicyAssignment($AccountID: String, $CompanyID: String!, $Category: String) {
  getApprovalPolicyAssignment(AccountID: $AccountID, CompanyID: $CompanyID, Category: $Category) {
    createdTs
    modTs
    modBy
    ApprovalPolicyAssignmentID
    ApprovalPolicyID
    AccountID
    CompanyID
    Category
    ApprovalList
    LowerLimit
    UpperLimit
  }
}
    `;

/**
 * __useGetApprovalPolicyAssignmentQuery__
 *
 * To run a query within a React component, call `useGetApprovalPolicyAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalPolicyAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalPolicyAssignmentQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      Category: // value for 'Category'
 *   },
 * });
 */
export function useGetApprovalPolicyAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApprovalPolicyAssignmentQuery, GetApprovalPolicyAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApprovalPolicyAssignmentQuery, GetApprovalPolicyAssignmentQueryVariables>(GetApprovalPolicyAssignmentDocument, baseOptions);
      }
export function useGetApprovalPolicyAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApprovalPolicyAssignmentQuery, GetApprovalPolicyAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApprovalPolicyAssignmentQuery, GetApprovalPolicyAssignmentQueryVariables>(GetApprovalPolicyAssignmentDocument, baseOptions);
        }
export type GetApprovalPolicyAssignmentQueryHookResult = ReturnType<typeof useGetApprovalPolicyAssignmentQuery>;
export type GetApprovalPolicyAssignmentLazyQueryHookResult = ReturnType<typeof useGetApprovalPolicyAssignmentLazyQuery>;
export type GetApprovalPolicyAssignmentQueryResult = ApolloReactCommon.QueryResult<GetApprovalPolicyAssignmentQuery, GetApprovalPolicyAssignmentQueryVariables>;
export const CreateApprovalPolicyDocument = gql`
    mutation createApprovalPolicy($input: ApprovalPolicyInput!) {
  createApprovalPolicy(input: $input) {
    ApprovalPolicyID
  }
}
    `;
export type CreateApprovalPolicyMutationFn = ApolloReactCommon.MutationFunction<CreateApprovalPolicyMutation, CreateApprovalPolicyMutationVariables>;

/**
 * __useCreateApprovalPolicyMutation__
 *
 * To run a mutation, you first call `useCreateApprovalPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApprovalPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApprovalPolicyMutation, { data, loading, error }] = useCreateApprovalPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApprovalPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApprovalPolicyMutation, CreateApprovalPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApprovalPolicyMutation, CreateApprovalPolicyMutationVariables>(CreateApprovalPolicyDocument, baseOptions);
      }
export type CreateApprovalPolicyMutationHookResult = ReturnType<typeof useCreateApprovalPolicyMutation>;
export type CreateApprovalPolicyMutationResult = ApolloReactCommon.MutationResult<CreateApprovalPolicyMutation>;
export type CreateApprovalPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApprovalPolicyMutation, CreateApprovalPolicyMutationVariables>;
export const CreateApprovalPolicyAssignmentDocument = gql`
    mutation createApprovalPolicyAssignment($input: ApprovalPolicyAssignmentInput!) {
  createApprovalPolicyAssignment(input: $input) {
    ApprovalPolicyAssignmentID
  }
}
    `;
export type CreateApprovalPolicyAssignmentMutationFn = ApolloReactCommon.MutationFunction<CreateApprovalPolicyAssignmentMutation, CreateApprovalPolicyAssignmentMutationVariables>;

/**
 * __useCreateApprovalPolicyAssignmentMutation__
 *
 * To run a mutation, you first call `useCreateApprovalPolicyAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApprovalPolicyAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApprovalPolicyAssignmentMutation, { data, loading, error }] = useCreateApprovalPolicyAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApprovalPolicyAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApprovalPolicyAssignmentMutation, CreateApprovalPolicyAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApprovalPolicyAssignmentMutation, CreateApprovalPolicyAssignmentMutationVariables>(CreateApprovalPolicyAssignmentDocument, baseOptions);
      }
export type CreateApprovalPolicyAssignmentMutationHookResult = ReturnType<typeof useCreateApprovalPolicyAssignmentMutation>;
export type CreateApprovalPolicyAssignmentMutationResult = ApolloReactCommon.MutationResult<CreateApprovalPolicyAssignmentMutation>;
export type CreateApprovalPolicyAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApprovalPolicyAssignmentMutation, CreateApprovalPolicyAssignmentMutationVariables>;
export const UpdateApprovalPolicyDocument = gql`
    mutation updateApprovalPolicy($input: ApprovalPolicyInput!) {
  updateApprovalPolicy(input: $input) {
    ApprovalPolicyID
  }
}
    `;
export type UpdateApprovalPolicyMutationFn = ApolloReactCommon.MutationFunction<UpdateApprovalPolicyMutation, UpdateApprovalPolicyMutationVariables>;

/**
 * __useUpdateApprovalPolicyMutation__
 *
 * To run a mutation, you first call `useUpdateApprovalPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApprovalPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApprovalPolicyMutation, { data, loading, error }] = useUpdateApprovalPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApprovalPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApprovalPolicyMutation, UpdateApprovalPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApprovalPolicyMutation, UpdateApprovalPolicyMutationVariables>(UpdateApprovalPolicyDocument, baseOptions);
      }
export type UpdateApprovalPolicyMutationHookResult = ReturnType<typeof useUpdateApprovalPolicyMutation>;
export type UpdateApprovalPolicyMutationResult = ApolloReactCommon.MutationResult<UpdateApprovalPolicyMutation>;
export type UpdateApprovalPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApprovalPolicyMutation, UpdateApprovalPolicyMutationVariables>;
export const UpdateApprovalPolicyAssignmentDocument = gql`
    mutation updateApprovalPolicyAssignment($input: ApprovalPolicyAssignmentInput!) {
  updateApprovalPolicyAssignment(input: $input) {
    ApprovalPolicyAssignmentID
  }
}
    `;
export type UpdateApprovalPolicyAssignmentMutationFn = ApolloReactCommon.MutationFunction<UpdateApprovalPolicyAssignmentMutation, UpdateApprovalPolicyAssignmentMutationVariables>;

/**
 * __useUpdateApprovalPolicyAssignmentMutation__
 *
 * To run a mutation, you first call `useUpdateApprovalPolicyAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApprovalPolicyAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApprovalPolicyAssignmentMutation, { data, loading, error }] = useUpdateApprovalPolicyAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApprovalPolicyAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApprovalPolicyAssignmentMutation, UpdateApprovalPolicyAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApprovalPolicyAssignmentMutation, UpdateApprovalPolicyAssignmentMutationVariables>(UpdateApprovalPolicyAssignmentDocument, baseOptions);
      }
export type UpdateApprovalPolicyAssignmentMutationHookResult = ReturnType<typeof useUpdateApprovalPolicyAssignmentMutation>;
export type UpdateApprovalPolicyAssignmentMutationResult = ApolloReactCommon.MutationResult<UpdateApprovalPolicyAssignmentMutation>;
export type UpdateApprovalPolicyAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApprovalPolicyAssignmentMutation, UpdateApprovalPolicyAssignmentMutationVariables>;
export const DeleteApprovalPolicyDocument = gql`
    mutation deleteApprovalPolicy($input: ApprovalPolicyDeleteInput!) {
  deleteApprovalPolicy(input: $input)
}
    `;
export type DeleteApprovalPolicyMutationFn = ApolloReactCommon.MutationFunction<DeleteApprovalPolicyMutation, DeleteApprovalPolicyMutationVariables>;

/**
 * __useDeleteApprovalPolicyMutation__
 *
 * To run a mutation, you first call `useDeleteApprovalPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApprovalPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApprovalPolicyMutation, { data, loading, error }] = useDeleteApprovalPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteApprovalPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApprovalPolicyMutation, DeleteApprovalPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApprovalPolicyMutation, DeleteApprovalPolicyMutationVariables>(DeleteApprovalPolicyDocument, baseOptions);
      }
export type DeleteApprovalPolicyMutationHookResult = ReturnType<typeof useDeleteApprovalPolicyMutation>;
export type DeleteApprovalPolicyMutationResult = ApolloReactCommon.MutationResult<DeleteApprovalPolicyMutation>;
export type DeleteApprovalPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApprovalPolicyMutation, DeleteApprovalPolicyMutationVariables>;
export const DeleteApprovalPolicyAssignmentDocument = gql`
    mutation deleteApprovalPolicyAssignment($input: ApprovalPolicyAssignmentDeleteInput!) {
  deleteApprovalPolicyAssignment(input: $input)
}
    `;
export type DeleteApprovalPolicyAssignmentMutationFn = ApolloReactCommon.MutationFunction<DeleteApprovalPolicyAssignmentMutation, DeleteApprovalPolicyAssignmentMutationVariables>;

/**
 * __useDeleteApprovalPolicyAssignmentMutation__
 *
 * To run a mutation, you first call `useDeleteApprovalPolicyAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApprovalPolicyAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApprovalPolicyAssignmentMutation, { data, loading, error }] = useDeleteApprovalPolicyAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteApprovalPolicyAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApprovalPolicyAssignmentMutation, DeleteApprovalPolicyAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApprovalPolicyAssignmentMutation, DeleteApprovalPolicyAssignmentMutationVariables>(DeleteApprovalPolicyAssignmentDocument, baseOptions);
      }
export type DeleteApprovalPolicyAssignmentMutationHookResult = ReturnType<typeof useDeleteApprovalPolicyAssignmentMutation>;
export type DeleteApprovalPolicyAssignmentMutationResult = ApolloReactCommon.MutationResult<DeleteApprovalPolicyAssignmentMutation>;
export type DeleteApprovalPolicyAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApprovalPolicyAssignmentMutation, DeleteApprovalPolicyAssignmentMutationVariables>;
export const GetApprovalLogDocument = gql`
    query getApprovalLog($RefID: String!) {
  getApprovalLog(RefID: $RefID)
}
    `;

/**
 * __useGetApprovalLogQuery__
 *
 * To run a query within a React component, call `useGetApprovalLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalLogQuery({
 *   variables: {
 *      RefID: // value for 'RefID'
 *   },
 * });
 */
export function useGetApprovalLogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApprovalLogQuery, GetApprovalLogQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApprovalLogQuery, GetApprovalLogQueryVariables>(GetApprovalLogDocument, baseOptions);
      }
export function useGetApprovalLogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApprovalLogQuery, GetApprovalLogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApprovalLogQuery, GetApprovalLogQueryVariables>(GetApprovalLogDocument, baseOptions);
        }
export type GetApprovalLogQueryHookResult = ReturnType<typeof useGetApprovalLogQuery>;
export type GetApprovalLogLazyQueryHookResult = ReturnType<typeof useGetApprovalLogLazyQuery>;
export type GetApprovalLogQueryResult = ApolloReactCommon.QueryResult<GetApprovalLogQuery, GetApprovalLogQueryVariables>;
export const GetGpApprovalPolicyInfolineDocument = gql`
    query getGPApprovalPolicyInfoline {
  getGPApprovalPolicyInfoline
}
    `;

/**
 * __useGetGpApprovalPolicyInfolineQuery__
 *
 * To run a query within a React component, call `useGetGpApprovalPolicyInfolineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGpApprovalPolicyInfolineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGpApprovalPolicyInfolineQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGpApprovalPolicyInfolineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGpApprovalPolicyInfolineQuery, GetGpApprovalPolicyInfolineQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGpApprovalPolicyInfolineQuery, GetGpApprovalPolicyInfolineQueryVariables>(GetGpApprovalPolicyInfolineDocument, baseOptions);
      }
export function useGetGpApprovalPolicyInfolineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGpApprovalPolicyInfolineQuery, GetGpApprovalPolicyInfolineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGpApprovalPolicyInfolineQuery, GetGpApprovalPolicyInfolineQueryVariables>(GetGpApprovalPolicyInfolineDocument, baseOptions);
        }
export type GetGpApprovalPolicyInfolineQueryHookResult = ReturnType<typeof useGetGpApprovalPolicyInfolineQuery>;
export type GetGpApprovalPolicyInfolineLazyQueryHookResult = ReturnType<typeof useGetGpApprovalPolicyInfolineLazyQuery>;
export type GetGpApprovalPolicyInfolineQueryResult = ApolloReactCommon.QueryResult<GetGpApprovalPolicyInfolineQuery, GetGpApprovalPolicyInfolineQueryVariables>;
export const GetPendingGpDocument = gql`
    query getPendingGP($RefTable: String!) {
  getPendingGP(RefTable: $RefTable)
}
    `;

/**
 * __useGetPendingGpQuery__
 *
 * To run a query within a React component, call `useGetPendingGpQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPendingGpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPendingGpQuery({
 *   variables: {
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function useGetPendingGpQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPendingGpQuery, GetPendingGpQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPendingGpQuery, GetPendingGpQueryVariables>(GetPendingGpDocument, baseOptions);
      }
export function useGetPendingGpLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPendingGpQuery, GetPendingGpQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPendingGpQuery, GetPendingGpQueryVariables>(GetPendingGpDocument, baseOptions);
        }
export type GetPendingGpQueryHookResult = ReturnType<typeof useGetPendingGpQuery>;
export type GetPendingGpLazyQueryHookResult = ReturnType<typeof useGetPendingGpLazyQuery>;
export type GetPendingGpQueryResult = ApolloReactCommon.QueryResult<GetPendingGpQuery, GetPendingGpQueryVariables>;
export const GetBulkPurchasePriceDocument = gql`
    query getBulkPurchasePrice($ItemID: String, $SupplierID: String, $UOMID: String, $orderByAsc: String, $orderByDesc: String, $BulkPurchasePriceHeaderID: String) {
  getBulkPurchasePrice(ItemID: $ItemID, SupplierID: $SupplierID, UOMID: $UOMID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, BulkPurchasePriceHeaderID: $BulkPurchasePriceHeaderID) {
    BulkPurchasePriceHeaderID
    SupplierID
    Supplier {
      CompanyName
      CompanyRegNo
      ContactNo
      Email
      CreditTerm
      TaxSchemeID
    }
    EffectiveDate
    ExpiryDate
    Remarks
    BulkPurchasePriceItem {
      BulkPurchasePriceItemID
      BulkPurchasePriceHeaderID
      ItemID
      Item {
        Name
        Description
      }
      UOMID
      UOM {
        Code
        Name
      }
      BulkPurchasePriceDetail {
        LowerLimit
        UpperLimit
        UnitPrice
      }
    }
  }
}
    `;

/**
 * __useGetBulkPurchasePriceQuery__
 *
 * To run a query within a React component, call `useGetBulkPurchasePriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBulkPurchasePriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBulkPurchasePriceQuery({
 *   variables: {
 *      ItemID: // value for 'ItemID'
 *      SupplierID: // value for 'SupplierID'
 *      UOMID: // value for 'UOMID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      BulkPurchasePriceHeaderID: // value for 'BulkPurchasePriceHeaderID'
 *   },
 * });
 */
export function useGetBulkPurchasePriceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBulkPurchasePriceQuery, GetBulkPurchasePriceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBulkPurchasePriceQuery, GetBulkPurchasePriceQueryVariables>(GetBulkPurchasePriceDocument, baseOptions);
      }
export function useGetBulkPurchasePriceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBulkPurchasePriceQuery, GetBulkPurchasePriceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBulkPurchasePriceQuery, GetBulkPurchasePriceQueryVariables>(GetBulkPurchasePriceDocument, baseOptions);
        }
export type GetBulkPurchasePriceQueryHookResult = ReturnType<typeof useGetBulkPurchasePriceQuery>;
export type GetBulkPurchasePriceLazyQueryHookResult = ReturnType<typeof useGetBulkPurchasePriceLazyQuery>;
export type GetBulkPurchasePriceQueryResult = ApolloReactCommon.QueryResult<GetBulkPurchasePriceQuery, GetBulkPurchasePriceQueryVariables>;
export const CreateBulkPurchasePriceDocument = gql`
    mutation createBulkPurchasePrice($input: BulkPurchasePriceHeaderInput!, $bulkPurchaseItemInput: [BulkPurchasePriceItemInput!]!) {
  createBulkPurchasePrice(input: $input, bulkPurchaseItemInput: $bulkPurchaseItemInput) {
    SupplierID
    EffectiveDate
    ExpiryDate
    Remarks
    BulkPurchasePriceItem {
      ItemID
      UOMID
      BulkPurchasePriceDetail {
        LowerLimit
        UpperLimit
        UnitPrice
      }
    }
  }
}
    `;
export type CreateBulkPurchasePriceMutationFn = ApolloReactCommon.MutationFunction<CreateBulkPurchasePriceMutation, CreateBulkPurchasePriceMutationVariables>;

/**
 * __useCreateBulkPurchasePriceMutation__
 *
 * To run a mutation, you first call `useCreateBulkPurchasePriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBulkPurchasePriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBulkPurchasePriceMutation, { data, loading, error }] = useCreateBulkPurchasePriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      bulkPurchaseItemInput: // value for 'bulkPurchaseItemInput'
 *   },
 * });
 */
export function useCreateBulkPurchasePriceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBulkPurchasePriceMutation, CreateBulkPurchasePriceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBulkPurchasePriceMutation, CreateBulkPurchasePriceMutationVariables>(CreateBulkPurchasePriceDocument, baseOptions);
      }
export type CreateBulkPurchasePriceMutationHookResult = ReturnType<typeof useCreateBulkPurchasePriceMutation>;
export type CreateBulkPurchasePriceMutationResult = ApolloReactCommon.MutationResult<CreateBulkPurchasePriceMutation>;
export type CreateBulkPurchasePriceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBulkPurchasePriceMutation, CreateBulkPurchasePriceMutationVariables>;
export const UpdateBulkPurchasePriceDocument = gql`
    mutation updateBulkPurchasePrice($BulkPurchasePriceHeaderID: String!, $input: BulkPurchasePriceHeaderInput!, $bulkPurchaseItemInput: [BulkPurchasePriceItemInput!]!) {
  updateBulkPurchasePrice(BulkPurchasePriceHeaderID: $BulkPurchasePriceHeaderID, input: $input, bulkPurchaseItemInput: $bulkPurchaseItemInput)
}
    `;
export type UpdateBulkPurchasePriceMutationFn = ApolloReactCommon.MutationFunction<UpdateBulkPurchasePriceMutation, UpdateBulkPurchasePriceMutationVariables>;

/**
 * __useUpdateBulkPurchasePriceMutation__
 *
 * To run a mutation, you first call `useUpdateBulkPurchasePriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBulkPurchasePriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBulkPurchasePriceMutation, { data, loading, error }] = useUpdateBulkPurchasePriceMutation({
 *   variables: {
 *      BulkPurchasePriceHeaderID: // value for 'BulkPurchasePriceHeaderID'
 *      input: // value for 'input'
 *      bulkPurchaseItemInput: // value for 'bulkPurchaseItemInput'
 *   },
 * });
 */
export function useUpdateBulkPurchasePriceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBulkPurchasePriceMutation, UpdateBulkPurchasePriceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBulkPurchasePriceMutation, UpdateBulkPurchasePriceMutationVariables>(UpdateBulkPurchasePriceDocument, baseOptions);
      }
export type UpdateBulkPurchasePriceMutationHookResult = ReturnType<typeof useUpdateBulkPurchasePriceMutation>;
export type UpdateBulkPurchasePriceMutationResult = ApolloReactCommon.MutationResult<UpdateBulkPurchasePriceMutation>;
export type UpdateBulkPurchasePriceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBulkPurchasePriceMutation, UpdateBulkPurchasePriceMutationVariables>;
export const DeleteBulkPurchasePriceDocument = gql`
    mutation deleteBulkPurchasePrice($BulkPurchasePriceHeaderID: String!) {
  deleteBulkPurchasePrice(BulkPurchasePriceHeaderID: $BulkPurchasePriceHeaderID)
}
    `;
export type DeleteBulkPurchasePriceMutationFn = ApolloReactCommon.MutationFunction<DeleteBulkPurchasePriceMutation, DeleteBulkPurchasePriceMutationVariables>;

/**
 * __useDeleteBulkPurchasePriceMutation__
 *
 * To run a mutation, you first call `useDeleteBulkPurchasePriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBulkPurchasePriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBulkPurchasePriceMutation, { data, loading, error }] = useDeleteBulkPurchasePriceMutation({
 *   variables: {
 *      BulkPurchasePriceHeaderID: // value for 'BulkPurchasePriceHeaderID'
 *   },
 * });
 */
export function useDeleteBulkPurchasePriceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBulkPurchasePriceMutation, DeleteBulkPurchasePriceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteBulkPurchasePriceMutation, DeleteBulkPurchasePriceMutationVariables>(DeleteBulkPurchasePriceDocument, baseOptions);
      }
export type DeleteBulkPurchasePriceMutationHookResult = ReturnType<typeof useDeleteBulkPurchasePriceMutation>;
export type DeleteBulkPurchasePriceMutationResult = ApolloReactCommon.MutationResult<DeleteBulkPurchasePriceMutation>;
export type DeleteBulkPurchasePriceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteBulkPurchasePriceMutation, DeleteBulkPurchasePriceMutationVariables>;
export const GetClassificationCodeDocument = gql`
    query getClassificationCode {
  getClassificationCode {
    id
    classificationCode
    description
  }
}
    `;

/**
 * __useGetClassificationCodeQuery__
 *
 * To run a query within a React component, call `useGetClassificationCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassificationCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassificationCodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClassificationCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClassificationCodeQuery, GetClassificationCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClassificationCodeQuery, GetClassificationCodeQueryVariables>(GetClassificationCodeDocument, baseOptions);
      }
export function useGetClassificationCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClassificationCodeQuery, GetClassificationCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClassificationCodeQuery, GetClassificationCodeQueryVariables>(GetClassificationCodeDocument, baseOptions);
        }
export type GetClassificationCodeQueryHookResult = ReturnType<typeof useGetClassificationCodeQuery>;
export type GetClassificationCodeLazyQueryHookResult = ReturnType<typeof useGetClassificationCodeLazyQuery>;
export type GetClassificationCodeQueryResult = ApolloReactCommon.QueryResult<GetClassificationCodeQuery, GetClassificationCodeQueryVariables>;
export const GetMsicCodeDocument = gql`
    query getMSICCode {
  getMSICCode {
    id
    msicCode
    description
    categoryReference
  }
}
    `;

/**
 * __useGetMsicCodeQuery__
 *
 * To run a query within a React component, call `useGetMsicCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMsicCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMsicCodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMsicCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMsicCodeQuery, GetMsicCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMsicCodeQuery, GetMsicCodeQueryVariables>(GetMsicCodeDocument, baseOptions);
      }
export function useGetMsicCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMsicCodeQuery, GetMsicCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMsicCodeQuery, GetMsicCodeQueryVariables>(GetMsicCodeDocument, baseOptions);
        }
export type GetMsicCodeQueryHookResult = ReturnType<typeof useGetMsicCodeQuery>;
export type GetMsicCodeLazyQueryHookResult = ReturnType<typeof useGetMsicCodeLazyQuery>;
export type GetMsicCodeQueryResult = ApolloReactCommon.QueryResult<GetMsicCodeQuery, GetMsicCodeQueryVariables>;
export const GetGeneralPurchasePolicyDocument = gql`
    query getGeneralPurchasePolicy($GeneralPurchasePolicyID: String, $AccountID: String) {
  getGeneralPurchasePolicy(GeneralPurchasePolicyID: $GeneralPurchasePolicyID, AccountID: $AccountID) {
    GeneralPurchasePolicyID
    AccountID
    VariancePerc
    RFQRequired
    MasterCOAID
    RecordStatus
  }
}
    `;

/**
 * __useGetGeneralPurchasePolicyQuery__
 *
 * To run a query within a React component, call `useGetGeneralPurchasePolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralPurchasePolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralPurchasePolicyQuery({
 *   variables: {
 *      GeneralPurchasePolicyID: // value for 'GeneralPurchasePolicyID'
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useGetGeneralPurchasePolicyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralPurchasePolicyQuery, GetGeneralPurchasePolicyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralPurchasePolicyQuery, GetGeneralPurchasePolicyQueryVariables>(GetGeneralPurchasePolicyDocument, baseOptions);
      }
export function useGetGeneralPurchasePolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralPurchasePolicyQuery, GetGeneralPurchasePolicyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralPurchasePolicyQuery, GetGeneralPurchasePolicyQueryVariables>(GetGeneralPurchasePolicyDocument, baseOptions);
        }
export type GetGeneralPurchasePolicyQueryHookResult = ReturnType<typeof useGetGeneralPurchasePolicyQuery>;
export type GetGeneralPurchasePolicyLazyQueryHookResult = ReturnType<typeof useGetGeneralPurchasePolicyLazyQuery>;
export type GetGeneralPurchasePolicyQueryResult = ApolloReactCommon.QueryResult<GetGeneralPurchasePolicyQuery, GetGeneralPurchasePolicyQueryVariables>;
export const CreateGeneralPurchasePolicyDocument = gql`
    mutation createGeneralPurchasePolicy($GeneralPurchasePolicyInput: GeneralPurchasePolicyInput!) {
  createGeneralPurchasePolicy(GeneralPurchasePolicyInput: $GeneralPurchasePolicyInput)
}
    `;
export type CreateGeneralPurchasePolicyMutationFn = ApolloReactCommon.MutationFunction<CreateGeneralPurchasePolicyMutation, CreateGeneralPurchasePolicyMutationVariables>;

/**
 * __useCreateGeneralPurchasePolicyMutation__
 *
 * To run a mutation, you first call `useCreateGeneralPurchasePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGeneralPurchasePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGeneralPurchasePolicyMutation, { data, loading, error }] = useCreateGeneralPurchasePolicyMutation({
 *   variables: {
 *      GeneralPurchasePolicyInput: // value for 'GeneralPurchasePolicyInput'
 *   },
 * });
 */
export function useCreateGeneralPurchasePolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGeneralPurchasePolicyMutation, CreateGeneralPurchasePolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGeneralPurchasePolicyMutation, CreateGeneralPurchasePolicyMutationVariables>(CreateGeneralPurchasePolicyDocument, baseOptions);
      }
export type CreateGeneralPurchasePolicyMutationHookResult = ReturnType<typeof useCreateGeneralPurchasePolicyMutation>;
export type CreateGeneralPurchasePolicyMutationResult = ApolloReactCommon.MutationResult<CreateGeneralPurchasePolicyMutation>;
export type CreateGeneralPurchasePolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGeneralPurchasePolicyMutation, CreateGeneralPurchasePolicyMutationVariables>;
export const UpdateGeneralPurchasePolicyDocument = gql`
    mutation updateGeneralPurchasePolicy($GeneralPurchasePolicyID: String, $GeneralPurchasePolicyInput: GeneralPurchasePolicyInput!) {
  updateGeneralPurchasePolicy(GeneralPurchasePolicyID: $GeneralPurchasePolicyID, GeneralPurchasePolicyInput: $GeneralPurchasePolicyInput)
}
    `;
export type UpdateGeneralPurchasePolicyMutationFn = ApolloReactCommon.MutationFunction<UpdateGeneralPurchasePolicyMutation, UpdateGeneralPurchasePolicyMutationVariables>;

/**
 * __useUpdateGeneralPurchasePolicyMutation__
 *
 * To run a mutation, you first call `useUpdateGeneralPurchasePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGeneralPurchasePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGeneralPurchasePolicyMutation, { data, loading, error }] = useUpdateGeneralPurchasePolicyMutation({
 *   variables: {
 *      GeneralPurchasePolicyID: // value for 'GeneralPurchasePolicyID'
 *      GeneralPurchasePolicyInput: // value for 'GeneralPurchasePolicyInput'
 *   },
 * });
 */
export function useUpdateGeneralPurchasePolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateGeneralPurchasePolicyMutation, UpdateGeneralPurchasePolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateGeneralPurchasePolicyMutation, UpdateGeneralPurchasePolicyMutationVariables>(UpdateGeneralPurchasePolicyDocument, baseOptions);
      }
export type UpdateGeneralPurchasePolicyMutationHookResult = ReturnType<typeof useUpdateGeneralPurchasePolicyMutation>;
export type UpdateGeneralPurchasePolicyMutationResult = ApolloReactCommon.MutationResult<UpdateGeneralPurchasePolicyMutation>;
export type UpdateGeneralPurchasePolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateGeneralPurchasePolicyMutation, UpdateGeneralPurchasePolicyMutationVariables>;
export const GetGeneralSettingSummaryDocument = gql`
    query getGeneralSettingSummary {
  activeSupplierCategoryCount
  activeSupplierProfileCount
  activeUomCount
  lastUomExchange
  activePurchaseCategoryCount
  activeItemCount
  activeBulkPurchaseCount
  lastPurchaseVariance
  purchaseVariancePercenetage
}
    `;

/**
 * __useGetGeneralSettingSummaryQuery__
 *
 * To run a query within a React component, call `useGetGeneralSettingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralSettingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralSettingSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGeneralSettingSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralSettingSummaryQuery, GetGeneralSettingSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralSettingSummaryQuery, GetGeneralSettingSummaryQueryVariables>(GetGeneralSettingSummaryDocument, baseOptions);
      }
export function useGetGeneralSettingSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralSettingSummaryQuery, GetGeneralSettingSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralSettingSummaryQuery, GetGeneralSettingSummaryQueryVariables>(GetGeneralSettingSummaryDocument, baseOptions);
        }
export type GetGeneralSettingSummaryQueryHookResult = ReturnType<typeof useGetGeneralSettingSummaryQuery>;
export type GetGeneralSettingSummaryLazyQueryHookResult = ReturnType<typeof useGetGeneralSettingSummaryLazyQuery>;
export type GetGeneralSettingSummaryQueryResult = ApolloReactCommon.QueryResult<GetGeneralSettingSummaryQuery, GetGeneralSettingSummaryQueryVariables>;
export const GetRoleWithAccountDocument = gql`
    query getRoleWithAccount($DifferentConnection: String) {
  getRoleWithAccount(DifferentConnection: $DifferentConnection) {
    ID
    accountID
    name
    createdTs
    createdBy
    modTs
  }
}
    `;

/**
 * __useGetRoleWithAccountQuery__
 *
 * To run a query within a React component, call `useGetRoleWithAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleWithAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleWithAccountQuery({
 *   variables: {
 *      DifferentConnection: // value for 'DifferentConnection'
 *   },
 * });
 */
export function useGetRoleWithAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoleWithAccountQuery, GetRoleWithAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRoleWithAccountQuery, GetRoleWithAccountQueryVariables>(GetRoleWithAccountDocument, baseOptions);
      }
export function useGetRoleWithAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoleWithAccountQuery, GetRoleWithAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRoleWithAccountQuery, GetRoleWithAccountQueryVariables>(GetRoleWithAccountDocument, baseOptions);
        }
export type GetRoleWithAccountQueryHookResult = ReturnType<typeof useGetRoleWithAccountQuery>;
export type GetRoleWithAccountLazyQueryHookResult = ReturnType<typeof useGetRoleWithAccountLazyQuery>;
export type GetRoleWithAccountQueryResult = ApolloReactCommon.QueryResult<GetRoleWithAccountQuery, GetRoleWithAccountQueryVariables>;
export const GetItemDocument = gql`
    query getItem($skip: Float, $take: Float, $ItemID: String, $SupplierID: String, $orderByAsc: String, $orderByDesc: String, $ItemCategoryID: String) {
  getItem(skip: $skip, take: $take, SupplierID: $SupplierID, ItemID: $ItemID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, ItemCategoryID: $ItemCategoryID) {
    ItemID
    ItemCategoryID
    ItemCategory {
      MasterCOAID
      MasterCOA {
        MasterCOAID
        Name
        Code
      }
      ParentCategoryMCOA
      Name
    }
    AccountID
    Name
    Description
    IsCentralised
    IsInventory
    RecordStatus
    DefaultSupplierID
    DefaultSupplier {
      CreditorAccountID
      CompanyName
      CompanyRegNo
    }
    PRItem {
      PRItemID
    }
    UOM {
      UOMID
      Name
      Code
    }
    UOMID
    TaxSchemeID
    TaxScheme {
      TaxSchemeID
      Code
      Description
      AccTaxCategory
    }
    ItemCategory {
      Name
    }
    Description
    Remark
    VariancePerc
    IsUse
    UsedInIC
    UOM {
      Code
    }
    LatestUOMID
    TaxSchemeID
    MarketPrice
    LatestPurchasePrice
    LatestUOMCode
  }
}
    `;

/**
 * __useGetItemQuery__
 *
 * To run a query within a React component, call `useGetItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      ItemID: // value for 'ItemID'
 *      SupplierID: // value for 'SupplierID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      ItemCategoryID: // value for 'ItemCategoryID'
 *   },
 * });
 */
export function useGetItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetItemQuery, GetItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetItemQuery, GetItemQueryVariables>(GetItemDocument, baseOptions);
      }
export function useGetItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetItemQuery, GetItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetItemQuery, GetItemQueryVariables>(GetItemDocument, baseOptions);
        }
export type GetItemQueryHookResult = ReturnType<typeof useGetItemQuery>;
export type GetItemLazyQueryHookResult = ReturnType<typeof useGetItemLazyQuery>;
export type GetItemQueryResult = ApolloReactCommon.QueryResult<GetItemQuery, GetItemQueryVariables>;
export const GetItemNameDocument = gql`
    query getItemName($skip: Float, $take: Float, $ItemID: String, $SupplierID: String, $orderByAsc: String, $orderByDesc: String, $ItemCategoryID: String) {
  getItem(skip: $skip, take: $take, SupplierID: $SupplierID, ItemID: $ItemID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, ItemCategoryID: $ItemCategoryID) {
    ItemID
    ItemCategoryID
    Name
  }
}
    `;

/**
 * __useGetItemNameQuery__
 *
 * To run a query within a React component, call `useGetItemNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemNameQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      ItemID: // value for 'ItemID'
 *      SupplierID: // value for 'SupplierID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      ItemCategoryID: // value for 'ItemCategoryID'
 *   },
 * });
 */
export function useGetItemNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetItemNameQuery, GetItemNameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetItemNameQuery, GetItemNameQueryVariables>(GetItemNameDocument, baseOptions);
      }
export function useGetItemNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetItemNameQuery, GetItemNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetItemNameQuery, GetItemNameQueryVariables>(GetItemNameDocument, baseOptions);
        }
export type GetItemNameQueryHookResult = ReturnType<typeof useGetItemNameQuery>;
export type GetItemNameLazyQueryHookResult = ReturnType<typeof useGetItemNameLazyQuery>;
export type GetItemNameQueryResult = ApolloReactCommon.QueryResult<GetItemNameQuery, GetItemNameQueryVariables>;
export const DeleteItemDocument = gql`
    mutation deleteItem($ItemID: String!) {
  deleteItem(ItemID: $ItemID)
}
    `;
export type DeleteItemMutationFn = ApolloReactCommon.MutationFunction<DeleteItemMutation, DeleteItemMutationVariables>;

/**
 * __useDeleteItemMutation__
 *
 * To run a mutation, you first call `useDeleteItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemMutation, { data, loading, error }] = useDeleteItemMutation({
 *   variables: {
 *      ItemID: // value for 'ItemID'
 *   },
 * });
 */
export function useDeleteItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteItemMutation, DeleteItemMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteItemMutation, DeleteItemMutationVariables>(DeleteItemDocument, baseOptions);
      }
export type DeleteItemMutationHookResult = ReturnType<typeof useDeleteItemMutation>;
export type DeleteItemMutationResult = ApolloReactCommon.MutationResult<DeleteItemMutation>;
export type DeleteItemMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteItemMutation, DeleteItemMutationVariables>;
export const GetCheckingValidationItemDocument = gql`
    query getCheckingValidationItem($ItemExcelInput: [ItemExcelInput!]!) {
  getCheckingValidationItem(ItemExcelInput: $ItemExcelInput)
}
    `;

/**
 * __useGetCheckingValidationItemQuery__
 *
 * To run a query within a React component, call `useGetCheckingValidationItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckingValidationItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckingValidationItemQuery({
 *   variables: {
 *      ItemExcelInput: // value for 'ItemExcelInput'
 *   },
 * });
 */
export function useGetCheckingValidationItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCheckingValidationItemQuery, GetCheckingValidationItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCheckingValidationItemQuery, GetCheckingValidationItemQueryVariables>(GetCheckingValidationItemDocument, baseOptions);
      }
export function useGetCheckingValidationItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCheckingValidationItemQuery, GetCheckingValidationItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCheckingValidationItemQuery, GetCheckingValidationItemQueryVariables>(GetCheckingValidationItemDocument, baseOptions);
        }
export type GetCheckingValidationItemQueryHookResult = ReturnType<typeof useGetCheckingValidationItemQuery>;
export type GetCheckingValidationItemLazyQueryHookResult = ReturnType<typeof useGetCheckingValidationItemLazyQuery>;
export type GetCheckingValidationItemQueryResult = ApolloReactCommon.QueryResult<GetCheckingValidationItemQuery, GetCheckingValidationItemQueryVariables>;
export const CreateItemDocument = gql`
    mutation createItem($input: ItemInput!) {
  createItem(input: $input) {
    ItemID
    createdBy
  }
}
    `;
export type CreateItemMutationFn = ApolloReactCommon.MutationFunction<CreateItemMutation, CreateItemMutationVariables>;

/**
 * __useCreateItemMutation__
 *
 * To run a mutation, you first call `useCreateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemMutation, { data, loading, error }] = useCreateItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateItemMutation, CreateItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateItemMutation, CreateItemMutationVariables>(CreateItemDocument, baseOptions);
      }
export type CreateItemMutationHookResult = ReturnType<typeof useCreateItemMutation>;
export type CreateItemMutationResult = ApolloReactCommon.MutationResult<CreateItemMutation>;
export type CreateItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateItemMutation, CreateItemMutationVariables>;
export const UpdateItemDocument = gql`
    mutation updateItem($input: ItemInput!) {
  updateItem(input: $input)
}
    `;
export type UpdateItemMutationFn = ApolloReactCommon.MutationFunction<UpdateItemMutation, UpdateItemMutationVariables>;

/**
 * __useUpdateItemMutation__
 *
 * To run a mutation, you first call `useUpdateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemMutation, { data, loading, error }] = useUpdateItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateItemMutation, UpdateItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateItemMutation, UpdateItemMutationVariables>(UpdateItemDocument, baseOptions);
      }
export type UpdateItemMutationHookResult = ReturnType<typeof useUpdateItemMutation>;
export type UpdateItemMutationResult = ApolloReactCommon.MutationResult<UpdateItemMutation>;
export type UpdateItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateItemMutation, UpdateItemMutationVariables>;
export const CreateBatchItemDocument = gql`
    mutation createBatchItem($input: [ItemExcelInput!]!) {
  createBatchItem(input: $input)
}
    `;
export type CreateBatchItemMutationFn = ApolloReactCommon.MutationFunction<CreateBatchItemMutation, CreateBatchItemMutationVariables>;

/**
 * __useCreateBatchItemMutation__
 *
 * To run a mutation, you first call `useCreateBatchItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBatchItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBatchItemMutation, { data, loading, error }] = useCreateBatchItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBatchItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBatchItemMutation, CreateBatchItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBatchItemMutation, CreateBatchItemMutationVariables>(CreateBatchItemDocument, baseOptions);
      }
export type CreateBatchItemMutationHookResult = ReturnType<typeof useCreateBatchItemMutation>;
export type CreateBatchItemMutationResult = ApolloReactCommon.MutationResult<CreateBatchItemMutation>;
export type CreateBatchItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBatchItemMutation, CreateBatchItemMutationVariables>;
export const CancelItemDocument = gql`
    mutation cancelItem($ItemID: String!) {
  cancelItem(ItemID: $ItemID)
}
    `;
export type CancelItemMutationFn = ApolloReactCommon.MutationFunction<CancelItemMutation, CancelItemMutationVariables>;

/**
 * __useCancelItemMutation__
 *
 * To run a mutation, you first call `useCancelItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelItemMutation, { data, loading, error }] = useCancelItemMutation({
 *   variables: {
 *      ItemID: // value for 'ItemID'
 *   },
 * });
 */
export function useCancelItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelItemMutation, CancelItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelItemMutation, CancelItemMutationVariables>(CancelItemDocument, baseOptions);
      }
export type CancelItemMutationHookResult = ReturnType<typeof useCancelItemMutation>;
export type CancelItemMutationResult = ApolloReactCommon.MutationResult<CancelItemMutation>;
export type CancelItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelItemMutation, CancelItemMutationVariables>;
export const GetApItemMappingDocument = gql`
    query getAPItemMapping($ExpenseItemID: String, $UOMID: String, $orderByAsc: String, $orderByDesc: String) {
  getExpenseItem(ExpenseItemID: $ExpenseItemID, UOMID: $UOMID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ExpenseItemID
    ExpenseClassID
    ItemName
    Description
    Remark
    TaxSchemeID
    TaxRate
  }
}
    `;

/**
 * __useGetApItemMappingQuery__
 *
 * To run a query within a React component, call `useGetApItemMappingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApItemMappingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApItemMappingQuery({
 *   variables: {
 *      ExpenseItemID: // value for 'ExpenseItemID'
 *      UOMID: // value for 'UOMID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetApItemMappingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApItemMappingQuery, GetApItemMappingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApItemMappingQuery, GetApItemMappingQueryVariables>(GetApItemMappingDocument, baseOptions);
      }
export function useGetApItemMappingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApItemMappingQuery, GetApItemMappingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApItemMappingQuery, GetApItemMappingQueryVariables>(GetApItemMappingDocument, baseOptions);
        }
export type GetApItemMappingQueryHookResult = ReturnType<typeof useGetApItemMappingQuery>;
export type GetApItemMappingLazyQueryHookResult = ReturnType<typeof useGetApItemMappingLazyQuery>;
export type GetApItemMappingQueryResult = ApolloReactCommon.QueryResult<GetApItemMappingQuery, GetApItemMappingQueryVariables>;
export const GetWarehouseByStockItemDocument = gql`
    query getWarehouseByStockItem($StockItemID: String!, $CompanyID: String!) {
  getWarehouseByStockItem(StockItemID: $StockItemID, CompanyID: $CompanyID)
}
    `;

/**
 * __useGetWarehouseByStockItemQuery__
 *
 * To run a query within a React component, call `useGetWarehouseByStockItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehouseByStockItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehouseByStockItemQuery({
 *   variables: {
 *      StockItemID: // value for 'StockItemID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetWarehouseByStockItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWarehouseByStockItemQuery, GetWarehouseByStockItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWarehouseByStockItemQuery, GetWarehouseByStockItemQueryVariables>(GetWarehouseByStockItemDocument, baseOptions);
      }
export function useGetWarehouseByStockItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWarehouseByStockItemQuery, GetWarehouseByStockItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWarehouseByStockItemQuery, GetWarehouseByStockItemQueryVariables>(GetWarehouseByStockItemDocument, baseOptions);
        }
export type GetWarehouseByStockItemQueryHookResult = ReturnType<typeof useGetWarehouseByStockItemQuery>;
export type GetWarehouseByStockItemLazyQueryHookResult = ReturnType<typeof useGetWarehouseByStockItemLazyQuery>;
export type GetWarehouseByStockItemQueryResult = ApolloReactCommon.QueryResult<GetWarehouseByStockItemQuery, GetWarehouseByStockItemQueryVariables>;
export const GetItemByCompanyAssignmentDocument = gql`
    query getItemByCompanyAssignment($CompanyID: String!, $orderByAsc: String, $orderByDesc: String) {
  getItemByCompanyAssignment(CompanyID: $CompanyID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ItemID
    ItemCategoryID
    Name
    IsCentralised
    IsInventory
    DefaultSupplierID
    PRItem {
      PRItemID
    }
    UOMID
    UOM {
      Code
    }
    LatestUOMID
    TaxSchemeID
    MarketPrice
    VariancePerc
    LatestPurchasePrice
    LatestUOMCode
  }
}
    `;

/**
 * __useGetItemByCompanyAssignmentQuery__
 *
 * To run a query within a React component, call `useGetItemByCompanyAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemByCompanyAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemByCompanyAssignmentQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetItemByCompanyAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetItemByCompanyAssignmentQuery, GetItemByCompanyAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetItemByCompanyAssignmentQuery, GetItemByCompanyAssignmentQueryVariables>(GetItemByCompanyAssignmentDocument, baseOptions);
      }
export function useGetItemByCompanyAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetItemByCompanyAssignmentQuery, GetItemByCompanyAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetItemByCompanyAssignmentQuery, GetItemByCompanyAssignmentQueryVariables>(GetItemByCompanyAssignmentDocument, baseOptions);
        }
export type GetItemByCompanyAssignmentQueryHookResult = ReturnType<typeof useGetItemByCompanyAssignmentQuery>;
export type GetItemByCompanyAssignmentLazyQueryHookResult = ReturnType<typeof useGetItemByCompanyAssignmentLazyQuery>;
export type GetItemByCompanyAssignmentQueryResult = ApolloReactCommon.QueryResult<GetItemByCompanyAssignmentQuery, GetItemByCompanyAssignmentQueryVariables>;
export const GetItemByCompanyAssignmentListingDocument = gql`
    query getItemByCompanyAssignmentListing($CompanyID: String!, $orderByAsc: String, $orderByDesc: String) {
  getItemByCompanyAssignment(CompanyID: $CompanyID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ItemID
    ItemCategoryID
    Name
    IsCentralised
    IsInventory
    DefaultSupplierID
    UOMID
    UOM {
      Code
    }
    LatestUOMID
    TaxSchemeID
    MarketPrice
    VariancePerc
    LatestPurchasePrice
    LatestUOMCode
    LatestUOMID
  }
}
    `;

/**
 * __useGetItemByCompanyAssignmentListingQuery__
 *
 * To run a query within a React component, call `useGetItemByCompanyAssignmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemByCompanyAssignmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemByCompanyAssignmentListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetItemByCompanyAssignmentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetItemByCompanyAssignmentListingQuery, GetItemByCompanyAssignmentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetItemByCompanyAssignmentListingQuery, GetItemByCompanyAssignmentListingQueryVariables>(GetItemByCompanyAssignmentListingDocument, baseOptions);
      }
export function useGetItemByCompanyAssignmentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetItemByCompanyAssignmentListingQuery, GetItemByCompanyAssignmentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetItemByCompanyAssignmentListingQuery, GetItemByCompanyAssignmentListingQueryVariables>(GetItemByCompanyAssignmentListingDocument, baseOptions);
        }
export type GetItemByCompanyAssignmentListingQueryHookResult = ReturnType<typeof useGetItemByCompanyAssignmentListingQuery>;
export type GetItemByCompanyAssignmentListingLazyQueryHookResult = ReturnType<typeof useGetItemByCompanyAssignmentListingLazyQuery>;
export type GetItemByCompanyAssignmentListingQueryResult = ApolloReactCommon.QueryResult<GetItemByCompanyAssignmentListingQuery, GetItemByCompanyAssignmentListingQueryVariables>;
export const GetItemCategoryDocument = gql`
    query getItemCategory($AccountID: String, $orderByDesc: String, $orderByAsc: String, $ItemCategoryID: String) {
  getItemCategory(AccountID: $AccountID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, ItemCategoryID: $ItemCategoryID) {
    ItemCategoryID
    Name
    ParentItemCategoryID
    Level
    IsLastNode
    MasterCOAID
    MasterCOA {
      MasterCOAID
      Code
      Name
    }
    ParentCategoryMCOA
    Item {
      Name
      Description
    }
  }
}
    `;

/**
 * __useGetItemCategoryQuery__
 *
 * To run a query within a React component, call `useGetItemCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemCategoryQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      ItemCategoryID: // value for 'ItemCategoryID'
 *   },
 * });
 */
export function useGetItemCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetItemCategoryQuery, GetItemCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetItemCategoryQuery, GetItemCategoryQueryVariables>(GetItemCategoryDocument, baseOptions);
      }
export function useGetItemCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetItemCategoryQuery, GetItemCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetItemCategoryQuery, GetItemCategoryQueryVariables>(GetItemCategoryDocument, baseOptions);
        }
export type GetItemCategoryQueryHookResult = ReturnType<typeof useGetItemCategoryQuery>;
export type GetItemCategoryLazyQueryHookResult = ReturnType<typeof useGetItemCategoryLazyQuery>;
export type GetItemCategoryQueryResult = ApolloReactCommon.QueryResult<GetItemCategoryQuery, GetItemCategoryQueryVariables>;
export const GetItemCategoryNameDocument = gql`
    query getItemCategoryName($AccountID: String, $orderByDesc: String, $orderByAsc: String, $ItemCategoryID: String) {
  getItemCategory(AccountID: $AccountID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, ItemCategoryID: $ItemCategoryID) {
    ItemCategoryID
    Name
    ParentItemCategoryID
    Level
    IsLastNode
  }
}
    `;

/**
 * __useGetItemCategoryNameQuery__
 *
 * To run a query within a React component, call `useGetItemCategoryNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemCategoryNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemCategoryNameQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      ItemCategoryID: // value for 'ItemCategoryID'
 *   },
 * });
 */
export function useGetItemCategoryNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetItemCategoryNameQuery, GetItemCategoryNameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetItemCategoryNameQuery, GetItemCategoryNameQueryVariables>(GetItemCategoryNameDocument, baseOptions);
      }
export function useGetItemCategoryNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetItemCategoryNameQuery, GetItemCategoryNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetItemCategoryNameQuery, GetItemCategoryNameQueryVariables>(GetItemCategoryNameDocument, baseOptions);
        }
export type GetItemCategoryNameQueryHookResult = ReturnType<typeof useGetItemCategoryNameQuery>;
export type GetItemCategoryNameLazyQueryHookResult = ReturnType<typeof useGetItemCategoryNameLazyQuery>;
export type GetItemCategoryNameQueryResult = ApolloReactCommon.QueryResult<GetItemCategoryNameQuery, GetItemCategoryNameQueryVariables>;
export const GetItemCategoryCompanyAssignmentDocument = gql`
    query getItemCategoryCompanyAssignment($ItemCategoryCompanyAssignmentID: String, $CompanyID: String, $ItemCategoryID: String) {
  getItemCategoryCompanyAssignment(ItemCategoryCompanyAssignmentID: $ItemCategoryCompanyAssignmentID, CompanyID: $CompanyID, ItemCategoryID: $ItemCategoryID) {
    ItemCategoryCompanyAssignmentID
    ItemCategoryID
    CompanyID
    createdTs
    createdBy
    modTs
    modBy
    RecordStatus
    ItemCategory {
      createdTs
      createdBy
      modTs
      modBy
      ItemCategoryID
      MasterCOAID
      RecordStatus
      Name
    }
  }
}
    `;

/**
 * __useGetItemCategoryCompanyAssignmentQuery__
 *
 * To run a query within a React component, call `useGetItemCategoryCompanyAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemCategoryCompanyAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemCategoryCompanyAssignmentQuery({
 *   variables: {
 *      ItemCategoryCompanyAssignmentID: // value for 'ItemCategoryCompanyAssignmentID'
 *      CompanyID: // value for 'CompanyID'
 *      ItemCategoryID: // value for 'ItemCategoryID'
 *   },
 * });
 */
export function useGetItemCategoryCompanyAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetItemCategoryCompanyAssignmentQuery, GetItemCategoryCompanyAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetItemCategoryCompanyAssignmentQuery, GetItemCategoryCompanyAssignmentQueryVariables>(GetItemCategoryCompanyAssignmentDocument, baseOptions);
      }
export function useGetItemCategoryCompanyAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetItemCategoryCompanyAssignmentQuery, GetItemCategoryCompanyAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetItemCategoryCompanyAssignmentQuery, GetItemCategoryCompanyAssignmentQueryVariables>(GetItemCategoryCompanyAssignmentDocument, baseOptions);
        }
export type GetItemCategoryCompanyAssignmentQueryHookResult = ReturnType<typeof useGetItemCategoryCompanyAssignmentQuery>;
export type GetItemCategoryCompanyAssignmentLazyQueryHookResult = ReturnType<typeof useGetItemCategoryCompanyAssignmentLazyQuery>;
export type GetItemCategoryCompanyAssignmentQueryResult = ApolloReactCommon.QueryResult<GetItemCategoryCompanyAssignmentQuery, GetItemCategoryCompanyAssignmentQueryVariables>;
export const CreateItemCategoryDocument = gql`
    mutation createItemCategory($ItemCategoryInput: ItemCategoryInput!) {
  createItemCategory(ItemCategoryInput: $ItemCategoryInput) {
    ItemCategoryID
    createdBy
    Name
    MasterCOAID
  }
}
    `;
export type CreateItemCategoryMutationFn = ApolloReactCommon.MutationFunction<CreateItemCategoryMutation, CreateItemCategoryMutationVariables>;

/**
 * __useCreateItemCategoryMutation__
 *
 * To run a mutation, you first call `useCreateItemCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemCategoryMutation, { data, loading, error }] = useCreateItemCategoryMutation({
 *   variables: {
 *      ItemCategoryInput: // value for 'ItemCategoryInput'
 *   },
 * });
 */
export function useCreateItemCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateItemCategoryMutation, CreateItemCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateItemCategoryMutation, CreateItemCategoryMutationVariables>(CreateItemCategoryDocument, baseOptions);
      }
export type CreateItemCategoryMutationHookResult = ReturnType<typeof useCreateItemCategoryMutation>;
export type CreateItemCategoryMutationResult = ApolloReactCommon.MutationResult<CreateItemCategoryMutation>;
export type CreateItemCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateItemCategoryMutation, CreateItemCategoryMutationVariables>;
export const UpdateItemCategoryDocument = gql`
    mutation updateItemCategory($ItemCategoryInput: ItemCategoryInput!) {
  updateItemCategory(ItemCategoryInput: $ItemCategoryInput)
}
    `;
export type UpdateItemCategoryMutationFn = ApolloReactCommon.MutationFunction<UpdateItemCategoryMutation, UpdateItemCategoryMutationVariables>;

/**
 * __useUpdateItemCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateItemCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemCategoryMutation, { data, loading, error }] = useUpdateItemCategoryMutation({
 *   variables: {
 *      ItemCategoryInput: // value for 'ItemCategoryInput'
 *   },
 * });
 */
export function useUpdateItemCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateItemCategoryMutation, UpdateItemCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateItemCategoryMutation, UpdateItemCategoryMutationVariables>(UpdateItemCategoryDocument, baseOptions);
      }
export type UpdateItemCategoryMutationHookResult = ReturnType<typeof useUpdateItemCategoryMutation>;
export type UpdateItemCategoryMutationResult = ApolloReactCommon.MutationResult<UpdateItemCategoryMutation>;
export type UpdateItemCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateItemCategoryMutation, UpdateItemCategoryMutationVariables>;
export const DeleteItemCategoryDocument = gql`
    mutation deleteItemCategory($ItemCategoryID: String!) {
  deleteItemCategory(ItemCategoryID: $ItemCategoryID)
}
    `;
export type DeleteItemCategoryMutationFn = ApolloReactCommon.MutationFunction<DeleteItemCategoryMutation, DeleteItemCategoryMutationVariables>;

/**
 * __useDeleteItemCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteItemCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemCategoryMutation, { data, loading, error }] = useDeleteItemCategoryMutation({
 *   variables: {
 *      ItemCategoryID: // value for 'ItemCategoryID'
 *   },
 * });
 */
export function useDeleteItemCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteItemCategoryMutation, DeleteItemCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteItemCategoryMutation, DeleteItemCategoryMutationVariables>(DeleteItemCategoryDocument, baseOptions);
      }
export type DeleteItemCategoryMutationHookResult = ReturnType<typeof useDeleteItemCategoryMutation>;
export type DeleteItemCategoryMutationResult = ApolloReactCommon.MutationResult<DeleteItemCategoryMutation>;
export type DeleteItemCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteItemCategoryMutation, DeleteItemCategoryMutationVariables>;
export const AssignItemCategoryCompanyDocument = gql`
    mutation AssignItemCategoryCompany($input: ItemCategoryCompanyAssignmentInput!) {
  AssignItemCategoryCompany(input: $input)
}
    `;
export type AssignItemCategoryCompanyMutationFn = ApolloReactCommon.MutationFunction<AssignItemCategoryCompanyMutation, AssignItemCategoryCompanyMutationVariables>;

/**
 * __useAssignItemCategoryCompanyMutation__
 *
 * To run a mutation, you first call `useAssignItemCategoryCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignItemCategoryCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignItemCategoryCompanyMutation, { data, loading, error }] = useAssignItemCategoryCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignItemCategoryCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignItemCategoryCompanyMutation, AssignItemCategoryCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignItemCategoryCompanyMutation, AssignItemCategoryCompanyMutationVariables>(AssignItemCategoryCompanyDocument, baseOptions);
      }
export type AssignItemCategoryCompanyMutationHookResult = ReturnType<typeof useAssignItemCategoryCompanyMutation>;
export type AssignItemCategoryCompanyMutationResult = ApolloReactCommon.MutationResult<AssignItemCategoryCompanyMutation>;
export type AssignItemCategoryCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignItemCategoryCompanyMutation, AssignItemCategoryCompanyMutationVariables>;
export const UnassignItemCategoryCompanyDocument = gql`
    mutation UnassignItemCategoryCompany($input: ItemCategoryCompanyAssignmentInput!) {
  UnassignItemCategoryCompany(input: $input)
}
    `;
export type UnassignItemCategoryCompanyMutationFn = ApolloReactCommon.MutationFunction<UnassignItemCategoryCompanyMutation, UnassignItemCategoryCompanyMutationVariables>;

/**
 * __useUnassignItemCategoryCompanyMutation__
 *
 * To run a mutation, you first call `useUnassignItemCategoryCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignItemCategoryCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignItemCategoryCompanyMutation, { data, loading, error }] = useUnassignItemCategoryCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnassignItemCategoryCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnassignItemCategoryCompanyMutation, UnassignItemCategoryCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UnassignItemCategoryCompanyMutation, UnassignItemCategoryCompanyMutationVariables>(UnassignItemCategoryCompanyDocument, baseOptions);
      }
export type UnassignItemCategoryCompanyMutationHookResult = ReturnType<typeof useUnassignItemCategoryCompanyMutation>;
export type UnassignItemCategoryCompanyMutationResult = ApolloReactCommon.MutationResult<UnassignItemCategoryCompanyMutation>;
export type UnassignItemCategoryCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UnassignItemCategoryCompanyMutation, UnassignItemCategoryCompanyMutationVariables>;
export const GetDocNumDocument = gql`
    query getDocNum($CompanyID: String!, $Type: DocumentType, $RefTable: String) {
  getDocumentNumberHeader(CompanyID: $CompanyID, Type: $Type, RefTable: $RefTable) {
    DocumentNumberHeaderID
    SampleOutput
    IsAutoDocNo
    DocNumGenerated
    DocumentNumberDetail {
      DocumentNumberDetailID
      Sequence
      FormatType
      FormatValue
      NextNumber
      StartFrom
      ResetBy
    }
  }
  getDocumentNumberDetail {
    Sequence
    FormatType
    FormatValue
    NextNumber
    StartFrom
    ResetBy
  }
}
    `;

/**
 * __useGetDocNumQuery__
 *
 * To run a query within a React component, call `useGetDocNumQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocNumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocNumQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Type: // value for 'Type'
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function useGetDocNumQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDocNumQuery, GetDocNumQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDocNumQuery, GetDocNumQueryVariables>(GetDocNumDocument, baseOptions);
      }
export function useGetDocNumLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDocNumQuery, GetDocNumQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDocNumQuery, GetDocNumQueryVariables>(GetDocNumDocument, baseOptions);
        }
export type GetDocNumQueryHookResult = ReturnType<typeof useGetDocNumQuery>;
export type GetDocNumLazyQueryHookResult = ReturnType<typeof useGetDocNumLazyQuery>;
export type GetDocNumQueryResult = ApolloReactCommon.QueryResult<GetDocNumQuery, GetDocNumQueryVariables>;
export const GetDocumentNumberHeaderDocument = gql`
    query getDocumentNumberHeader($CompanyID: String!, $Type: DocumentType, $RefTable: String) {
  getDocumentNumberHeader(CompanyID: $CompanyID, Type: $Type, RefTable: $RefTable) {
    DocumentNumberHeaderID
    SampleOutput
    IsAutoDocNo
    DocNumGenerated
    RefTable
  }
}
    `;

/**
 * __useGetDocumentNumberHeaderQuery__
 *
 * To run a query within a React component, call `useGetDocumentNumberHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentNumberHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentNumberHeaderQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Type: // value for 'Type'
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function useGetDocumentNumberHeaderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDocumentNumberHeaderQuery, GetDocumentNumberHeaderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDocumentNumberHeaderQuery, GetDocumentNumberHeaderQueryVariables>(GetDocumentNumberHeaderDocument, baseOptions);
      }
export function useGetDocumentNumberHeaderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDocumentNumberHeaderQuery, GetDocumentNumberHeaderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDocumentNumberHeaderQuery, GetDocumentNumberHeaderQueryVariables>(GetDocumentNumberHeaderDocument, baseOptions);
        }
export type GetDocumentNumberHeaderQueryHookResult = ReturnType<typeof useGetDocumentNumberHeaderQuery>;
export type GetDocumentNumberHeaderLazyQueryHookResult = ReturnType<typeof useGetDocumentNumberHeaderLazyQuery>;
export type GetDocumentNumberHeaderQueryResult = ApolloReactCommon.QueryResult<GetDocumentNumberHeaderQuery, GetDocumentNumberHeaderQueryVariables>;
export const GetDocumentNumberDetailDocument = gql`
    query getDocumentNumberDetail($DocumentNumberHeaderID: String!) {
  getDocumentNumberDetail(DocumentNumberHeaderID: $DocumentNumberHeaderID) {
    DocumentNumberDetailID
    Sequence
    FormatType
    FormatValue
    NextNumber
    StartFrom
    ResetBy
  }
}
    `;

/**
 * __useGetDocumentNumberDetailQuery__
 *
 * To run a query within a React component, call `useGetDocumentNumberDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentNumberDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentNumberDetailQuery({
 *   variables: {
 *      DocumentNumberHeaderID: // value for 'DocumentNumberHeaderID'
 *   },
 * });
 */
export function useGetDocumentNumberDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDocumentNumberDetailQuery, GetDocumentNumberDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDocumentNumberDetailQuery, GetDocumentNumberDetailQueryVariables>(GetDocumentNumberDetailDocument, baseOptions);
      }
export function useGetDocumentNumberDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDocumentNumberDetailQuery, GetDocumentNumberDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDocumentNumberDetailQuery, GetDocumentNumberDetailQueryVariables>(GetDocumentNumberDetailDocument, baseOptions);
        }
export type GetDocumentNumberDetailQueryHookResult = ReturnType<typeof useGetDocumentNumberDetailQuery>;
export type GetDocumentNumberDetailLazyQueryHookResult = ReturnType<typeof useGetDocumentNumberDetailLazyQuery>;
export type GetDocumentNumberDetailQueryResult = ApolloReactCommon.QueryResult<GetDocumentNumberDetailQuery, GetDocumentNumberDetailQueryVariables>;
export const GetDocNumTitleDocument = gql`
    query getDocNumTitle($CompanyID: String!, $Type: DocumentType, $RefTable: String) {
  getDocumentNumberHeader(CompanyID: $CompanyID, Type: $Type, RefTable: $RefTable) {
    SampleOutput
    DocumentNumberHeaderID
    Type
    RefTable
    IsAutoDocNo
    DocNumGenerated
  }
}
    `;

/**
 * __useGetDocNumTitleQuery__
 *
 * To run a query within a React component, call `useGetDocNumTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocNumTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocNumTitleQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Type: // value for 'Type'
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function useGetDocNumTitleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDocNumTitleQuery, GetDocNumTitleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDocNumTitleQuery, GetDocNumTitleQueryVariables>(GetDocNumTitleDocument, baseOptions);
      }
export function useGetDocNumTitleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDocNumTitleQuery, GetDocNumTitleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDocNumTitleQuery, GetDocNumTitleQueryVariables>(GetDocNumTitleDocument, baseOptions);
        }
export type GetDocNumTitleQueryHookResult = ReturnType<typeof useGetDocNumTitleQuery>;
export type GetDocNumTitleLazyQueryHookResult = ReturnType<typeof useGetDocNumTitleLazyQuery>;
export type GetDocNumTitleQueryResult = ApolloReactCommon.QueryResult<GetDocNumTitleQuery, GetDocNumTitleQueryVariables>;
export const UpdateDocumentNumberHeaderDocument = gql`
    mutation updateDocumentNumberHeader($DocumentNumberDetailInput: [DocumentNumberDetailInput!]!, $DocumentNumberHeaderInput: DocumentNumberHeaderInput!, $DifferentConnection: String) {
  updateDocumentNumberHeader(DocumentNumberDetailInput: $DocumentNumberDetailInput, DocumentNumberHeaderInput: $DocumentNumberHeaderInput, DifferentConnection: $DifferentConnection)
}
    `;
export type UpdateDocumentNumberHeaderMutationFn = ApolloReactCommon.MutationFunction<UpdateDocumentNumberHeaderMutation, UpdateDocumentNumberHeaderMutationVariables>;

/**
 * __useUpdateDocumentNumberHeaderMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentNumberHeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentNumberHeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentNumberHeaderMutation, { data, loading, error }] = useUpdateDocumentNumberHeaderMutation({
 *   variables: {
 *      DocumentNumberDetailInput: // value for 'DocumentNumberDetailInput'
 *      DocumentNumberHeaderInput: // value for 'DocumentNumberHeaderInput'
 *      DifferentConnection: // value for 'DifferentConnection'
 *   },
 * });
 */
export function useUpdateDocumentNumberHeaderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDocumentNumberHeaderMutation, UpdateDocumentNumberHeaderMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDocumentNumberHeaderMutation, UpdateDocumentNumberHeaderMutationVariables>(UpdateDocumentNumberHeaderDocument, baseOptions);
      }
export type UpdateDocumentNumberHeaderMutationHookResult = ReturnType<typeof useUpdateDocumentNumberHeaderMutation>;
export type UpdateDocumentNumberHeaderMutationResult = ApolloReactCommon.MutationResult<UpdateDocumentNumberHeaderMutation>;
export type UpdateDocumentNumberHeaderMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDocumentNumberHeaderMutation, UpdateDocumentNumberHeaderMutationVariables>;
export const UpdateIsAutoDocNoDocument = gql`
    mutation updateIsAutoDocNo($CompanyID: String!, $RefTable: String!, $IsAutoDocNo: Boolean!, $input: DocumentNumberHeaderInput!) {
  updateIsAutoDocNo(CompanyID: $CompanyID, RefTable: $RefTable, IsAutoDocNo: $IsAutoDocNo, input: $input)
}
    `;
export type UpdateIsAutoDocNoMutationFn = ApolloReactCommon.MutationFunction<UpdateIsAutoDocNoMutation, UpdateIsAutoDocNoMutationVariables>;

/**
 * __useUpdateIsAutoDocNoMutation__
 *
 * To run a mutation, you first call `useUpdateIsAutoDocNoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIsAutoDocNoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIsAutoDocNoMutation, { data, loading, error }] = useUpdateIsAutoDocNoMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      RefTable: // value for 'RefTable'
 *      IsAutoDocNo: // value for 'IsAutoDocNo'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIsAutoDocNoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateIsAutoDocNoMutation, UpdateIsAutoDocNoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateIsAutoDocNoMutation, UpdateIsAutoDocNoMutationVariables>(UpdateIsAutoDocNoDocument, baseOptions);
      }
export type UpdateIsAutoDocNoMutationHookResult = ReturnType<typeof useUpdateIsAutoDocNoMutation>;
export type UpdateIsAutoDocNoMutationResult = ApolloReactCommon.MutationResult<UpdateIsAutoDocNoMutation>;
export type UpdateIsAutoDocNoMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateIsAutoDocNoMutation, UpdateIsAutoDocNoMutationVariables>;
export const GetPurchaseItemVariancePolicyDocument = gql`
    query getPurchaseItemVariancePolicy($PurchaseItemVariancePolicyID: String) {
  getPurchaseItemVariancePolicy(PurchaseItemVariancePolicyID: $PurchaseItemVariancePolicyID) {
    PurchaseItemVariancePolicyID
    VariancePerc
  }
}
    `;

/**
 * __useGetPurchaseItemVariancePolicyQuery__
 *
 * To run a query within a React component, call `useGetPurchaseItemVariancePolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseItemVariancePolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseItemVariancePolicyQuery({
 *   variables: {
 *      PurchaseItemVariancePolicyID: // value for 'PurchaseItemVariancePolicyID'
 *   },
 * });
 */
export function useGetPurchaseItemVariancePolicyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPurchaseItemVariancePolicyQuery, GetPurchaseItemVariancePolicyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPurchaseItemVariancePolicyQuery, GetPurchaseItemVariancePolicyQueryVariables>(GetPurchaseItemVariancePolicyDocument, baseOptions);
      }
export function useGetPurchaseItemVariancePolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPurchaseItemVariancePolicyQuery, GetPurchaseItemVariancePolicyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPurchaseItemVariancePolicyQuery, GetPurchaseItemVariancePolicyQueryVariables>(GetPurchaseItemVariancePolicyDocument, baseOptions);
        }
export type GetPurchaseItemVariancePolicyQueryHookResult = ReturnType<typeof useGetPurchaseItemVariancePolicyQuery>;
export type GetPurchaseItemVariancePolicyLazyQueryHookResult = ReturnType<typeof useGetPurchaseItemVariancePolicyLazyQuery>;
export type GetPurchaseItemVariancePolicyQueryResult = ApolloReactCommon.QueryResult<GetPurchaseItemVariancePolicyQuery, GetPurchaseItemVariancePolicyQueryVariables>;
export const CreatePurchaseItemVariancePolicyDocument = gql`
    mutation createPurchaseItemVariancePolicy($PurchaseItemVariancePolicyInput: PurchaseItemVariancePolicyInput!) {
  createPurchaseItemVariancePolicy(PurchaseItemVariancePolicyInput: $PurchaseItemVariancePolicyInput)
}
    `;
export type CreatePurchaseItemVariancePolicyMutationFn = ApolloReactCommon.MutationFunction<CreatePurchaseItemVariancePolicyMutation, CreatePurchaseItemVariancePolicyMutationVariables>;

/**
 * __useCreatePurchaseItemVariancePolicyMutation__
 *
 * To run a mutation, you first call `useCreatePurchaseItemVariancePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePurchaseItemVariancePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPurchaseItemVariancePolicyMutation, { data, loading, error }] = useCreatePurchaseItemVariancePolicyMutation({
 *   variables: {
 *      PurchaseItemVariancePolicyInput: // value for 'PurchaseItemVariancePolicyInput'
 *   },
 * });
 */
export function useCreatePurchaseItemVariancePolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePurchaseItemVariancePolicyMutation, CreatePurchaseItemVariancePolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePurchaseItemVariancePolicyMutation, CreatePurchaseItemVariancePolicyMutationVariables>(CreatePurchaseItemVariancePolicyDocument, baseOptions);
      }
export type CreatePurchaseItemVariancePolicyMutationHookResult = ReturnType<typeof useCreatePurchaseItemVariancePolicyMutation>;
export type CreatePurchaseItemVariancePolicyMutationResult = ApolloReactCommon.MutationResult<CreatePurchaseItemVariancePolicyMutation>;
export type CreatePurchaseItemVariancePolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePurchaseItemVariancePolicyMutation, CreatePurchaseItemVariancePolicyMutationVariables>;
export const UpdatePurchaseItemVariancePolicyDocument = gql`
    mutation updatePurchaseItemVariancePolicy($PurchaseItemVariancePolicyID: String!, $PurchaseItemVariancePolicyInput: PurchaseItemVariancePolicyInput!) {
  updatePurchaseItemVariancePolicy(PurchaseItemVariancePolicyID: $PurchaseItemVariancePolicyID, PurchaseItemVariancePolicyInput: $PurchaseItemVariancePolicyInput)
}
    `;
export type UpdatePurchaseItemVariancePolicyMutationFn = ApolloReactCommon.MutationFunction<UpdatePurchaseItemVariancePolicyMutation, UpdatePurchaseItemVariancePolicyMutationVariables>;

/**
 * __useUpdatePurchaseItemVariancePolicyMutation__
 *
 * To run a mutation, you first call `useUpdatePurchaseItemVariancePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchaseItemVariancePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePurchaseItemVariancePolicyMutation, { data, loading, error }] = useUpdatePurchaseItemVariancePolicyMutation({
 *   variables: {
 *      PurchaseItemVariancePolicyID: // value for 'PurchaseItemVariancePolicyID'
 *      PurchaseItemVariancePolicyInput: // value for 'PurchaseItemVariancePolicyInput'
 *   },
 * });
 */
export function useUpdatePurchaseItemVariancePolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePurchaseItemVariancePolicyMutation, UpdatePurchaseItemVariancePolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePurchaseItemVariancePolicyMutation, UpdatePurchaseItemVariancePolicyMutationVariables>(UpdatePurchaseItemVariancePolicyDocument, baseOptions);
      }
export type UpdatePurchaseItemVariancePolicyMutationHookResult = ReturnType<typeof useUpdatePurchaseItemVariancePolicyMutation>;
export type UpdatePurchaseItemVariancePolicyMutationResult = ApolloReactCommon.MutationResult<UpdatePurchaseItemVariancePolicyMutation>;
export type UpdatePurchaseItemVariancePolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePurchaseItemVariancePolicyMutation, UpdatePurchaseItemVariancePolicyMutationVariables>;
export const GetRoleDocument = gql`
    query getRole($ID: String) {
  getRole(ID: $ID) {
    ID
    name
  }
}
    `;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a React component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetRoleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, baseOptions);
      }
export function useGetRoleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, baseOptions);
        }
export type GetRoleQueryHookResult = ReturnType<typeof useGetRoleQuery>;
export type GetRoleLazyQueryHookResult = ReturnType<typeof useGetRoleLazyQuery>;
export type GetRoleQueryResult = ApolloReactCommon.QueryResult<GetRoleQuery, GetRoleQueryVariables>;
export const GetGeneralPurchaseSummaryDocument = gql`
    query getGeneralPurchaseSummary($CompanyID: String) {
  scheduledPurchaseInfoCount(CompanyID: $CompanyID)
  prInfoCount(CompanyID: $CompanyID)
  rfqCount(CompanyID: $CompanyID)
  poInfoCount(CompanyID: $CompanyID)
  doInfoCount(CompanyID: $CompanyID)
  lastDODate(CompanyID: $CompanyID)
  grtnInfoCount(CompanyID: $CompanyID)
  lastGRTNDate(CompanyID: $CompanyID)
  procurementLoggedInUserProfile {
    ID
    accountID
    avatar
    name
    email
    contactNo
    department
    superUser
    userName
    jobTitle
    lastestAccessedEntity
  }
  getRolePermission
  getAccountPermission
}
    `;

/**
 * __useGetGeneralPurchaseSummaryQuery__
 *
 * To run a query within a React component, call `useGetGeneralPurchaseSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralPurchaseSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralPurchaseSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetGeneralPurchaseSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralPurchaseSummaryQuery, GetGeneralPurchaseSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralPurchaseSummaryQuery, GetGeneralPurchaseSummaryQueryVariables>(GetGeneralPurchaseSummaryDocument, baseOptions);
      }
export function useGetGeneralPurchaseSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralPurchaseSummaryQuery, GetGeneralPurchaseSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralPurchaseSummaryQuery, GetGeneralPurchaseSummaryQueryVariables>(GetGeneralPurchaseSummaryDocument, baseOptions);
        }
export type GetGeneralPurchaseSummaryQueryHookResult = ReturnType<typeof useGetGeneralPurchaseSummaryQuery>;
export type GetGeneralPurchaseSummaryLazyQueryHookResult = ReturnType<typeof useGetGeneralPurchaseSummaryLazyQuery>;
export type GetGeneralPurchaseSummaryQueryResult = ApolloReactCommon.QueryResult<GetGeneralPurchaseSummaryQuery, GetGeneralPurchaseSummaryQueryVariables>;