import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import ReportViewer, {
  accountxReportUrl,
} from 'containers/DigitalReportModule/ReportViewer'
import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router'

export const GLJournalByEntityReport = () => {
  const history = useHistory()
  const { CompanyID, BankAccountID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const accountID = user.accountID
  let location = useLocation()
  const editData = location?.state as any

  const parameters = [
    {
      name: 'accountid',
      value: accountID,
    },
    {
      name: 'companyid',
      value: CompanyID,
    },
    {
      name: 'fromDate',
      value: new Date(editData?.StartDate.setHours(0, 0, 0)).toISOString(),
    },
    {
      name: 'toDate',
      value: new Date(editData?.EndDate.setHours(0, 0, 0)).toISOString(),
    },
    {
      name: 'fyear',
      value: editData?.Year,
    },
    {
      name: 'fperiod',
      value: editData?.Period,
    },
    {
      name: 'accountcode',
      value: editData?.AccountCode,
    },
    {
      name: 'journaltype',
      value: editData?.JournalType,
    },
    {
      name: 'journalno',
      value: editData?.JournalNo,
    },
    {
      name: 'accountname',
      value: editData?.AccountName,
    },
    {
      name: 'remark',
      value: editData?.Description,
    },
    {
      name: 'costcentrecode',
      value: editData?.CostCentreCode,
    },
    {
      name: 'referenceno',
      value: editData?.ReferenceNo,
    },
  ]

  const generatedurl = (reportName, parameters) => {
    let reporturl = reportName

    parameters?.map((x, index) => {
      const symbol = index === 0 ? '?' : '&'
      if (x?.value !== undefined)
        reporturl = reporturl + symbol + x?.name + '=' + x?.value
    })
    return reporturl
  }

  // let reporturl = 'TestReport'

  return (
    <>
      <MainHeader
        onClick={() =>
          // history.push({
          //   pathname: `/general-ledger/${CompanyID}/digital-report/gl-journal-report/parameters`,
          //   state: editData,
          // })
          history.push(`/general-ledger/${CompanyID}/digital-report`)
        }
        mainBtn="close"
        smTitle="General Ledger"
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Digital Reporting' },
          { name: 'GL Journal By Account', current: true },
        ]}
        rightRouteSegments={[{ name: 'Report', current: true }]}
      />
      <ContentWrapper style={{ maxHeight: '100%' }}>
        {ReportViewer({
          url: generatedurl('GLJournalByEntityReport', parameters),
          host: accountxReportUrl,
          // host: 'http://localhost:61904',
          // host: contractReportUrl,
          excludedExportFormat: [],
        })}
      </ContentWrapper>
    </>
  )
}
