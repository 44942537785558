import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'
import { Grid } from '@material-ui/core'
import { useDocumentListingQuery, useGetCompanyQuery } from 'generated/graphql'
import React from 'react'
import { useParams } from 'react-router'

export const HeaderGL = (props: any) => {
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { CompanyID }: any = useParams()

  const {
    loading: companyLoading,
    error: companyError,
    data: { getCompany: getCompany } = { getCompany: [] },
  } = useGetCompanyQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID,
    },
  })

  const {
    loading: documentLoading,
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: CompanyID,
    },
  })

  ////////TO DETECT CHANGE IN SCREEN SIZE///////
  const [width] = useMediaQuery()

  let fontSize
  if (width < 769) {
    fontSize = 7
  } else {
    fontSize = 10
  }

  let containerStyle, fontStyle
  fontStyle = {
    padding: '10px',
    fontSize: fontSize,
    textAlign: 'center',
  }

  return (
    <>
      {documentLoading && <Loading />}
      <div style={containerStyle}>
        <div style={fontStyle}>
          <Grid container>
            <Grid xs={3} style={{ textAlign: 'left' }}>
              <div>
                {DocumentListing?.length > 0
                  ? DocumentListing?.map(x => {
                      if (x?.description === 'Company-Logo') {
                        return (
                          <>
                            <img
                              src={x?.fileURL}
                              style={{
                                maxHeight: width < 769 ? '80px' : '100px',
                                maxWidth: width < 769 ? '80px' : '100px',
                              }}
                              crossOrigin="anonymous"
                            />
                          </>
                        )
                      }
                    })
                  : null}
              </div>
            </Grid>

            <Grid xs={6}>
              <b>{getCompany[0]?.Name}</b>
              <br />
              {` (Co. No. : ${getCompany[0]?.CompanyRegNo}`}
              {!!getCompany[0]?.GSTNo ? (
                <>, GST No. : {` ${getCompany[0]?.GSTNo}`}</>
              ) : !!getCompany[0]?.GSTNo ? (
                <>, SST No. : {` ${getCompany[0]?.SSTNo}`}</>
              ) : null}
              {`)`}
              <br />
              <br />
              {getCompany[0]?.Address?.address}
              <br />
              {getCompany[0]?.Address?.country == 'Singapore'
                ? `${getCompany[0]?.Address?.country} ${getCompany[0]?.Address?.postCode}`
                : `${getCompany[0]?.Address?.postCode} ${getCompany[0]?.Address?.city}, ${getCompany[0]?.Address?.state}, ${getCompany[0]?.Address?.country}`}
              <br />
              Tel No. {getCompany[0]?.ContactNo}
              {/* Tel No. {user?.contactNo} */}
              {/* {getCompany[0]?.CompanyTax === 'GST_NO' ? (
            <>
              <br />
              GST Registration No. : {getCompany[0]?.GSTNo}
            </>
          ) : (
            <>
              <br />
              SST Registration No. : {getCompany[0]?.SSTNo}
            </>
          )} */}
            </Grid>

            <Grid xs={3}></Grid>
          </Grid>
        </div>
      </div>
    </>
  )
}

export default HeaderGL
