import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { Checkbox, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { AccountFooter } from 'components/Footer/AccountFooter'
import {
  useGetCreditorAccountCompanyAssignmentQuery,
  useGetCreditorTypeQuery,
} from 'generated/graphql'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import * as yup from 'yup'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import React, { useState } from 'react'

interface APCreditorLedgerDetailbyTrxDateParamsProps {
  StartDate: Date
  EndDate: Date
  CreditorAccountID: string
  CreditorTypeID: string
  TrxType: string
  DocNo: string
  RefNo: string
  Description: string
  DocAmt: string
}

export const APCreditorLedgerDetailbyTrxDateParamsForm = (props: any) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()
  let location = useLocation()
  const editData = location?.state as any
  const ParamsSchema = yup.object().shape({})
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />
  let user = JSON.parse(localStorage.getItem('loggedInUser'))

  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    watch,
    setValue,
  } = useForm<APCreditorLedgerDetailbyTrxDateParamsProps>({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(ParamsSchema),
  })

  const {
    loading: CreditorTypeLoading,
    error: CreditorTypeErrors,
    data: { getCreditorType } = { getCreditorType: [] },
  } = useGetCreditorTypeQuery({
    fetchPolicy: 'network-only',
  })

  const {
    loading: CreditorAccountLoading,
    error: CreditorAccountError,
    data: { getCreditorAccountCompanyAssignment } = {
      getCreditorAccountCompanyAssignment: [],
    },
  } = useGetCreditorAccountCompanyAssignmentQuery({
    fetchPolicy: 'network-only',

    variables: {
      CompanyID: CompanyID,
    },
  })

  let getCreditorAccount = getCreditorAccountCompanyAssignment?.map(x => {
    return x?.CreditorAccount
  })

  const [selectedCreditAccount, setSelectedCreditAccount]: any = useState(
    new Set()
  )

  const handleCheckboxCreditorAccountChange = (event: any) => {
    let creditorAccount = selectedCreditAccount

    if (event?.target?.checked) {
      creditorAccount.add(event?.target?.value)
    } else {
      creditorAccount.delete(event?.target?.value)
    }
    setSelectedCreditAccount(new Set(creditorAccount))
  }

  const [selectedType, setSelectedType] = useState(new Set())

  const handleCheckboxCreditorTypeChange = (event: any) => {
    let creditorType = selectedType
    if (event?.target?.checked) {
      creditorType.add(event?.target?.value)
    } else {
      creditorType.delete(event?.target?.value)
    }
    setSelectedType(new Set(creditorType))
  }

  const [selectedTransactionType, setSelectedTransactionType]: any = useState(
    new Set()
  )

  const handleCheckboxTrxTypeChange = (event: any) => {
    let trxType = selectedTransactionType

    if (event?.target?.checked) {
      trxType.add(event?.target?.value)
    } else {
      trxType.delete(event?.target?.value)
    }
    setSelectedTransactionType(new Set(trxType))
  }

  const trxType = [
    {
      TrxTypeValue: 'AV',
      title: 'Advance',
    },
    {
      TrxTypeValue: 'I',
      title: 'Invoice',
    },
    {
      TrxTypeValue: 'PV',
      title: 'Payment',
    },
    {
      TrxTypeValue: 'CN',
      title: 'Credit Note',
    },
    {
      TrxTypeValue: 'CN',
      title: 'Creditor Credit Note',
    },
    {
      TrxTypeValue: 'DN',
      title: 'Debit Note',
    },
    {
      TrxTypeValue: 'R',
      title: 'Refund',
    },
  ]

  const onSubmit = data => {
    history.push({
      pathname: `/account-payable/${CompanyID}/digital-report/ap-creditor-ledger-detail-trx-date/generated`,
      state: {
        StartDate: new Date(data.StartDate),
        EndDate: new Date(data.EndDate),
        CreditorTypeID: selectedType ? Array.from(selectedType) : undefined,
        CreditorAccountID: selectedCreditAccount
          ? Array.from(selectedCreditAccount)
          : undefined,
        TrxType: selectedTransactionType
          ? Array.from(selectedTransactionType)
          : undefined,
        // TrxType: data.TrxType,
        RefNo: data.RefNo,
        DocNo: data?.DocNo,
        Description: data.Description,
        DocAmt: data.DocAmt,
      },
    })
  }

  return (
    <>
      {CreditorAccountLoading && <Loading />}
      {CreditorTypeLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(`/account-payable/${CompanyID}/digital-report`)
        }
        smTitle={'Account Payable'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'Creditor Ledger Detail by Transaction Date', current: true },
        ]}
        rightRouteSegments={[{ name: 'Parameters', current: true }]}
      />
      <ContentWrapper float>
        <CardContents>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="StartDate"
              label="Start Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch('StartDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.StartDate ?? new Date()}
              helperText={errors?.StartDate?.message}
              error={errors?.StartDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="EndDate"
              label="End Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch('EndDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.EndDate ?? new Date()}
              helperText={errors?.EndDate?.message}
              error={errors?.EndDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  options={getCreditorAccount || []}
                  getOptionLabel={option => option?.CompanyName}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selecteted = new Set()

                    newValue?.map(x => {
                      selecteted.add(x?.CreditorAccountID)
                    })

                    setSelectedCreditAccount(new Set(selecteted))
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.CreditorAccountID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxCreditorAccountChange(event)
                        }}
                      />
                      {option?.CompanyName}
                    </React.Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ width: '100%' }}
                          label="Creditor Account"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="CreditorAccountID"
            label="Creditor Account"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  options={getCreditorType || []}
                  getOptionLabel={option =>
                    `${option?.CreditorType}=>${option?.Description}`
                  }
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selecteted = new Set()

                    newValue?.map(x => {
                      selecteted.add(x?.CreditorTypeID)
                    })

                    setSelectedType(new Set(selecteted))
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.CreditorTypeID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxCreditorTypeChange(event)
                        }}
                      />
                      {`${option?.CreditorType}=>${option?.Description}`}
                    </React.Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ width: '100%' }}
                          label="Creditor Type"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="CreditorTypeID"
            label="Creditor Type"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  options={trxType || []}
                  getOptionLabel={option => `${option?.title}`}
                  fullWidth
                  // onChange={(value, newValue: any) => {
                  //   setValue('TrxType', newValue?.TrxTypeValue)
                  // }}
                  onChange={(value, newValue: any) => {
                    const selecteted = new Set()

                    newValue?.map(x => {
                      selecteted.add(x?.TrxTypeValue)
                    })

                    setSelectedTransactionType(new Set(selecteted))
                  }}
                  // renderOption={(props, option) => {
                  //   return <span>{props?.title}</span>
                  // }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.TrxTypeValue}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxTrxTypeChange(event)
                        }}
                      />
                      {`${option?.title}`}
                    </React.Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          label="Transaction Type"
                          style={{ width: '100%' }}
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Transaction Type"
            name="TrxType"
            margin="normal"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            ref={register}
            select
            // helperText={errors?.TrxType?.message}
            // error={errors?.TrxType ? true : false}
          />

          <Controller
            as={TextField}
            name="DocNo"
            fullWidth
            className="left"
            label="Document No"
            control={control}
            ref={register}
          />

          <Controller
            as={TextField}
            name="RefNo"
            fullWidth
            className="right"
            label="Reference No"
            control={control}
            ref={register}
          />

          <Controller
            as={TextField}
            name="Description"
            fullWidth
            label="Description"
            control={control}
            ref={register}
          />

          <Controller
            as={TextField}
            name="DocAmt"
            fullWidth
            label="Doc Amt"
            control={control}
            ref={register}
          />
        </CardContents>

        <AccountFooter
          options={[
            {
              name: 'Submit',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
              //   props: { disabled: getReceipt?.length === 0 },
            },
          ]}
        />
      </ContentWrapper>

      {/* FOOTER */}
    </>
  )
}
