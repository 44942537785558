import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { TextField, Checkbox } from '@material-ui/core'
import AppContext from 'containers/App/Store/AppContext'
import {
  useGetAccountPeriodQuery,
  useGetEntityCoaQuery,
  useGetMasterCoaCodeQuery,
  useGetMasterCoaQuery,
} from 'generated/graphql'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { AccountFooter } from 'components/Footer/AccountFooter'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Autocomplete } from '@material-ui/lab'
import { AccCodeDropdownFullWidth } from 'components/Dropdown/AccCodeDropdown'

interface TrailBalanceByEntityParamsFormProps {
  Year: Number
  Period: Number
  GLAccount: string[]
  AccountType: string[]
}

export interface MasterCOA {
  Name: string
  MasterCOAID: string
  ParentMasterCOAID: string
  IsControl: boolean
  IsLastNode: boolean
  AccountType: string
  Code: string
  Level: number
}

export interface AccountType {
  name: string
  value: string
}

export const TrailBalanceByEntityParamsForm = (props: any) => {
  const { globalState, dispatch }: any = useContext(AppContext as any)
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  let location = useLocation()
  const editData = location?.state as any
  const ParamsSchema = yup.object().shape({
    Year: CommonYupValidation.requireField('Year is required'),
    Period: CommonYupValidation.requireField('Period is required'),
  })
  const [masterCOAData, setMasterCOAData] = useState<MasterCOA[]>([])
  const [accountTypeData, setAccountTypeData] = useState<AccountType[]>([])

  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    watch,
    setValue,
  } = useForm<TrailBalanceByEntityParamsFormProps>({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(ParamsSchema),
  })

  // ACCOUNTX API CALLS
  const {
    loading: accountPeriodLoading,
    error: accountPeriodError,
    data: { getAccountPeriod } = { getAccountPeriod: [] },
  } = useGetAccountPeriodQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, orderByAsc: 'FYear' },
  })

  //MasterCOA
  const {
    loading: MasterCOALoading,
    error: MasterCOAError,
    data: { getMasterCOA } = { getMasterCOA: [] },
  } = useGetMasterCoaCodeQuery({
    fetchPolicy: 'network-only',
    variables: { IsLastNode: true },
  })

  //EntityCOA
  const {
    loading: EntityCOALoading,
    error: EntityCOAErros,
    data: { getEntityCOA } = { getEntityCOA: [] },
  } = useGetEntityCoaQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
    onCompleted: () => {
      const masterCOAList =
        getEntityCOA?.length > 0
          ? getEntityCOA
              ?.map(coa => coa?.MasterCOA)
              ?.filter(
                coa => coa?.IsLastNode === true && coa?.IsControl === false
              )
          : getMasterCOA?.filter(
              coa => coa?.IsLastNode === true && coa?.IsControl === false
            )
      setMasterCOAData((masterCOAList as any) as MasterCOA[])

      const accountTypeList = [
        { name: 'Asset', value: 'ASSET' },
        { name: 'Liability', value: 'LIABILITY' },
        { name: 'Equity', value: 'EQUITY' },
        { name: 'Revenue', value: 'REVENUE' },
        { name: 'Expenses', value: 'EXPENSES' },
      ]
      setAccountTypeData((accountTypeList as any) as AccountType[])
    },
  })

  const getObjectValues = (values: string[], dataType: string) => {
    switch (dataType) {
      case 'GLAccount':
        return masterCOAData.filter(x => {
          return values?.some(y => y === x.Code)
        })
        break
      case 'AccountType':
        return accountTypeData.filter(x => {
          return values?.some(y => y === x.value)
        })
        break
      default:
        return []
    }
  }

  const onSubmit = (data, status) => {
    history.push({
      pathname: `/general-ledger/${CompanyID}/digital-report/trial-balance-entity-report/generated`,
      state: {
        Year: Number(data.Year),
        Period: Number(data.Period),
        AccountType:
          !!watch('AccountType') && watch('AccountType')?.length
            ? watch('AccountType')?.join(',')
            : undefined,
        GLAccount:
          !!watch('GLAccount') && watch('GLAccount')?.length
            ? watch('GLAccount')?.join(',')
            : undefined,
      },
    })
  }

  return (
    <>
      {MasterCOALoading && <Loading />}
      {EntityCOALoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(`/general-ledger/${CompanyID}/digital-report`)
        }
        smTitle={'General Ledger'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'Trial Balance By Entity', current: true },
        ]}
        rightRouteSegments={[{ name: 'Parameters', current: true }]}
      />
      <ContentWrapper float>
        <CardContents>
          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  options={
                    getAccountPeriod
                      ?.map(el => el?.FYear)
                      .filter((element, index) => {
                        return (
                          getAccountPeriod
                            ?.map(el => el?.FYear)
                            .indexOf(element) === index
                        )
                      })
                      .sort((a, b) => b - a) || []
                  }
                  getOptionLabel={option => `${option}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    setValue('Year', newValue)
                  }}
                  renderOption={(props, option) => {
                    return <span>{props}</span>
                  }}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          //helperText={errors?.Year?.message}
                          error={errors?.Year ? true : false}
                          label="Year"
                          style={{ width: '100%' }}
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Year"
            name="Year"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            // helperText={errors?.Year?.message}
            error={errors?.Year ? true : false}
          />
          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  options={
                    getAccountPeriod
                      ?.filter(x => x?.FYear === Number(watch('Year')))
                      .sort((a, b) => b.FPeriod - a.FPeriod) || []
                  }
                  getOptionLabel={option => `${option?.FPeriod}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    setValue('Period', newValue?.FPeriod)
                  }}
                  renderOption={(props, option) => {
                    return <span>{props?.FPeriod}</span>
                  }}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          //helperText={errors?.Period?.message}
                          error={errors?.Period ? true : false}
                          label="Period"
                          style={{ width: '100%' }}
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Period"
            name="Period"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            // helperText={errors?.Period?.message}
            error={errors?.Period ? true : false}
          />

          {!EntityCOALoading && !MasterCOALoading && (
            <Controller
              name="GLAccount"
              label="GL Account"
              fullWidth
              margin="dense"
              ref={register}
              control={control}
              render={({ onChange, onBlur, value }) => {
                return (
                  <Autocomplete
                    multiple
                    value={
                      getObjectValues(
                        watch('GLAccount'),
                        'GLAccount'
                      ) as MasterCOA[]
                    }
                    fullWidth
                    options={masterCOAData}
                    disableCloseOnSelect
                    getOptionLabel={option =>
                      `${option?.Name} | ${option?.Code}`
                    }
                    PopperComponent={AccCodeDropdownFullWidth}
                    onChange={(value, newValue: any) => {
                      onChange(newValue?.map(x => x?.Code))
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="GL Account"
                      />
                    )}
                    renderOption={(option, { selected }) => {
                      return (
                        <>
                          <Checkbox checked={selected} color="primary" />
                          <div>
                            <div>
                              <span className="xsTitle">{option.Name}</span>
                            </div>
                            <div className="desc">{option.Code}</div>
                          </div>
                        </>
                      )
                    }}
                  />
                )
              }}
            />
          )}

          <Controller
            name="AccountType"
            label="Account Type"
            fullWidth
            margin="dense"
            ref={register}
            control={control}
            render={({ onChange, onBlur, value }) => {
              return (
                <Autocomplete
                  multiple
                  value={
                    getObjectValues(
                      watch('AccountType'),
                      'AccountType'
                    ) as AccountType[]
                  }
                  fullWidth
                  options={accountTypeData}
                  disableCloseOnSelect
                  getOptionLabel={option => `${option?.name}`}
                  PopperComponent={AccCodeDropdownFullWidth}
                  onChange={(value, newValue: any) => {
                    onChange(newValue?.map(x => x?.value))
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Account Type"
                    />
                  )}
                  renderOption={(option, { selected }) => {
                    return (
                      <>
                        <Checkbox checked={selected} color="primary" />
                        <div>
                          <div>
                            <span className="xsTitle">{option.name}</span>
                          </div>
                        </div>
                      </>
                    )
                  }}
                />
              )
            }}
          />
        </CardContents>

        <AccountFooter
          options={[
            {
              name: 'Submit',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
              //   props: { disabled: getReceipt?.length === 0 },
            },
          ]}
        />
      </ContentWrapper>

      {/* FOOTER */}
    </>
  )
}
