import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { AccountFooter } from 'components/Footer/AccountFooter'
import {
  useGetBankAccountLazyQuery,
  useGetCompanyQuery,
} from 'generated/graphql'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router'
import * as yup from 'yup'

interface BankReconciliationStatementParamsFormProps {
  CompanyID: string
  DocDate: Date
  BankAccountID: string
}

export const BankReconciliationStatementParamsForm = (props: any) => {
  let history = useHistory()
  let location = useLocation()
  const editData = location?.state as any
  const [comp, setComp] = useState(undefined)
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [bankAcc, setBankAcc] = useState(undefined)
  const ParamsSchema = yup.object().shape({
    CompanyID: CommonYupValidation.requireField('Company is required'),
    DocDate: CommonYupValidation.requireField('Document Date is required'),
  })

  const latestCompany = localStorage.getItem('latestCompany')

  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    watch,
    setValue,
  } = useForm<BankReconciliationStatementParamsFormProps>({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(ParamsSchema),
  })

  // ACCOUNTX API CALLS

  const {
    loading: companyLoading,
    error: companyError,
    data: { getCompany: getCompany } = { getCompany: [] },
  } = useGetCompanyQuery({
    fetchPolicy: 'network-only',
    variables: {
      orderByAsc: 'Name',
    },
  })

  const [
    loadBankAccount,
    {
      called: bankAccountCalled,
      loading: bankAccountLoading,
      data: { getBankAccount } = { getBankAccount: [] },
    },
  ] = useGetBankAccountLazyQuery({
    fetchPolicy: 'network-only',
  })

  const onSubmit = (data, status) => {
    history.push({
      pathname: `/cash-book/submenu/digital-report/bank-reconciliation-statement-report/generated`,
      state: {
        CompanyID: data?.CompanyID,
        DocDate: new Date(data.DocDate),
        BankAccountID: data?.BankAccountID,
      },
    })
  }

  return (
    <>
      {companyLoading && <Loading />}
      {bankAccountLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/cash-book/submenu/digital-report`)}
        smTitle={smTitle?.CASH_BOOK}
        title={user?.companyName}
        routeSegments={[
          { name: 'Submenu' },
          { name: 'Bank Reconciliation Statement', current: true },
        ]}
        rightRouteSegments={[{ name: 'Parameters', current: true }]}
      />
      <ContentWrapper float>
        <CardContents>
          {/* {!companyLoading && ( */}
          <Controller
            render={({ value, onChange }) => {
              const defVal = getCompany?.filter(
                x => x?.CompanyID === latestCompany
              )[0]
              return (
                <Autocomplete
                  options={getCompany || []}
                  getOptionLabel={option => `${option?.Name}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    setValue('CompanyID', newValue?.CompanyID)
                    setComp(newValue?.CompanyID)
                    loadBankAccount({
                      variables: { CompanyID: newValue?.CompanyID },
                    })
                    setValue('BankAccountID', undefined)
                    setBankAcc(undefined)
                  }}
                  renderOption={(props, option) => {
                    return <span>{props?.Name}</span>
                  }}
                  // defaultValue={defVal}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          helperText={errors?.CompanyID?.message}
                          error={errors?.CompanyID ? true : false}
                          label="Company"
                          style={{ width: '100%' }}
                          margin="normal"
                          required
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Company"
            name="CompanyID"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            helperText={errors?.CompanyID?.message}
            error={errors?.CompanyID ? true : false}
            required
            // defaultValue={latestCompany}
          />
          {/* )} */}

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="DocDate"
              label="Document Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch('DocDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.DocDate ?? new Date()}
              helperText={errors?.DocDate?.message}
              error={errors?.DocDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  options={getBankAccount || []}
                  getOptionLabel={option => `${option?.Code}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    setValue('BankAccountID', newValue?.BankAccountID)
                    setBankAcc(newValue)
                  }}
                  renderOption={(props, option) => {
                    return <span>{props?.Code}</span>
                  }}
                  value={bankAcc}
                  key={bankAcc}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          helperText={errors?.BankAccountID?.message}
                          error={errors?.BankAccountID ? true : false}
                          label="Bank"
                          style={{ width: '100%' }}
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Bank"
            name="BankAccountID"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            helperText={errors?.BankAccountID?.message}
            error={errors?.BankAccountID ? true : false}
          />
        </CardContents>

        <AccountFooter
          options={[
            {
              name: 'Submit',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
            },
          ]}
        />
      </ContentWrapper>
    </>
  )
}
