import React from 'react';
import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize';

export const customDateFormatter = date => {
  const toSplit = date?.split('-');

  const toReverse = toSplit?.reverse();

  const toJoin = toReverse?.join('/');

  return toJoin;
};

export const TablePR = (props: any) => {
  const { data, name, docType, page, pageLength } = props;
  // const [width] = useMediaQuery()
  const [width] = useMediaQuery();

  let fontSize;
  if (width < 769) {
    fontSize = 7;
  } else {
    fontSize = 10;
  }

  return (
    <>
      {/* ================Purchase Requisition================ */}
      {docType === 'purchase-requisition' && (
        <div style={{ fontSize }}>
          <div style={{ display: 'flex' }}>
            {/* Left Column */}
            <span style={{ width: '60%', padding: '8px' }}>
              <table style={{ marginTop: '5px', borderCollapse: 'collapse' }}>
                <tr>
                  <td>Requested By</td>
                  <td>:</td>
                  <td>{data?.RequestedBy}</td>
                </tr>
                <tr>
                  <td>Contact Person </td>
                  <td>:</td>
                  <td>{data?.ContactNo}</td>
                </tr>
              </table>
            </span>

            {/* Right Column */}
            <span style={{ width: '40%', padding: '8px' }}>
              <table style={{ borderCollapse: 'collapse' }}>
                <>
                  <tr>
                    <td style={{ paddingRight: '20px' }}>PR No. </td>
                    <td>:</td>
                    <td>
                      <b>{data?.DocNo}</b>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingRight: '20px' }}>PR Date</td>
                    <td>:</td>
                    <td>{customDateFormatter(data?.DocDate)}</td>
                  </tr>
                  <tr>
                    <td style={{ paddingRight: '20px' }}>Delivery Location</td>
                    <td>:</td>
                    <td>{name}</td>
                  </tr>
                  <tr>
                    <td style={{ paddingRight: '20px' }}>Delivery Date</td>
                    <td>:</td>
                    <td>{customDateFormatter(data?.ExpectedDate)}</td>
                  </tr>
                  <tr>
                    <td style={{ paddingRight: '20px' }}>Page </td>
                    <td>:</td>
                    <td>
                      {page + 1} / {pageLength}
                    </td>
                  </tr>
                </>
              </table>
            </span>
          </div>
        </div>
      )}

      {/* ================Purchase Order================ */}
      {docType !== 'purchase-requisition' && (
        <div style={{ fontSize }}>
          <div style={{ display: 'flex' }}>
            {/* Left Column */}
            <span style={{ width: '60%', padding: '8px' }}>
              <table style={{ marginTop: '5px', borderCollapse: 'collapse' }}>
                <tr>
                  <td>
                    <b>{data?.Supplier?.CompanyName}</b>
                  </td>
                </tr>
                <tr>
                  {data?.Supplier?.Address?.address}
                  <br />
                  {data?.Supplier?.Address?.postCode}{' '}
                  {data?.Supplier?.Address?.city}
                  <br />
                  {data?.Supplier?.Address?.state}
                  <br />
                  {data?.Supplier?.Address?.country}
                  <br />
                </tr>
              </table>

              <table style={{ marginTop: '5px', borderCollapse: 'collapse' }}>
                <tr>
                  <td>GST No.</td>
                  <td>:</td>
                  <td>{data?.Supplier?.GSTRegNo ?? ''}</td>
                </tr>
                <tr> </tr>
                <tr>
                  <td>Attn. </td>
                  <td>:</td>
                  <td>{data?.Supplier?.CreditorContact[0]?.Name ?? ''}</td>
                </tr>
                <tr>
                  <td>Tel No. </td>
                  <td>:</td>
                  <td>{data?.Supplier?.CreditorContact[0]?.ContactNo ?? ''}</td>
                </tr>
                <tr>
                  <td>Email </td>
                  <td>:</td>
                  <td>{data?.Supplier?.CreditorContact[0]?.Email ?? ''}</td>
                </tr>
              </table>
            </span>

            {/* Right Column */}
            <span style={{ width: '40%', padding: '8px' }}>
              <table style={{ borderCollapse: 'collapse' }}>
                <>
                  <tr>
                    <td style={{ paddingRight: '20px' }}>PO No. </td>
                    <td>:</td>
                    <td>
                      <b>{data?.DocNo}</b>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingRight: '20px' }}>PO Date</td>
                    <td>:</td>
                    <td>{customDateFormatter(data?.DocDate)}</td>
                  </tr>

                  {docType === 'generate-po-from-pr' && (
                    <tr>
                      <td style={{ paddingRight: '20px' }}>PR No. </td>
                      <td>:</td>
                      <td>{data?.POItem[0]?.PRItem?.PRHeader?.DocNo ?? ''}</td>
                    </tr>
                  )}
                  <tr>
                    <td style={{ paddingRight: '20px' }}>Delivery Location</td>
                    <td>:</td>
                    <td>{name}</td>
                  </tr>
                  <tr>
                    <td style={{ paddingRight: '20px' }}>Delivery Date</td>
                    <td>:</td>
                    <td>{customDateFormatter(data?.ExpectedDate)}</td>
                  </tr>
                  <tr>
                    <td style={{ paddingRight: '20px' }}>Credit Terms</td>
                    <td>:</td>
                    <td>{data?.Supplier?.CreditTerm}</td>
                  </tr>
                  <tr>
                    <td style={{ paddingRight: '20px' }}>Page </td>
                    <td>:</td>
                    <td>
                      {page + 1} / {pageLength}
                    </td>
                  </tr>
                </>
              </table>
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default TablePR;
