import { yupResolver } from '@hookform/resolvers'
import theme from '@ifca-root/react-component/src/assets/theme'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import {
  Avatar,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import SubmitterLog from 'assets/icons/GeneralLedger/submitter-log-orange.svg'
import { AccountFooter } from 'components/Footer/AccountFooter'
import AppContext from 'containers/App/Store/AppContext'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  ApprovalStatus,
  useCreateStockReconMutation,
  useGetCompanySummaryQuery,
  useGetCycleCountItemLazyQuery,
  useGetCycleCountQuery,
  useGetStockAdjustmentReasonQuery,
  useGetUsersByAccountAndSoftwareQuery,
  usePostCycleCountMutation,
} from 'generated/graphql'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate, formatTime } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr4Dec } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import * as yup from 'yup'
import '../../style/CycleCount.scss'
import { RejectDialog } from 'components/Dialog/RejectDialog'

interface AdjustmentReasonProps {
  ReasonID: string
  OnHandQty: number
  CountQty: number
  AdjustQty: number
  StockQty: number
  StockItemName: string
  CycleCountItemID: string
}

export const StockRecon = (props: any) => {
  const StockReconSchema = yup.object().shape({
    cycleCountItem: yup.array().of(
      yup.object().shape({
        ReasonID: yup.string().when('IsNeedRecon', {
          is: true,
          then: CommonYupValidation.requireField(
            'Reason is required'
          ).nullable(),
        }),
      })
    ),
  })

  const {
    handleSubmit,
    register,
    unregister,
    errors,
    control,
    getValues,
    watch,
    setValue,
    clearErrors,
    formState: { isSubmitted },
  } = useForm({
    defaultValues: {
      // DocDate: stockReceiptDetail ? stockReceiptDetail ?.DocDate : '',
      // RefNo: stockReceiptDetail ? stockReceiptDetail ?.RefNo : '',
      // WarehouseID: stockReceiptDetail ? stockReceiptDetail ?.WarehouseID : '',
      // remark: stockReceiptDetail ? stockReceiptDetail ?.remark : '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(StockReconSchema),
  })

  const { mode, editsData }: any = props
  let location = useLocation()
  const editData = location?.state as any

  let history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const getSearch = localStorage?.getItem('searchFilter')

  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: 'StockItemFromWarehouseArr',
  })

  // const reducer: Reducer<Props, IAction> = (state, action) => {
  //   switch (action.type) {
  //     case 'reset':
  //       return initialState
  //     default:
  //       return { ...state, [action.type]: action.payload }
  //   }
  // }

  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()
  const [entity, setEntity] = useState<Set<any>>(new Set())
  const { setOpenSnackBar, setSnackBarMsg } = useContext(SnackBarContext) as any
  const { CompanyID, CycleCountID, WarehouseID }: any = useParams()
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  // const [state, dispatch] = useReducer(reducer, initialState)
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  const { globalState, dispatch }: any = useContext(AppContext as any)

  // const enumListing = [
  //   { Name: 'Demo', Code: SampleEnum.Demo },
  //   { Name: 'Testing', Code: SampleEnum.Testing },
  // ]

  /* -------------------------------------------- */
  /*                  Schema                      */
  /* -------------------------------------------- */

  useEffect(() => {
    fetchCycleCountItem({
      variables: {
        CycleCountID: CycleCountID,
        WarehouseID: WarehouseID,
        CompanyID: CompanyID,
        IsReconciled: false,
        DocDate: editData?.DocDate,
      },
    })
  }, [])

  /* -------------------------------------------- */
  /*                   Query                      */
  /* -------------------------------------------- */

  const {
    loading: stockAdjustmentReasonLoading,
    error: stockAdjustmentReasonError,
    data: { getStockAdjustmentReason } = {
      getStockAdjustmentReason: [],
    },
  } = useGetStockAdjustmentReasonQuery({
    fetchPolicy: 'network-only',
    variables: {
      orderByAsc: 'Reason',
    },
  })

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
  })

  const {
    loading: getCycleCountLoading,
    error: getCycleCountError,
    data: { getCycleCount } = { getCycleCount: [] },
  } = useGetCycleCountQuery({
    fetchPolicy: 'network-only',
    variables: {
      CycleCountID: CycleCountID,
      CompanyID: CompanyID,
    },
  })

  const {
    loading: getCompanyLoading,
    error: getCompanyError,
    data: { getCompany: curCompany } = { getCompany: [] },
  } = useGetCompanySummaryQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
  })

  const [
    fetchCycleCountItem,
    {
      loading: getCycleCountItemLoading,
      error: getCycleCountItemError,
      data: { getCycleCountItem } = { getCycleCountItem: [] },
    },
  ] = useGetCycleCountItemLazyQuery({
    fetchPolicy: 'network-only',

    onCompleted: ({ getCycleCountItem }) => {
      if (getCycleCountItem?.length > 0) {
        const isRecon = getCycleCountItem?.filter(
          item => item?.StockItem?.CurrentQty !== item?.CountQty
        )
        const noRecon = getCycleCountItem?.filter(
          item => item?.StockItem?.CurrentQty === item?.CountQty
        )
        const sorted = [...isRecon, ...noRecon]
        sorted?.map((item, index) => {
          const cycleCountItem = `cycleCountItem[${index}]`
          let data = (({ Reason, ReasonID, OnHandQty, AdjustQty, ...other }) =>
            other)(item)
          register(`${cycleCountItem}`)
          setValue(`${cycleCountItem}`, {
            ...data,
            IsNeedRecon:
              data?.StockItem?.CurrentQty !== data?.CountQty ? true : false,
            OnHandQty: data?.StockItem?.CurrentQty,
            CountQty: data?.CountQty,
            AdjustQty: data?.CountQty - data?.StockItem?.CurrentQty,
          })
        })
      }
    },
  })

  const [
    createStockRecon,
    {
      loading: createStockReconLoading,
      called: createStockReconCalled,
      error: createStockReconError,
    },
  ] = useCreateStockReconMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setTimeout(() => {
        history.push({
          pathname: `/inventory-control/${WarehouseID}/cycle-count`,
          state: { success: true, msgMode: 'update' },
        })
      }, 500)
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      localStorage.removeItem('CycleCountItem')
    },
  })

  // // const handleCheckBoxChange = (e: any, index: number) => {
  // //   let cycle = getCycleCount[0]?.CycleCountItem?.map((el, index) => {}
  // // )}

  let listCheckedEntity = Array.from(entity || [])
  const checkedData: {
    [key: string]: any
  } = {}

  listCheckedEntity?.map(v => {
    checkedData[v?.CycleCountItemID] = v
  })

  const handleCheckBoxChange = (e: any, index: number) => {
    let entityTemp = entity || new Set()
    if (!e.target.checked) {
      getCycleCountItem?.find(v => {
        if (v?.CycleCountItemID === e.target.value) {
          entityTemp.delete(v)
        }
      })
    } else {
      getCycleCountItem?.find(v => {
        if (v?.CycleCountItemID === e.target.value) {
          entityTemp.add(v)
        }
      })
    }
    setEntity(new Set(entityTemp))
  }

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */
  const [AdjustmentReasonItemData, setAdjustmentReasonItemData] = useState([])
  const [checkReconError, setCheckReconError] = useState([])
  const [openRejectDialog, setOpenRejectDialog] = useState(false)
  const [clickReject, setIsReject] = useState(false)
  const [rejectDialogInput, setRejectDialogInput] = useState({
    RejectionRemark: '',
  })
  useEffect(() => {
    if (mode === 'edit' && editData) {
      const AdjustmentReasonItem = editData.AdjustmentReasonItem?.map(
        (ex, index) => {
          return {
            ReasonID: ex?.ReasonID,
            CycleCountID: ex?.CycleCountID,
            CycleCountItemID: ex?.CycleCountItemID,
            Name: ex?.StockItem?.Name,
          }
        }
      )
      AdjustmentReasonItemData.push(...AdjustmentReasonItem)
    }
  }, [mode, editData, AdjustmentReasonItemData])

  const onSubmit = data => {
    createStockRecon({
      variables: {
        input: {
          CycleCountID: getCycleCount[0]?.CycleCountID,
          CompanyID: CompanyID,
          WarehouseID: getCycleCount[0]?.WarehouseID,
          DocDate: getCycleCount[0]?.DocDate,
          TransactionDate: getCycleCount[0]?.TransactionDate,
          ApprovalStatus: ApprovalStatus.Completed,
        },
        itemInput: data?.cycleCountItem?.map(x => {
          return {
            CycleCountItemID: x?.CycleCountItemID,
            StockItemID: x?.StockItemID,
            CompanyID: CompanyID,
            UOMID: x?.UOMID,
            ReasonID: x?.ReasonID,
            Quantity: Number(Number(x?.AdjustQty).toFixed(4)),
            WarehouseID: x?.WarehouseID,
            OnHandQty: x?.OnHandQty,
            CountQty: x?.CountQty,
            AdjustQty: Number(Number(x?.AdjustQty).toFixed(4)),
          }
        }),
      },
    })
  }

  const [
    postCycleCount,
    {
      loading: postCycleCountLoading,
      called: postCycleCountCalled,
      error: postCycleCountError,
    },
  ] = usePostCycleCountMutation({
    onError: error => {},
    onCompleted: data => {
      setTimeout(() => {
        history.push({
          pathname: `/inventory-control/${WarehouseID}/cycle-count`,
          state: { success: true, msgMode: 'update' },
        })
      }, 500)
      setOpenSnackBar(true)
      setSnackBarMsg('Record rejected successfully.')
      localStorage.removeItem('CycleCountItem')
    },
  })

  const onReject = () => {
    postCycleCount({
      variables: {
        postIDs: [getCycleCount[0]?.CycleCountID],
        ApprovalStatus: ApprovalStatus.Rejected,
        CompanyID: CompanyID,
        input: {
          ...rejectDialogInput,
        },
      },
    })
  }
  useEffect(() => {
    if (clickReject) {
      onReject()
    }
  }, [clickReject])

  return (
    <>
      {getCycleCountLoading && <Loading />}
      {getCycleCountItemLoading && <Loading />}
      {stockAdjustmentReasonLoading && <Loading />}
      {createStockReconLoading && <Loading />}
      {UserLoading && <Loading />}
      {getCompanyLoading && <Loading />}
      {postCycleCountLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(`/inventory-control/${WarehouseID}/cycle-count`)
        }
        smTitle="Inventory Control"
        title={user?.warehouseName}
        routeSegments={[
          { name: 'stock' },
          { name: 'Cycle Count', current: true },
        ]}
        rightRouteSegments={[{ name: 'Stock Recon', current: true }]}
      />

      {/* Orange title ..................................................... */}

      <DynamicSubHeader
        title={
          <>
            <span style={{ paddingTop: '-10px' }}>
              {getCycleCount[0]?.Warehouse?.Name}
            </span>
          </>
        }
        infoLine={
          <>
            <span>
              <b>{formatDate(getCycleCount[0]?.DocDate)}</b>
            </span>
          </>
        }
        rightInfoLine={
          <>
            <span>{getCycleCount[0]?.RefNo}</span>
          </>
        }
        //style={{paddingBottom: '100px'}}
      />

      {/* ..................................................... */}

      <ContentWrapper multiDynamicInfo float>
        <List className="core-list">
          {getCycleCountItem
            ?.filter(item => item?.StockItem?.CurrentQty !== item?.CountQty)
            ?.map((el, index) => {
              const cycleCountItem = `cycleCountItem[${index}]`

              return (
                <ListItem>
                  <ListItemText
                    primary={
                      <>
                        <Grid container className="table-content">
                          <Grid item xs={12}>
                            <div
                              className="xsTitle"
                              style={{
                                color: 'black',
                                fontSize: '12px',
                              }}
                            >
                              {el?.StockItem?.Name}
                            </div>
                          </Grid>

                          <Grid item xs={12}>
                            <span
                              className="desc"
                              style={{
                                color: 'black',
                              }}
                            >
                              {el?.StockItem?.StockCategory?.Name}
                            </span>
                            <span className="desc" style={{ float: 'right' }}>
                              {el?.StockItem?.UOM?.Name}
                            </span>
                          </Grid>
                        </Grid>
                      </>
                    }
                    secondary={
                      <>
                        <div>
                          <span
                            className="xxTitle"
                            style={{ fontSize: '10px', paddingRight: '3px' }}
                          >
                            On Hand
                            <span
                              style={{
                                margin: '4px',
                                color:
                                  el?.StockItem?.CurrentQty >= 0
                                    ? 'orange'
                                    : 'red',
                              }}
                            >
                              {amtStr4Dec(el?.StockItem?.CurrentQty)}
                            </span>
                          </span>

                          <span
                            className="xxTitle"
                            style={{ fontSize: '10px', paddingRight: '3px' }}
                          >
                            Count
                            <span
                              style={{
                                margin: '4px',
                                color: el?.CountQty >= 0 ? 'orange' : 'red',
                              }}
                            >
                              {amtStr4Dec(el?.CountQty)}
                            </span>
                          </span>

                          <span
                            className="xxTitle"
                            style={{ fontSize: '10px' }}
                          >
                            Difference
                            <span
                              style={{
                                margin: '4px',
                                color:
                                  el?.CountQty - el?.StockItem?.CurrentQty > 0
                                    ? 'green'
                                    : el?.CountQty - el?.StockItem?.CurrentQty <
                                      0
                                    ? 'red'
                                    : 'orange',
                              }}
                            >
                              {el?.CountQty - el?.StockItem?.CurrentQty > 0
                                ? '+'
                                : ''}
                              {amtStr4Dec(
                                el?.CountQty - el?.StockItem?.CurrentQty
                              )}
                            </span>
                          </span>
                        </div>

                        <Grid container className="table-content">
                          <Grid item xs={11}>
                            <Controller
                              render={({ onChange, value }) => (
                                <TextField
                                  label="Reason Here"
                                  name={`${cycleCountItem}.ReasonID`}
                                  required
                                  select
                                  fullWidth
                                  className="padding-field"
                                  style={{
                                    fontSize: '1px',
                                    // width: isDesktop ? '150%' : null,
                                    width:
                                      isDesktop && globalState.drawerOpen
                                        ? '105%'
                                        : isDesktop && !globalState.drawerOpen
                                        ? '105%'
                                        : '100%',
                                  }}
                                  margin="dense"
                                  variant="outlined"
                                  onChange={e => {
                                    setValue(`${cycleCountItem}`, {
                                      ...el,
                                      ReasonID: e.target.value,
                                      OnHandQty: el?.StockItem?.CurrentQty,
                                      CountQty: el?.CountQty,
                                      AdjustQty:
                                        el?.CountQty -
                                        el?.StockItem?.CurrentQty,
                                    })
                                  }}
                                  // defaultValue={el?.Reason?.Reason}
                                  InputLabelProps={{ shrink: true }}
                                  helperText={
                                    errors?.cycleCountItem?.[index]?.ReasonID
                                      ?.message
                                  }
                                  error={
                                    errors.cycleCountItem &&
                                    errors.cycleCountItem[index]?.ReasonID
                                  }
                                >
                                  {getStockAdjustmentReason?.map(
                                    (el, index) => (
                                      <MenuItem
                                        key={index}
                                        style={{ whiteSpace: 'normal' }}
                                        value={el?.StockAdjustmentReasonID}
                                      >
                                        <span className="text-noflow">
                                          {el?.Reason}
                                        </span>
                                      </MenuItem>
                                    )
                                  )}
                                </TextField>
                              )}
                              id="standard-basic"
                              onChange={e => {
                                setValue(`${cycleCountItem}`, {
                                  ...el,
                                  ReasonID: e.target.value,
                                })
                              }}
                              style={{
                                paddingTop: '0px',
                                paddingBottom: '0px',
                              }}
                              // className="mat-new"
                              name={`${cycleCountItem}.ReasonID`}
                              label="Reason"
                              margin="dense"
                              multiline={false}
                              autoComplete="off"
                              control={control}
                              fullWidth
                              helperText={errors?.ReasonID?.message}
                              error={errors?.ReasonID ? true : false}
                              defaultValue={el?.ReasonID}
                              ref={register()}
                            />
                          </Grid>

                          <Grid item xs={1}>
                            <Tooltip
                              disableFocusListener
                              title={
                                <React.Fragment>
                                  <Grid container wrap="nowrap" spacing={2}>
                                    <Grid item>
                                      <Avatar
                                        style={{
                                          width: '20px',
                                          height: '20px',
                                        }}
                                      >
                                        <PersonIcon
                                          style={{ fontSize: 'small' }}
                                        />
                                      </Avatar>
                                    </Grid>
                                    <Grid item xs zeroMinWidth>
                                      <div style={{ fontSize: '12px' }}>
                                        {getCycleCount?.map(p => {
                                          return (
                                            <>
                                              {getUsersByAccountAndSoftware?.find(
                                                x => x?.ID === p?.createdBy
                                              )?.name ??
                                                getUsersByAccountAndSoftware?.find(
                                                  x =>
                                                    x?.ID === p?.createdBy ??
                                                    p?.modBy
                                                )?.name}
                                            </>
                                          )
                                        })}
                                      </div>
                                      <div style={{ fontSize: '10px' }}>
                                        {getCycleCount?.map(p => {
                                          return (
                                            <>
                                              {formatDate(
                                                p?.modTs ?? p?.createdTs
                                              )}
                                              &nbsp;
                                              {formatTime(
                                                p?.modTs ?? p?.createdTs
                                              )}
                                            </>
                                          )
                                        })}
                                      </div>
                                    </Grid>
                                  </Grid>
                                </React.Fragment>
                              }
                              arrow
                              enterTouchDelay={0}
                            >
                              <Grid style={{ float: 'right' }}>
                                <IconText
                                  icon={
                                    <img
                                      src={SubmitterLog}
                                      style={{
                                        width: '12px',
                                        marginRight: '6px',
                                        color: 'orange',
                                        paddingTop: '15px',
                                      }}
                                    />
                                  }
                                  font="desc"
                                ></IconText>
                              </Grid>
                            </Tooltip>
                            {/* </span> */}
                          </Grid>
                        </Grid>
                      </>
                    }
                  />
                </ListItem>
              )
            })}
        </List>
        {
          <CardContents
            section={{
              header: {
                title: 'No Difference',
              },
            }}
          >
            <div className="rm-padding table-wrap ">
              {getCycleCountItem
                ?.filter(item => item?.StockItem?.CurrentQty === item?.CountQty)
                ?.map((el, index) => {
                  return (
                    <>
                      <Grid spacing={1} container className="table-content">
                        <Grid
                          item
                          xs={1}
                          style={{
                            placeSelf: 'start',
                            marginTop: '2px',
                          }}
                        >
                          <span className="xsTitle">{`${index + 1}.`}</span>
                        </Grid>

                        <Grid
                          item
                          xs={11}
                          style={{
                            placeSelf: 'start',
                            marginTop: '8px',
                          }}
                        >
                          <div
                            className="xsTitle text-noflow"
                            style={{
                              lineBreak: 'anywhere',
                            }}
                          >
                            {el?.StockItem?.Name}
                          </div>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={6} style={{ marginTop: '-17px' }}>
                          <div>
                            <span className="desc">
                              {el?.StockItem?.StockCategory?.Name}
                            </span>
                          </div>
                          <div style={{ marginTop: '-8px' }}>
                            <span
                              className="xxTitle"
                              style={{ paddingRight: '5px' }}
                            >
                              On Hand:
                            </span>
                            <span className="xxTitle c-orange">
                              {amtStr4Dec(el?.StockItem?.CurrentQty)}
                            </span>
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={5}
                          style={{
                            textAlign: 'right',
                            marginTop: '-17px',
                          }}
                        >
                          <div>
                            <span className="desc">
                              {el?.StockItem?.UOM?.Name}
                            </span>
                          </div>
                          <div style={{ marginTop: '-8px' }}>
                            <span
                              className="xxTitle"
                              style={{ paddingRight: '5px' }}
                            >
                              Count:
                            </span>
                            <span className="xxTitle c-orange">
                              {amtStr4Dec(el?.CountQty)}
                            </span>
                          </div>
                        </Grid>
                      </Grid>

                      <Divider
                        variant="fullWidth"
                        style={{ background: '#E4E4E4' }}
                      ></Divider>
                    </>
                  )
                })}
            </div>
          </CardContents>
        }
      </ContentWrapper>

      <AccountFooter
        options={[
          {
            name: 'Reject',
            onClick: () => {
              setOpenRejectDialog(true)
            },
            color: 'secondary',
            props: {
              type: 'submit',
            },
          },
          {
            name: 'Approve',
            onClick: () => {
              handleSubmit(data => onSubmit(data))()
            },
            color: 'primary',
            props: {
              type: 'submit',
            },
          },
        ]}
      />
      <RejectDialog
        data={filteredList}
        openRejectDialog={openRejectDialog}
        setOpenRejectDialog={setOpenRejectDialog}
        setRejectDialogInput={setRejectDialogInput}
        setIsReject={setIsReject}
      />
    </>
  )
}
