import React, { lazy } from 'react'

const GeneralDOMain = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/GeneralDOModule/GeneralDOMain'
  ).then(module => ({
    default: module.GeneralDOMain,
  }))
)

const GeneralDOForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/GeneralDOModule/GeneralDOForm'
  ).then(module => ({
    default: module.GeneralDOForm,
  }))
)

const PDFPreviewGP = lazy(() =>
  import(
    '@account-root/procurement-react/src/components/PDFTemplate/PDFPreviewGP'
  ).then(module => ({
    default: module.PDFPreviewGP,
  }))
)

const GeneralDODetail = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/GeneralDOModule/GeneralDODetail'
  ).then(module => ({
    default: module.GeneralDODetail,
  }))
)

const generalDORoutes = [
  {
    props: { exact: true, path: '/general-purchase/:CompanyID/delivery-order' },
    component: <GeneralDOMain />,
  },
  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/delivery-order/add',
    },
    component: <GeneralDOForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/delivery-order/edit/:DOHeaderID',
    },
    component: <GeneralDOForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/delivery-order/resubmit/:DOHeaderID',
    },
    component: <GeneralDOForm mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/delivery-order/detail/:DOHeaderID',
    },
    component: <GeneralDODetail />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/delivery-order/detail/:DOHeaderID/preview',
    },
    component: (
      <PDFPreviewGP
        accountType="/"
        docType="delivery-order"
        backPath="delivery-order"
      />
    ),
  },
]

export default generalDORoutes
