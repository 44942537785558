import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { AccountFooter } from 'components/Footer/AccountFooter'
import AppContext from 'containers/App/Store/AppContext'
import {
  useGetBankAccountLazyQuery,
  useGetCompanyQuery,
} from 'generated/graphql'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation } from 'react-router'
import * as yup from 'yup'

interface CashInflowVSOutflowByMonthParamsFormProps {
  CompanyID: string
  Month: Number
  Year: Number
  BankAccountID: string
}

export const CashInflowVSOutflowByMonthParamsForm = (props: any) => {
  useEffect(() => {
    console.log('CashInflowVSOutflowByMonthParamsForm')
  }, [])
  const { globalState, dispatch }: any = useContext(AppContext as any)
  let history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  //const { CompanyID, BankAccountID }: any = useParams()
  let location = useLocation()
  const editData = location?.state as any
  const [bankAcc, setBankAcc] = useState()
  const ParamsSchema = yup.object().shape({
    CompanyID: CommonYupValidation.requireField('Company is required'),
    Year: CommonYupValidation.requireField('Year is required'),
    Month: CommonYupValidation.requireField('Month is required'),
  })
  const latestCompany = localStorage.getItem('latestCompany')

  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    watch,
    setValue,
  } = useForm<CashInflowVSOutflowByMonthParamsFormProps>({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(ParamsSchema),
  })

  // ACCOUNTX API CALLS

  const [
    loadBankAccount,
    {
      called: bankAccountCalled,
      loading: bankAccountLoading,
      data: { getBankAccount } = { getBankAccount: [] },
    },
  ] = useGetBankAccountLazyQuery({
    fetchPolicy: 'network-only',
  })

  const {
    loading: companyLoading,
    error: companyError,
    data: { getCompany: getCompany } = { getCompany: [] },
  } = useGetCompanyQuery({
    fetchPolicy: 'network-only',
    variables: {
      orderByAsc: 'Name',
    },
  })

  const onSubmit = (data, status) => {
    console.log('DATA', data)
    history.push({
      pathname: `/cash-book/submenu/digital-report/cash-inflow-outflow-report/generated`,
      state: {
        Year: data.Year,
        Month: data.Month,
        BankAccountID: data.BankAccountID,
        CompanyID: data?.CompanyID,
      },
    })
  }

  return (
    <>
      {companyLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/cash-book/submenu/digital-report`)}
        smTitle={'Cash Book'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Submenu' },
          { name: 'Cash Inflow VS Outflow (By Month)', current: true },
        ]}
        rightRouteSegments={[{ name: 'Parameters', current: true }]}
      />
      <ContentWrapper float>
        <CardContents>
          {!companyLoading && (
            <Controller
              render={({ value, onChange }) => {
                const defVal = getCompany?.filter(
                  x => x?.CompanyID === latestCompany
                )[0]
                return (
                  <Autocomplete
                    options={getCompany || []}
                    getOptionLabel={option => `${option?.Name}`}
                    fullWidth
                    defaultValue={defVal}
                    onChange={(value, newValue: any) => {
                      setValue('CompanyID', newValue?.CompanyID)
                      loadBankAccount({
                        variables: { CompanyID: newValue?.CompanyID },
                      })
                      setValue('BankAccountID', undefined)
                      setBankAcc(undefined)
                    }}
                    renderOption={(props, option) => {
                      return <span>{props?.Name}</span>
                    }}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            helperText={errors?.CompanyID?.message}
                            error={errors?.CompanyID ? true : false}
                            label="Company"
                            style={{ width: '100%' }}
                            margin="normal"
                            required
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              label="Company"
              name="CompanyID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="normal"
              ref={register}
              helperText={errors?.CompanyID?.message}
              error={errors?.CompanyID ? true : false}
              defaultValue={latestCompany}
              required
            />
          )}

          <Controller
            as={<NumberFormat allowNegative={false} />}
            customInput={TextField}
            id="standard-basic"
            name="Year"
            label="Year"
            autoComplete="off"
            control={control}
            margin="normal"
            required
            ref={register}
            helperText={errors?.Year ? 'Year is required' : null}
            error={errors?.Year ? true : false}
            defaultValue={new Date().getFullYear()}
          />

          <Controller
            as={<NumberFormat allowNegative={false} />}
            customInput={TextField}
            id="standard-basic"
            name="Month"
            label="Month"
            autoComplete="off"
            control={control}
            margin="normal"
            required
            ref={register}
            helperText={errors?.Month ? 'Month is required' : null}
            error={errors?.Month ? true : false}
            defaultValue={1}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  options={getBankAccount || []}
                  getOptionLabel={option => `${option?.Code}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    setValue('BankAccountID', newValue?.BankAccountID)
                    setBankAcc(newValue)
                  }}
                  renderOption={(props, option) => {
                    return <span>{props?.Code}</span>
                  }}
                  value={bankAcc}
                  key={bankAcc}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          helperText={errors?.BankAccountID?.message}
                          error={errors?.BankAccountID ? true : false}
                          label="Bank"
                          style={{ width: '100%' }}
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Bank"
            name="BankAccountID"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            helperText={errors?.BankAccountID?.message}
            error={errors?.BankAccountID ? true : false}
          />
        </CardContents>

        <AccountFooter
          options={[
            {
              name: 'Submit',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
              //   props: { disabled: getReceipt?.length === 0 },
            },
          ]}
        />
      </ContentWrapper>

      {/* FOOTER */}
    </>
  )
}
