import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import { CopyrightFooter } from 'components/Footer/Copyright'
import AppContext from 'containers/App/Store/AppContext'
import {
  useGetCompanyQuery,
  useGetWarehouseLazyQuery,
  useGetStockItemNameQuery,
  useGetStockCategoryQuery,
} from 'generated/graphql'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { AccountFooter } from 'components/Footer/AccountFooter'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Autocomplete } from '@material-ui/lab'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'

interface StockLedgerByLocationParamsFormProps {
  CompanyID: string
  StartDate: Date
  EndDate: Date
  WarehouseID: string
  StockItemID: string
  StockCategoryID: string
  TrxType: string
}

export const StockLedgerByLocationParamsForm = (props: any) => {
  const { globalState, dispatch }: any = useContext(AppContext as any)
  let history = useHistory()
  const { WarehouseID }: any = useParams()
  let location = useLocation()
  const editData = location?.state as any
  const ParamsSchema = yup.object().shape({
    CompanyID: CommonYupValidation.requireField('CompanyID is required'),
    StartDate: CommonYupValidation.requireField('Start Date is required'),
    EndDate: CommonYupValidation.requireField('End Date is required'),
  })
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />
  const [selectedWarehouse, setSelectedWarehouse] = useState(new Set())

  const handleCheckboxWarehouseChange = (event: any) => {
    let warehouse = selectedWarehouse
    if (event?.target?.checked) {
      warehouse.add(event?.target?.value)
    } else {
      warehouse.delete(event?.target?.value)
    }
    setSelectedWarehouse(new Set(warehouse))
  }
  const [selectedStockItem, setSelectedStockItem] = useState(new Set())

  const handleCheckboxStockItemChange = (event: any) => {
    let stockItem = selectedStockItem
    if (event?.target?.checked) {
      stockItem.add(event?.target?.value)
    } else {
      stockItem.delete(event?.target?.value)
    }
    setSelectedStockItem(new Set(stockItem))
  }

  const [selectedStockCategory, setSelectedStockCategory] = useState(new Set())

  const handleCheckboxStockCategoryChange = (event: any) => {
    let stockCategory = selectedStockCategory
    if (event?.target?.checked) {
      stockCategory.add(event?.target?.value)
    } else {
      stockCategory.delete(event?.target?.value)
    }
    setSelectedStockCategory(new Set(stockCategory))
  }

  const [selectedType, setSelectedType] = useState(new Set())

  const handleCheckboxTrxTypeChange = (event: any) => {
    let creditorType = selectedType
    if (event?.target?.checked) {
      creditorType.add(event?.target?.value)
    } else {
      creditorType.delete(event?.target?.value)
    }
    setSelectedType(new Set(creditorType))
  }

  const trxType = [
    {
      TrxTypeValue: 'Receipt',
      title: 'Receipt',
    },
    {
      TrxTypeValue: 'Issue',
      title: 'Issue',
    },
    {
      TrxTypeValue: 'Transfer Out',
      title: 'Transfer Out',
    },
    {
      TrxTypeValue: 'Transfer In',
      title: 'Transfer In',
    },
    {
      TrxTypeValue: 'Adjustment',
      title: 'Adjustment',
    },
    {
      TrxTypeValue: 'Cost Adjustment',
      title: 'Cost Adjustment',
    },
  ]

  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    watch,
    setValue,
  } = useForm<StockLedgerByLocationParamsFormProps>({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(ParamsSchema),
  })

  // ACCOUNTX API CALLS

  const {
    loading: companyLoading,
    error: companyError,
    data: { getCompany: getCompany } = { getCompany: [] },
  } = useGetCompanyQuery({
    fetchPolicy: 'network-only',
    variables: {
      orderByAsc: 'Name',
    },
  })

  const {
    loading: categoryLoading,
    error: categoryError,
    data: { getStockCategory: getStockCategory } = { getStockCategory: [] },
  } = useGetStockCategoryQuery({
    fetchPolicy: 'network-only',
    variables: {
      orderByAsc: 'Name',
    },
  })

  const StockCategoryData = getStockCategory?.filter(
    x => x?.AccountID === user?.accountID
  )

  const [
    loadWarehouse,
    {
      loading: WarehouseLoading,
      error: WarehouseError,
      data: { getWarehouse } = { getWarehouse: [] },
    },
  ] = useGetWarehouseLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getWarehouse }) => {},
  })

  // const [
  //   loadAccountPeriod,
  //   {
  //     loading: accountPeriodLoading,
  //     error: accountPeriodError,
  //     data: { getAccountPeriod } = { getAccountPeriod: [] },
  //   },
  // ] = useGetAccountPeriodLazyQuery({
  //   fetchPolicy: 'network-only',
  // })

  const {
    loading: StockItemLoading,
    error: StockItemError,
    data: { getStockItem } = { getStockItem: [] },
  } = useGetStockItemNameQuery({
    fetchPolicy: 'network-only',
  })

  const onSubmit = (data, status) => {
    history.push({
      pathname: `/inventory-control/digital-report/stock-ledger-by-location-report/generated`,
      state: {
        CompanyID: data?.CompanyID,
        StartDate: new Date(data.StartDate),
        EndDate: new Date(data.EndDate),
        WarehouseID: selectedWarehouse
          ? Array.from(selectedWarehouse).toString()
          : undefined,
        StockItemID: selectedStockItem
          ? Array.from(selectedStockItem).toString()
          : undefined,
        StockCategoryID: selectedStockCategory
          ? Array.from(selectedStockCategory).toString()
          : undefined,
        TrxType: selectedType ? Array.from(selectedType) : undefined,
      },
    })
  }

  return (
    <>
      {WarehouseLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(`/inventory-control/submenu/digital-report`)
        }
        smTitle={'Inventory Control'}
        title={user?.accountName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'Stock Ledger', current: true },
        ]}
        rightRouteSegments={[{ name: 'Parameters', current: true }]}
      />
      <ContentWrapper float>
        <CardContents>
          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  options={getCompany || []}
                  getOptionLabel={option => `${option?.Name}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    setValue('CompanyID', newValue?.CompanyID)
                    loadWarehouse({
                      variables: { CompanyID: newValue?.CompanyID },
                    })
                    setValue('WarhouseID', undefined)
                  }}
                  renderOption={(props, option) => {
                    return <span>{props?.Name}</span>
                  }}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          helperText={errors?.CompanyID?.message}
                          error={errors?.CompanyID ? true : false}
                          label="Company"
                          style={{ width: '100%' }}
                          margin="normal"
                          required
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Company"
            name="CompanyID"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            helperText={errors?.CompanyID?.message}
            error={errors?.CompanyID ? true : false}
            required
          />

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="StartDate"
              label="Start Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch('StartDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.StartDate ?? new Date()}
              helperText={errors?.StartDate?.message}
              error={errors?.StartDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="EndDate"
              label="End Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch('EndDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.EndDate ?? new Date()}
              helperText={errors?.EndDate?.message}
              error={errors?.EndDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>

          {/* <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  options={getWarehouse || []}
                  getOptionLabel={option => `${option?.Name}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    setValue('WarehouseID', newValue?.WarehouseID)
                    loadStockItemWarehouse({
                      variables: { WarehouseID: newValue?.WarehouseID },
                    })
                    setValue('WarhouseID', undefined)
                  }}
                  renderOption={(props, option) => {
                    return <span>{props?.Name}</span>
                  }}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          helperText={errors?.WarehouseID?.message}
                          error={errors?.WarehouseID ? true : false}
                          label="Warehouse"
                          style={{ width: '100%' }}
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Warehouse"
            name="WarehouseID"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            helperText={errors?.WarehouseID?.message}
            error={errors?.WarehouseID ? true : false}
          /> */}

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  options={
                    getWarehouse?.sort((a, b) => {
                      return a.Name.localeCompare(b.Name)
                    }) || []
                  }
                  getOptionLabel={option => option?.Name}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selecteted = new Set()

                    newValue?.map(x => {
                      selecteted.add(x?.WarehouseID)
                    })

                    setSelectedWarehouse(new Set(selecteted))
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.WarehouseID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxWarehouseChange(event)
                        }}
                      />
                      {option?.Name}
                    </React.Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          // variant="outlined"
                          style={{ width: '100%' }}
                          label="Location"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="WarehouseID"
            label="Location"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          {/* <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  options={getStockItem || []}
                  getOptionLabel={(option: any) => `${option?.Name}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    setValue('StockItemID', newValue?.StockItemID)
                  }}
                  renderOption={(props: any, option) => {
                    return <span>{props?.Name}</span>
                  }}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          helperText={errors?.StockItemID?.message}
                          error={errors?.StockItemID ? true : false}
                          label="Stock Item"
                          style={{ width: '100%' }}
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Stock Item"
            name="StockItemID"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            helperText={errors?.StockItemID?.message}
            error={errors?.StockItemID ? true : false}
          /> */}

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  options={
                    getStockItem?.sort((a, b) => {
                      return a.Name.localeCompare(b.Name)
                    }) || []
                  }
                  getOptionLabel={option => option?.Name}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selecteted = new Set()

                    newValue?.map(x => {
                      selecteted.add(x?.StockItemID)
                    })

                    setSelectedStockItem(new Set(selecteted))
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.StockItemID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxStockItemChange(event)
                        }}
                      />
                      {option?.Name}
                    </React.Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          // variant="outlined"
                          style={{ width: '100%' }}
                          label="Stock Item"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="StockItemID"
            label="Stock Item"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  options={
                    StockCategoryData?.sort((a, b) => {
                      return a.Name.localeCompare(b.Name)
                    }) || []
                  }
                  getOptionLabel={option => option?.Name}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selecteted = new Set()

                    newValue?.map(x => {
                      selecteted.add(x?.StockCategoryID)
                    })

                    setSelectedStockCategory(new Set(selecteted))
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.StockCategoryID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxStockCategoryChange(event)
                        }}
                      />
                      {option?.Name}
                    </React.Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          style={{ width: '100%' }}
                          label="Stock Category"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="StockCategoryID"
            label="Stock Category"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  options={trxType || []}
                  getOptionLabel={option => `${option?.title}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selecteted = new Set()

                    newValue?.map(x => {
                      selecteted.add(x?.TrxTypeValue)
                    })

                    setSelectedType(new Set(selecteted))
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.TrxTypeValue}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxTrxTypeChange(event)
                        }}
                      />
                      {`${option?.title}`}
                    </React.Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          // variant="outlined"
                          style={{ width: '100%' }}
                          label="Transaction Type"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="TrxType"
            label="Transaction Type"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          {/* <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  options={trxType || []}
                  getOptionLabel={option => `${option?.title}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    setValue('TrxType', newValue?.TrxTypeValue)
                  }}
                  renderOption={(props, option) => {
                    return <span>{props?.title}</span>
                  }}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          helperText={errors?.TrxType?.message}
                          error={errors?.TrxType ? true : false}
                          label="Transaction Type"
                          style={{ width: '100%' }}
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Transaction Type"
            name="TrxType"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="dense"
            ref={register}
            helperText={errors?.TrxType?.message}
            error={errors?.TrxType ? true : false}
          /> */}
        </CardContents>

        <AccountFooter
          options={[
            {
              name: 'Submit',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
              //   props: { disabled: getReceipt?.length === 0 },
            },
          ]}
        />
      </ContentWrapper>

      {/* FOOTER */}
    </>
  )
}
