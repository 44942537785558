import { yupResolver } from '@hookform/resolvers'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import {
  Grid,
  InputAdornment,
  ListSubheader,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import BigNumber from 'bignumber.js'
import {
  RecordStatus,
  useGetCostCentreLazyQuery,
  useGetDocumentDateValidationQuery,
  useGetTaxEffectiveDateQuery,
  useGetTaxSchemeQuery,
} from 'generated/graphql'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import { amtNumStr, amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import * as yup from 'yup'
import { calculateDocAmt } from '../../ARAPModule/Helper/AmountCalculation'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'

interface ItemProps {
  BillItemID: string
  Amount: number
  DocAmt: number
  TaxSchemeID: string
  TaxAmt: number
  TaxRate: number
  CostCentreID: string
  Remark: string
}

const defaultVal: any = {
  BillItemID: '',
  Amount: 0,
  DocAmt: 0,
  TaxSchemeID: '',
  TaxAmt: 0,
  TaxRate: 0,
  CostCentreID: '',
  Remark: '',
}

export const ARItemDialog = (props: any) => {
  const {
    CompanyID,
    openItemDialog,
    setOpenItemDialog,
    itemData,
    BillItemLoading,
    fetchBillItem,
    getBillItem,
    title,
    watch,
    detailMode,
    menu,
    selectedTax,
    setSelectedTax,
    primaryItemKey,
    docType,
    defaultCostCentre,
    getMasterCOA,
  } = props

  const [selectedBillItem, setSelectedBillItem] = useState(null)
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const [accountType, setAccountType] = useState('')

  const [cost, setCost] = useState(
    detailMode === 'edit' ? menu?.obj?.DocAmt - menu?.obj?.TaxAmt : 0
  )

  const [taxAmt, setTaxAmt] = useState(
    !!menu?.obj && menu?.obj?.InvoiceItemID ? menu?.obj?.TaxAmt : 0
  )
  const docAmt = calculateDocAmt(cost, taxAmt)
  const ItemSchema = yup.object().shape({
    TaxSchemeID: CommonYupValidation.requireField('Tax Code is required'),
    BillItemID: CommonYupValidation.requireField('Item is required'),
    CostCentreID: CommonYupValidation.requireField('Department is required'),
    Amount: yup
      .string()
      .required('Amount is required')
      .test('sumZero', 'Amount should not be zero', val => {
        return parseFloat(amtNumStr(val)) !== 0
      }),
    Remark: CommonYupValidation.requireField('Remark is required'),
  })

  // Tax
  const {
    loading: taxSchemeLoading,
    data: { getTaxScheme } = { getTaxScheme: [] },
  } = useGetTaxSchemeQuery({
    variables: { RecordStatus: RecordStatus.Active },
    onCompleted: () => {
      if (detailMode === 'edit') {
        setSelectedTax(
          taxScheme?.find(x => x?.TaxSchemeID === menu?.obj?.TaxSchemeID)
        )
      }
    },
  })

  const {
    loading: docDateValidationLoading,
    error: DocDateValidationError,
    data: { getDocumentDateValidation } = { getDocumentDateValidation: [] },
  } = useGetDocumentDateValidationQuery({
    fetchPolicy: 'network-only',
  })

  //TaxEffectiveDate
  const {
    loading: taxEffectiveDateLoading,
    error: TaxEffectiveDateError,
    data: { getTaxEffectiveDate } = { getTaxEffectiveDate: [] },
  } = useGetTaxEffectiveDateQuery({
    fetchPolicy: 'network-only',
  })

  const [
    fetchCostCentre,
    {
      loading: CostCentreLoading,
      error: CostCentreErrors,
      data: { getCostCentre } = { getCostCentre: [] },
    },
  ] = useGetCostCentreLazyQuery({
    fetchPolicy: 'network-only',
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(
        `${errorMessage} that is assigned to the item: "${selectedBillItem}."`
      )
    },
  })

  const taxScheme: any = getTaxScheme
  // To get list of Tax Code that has been effective //
  const taxEffectiveDate: any = new Date(
    new Date().getTime() + 24 * 60 * 60 * 1000
  )
    .toISOString()
    .slice(0, 10)

  const taxSchemeInput: any = getTaxScheme?.filter(
    el =>
      el?.AccTaxClass === 'INPUT' &&
      el?.TaxEffective.map(y => y?.Date) <= taxEffectiveDate
  )

  const taxSchemeOutput: any = getTaxScheme?.filter(
    el =>
      el?.AccTaxClass === 'OUTPUT' &&
      el?.TaxEffective.map(y => y?.Date) <= taxEffectiveDate
  )

  const {
    handleSubmit: handleItemSubmit,
    register: itemRegister,
    setValue: setValueItem,
    watch: itemWatch,
    errors: itemErrors,
    control: itemControl,
    clearErrors: clearErrorsItem,
    reset: itemReset,
  } = useForm<ItemProps>({
    defaultValues: {
      BillItemID: !!menu ? menu?.obj?.BillItemID : '',
      CostCentreID: !!menu ? menu?.obj?.CostCentreID : '',
      // Amount: 0,
      DocAmt: 0,
      TaxSchemeID: !!menu ? menu?.obj?.TaxSchemeID : '',
      TaxAmt: 0,
      TaxRate: 0,
      Remark: !!menu ? menu?.obj?.Remark : '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(ItemSchema),
  })

  const taxCodeName = getTaxScheme?.filter(
    x => x?.TaxSchemeID === selectedTax?.TaxSchemeID
  )[0]?.Code

  const taxCodeRate = amtStr(
    getTaxScheme?.filter(x => x?.TaxSchemeID === selectedTax?.TaxSchemeID)[0]
      ?.LatestTax?.Rate
  )

  const costCentreCode =
    itemWatch('CostCentreID') !== undefined
      ? getCostCentre.filter(
          x => x?.CostCentreID === itemWatch('CostCentreID')
        )[0]?.Name
      : getCostCentre.filter(
          x => x?.CostCentreID === menu?.obj?.CostCentreID
        )[0]?.Name

  const itemName =
    itemWatch('BillItemID') !== undefined
      ? getBillItem.filter(x => x?.BillItemID === itemWatch('BillItemID'))[0]
          ?.Name
      : getBillItem.filter(x => x?.BillItemID === menu?.obj?.BillItemID)[0]
          ?.Name

  const onSubmit = (data, docAmt, index, isSaveNew) => {
    if (detailMode === 'add') {
      itemData.push({
        Sequence: itemData?.length + 1,
        BillItemID: data?.BillItemID,
        CostCentreID: data?.CostCentreID,
        Amount: parseFloat(amtNumStr(data?.Amount)),
        TaxSchemeID: data?.TaxSchemeID,
        TaxCode: taxCodeName,
        TaxRate: taxCodeRate,
        BillItemName: itemName,
        CostCentreName: costCentreCode ?? defaultCostCentre?.Name,
        TaxAmt: data?.TaxAmt,
        DocAmt: docAmt,
        Remark: data?.Remark,
      })
    } else {
      let existingItemData = itemData[index]

      itemData[index] = {
        Sequence: itemData?.Sequence ?? index + 1,
        [`${primaryItemKey}`]: itemData[index]?.[`${primaryItemKey}`],
        BillItemID: data?.BillItemID,
        CostCentreID: data?.CostCentreID,
        Amount: !!parseFloat(amtNumStr(data?.Amount))
          ? parseFloat(amtNumStr(data?.Amount))
          : docAmt - parseFloat(amtNumStr(existingItemData?.TaxAmt)),
        BillItemName: itemName,
        CostCentreName: costCentreCode ?? defaultCostCentre?.Name,
        TaxRate: taxCodeRate ?? existingItemData?.TaxRate,
        TaxCode: taxCodeName,
        TaxSchemeID: data?.TaxSchemeID,
        TaxAmt: !!data?.TaxAmt ? data?.TaxAmt : existingItemData?.TaxAmt,
        DocAmt: docAmt,
        Remark: data?.Remark,
      }
    }
    if (!isSaveNew) {
      setOpenItemDialog(false)
    } else {
      setOpenItemDialog(true)
    }
  }

  const handleBillItemChange = BillItemID => {
    setValueItem('BillItemID', BillItemID)
    // this is to get tax after select the expense item
    let defTax = getBillItem?.filter(x => x?.BillItemID === BillItemID)[0]
      ?.TaxSchemeID
    let selectTax = taxScheme?.find(x => x?.TaxSchemeID === defTax)
    setValueItem('TaxSchemeID', defTax)
    setSelectedTax(selectTax)
    clearErrorsItem('BillItemID')

    const taxEffectiveDate: any = watch('DocDate')
      ? new Date(new Date(watch('DocDate')).getTime() + 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 10)
      : null

    const taxAmount = new BigNumber(
      selectTax?.LatestTax === null
        ? selectTax?.TaxEffective.reduce((a, b) => {
            return new Date(a.Date) > new Date(b.Date) ? a : b
          })?.Rate
        : selectTax?.TaxEffective?.filter(
            x => new Date(x?.Date) <= new Date(taxEffectiveDate)
          ).reduce((a, b) => {
            return new Date(a.Date) > new Date(b.Date) ? a : b
          }, 0)?.Rate
    )
      .dividedBy(100)
      .multipliedBy(cost)
      .toNumber()
    setTaxAmt(taxAmount)
    setValueItem('TaxAmt', taxAmount)
  }

  const handleDefaultCostCentre = coaId => {
    const findcoa = getMasterCOA?.find(x => x?.MasterCOAID === coaId)
    setAccountType(findcoa?.AccountType)
    if (
      findcoa?.AccountType === 'ASSET' ||
      findcoa?.AccountType === 'LIABILITY' ||
      findcoa?.AccountType === 'EQUITY'
    ) {
      setValueItem('CostCentreID', defaultCostCentre?.CostCentreID)
      setValueItem('CostCentreName', defaultCostCentre?.Name)
    } else {
      fetchCostCentre({
        variables: {
          CompanyID: CompanyID,
          MasterCOAID: coaId,
          IsLastNode: true,
        },
      })
    }
  }

  // Autoset tax after select tax amount
  useEffect(() => {
    if (!!selectedTax) {
      setValueItem('TaxSchemeID', selectedTax?.TaxSchemeID)
      clearErrorsItem('TaxSchemeID')
    }
  }, [selectedTax])

  useEffect(() => {
    const findCoa = getBillItem
      ?.filter(x => {
        return x?.BillItemID === menu?.obj?.BillItemID
      })
      .map(y => {
        return y?.MasterCOAID
      })

    if (!!menu?.obj?.BillItemID) {
      fetchCostCentre({
        variables: {
          CompanyID: CompanyID,
          MasterCOAID: findCoa[0],
          IsLastNode: true,
        },
      })
    }
  }, [menu?.obj])

  const handleAmtChange = event => {
    setCost(event.value)
    if (selectedTax) {
      setTaxAmt(
        new BigNumber(
          selectedTax?.LatestTax === null
            ? selectedTax?.TaxEffective?.reduce((a, b) => {
                return new Date(a.Date) > new Date(b.Date) ? a : b
              })?.Rate
            : selectedTax?.LatestTax?.Rate
        )
          .dividedBy(100)
          .multipliedBy(Number(event.value))
          .toNumber()
      )
      setValueItem('TaxAmt', taxAmt)
    }
  }

  const handleTaxChange = (event, taxEffectiveDate) => {
    setSelectedTax(taxScheme.find(x => x?.TaxSchemeID === event?.target?.value))
    setTaxAmt(
      (new BigNumber(
        taxScheme.filter(x => x?.TaxSchemeID === event?.target?.value)[0]
          .LatestTax === null
          ? taxScheme
              .filter(x => x?.TaxSchemeID === event?.target?.value)[0]
              ?.TaxEffective.reduce((a, b) => {
                return new Date(a.Date) > new Date(b.Date) ? a : b
              })?.Rate
          : taxScheme
              .filter(x => x?.TaxSchemeID === event?.target?.value)[0]
              ?.TaxEffective?.filter(
                x => new Date(x?.Date) <= new Date(taxEffectiveDate)
              )
              .reduce((a, b) => {
                return new Date(a.Date) > new Date(b.Date) ? a : b
              })?.Rate
      ).toNumber() /
        100) *
        cost
    )
  }

  const handleTaxAmtChange = event => {
    setTaxAmt(event.value)
  }

  useEffect(() => {
    if (!!menu?.obj?.BillItemID) {
      const findCoa = getBillItem
        ?.filter(x => {
          return x?.BillItemID === menu?.obj?.BillItemID
        })
        .map(y => {
          return y?.MasterCOAID
        })

      const tempCoa = getMasterCOA?.find(x => x?.MasterCOAID === findCoa[0])

      setAccountType(tempCoa?.AccountType)
      if (
        tempCoa?.AccountType === 'ASSET' ||
        tempCoa?.AccountType === 'LIABILITY' ||
        tempCoa?.AccountType === 'EQUITY'
      ) {
        setValueItem('CostCentreID', defaultCostCentre?.CostCentreID)
        setValueItem('CostCentreName', defaultCostCentre?.Name)
      } else {
        fetchCostCentre({
          variables: {
            CompanyID: CompanyID,
            MasterCOAID: findCoa[0],
            IsLastNode: true,
          },
        })
      }
    }
  }, [menu?.obj])

  useEffect(() => {
    if (!isNaN(taxAmt)) {
      setValueItem('TaxAmt', taxAmt)
      clearErrorsItem('TaxAmt')
    }
  }, [taxAmt])

  useEffect(() => {
    if (detailMode === 'edit') {
      let selectTax = taxScheme.find(
        x => x?.TaxSchemeID === menu?.obj?.TaxSchemeID
      )

      setSelectedTax(selectTax)
    }
  }, [detailMode])

  return (
    <>
      {taxSchemeLoading && <Loading />}
      {CostCentreLoading && <Loading />}
      {BillItemLoading && <Loading />}
      {taxEffectiveDateLoading && <Loading />}
      {docDateValidationLoading && <Loading />}
      <CommonDialog
        fullWidth={true}
        open={openItemDialog}
        onClose={() => {
          setOpenItemDialog(false)
          setAccountType('')
        }}
        sections={{
          header: {
            title: title,
            rightText: detailMode === 'edit' ? 'Edit' : 'New',
          },
          body: () => (
            <>
              <div className="content-container" style={{ marginTop: '-20px' }}>
                {!BillItemLoading && (
                  <Controller
                    render={({ onChange, value }) => {
                      const defVal = getBillItem?.filter(
                        x => x?.BillItemID === menu?.obj?.BillItemID
                      )[0]

                      return (
                        <Autocomplete
                          options={
                            getBillItem?.sort((a, b) => {
                              return a.Name.localeCompare(b.Name)
                            }) || []
                          }
                          getOptionLabel={option => `${option?.Name}`}
                          fullWidth
                          // value={BillItemID}
                          onChange={(value, newValue: any) => {
                            handleBillItemChange(newValue?.BillItemID)
                            setValueItem('BillItemID', newValue?.BillItemID)
                            setValueItem('CostCentreID', null)
                            handleDefaultCostCentre(newValue?.MasterCOAID)
                            setSelectedBillItem(newValue?.Name)
                          }}
                          renderOption={(props, option) => {
                            return <span>{props?.Name}</span>
                          }}
                          defaultValue={defVal}
                          renderInput={(params: any) => {
                            return (
                              <div>
                                <TextField
                                  {...params}
                                  required
                                  label="Item"
                                  variant="standard"
                                  fullWidth
                                  name="BillItemID"
                                  helperText={itemErrors?.BillItemID?.message}
                                  error={itemErrors?.BillItemID ? true : false}
                                />
                              </div>
                            )
                          }}
                        />
                      )
                    }}
                    name="BillItemID"
                    autoComplete="off"
                    control={itemControl}
                    multiline={true}
                    fullWidth
                    margin="dense"
                    ref={itemRegister}
                    required
                    defaultValue={menu?.obj?.BillItemID}
                    helperText={itemErrors?.BillItemID?.message}
                    error={itemErrors?.BillItemID ? true : false}
                  />
                )}

                {accountType === 'ASSET' ||
                accountType === 'LIABILITY' ||
                accountType === 'EQUITY' ? (
                  <Controller
                    render={({ value, onChange }) => {
                      return (
                        <TextField
                          value={`${defaultCostCentre?.Code} | ${defaultCostCentre?.Name}`}
                          label="Department *"
                          fullWidth
                          disabled
                        />
                      )
                    }}
                    value={defaultCostCentre?.CostCentreID}
                    name="CostCentreID"
                    autoComplete="off"
                    control={itemControl}
                    multiline={true}
                    fullWidth
                    margin="dense"
                    ref={itemRegister()}
                    required
                    helperText={
                      (itemWatch('CostCentreID') === '' ||
                        itemWatch('CostCentreID') === undefined) &&
                      itemErrors?.CostCentreID?.message
                    }
                    error={
                      (itemWatch('CostCentreID') === '' ||
                        itemWatch('CostCentreID') === undefined) &&
                      itemErrors?.CostCentreID
                        ? true
                        : false
                    }
                  />
                ) : (
                  !CostCentreLoading && (
                    <Controller
                      render={({ value, onChange }) => {
                        const defVal = getCostCentre?.filter(
                          x => x?.CostCentreID === menu?.obj?.CostCentreID
                        )[0]
                        return (
                          <Autocomplete
                            options={
                              getCostCentre?.sort((a, b) => {
                                return a.Code.localeCompare(b.Code)
                              }) || []
                            }
                            getOptionLabel={option =>
                              `${option?.Code} | ${option?.Name}`
                            }
                            fullWidth
                            onChange={(value, newValue: any) => {
                              setValueItem(
                                'CostCentreID',
                                newValue?.CostCentreID
                              )
                            }}
                            renderOption={(props, option) => {
                              return (
                                <>
                                  <div>
                                    <div>
                                      <span className="xsTitle">
                                        {props?.Code}
                                      </span>
                                    </div>
                                    <div className="desc">{props?.Name}</div>
                                  </div>
                                </>
                              )
                            }}
                            defaultValue={defVal}
                            renderInput={(params: any) => {
                              return (
                                <div>
                                  <TextField
                                    {...params}
                                    required
                                    label="Department"
                                    variant="standard"
                                    fullWidth
                                    name="CostCentreID"
                                    value={value}
                                    helperText={
                                      (itemWatch('CostCentreID') === '' ||
                                        itemWatch('CostCentreID') ===
                                          undefined) &&
                                      itemErrors?.CostCentreID?.message
                                    }
                                    error={
                                      (itemWatch('CostCentreID') === '' ||
                                        itemWatch('CostCentreID') ===
                                          undefined) &&
                                      itemErrors?.CostCentreID
                                        ? true
                                        : false
                                    }
                                    defaultValue={menu?.obj?.CostCentreID || ''}
                                  />
                                </div>
                              )
                            }}
                          />
                        )
                      }}
                      name="CostCentreID"
                      autoComplete="off"
                      control={itemControl}
                      multiline={true}
                      fullWidth
                      margin="dense"
                      ref={itemRegister()}
                      required
                      defaultValue={menu?.obj?.CostCentreID || ''}
                      helperText={
                        (itemWatch('CostCentreID') === '' ||
                          itemWatch('CostCentreID') === undefined) &&
                        itemErrors?.CostCentreID?.message
                      }
                      error={
                        (itemWatch('CostCentreID') === '' ||
                          itemWatch('CostCentreID') === undefined) &&
                        itemErrors?.CostCentreID
                          ? true
                          : false
                      }
                    />
                  )
                )}

                <Controller
                  as={<NumberFormat allowNegative={false} />}
                  thousandSeparator
                  customInput={TextField}
                  id="standard-basic"
                  name="Amount"
                  label="Amount"
                  value={cost}
                  autoComplete="off"
                  control={itemControl}
                  onValueChange={e => {
                    handleAmtChange(e)
                  }}
                  decimalScale={2}
                  fixedDecimalScale
                  margin="dense"
                  required
                  fullWidth
                  helperText={itemErrors?.Amount?.message}
                  error={itemErrors?.Amount ? true : false}
                  ref={itemRegister()}
                  defaultValue={menu?.obj?.Amount || ''}
                />

                <Grid
                  item
                  xs={6}
                  style={{
                    marginBottom: '5px',
                    paddingRight: '10px',
                  }}
                >
                  <Controller
                    as={
                      <TextField
                        margin="dense"
                        label="Tax Code"
                        required
                        SelectProps={{
                          renderValue: () => `${selectedTax?.Code}`,
                          onChange: event => {
                            handleTaxChange(event, taxEffectiveDate)
                            setValueItem(
                              'TaxSchemeID',
                              event?.target?.value.toString()
                            )
                            clearErrorsItem('TaxSchemeID')
                          },
                        }}
                        select
                        helperText={itemErrors?.TaxSchemeID?.message}
                        error={itemErrors?.TaxSchemeID ? true : false}
                      >
                        <ListSubheader>Input</ListSubheader>
                        {taxSchemeInput?.map((tax, index) => (
                          <MenuItem
                            id="tax-select"
                            key={index}
                            value={tax?.TaxSchemeID}
                          >
                            <span className="text-noflow">{`${tax?.Code}`}</span>
                          </MenuItem>
                        ))}
                        <ListSubheader>Output</ListSubheader>
                        {taxSchemeOutput?.map((tax, index) => (
                          <MenuItem
                            id="tax-select"
                            key={index}
                            value={tax?.TaxSchemeID}
                          >
                            <span className="text-noflow">{`${tax?.Code}`}</span>
                          </MenuItem>
                        ))}
                      </TextField>
                    }
                    select
                    name="TaxSchemeID"
                    autoComplete="off"
                    control={itemControl}
                    defaultValue={menu?.obj?.TaxSchemeID || ''}
                    ref={itemRegister}
                    // hidden={true}
                    // style={{ display: 'none' }}
                  />
                </Grid>

                <Grid
                  item
                  xs={6}
                  style={{
                    marginBottom: '5px',
                  }}
                >
                  <TextField
                    name="TaxRate"
                    label="Tax Rate %"
                    value={amtStr(taxCodeRate)}
                    disabled
                    // className="right"
                    margin="normal"
                    // ref={register}
                    style={{ marginTop: '5px' }}
                  />
                </Grid>

                <Controller
                  as={<NumberFormat allowNegative={false} />}
                  thousandSeparator
                  customInput={TextField}
                  ref={itemRegister()}
                  control={itemControl}
                  margin="dense"
                  name="TaxAmt"
                  label="Tax Amount"
                  onValueChange={e => {
                    handleTaxAmtChange(e?.floatValue)
                    setTaxAmt(e?.floatValue)
                  }}
                  value={taxAmt}
                  defaultValue={menu?.obj?.TaxAmt ?? taxAmt ?? 0}
                  decimalScale={2}
                  fixedDecimalScale
                  className="left"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
                <Controller
                  render={({ value, onChange }) => {
                    value = { docAmt }
                    return (
                      <TextField
                        label="Doc Amount"
                        value={amtStr(docAmt)}
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                        }}
                        className="right"
                        disabled
                      />
                    )
                  }}
                  disabled
                  margin="dense"
                  defaultValue={(docAmt || 0) ?? (menu?.obj?.DocAmt || 0)}
                  name="DocAmt"
                  ref={itemRegister()}
                  control={itemControl}
                />

                <Controller
                  as={TextField}
                  id="standard-basic"
                  name="Remark"
                  label="Remark"
                  autoComplete="off"
                  control={itemControl}
                  fullWidth
                  margin="dense"
                  ref={itemRegister}
                  defaultValue={menu ? menu?.obj?.Remark : ''}
                  helperText={itemErrors?.Remark?.message}
                  error={itemErrors?.Remark ? true : false}
                  required
                />
              </div>
            </>
          ),
          footer: {
            actions:
              detailMode === 'add'
                ? [
                    {
                      displayText: 'Close',
                      props: {
                        onClick: () => {
                          setOpenItemDialog(false)
                          setAccountType('')
                        },
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                    {
                      displayText: 'Confirm & New',
                      props: {
                        onClick: () => {
                          handleItemSubmit(data =>
                            onSubmit(data, docAmt, menu?.index, true)
                          )()
                          fetchBillItem()
                          fetchCostCentre()
                          itemReset(defaultVal)
                          setOpenItemDialog(true)
                          setAccountType('')
                        },
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                    {
                      displayText: 'Confirm',
                      props: {
                        onClick: () => {
                          handleItemSubmit(data =>
                            onSubmit(data, docAmt, menu?.index, false)
                          )()
                          setAccountType('')
                        },
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                  ]
                : [
                    {
                      displayText: 'Close',
                      props: {
                        onClick: () => setOpenItemDialog(false),
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                    {
                      displayText: 'Confirm',
                      props: {
                        onClick: () => {
                          handleItemSubmit(data =>
                            onSubmit(data, docAmt, menu?.index, false)
                          )()
                        },
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                  ],
          },
        }}
      />
      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
