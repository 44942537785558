import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize';
import { Grid } from '@material-ui/core';
import { useDocumentListingQuery } from 'generated/graphql';
import React from 'react';
import { useParams } from 'react-router';

export const HeaderPR = (props: any) => {
  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  const { CompanyID }: any = useParams();
  const [width] = useMediaQuery();

  const {
    loading: documentLoading,
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: CompanyID,
    },
  });

  let fontSize;
  if (width < 769) {
    fontSize = 7;
  } else {
    fontSize = 10;
  }

  let containerStyle, fontStyle;
  fontStyle = {
    padding: '5px',
    fontSize: fontSize,
    textAlign: 'center',
  };

  return (
    <>
      {documentLoading && <Loading />}
      <div style={containerStyle}>
        <div style={fontStyle}>
          <Grid container>
            <Grid xs={3} style={{ textAlign: 'left', marginLeft: '5px' }}>
              <div>
                {DocumentListing?.length > 0
                  ? DocumentListing?.map(x => {
                      if (x?.description === 'Company-Logo') {
                        return (
                          <>
                            <img
                              src={x?.fileURL}
                              style={{
                                maxHeight: width < 769 ? '80px' : '100px',
                                maxWidth: width < 769 ? '80px' : '100px',
                              }}
                              crossOrigin="anonymous"
                            />
                          </>
                        );
                      }
                    })
                  : null}
              </div>
            </Grid>

            <Grid xs={6}>
              <b>{user?.companyName}</b>
              <br />
              {` (Co. No. : ${user?.companyRegNo}`}
              {!!user?.companyGSTNo ? (
                <>, GST No. : {` ${user?.companyGSTNo}`}</>
              ) : !!user?.companyGSTNo ? (
                <>, SST No. : {` ${user?.companySSTNo}`}</>
              ) : null}
              {`)`}
              <br />
              <br />
              {user?.companyAddress?.address}
              <br />
              {user?.companyAddress?.country == 'Singapore'
                ? `${user?.companyAddress?.country} ${user?.companyAddress?.postCode}`
                : `${user?.companyAddress?.postCode} ${user?.companyAddress?.city}, ${user?.companyAddress?.state}, ${user?.companyAddress?.country}`}
              <br />
              Tel No. {user?.companyContactNo}
            </Grid>

            <Grid xs={3}></Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default HeaderPR;
