import theme from '@ifca-root/react-component/src/assets/theme'
import IconItem from '@ifca-root/react-component/src/components/Avatar/IconItem'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import SubHeaderAction from '@ifca-root/react-component/src/components/Header/SubHeaderAction'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import StandardTabs from '@ifca-root/react-component/src/components/Tabs/StandardTabs'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import {
  Box,
  Button,
  IconButton,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import EventIcon from '@material-ui/icons/Event'
import MenuIcon from '@material-ui/icons/Menu'
import { TreeView } from '@material-ui/lab'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { AccountFooter } from 'components/Footer/AccountFooter'
import { CopyrightFooter } from 'components/Footer/Copyright'
import {
  DotIcon,
  MinusSquare,
  PlusSquare,
  StyledTreeItem,
} from 'containers/GeneralSettingModule/MasterCOA/RoleTreeStyle'
import {
  AcctPermission,
  ApprovalStatus,
  GetBudgetDocument,
  useCreateBudgetWithDetailMutation,
  useDocumentListingLazyQuery,
  useGetAccountPeriodLazyQuery,
  useGetBudgetImportListLazyQuery,
  useGetBudgetMasterCoaQuery,
  useGetBudgetQuery,
  useGetCoaStructureQuery,
  useGetCostCentreQuery,
  useGetParentMasterCoaForTreeLazyQuery,
  useImportExcelBudgetLazyQuery,
  useUpdateBudgetMutation,
  useUpdateBudgetWithDetailMutation,
} from 'generated/graphql'
import { useColorStatusList } from 'helpers/Hooks/useColorStatusList'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { amtNumStr, amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation, useParams } from 'react-router'
import { v4 as uuidv4 } from 'uuid'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { useBudgetExcelUpload } from './GLBudgetFunction'
import { GLBudgetExcelInput } from './GLBudgetExcelInput'
import SnackBarContext from 'containers/App/Store/SnackBarContext'

interface GLBudgetProps {
  Amount: number
  Year: Date
  BudgetAmountArr: any
  Remarks: string
}

export const GLBudgetFormV2 = (props: any) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const { mode } = props
  let location = useLocation()
  const editData: any = location?.state
  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { handleStatusColor, handleStatusRename } = useColorStatusList()
  const [value, setVal] = useState(editData?.accType ?? 0)

  const {
    openSnackBar,
    setOpenSnackBar,
    setSnackBarMsg,
    snackBarMessage,
  }: any = useContext(SnackBarContext as any)

  const [openRejectDialog, setOpenRejectDialog] = useState(false)
  const [errorDialog, setErrorDialog] = useState(false)
  const [excelImport, setExcelImport] = useState(false)
  const [errorMsg2, setErrorMsg2] = useState<any>(null)
  const [errorMsg1, setErrorMsg1] = useState<any>(null)
  const [errorDial, setErrorDial] = useState(false)
  const [errorYear, setErrorYear] = useState(new Date().getFullYear())
  const [GLBudgetExcelYear, setGLBudgetExcelYear] = useState(undefined)
  const [openExitConf, setOpenExitConf] = useState(false)
  const [openProceedConf, setOpenProceedConf] = useState(false)
  const [emptyBudgetErrorDialog, setEmptyBudgetErrorDialog] = useState(false)
  const [proceedAction, setProceedAction] = useState<string>('')
  const [proceedStatus, setProceedStatus] = useState<string>('')
  const [OriginalBudgetTemplateForm, setOriginalBudgetTemplateForm] = useState<
    any
  >([
    {
      header: {
        header: 'AccountCode',
        width: 30,
      },
      dataType: 'text',
    },
    {
      header: {
        header: 'Description',
        width: 30,
      },
      dataType: 'text',
    },
    {
      header: {
        header: 'DepartmentCode',
        width: 30,
      },
      dataType: 'text',
    },
    {
      header: {
        header: 'Total',
        width: 17,
      },
      dataType: 'number',
    },
  ])

  const [openCheckingYearDialog, setCheckingYearDialog] = useState(false)
  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */
  const handleChange = (event, newValue) => {
    setVal(newValue)
    fetchParentNode({
      variables: {
        MasterCOABudgetArr: BudgetItem?.map(x => x?.MasterCOAID),
        CompanyID,
      },
    })
  }
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  let BudgetItem = JSON.parse(localStorage.getItem('BudgetItem'))
  let CostCentreBudgetItem = JSON.parse(
    localStorage.getItem('CostCentreBudgetItem')
  )
  let PeriodBudgetItem = JSON.parse(localStorage.getItem('PeriodBudgetItem'))

  const [prevBudgetItem, setPrevBudgetItem] = useState<any>(
    editData?.previousData?.prevBudgetItem
  )
  const [prevCCBudgetItem, setPrevCCBudgetItem] = useState<any>(
    editData?.previousData?.prevCCItem
  )
  const [prevPeriodBudgetItem, setPrevPeriodBudgetItem] = useState<any>(
    editData?.previousData?.prevPItem
  )

  const {
    onDownloadTemplateBudget,
    fileTitle,
    setFileTitle,
    currentData,
    setCurrentData,
    openFileUpload,
    setOpenFileUpload,
    resetFileUpload,
    onSelectFile,
    onExportBudget,
    disableButton,
  } = useBudgetExcelUpload()

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    watch,
    reset,
    setError,
    getValues,
    formState,
  } = useForm<GLBudgetProps>({
    defaultValues: {},
    mode: 'onSubmit',
    //resolver: yupResolver(GLBudgetFormSchema),
  })

  // const {
  //   loading: entityCOALoading,
  //   error: entityCOAError,
  //   data: { getEntityCOA } = { getEntityCOA: [] },
  // } = useGetEntityCoaQuery({
  //   fetchPolicy: 'network-only',
  //   variables: { CompanyID: CompanyID },
  //   onCompleted: ({ getEntityCOA }) => {},
  // })

  const {
    loading: coaStructureLoading,
    error: coaStructureError,
    data: { getCOAStructure } = {
      getCOAStructure: [],
    },
  } = useGetCoaStructureQuery({
    fetchPolicy: 'network-only',
  })

  const {
    loading: BudgetMasterCOALoading,
    error: BudgetMasterCOAError,
    data: { getBudgetMasterCOA } = { getBudgetMasterCOA: [] },
  } = useGetBudgetMasterCoaQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
    onCompleted: ({ getBudgetMasterCOA }) => {
      if (getBudgetMasterCOA.CoaBudgetTree?.length === 0) {
        setOriginalListing([])
      } else {
        setOriginalListing(getBudgetMasterCOA.CoaBudgetTree)
      }
    },
  })

  const [
    fetchBudgetImportList,
    {
      loading: BudgetImportListLoading,
      error: BudgetImportListError,
      data: { getBudgetImportList: getBudgetImportList } = {
        getBudgetImportList: [],
      },
    },
  ] = useGetBudgetImportListLazyQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
    onCompleted: ({ getBudgetImportList }) => {
      if (getBudgetImportList?.length > 0) {
        onExportBudget({
          name: `Original_Budget_${user?.companyName}_${Year}`,
          workSheetName: `Budget List`,
          headerSetup: OriginalBudgetTemplateForm.concat(
            getAccountPeriod?.map(x => {
              return {
                header: {
                  header: `${x?.FPeriod}`,
                  width: 17,
                  period: true,
                },
                dataType: 'number',
              }
            })
          ),
          BudgetYear: Year,
          BudgetData: getBudgetImportList,
        })

        setOpenSnackBar(true)
        setSnackBarMsg('Excel exported successfully')
      }
    },
  })

  const [
    fetchImportExcelBudget,
    {
      loading: ImportExcelBudgetLoading,
      error: ImportExcelBudgetError,
      data: { ImportExcelBudget: ImportExcelBudget } = {
        ImportExcelBudget: [],
      },
    },
  ] = useImportExcelBudgetLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ ImportExcelBudget }) => {
      if (ImportExcelBudget?.length > 0) {
        let Budget = ImportExcelBudget?.map(x => {
          return {
            BudgetItemID: x?.BudgetItemID,
            MasterCOAID: x?.MasterCOAID,
            BudgetAmount: amtNumStr(x?.BudgetAmount),
            MasterCOACode: x?.MasterCOACode,
            MasterCOAName: x?.MasterCOAName,
          }
        })
        setPrevBudgetItem(Budget)
        localStorage.setItem('BudgetItem', JSON.stringify(Budget))
        fetchParentNode({
          variables: {
            MasterCOABudgetArr: ImportExcelBudget?.map(x => x?.MasterCOAID),
            CompanyID,
          },
        })

        let CCBudgetItem = []
        ImportExcelBudget?.map(x => {
          x?.CCBudgetItem?.map(el => {
            CCBudgetItem.push({
              BudgetItemID: el?.BudgetItemID,
              BudgetItemCostCentreID: el?.BudgetItemCostCentreID,
              CostCentreID: el?.CostCentreID,
              MasterCOAID: x?.MasterCOAID,
              CostCentreBudgetAmount: amtNumStr(el?.CostCentreBudgetAmount),
              CostCentreCode: el?.CostCentreCode,
            })
          })
        })
        setPrevCCBudgetItem(CCBudgetItem)
        localStorage.setItem(
          'CostCentreBudgetItem',
          JSON.stringify(CCBudgetItem)
        )
        setPrevCCBudgetItem(CCBudgetItem)

        let PBudgetItem = []
        ImportExcelBudget?.map(x => {
          x?.CCBudgetItem?.map(el => {
            el?.PBudgetItem?.map(m => {
              PBudgetItem.push({
                BudgetItemCostCentreID: m?.BudgetItemCostCentreID,
                BudgetItemPeriodID: m?.BudgetItemPeriodID,
                AccountPeriodID: m?.AccountPeriodID,
                CostCentreID: el?.CostCentreID,
                MasterCOAID: x?.MasterCOAID,
                PeriodBudgetAmount: amtNumStr(m?.PeriodBudgetAmount),
                Period: m?.FPeriod,
              })
            })
          })
        })
        setPrevPeriodBudgetItem(PBudgetItem)
        localStorage.setItem('PeriodBudgetItem', JSON.stringify(PBudgetItem))
        setPrevPeriodBudgetItem(PBudgetItem)

        setOpenSnackBar(true)
        setSnackBarMsg('Excel imported successfully')
      }
    },
  })

  const {
    loading: BudgetLoading,
    error: BudgetError,
    data: { getBudget: getBudget } = { getBudget: [] },
  } = useGetBudgetQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
    onCompleted: ({ getBudget }) => {
      if (getBudget?.length > 0 && mode === 'add' && !editData) {
        setYear(checkCreatedBudgetYear(new Date().getFullYear()))
      }
    },
  })
  const {
    loading: getCostCentreLoading,
    data: { getCostCentre } = { getCostCentre: [] },
    // refetch: getCostCentreRefetch,
    // error: getCostCentreError,
  } = useGetCostCentreQuery({
    variables: {
      CompanyID: CompanyID,
    },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      //   if (data.getCostCentre.length > 0) {
      //     setPageMode('Edit')
      //   } else {
      //     setPageMode('Add')
      //   }
    },
  })

  // const {
  //   loading: coaLoading,
  //   data: { getMasterCOA } = { getMasterCOA: [] },
  // } = useGetMasterCoaQuery({
  //   fetchPolicy: 'network-only',
  //   variables: { orderByAsc: 'Code' },
  //   onCompleted: ({ getMasterCOA }) => {},
  // })

  //// Handle Attachment ////

  const {
    files,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment()

  const [
    fetchdocuments,
    {
      data: { DocumentListing } = { DocumentListing: [] },
      loading: documentLoading,
      error: documentError,
    },
  ] = useDocumentListingLazyQuery({
    fetchPolicy: 'network-only',

    onCompleted: ({ DocumentListing }) => {
      handleEditUpload(DocumentListing)
      previewFiles.push(...DocumentListing?.map(v => v.fileURL))
    },
  })

  ///////////////////////////

  const treeQuery = getBudgetMasterCOA.CoaData

  const [
    createBudget,
    { loading: createLoading, error: createError },
  ] = useCreateBudgetWithDetailMutation({
    onError: error => {},
    //fetchPolicy: 'network-only',
    onCompleted: data => {
      // history.goBack()
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({
          pathname: `/general-ledger/${CompanyID}/original-budget`,
          state: { success: true, msgMode: 'create' },
        })
        localStorage.removeItem('BudgetItem')
        localStorage.removeItem('PeriodBudgetItem')
        localStorage.removeItem('CostCentreBudgetItem')
      }, 500)
    },
  })

  const [
    updateNewBudget,
    {
      loading: updateBudgetWithDetailLoading,
      error: updateBudgetWithDetailError,
    },
  ] = useUpdateBudgetWithDetailMutation({
    onError: error => {
      //snackBar(error.message)
    },
    onCompleted: ({ updateBudgetWithDetail }) => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      setTimeout(() => {
        history.push({
          pathname: `/general-ledger/${CompanyID}/original-budget`,
          state: { success: true, msgMode: 'update' },
        })
        localStorage.removeItem('BudgetItem')
        localStorage.removeItem('PeriodBudgetItem')
        localStorage.removeItem('CostCentreBudgetItem')
      }, 500)
    },
  })

  const [
    updateBudget,
    { loading: updateBudgetLoading, error: updateBudgetError },
  ] = useUpdateBudgetMutation({
    onError: error => {
      //snackBar(error.message)
    },
    onCompleted: ({ updateBudget }) => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      setTimeout(() => {
        history.push({
          pathname: `/general-ledger/${CompanyID}/original-budget`,
          state: { success: true, msgMode: 'update' },
        })
        localStorage.removeItem('BudgetItem')
        localStorage.removeItem('PeriodBudgetItem')
        localStorage.removeItem('CostCentreBudgetItem')
      }, 500)
    },
  })

  const [
    fetchParentNode,
    {
      loading: getParentMasterCOAForTreeLoading,
      data: { getParentMasterCOAForTree } = { getParentMasterCOAForTree: [] },
    },
  ] = useGetParentMasterCoaForTreeLazyQuery({
    // variables: {
    //   MasterCOABudgetArr: BudgetItem?.map(x => x?.MasterCOAID),
    //   CompanyID,
    // },
    fetchPolicy: 'network-only',
    onCompleted: data => {},
  })

  /// Checking for Created Budget Year ///

  const checkCreatedBudgetYear = year => {
    const currentYear = getBudget?.filter(x => x?.Fyear === year)

    if (currentYear?.length > 0) {
      return checkCreatedBudgetYear(new Date(`${year + 1}`).getFullYear())
    } else {
      return new Date(`${year}`).getFullYear()
    }
  }

  const [Year, setYear] = useState(
    mode === 'add' && !!editData?.selectedYear
      ? editData?.selectedYear
      : mode === 'add' && !editData?.selectedYear
      ? new Date().getFullYear()
      : mode === 'edit' && !!editData?.selectedYear
      ? editData?.selectedYear
      : editData?.Fyear
  )

  const [
    fetchAccountPeriod,
    {
      loading: AccountPeriodLoading,
      error: AccountPeriodError,
      data: { getAccountPeriod } = { getAccountPeriod: [] },
    },
  ] = useGetAccountPeriodLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getAccountPeriod }) => {},
  })

  useEffect(() => {
    if (Year) {
      fetchAccountPeriod({
        variables: {
          CompanyID: CompanyID,
          FYear: Year,
          orderByAsc: 'FPeriod',
        },
      })
    }
  }, [Year])

  /// Default Data for edit ///

  useEffect(() => {
    //// Budget Item ////
    if (mode === 'edit' || mode === 'approve-reject' || mode === 'detail') {
      if (!!!BudgetItem) {
        let Budget = editData?.BudgetItem?.map(x => {
          return {
            BudgetID: x?.BudgetID,
            BudgetItemID: x?.BudgetItemID,
            MasterCOAID: x?.MasterCOAID,
            BudgetAmount: amtNumStr(x?.BudgetAmt),
            MasterCOACode: x?.MasterCOA?.Code,
            MasterCOAName: x?.MasterCOA?.Name,
          }
        })
        setPrevBudgetItem(Budget)
        localStorage.setItem('BudgetItem', JSON.stringify(Budget))
        fetchParentNode({
          variables: {
            MasterCOABudgetArr: editData?.BudgetItem?.map(x => x?.MasterCOAID),
            CompanyID,
          },
        })
      } else {
        fetchParentNode({
          variables: {
            MasterCOABudgetArr: BudgetItem?.map(x => x?.MasterCOAID),
            CompanyID,
          },
        })
      }

      //// Department Budget Item ////

      if (!!!CostCentreBudgetItem) {
        let CCBudgetItem = []
        editData?.BudgetItem?.map(x => {
          x?.BudgetItemCostCentre?.map(el => {
            CCBudgetItem.push({
              BudgetItemID: el?.BudgetItemID,
              BudgetItemCostCentreID: el?.BudgetItemCostCentreID,
              CostCentreID: el?.CostCentreID,
              MasterCOAID: x?.MasterCOAID,
              CostCentreBudgetAmount: amtStr(el?.BudgetAmt),
              CostCentreCode: el?.CostCentre?.Code,
            })
          })
        })

        localStorage.setItem(
          'CostCentreBudgetItem',
          JSON.stringify(CCBudgetItem)
        )
        setPrevCCBudgetItem(CCBudgetItem)
      }

      //// Period Budget Item ////

      if (!!!PeriodBudgetItem) {
        let PBudgetItem = []
        editData?.BudgetItem?.map(x => {
          x?.BudgetItemCostCentre?.map(el => {
            el?.BudgetItemPeriod?.map(m => {
              PBudgetItem.push({
                BudgetItemCostCentreID: m?.BudgetItemCostCentreID,
                BudgetItemPeriodID: m?.BudgetItemPeriodID,
                AccountPeriodID: m?.AccountPeriodID,
                CostCentreID: el?.CostCentreID,
                MasterCOAID: x?.MasterCOAID,
                PeriodBudgetAmount: amtStr(m?.BudgetAmt),
                Period: m?.AccountPeriod?.FPeriod,
              })
            })
          })
        })

        localStorage.setItem('PeriodBudgetItem', JSON.stringify(PBudgetItem))
        setPrevPeriodBudgetItem(PBudgetItem)
      }
    } else {
      if (!!BudgetItem) {
        fetchParentNode({
          variables: {
            MasterCOABudgetArr: BudgetItem?.map(x => x?.MasterCOAID),
            CompanyID,
          },
        })
      }
    }
  }, [mode])

  ////////////////////////////

  const PassedData = () => {
    let BudgetItemData = []
    const AllMasterCOADAta = treeQuery?.map(x => {
      return {
        BudgetItemID: !!BudgetItem?.find(
          el => el?.MasterCOAID === x?.MasterCOAID
        )
          ? BudgetItem?.find(el => el?.MasterCOAID === x?.MasterCOAID)
              ?.BudgetItemID
          : uuidv4(),
        MasterCOAID: x?.MasterCOAID,
        Code: x?.Code,
        BudgetAmount:
          watch(`BudgetAmountArr[${x?.MasterCOAID}].Amount`) === undefined
            ? BudgetItem?.find(v => v?.MasterCOAID === x?.MasterCOAID)
                ?.BudgetAmount
            : watch(`BudgetAmountArr[${x?.MasterCOAID}].Amount`),
        MasterCOACode: x?.Code,
        MasterCOAName: x?.Name,
      }
    })

    BudgetItemData.push(
      ...AllMasterCOADAta?.filter(x => !!x?.BudgetAmount),
      ...AllMasterCOADAta?.filter(
        x =>
          !x?.BudgetAmount &&
          CostCentreBudgetItem?.filter(
            el => el?.BudgetItemID === x?.BudgetItemID
          )?.length > 0
      )
    )

    return BudgetItemData
  }

  const disableField = (ID, level) => {
    const FirstLevelLength = Number(
      getCOAStructure?.find(v => v.Level === 1).CodeLength
    )
    const theNodeCode = treeQuery
      ?.find(x => x?.MasterCOAID === ID)
      .Code.substring(0, FirstLevelLength)
    const allParentChild = treeQuery?.filter(el =>
      el?.Code.includes(theNodeCode)
    )

    if (!!watch(`BudgetAmountArr`)) {
      const grabData = allParentChild?.filter(
        k => !!watch(`BudgetAmountArr[${k?.MasterCOAID}].Amount`)
      )

      if (grabData?.length > 0) {
        if (grabData[0].Level !== level) return true
        else return false
      }
    }
    return false
  }
  const disableFieldfromlocal = (ID, level) => {
    const FirstLevelLength = Number(
      getCOAStructure?.find(v => v.Level === 1).CodeLength
    )
    const theNodeCode = treeQuery
      ?.find(x => x?.MasterCOAID === ID)
      .Code.substring(0, FirstLevelLength)
    const allParentChild = treeQuery?.filter(
      el => el?.Code.substring(0, FirstLevelLength) === theNodeCode
    )

    if (!!BudgetItem) {
      const grabData = allParentChild?.filter(
        k => !!BudgetItem?.find(x => x?.MasterCOAID === k?.MasterCOAID)
      )
      if (grabData?.length > 0) {
        if (grabData[0].Level !== level) return true
        else return false
      }
    }
    return false
  }

  const parentTotalAmount = node => {
    let arrayIDs = []
    getAllChildID(node, arrayIDs)

    const totalAmt = BudgetItem?.filter(v =>
      arrayIDs.includes(v?.MasterCOAID)
    ).reduce((a, b) => a + Number(amtNumStr(b?.BudgetAmount)), 0)

    if (totalAmt > 0) {
      return totalAmt
    }
  }

  const getAllChildID = (nodes, ids) => {
    nodes?.child?.map(x => ids.push(x?.ID))
    if (nodes?.child?.length > 0) {
      nodes?.child?.map(el => getAllChildID(el, ids))
    }
  }

  const AllBudgetItemChecking = () => {
    const AllBudgetItemData = PassedData()
      ?.filter(x => x?.BudgetAmount !== null)
      ?.map(x => {
        let finalCCBudgetAmt = Number(
          CostCentreBudgetItem?.filter(
            el => el?.BudgetItemID === x?.BudgetItemID
          )
            ?.reduce(
              (a, b) => a + Number(amtNumStr(b?.CostCentreBudgetAmount)),
              0
            )
            .toFixed(2)
        )

        return {
          BudgetItemID: x?.BudgetItemID,
          BudgetAmount: Number(Number(amtNumStr(x?.BudgetAmount)).toFixed(2)),
          TotalCCAmount: finalCCBudgetAmt === 0 ? undefined : finalCCBudgetAmt,
          MasterCOAID: x?.MasterCOAID,
        }
      })
    const CCBudgetItemData = CostCentreBudgetItem?.map(m => {
      let finalPeriodBudgetAmt = Number(
        PeriodBudgetItem?.filter(
          k => k?.BudgetItemCostCentreID === m?.BudgetItemCostCentreID
        )
          ?.reduce(
            (a, b) =>
              a + Number(Number(amtNumStr(b?.PeriodBudgetAmount)).toFixed(2)),
            0
          )
          .toFixed(2)
      )
      return {
        CCBudgetItemID: m?.BudgetItemCostCentreID,
        CCAmount: Number(
          Number(amtNumStr(m?.CostCentreBudgetAmount)).toFixed(2)
        ),
        TotalAPAmount:
          finalPeriodBudgetAmt == 0 ? undefined : finalPeriodBudgetAmt,
        CostCentreID: m?.CostCentreID,
        MasterCOAID: m?.MasterCOAID,
      }
    })

    const ErrorCCItem = AllBudgetItemData?.filter(
      v => v?.TotalCCAmount > v?.BudgetAmount
    )

    const ErrorAPItem = CCBudgetItemData?.filter(
      v => v?.TotalAPAmount > v?.CCAmount
    )

    if (ErrorCCItem?.length > 0) {
      DisplayErrorDialog(ErrorCCItem, 'CC')
      return true
    } else if (ErrorAPItem?.length > 0) {
      DisplayErrorDialog(ErrorAPItem, 'AP')
      return true
    } else return false
  }

  const DisplayErrorDialog = (data, type) => {
    if (type === 'CC') {
      const coaFilter = treeQuery?.filter(
        v => v?.MasterCOAID === data[0].MasterCOAID
      )[0]
      const MasterCOAName = `${coaFilter?.Code} ${coaFilter?.Name}`
      setErrorMsg1(`${MasterCOAName}`)
      setErrorMsg2(`${MasterCOAName} > Department Budget`)
      setErrorDial(true)
    } else if (type === 'AP') {
      const ccFilter = getCostCentre?.filter(
        v => v?.CostCentreID === data[0].CostCentreID
      )[0]
      const coaFilter = treeQuery?.filter(
        v => v?.MasterCOAID === data[0].MasterCOAID
      )[0]
      const CostCentreName = `${ccFilter?.Code} ${ccFilter?.Name}`
      const MasterCOAName = `${coaFilter?.Code} ${coaFilter?.Name}`
      setErrorMsg1(`${MasterCOAName} > ${CostCentreName}`)
      setErrorMsg2(`${MasterCOAName} > ${CostCentreName} > Period Budget`)
      setErrorDial(true)
    }
  }

  const draftFooterOption = {
    name: 'Save As Draft',
    onClick: () => {
      if (
        AllBudgetItemChecking() === false &&
        PassedData()?.filter(x => x?.BudgetAmount !== null)?.length > 0
      ) {
        if (
          CostCentreBudgetItem?.filter(
            x =>
              !updateEmptyBudgetItem(
                PassedData()?.filter(x => x?.BudgetAmount !== null),
                CostCentreBudgetItem,
                PeriodBudgetItem
              )
                ?.CCItem?.map(el => el?.BudgetItemID)
                .includes(x?.BudgetItemID)
          )?.length > 0
        ) {
          setProceedStatus('active')
          setOpenProceedConf(true)
        } else {
          handleSubmit(data => onSubmit(data, ApprovalStatus.Active))()
        }
      } else if (
        !(PassedData()?.filter(x => x?.BudgetAmount !== null)?.length > 0)
      ) {
        setEmptyBudgetErrorDialog(true)
      }
    },

    color: 'primary',
    disabled: handlePermDisabled({
      companyID: CompanyID,
      permEnum: AcctPermission.GeneralLedgerOriginalBudgetDraft,
    }),
  }

  const submitFooterOption = {
    name: 'Verify',
    onClick: () => {
      if (
        AllBudgetItemChecking() === false &&
        PassedData()?.filter(x => x?.BudgetAmount !== null)?.length > 0
      ) {
        if (
          CostCentreBudgetItem?.filter(
            x =>
              !updateEmptyBudgetItem(
                PassedData()?.filter(x => x?.BudgetAmount !== null),
                CostCentreBudgetItem,
                PeriodBudgetItem
              )
                ?.CCItem?.map(el => el?.BudgetItemID)
                .includes(x?.BudgetItemID)
          )?.length > 0
        ) {
          setProceedStatus('active')
          setOpenProceedConf(true)
        } else {
          handleSubmit(data => onSubmit(data, ApprovalStatus.Completed))()
        }
      } else if (
        !(PassedData()?.filter(x => x?.BudgetAmount !== null)?.length > 0)
      ) {
        setEmptyBudgetErrorDialog(true)
      }
    },
    color: 'primary',
    disabled: handlePermDisabled({
      companyID: CompanyID,
      permEnum: AcctPermission.GeneralLedgerOriginalBudgetVerified,
    }),
  }

  const footerOptions: any[] =
    mode === 'add' || mode === 'edit' || mode === 'resubmit'
      ? [draftFooterOption, submitFooterOption]
      : null

  ////TREE VIEW////

  const expandBudgetItemWithChild = () => {
    const MCOAID = BudgetItem?.map(x => x?.MasterCOAID)
    const parentMCOA =
      MCOAID?.length > 0
        ? treeQuery
            ?.filter(x => MCOAID.includes(x?.MasterCOAID))
            ?.map(el => el?.ParentMasterCOAID)
        : null

    const parentIDs = []

    return parentMCOA
  }

  const listing = accountType => (
    <ContentWrapper tabs float>
      {filteredList === undefined || filteredList?.length === 0 ? (
        <EmptyList title="No Record found" subtitle="Add a new record now." />
      ) : (
        <CardContents>
          <TreeView
            className="tree-view-root"
            defaultExpanded={
              mode === 'add' || mode === 'edit' || mode === 'detail'
                ? getParentMasterCOAForTree?.length > 0
                  ? getParentMasterCOAForTree?.map(x => x?.ParentMasterCOAID)
                  : ['root']
                : ['root']
            }
            defaultCollapseIcon={<MinusSquare />}
            defaultExpandIcon={<PlusSquare />}
            defaultEndIcon={<DotIcon />}
            multiSelect
            //expanded={getMasterCOA?.map(x => x?.MasterCOAID)}
            // onNodeToggle={handleToggle}
            // expanded={expanded}
          >
            {filteredList?.filter(x => x?.AccountType === accountType)?.length >
              0 ||
            reRender ||
            watch('BudgetAmountArr') !== undefined ? (
              filteredList
                ?.filter(x => x?.AccountType === accountType)
                .map((el, index) => {
                  return renderTree(el, index)
                })
            ) : (
              <EmptyList
                title="No Record found"
                subtitle="Add a new record now."
              />
            )}
          </TreeView>
        </CardContents>
      )}
    </ContentWrapper>
  )

  /// Remove Empty Budget Amount with SubItem ///
  const [reRender, seRerender] = useState(false)

  const EmptyBudgetItem = () => {
    if (watch(`BudgetAmountArr`) !== undefined) {
      const MasterCOAID = treeQuery
        ?.filter(
          x =>
            watch(`BudgetAmountArr[${x?.MasterCOAID}].Amount`) === '' ||
            watch(`BudgetAmountArr[${x?.MasterCOAID}].Amount`) === '0.00'
        )
        ?.map(el => el?.MasterCOAID)

      const EmptyBudgetItemID = PassedData()
        ?.filter(x => x?.BudgetAmount !== null)
        ?.filter(x => MasterCOAID.includes(x?.MasterCOAID))
        ?.map(el => el?.BudgetItemID)

      const EmptyCostCentreItemID =
        CostCentreBudgetItem?.length > 0
          ? CostCentreBudgetItem.filter(x =>
              EmptyBudgetItemID.includes(x?.BudgetItemID)
            )?.map(el => el?.BudgetItemCostCentreID)
          : []

      const EmptyPeriodItemID =
        PeriodBudgetItem?.length > 0
          ? PeriodBudgetItem.filter(x =>
              EmptyCostCentreItemID.includes(x?.BudgetItemCostCentreID)
            )?.map(el => el?.BudgetItemPeriodID)
          : []

      return {
        BudgetItemID: EmptyBudgetItemID,
        CostCentreBudgetItemID: EmptyCostCentreItemID,
        PeriodBudgetItem: EmptyPeriodItemID,
      }
    } else return null
  }

  //////////////////////////////////////////////

  const onSubmit = (data, status) => {
    if (mode == 'add') {
      if (
        getBudget?.filter(
          x =>
            x?.Fyear === Year &&
            x?.ApprovalStatus !== 'REJECTED' &&
            x?.Version === 'Version 1'
        )?.length > 0
      ) {
        setErrorDialog(true)
      } else {
        createBudget({
          variables: {
            BudgetInput: {
              Fyear: Year,
              CompanyID: CompanyID,

              Version: `Version 1`,

              Description: CompanyID,
              IsDefault: true,
              ApprovalStatus: status,
            },

            BudgetItemInput: PassedData()
              ?.filter(x => x?.BudgetAmount !== null)
              ?.filter(
                x =>
                  !!x?.BudgetAmount &&
                  !EmptyBudgetItem()?.BudgetItemID.includes(x?.BudgetItemID)
              )
              ?.map(x => {
                return {
                  BudgetItemID: x?.BudgetItemID,
                  CompanyID: CompanyID,
                  MasterCOAID: x?.MasterCOAID,
                  BudgetAmt: Number(amtNumStr(x?.BudgetAmount)),
                }
              }),

            BudgetItemCostCentreInput: updateEmptyBudgetItem(
              PassedData()?.filter(x => x?.BudgetAmount !== null),
              CostCentreBudgetItem,
              PeriodBudgetItem
            )
              ?.CCItem?.filter(
                x =>
                  !!x?.CostCentreBudgetAmount &&
                  !EmptyBudgetItem()?.CostCentreBudgetItemID.includes(
                    x?.BudgetItemCostCentreID
                  )
              )
              ?.map(x => {
                return {
                  BudgetItemID: x?.BudgetItemID,
                  BudgetItemCostCentreID: x?.BudgetItemCostCentreID,
                  CostCentreID: x?.CostCentreID,
                  BudgetAmt: Number(amtNumStr(x?.CostCentreBudgetAmount)),
                }
              }),

            BudgetItemPeriodInput: updateEmptyBudgetItem(
              PassedData()?.filter(x => x?.BudgetAmount !== null),
              CostCentreBudgetItem,
              PeriodBudgetItem
            )
              ?.PItem?.filter(
                x =>
                  !!x?.PeriodBudgetAmount &&
                  !EmptyBudgetItem()?.PeriodBudgetItem.includes(
                    x?.BudgetItemPeriodID
                  )
              )
              ?.map(x => {
                return {
                  BudgetItemCostCentreID: x?.BudgetItemCostCentreID,
                  BudgetItemPeriodID: x?.BudgetItemPeriodID,
                  AccountPeriodID: x?.AccountPeriodID,
                  BudgetAmt: Number(amtNumStr(x?.PeriodBudgetAmount)),
                }
              }),
          },
          refetchQueries: [
            {
              query: GetBudgetDocument,
            },
          ],
        })
      }
    }

    if (mode == 'edit') {
      updateNewBudget({
        variables: {
          BudgetInput: {
            BudgetID: editData?.BudgetID,
            Fyear: Year,
            CompanyID: CompanyID,
            Version: editData?.Version,
            Description: CompanyID,
            IsDefault: editData?.IsDefault,
            ApprovalStatus: status,
          },

          BudgetItemInput: PassedData()
            ?.filter(x => x?.BudgetAmount !== null)
            ?.filter(
              x =>
                !!x?.BudgetAmount &&
                !EmptyBudgetItem()?.BudgetItemID.includes(x?.BudgetItemID)
            )
            ?.map(x => {
              return {
                BudgetItemID: x?.BudgetItemID,
                CompanyID: CompanyID,
                MasterCOAID: x?.MasterCOAID,
                BudgetAmt: Number(amtNumStr(x?.BudgetAmount)),
              }
            }),

          BudgetItemCostCentreInput: updateEmptyBudgetItem(
            PassedData()?.filter(x => x?.BudgetAmount !== null),
            CostCentreBudgetItem,
            PeriodBudgetItem
          )
            ?.CCItem?.filter(
              x =>
                !!x?.CostCentreBudgetAmount &&
                !EmptyBudgetItem()?.CostCentreBudgetItemID.includes(
                  x?.BudgetItemCostCentreID
                )
            )
            ?.map(x => {
              return {
                BudgetItemID: x?.BudgetItemID,
                BudgetItemCostCentreID: x?.BudgetItemCostCentreID,
                CostCentreID: x?.CostCentreID,
                BudgetAmt: Number(amtNumStr(x?.CostCentreBudgetAmount)),
              }
            }),

          BudgetItemPeriodInput: updateEmptyBudgetItem(
            PassedData()?.filter(x => x?.BudgetAmount !== null),
            CostCentreBudgetItem,
            PeriodBudgetItem
          )
            ?.PItem?.filter(
              x =>
                !!x?.PeriodBudgetAmount &&
                !EmptyBudgetItem()?.PeriodBudgetItem.includes(
                  x?.BudgetItemPeriodID
                )
            )
            ?.map(x => {
              return {
                BudgetItemCostCentreID: x?.BudgetItemCostCentreID,
                BudgetItemPeriodID: x?.BudgetItemPeriodID,
                AccountPeriodID: x?.AccountPeriodID,
                BudgetAmt: Number(amtNumStr(x?.PeriodBudgetAmount)),
              }
            }),
        },
        refetchQueries: [{ query: GetBudgetDocument }],
      })
    }

    if (mode == 'approve-reject') {
      updateBudget({
        variables: {
          input: {
            BudgetID: editData?.BudgetID,
            Fyear: Year,
            CompanyID: CompanyID,
            Version: editData?.Version,
            Description: CompanyID,
            IsDefault: editData?.IsDefault,
            ApprovalStatus: status,
            RejectionDate:
              status === 'REJECTED' ? new Date().toISOString() : null,
            RejectionRemark: data?.Remark,
          },
        },
        refetchQueries: [{ query: GetBudgetDocument }],
      })
    }
  }

  /////////////////////////////

  const renderTree = (nodes, index) => {
    const BudgetAmountArr = `BudgetAmountArr[${nodes?.ID}]`

    return (
      <>
        <StyledTreeItem
          key={nodes.ID}
          nodeId={nodes.ID}
          label={
            <>
              <Box
                p={0}
                pr={0}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Typography
                  variant="body2"
                  style={{
                    fontWeight: 'inherit',
                    flexGrow: 1,
                    width: '50px',
                  }}
                >
                  <div className="xsTitle text-noflow">{nodes.code}</div>
                  <div className="desc text-noflow"> {nodes.name}</div>

                  {/* <span
              //</Typography>className="xsTitle flex-space"
              >{`${nodes.code} | ${nodes.name}`}</span> */}
                </Typography>

                <Typography variant="caption" color="inherit">
                  {mode === 'approve-reject' || mode === 'detail' ? (
                    <div className="xsTitle">
                      {
                        BudgetItem?.find(x => x?.MasterCOAID === nodes?.ID)
                          ?.BudgetAmount
                      }
                    </div>
                  ) : disableFieldfromlocal(nodes?.ID, nodes?.level) ===
                    true ? (
                    <NumberFormat
                      key={nodes.ID}
                      allowNegative={true}
                      customInput={TextField}
                      decimalScale={2}
                      fixedDecimalScale
                      variant={'filled'}
                      className="qty-input-new-wid"
                      thousandSeparator
                      isNumericString
                      value={parentTotalAmount(nodes)}
                      margin="dense"
                      InputProps={{
                        style: {
                          width: '100px',
                        },
                      }}
                      disabled
                    />
                  ) : (
                    <Controller
                      ref={register({})}
                      name={`${BudgetAmountArr}.Amount`}
                      control={control}
                      onChangeName="onValueChange"
                      defaultValue={
                        !!BudgetItem?.find(x => x?.MasterCOAID === nodes?.ID)
                          ? BudgetItem?.find(x => x?.MasterCOAID === nodes?.ID)
                              ?.BudgetAmount
                          : null
                      }
                      render={({ value, onChange }) => (
                        <NumberFormat
                          key={nodes.ID}
                          allowNegative={true}
                          customInput={TextField}
                          decimalScale={2}
                          fixedDecimalScale
                          variant={
                            disableFieldfromlocal(nodes?.ID, nodes?.level) ===
                            true
                              ? 'filled'
                              : 'outlined'
                          }
                          className="qty-input-new-wid"
                          thousandSeparator
                          isNumericString
                          defaultValue={
                            !!BudgetItem?.find(
                              x => x?.MasterCOAID === nodes?.ID
                            )
                              ? BudgetItem?.find(
                                  x => x?.MasterCOAID === nodes?.ID
                                )?.BudgetAmount
                              : null
                          }
                          //label="Journal Amount *"
                          onValueChange={e => {
                            e.value === '' || e.value === '-'
                              ? setValue(`${BudgetAmountArr}.Amount`, null)
                              : setValue(`${BudgetAmountArr}.Amount`, e.value)

                            localStorage.setItem(
                              'BudgetItem',
                              JSON.stringify(PassedData())
                            )
                          }}
                          margin="dense"
                          InputProps={{
                            style: {
                              width: '100px',
                            },
                          }}
                          disabled={disableFieldfromlocal(
                            nodes?.ID,
                            nodes?.level
                          )}
                        />
                      )}
                    />
                  )}
                </Typography>

                <Typography variant="caption" color="inherit">
                  {(mode === 'approve-reject' || mode === 'detail') &&
                  !BudgetItem?.find(x => x?.MasterCOAID === nodes?.ID)
                    ?.BudgetAmount ? (
                    <IconButton>
                      <div
                        style={{
                          width: '20px',
                          height: '20px',
                          color: 'white',
                          //backgroundColor: 'white',
                          //borderRadius: '3px',
                        }}
                      ></div>
                    </IconButton>
                  ) : (
                    <IconButton
                      className="first-btn"
                      onClick={e => {
                        if (
                          disableFieldfromlocal(nodes?.ID, nodes?.level) ===
                            true ||
                          BudgetItem?.find(
                            x => x?.MasterCOAID === nodes?.ID
                          ) === undefined
                        ) {
                        } else {
                          history.push({
                            pathname: `/general-ledger/${CompanyID}/${'original-budget'}/${
                              nodes?.ID
                            }/${mode}`,
                            state:
                              mode === 'add'
                                ? {
                                    ...nodes,
                                    selectedYear: Year,
                                    budgetAmount: watch(
                                      `${BudgetAmountArr}.Amount`
                                    ),
                                    prevBudgetItem: prevBudgetItem,
                                    prevCCItem:
                                      CostCentreBudgetItem === null
                                        ? []
                                        : CostCentreBudgetItem,
                                    prevPItem:
                                      PeriodBudgetItem === null
                                        ? []
                                        : PeriodBudgetItem,
                                    accType: value,
                                  }
                                : {
                                    ...nodes,
                                    DefaultBudgetData: {
                                      ...editData,
                                      selectedYear: Year,
                                    },
                                    selectedYear: Year,
                                    budgetAmount:
                                      mode === 'approve-reject' ||
                                      mode === 'detail'
                                        ? BudgetItem?.find(
                                            x => x?.MasterCOAID === nodes?.ID
                                          )?.BudgetAmount
                                        : watch(`${BudgetAmountArr}.Amount`),
                                    prevBudgetItem: prevBudgetItem,
                                    prevCCItem:
                                      CostCentreBudgetItem === null
                                        ? []
                                        : CostCentreBudgetItem,
                                    prevPItem:
                                      PeriodBudgetItem === null
                                        ? []
                                        : PeriodBudgetItem,
                                    accType: value,
                                  },
                          })
                          // }
                          // }
                        }
                      }}
                    >
                      <IconItem
                        icon={
                          <MenuIcon
                            style={{
                              width: '20px',
                              height: '20px',
                              color: 'white',
                              backgroundColor:
                                disableFieldfromlocal(
                                  nodes?.ID,
                                  nodes?.level
                                ) === true ||
                                BudgetItem?.find(
                                  x => x?.MasterCOAID === nodes?.ID
                                ) === undefined
                                  ? '#938D8B'
                                  : '#FF9800',
                              borderRadius: '3px',
                            }}
                          />
                        }
                        //imageType="svg"
                        badgeType="number"
                        badgeNumber={
                          CostCentreBudgetItem?.filter(
                            x =>
                              x.BudgetItemID ===
                              BudgetItem?.find(
                                x => x?.MasterCOAID === nodes?.ID
                              )?.BudgetItemID
                          ).length > 0 ? (
                            <span
                              style={{
                                width: '15px',
                                height: '12px',
                                backgroundColor: '#faf2e8',
                                fontSize: '10px',
                                color: 'black',
                                borderRadius: '20px',
                                paddingTop: '3px',
                              }}
                            >
                              {
                                CostCentreBudgetItem?.filter(
                                  x =>
                                    x.BudgetItemID ===
                                    BudgetItem?.find(
                                      x => x?.MasterCOAID === nodes?.ID
                                    )?.BudgetItemID
                                ).length
                              }
                            </span>
                          ) : null
                        }
                      />
                    </IconButton>
                  )}
                </Typography>
              </Box>
            </>
          }
        >
          {Array.isArray(nodes.child)
            ? nodes.child.map((nodes, index) => renderTree(nodes, index))
            : null}
        </StyledTreeItem>
      </>
    )
  }

  const checkBudgetSameAsPrev = () => {
    const prev = JSON.stringify(
      prevBudgetItem?.map(x => {
        return {
          MasterCOAID: x?.MasterCOAID,
          BudgetAmount: x?.BudgetAmount,
        }
      })
    )
    const current = JSON.stringify(
      BudgetItem?.map(x => {
        return {
          MasterCOAID: x?.MasterCOAID,
          BudgetAmount: x?.BudgetAmount,
        }
      })
    )

    if (current === prev) return true
    else return false
  }

  const checkCCSameAsPrev = () => {
    const prev = JSON.stringify(
      prevCCBudgetItem?.map(x => {
        return {
          MasterCOAID: x?.MasterCOAID,
          CostCentreID: x?.CostCentreID,
          CostCentreBudgetAmount: x?.CostCentreBudgetAmount,
        }
      })
    )
    const current = JSON.stringify(
      CostCentreBudgetItem?.map(x => {
        return {
          MasterCOAID: x?.MasterCOAID,
          CostCentreID: x?.CostCentreID,
          CostCentreBudgetAmount: x?.CostCentreBudgetAmount,
        }
      })
    )

    if (current === prev) return true
    else return false
  }

  const checkPeriodSameAsPrev = () => {
    const prev = JSON.stringify(
      prevPeriodBudgetItem?.map(x => {
        return {
          MasterCOAID: x?.MasterCOAID,
          CostCentreID: x?.CostCentreID,
          AccountPeriodID: x?.AccountPeriodID,
          CostCentreBudgetAmount: x?.CostCentreBudgetAmount,
          PeriodBudgetAmount: x?.PeriodBudgetAmount,
        }
      })
    )
    const current = JSON.stringify(
      PeriodBudgetItem?.map(x => {
        return {
          MasterCOAID: x?.MasterCOAID,
          CostCentreID: x?.CostCentreID,
          AccountPeriodID: x?.AccountPeriodID,
          CostCentreBudgetAmount: x?.CostCentreBudgetAmount,
          PeriodBudgetAmount: x?.PeriodBudgetAmount,
        }
      })
    )

    if (current === prev) return true
    else return false
  }

  const updateEmptyBudgetItem = (BItem, CCItem, PItem) => {
    const BItemIDs = BItem?.map(x => x?.BudgetItemID)

    const filteredCCItem = CCItem?.filter(el =>
      BItemIDs.includes(el?.BudgetItemID)
    )

    const CCItemIDs = filteredCCItem?.map(x => x?.BudgetItemCostCentreID)

    const filteredPItem = PItem?.filter(el =>
      CCItemIDs.includes(el?.BudgetItemCostCentreID)
    )

    return {
      CCItem: filteredCCItem,
      PItem: filteredPItem,
    }
    //return filteredCCItem
  }

  const handleSelectYear = date => {
    if (
      getBudget
        ?.filter(el => el?.BudgetID !== editData?.BudgetID)
        ?.find(x => x?.Fyear === new Date(date).getFullYear()) !== undefined
    ) {
      setErrorYear(new Date(date).getFullYear())
      setErrorDialog(true)
      resetFileUpload()
    } else {
      setYear(new Date(date).getFullYear())
    }
  }

  useEffect(() => {
    if (currentData !== null) {
      seRerender(true)
      handleSelectYear(`${currentData?.BudgetYear}-01-01`)
    }
  }, [currentData])

  return (
    <>
      {coaStructureLoading && <Loading />}
      {getParentMasterCOAForTreeLoading && <Loading />}
      {BudgetImportListLoading && <Loading />}
      {updateBudgetWithDetailLoading && <Loading />}
      {ImportExcelBudgetLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() => {
          if (mode === 'add') {
            if (
              BudgetItem?.length > 0 ||
              CostCentreBudgetItem?.length > 0 ||
              PeriodBudgetItem?.length > 0
            ) {
              setOpenExitConf(true)
            } else {
              localStorage.removeItem('BudgetItem')
              localStorage.removeItem('PeriodBudgetItem')
              localStorage.removeItem('CostCentreBudgetItem')
              history.push(`/general-ledger/${CompanyID}/original-budget`)
            }
          } else {
            if (
              checkBudgetSameAsPrev() === false ||
              checkCCSameAsPrev() === false ||
              checkPeriodSameAsPrev() === false
            ) {
              setOpenExitConf(true)
            } else {
              localStorage.removeItem('BudgetItem')
              localStorage.removeItem('PeriodBudgetItem')
              localStorage.removeItem('CostCentreBudgetItem')
              history.push(`/general-ledger/${CompanyID}/original-budget`)
            }
          }
        }}
        smTitle="General Ledger"
        title={user?.companyName}
        routeSegments={[
          { name: 'Home Journal' },
          { name: 'Original Budget Listing' },
          { name: 'Original Budget', current: true },
        ]}
        rightRouteSegments={[
          {
            name: mode === 'add' ? 'New' : 'Edit',
            current: true,
          },
        ]}
      />

      {mode === 'approve-reject' || mode === 'detail' ? (
        <DynamicSubHeader
          title={
            <IconText
              icon={<EventIcon style={{ width: '14px' }} />}
              children={Year}
            />
          }
          rightText={
            <Button
              style={{
                width: '0px',
                height: '20px',
                paddingRight: 'initial',
              }}
              variant="contained"
              color="primary"
              disabled={disableButton}
              onClick={() => {
                fetchBudgetImportList({
                  variables: {
                    CompanyID,
                    PeriodBudgetItemInput: PeriodBudgetItem?.map(x => {
                      return {
                        AccountPeriodID: x?.AccountPeriodID,
                        MasterCOAID: x?.MasterCOAID,
                        CostCentreID: x?.CostCentreID,
                        PeriodBudgetAmount: Number(
                          x?.PeriodBudgetAmount.replace(/,/g, '')
                        ),
                        Period: x?.Period,
                      }
                    }),
                  },
                })
              }}
              form="submit-form"
              className="action-btn"
              type="submit"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                style={{
                  height: '13px',
                  width: '13px',
                  paddingRight: '3px',
                  fill: '#FFFFFF',
                  marginLeft: '-23px',
                }}
              >
                {' '}
                <path d="M 11.033203 3.2207031 C 10.958186 3.2183086 10.882062 3.2229062 10.804688 3.2382812 L 2.8046875 4.8398438 C 2.3366875 4.9338437 2 5.3433125 2 5.8203125 L 2 18.179688 C 2 18.656688 2.3366875 19.066156 2.8046875 19.160156 L 10.804688 20.761719 C 11.423687 20.885719 12 20.410297 12 19.779297 L 12 4.2207031 C 12 3.6685781 11.558326 3.2374648 11.033203 3.2207031 z M 14 5 L 14 7 L 16 7 L 16 9 L 14 9 L 14 11 L 16 11 L 16 13 L 14 13 L 14 15 L 16 15 L 16 17 L 14 17 L 14 19 L 21 19 C 21.552 19 22 18.552 22 18 L 22 6 C 22 5.448 21.552 5 21 5 L 14 5 z M 18 7 L 20 7 L 20 9 L 18 9 L 18 7 z M 5.4375 8.296875 C 5.7495 8.296875 6.0323438 8.4854375 6.1523438 8.7734375 L 6.8769531 10.511719 C 6.9519531 10.692719 7.0084063 10.902625 7.0664062 11.140625 L 7.0917969 11.140625 C 7.1247969 10.997625 7.1919688 10.779141 7.2929688 10.494141 L 8.1328125 8.703125 C 8.2488125 8.455125 8.4974844 8.296875 8.7714844 8.296875 C 9.3034844 8.296875 9.6444844 8.8620312 9.3964844 9.3320312 L 8.0078125 11.966797 L 9.4140625 14.599609 C 9.6780625 15.099609 9.3179531 15.703125 8.7519531 15.703125 C 8.4599531 15.703125 8.1923125 15.533578 8.0703125 15.267578 L 7.1582031 13.287109 C 7.1162031 13.202109 7.0663906 13.032922 7.0253906 12.794922 L 7.0097656 12.794922 C 6.9847656 12.908922 6.934375 13.079594 6.859375 13.308594 L 5.9277344 15.296875 C 5.8117344 15.544875 5.5611094 15.703125 5.2871094 15.703125 L 5.2011719 15.703125 C 4.6621719 15.703125 4.3210312 15.123344 4.5820312 14.652344 L 6.0605469 11.994141 L 4.7480469 9.421875 C 4.4860469 8.906875 4.8595 8.296875 5.4375 8.296875 z M 18 11 L 20 11 L 20 13 L 18 13 L 18 11 z M 18 15 L 20 15 L 20 17 L 18 17 L 18 15 z"></path>
              </svg>
              <span
                style={{
                  fontSize: '10px',
                  fontWeight: 400,
                  marginRight: '-7px',
                }}
              >
                Export
              </span>
            </Button>
          }
        />
      ) : (
        <SubHeaderAction
          // classname="small-box"
          style={{
            top: isDesktop ? '64px' : null,
            position: isDesktop ? 'relative' : null,
          }}
          title={
            <Controller
              as={KeyboardDatePicker}
              required
              onChange={(date: Date | null) => {
                if (Year === getBudget?.map(x => x?.Fyear)) {
                  setErrorDialog(true)
                }
              }}
              views={['year']}
              name="Year"
              control={control}
              className="test-class"
              format="yyyy"
              value={Year}
              inputValue={Year}
              onAccept={(date: Date | null) => {
                handleSelectYear(date)
              }}
              allowKeyboardControl
              autoComplete="off"
              fullWidth
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              keyboardIcon={
                <ArrowDropDownIcon
                  style={{
                    marginRight: '-15px',
                    width: '17px',
                    height: '17px',
                  }}
                />
              }
              margin="dense"
              inputVariant="outlined"
              InputProps={{
                style: {
                  fontSize: '10px',
                  color: 'black',
                  height: '22px',
                  width: '100px',
                  backgroundColor: 'white',
                },
                startAdornment: (
                  <>
                    <EventIcon
                      style={{
                        marginLeft: '-8px',
                        width: '14.3px',
                        height: '18.3px',
                      }}
                    />
                    &nbsp;&nbsp;
                  </>
                ),
              }}
            />
          }
          noButton={true}
          rightTitle={
            <>
              <Button
                style={{
                  width: '0px',
                  height: '20px',
                  paddingRight: 'initial',
                  marginRight: '10px',
                }}
                variant="contained"
                color="primary"
                onClick={() => {
                  setOpenFileUpload(true)
                }}
                form="submit-form"
                className="action-btn"
                type="submit"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  style={{
                    height: '13px',
                    width: '13px',
                    paddingRight: '3px',
                    fill: '#FFFFFF',
                    marginLeft: '-23px',
                  }}
                >
                  {' '}
                  <path d="M 11.033203 3.2207031 C 10.958186 3.2183086 10.882062 3.2229062 10.804688 3.2382812 L 2.8046875 4.8398438 C 2.3366875 4.9338437 2 5.3433125 2 5.8203125 L 2 18.179688 C 2 18.656688 2.3366875 19.066156 2.8046875 19.160156 L 10.804688 20.761719 C 11.423687 20.885719 12 20.410297 12 19.779297 L 12 4.2207031 C 12 3.6685781 11.558326 3.2374648 11.033203 3.2207031 z M 14 5 L 14 7 L 16 7 L 16 9 L 14 9 L 14 11 L 16 11 L 16 13 L 14 13 L 14 15 L 16 15 L 16 17 L 14 17 L 14 19 L 21 19 C 21.552 19 22 18.552 22 18 L 22 6 C 22 5.448 21.552 5 21 5 L 14 5 z M 18 7 L 20 7 L 20 9 L 18 9 L 18 7 z M 5.4375 8.296875 C 5.7495 8.296875 6.0323438 8.4854375 6.1523438 8.7734375 L 6.8769531 10.511719 C 6.9519531 10.692719 7.0084063 10.902625 7.0664062 11.140625 L 7.0917969 11.140625 C 7.1247969 10.997625 7.1919688 10.779141 7.2929688 10.494141 L 8.1328125 8.703125 C 8.2488125 8.455125 8.4974844 8.296875 8.7714844 8.296875 C 9.3034844 8.296875 9.6444844 8.8620312 9.3964844 9.3320312 L 8.0078125 11.966797 L 9.4140625 14.599609 C 9.6780625 15.099609 9.3179531 15.703125 8.7519531 15.703125 C 8.4599531 15.703125 8.1923125 15.533578 8.0703125 15.267578 L 7.1582031 13.287109 C 7.1162031 13.202109 7.0663906 13.032922 7.0253906 12.794922 L 7.0097656 12.794922 C 6.9847656 12.908922 6.934375 13.079594 6.859375 13.308594 L 5.9277344 15.296875 C 5.8117344 15.544875 5.5611094 15.703125 5.2871094 15.703125 L 5.2011719 15.703125 C 4.6621719 15.703125 4.3210312 15.123344 4.5820312 14.652344 L 6.0605469 11.994141 L 4.7480469 9.421875 C 4.4860469 8.906875 4.8595 8.296875 5.4375 8.296875 z M 18 11 L 20 11 L 20 13 L 18 13 L 18 11 z M 18 15 L 20 15 L 20 17 L 18 17 L 18 15 z"></path>
                </svg>
                <span
                  style={{
                    fontSize: '10px',
                    fontWeight: 400,
                    marginRight: '-7px',
                  }}
                >
                  Import
                </span>
              </Button>
              <Button
                style={{
                  width: '0px',
                  height: '20px',
                  paddingRight: 'initial',
                }}
                variant="contained"
                color="primary"
                disabled={disableButton}
                onClick={() => {
                  fetchBudgetImportList({
                    variables: {
                      CompanyID,
                      PeriodBudgetItemInput: PeriodBudgetItem?.map(x => {
                        return {
                          AccountPeriodID: x?.AccountPeriodID,
                          MasterCOAID: x?.MasterCOAID,
                          CostCentreID: x?.CostCentreID,
                          PeriodBudgetAmount: Number(
                            x?.PeriodBudgetAmount.replace(/,/g, '')
                          ),
                          Period: x?.Period,
                        }
                      }),
                    },
                  })
                }}
                form="submit-form"
                className="action-btn"
                type="submit"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  style={{
                    height: '13px',
                    width: '13px',
                    paddingRight: '3px',
                    fill: '#FFFFFF',
                    marginLeft: '-23px',
                  }}
                >
                  {' '}
                  <path d="M 11.033203 3.2207031 C 10.958186 3.2183086 10.882062 3.2229062 10.804688 3.2382812 L 2.8046875 4.8398438 C 2.3366875 4.9338437 2 5.3433125 2 5.8203125 L 2 18.179688 C 2 18.656688 2.3366875 19.066156 2.8046875 19.160156 L 10.804688 20.761719 C 11.423687 20.885719 12 20.410297 12 19.779297 L 12 4.2207031 C 12 3.6685781 11.558326 3.2374648 11.033203 3.2207031 z M 14 5 L 14 7 L 16 7 L 16 9 L 14 9 L 14 11 L 16 11 L 16 13 L 14 13 L 14 15 L 16 15 L 16 17 L 14 17 L 14 19 L 21 19 C 21.552 19 22 18.552 22 18 L 22 6 C 22 5.448 21.552 5 21 5 L 14 5 z M 18 7 L 20 7 L 20 9 L 18 9 L 18 7 z M 5.4375 8.296875 C 5.7495 8.296875 6.0323438 8.4854375 6.1523438 8.7734375 L 6.8769531 10.511719 C 6.9519531 10.692719 7.0084063 10.902625 7.0664062 11.140625 L 7.0917969 11.140625 C 7.1247969 10.997625 7.1919688 10.779141 7.2929688 10.494141 L 8.1328125 8.703125 C 8.2488125 8.455125 8.4974844 8.296875 8.7714844 8.296875 C 9.3034844 8.296875 9.6444844 8.8620312 9.3964844 9.3320312 L 8.0078125 11.966797 L 9.4140625 14.599609 C 9.6780625 15.099609 9.3179531 15.703125 8.7519531 15.703125 C 8.4599531 15.703125 8.1923125 15.533578 8.0703125 15.267578 L 7.1582031 13.287109 C 7.1162031 13.202109 7.0663906 13.032922 7.0253906 12.794922 L 7.0097656 12.794922 C 6.9847656 12.908922 6.934375 13.079594 6.859375 13.308594 L 5.9277344 15.296875 C 5.8117344 15.544875 5.5611094 15.703125 5.2871094 15.703125 L 5.2011719 15.703125 C 4.6621719 15.703125 4.3210312 15.123344 4.5820312 14.652344 L 6.0605469 11.994141 L 4.7480469 9.421875 C 4.4860469 8.906875 4.8595 8.296875 5.4375 8.296875 z M 18 11 L 20 11 L 20 13 L 18 13 L 18 11 z M 18 15 L 20 15 L 20 17 L 18 17 L 18 15 z"></path>
                </svg>
                <span
                  style={{
                    fontSize: '10px',
                    fontWeight: 400,
                    marginRight: '-7px',
                  }}
                >
                  Export
                </span>
              </Button>
            </>
          }
        />
      )}

      <StandardTabs
        value={value}
        onChange={handleChange}
        responsive
        subHeader
        dynamicInfo={mode === 'approve-reject' || mode === 'detail'}
        fullWidth
        tabs={[
          {
            label: 'Revenue',
            content:
              !BudgetMasterCOALoading &&
              !getParentMasterCOAForTreeLoading &&
              !coaStructureLoading &&
              listing('REVENUE'),
          },
          {
            label: 'Expenses',
            content:
              !BudgetMasterCOALoading &&
              !getParentMasterCOAForTreeLoading &&
              !coaStructureLoading &&
              listing('EXPENSE'),
          },
          {
            label: 'Asset',
            content:
              !BudgetMasterCOALoading &&
              !getParentMasterCOAForTreeLoading &&
              !coaStructureLoading &&
              listing('ASSET'),
          },
          {
            label: 'Liability',
            content:
              !BudgetMasterCOALoading &&
              !getParentMasterCOAForTreeLoading &&
              !coaStructureLoading &&
              listing('LIABILITY'),
          },
          {
            label: 'Equity',
            content:
              !BudgetMasterCOALoading &&
              !getParentMasterCOAForTreeLoading &&
              !coaStructureLoading &&
              listing('EQUITY'),
          },
        ]}
      />

      {mode === 'detail' ? null : ( // || mode === 'approve-reject'
        <AccountFooter options={[...footerOptions]} />
      )}

      <CommonDialog
        fullWidth={true}
        open={openRejectDialog}
        onClose={() => setOpenRejectDialog(false)}
        sections={{
          header: {
            children: (
              <ListItem className="remove-padding">
                <ListItemText
                  primary={
                    <>
                      <span className="smTitle flex-space">
                        {'Rejection Remarks'}
                      </span>
                    </>
                  }
                />
              </ListItem>
            ),
          },
          body: () => (
            <Controller
              as={TextField}
              //className="left"
              id="standard-basic"
              name="Remark"
              label="Remarks"
              autoComplete="off"
              control={control}
              fullWidth
              margin="dense"
              helperText={errors?.Remarks?.message}
              error={errors?.Remarks ? true : false}
              inputRef={register({})}
              required
              defaultValue={editData?.Remark}
            />
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => setOpenRejectDialog(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () =>
                    handleSubmit(data =>
                      onSubmit(data, ApprovalStatus.Rejected)
                    )(),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <CommonDialog
        fullWidth={true}
        open={errorDialog}
        onClose={() => setErrorDialog(false)}
        sections={{
          header: {
            children: (
              <ListItem className="remove-padding">
                <ListItemText
                  primary={
                    <>
                      <span
                        className="smTitle flex-space"
                        style={{ color: 'red' }}
                      >
                        {'Error!'}
                      </span>
                    </>
                  }
                />
              </ListItem>
            ),
          },
          body: () => (
            <div>
              <span>Original Budget for year </span>
              <span
                style={{
                  fontWeight: 'bold',
                }}
              >
                {errorYear}{' '}
              </span>
              <span>has been created!</span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => setErrorDialog(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
      <CommonDialog
        fullWidth={true}
        open={emptyBudgetErrorDialog}
        onClose={() => setEmptyBudgetErrorDialog(false)}
        sections={{
          header: {
            children: (
              <ListItem className="remove-padding">
                <ListItemText
                  primary={
                    <>
                      <span
                        className="smTitle flex-space"
                        style={{ color: 'red' }}
                      >
                        {'Error!'}
                      </span>
                    </>
                  }
                />
              </ListItem>
            ),
          },
          body: () => (
            <div>
              <span>Budget cannot be empty</span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => setEmptyBudgetErrorDialog(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      {/* <CommonDialog
        fullWidth={true}
        open={excelImport}
        onClose={() => setExcelImport(false)}
        sections={{
          header: {
            children: (
              <ListItem className="remove-padding">
                <ListItemText
                  primary={
                    <>
                      <span className="smTitle flex-space">
                        {'Import Excel'}
                      </span>
                    </>
                  }
                />
              </ListItem>
            ),
          },
          body: () => (
            <InputUpload
              accept={'.xlsx, .xls, .cxv'}
              value={fileTitle}
              label="File"
              required
              multiline={true}
              handleUpload={e => onSelectFile(e)}
              className="p-b-20"
            />
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => setExcelImport(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Download',
                props: {
                  onClick: () =>
                    onDownloadTemplateBudget({
                      name: `Original Budget SpreadSheet`,
                      workSheetName: `Budget List`,
                      headerSetup: OriginalBudgetTemplateForm,
                    }),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => setExcelImport(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      /> */}

      <GLBudgetExcelInput
        showDupErrors={true}
        openUpload={openFileUpload}
        setOpenUpload={setOpenFileUpload}
        upload={fileTitle}
        template={currentData}
        setTemplate={setCurrentData}
        setUpload={setFileTitle}
        resetFileUpload={resetFileUpload}
        onUploadTemplate={() => {
          fetchImportExcelBudget({
            variables: {
              CompanyID: CompanyID,
              Year: Year,
              BudgetexcelImportInput: JSON.stringify(currentData?.ReportData),
            },
          })
          setOpenFileUpload(false)
          resetFileUpload()
          setFileTitle('')
        }}
        onSelectFile={(e: any) => {
          onSelectFile({ event: e })
        }}
        onDownloadTemplate={() => {}}
        snackBarProps={{
          openSnackBar: openSnackBar,
          snackBarMessage: snackBarMessage,
          setOpenSnackBar: setOpenSnackBar,
          onClickButton: () => {
            setOpenSnackBar(false)
          },
        }}
        GLBudgetExcelYear={Year}
        setGLBudgetExcelYear={setYear}
        handleSelectYear={handleSelectYear}
      />
      <CommonDialog
        fullWidth={true}
        open={errorDial}
        onClose={() => setErrorDial(false)}
        sections={{
          header: {
            children: (
              <ListItem className="remove-padding">
                <ListItemText
                  primary={
                    <>
                      <span
                        className="smTitle flex-space"
                        style={{ color: 'red' }}
                      >
                        {'Error!'}
                      </span>
                    </>
                  }
                />
              </ListItem>
            ),
          },
          body: () => (
            <>
              <span>Amount in </span>
              <span style={{ fontWeight: 'bold' }}> {errorMsg2} </span>
              <span>cannot exceed </span>
              <span style={{ fontWeight: 'bold' }}> {errorMsg1} </span>
              <span>amount </span>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => setErrorDial(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          localStorage.removeItem('BudgetItem')
          localStorage.removeItem('PeriodBudgetItem')
          localStorage.removeItem('CostCentreBudgetItem')
          history.push(`/general-ledger/${CompanyID}/original-budget`)
        }}
        //hasInfo={hasInfo}
      />

      <CommonDialog
        fullWidth={true}
        open={openProceedConf}
        onClose={() => setOpenProceedConf(false)}
        sections={{
          header: {
            children: (
              <ListItem className="remove-padding">
                <ListItemText
                  primary={
                    <>
                      <span
                        className="smTitle flex-space"
                        style={{ color: '#ffb11f' }}
                      >
                        {'Proceed Confirmation'}
                      </span>
                    </>
                  }
                />
              </ListItem>
            ),
          },
          body: () => (
            <div>
              <span>
                {
                  'Are you sure you want to Proceed? Leaving Budget with empty value will delete all the Department Budget and Period Budget for the respective Budget.'
                }
              </span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => setOpenProceedConf(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    if (
                      PassedData()?.filter(x => x?.BudgetAmount !== null)
                        ?.length > 0
                    ) {
                      localStorage.setItem(
                        'CostCentreBudgetItem',
                        JSON.stringify(
                          updateEmptyBudgetItem(
                            PassedData()?.filter(x => x?.BudgetAmount !== null),
                            CostCentreBudgetItem,
                            PeriodBudgetItem
                          )?.CCItem
                        )
                      )

                      localStorage.setItem(
                        'PeriodBudgetItem',
                        JSON.stringify(
                          updateEmptyBudgetItem(
                            PassedData()?.filter(x => x?.BudgetAmount !== null),
                            CostCentreBudgetItem,
                            PeriodBudgetItem
                          )?.PItem
                        )
                      )

                      if (proceedStatus === 'active') {
                        handleSubmit(data =>
                          onSubmit(data, ApprovalStatus.Active)
                        )()
                      } else if (proceedStatus === 'completed') {
                        handleSubmit(data =>
                          onSubmit(data, ApprovalStatus.Completed)
                        )()
                      }
                    } else {
                      setOpenProceedConf(false)
                      setEmptyBudgetErrorDialog(true)
                    }
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
    </>
  )
}
