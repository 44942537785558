import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize';
import { formatDate } from '@ifca-root/react-component/src/helpers/StringNumberFunction/formatDate';
import { format } from 'date-fns';
import React from 'react';

export const FooterPR = (props: any) => {
  const [width] = useMediaQuery();

  const { data, getUser } = props;

  let fontSize;
  if (width < 769) {
    fontSize = 6;
  } else {
    fontSize = 9;
  }
  return (
    <>
      <div
        style={{
          marginLeft: '10px',
          marginRight: '10px',
          marginBottom: '10px',
        }}
      >
        <table
          style={{
            marginTop: '20px',
            marginBottom: '20px',
            width: '100%',
            fontSize,
          }}
        >
          <tr style={{ display: 'flex' }}>
            <th
              style={{
                fontWeight: 'normal',
                textAlign: 'left',
                width: '25%',
              }}
            >
              <div
                style={{
                  marginRight: '15px',
                }}
              >
                <span>
                  <b>Requested By :</b>
                  <br />
                  <br />
                  {data?.ApprovalWorkflowPR[0]?.Name ?? ''}
                  <br />
                  <b>Date: </b>
                  {formatDate(data?.createdTs)}
                </span>
              </div>
            </th>

            {!!data?.approvedBy ? (
              <th
                style={{
                  fontWeight: 'normal',
                  textAlign: 'left',
                  width: '50%',
                }}
              >
                <div
                  style={{
                    marginRight: 'auto',
                    marginLeft: 'auto',
                    marginTop: '-1px',
                  }}
                >
                  <span>
                    <b>Verified By :</b>
                    <br />
                    <br />
                    {data?.ApprovalWorkflowPR?.length > 0 ? (
                      data?.ApprovalWorkflowPR[0]?.WorkflowStep?.filter(x => {
                        return x?.ApprovalStatus === 'APPROVED';
                      })?.map((x, i) => (
                        <div
                          style={{
                            width: '50%',
                            marginBottom: '10px',
                            float: 'left',
                          }}
                        >
                          {x?.Name}
                          <br />
                          <b>Date: </b>
                          {formatDate(x?.ApprovalDT)}
                        </div>
                      ))
                    ) : (
                      <>
                        {getUser
                          ?.filter(v => v?.ID === data?.approvedBy)
                          .map(v => {
                            return v?.name;
                          })}
                        <br />
                        <b>Date: </b>
                        {formatDate(data?.approvedTs)}
                      </>
                    )}
                  </span>
                </div>
              </th>
            ) : (
              <th
                style={{
                  fontWeight: 'normal',
                  textAlign: 'left',
                  width: '33%',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                }}
              ></th>
            )}

            <th
              style={{
                fontWeight: 'normal',
                textAlign: 'left',
                width: '25%',
              }}
            >
              <div
                style={{
                  marginLeft: '15px',
                }}
              >
                <span>
                  <b>Authorized Signatory: </b>
                  <br />
                  <br />
                  <br />
                  <b>Date: </b>
                </span>
              </div>
            </th>
          </tr>
        </table>
      </div>
    </>
  );
};

export default FooterPR;
