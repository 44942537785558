import { yupResolver } from '@hookform/resolvers'
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  AcctPermission,
  GetReportSettingsDocument,
  useCreateReportSettingsMutation,
  useDeleteReportSettingsMutation,
  useDesignReportLazyQuery,
  useGetReportSettingsQuery,
  useUpdateReportSettingsMutation,
  useUploadReportTemplateMutation,
} from 'generated/graphql'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import * as yup from 'yup'

export interface ReportSettingsProps {
  name: string
  companyID: string
  FPeriod: Date
  FilterZero: Boolean
}

export const ReportSettingsListing = (props: any) => {
  let history = useHistory()
  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()
  const {
    anchorEl,
    menu,
    handleClick,
    handleClose,
    resetMenu,
  } = useMenuOption()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [open, setOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [deleteDia, setDeleteDia] = useState(null)
  const [openUpload, setOpenUpload] = useState(false)
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const { handlePermDisabled } = usePermissionChecker()

  const reportSettingsSchema = yup.object().shape({
    name: CommonYupValidation.requireField(SystemMsgs.name()),
  })

  const {
    files,
    setFiles,
    setPreviewFiles,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment()

  const {
    handleSubmit,
    control,
    register,
    setValue,
    watch,
    setError,
    clearErrors,
    reset,
    errors,
  } = useForm<ReportSettingsProps>({
    resolver: yupResolver(reportSettingsSchema),
  })

  const {
    loading: reportSettingsLoading,
    error: reportSettingsError,
    data: { getReportSettings } = {
      getReportSettings: [],
    },
  } = useGetReportSettingsQuery({
    fetchPolicy: 'network-only',
    variables: {
      orderByAsc: 'Name',
    },
  })

  useEffect(() => {
    if (getReportSettings && getReportSettings?.length > 0) {
      setOriginalListing(getReportSettings)
    }
  }, [getReportSettings])

  const [
    createReportSettings,
    { loading: createReportSettingsLoading, error: createReportSettingsError },
  ] = useCreateReportSettingsMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: () => {
      setOpen(false)
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
    },
  })

  const [
    updateReportSettings,
    { loading: updateReportSettingsLoading, error: updateReportSettingsError },
  ] = useUpdateReportSettingsMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: () => {
      setOpen(false)
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
    },
  })

  const [
    deleteReportSettings,
    { loading: deleteReportSettingsLoading, error: deleteReportSettingsError },
  ] = useDeleteReportSettingsMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: () => {
      setDeleteDia(false)
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
    },
  })

  const handleDelete = () => {
    deleteReportSettings({
      variables: {
        input: { ReportSettingsID: menu?.obj?.ReportSettingsID },
      },
      refetchQueries: [
        {
          query: GetReportSettingsDocument,
          variables: { orderByAsc: 'Name' },
        },
      ],
    })
  }

  const [
    uploadReport,
    { loading: uploadReportLoading, error: uploadReportError },
  ] = useUploadReportTemplateMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: ({ uploadReportTemplate }) => {
      if (uploadReportTemplate === true) {
        setOpenUpload(false)
        setFiles([])
        setOpenSnackBar(true)
        setSnackBarMsg('Excel uploaded')
      }
    },
  })

  const handleCreateUpdate = isEdit
    ? updateReportSettings
    : createReportSettings
  const onSubmit = (data, e) => {
    handleCreateUpdate({
      variables: {
        input: {
          ...(isEdit
            ? { ReportSettingsID: menu?.obj?.ReportSettingsID }
            : null),
          Name: data?.name,
        },
        // JournalProcessingItemID
      },
      refetchQueries: [
        {
          query: GetReportSettingsDocument,
          variables: { orderByAsc: 'Name' },
        },
      ],
    })
  }

  const downloadUploadedExcel = async (filename, fileurl) => {
    if (fileurl) {
      let response = await fetch(fileurl)
      let data = await response.blob()
      let file = new File([data], filename)
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(file)
      link.download = `${filename}`
      link.click()
    } else {
      setOpenSnackBar(true)
      setSnackBarMsg('No uploaded file')
    }
  }

  const onUpload = (id, file) => {
    uploadReport({
      variables: {
        ReportSettingsID: id,
        file: file,
      },
    })
  }

  return (
    <>
      {uploadReportLoading && <Loading />}
      {reportSettingsLoading && <Loading />}
      {createReportSettingsLoading && <Loading />}
      {updateReportSettingsLoading && <Loading />}
      {deleteReportSettingsLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/general-ledger/financial-reporting`)
        }} //<- back button action
        smTitle="General Ledger"
        title={user?.accountName}
        routeSegments={[
          { name: 'Financial Reporting' },
          { name: 'Design Financial Reports', current: true },
        ]}
      />
      <SearchHeader
        title="Reports Listing"
        value={`${filteredList?.length}`}
        search
        fixed
        onChangeAction={e => {
          handleSearch(e?.target?.value, ['Name', 'Code', 'Description'])
        }}
        onCloseAction={() => handleSearch('', [])}
      />
      <ContentWrapper singleDynamicInfo float>
        <List className="core-list">
          {filteredList === undefined || filteredList?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            filteredList?.map((el, index) => {
              return (
                <ListItem>
                  <ListItemText
                    primary={
                      <span className="flex-space mdLabel">{el?.Name}</span>
                    }
                    secondary={
                      <span className="desc flex-space">
                        {`Last updated on ${formatDate(el.createdTs)}`}
                      </span>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      aria-controls="menu-list"
                      aria-haspopup="true"
                      onClick={e => {
                        handleClick(e, el?.ReportSettingsID, index, el)
                      }}
                    >
                      <MoreVert />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })
          )}
        </List>
        <FloatButton
          disabled={handlePermDisabled({
            permEnum:
              AcctPermission.GeneralLedgerFinancialReportSettingsReportFormatCreate,
          })}
          onClick={() => {
            resetMenu()
            setOpen(true)
          }}
        />
      </ContentWrapper>

      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <>
          <MenuItem
            disabled={handlePermDisabled({
              permEnum:
                AcctPermission.GeneralLedgerFinancialReportSettingsReportFormatUpdate,
            })}
            onClick={() => {
              setIsEdit(true)
              setOpen(true)
            }}
          >
            <span className="">Edit</span>
          </MenuItem>
          <MenuItem
            disabled={handlePermDisabled({
              permEnum:
                AcctPermission.GeneralLedgerFinancialReportSettingsReportFormatUpload,
            })}
            onClick={() => {
              setOpenUpload(true)
            }}
          >
            <span className="">Upload</span>
          </MenuItem>
          <MenuItem
            disabled={handlePermDisabled({
              permEnum:
                AcctPermission.GeneralLedgerFinancialReportSettingsReportFormatDownload,
            })}
            onClick={() => {
              downloadUploadedExcel(menu?.obj?.FileName, menu?.obj.FileURL)
            }}
          >
            <span className="">Download</span>
          </MenuItem>

          <MenuItem
            disabled={handlePermDisabled({
              permEnum:
                AcctPermission.GeneralLedgerFinancialReportSettingsReportFormatDelete,
            })}
            onClick={() => {
              // alert('Delete')
              setDeleteDia(true)
            }}
          >
            <span className="">Delete</span>
          </MenuItem>
        </>
      </Menu>

      {/* NEW & EDIT DIALOG */}
      <CommonDialog
        open={open}
        fullWidth={true}
        onClose={() => {
          setOpen(false)
          setIsEdit(false)
        }}
        sections={{
          header: {
            title: 'Design Financial Reports',
            rightText: menu?.obj?.ReportSettingsID ? 'Edit' : 'New',
            infoLine: menu?.obj?.Name,
          },
          body: () => (
            <>
              <div className="content-container">
                <Controller
                  as={TextField}
                  name="name"
                  label="Name"
                  autoComplete="off"
                  multiline={true}
                  fullWidth
                  ref={register}
                  control={control}
                  required
                  defaultValue={menu?.obj?.Name}
                  helperText={errors?.name?.message}
                  error={errors?.name ? true : false}
                />
              </div>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    setOpen(false)
                    setIsEdit(false)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => handleSubmit(onSubmit)(),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <CommonDialog
        fullWidth={true}
        open={openUpload}
        onClose={() => {
          setOpenUpload(false)
          setFiles([])
        }}
        sections={{
          header: {
            dynamic: (
              <div className="">
                <div className="dialog-dynamic-content">
                  <span
                    className="title c-orange flex-space"
                    style={{
                      fontSize: '13px',
                      fontWeight: 'bold',
                    }}
                  >
                    Excel (.xlsx)
                  </span>

                  <span
                    className="title c-orange"
                    style={{
                      fontSize: '13px',
                      fontWeight: 'bold',
                      textAlign: 'right',
                    }}
                  >
                    {'Upload'}
                  </span>
                </div>
              </div>
            ),
            title: menu?.obj?.Name,
          },
          body: () => (
            <>
              <div style={{ width: '100%', marginTop: '24px' }}>
                <FileUploadInput
                  // placeholder={previewFiles.length === 0 ? 'Attachment' : null}
                  label={'Excel Upload'}
                  name="Excel Upload"
                  files={files}
                  onHandleUploadChange={handleUploadChange}
                  accept={'.xlsx'}
                  singleFile
                  singleFileClear={setFiles}
                />
              </div>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    setOpenUpload(false)
                    setFiles([])
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Upload',
                props: {
                  onClick: () => {
                    onUpload(menu?.obj?.ReportSettingsID, files[0])
                  },
                  variant: 'contained',
                  color: 'primary',
                  disabled: !!files[0] ? false : true,
                },
              },
            ],
          },
        }}
      />

      <CommonDialog
        fullWidth={true}
        open={deleteDia}
        onClose={() => setDeleteDia(false)}
        sections={{
          header: {
            title: 'Design Financial Reports',
            infoLine: menu?.obj?.Name,
            rightText: 'Delete',
          },
          body: () => (
            <div className="content-wrap full">
              <span className="mdDesc full">
                Are you sure you want to delete this Report?
              </span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => setDeleteDia(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => handleDelete(),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
    </>
  )
}
