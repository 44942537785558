import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { Checkbox, TextField } from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { AccountFooter } from 'components/Footer/AccountFooter'
import AppContext from 'containers/App/Store/AppContext'
import {
  useGetCompanyQuery,
  useGetStockItemNameLazyQuery,
  useGetWarehouseLazyQuery,
  useGetStockCategoryQuery,
  useGetStockCategoryNameLazyQuery,
} from 'generated/graphql'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation, useParams } from 'react-router'
import * as yup from 'yup'

interface StockTransferReportParamsFormProps {
  CompanyID: string
  DocStartDate: Date
  DocEndDate: Date
  TrxnStartDate: Date
  TrxnEndDate: Date
  DocNo: string
  DocRef: string
  FromWarehouseID: string
  ToWarehouseID: string
  StockCategoryID: string
  StockItemID: string
  Amount: number
}

export const StockTransferReportParamsForm = (props: any) => {
  let history = useHistory()
  let location = useLocation()
  const editData = location?.state as any
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />
  const ParamsSchema = yup.object().shape({
    CompanyID: CommonYupValidation.requireField('CompanyID is required'),
    DocStartDate: CommonYupValidation.requireField(
      'Doc Start Date is required'
    ),
    DocEndDate: CommonYupValidation.requireField('Doc End Date is required'),
  })

  const [selectedFromWarehouse, setSelectedFromWarehouse]: any = useState(
    new Set()
  )
  const [selectedToWarehouse, setSelectedToWarehouse]: any = useState(new Set())
  const [selectedStockItem, setSelectedStockItem]: any = useState(new Set())
  const [selectedStockCategory, setSelectedStockCategory] = useState(new Set())

  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    watch,
    setValue,
  } = useForm<StockTransferReportParamsFormProps>({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(ParamsSchema),
  })

  // ACCOUNTX API CALLS
  const {
    loading: companyLoading,
    error: companyError,
    data: { getCompany: getCompany } = { getCompany: [] },
  } = useGetCompanyQuery({
    fetchPolicy: 'network-only',
    variables: {
      orderByAsc: 'Name',
    },
  })

  const [
    loadWarehouse,
    {
      loading: WarehouseLoading,
      error: WarehouseError,
      data: { getWarehouse } = { getWarehouse: [] },
    },
  ] = useGetWarehouseLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getWarehouse }) => {},
  })

  const onSubmit = (data, status) => {
    history.push({
      pathname: `/inventory-control/digital-report/stock-transfer-report/generated`,
      state: {
        CompanyID: data?.CompanyID,
        DocStartDate: new Date(data.DocStartDate),
        DocEndDate: new Date(data.DocEndDate),
        TrxnStartDate: data?.TrxnStartDate
          ? new Date(data?.TrxnStartDate)
          : null,
        TrxnEndDate: data?.TrxnEndDate ? new Date(data?.TrxnEndDate) : null,
        DocNo: data?.DocNo && data?.DocNo !== '' ? data?.DocNo : undefined,
        DocRef: data?.DocRef && data?.DocRef !== '' ? data?.DocRef : undefined,
        FromWarehouseID: selectedFromWarehouse
          ? Array.from(selectedFromWarehouse)
          : undefined,
        ToWarehouseID: selectedToWarehouse
          ? Array.from(selectedToWarehouse)
          : undefined,
        StockCategoryID: selectedStockCategory
          ? Array.from(selectedStockCategory).toString()
          : undefined,
        StockItemID: selectedStockItem
          ? Array.from(selectedStockItem)
          : undefined,
        Amount: data?.Amount && data?.Amount !== '' ? data?.Amount : undefined,
      },
    })
  }

  const [
    loadStockCategory,
    {
      loading: StockCategoryNameLoading,
      error: StockCategoryNameError,
      data: { getStockCategory } = { getStockCategory: [] },
    },
  ] = useGetStockCategoryNameLazyQuery({
    fetchPolicy: 'network-only',
  })

  const [
    loadStockItem,
    {
      loading: StockItemNameLoading,
      error: StockItemNameError,
      data: { getStockItem } = { getStockItem: [] },
    },
  ] = useGetStockItemNameLazyQuery({
    fetchPolicy: 'network-only',
  })

  const handleCheckboxFromWarehouseChange = (event: any) => {
    let FromWarehouse = selectedFromWarehouse

    if (event?.target?.checked) {
      FromWarehouse.add(event?.target?.value)
    } else {
      FromWarehouse.delete(event?.target?.value)
    }
    setSelectedFromWarehouse(new Set(FromWarehouse))
  }

  const handleCheckboxToWarehouseChange = (event: any) => {
    let ToWarehouse = selectedToWarehouse

    if (event?.target?.checked) {
      ToWarehouse.add(event?.target?.value)
    } else {
      ToWarehouse.delete(event?.target?.value)
    }
    setSelectedToWarehouse(new Set(ToWarehouse))
  }

  const handleCheckboxStockCategoryChange = (event: any) => {
    let stockCategory = selectedStockCategory
    if (event?.target?.checked) {
      stockCategory.add(event?.target?.value)
    } else {
      stockCategory.delete(event?.target?.value)
    }
    setSelectedStockCategory(new Set(stockCategory))
  }

  const handleCheckboxStockItemChange = (event: any) => {
    let StockItem = selectedStockItem

    if (event?.target?.checked) {
      StockItem.add(event?.target?.value)
    } else {
      StockItem.delete(event?.target?.value)
    }
    setSelectedStockItem(new Set(StockItem))
  }

  return (
    <>
      {companyLoading && <Loading />}
      {WarehouseLoading && <Loading />}
      {StockItemNameLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(`/inventory-control/submenu/digital-report`)
        }
        smTitle={'Inventory Control'}
        title={user?.accountName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'Stock Transfer Report', current: true },
        ]}
        rightRouteSegments={[{ name: 'Parameters', current: true }]}
      />
      <ContentWrapper float>
        <CardContents>
          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  options={getCompany || []}
                  getOptionLabel={option => `${option?.Name}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    setValue('CompanyID', newValue?.CompanyID)
                    loadWarehouse({
                      variables: { CompanyID: newValue?.CompanyID },
                    })
                    setValue('WarhouseID', undefined)
                    loadStockItem({ variables: { orderByAsc: 'Name' } })
                    loadStockCategory({ variables: { orderByAsc: 'Name' } })
                  }}
                  renderOption={(props, option) => {
                    return <span>{props?.Name}</span>
                  }}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          helperText={errors?.CompanyID?.message}
                          error={errors?.CompanyID ? true : false}
                          label="Company"
                          style={{ width: '100%' }}
                          margin="normal"
                          required
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Company"
            name="CompanyID"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            helperText={errors?.CompanyID?.message}
            error={errors?.CompanyID ? true : false}
            required
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="DocStartDate"
              label="Doc Start Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch('DocStartDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.DocStartDate ?? new Date()}
              helperText={errors?.DocStartDate?.message}
              error={errors?.DocStartDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="DocEndDate"
              label="Doc End Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch('DocEndDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.DocEndDate ?? new Date()}
              helperText={errors?.DocEndDate?.message}
              error={errors?.DocEndDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="TrxnStartDate"
              label="Trxn Start Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch('TrxnStartDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={null}
              helperText={errors?.TrxnStartDate?.message}
              error={errors?.TrxnStartDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="TrxnEndDate"
              label="Trxn End Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch('TrxnEndDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={null}
              helperText={errors?.TrxnEndDate?.message}
              error={errors?.TrxnEndDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>

          <Controller
            as={TextField}
            id="standard-basic"
            name="DocNo"
            label="Doc No"
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            ref={register}
            helperText={errors?.DocNo?.message}
            error={errors?.DocNo ? true : false}
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="DocRef"
            label="Doc Ref"
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            ref={register}
            helperText={errors?.DocRef?.message}
            error={errors?.DocRef ? true : false}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  options={getWarehouse || []}
                  getOptionLabel={option => `${option?.Name}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selecteted = new Set()

                    newValue?.map(x => {
                      selecteted.add(x?.WarehouseID)
                    })

                    setSelectedFromWarehouse(new Set(selecteted))
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.WarehouseID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxFromWarehouseChange(event)
                        }}
                      />
                      {option?.Name}
                    </React.Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          style={{ width: '100%' }}
                          label="From Warehouse"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="FromWarehouseID"
            label="From Warehouse"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  options={getWarehouse || []}
                  getOptionLabel={option => `${option?.Name}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selecteted = new Set()

                    newValue?.map(x => {
                      selecteted.add(x?.WarehouseID)
                    })

                    setSelectedToWarehouse(new Set(selecteted))
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.WarehouseID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxToWarehouseChange(event)
                        }}
                      />
                      {option?.Name}
                    </React.Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          style={{ width: '100%' }}
                          label="To Warehouse"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="ToWarehouseID"
            label="To Warehouse"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  options={
                    getStockCategory?.sort((a, b) => {
                      return a.Name.localeCompare(b.Name)
                    }) || []
                  }
                  getOptionLabel={option => option?.Name}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selecteted = new Set()

                    newValue?.map(x => {
                      selecteted.add(x?.StockCategoryID)
                    })

                    setSelectedStockCategory(new Set(selecteted))
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.StockCategoryID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxStockCategoryChange(event)
                        }}
                      />
                      {option?.Name}
                    </React.Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          style={{ width: '100%' }}
                          label="Stock Category"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="StockCategoryID"
            label="Stock Category"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  options={getStockItem || []}
                  getOptionLabel={(option: any) => `${option?.Name}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selecteted = new Set()

                    newValue?.map(x => {
                      selecteted.add(x?.StockItemID)
                    })

                    setSelectedStockItem(new Set(selecteted))
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.StockItemID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxStockItemChange(event)
                        }}
                      />
                      {option?.Name}
                    </React.Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          style={{ width: '100%' }}
                          label="Stock Item"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="StockItemID"
            label="Stock Item"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            as={
              <NumberFormat
                allowNegative={false}
                customInput={TextField}
                decimalScale={2}
                fixedDecimalScale
                thousandSeparator
                isNumericString
                onValueChange={e => {
                  setValue('Amount', parseFloat(e.value))
                }}
              />
            }
            name="Amount"
            label="Amount"
            ref={register()}
            control={control}
          />
        </CardContents>

        <AccountFooter
          options={[
            {
              name: 'Submit',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
              //   props: { disabled: getReceipt?.length === 0 },
            },
          ]}
        />
      </ContentWrapper>

      {/* FOOTER */}
    </>
  )
}
