import { useGetTransactionTypeQuery } from '@account-root/account-react/src/generated/graphql';
import { yupResolver } from '@hookform/resolvers';
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable';
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ErrorDialog } from 'components/Dialog/ErrorDialog';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import { CommonYupValidation } from 'helpers/YupSchema/yup';
import localforage from 'localforage';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { TooltipAmtFooter } from '../../../components/Footer/TooltipAmtFooter';
import {
  ApprovalStatus,
  GetGeneralDoDocument,
  GetGrtnHeaderDocument,
  useCreateGrtnMutation,
  useDocumentListingLazyQuery,
  useGetGeneralDOforGrtnLazyQuery,
  useGetGeneralPurchasePolicyQuery,
  useGetSupplierByDoStatusQuery,
  useGetDeliveryLocationNameQuery,
  useLatestOpenPeriodCheckingDateQuery,
  useUpdateGrtnMutation,
} from '../../../generated/graphql';
import { SystemMsgs } from '../../../helpers/Messages/SystemMsg';
import { ExitConfirmationDialog } from '../components/ExitConfirmationDialog';
import { GeneralGRTNContent } from './Components/GeneralGRTNContent';

interface GoodReturnNoteProps {
  DocDate: Date;
  TrxDate: Date;
  SupplierID: string;
  DocRef: string;
  Description: string;
  Attachment: string;
  LocationID: string;
}

export const GRTNSchema = yup.object().shape({
  SupplierID: CommonYupValidation.requireField(SystemMsgs.supplierSelection()),
  Description: CommonYupValidation.requireField(SystemMsgs.description()),
  DocRef: CommonYupValidation.requireField(SystemMsgs.docRef()),
  docDate: CommonYupValidation.requireField('Doc Date is required'),
  trxDate: CommonYupValidation.requireField('Transaction Date is required'),
  LocationID: CommonYupValidation.requireField(SystemMsgs.deliveryLocation()),
});

export const GeneralGRTNForm = ({ mode }) => {
  let formMode = mode === 'add' ? 'New' : 'Edit';
  let history = useHistory();
  let location = useLocation();
  const editData = location?.state as any;
  const { CompanyID, GRTNHeaderID }: any = useParams();
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any,
  );
  const user = JSON.parse(localStorage.getItem('loggedInUser'));

  const [state, setDependecies] = useState(false);
  const [isWarehouse, setIsWarehouse] = useState(false);
  const [noError, setNoError] = useState(true);
  const [doDate, setDoDate] = useState(new Date());
  const [itemHasValue, setItemHasValue] = useState(null);
  const [itemHasValueAmt, setItemHasValueAmt] = useState(null);
  const [exitDialog, setExitDialog] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  const editResubmitMode = mode => {
    switch (mode) {
      case 'edit':
        return true;
      case 'resubmit':
        return true;
      default:
        return false;
    }
  };

  const [deliveryLocationDetail, setDeliveryLocationDetail] = useState(
    editResubmitMode(mode)
      ? {
          ID: editData?.DeliveryLocationID ?? editData?.WarehouseID,
          IsWarehouse: !!editData?.WarehouseID,
        }
      : { ID: '', IsWarehouse: false },
  );
  const [suppID, setSuppID] = useState(
    editResubmitMode(mode) ? editData?.SupplierID : null,
  );
  const [LocationID, setLocationID] = useState(
    editResubmitMode(mode) ? editData?.DeliveryLocationID : null,
  );

  const [selectedDate, handleDateChange] = useState(
    editResubmitMode(mode) ? new Date(editData?.DocDate) : new Date(),
  );

  const [stopFlag, setStopFlag] = useState(false);

  //==============================================//
  //-------------------USEFORM--------------------//
  //==============================================//
  const {
    handleSubmit,
    register,
    errors,
    control,
    setValue,
    watch,
    setError,
    clearErrors,
    formState,
    getValues,
  } = useForm<GoodReturnNoteProps>({
    defaultValues: {},
    mode: 'onSubmit',
    resolver: yupResolver(GRTNSchema),
  });

  //============================================//
  //-------------------QUERY--------------------//
  //============================================//

  const [
    loadDOHeader,
    {
      loading: doHeaderLoading,
      error: doError,
      data: { getGeneralDO } = { getGeneralDO: [] },
    },
  ] = useGetGeneralDOforGrtnLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getGeneralDO }) => {},
  });

  const {
    loading: getSuplierLoading,
    data: { getSupplierByDOStatus } = { getSupplierByDOStatus: [] },
  } = useGetSupplierByDoStatusQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      ApprovalStatus: ApprovalStatus.Completed,
    },
  });

  const {
    loading: warehouseLoading,
    data: { getDeliveryLocation } = {
      getDeliveryLocation: [],
    },
  } = useGetDeliveryLocationNameQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
    },
  });

  const {
    loading: openPeriodCheckDateLoading,
    data: { latestOpenPeriodCheckingDate } = {
      latestOpenPeriodCheckingDate: {} as any,
    },
  } = useLatestOpenPeriodCheckingDateQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  });

  const {
    loading: GPPolicyLoading,
    error,
    data: { getGeneralPurchasePolicy } = { getGeneralPurchasePolicy: [] },
  } = useGetGeneralPurchasePolicyQuery({
    fetchPolicy: 'network-only',
    variables: {
      AccountID: user?.accountID,
    },
  });

  const {
    loading: TransactionTypeLoading,
    error: TransactionTypeError,
    data: { getTransactionType } = { getTransactionType: [] },
  } = useGetTransactionTypeQuery({
    fetchPolicy: 'network-only',
    variables: { orderByAsc: 'Name' },
  });

  const grnTransaction = getTransactionType?.filter(
    x => x?.Name === 'Goods Receive Note',
  );

  const [gpPolicyControl, setGPPolicyControl] = useState<boolean>(false);

  const checkGPPolicy = () => {
    if (getGeneralPurchasePolicy[0]?.MasterCOAID?.length > 0) {
      return true;
    } else return false;
  };

  const [icTrxType, setICTrxType] = useState<boolean>(false);
  const checkICTrxType = () => {
    if (grnTransaction[0]?.MasterCOAID?.length > 0) {
      return true;
    } else return false;
  };

  //============================================//
  //----------------ATTACHMENT------------------//
  //============================================//
  const {
    files,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
    setFiles,
    setPreviewFiles,
  } = useUploadAttachment();

  const [
    fetchDocuments,
    { loading: documentLoading, error: DocError, data: DocData },
  ] = useDocumentListingLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: GRTNHeaderID,
    },
    onCompleted: ({ DocumentListing }) => {
      // only applies when first opening edit form, when editData still does not have "files" but only Attachment from field resolver
      if (!editData?.files) {
        handleEditUpload(
          DocumentListing?.filter(doc => doc?.description !== 'document_pdf'),
        );
        previewFiles?.push(
          ...DocumentListing?.filter(
            doc => doc?.description !== 'document_pdf',
          )?.map(x => x?.fileURL),
        );
      }
    },
  });

  //============================================//
  //------------------MUTATION------------------//
  //============================================//
  const [CreateGRTN, { loading: createLoading }] = useCreateGrtnMutation({
    onError: error => {
      console.log('ERROR', error);
    },
    onCompleted: ({ CreateGRTN }) => {
      setTimeout(() => {
        if (!!isSubmit) {
          window.open(`${CreateGRTN?.GRTNHeaderID}/detail/preview`, '_blank');
        }
      }, 100);
      setSnackBarMsg(SystemMsgs.createNewRecord());
      setOpenSnackBar(true);
      setTimeout(() => {
        history.push(`/general-purchase/${CompanyID}/good-return-note`);
      }, 500);
      localforage.removeItem('getDO');
    },
  });

  const [UpdateGRTN, { loading: updateLoading }] = useUpdateGrtnMutation({
    onError: error => {
      console.log('ERROR', error);
    },
    onCompleted: ({ UpdateGRTN }) => {
      setTimeout(() => {
        if (!!isSubmit) {
          if (!!GRTNHeaderID)
            window.open(
              `/general-purchase/${CompanyID}/good-return-note/${GRTNHeaderID}/detail/preview`,
              '_blank',
            );
        }
      }, 100);
      setSnackBarMsg(SystemMsgs.updateRecord());
      setOpenSnackBar(true);
      setTimeout(() => {
        history.push(`/general-purchase/${CompanyID}/good-return-note`);
      }, 500);
    },
  });

  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ -  USE EFFECT  - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥//
  useEffect(() => {
    if (mode === 'edit')
      if (!!!editData?.files) {
        fetchDocuments({
          variables: {
            refID: GRTNHeaderID,
          },
        });
      }
  }, [mode]);

  useEffect(() => {
    if (editData?.files) {
      setFiles(editData?.files);
      setPreviewFiles(
        editData?.files?.map(file => {
          return URL.createObjectURL(file);
        }),
      );
    }
  }, [editData]);

  useEffect(() => {
    if (!!suppID) {
      loadDOHeader({
        variables: {
          SupplierID: suppID,
          CompanyID: CompanyID,
          ApprovalStatus: ApprovalStatus.Completed,
        },
      });
    }
  }, [suppID, selectedDate, LocationID, isWarehouse]);

  useEffect(() => {
    setDoDate(watch('docDate'));
  }, [watch('docDate')]);

  //============================================//
  //------------------ONSUBMIT------------------//
  //============================================//
  const createUpdateGRTN = mode === 'add' ? CreateGRTN : UpdateGRTN;

  const onSubmit = (data, submitMode) => {
    if (!loading) {
      setLoading(true);
      localforage.getItem('getDO', function(err, getDO) {
        createUpdateGRTN({
          variables: {
            GRTNHeaderID: mode === 'edit' ? GRTNHeaderID : null,
            grtnInput: {
              SupplierID: data?.SupplierID,
              CompanyID: CompanyID,
              DeliveryLocationID:
                deliveryLocationDetail?.IsWarehouse === false
                  ? data?.LocationID
                  : null,
              WarehouseID:
                deliveryLocationDetail?.IsWarehouse === true
                  ? data?.LocationID
                  : null,
              DocDate: new Date(data?.docDate).toISOString(),
              TransactionDate: new Date(data?.trxDate).toISOString(),
              RefNo: data?.DocRef,
              Description: data?.Description,
              file: files,
              ApprovalStatus:
                submitMode === 'submit'
                  ? ApprovalStatus.Submit
                  : submitMode === 'reject'
                  ? ApprovalStatus.Rejected
                  : ApprovalStatus.Active,
            },
            grtnItemInput: Object.values(getDO)
              ?.map((x: any) => {
                return x?.DOItem.map(y => {
                  return {
                    DoItemID: y?.doItemID,
                    ReturnedQty: parseFloat(y?.ReturnedQty ?? 0),
                    ReturnedAmt: parseFloat(y?.ReturnedAmt ?? 0),
                    Replacement: y?.replacement === true ? true : false,
                  };
                });
              })
              .flat(),
          },
          refetchQueries: [
            {
              query: GetGrtnHeaderDocument,
              variables: {
                CompanyID: CompanyID,
                orderByDesc: 'DocDate',
                skip: 0,
                take: 30,
              },
            },
            {
              query: GetGeneralDoDocument,
              variables: {
                CompanyID: CompanyID,
                ApprovalStatus: ApprovalStatus.Active,
                skip: 0,
                take: 30,
              },
            },
          ],
        });
      });
    }
  };

  const handleCLick = handleSubmit(onSubmit);

  const checkItemQty = () => {
    const acceptedQtys = [];

    getGeneralDO?.map((el, inindex) => {
      el?.DOItem?.map((inel, initem) => {
        const acceptedAmt = watch(
          `getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedAmt`,
        ) as string | undefined;

        const acceptedQty = Number(acceptedAmt);
        if (!isNaN(acceptedQty)) {
          acceptedQtys.push(acceptedQty);
        }
      });
    });

    const totalAcceptedQty = acceptedQtys.reduce((acc, qty) => acc + qty, 0);

    return totalAcceptedQty;
  };

  //============================================//
  //------------------CHECKING------------------//
  //============================================//

  const checkItemLength = () => {
    let status = false;

    getGeneralDO?.forEach(el => {
      el?.DOItem?.forEach((initem, index) => {
        // Construct the path correctly using index
        const path = `getDO[${el?.DOHeaderID}].DOItem[${index}].ReturnedQty`;
        const ReturnedQty = watch(path);
        if (ReturnedQty !== undefined && ReturnedQty !== null) {
          const ReturnedQtyString = String(ReturnedQty).replace(/,/g, '');
          const ReturnedQtyNumber = parseFloat(ReturnedQtyString);

          if (
            mode === 'add'
              ? ReturnedQtyNumber > 0
              : ReturnedQtyNumber > 0 || ReturnedQty === undefined
          ) {
            status = true;
          }
        }
      });
    });

    return status;
  };

  const checkGRTNItem = () => {
    let status = false;
    getGeneralDO?.map((el, inindex) => {
      el?.DOItem?.map((inel, initem) => {
        const watchRtQty = watch(
          `getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedQty`,
        );
        const ReturnedQty =
          typeof watchRtQty === 'string' && watchRtQty.includes(',')
            ? watchRtQty.replace(/,/g, '')
            : watchRtQty;

        const DOqty = mode === 'add' ? inel?.UpToDateQty : inel?.AcceptedQty;

        if ((ReturnedQty as number) > DOqty) {
          status = true;
        }
      });
    });
    return status;
  };

  const trxDateTimestamp = new Date(watch('trxDate'));
  const yearTrxDate = trxDateTimestamp.getFullYear();
  const monthTrxDate = (trxDateTimestamp.getMonth() + 1)
    .toString()
    .padStart(2, '0');
  const dayTrxDate = trxDateTimestamp
    .getDate()
    .toString()
    .padStart(2, '0');

  const trxDate = `${yearTrxDate}-${monthTrxDate}-${dayTrxDate}`;

  const openPeriod2 =
    trxDate >= latestOpenPeriodCheckingDate?.StartDate &&
    trxDate <= latestOpenPeriodCheckingDate?.EndDate;

  const checkingYearClose = openPeriod2 ? false : true;

  const draftFooterOption =
    mode !== 'edit'
      ? {
          name: 'Save as Draft',
          onClick: () => {
            if (noError) {
              handleSubmit(data => onSubmit(data, 'draft'))();
            }
          },
          disabled:
            checkingYearClose ||
            checkItemLength() === false ||
            checkGRTNItem() === true,
          color: 'primary',
        }
      : {
          name: editData?.mode === 'resubmit' ? 'Save' : 'Save as Draft',
          onClick: () => {
            if (noError) {
              handleSubmit(data =>
                onSubmit(
                  data,
                  editData?.mode === 'resubmit' ? 'reject' : 'draft',
                ),
              )();
            }
          },
          disabled:
            editData?.mode === 'resubmit'
              ? !!errors?.DocDate ||
                !!errors?.TrxDate ||
                !!errors?.SupplierID ||
                !!errors?.DocRef ||
                !!errors?.Description ||
                checkingYearClose ||
                !noError ||
                checkItemLength() === false
              : !noError ||
                checkItemLength() === false ||
                checkGRTNItem() === true ||
                checkingYearClose,
          color: 'primary',
        };

  const submitFooterOption =
    mode !== 'edit'
      ? {
          name: 'Save as Submit',
          onClick: () => {
            if (noError) {
              (checkGPPolicy() === false
                ? setGPPolicyControl(true)
                : handleSubmit(data => onSubmit(data, 'submit'))()) ||
                (checkICTrxType() === false
                  ? setICTrxType(true)
                  : handleSubmit(data => onSubmit(data, 'submit'))());
            }
            setIsSubmit(true);
          },
          disabled:
            checkingYearClose ||
            checkItemLength() === false ||
            checkGRTNItem() === true ||
            !noError ||
            (itemHasValue?.every(s => s === false) &&
              itemHasValueAmt?.every(s => s === false)),
          color: 'primary',
        }
      : {
          name: 'Save as Submit',
          onClick: () => {
            if (noError) {
              handleSubmit(data => onSubmit(data, 'submit'))();
            }
            setIsSubmit(true);
          },
          disabled:
            checkingYearClose ||
            !noError ||
            checkItemLength() === false ||
            checkGRTNItem() === true,
          color: 'primary',
        };

  let footerOptions: any[] = [draftFooterOption, submitFooterOption];

  return (
    <>
      {createLoading && <Loading />}
      {updateLoading && <Loading />}
      {documentLoading && <Loading />}
      {doHeaderLoading && <Loading />}
      {GPPolicyLoading && <Loading />}
      {warehouseLoading && <Loading />}
      {getSuplierLoading && <Loading />}
      {TransactionTypeLoading && <Loading />}
      {openPeriodCheckDateLoading && <Loading />}

      <MainHeader
        onClick={() => {
          history.push(`/general-purchase/${CompanyID}/good-return-note`);
          localStorage?.removeItem('searchFilter');

          localforage.removeItem('getDO');
        }}
        mainBtn="back"
        smTitle={smTitle.GENERAL_PURCHASES}
        title={user?.companyName}
        currency={user?.companyCurrencyCode}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Goods Return Note', current: true },
        ]}
        rightRouteSegments={[{ name: `${formMode}`, current: true }]}
      />

      <ContentWrapper footer>
        <form onSubmit={handleCLick} id="submit-form">
          <CardContents
            section={{
              header: {
                title: 'Goods Return Note Details',
              },
            }}
          >
            <Controller
              control={control}
              ref={register}
              name="docDate"
              required
              className="left"
              label="DO Date"
              fullWidth
              as={KeyboardDatePicker}
              defaultValue={editData ? editData?.DocDate : new Date()}
              value={new Date()}
              format="dd/MM/yyyy"
              onChange={(value, newValue: any) => {}}
              showTodayButton
            />

            <Controller
              control={control}
              ref={register}
              name="trxDate"
              required
              className="right"
              label="Transaction  Date"
              fullWidth
              as={KeyboardDatePicker}
              defaultValue={editData ? editData?.TransactionDate : new Date()}
              value={new Date()}
              format="dd/MM/yyyy"
              onChange={(e: Date | null) => {}}
              showTodayButton
              minDate={new Date(latestOpenPeriodCheckingDate?.StartDate)}
              maxDate={new Date(latestOpenPeriodCheckingDate?.EndDate)}
              helperText={
                checkingYearClose
                  ? 'Financial Period already closed'
                  : errors?.TrxDate?.message
              }
              error={errors?.TrxDate || checkingYearClose ? true : false}
            />

            {!getSuplierLoading && (
              <Controller
                render={({ value, onChange }) => {
                  const defVal = getSupplierByDOStatus?.filter(
                    x => x?.CreditorAccountID === editData?.SupplierID,
                  )[0];

                  return (
                    <Autocomplete
                      options={getSupplierByDOStatus || []}
                      getOptionLabel={option => option?.CompanyName}
                      fullWidth
                      onChange={(value, newValue: any) => {
                        setValue('SupplierID', newValue?.CreditorAccountID);
                        setSuppID(watch(`SupplierID`));
                      }}
                      renderOption={(props, option) => {
                        return <span>{props?.CompanyName}</span>;
                      }}
                      defaultValue={defVal}
                      renderInput={(params: any) => {
                        return (
                          <div>
                            <TextField
                              {...params}
                              helperText={errors?.SupplierID?.message}
                              error={errors?.SupplierID ? true : false}
                              label="Supplier"
                              style={{ width: '100%' }}
                              margin="dense"
                              required
                            />
                          </div>
                        );
                      }}
                    />
                  );
                }}
                label="Supplier"
                name="SupplierID"
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                margin="dense"
                ref={register}
                helperText={errors?.SupplierID?.message}
                error={errors?.SupplierID ? true : false}
                defaultValue={editData?.SupplierID}
                required
              />
            )}

            {!warehouseLoading && (
              <Controller
                render={({ value, onChange }) => {
                  const defVal = getDeliveryLocation?.filter(
                    x => x?.DeliveryLocationID === editData?.DeliveryLocationID,
                  )[0];
                  return (
                    <Autocomplete
                      options={getDeliveryLocation || []}
                      getOptionLabel={option => option?.Name}
                      fullWidth
                      onChange={(value, newValue: any) => {
                        setValue('LocationID', newValue?.ID);
                        setDeliveryLocationDetail({
                          ID: newValue?.ID,
                          IsWarehouse: false,
                        });
                      }}
                      renderOption={(props, option) => {
                        return <span>{props?.Name}</span>;
                      }}
                      defaultValue={defVal}
                      renderInput={(params: any) => {
                        return (
                          <div>
                            <TextField
                              {...params}
                              label="Location"
                              style={{ width: '100%' }}
                              margin="dense"
                              required
                              helperText={errors?.LocationID?.message}
                              error={errors?.LocationID ? true : false}
                            />
                          </div>
                        );
                      }}
                    />
                  );
                }}
                label="Location"
                name="LocationID"
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                margin="dense"
                ref={register}
                defaultValue={
                  editData?.WarehouseID ?? editData?.DeliveryLocationID
                }
                helperText={errors?.LocationID?.message}
                error={errors?.LocationID ? true : false}
                required
              />
            )}

            <Controller
              as={TextField}
              name="DocRef"
              // className="left"
              fullWidth
              required
              label="Do No"
              defaultValue={editData?.RefNo}
              control={control}
              helperText={errors?.DocRef?.message}
              error={!!errors?.DocRef}
            />

            <Controller
              as={TextField}
              name="Description"
              fullWidth
              required
              label="Description"
              defaultValue={editData?.Description}
              control={control}
              helperText={errors?.Description?.message}
              error={!!errors?.Description}
            />

            <FileUploadInput
              label="Attachment"
              files={files}
              onHandleUploadChange={handleUploadChange}
              multiple
              imagePreview={
                <>
                  {previewFiles?.map((v, i) => (
                    <UploadPreview
                      remove
                      key={v}
                      src={v}
                      onClick={() => removeFile(i)}
                    />
                  ))}
                </>
              }
            />
          </CardContents>
          {/* {!doHeaderLoading && ( */}
          <GeneralGRTNContent
            state={state}
            GRTNLoading={doHeaderLoading}
            doArr={getGeneralDO}
            mode={mode}
            editData={editData}
            setNoError={setNoError}
            useFormProps={{
              clearErrors,
              setError,
              errors,
              watch,
              register,
              control,
              setValue,
            }}
            itemHasValueQty={setItemHasValue}
            itemHasValueAmt={setItemHasValueAmt}
            GRTNHeaderID={GRTNHeaderID}
            CompanyID={CompanyID}
            selectedDate={doDate}
            stopFlag={stopFlag}
            setStopFlag={setStopFlag}
          />
          {/* )} */}
        </form>
        <TooltipAmtFooter data={checkItemQty()} module={'grtn-draft'} />

        {footerOptions?.length > 0 ? (
          <Footer options={[...footerOptions]} />
        ) : null}
      </ContentWrapper>

      <ExitConfirmationDialog
        exitDialog={exitDialog}
        setExitDialog={setExitDialog}
        path={() =>
          history.push(`/general-purchase/${CompanyID}/good-return-note`)
        }
      />

      <ErrorDialog
        errorDia={gpPolicyControl}
        setErrorDia={setGPPolicyControl}
        errorMsg={'Please setup Inventory Clearance Account'}
        errorHeaderMsg={'Error!'}
      />

      <ErrorDialog
        errorDia={icTrxType}
        setErrorDia={checkICTrxType}
        errorMsg={'Please setup Transaction Type'}
        errorHeaderMsg={'Error!'}
      />
    </>
  );
};
