import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable';
import { default as useUploadDocument } from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment';
import { AttachFile } from '@material-ui/icons';
import PrintIcon from '@material-ui/icons/Visibility';
import { useMenuOption } from 'helpers/Hooks/useMenuOption';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { AttachmentDialog } from '../../../components/Dialog/AttachmentDialog';
import { TooltipAmtFooter } from '../../../components/Footer/TooltipAmtFooter';
import {
  useDocumentListingQuery,
  useGetGrtnHeaderQuery,
  useGetUsersByAccountAndSoftwareQuery,
} from '../../../generated/graphql';
import { addressFormat } from '../../../helpers/AddressHandler/AddressFormat';
import { attachmentDetailView } from '../../../helpers/Hooks/attachmentDetailsView';
import { formatDate } from '../../../helpers/StringNumberFunction/formatDate';
import { GRTNContent } from './Components/GRTNContent';

export const GeneralGRTNDetail = () => {
  let history = useHistory();
  const { CompanyID, GRTNHeaderID }: any = useParams();
  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  const [labelState, setLabelState] = useState<string>();
  const [openDoc, setOpenDoc] = useState<boolean>(false);

  const {
    loading: grtnLoading,
    error: grtnError,
    data: { getGRTNHeader } = { getGRTNHeader: [] },
  } = useGetGrtnHeaderQuery({
    fetchPolicy: 'network-only',
    variables: {
      GRTNHeaderID: GRTNHeaderID,
      CompanyID: CompanyID,
      orderByDesc: 'DocDate',
    },
  });
  const acceptedAmtArray =
    getGRTNHeader[0]?.GRTNHeaderItem?.map(x => x?.ReturnedAmt) || [];

  const TotalAmt = acceptedAmtArray.reduce(
    (accumulator, currentValue) => (accumulator || 0) + (currentValue || 0),
    0,
  );

  const {
    anchorEl,
    menu,
    handleClick,
    handleClose,
    resetMenu: resetVert,
  } = useMenuOption();

  const {
    files,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
    setFiles,
  } = useUploadDocument();

  const {
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: GRTNHeaderID,
    },
    onCompleted: data => {
      handleEditUpload(data?.DocumentListing);
      previewFiles.push(...data?.DocumentListing?.map(x => x?.fileURL));
    },
  });

  useEffect(() => {
    if (!!user?.companyName) setLabelState(user?.companyName);
  }, [user?.companyName]);

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
  });

  return (
    <>
      {UserLoading && <Loading />}
      {grtnLoading && <Loading />}
      <MainHeader
        onClick={() =>
          history.push(`/general-purchase/${CompanyID}/good-return-note`)
        }
        mainBtn="back"
        smTitle={smTitle.GENERAL_PURCHASES}
        title={labelState}
        currency={user?.companyCurrencyCode}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Goods Return Note', current: true },
        ]}
        rightRouteSegments={[{ name: `Details`, current: true }]}
      />

      <ContentWrapper float>
        <CardContents
          section={{
            header: {
              title: 'GRTN',
            },
          }}
        >
          <div className="content-wrap left">
            <div className="desc">Doc Date</div>
            <div className="xxTitle">
              {formatDate(getGRTNHeader[0]?.DocDate)}
            </div>
          </div>
          <div className="content-wrap right">
            <span
              style={{ float: 'right' }}
              onClick={() => {
                history.push({
                  pathname: `/general-purchase/${CompanyID}/good-return-note/${getGRTNHeader[0]?.GRTNHeaderID}/detail/preview`,
                  state: {
                    mode: 'detail',
                  },
                });
              }}
            >
              <PrintIcon
                style={{
                  fontSize: 'medium',
                  padding: '0px 10px 2px 0px',
                }}
              />
            </span>
            {DocumentListing && DocumentListing?.length > 0 && (
              <>
                <span
                  style={{ float: 'right', color: '#000' }}
                  onClick={e => {
                    handleClick(
                      e,
                      getGRTNHeader[0]?.GRTNHeaderID,
                      0,
                      getGRTNHeader[0],
                    );
                    setOpenDoc(true);
                  }}
                >
                  <AttachFile
                    className="mdDesc"
                    style={{
                      fontSize: 12,
                      color: 'orange',
                    }}
                  />

                  <span
                    className="mdDesc"
                    style={{
                      float: 'right',
                      color: 'orange',
                      textDecoration: 'underline',
                      paddingRight: '5px',
                      paddingTop: '4px',
                    }}
                  >
                    {DocumentListing?.length}
                  </span>
                </span>
              </>
            )}
          </div>
          <div className="content-wrap full">
            <div className="desc">Transaction Date</div>
            <div className="xxTitle">
              {formatDate(getGRTNHeader[0]?.TransactionDate)}
            </div>
          </div>
          <div className="content-wrap">
            <div className="desc">Document No.</div>
            <div className="xxTitle">{getGRTNHeader[0]?.DocNo}</div>
          </div>
          <div className="content-wrap">
            <div className="desc">Do No.</div>
            <div className="xxTitle">{getGRTNHeader[0]?.RefNo}</div>
          </div>
          <div className="content-wrap full">
            <div className="desc">Supplier</div>
            <div className="xxTitle">
              {getGRTNHeader[0]?.Supplier?.CompanyName}
            </div>
          </div>
          <div className="content-wrap full">
            <div className="desc">Deliver To</div>
            <div className="xxTitle text-noflow">
              {getGRTNHeader[0]?.DeliveryLocation?.Name} -{' '}
              {addressFormat(getGRTNHeader[0]?.DeliveryLocation?.Address)}
            </div>
          </div>
          <div className="content-wrap full">
            <div className="desc">Descriptions</div>
            <div className="xxTitle text-noflow">
              {getGRTNHeader[0]?.Description}
            </div>
          </div>

          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Submitted Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(getGRTNHeader[0]?.submittedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Submitted By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {
                getUsersByAccountAndSoftware?.filter(
                  user => user.ID == getGRTNHeader[0]?.submittedBy,
                )[0]?.name
              }
            </div>
          </div>
          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(getGRTNHeader[0]?.approvedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {
                getUsersByAccountAndSoftware?.filter(
                  user => user.ID == getGRTNHeader[0]?.approvedBy,
                )[0]?.name
              }
            </div>
          </div>
        </CardContents>

        {getGRTNHeader[0]?.GRTNHeaderItem?.length > 0 && (
          <GRTNContent mode="view" dataDetail={getGRTNHeader[0]} />
        )}

        <TooltipAmtFooter
          data={TotalAmt}
          module={`grtn-approved`}
          detail={true}
        />
      </ContentWrapper>

      <AttachmentDialog
        title="GRTN"
        menu={menu}
        openDoc={openDoc}
        setOpenDoc={setOpenDoc}
        attachmentDetailView={attachmentDetailView}
        DocumentListing={DocumentListing}
      />
    </>
  );
};
