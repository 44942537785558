import { AdvancedFilter } from 'components/Filter/AdvancedFilter'
import { PDFPreviewV2 } from '../Hooks/PDFPreviewV2'
import { ARCreditNoteAllocation } from './ARCreditNoteAllocation/ARCreditNoteAllocation'
import { ARCreditNoteDetail } from './ARCreditNoteDetail/ARCreditNoteDetail'
import { ARCreditNoteFormWithAllocation } from './ARCreditNoteForm/ARCreditNoteFormWithAllocation'
import React from 'react'
import { ARMain } from '../ARMain'
import { ARDetail } from '../ARDetail/ARDetail'

const arCreditNoteRoute = [
  /* -------------------------------------------- */
  /*                ADVANCED FILTER               */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/credit-note/filter',
    },
    component: (
      <AdvancedFilter
        mode="credit-note"
        app="account-receivable"
        hasStatus
        hasDocDate
        hasDateRange
      />
    ),
  },

  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/credit-note',
    },
    component: (
      <ARMain
        arSubmenu="credit-note"
        routeSegments="Credit Note"
        accountType="account-receivable"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/account-receivable/:CompanyID/credit-note/:CreditNoteID/preview`,
    },
    component: (
      <PDFPreviewV2 accountType="account-receivable" docType="credit-note" />
    ),
  },

  /* -------------------------------------------- */
  /*                    DETAIL                    */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/account-receivable/:CompanyID/credit-note/:DocumentID/detail',
    },
    component: (
      <ARDetail
        arSubmenu="credit-note"
        routeSegments="Credit Note"
        accountType="account-receivable"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/credit-note/:CreditNoteID/detail/workdesk',
    },
    component: <ARCreditNoteDetail mode="workdesk" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/credit-note/:CreditNoteID/detail/workdesk-history',
    },
    component: <ARCreditNoteDetail mode="workdesk-history" />,
  },

  //   /* -------------------------------------------- */
  //   /*                ALLOCATION                    */
  //   /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/credit-note/:CreditNoteID/allocation',
    },
    component: <ARCreditNoteAllocation />,
  },

  //   /* -------------------------------------------- */
  //   /*                     FORM                     */
  //   /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: `/account-receivable/:CompanyID/credit-note/:formMode`,
    },
    component: (
      <ARCreditNoteFormWithAllocation docType="credit-note" formMode="add" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/account-receivable/:CompanyID/credit-note/:CreditNoteID/edit`,
    },
    component: (
      <ARCreditNoteFormWithAllocation
        docType="credit-note"
        // routeSegments="Credit Note"
        formMode="edit"
      />
    ),
  },
]

export default arCreditNoteRoute
