import React from 'react'
import { useParams } from 'react-router'
import { useGetCompanyQuery } from 'generated/graphql'
import { extractHtmlValue } from './ELetterService'
import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'

export const ELetterPreview = (props: any) => {
  const {
    fontStyle,
    title,
    previewDetail,
    accountType,
    docType,
    itemName,
    creditorOrDebtor,
    getUsersByAccountAndSoftware,
    remapData,
    page,
    pageLength,
    hasItem,
    currencyCode,
    htmlTemplate,
    isView,
  } = props

  const { CompanyID }: any = useParams()
  const [width, height] = useMediaQuery()

  const {
    loading: companyLoading,
    error: companyError,
    data: { getCompany: getCompany } = { getCompany: [] },
  } = useGetCompanyQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID,
    },
  })

  const dataDetails = remapData ? remapData[page] : previewDetail

  const ReturnHTML = () => {
    let htmlContent

    htmlContent = extractHtmlValue(
      htmlTemplate,
      accountType,
      docType,
      dataDetails,
      getCompany,
      page,
      pageLength,
      getUsersByAccountAndSoftware
    )
    let parser = new DOMParser()
    let doc = parser.parseFromString(htmlContent, 'text/html')

    // let PageFooter = doc.getElementById('PageFooter')
    // if (PageFooter) {
    //   PageFooter.style.position = 'absolute'
    //   PageFooter.style.top = '1120px'
    //   PageFooter.style.width = '178.5mm'
    // }

    // Cater for header and footer
    /// Remove footer for first page
    if (page !== pageLength - 1) {
      let PageFooter = doc.getElementById('PageFooter')
      let ItemFooter = doc.getElementById('ItemFooter')
      if (PageFooter) {
        PageFooter.parentNode.removeChild(PageFooter)
      }
      if (ItemFooter) {
        ItemFooter.parentNode.removeChild(ItemFooter)
      }
    }

    /// Remove Header for last page
    if (page !== 0) {
      let PageHeader = doc.getElementById('PageHeader')
      if (PageHeader) {
        PageHeader.parentNode.removeChild(PageHeader)
      }
    }

    htmlContent = doc.documentElement.outerHTML

    return htmlContent
  }

  return (
    <div
      style={
        width < 769 && isView === true
          ? {
              width: `0`,
              height: `0`,
              transform: `scale(0.5)`,
            }
          : {}
      }
      dangerouslySetInnerHTML={{ __html: ReturnHTML() }}
    />
  )
}

export default ELetterPreview
