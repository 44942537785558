import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'
import { format } from 'date-fns'
import React from 'react'

export const FooterPR = (props: any) => {
  const [width] = useMediaQuery()
  const { data, docType, getUser } = props

  let fontSize
  if (width < 769) {
    fontSize = 6
  } else {
    fontSize = 9
  }

  return (
    <>
      {docType !== 'refund' ? (
        <div style={{ margin: '10px' }}>
          <div>
            <table
              style={{
                marginTop: '20px',
                marginBottom: '20px',
                width: '100%',
                fontSize,
              }}
            >
              <tr>
                <th
                  style={{
                    fontWeight: 'normal',
                    textAlign: 'left',
                    width: '25%',
                  }}
                >
                  <div
                    style={{
                      border: '0.5px solid',
                      marginRight: '5px',
                      padding: '5px',
                    }}
                  >
                    <span>
                      <b>Prepared By: </b>
                      <br />
                      <br />
                      {getUser
                        ?.filter(v => v?.ID === data?.createdBy)
                        .map(v => {
                          return v?.name
                        })}
                      <br />
                      <b>Date: </b>
                      {format(new Date(data?.createdTs), 'dd/MM/yyyy')}
                    </span>
                  </div>
                </th>

                {!!data?.approvedBy && docType !== 'payment' ? (
                  <th
                    style={{
                      fontWeight: 'normal',
                      textAlign: 'left',
                      width: '25%',
                    }}
                  >
                    <div
                      style={{
                        border: '0.5px solid',
                        marginRight: '5px',
                        marginLeft: '5px',
                        padding: '5px',
                      }}
                    >
                      <span>
                        <b>Approved By: </b>
                        <br />
                        <br />
                        {getUser
                          ?.filter(v => v?.ID === data?.approvedBy)
                          .map(v => {
                            return v?.name
                          })}
                        <br />
                        <b>Date: </b>
                        {format(new Date(data?.approvedTs), 'dd/MM/yyyy')}
                      </span>
                    </div>
                  </th>
                ) : docType === 'payment' &&
                  data?.ApprovalStatus === 'SUBMIT' ? (
                  <th
                    style={{
                      fontWeight: 'normal',
                      textAlign: 'left',
                      width: '25%',
                    }}
                  >
                    <div
                      style={{
                        border: '0.5px solid',
                        marginRight: '5px',
                        marginLeft: '5px',
                        padding: '5px',
                      }}
                    >
                      <span>
                        <b>Verified By: </b>
                        <br />
                        <br />
                        <br />
                        <b>Date: </b>
                      </span>
                    </div>
                  </th>
                ) : (
                  <th
                    style={{
                      fontWeight: 'normal',
                      textAlign: 'left',
                      width: '25%',
                      marginRight: '5px',
                      marginLeft: '5px',
                    }}
                  ></th>
                )}

                <th
                  style={{
                    fontWeight: 'normal',
                    textAlign: 'left',
                    width: '25%',
                  }}
                >
                  <div
                    style={{
                      border: '0.5px solid',
                      marginRight: '5px',
                      marginLeft: '5px',
                      padding: '5px',
                    }}
                  >
                    <span>
                      <b>Authorized Signatory: </b>
                      <br />
                      <br /> <br />
                      <b>Date: </b>
                    </span>
                  </div>
                </th>

                <th
                  style={{
                    fontWeight: 'normal',
                    textAlign: 'left',
                    width: '25%',
                  }}
                >
                  <div
                    style={{
                      border: '0.5px solid',
                      marginLeft: '5px',
                      padding: '5px',
                    }}
                  >
                    <span>
                      <b>Received By: </b>
                      <br />
                      <br /> <br />
                      <b>Date: </b>
                    </span>
                  </div>
                </th>
              </tr>
            </table>
          </div>
        </div>
      ) : (
        <div style={{ margin: '10px' }}>
          <div>
            <table
              style={{
                marginTop: '20px',
                marginBottom: '20px',
                width: '100%',
                fontSize,
              }}
            >
              <tr>
                <th
                  style={{
                    fontWeight: 'normal',
                    textAlign: 'left',
                    width: '33.333%',
                  }}
                >
                  <div
                    style={{
                      border: '0.5px solid',
                      marginRight: '5px',
                      padding: '5px',
                    }}
                  >
                    <span>
                      <b>Prepared By: </b>
                      <br />
                      <br />
                      {getUser
                        ?.filter(v => v?.ID === data?.createdBy)
                        .map(v => {
                          return v?.name
                        })}
                      <br />
                      <b>Date: </b>
                      {format(new Date(data?.createdTs), 'dd/MM/yyyy')}
                    </span>
                  </div>
                </th>

                <th
                  style={{
                    fontWeight: 'normal',
                    textAlign: 'left',
                    width: '33.333%',
                  }}
                >
                  <div
                    style={{
                      border: '0.5px solid',
                      marginRight: '5px',
                      marginLeft: '5px',
                      padding: '5px',
                    }}
                  >
                    <span>
                      <b>Verified By: </b>
                      <br />
                      <br />
                      {getUser
                        ?.filter(v => v?.ID === data?.submittedBy)
                        .map(v => {
                          return v?.name
                        })}
                      <br />
                      <b>Date: </b>
                      {format(new Date(data?.submittedTs), 'dd/MM/yyyy')}
                    </span>
                  </div>
                </th>

                {!!data?.approvedBy ? (
                  <th
                    style={{
                      fontWeight: 'normal',
                      textAlign: 'left',
                      width: '33.333%',
                    }}
                  >
                    <div
                      style={{
                        border: '0.5px solid',
                        marginLeft: '5px',
                        padding: '5px',
                      }}
                    >
                      <span>
                        <b>Approved By: </b>
                        <br />
                        <br />
                        {getUser
                          ?.filter(v => v?.ID === data?.approvedBy)
                          .map(v => {
                            return v?.name
                          })}
                        <br />
                        <b>Date: </b>
                        {format(new Date(data?.approvedTs), 'dd/MM/yyyy')}
                      </span>
                    </div>
                  </th>
                ) : (
                  <th
                    style={{
                      fontWeight: 'normal',
                      textAlign: 'left',
                      width: '33.333%',
                      marginRight: '5px',
                      marginLeft: '5px',
                    }}
                  ></th>
                )}
              </tr>
            </table>
          </div>
        </div>
      )}
    </>
  )
}

export default FooterPR

const style = {
  center: {
    fontSize: 5,
    right: 150,
    bottom: '10px',
    padding: '185px 10px 10px 10px',
  },
}
