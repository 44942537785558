import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import {
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import Fuse from 'fuse.js'
import {
  useCreditorAccountTotalDueQuery,
  useGetCreditorAccountCompanyAssignmentListingLazyQuery,
  useGetCreditorLatestUpdatedDateQuery,
  useGetCreditorTotalDocAmtQuery,
  useGetOsCreditorCreditDocLazyQuery,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import {
  checkDueDate,
  formatDate,
} from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useHistory, useParams } from 'react-router'

export const CreditorAccountListing = (props: any) => {
  const getSearch = localStorage?.getItem('searchFilter')
  let history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [osDialog, setOsDialog] = useState(false)
  const [creditorName, setCreditorName] = useState('')
  const [TotalLength, setTotalLength] = useState(0)

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()

  const {
    CompanyID,
    CreditorAccountID,
    CreditorAccountCompanyAssignmentID,
  }: any = useParams()

  //Creditor Account Company Assignment//
  const [
    loadCreditorCompany,
    {
      loading: CreditorAccountCompanyAssignmentLoading,
      error: CreditorAccountCompanyAssignmentError,
      data: { getCreditorAccountCompanyAssignmentListing } = {
        getCreditorAccountCompanyAssignmentListing: [],
      },
    },
  ] = useGetCreditorAccountCompanyAssignmentListingLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      // CreditorAccountID: CreditorAccountID,
    },
    onCompleted: ({ getCreditorAccountCompanyAssignmentListing }) => {
      if (getCreditorAccountCompanyAssignmentListing?.Data?.length > 0) {
        setOriginalListing([
          ...filteredList,
          ...getCreditorAccountCompanyAssignmentListing?.Data,
        ])
        setTotalLength(getCreditorAccountCompanyAssignmentListing?.TotalLength)
      }
    },
  })

  //Creditor Account TotalDue//
  const {
    loading: CreditorAccountTotalDueLoading,
    error: CreditorAccountTotalDueError,
    data: { CreditorAccountTotalDue } = { CreditorAccountTotalDue: [] },
  } = useCreditorAccountTotalDueQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
  })

  const {
    loading: CreditorTotalDocAmtLoading,
    called: CreditorTotalDocAmtCalled,
    data: { getCreditorTotalDocAmt } = { getCreditorTotalDocAmt: [] },
  } = useGetCreditorTotalDocAmtQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
  })

  const {
    loading: CreditorLatestUpdatedDateLoading,
    called: CreditorLatestUpdatedDateCalled,
    data: { getCreditorLatestUpdatedDate } = {
      getCreditorLatestUpdatedDate: [],
    },
  } = useGetCreditorLatestUpdatedDateQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
  })

  const [
    loadAPCreditDoc,
    {
      loading: apDocLoading,
      error: apDocError,
      data: {
        getAPInvoice,
        getAPDebitNote,
        getAPCreditNote,
        getAPPayment,
        getAPRefund,
      } = {
        getAPInvoice: [],
        getAPDebitNote: [],
        getAPCreditNote: [],
        getAPPayment: [],
        getAPRefund: [],
      },
    },
  ] = useGetOsCreditorCreditDocLazyQuery({
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    loadCreditorCompany({
      variables: {
        CompanyID: CompanyID,
        take: 30,
        skip: 0,
      },
    })
  }, [])

  let outstandingCn: any[] = []
  let outstandingDn: any[] = []
  let outstandingInvoices: any[] = []
  let outstandingPayment: any[] = []
  let outstandingRefund: any[] = []

  outstandingInvoices = getAPInvoice.filter(inv => inv.BalanceAmt > 0)

  outstandingCn = getAPCreditNote
    ?.filter(ldg => ldg.BalanceAmt > 0)
    .map(ldg => ({ ...ldg, Negative: true }))

  outstandingDn = getAPDebitNote.filter(dn => dn.BalanceAmt > 0)

  outstandingPayment = getAPPayment
    ?.filter(ldg => ldg.BalanceAmt > 0)
    ?.map(ldg => {
      return { ...ldg, Negative: true }
    })
  outstandingRefund = getAPRefund?.filter(ldg => ldg.BalanceAmt > 0)

  const outstandingArray: any[] = [
    ...outstandingInvoices,
    ...outstandingCn.map((cnItem: any) => ({
      ...cnItem,
      BalanceAmt: -cnItem.BalanceAmt,
    })),
    ...outstandingDn,
    ...outstandingPayment.map((pItem: any) => ({
      ...pItem,
      BalanceAmt: -pItem.BalanceAmt,
    })),
    ...outstandingRefund,
  ]?.sort((a, b) => (a?.DocDate > b?.DocDate ? 1 : -1))

  const handleOSDialog = associateID => {
    setOsDialog(true)
    loadAPCreditDoc({
      variables: { CompanyID: CompanyID, CreditorAccountID: associateID },
    })
  }

  // useEffect(() => {
  //   if (getCreditorAccountCompanyAssignment?.length > 0) {
  //     setOriginalListing(getCreditorAccountCompanyAssignment)
  //   }
  // }, [getCreditorAccountCompanyAssignment])

  let newFilteredList = filteredList
    ?.map(x => {
      return x?.CreditorAccount
    })
    ?.sort((a: any, b: any) =>
      a?.CompanyName < b?.CompanyName
        ? -1
        : a?.CompanyName > b?.CompanyName
        ? 1
        : 0
    )

  let newFilteredListWithTotalDocAmt = newFilteredList.map(subject => {
    let otherSubject = getCreditorTotalDocAmt.find(
      element => element.CreditorAccountID === subject.CreditorAccountID
    )
    let TotalDueAmount = CreditorAccountTotalDue.find(
      f => f?.CreditorAccountID === subject?.CreditorAccountID
    )
    let LatestUpdatedDate = getCreditorLatestUpdatedDate.find(
      e => e?.CreditorAccountID === subject?.CreditorAccountID
    )
    return {
      ...subject,
      ...otherSubject,
      TotalDueAmount,
      LatestUpdatedDate,
    }
  })

  useEffect(() => {
    if (getSearch && !!originalList) {
      const keys = ['CreditorAccount.CompanyName']
      const options = {
        shouldSort: true,
        threshold: 0.6,
        ignoreLocation: true,
        keys: keys,
      }

      const myFuse = new Fuse(originalList, options)
      const result = myFuse.search(getSearch)
      const val = result?.map(x => x.item)
      if (val.length !== 0) {
        handleSearch(getSearch + '', keys)
      }
    }
  }, [getSearch, originalList])

  const BalAmt = outstandingArray?.reduce(
    (total, curValue) => total + curValue?.BalanceAmt,
    0
  )

  //to make delay for search function for the query to load

  const [timer, setTimer] = useState(null)
  function changeDelay(change) {
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }

    setTimer(
      setTimeout(() => {
        setOriginalListing([])
        loadCreditorCompany({
          variables: {
            CompanyID: CompanyID,
            skip: 0,
            take: 30,
            searchValue: change,
          },
        })
      }, 1000)
    )
  }

  return (
    <>
      {apDocLoading && <Loading />}
      {CreditorTotalDocAmtLoading && <Loading />}
      {CreditorAccountTotalDueLoading && <Loading />}
      {CreditorLatestUpdatedDateLoading && <Loading />}
      {CreditorAccountCompanyAssignmentLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/account-payable/${CompanyID}`)
          localStorage.removeItem('searchFilter')
        }} //<- back button action
        smTitle={'Account Payable'}
        title={user?.companyName}
        routeSegments={[
          { name: 'AP' },
          { name: 'Creditor Accounts', current: true },
        ]}
      />

      <div className="search-filter with-dropdown-filter ">
        <SearchHeader
          title="Creditor Listing"
          value={`${TotalLength}`}
          search
          onChangeAction={e => {
            // handleSearch(e?.target?.value, ['CompanyName'])
            localStorage.setItem('searchFilter', e?.target?.value)
            changeDelay(e.target.value)
          }}
          defaultValue={getSearch ? getSearch : ''}
          isDefaultValue={!!getSearch}
          onCloseAction={() => {
            handleSearch('', [])
            localStorage.removeItem('searchFilter')
            setOriginalListing([])
            loadCreditorCompany({
              variables: {
                CompanyID: CompanyID,
                skip: 0,
                take: 30,
              },
            })
          }}
        />
      </div>

      <ContentWrapper footer overflow>
        <List className="core-list">
          {newFilteredListWithTotalDocAmt === undefined ||
          newFilteredListWithTotalDocAmt?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            <InfiniteScroll
              dataLength={filteredList?.length}
              next={() => {
                const currentLength = filteredList?.length
                loadCreditorCompany({
                  variables: {
                    CompanyID: CompanyID,
                    skip: currentLength,
                    take: 30,
                    searchValue: getSearch === '' ? undefined : getSearch,
                  },
                })
              }}
              hasMore={true}
              className=""
              style={{ padding: '8px 6px' }}
              loader={
                CreditorAccountCompanyAssignmentLoading && (
                  <div style={{ textAlign: 'center' }}>
                    {' '}
                    <CircularProgress />{' '}
                  </div>
                )
              }
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>-</b>
                </p>
              }
            >
              {newFilteredListWithTotalDocAmt?.map((el, index) => {
                return (
                  <ListItem
                    key={index}
                    // onClick={() =>
                    //   history.push({
                    //     pathname: `/account-payable/${CompanyID}/creditor-account/creditor-profile/${el.CreditorAccountID}`,
                    //     state: el?.CreditorAccountID,
                    //   })
                    // }
                  >
                    <ListItemText
                      primary={
                        <>
                          <span className="xsTitle flex-space">
                            {el?.CompanyName}
                          </span>
                          <span
                            className="xsTitle c-orange click-text"
                            onClick={(e: any) => {
                              handleOSDialog(el?.CreditorAccountID)
                              setCreditorName(el?.CompanyName)
                            }}
                          >
                            {el?.TotalOutstandingAmt < 0
                              ? `(${amtStr(el?.TotalOutstandingAmt * -1)})`
                              : amtStr(el?.TotalOutstandingAmt)}
                          </span>
                        </>
                      }
                      secondary={
                        <span className="desc">
                          <span className="fw-medium p-r-3">Total Due:</span>
                          <span>
                            <span className="desc p-r-3">
                              {amtStr(el?.TotalDueAmount?.TotalDue)}
                            </span>
                            <span>|</span>
                            <span className="desc p-l-3">
                              {`Last Trxn On: ${formatDate(
                                el?.LatestUpdatedDate?.LatestUpdatedDate
                              )}`}
                            </span>
                          </span>
                        </span>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete">
                        <KeyboardArrowRight
                          onClick={() =>
                            history.push({
                              pathname: `/account-payable/${CompanyID}/creditor-account/creditor-profile/${el.CreditorAccountID}`,
                              state: el?.CreditorAccountID,
                            })
                          }
                        />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })}
            </InfiniteScroll>
          )}
        </List>
      </ContentWrapper>

      <CommonDialog
        fullWidth={true}
        open={osDialog}
        onClose={() => setOsDialog(false)}
        sections={{
          header: {
            title: creditorName,
            infoLine: 'Owing Amount',
            rightInfoLine: (() => {
              return BalAmt < 0 ? `(${amtStr(BalAmt * -1)})` : amtStr(BalAmt)
            })(),
          },
          body: () => (
            <>
              <List className="core-list">
                {outstandingArray?.length === 0 ||
                outstandingArray === undefined ? (
                  <EmptyList title={'No Outstanding Document'} />
                ) : (
                  outstandingArray?.map((el, index) => (
                    <ListItem key={index}>
                      <ListItemText
                        primary={
                          <>
                            <span className="desc date-width">
                              {formatDate(el?.DocDate)}
                            </span>
                            <span className="mdLabel flex-space">
                              {el?.DocNo}
                            </span>
                            <span className="xxTitle">
                              <IconText
                                font="desc xxTitle"
                                children={
                                  el?.Negative
                                    ? `-${amtStr(el?.DocAmt)}`
                                    : amtStr(el?.DocAmt)
                                }
                                childrenStyle={{
                                  color: el?.Negative ? 'red' : '',
                                }}
                              />
                            </span>
                          </>
                        }
                        secondary={
                          <>
                            {!!el?.DueDate && (
                              <span
                                className="desc date-width"
                                style={{
                                  color: checkDueDate(el?.DueDate)
                                    ? 'red'
                                    : null,
                                }}
                              >
                                {formatDate(el?.DueDate)}
                              </span>
                            )}
                            <span className="desc flex-space text-overflow">
                              {el?.Description}
                            </span>
                            <span className="xxTitle">
                              <IconText
                                font=" desc xxTitle"
                                children={amtStr(el?.BalanceAmt)}
                              />
                            </span>
                          </>
                        }
                      />
                    </ListItem>
                  ))
                )}
              </List>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => setOsDialog(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
    </>
  )
}
