import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import AppContext from 'containers/App/Store/AppContext'
import { AcctPermission } from 'generated/graphql'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import React, { useContext, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'

export const APDigitalReportSubmenu = (props: any) => {
  const { globalState, dispatch }: any = useContext(AppContext as any)
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const { handlePermHidden } = usePermissionChecker()
  let user = JSON.parse(localStorage.getItem('loggedInUser'))

  // ACCOUNTX API CALLS

  const submenuList = [
    {
      path: `/account-payable/${CompanyID}/digital-report/ap-advance-ledger/parameters`,
      title: `Advance Ledger`,
      permission: AcctPermission.AccPayableDigitalReportOverduePayableView,
      deployed: true,
    },
    {
      path: `/account-payable/${CompanyID}/digital-report/ap-overdue-payable/parameters`,
      title: `Overdue Payable`,
      permission: AcctPermission.AccPayableDigitalReportOverduePayableView,
      deployed: true,
    },
    {
      path: `/account-payable/${CompanyID}/digital-report/ap-creditor-ledger-detail-by-doc-date/parameters`,
      title: `Creditor Ledger Detail By Document Date`,
      permission:
        AcctPermission.AccPayableDigitalReportCreditorLedgerByDocDateView,
      deployed: true,
    },
    {
      path: `/account-payable/${CompanyID}/digital-report/ap-creditor-ledger-detail-by-trx-date/parameters`,
      title: `Creditor Ledger Detail By Transaction Date`,
      // permission:
      //   AcctPermission.AccPayableDigitalReportCreditorLedgerByDocDateView,
      deployed: true,
    },
    {
      path: `/account-payable/${CompanyID}/digital-report/ap-creditor-aging-detail-by-doc-date/parameters`,
      title: `Creditor Aging Detail By Document Date`,
      permission:
        AcctPermission.AccPayableDigitalReportCreditorAgingByDocDateView,
      deployed: true,
    },
    {
      path: `/account-payable/${CompanyID}/digital-report/ap-creditor-aging-detail-by-trx-date/parameters`,
      title: `Creditor Aging Detail By Transaction Date`,
      // permission:
      //   AcctPermission.AccPayableDigitalReportCreditorAgingByDocDateView,
      deployed: true,
    },
    // {
    //   path: `/account-payable/${CompanyID}/digital-report/ap-creditor-aging-summary-trx-date/parameters`,
    //   title: `Creditor Aging Summary By Transaction Date`,
    //   // permission:
    //   //   AcctPermission.AccPayableDigitalReportCreditorAgingByDocDateView,
    // },
    {
      path: `/account-payable/${CompanyID}/digital-report/ap-creditor-aging-summary-doc-date/parameters`,
      title: `Creditor Aging Summary By Document Date`,
      // permission:
      //   AcctPermission.AccPayableDigitalReportCreditorAgingByDocDateView,
      deployed: true,
    },
    {
      path: `/account-payable/${CompanyID}/digital-report/ap-creditor-aging-summary-trx-date/parameters`,
      title: `Creditor Aging Summary By Transaction Date`,
      // permission:
      //   AcctPermission.AccPayableDigitalReportCreditorAgingByDocDateView,
      deployed: true,
    },
    // {
    //   path: `/general-ledger/${CompanyID}/digital-report/cash-ledger-listing-report`,
    //   title: `Cash Ledger Listing`,
    // },
    {
      path: `/account-payable/${CompanyID}/digital-report/ap-creditor-control-summary/parameters`,
      title: `Creditor Control Summary`,
      // permission:
      //   AcctPermission.AccPayableDigitalReportCreditorAgingByDocDateView,
      deployed: true,
    },
    {
      path: `/account-payable/${CompanyID}/digital-report/ap-creditor-control-detail/parameters`,
      title: `Creditor Control Detail`,
      // permission:
      //   AcctPermission.AccPayableDigitalReportCreditorAgingByDocDateView,
      deployed: true,
    },
    process.env.REACT_APP_LOCAL_DB !== 'promenade' && {
      path: `/account-payable/${CompanyID}/digital-report/e-invoice-listing/parameters`,
      title: `e-Invoice Listing`,
      // permission:
      //   AcctPermission.AccPayableDigitalReportCreditorAgingByDocDateView,
      deployed: true,
    },
  ]

  return (
    <>
      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/account-payable/${CompanyID}`)}
        smTitle={'Account Payable'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Digital Reports', current: true },
        ]}
      />
      <ContentWrapper float>
        <List className="core-list">
          {submenuList.map((el, index) => {
            if (
              el?.deployed &&
              handlePermHidden({
                // companyID: CompanyID,
                permEnum: el?.permission,
              })
            )
              return (
                <ListItem key={index} onClick={() => history.push(el?.path)}>
                  <ListItemText
                    style={{
                      textAlign: 'right',
                      marginLeft: '5px',
                    }}
                    primary={<span className="xsTitle">{el?.title}</span>}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                      <KeyboardArrowRight
                        onClick={() =>
                          history.push({
                            pathname: el.path,
                          })
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
          })}
        </List>
      </ContentWrapper>

      {/* FOOTER */}
    </>
  )
}
