import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { List, ListItem, ListItemText } from '@material-ui/core'
import {
  useDocumentListingQuery,
  useGetStockItemDetailQuery,
} from 'generated/graphql'
import { attachmentDetailView } from 'helpers/Hooks/attachmentDetailsView'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'

export const StockItemDetail = (props: any) => {
  const { mode }: any = props
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  let history = useHistory()
  const { StockItemID, StockCategoryID, WarehouseID }: any = useParams()
  let location = useLocation()
  const editData = location?.state as any
  const [warehouseData, setWarehouseData] = useState([])

  // QUERY //

  const {
    loading: StockItemLoading,
    error: StockItemError,
    data: { getStockItem } = {
      getStockItem: [],
    },
  } = useGetStockItemDetailQuery({
    fetchPolicy: 'network-only',
    variables: {
      StockItemID: StockItemID,
    },
  })

  const {
    loading: documentLoading,
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: StockItemID,
    },
  })

  useEffect(() => {
    if (mode === 'view' && editData) {
      const Warehouse = editData?.StockItemWarehouse?.map((el, index) => {
        return {
          WarehouseID: el?.WarehouseID,
          Name: el?.Warehouse?.Name,
          MaximumQty: el?.MaximumQty,
          MinimumQty: el?.MinimumQty,
          ReorderLevel: el?.ReorderLevel,
          ReorderQty: el?.ReorderQty,
        }
      })

      warehouseData.push(...Warehouse)
    }
  }, [mode, editData])

  let routeSegments, pathname, lgTitle
  switch (mode) {
    case 'view':
      routeSegments = 'Stock Item'
      pathname = `/inventory-control/general-setting/stock-item/${StockCategoryID}`
      lgTitle = `${user?.accountName}`
      break
    case 'enquiry':
      routeSegments = 'Stock Profile'
      pathname = `/inventory-control/${WarehouseID}/stock-enquiry`
      lgTitle = `${editData?.Warehouse?.Name}`
      break

    default:
      break
  }

  return (
    <>
      {StockItemLoading && <Loading />}
      {documentLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => history.push(pathname)}
        smTitle="Inventory Control"
        title={lgTitle}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Inventory Control' },
          { name: routeSegments, current: true },
        ]}
      />

      <ContentWrapper>
        <CardContents
          section={{
            header: {
              title: 'Stock Item Details',
            },
          }}
        >
          <div className="content-wrap left">
            <div className="desc">Name</div>
            <div className="xsTitle">{getStockItem[0]?.Name}</div>
          </div>
          <div className="content-wrap left">
            <div className="desc">Stock Category</div>
            <div className="xsTitle">
              {getStockItem[0]?.StockCategory?.Name}
            </div>
          </div>
          <div className="content-wrap full">
            <div className="desc">Description</div>
            <div className="xsTitle text-noflow">
              {getStockItem[0]?.Description}
            </div>
          </div>
          <div className="content-wrap left">
            <div className="desc">UOM</div>
            <div className="xsTitle">{getStockItem[0]?.UOM?.Name}</div>
          </div>
          <div className="content-wrap right">
            <div className="desc">Tax Code</div>
            <div className="xsTitle">{getStockItem[0]?.TaxScheme?.Code}</div>
          </div>
          <div className="content-wrap full">
            <div className="desc">Class</div>
            <div className="xsTitle">{getStockItem[0]?.ABCClass}</div>
          </div>

          <div className="content-wrap left">
            <div className="desc">Class</div>
            <div className="xsTitle">{getStockItem[0]?.ABCClass}</div>
          </div>
          <div className="content-wrap left">
            <div className="desc">Shelf Life (Days)</div>
            <div className="xsTitle">{getStockItem[0]?.ShelfLife}</div>
          </div>
          <div className="content-wrap right">
            <div className="desc">Standard Cost</div>
            <div className="xsTitle">{getStockItem[0]?.StandardCost}</div>
          </div>
          <div className="content-wrap full">
            <div className="desc">Remark</div>
            <div className="xsTitle text-noflow">{getStockItem[0]?.Remark}</div>
          </div>
          <div className="content-wrap full">
            <div className="desc">Attachment</div>
            {attachmentDetailView(DocumentListing)}
          </div>
          <div className="content-wrap left">
            <div className="desc">Category Account Code</div>
            <div className="xsTitle text-noflow">
              {!!getStockItem[0]?.StockCategory?.StockAccountCodeID
                ? ` ${getStockItem[0]?.StockCategory?.StockAccountCode?.Code} | ${getStockItem[0]?.StockCategory?.StockAccountCode?.Name}`
                : `${getStockItem[0]?.StockCategory?.ParentCategoryMCOA?.MasterCOACode} | ${getStockItem[0]?.StockCategory?.ParentCategoryMCOA?.MasterCOAName}`}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc">Status</div>
            <div
              className="xsTitle"
              style={{ display: 'flex', marginTop: '3px' }}
            >
              {getStockItem[0]?.RecordStatus === 'ACTIVE' && (
                <div
                  className="blue-status"
                  style={{
                    padding: '5px',
                    margin: '0px',
                    borderRadius: '2px',
                  }}
                >
                  Active
                </div>
              )}
              {getStockItem[0]?.RecordStatus === 'INACTIVE' && (
                <div
                  className="grey-status"
                  style={{
                    padding: '5px',
                    margin: '0px',
                    borderRadius: '2px',
                  }}
                >
                  Inactive
                </div>
              )}
            </div>
          </div>
        </CardContents>

        {/* {mode === 'view' && ()} */}
        <CardContents
          section={{
            header: {
              title: mode === 'view' ? 'Warehouses' : 'Warehouse',
            },
          }}
        >
          <List
            style={{ width: '100%', paddingBottom: '0px' }}
            //className={addStyle.list}
          >
            {mode === 'view' ? (
              warehouseData.map((el, index) => {
                return (
                  <ListItem
                    key={index}
                    style={{ marginLeft: '-5px', marginTop: '-15px' }}
                  >
                    <ListItemText
                      primary={
                        <>
                          <span className="xsTitle">
                            {index + 1} . &nbsp;{el.Name}{' '}
                          </span>
                        </>
                      }
                      secondary={
                        <>
                          <div className="desc">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Max. Qty : &nbsp;
                            {el.MaximumQty} &nbsp; | &nbsp; Min. Qty : &nbsp;{' '}
                            {el.MinimumQty}
                          </div>
                          <div className="desc">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Reorder Lvl : &nbsp;
                            {el.ReorderLevel} &nbsp; | &nbsp; Reorder Qty :
                            &nbsp; {el.ReorderQty}
                          </div>
                        </>
                      }
                    />
                  </ListItem>
                )
              })
            ) : (
              <ListItem style={{ marginLeft: '-5px', marginTop: '-15px' }}>
                <ListItemText
                  primary={
                    <>
                      <span className="xsTitle">
                        {editData?.Warehouse?.Name}{' '}
                      </span>
                    </>
                  }
                  secondary={
                    <>
                      <div className="desc">
                        Max. Qty : &nbsp;
                        {editData?.MaximumQty} &nbsp; | &nbsp; Min. Qty : &nbsp;{' '}
                        {editData?.MinimumQty}
                      </div>
                      <div className="desc">
                        Reorder Lvl : &nbsp;
                        {editData?.ReorderLevel} &nbsp; | &nbsp; Reorder Qty :
                        {editData?.ReorderQty}
                      </div>
                    </>
                  }
                />
              </ListItem>
            )}
          </List>
        </CardContents>
      </ContentWrapper>
    </>
  )
}
