import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import CardExpansion from '@ifca-root/react-component/src/components/CardList/CardExpansion';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@material-ui/core';
import BigNumber from 'bignumber.js';
import { formatDate } from '../../../../helpers/StringNumberFunction/formatDate';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useFuseSearch } from 'helpers/Hooks/useSearch';
import Fuse from 'fuse.js';
import localforage from 'localforage';
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar';

export const GeneralGRTNContent = props => {
  const { doArr, GRTNLoading, stopFlag, setStopFlag }: any = props;
  const [itemHasChanges, setItemChanges] = useState([]);

  return (
    <>
      {!GRTNLoading && doArr?.length ? (
        <InputContentDetails
          doMap={doArr}
          {...props}
          headerTitle="GRTN Item"
          setItemChanges={setItemChanges}
          itemHasChanges={itemHasChanges}
          stopFlag={stopFlag}
          setStopFlag={setStopFlag}
        />
      ) : null}
    </>
  );
};

export const InputContentDetails = props => {
  const {
    state,
    rpaResult,
    GRTNLoading,
    doMap,
    doArr,
    headerTitle,
    mode,
    editData,
    setPO,
    itemHasChanges,
    setItemChanges,
    setNoError,
    DOHeaderID,
    useFormProps: {
      clearErrors,
      setError,
      errors,
      watch,
      register,
      control,
      setValue,
    },
    itemHasValueQty,
    itemHasValueAmt,
    CompanyID,
    selectedDate,
    stopFlag,
    setStopFlag,
  } = props;

  //=============================================//

  //-------------------SEARCH--------------------//

  //=============================================//

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch();

  const getSearch = JSON.parse(localStorage.getItem('searchFilter'));

  useEffect(() => {
    const keys = [
      // 'docDateDateFormatted',
      'Description',
      'DocNo',
      'DoNo',
      'DOItem.POItem.Item.Name',
    ];

    const options = {
      shouldSort: true,

      threshold: 0.6,

      ignoreLocation: true,

      keys: keys,
    };

    if (getSearch?.name && filteredList) {
      const myFuse = new Fuse(originalList, options);
      const result = myFuse.search(getSearch?.name);
      const val = result?.map(x => x.item);
      handleSearch(getSearch?.name + '', keys);
    }
  }, []);

  useEffect(() => {
    if (doMap && doMap?.length > 0) {
      setOriginalListing(doMap, v => {
        v['docDateDateFormatted'] = formatDate(v?.DocDate);
      });

      setStopFlag(false);
    }
  }, [doMap]);

  useEffect(() => {
    if (originalList && watch('getDO') && stopFlag === false) {
      localforage.removeItem('getDO');
      localforage.setItem('getDO', watch(`getDO`));
      setStopFlag(true);
    }
  }, [watch(`getDO`), originalList]);

  const checkItemHasValueQty = () => {
    const hasValue = Object.values(watch(`getDO`) || {})?.map((v: any) =>
      v?.DOItem?.map(s =>
        s?.ReturnedQty
          ? parseFloat(s?.ReturnedQty)
          : editData?.GRTNHeaderItem?.DOItem?.find(
              a => a?.doItemID === s?.doItemID,
            )?.ReturnedQty,
      ),
    );

    itemHasValueQty(hasValue?.map(v => v?.some(s => s > 0)));
  };

  useEffect(() => {
    if (editData || itemHasChanges) {
      checkItemHasValueQty();
    }
  }, [editData, itemHasChanges]);

  const sortingFormatDate = dateString => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const day = `${date.getDate()}`.padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const formattedDate = sortingFormatDate(selectedDate);

  function amtStrNoRounding(number) {
    if (typeof number !== 'number' || isNaN(number)) {
      return '';
    }
    const parts = number.toString().split('.');
    if (parts[1] && parts[1].length < 4) {
      parts[1] = parts[1].padEnd(4, '0');
    } else if (!parts[1]) {
      parts.push('0000');
    }

    return parts.join('.');
  }

  const [isQtyDisabled, setIsQtyDisabled] = useState(false);
  const [isAmtDisabled, setIsAmtDisabled] = useState(false);

  const handleQtyChange = event => {
    const value = event.target.value;
    setIsAmtDisabled(value !== '');
  };

  const handleAmtChange = event => {
    const value = event.target.value;
    setIsQtyDisabled(value !== '');
  };

  const updateGetDO = (el, initem) => {
    let getDO: any;

    localforage.getItem('getDO', function(err, object) {
      getDO = object;

      getDO[el?.DOHeaderID].DOItem[initem].ReturnedAmt = watch(
        `getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedAmt`,
      );

      getDO[el?.DOHeaderID].DOItem[initem].ReturnedQty = watch(
        `getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedQty`,
      );

      getDO[el?.DOHeaderID].DOItem[initem].replacement = watch(
        `getDO[${el?.DOHeaderID}].DOItem[${initem}].replacement`,
      );

      localforage.setItem('getDO', getDO);
    });
  };

  return (
    <>
      {GRTNLoading && <Loading />}
      <CardContents
        contentStyle={{ display: 'block' }}
        section={{
          header: {
            title: headerTitle,
          },
        }}
      >
        <div className="search-filter " style={{ marginBottom: '5px' }}>
          <SearchBar
            borderRadius="4px"
            onChangeAction={e => {
              handleSearch(e?.target?.value, [
                // 'docDateDateFormatted',
                'Description',
                'DocNo',
                'DoNo',
                'DOItem.POItem.Item.Name',
              ]);

              localStorage.setItem(
                'searchFilter',
                JSON.stringify({
                  name: e?.target?.value,
                }),
              );
            }}
            onCloseAction={() => {
              handleSearch('', []);
            }}
          />
        </div>
        <List className="core-list">
          {filteredList
            ?.filter(
              m =>
                m?.DOItem?.filter(
                  q =>
                    !(
                      q?.UpToDateQty === 0 &&
                      (editData?.GRTNHeaderItem?.map(x => x?.ReturnedQty) ??
                        0) === 0
                    ),
                )?.length > 0,
            )
            ?.filter(dateFil => dateFil?.DocDate <= formattedDate)
            ?.sort((a, b) => {
              return a.DocNo.localeCompare(b.DocNo);
            })
            ?.map((el, i) => {
              const defaultDOHeaderIDs = editData?.GRTNHeaderItem?.map(
                x => x?.DOItem?.DOHeaderID,
              );

              return (
                <CardExpansion
                  id={`${el?.DOHeaderID}`}
                  expansion={
                    mode === 'edit'
                      ? !!defaultDOHeaderIDs.includes(el?.DOHeaderID)
                      : false
                  }
                  key={`${el?.DOHeaderID}`}
                  summary={
                    <Grid item xs={12}>
                      <ListItemText
                        primary={
                          <>
                            <Grid container className="fw-medium desc">
                              <input
                                name={`getDO[${el?.DOHeaderID}].DOHeaderID`}
                                ref={register}
                                defaultValue={el?.DOHeaderID}
                                style={{ display: 'none' }}
                              />
                              <Grid item xs={9} className="mdLabel">
                                <span>{`${el?.DocNo}  | ${el?.DoNo}`}</span>
                              </Grid>
                              <Grid item xs={3} className="right-text">
                                <span>{formatDate(el?.DocDate)}</span>
                              </Grid>
                            </Grid>
                          </>
                        }
                      />
                    </Grid>
                  }
                >
                  <div className="rm-padding table-wrap">
                    <List
                      className="core-list content-wrap ;"
                      style={{ display: 'contents' }}
                    >
                      {el?.DOItem?.map((inel, initem) => {
                        const OutstandingQty =
                          mode === 'edit'
                            ? inel?.AcceptedQty
                            : inel?.UpToDateQty ?? 0;
                        const returnQty = editData?.GRTNHeaderItem.find(
                          x =>
                            x?.DOItem?.DOHeaderID === inel?.DOHeaderID &&
                            x?.DOItem?.DOItemID === inel?.DOItemID,
                        )?.ReturnedQty;
                        const totalOrdQty = OutstandingQty + returnQty;

                        const balanceQty =
                          totalOrdQty !== undefined
                            ? totalOrdQty > inel?.AcceptedQty
                              ? inel?.AcceptedQty
                              : totalOrdQty
                            : inel?.UpToDateQty;

                        const ReturnedQty =
                          typeof watch(
                            `getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedQty`,
                          ) === 'string' &&
                          watch(
                            `getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedQty`,
                          ).includes(',')
                            ? watch(
                                `getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedQty`,
                              ).replace(/,/g, '')
                            : watch(
                                `getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedQty`,
                              );

                        return (
                          <ListItem
                            key={initem}
                            className="table-listItem"
                            divider={initem > 1 ? true : false}
                            style={{ padding: '0px', backgroundColor: 'white' }}
                          >
                            <ListItemText
                              primary={
                                <Grid container className="desc">
                                  <Grid
                                    item
                                    xs={1}
                                    component="span"
                                    className="desc"
                                    style={{ paddingTop: '10px' }}
                                  >
                                    <span className="xxTitle">{`${initem +
                                      1}.`}</span>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={11}
                                    component="span"
                                    className="desc fw-medium"
                                    style={{ paddingTop: '10px' }}
                                  >
                                    <div
                                      className="xxTitle text-noflow"
                                      style={{
                                        width: '100%',
                                        wordWrap: 'break-word',
                                      }}
                                    >
                                      {inel?.POItem !== null
                                        ? inel?.POItem?.Item?.Name
                                        : inel?.ReplacedGRTNItem?.DOItem?.POItem
                                            ?.Item?.Name}
                                    </div>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={1}
                                    component="span"
                                    className="desc"
                                    style={{ paddingTop: '10px' }}
                                  >
                                    <span className="xxTitle"></span>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={11}
                                    component="span"
                                    className="desc fw-medium"
                                    style={{ paddingTop: '10px' }}
                                  >
                                    <div
                                      className="xxTitle text-noflow"
                                      style={{
                                        width: '100%',
                                        wordWrap: 'break-word',
                                      }}
                                    >
                                      {inel?.POItem !== null
                                        ? inel?.POItem?.CostCentre?.Name
                                        : inel?.ReplacedGRTNItem?.DOItem?.POItem
                                            ?.CostCentre?.Name}
                                    </div>
                                  </Grid>
                                </Grid>
                              }
                              secondary={
                                <>
                                  <Grid container className="desc">
                                    <Grid item xs={1}></Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      component="span"
                                      className="desc"
                                    >
                                      <td style={{ paddingTop: '10px' }}>
                                        {' '}
                                        <div
                                          className="desc"
                                          style={{ color: 'lightslategrey' }}
                                        >
                                          {' '}
                                          <span className="desc fw-medium">
                                            {inel?.POItem !== null
                                              ? inel?.POItem?.UOM?.Code
                                              : inel?.ReplacedGRTNItem?.DOItem
                                                  ?.POItem?.UOM?.Code}
                                          </span>
                                        </div>
                                      </td>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={3}
                                      component="span"
                                      className="desc"
                                    >
                                      <td
                                        style={{
                                          paddingTop: '10px',
                                          width: '100px',
                                        }}
                                      >
                                        <input
                                          name={`getDO[${el?.DOHeaderID}].DOItem[${initem}].doItemID`}
                                          ref={register}
                                          defaultValue={inel?.DOItemID}
                                          style={{ display: 'none' }}
                                        />
                                        <input
                                          name={`getDO[${el?.DOHeaderID}].DOItem[${initem}].UOMID`}
                                          ref={register}
                                          defaultValue={inel?.UOMID}
                                          style={{ display: 'none' }}
                                        />
                                        <Controller
                                          as={<NumberFormat />}
                                          thousandSeparator
                                          disabled
                                          variant="outlined"
                                          label="DO Qty"
                                          className="disabled-input"
                                          autoComplete="off"
                                          customInput={TextField}
                                          allowNegative={false}
                                          fixedDecimalScale
                                          name={`getDO[${el?.DOHeaderID}].DOItem[${initem}].UpToDateQty`}
                                          ref={register}
                                          control={control}
                                          decimalScale={4}
                                          defaultValue={
                                            mode === 'add'
                                              ? inel?.UpToDateQty
                                              : inel?.AcceptedQty
                                          }
                                        />
                                      </td>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={3}
                                      component="span"
                                      className="desc"
                                    >
                                      <td
                                        style={{
                                          paddingTop: '10px',
                                          width: '100px',
                                        }}
                                      >
                                        <Controller
                                          control={control}
                                          ref={register}
                                          label="Rtn Qty"
                                          defaultValue={
                                            mode !== 'add'
                                              ? editData?.GRTNHeaderItem?.filter(
                                                  v =>
                                                    v?.DOItemID ===
                                                    inel?.DOItemID,
                                                )?.length > 0
                                                ? editData?.GRTNHeaderItem?.filter(
                                                    v =>
                                                      v?.DOItemID ===
                                                      inel?.DOItemID,
                                                  )?.map(x => x?.ReturnedQty)[0]
                                                : '.0000'
                                              : '.0000'
                                          }
                                          name={`getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedQty`}
                                          render={({ onChange, value }) => {
                                            return (
                                              <NumberFormat
                                                thousandSeparator
                                                label="Rtn Qty"
                                                value={
                                                  value === '0.0000'
                                                    ? '.0000'
                                                    : value
                                                }
                                                allowNegative={false}
                                                disabled={isQtyDisabled}
                                                // onChange={handleQtyChange}
                                                onChange={e => {
                                                  onChange(e);
                                                  handleQtyChange(e);
                                                  updateGetDO(el, initem);
                                                }}
                                                // onChange={() => {
                                                //   handleQtyChange;
                                                //   updateGetDO(el, initem);
                                                // }}
                                                customInput={TextField}
                                                className="disabled-input"
                                                name={`getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedQty`}
                                                variant="outlined"
                                                decimalScale={4}
                                                fixedDecimalScale
                                                defaultValue={
                                                  mode !== 'add'
                                                    ? editData?.GRTNHeaderItem?.filter(
                                                        v =>
                                                          v?.DOItemID ===
                                                          inel?.DOItemID,
                                                      )?.length > 0
                                                      ? editData?.GRTNHeaderItem.find(
                                                          x =>
                                                            x?.DOItem
                                                              ?.DOHeaderID ===
                                                              inel?.DOHeaderID &&
                                                            x?.DOItem
                                                              ?.DOItemID ===
                                                              inel?.DOItemID,
                                                        )?.ReturnedQty ||
                                                        '.0000'
                                                      : '.0000'
                                                    : '.0000'
                                                }
                                                onValueChange={({
                                                  floatValue,
                                                }) => {
                                                  const val = floatValue;
                                                  let displayValue;
                                                  if (
                                                    val === 0 ||
                                                    val === null ||
                                                    val === undefined
                                                  ) {
                                                    displayValue = '.0000';
                                                  } else {
                                                    displayValue = val;
                                                  }

                                                  const unitPrice =
                                                    inel?.POItem?.UnitPrice ||
                                                    0;

                                                  const calculatedAmt =
                                                    val * unitPrice || 0;

                                                  if (!isQtyDisabled)
                                                    setValue(
                                                      `getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedAmt`,
                                                      calculatedAmt.toFixed(2),
                                                    );

                                                  let temp = itemHasChanges;

                                                  if (
                                                    temp.find(
                                                      s =>
                                                        s?.DOHeaderID ===
                                                        inel?.DOHeaderID,
                                                    ) !== false
                                                  ) {
                                                    temp = temp?.filter(
                                                      d =>
                                                        d?.DOHeaderID !==
                                                        inel?.DOHeaderID,
                                                    );

                                                    setItemChanges([
                                                      {
                                                        DOHeaderID:
                                                          inel?.DOHeaderID,
                                                        value: floatValue,
                                                      },
                                                      ...temp,
                                                    ]);
                                                  }

                                                  setItemChanges([
                                                    {
                                                      DOHeaderID:
                                                        inel?.DOHeaderID,
                                                      value: floatValue,
                                                    },
                                                    ...temp,
                                                  ]);

                                                  if (val !== 0.0) {
                                                    if (
                                                      new BigNumber(value).gt(
                                                        inel?.UpToDateQty,
                                                      )
                                                    ) {
                                                      setNoError(false);
                                                      setError(
                                                        `getDO[${el?.DOHeaderID}].DOItem[${initem}]`,
                                                        {
                                                          type: 'manual',

                                                          message:
                                                            'Quantity exceeded delivered',
                                                        },
                                                      );
                                                    } else if (
                                                      new BigNumber(value).lt(0)
                                                    ) {
                                                      setNoError(false);
                                                      setError(
                                                        `getDO[${el?.DOHeaderID}].DOItem[${initem}]`,
                                                        {
                                                          type: 'manual',
                                                          message:
                                                            'Quantity must be positive',
                                                        },
                                                      );
                                                    } else {
                                                      setNoError(true);
                                                      clearErrors(
                                                        `getDO[${el?.DOHeaderID}].DOItem[${initem}]`,
                                                      );
                                                    }
                                                    onChange(
                                                      isAmtDisabled
                                                        ? amtStrNoRounding(
                                                            displayValue,
                                                          )
                                                        : displayValue,
                                                    );
                                                  }
                                                }}
                                              />
                                            );
                                          }}
                                        />
                                      </td>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={3}
                                      component="span"
                                      className="desc"
                                    >
                                      <td
                                        style={{
                                          paddingTop: '10px',
                                          width: '100px',
                                        }}
                                      >
                                        <Controller
                                          control={control}
                                          ref={register}
                                          label="Rtn Amt"
                                          defaultValue={
                                            mode !== 'add'
                                              ? editData?.GRTNHeaderItem?.filter(
                                                  v =>
                                                    v?.DOItemID ===
                                                    inel?.DOItemID,
                                                )?.length > 0
                                                ? editData?.GRTNHeaderItem.find(
                                                    x =>
                                                      x?.DOItem?.DOHeaderID ===
                                                        inel?.DOHeaderID &&
                                                      x?.DOItem?.DOItemID ===
                                                        inel?.DOItemID,
                                                  )?.ReturnedAmt || '.00'
                                                : '.00'
                                              : '.00'
                                          }
                                          name={`getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedAmt`}
                                          render={({ onChange, value }) => {
                                            return (
                                              <NumberFormat
                                                thousandSeparator
                                                label="Rtn Amt"
                                                allowNegative={false}
                                                customInput={TextField}
                                                disabled={isAmtDisabled}
                                                // onChange={e => {
                                                //   onChange(e);
                                                //   handleQtyChange(e);
                                                //   updateGetDO(el, initem);
                                                // }}
                                                onChange={() => {
                                                  handleAmtChange;
                                                  updateGetDO(el, initem);
                                                }}
                                                className="disabled-input"
                                                name={`getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedAmt`}
                                                variant="outlined"
                                                value={
                                                  value === '0.00'
                                                    ? '.00'
                                                    : value
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale
                                                onValueChange={({
                                                  floatValue,
                                                }) => {
                                                  const val = floatValue;
                                                  let displayValue;
                                                  if (
                                                    val === 0 ||
                                                    val === null ||
                                                    val === undefined
                                                  ) {
                                                    displayValue = '.00';
                                                  } else {
                                                    displayValue = Number(val);
                                                  }

                                                  const unitPrice =
                                                    inel?.POItem?.UnitPrice ||
                                                    0;

                                                  const calculatedQty =
                                                    val / unitPrice || 0;

                                                  if (!isAmtDisabled)
                                                    setValue(
                                                      `getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedQty`,
                                                      calculatedQty.toFixed(4),
                                                    );

                                                  let temp = itemHasChanges;

                                                  if (
                                                    temp.find(
                                                      s =>
                                                        s?.DOHeaderID ===
                                                        inel?.DOHeaderID,
                                                    ) !== false
                                                  ) {
                                                    temp = temp?.filter(
                                                      d =>
                                                        d?.DOHeaderID !==
                                                        inel?.DOHeaderID,
                                                    );

                                                    setItemChanges([
                                                      {
                                                        DOHeaderID:
                                                          inel?.DOHeaderID,
                                                        value: floatValue,
                                                      },
                                                      ...temp,
                                                    ]);
                                                  }

                                                  setItemChanges([
                                                    {
                                                      DOHeaderID:
                                                        inel?.DOHeaderID,
                                                      value: floatValue,
                                                    },
                                                    ...temp,
                                                  ]);

                                                  if (val !== 0.0) {
                                                    if (
                                                      new BigNumber(value).gt(
                                                        inel?.ReturnedAmt,
                                                      )
                                                    ) {
                                                      setNoError(false);
                                                      setError(
                                                        `getDO[${el?.DOHeaderID}].DOItem[${initem}]`,
                                                        {
                                                          type: 'manual',

                                                          message:
                                                            'Quantity exceeded delivered',
                                                        },
                                                      );
                                                    } else if (
                                                      new BigNumber(value).lt(0)
                                                    ) {
                                                      setNoError(false);
                                                      setError(
                                                        `getDO[${el?.DOHeaderID}].DOItem[${initem}]`,
                                                        {
                                                          type: 'manual',
                                                          message:
                                                            'Quantity must be positive',
                                                        },
                                                      );
                                                    } else {
                                                      setNoError(true);
                                                      clearErrors(
                                                        `getDO[${el?.DOHeaderID}].DOItem[${initem}]`,
                                                      );
                                                    }
                                                    onChange(
                                                      isQtyDisabled
                                                        ? amtStrNoRounding(
                                                            displayValue,
                                                          )
                                                        : displayValue,
                                                    );
                                                  }
                                                }}
                                              />
                                            );
                                          }}
                                        />
                                      </td>
                                    </Grid>
                                  </Grid>
                                  <Grid container className="desc">
                                    <Grid
                                      item
                                      xs={12}
                                      style={{ textAlignLast: 'end' }}
                                    >
                                      <span style={{ fontSize: '10px' }}>
                                        {ReturnedQty > OutstandingQty ? (
                                          <span style={{ color: 'red' }}>
                                            Quantity exceeded ordered
                                          </span>
                                        ) : null}
                                      </span>
                                    </Grid>
                                  </Grid>
                                  <Grid container>
                                    <Grid item xs={1} component="span" />
                                    <Grid item xs={11} component="div">
                                      <div className="desc text-noflow fw-medium">
                                        {!!inel?.POItem?.Remarks
                                          ? inel?.POItem?.POHeader?.DocNo +
                                            ` | ` +
                                            inel?.POItem?.Remarks
                                          : inel?.ReplacedGRTNItem?.DOItem
                                              ?.POItem?.POHeader?.DocNo +
                                            ` | ` +
                                            inel?.ReplacedGRTNItem?.DOItem
                                              ?.POItem?.Remarks}
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <Grid container className="desc">
                                    <Grid
                                      item
                                      style={{
                                        marginLeft: '30px',
                                        marginTop: '10px',
                                      }}
                                    >
                                      <Controller
                                        render={({ onChange, value }) => (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                style={{ marginLeft: '12px' }}
                                                color="primary"
                                                onChange={e => {
                                                  onChange(e.target.checked);
                                                  updateGetDO(el, initem);
                                                }}
                                                checked={value}
                                              />
                                            }
                                            label={
                                              <div
                                                className="desc"
                                                style={{
                                                  color: 'lightslategray',
                                                }}
                                              >
                                                Replacement
                                              </div>
                                            }
                                            ref={register}
                                          />
                                        )}
                                        name={`getDO[${el?.DOHeaderID}].DOItem[${initem}].replacement`}
                                        control={control}
                                        defaultValue={
                                          mode !== 'add'
                                            ? editData?.GRTNHeaderItem?.filter(
                                                v =>
                                                  v?.DOItemID ===
                                                  inel?.DOItemID,
                                              )?.map(x => x?.Replacement)[0]
                                            : true
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </>
                              }
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </div>
                </CardExpansion>
              );
            })}
        </List>
      </CardContents>
    </>
  );
};
