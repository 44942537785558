import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import AppContext from 'containers/App/Store/AppContext'
import { AcctPermission } from 'generated/graphql'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router'

export const CBDigitalReportSubmenu = (props: any) => {
  useEffect(() => {
    console.log('CBDigitalReportSubmenu')
  }, [])
  const { globalState, dispatch }: any = useContext(AppContext as any)
  let history = useHistory()
  const { handlePermHidden } = usePermissionChecker()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  const handleDrawer = () => {
    dispatch({ type: 'drawerOpen', payload: !globalState.drawerOpen })
  }

  const submenuList = [
    {
      path: `/cash-book/submenu/digital-report/cash-ledger-report/parameters`,
      title: `Cash Ledger Listing`,
      deployed: true,
      permission: AcctPermission.CashBookDigitalReportCashLedgerView,
    },
    {
      path: `/cash-book/submenu/digital-report/cash-inflow-outflow-report/parameters`,
      title: `Cash Inflow VS Outflow (By Month)`,
      deployed: true,
      permission: AcctPermission.CashBookDigitalReportCashLedgerView,
    },
    {
      path: `/cash-book/submenu/digital-report/bank-reconciliation-statement-report/parameters`,
      title: `Bank Reconciliation Statement`,
      deployed: true,
      // permission: AcctPermission.CashBookDigitalReportCashLedgerView,
    },
  ]

  return (
    <>
      <MainHeader
        mainBtn="menu"
        onClick={handleDrawer}
        smTitle={'Cash Book'}
        title={user?.companyName}
        routeSegments={[{ name: 'Digital Reports', current: true }]}
      />
      <ContentWrapper float>
        <List className="core-list">
          {submenuList.map((el, index) => {
            if (el?.deployed && handlePermHidden({ permEnum: el?.permission }))
              return (
                <ListItem key={index} onClick={() => history.push(el?.path)}>
                  <ListItemText
                    style={{
                      textAlign: 'right',
                      marginLeft: '5px',
                    }}
                    primary={<span className="xsTitle">{el?.title}</span>}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                      <KeyboardArrowRight
                        onClick={() =>
                          history.push({
                            pathname: el.path,
                          })
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
          })}
        </List>
      </ContentWrapper>
    </>
  )
}
